import Multiselect from 'multiselect-react-dropdown';
import React, { useState, forwardRef, useEffect } from 'react';
import Service from '../../../Services/Service'
import { useTranslation } from "react-i18next";
import Spinner from 'react-bootstrap/Spinner';

function ProductSelectorAttribute(props) {
  const [dualListBoxOptionsByAttribute, setdualListBoxOptionsByAttribute] = useState([])
  const [t, i18n] = useTranslation('translation');
  const [finalSelectedPdt, SetFinalSelectedPdt] = useState([]);
  const [data, setData] = useState([]);
  const [filtertext, setfiltertext] = useState('')
  const [showLoadMore, setshowLoadMore] = useState('');
  const [checkboxProduct, setcheckboxProduct] = useState([]);
  const [attributetype, setattributetype] = useState('');
  const [showfilterpopup, setshowfilterpopup] = useState(false);
  const [productattributedisabled, setproductattributedisabled] = useState(false);
  const [filterCol, setFilterCol] = useState('');
  const [size, setSize] = useState(2000);

  const [filterDropDownData, setFilterDropDownData] = useState([]);
  const [dropdownData, setDropdownData] = useState([]);
  const [filterpopuptext, setfilterPopuptext] = useState('');
  const [filterSearch, setFilterSearch] = useState("");
  const [filterValue, setFilterValue] = useState([])
  const [currentFilterKey, setcurrentFilterKey] = useState('');
  const [multiFilterValue, setMultiFilterValue] = useState({

    Product: '',
    Oem: '',
    Vehicletype: '',
    ProductId: ''
  });
  const [paging, setpaging] = useState({
    pageSize: 10,
    pageNumber: 1
  })

  const checkAll = (mycheckbox) => {
    //alert(checkboxProduct)

    var checkboxProductNew = checkboxProduct;
    let arrayreturn = [];
    if (mycheckbox.target.checked == true) {
      let olddata = [...dualListBoxOptionsByAttribute];
      olddata.map((i) => {
        i.Selected = true;
        arrayreturn.push(i.Label + '_' + i.Id);
        //  var value = i.Label + "_" + i.Id;
        //   console.log('value ' + value)
        //  checkboxProductNew = checkboxProductNew.concat(value)

      });
      // props.onCheckboxChecked(checkboxProductNew);
      setcheckboxProduct(arrayreturn);
      setdualListBoxOptionsByAttribute(olddata);
      props.onCheckboxChecked(arrayreturn);
    }
    else {
      let olddata = [...dualListBoxOptionsByAttribute];
      olddata.map((i) => {
        i.Selected = false;
      })
      setcheckboxProduct(arrayreturn);
      props.onCheckboxChecked(arrayreturn);
      setdualListBoxOptionsByAttribute(olddata);
    }

  }

  const LoadMore = (lst, totalcount) => {

    if (lst < totalcount) {
      setshowLoadMore(true);
    }
    else if (lst >= totalcount) {
      setshowLoadMore(false);
    } else {
      setshowLoadMore(false);
    }

  }

  useEffect(() => {


    var SelectedData = props.SelectedData;


    //var dualListBoxOptionsByAttribute = props.dualListBoxOptionsByAttribute;


    var dualListBoxOptionsByAttribute = [...props.dualListBoxOptionsByAttribute].sort((a, b) =>
      a.Selected > b.Selected ? -1 : 1,
    );

    // setSize(dualListBoxOptionsByAttribute.length / 3);


    setcheckboxProduct(SelectedData)
    if (dualListBoxOptionsByAttribute.length > 0) {
      var checkboxProduct = '';
      // var selectedValue=props.dualListBoxOptionsByAttribute.filter((e) => e.Selected === true);
      //SelectedData
      for (var i = 0; i < dualListBoxOptionsByAttribute.length; i++) {
        dualListBoxOptionsByAttribute[i].Selected = false;
      }
      for (var i = 0; i < dualListBoxOptionsByAttribute.length; i++) {
        for (var j = 0; j < SelectedData.length; j++) {
          var lable = dualListBoxOptionsByAttribute[i].Label + "_" + dualListBoxOptionsByAttribute[i].Id;
          if (lable === SelectedData[j]) {
            dualListBoxOptionsByAttribute[i].Selected = true;
            ///  alert('Selected')   
          }
          //checkboxProduct=checkboxProduct.concat(selectedValue[i].Label + "_" + selectedValue[i].Id);
        }
      }
      // setcheckboxProduct(checkboxProduct);
      // alert(checkboxProduct)
    }
    setdualListBoxOptionsByAttribute(dualListBoxOptionsByAttribute)
    setData(dualListBoxOptionsByAttribute);
    SetFinalSelectedPdt(props.SelectedData);
    if (props.rebatelinestatus == 'New' || props.rebatelinestatus == 'Revising') {
      setproductattributedisabled(false)
    } else {
      setproductattributedisabled(true)
    }
    if (Service.editPerms(props.userPerms) == false) {
      //setproductattributedisabled(true)
    }

    console.log("27/9", productattributedisabled)

    // setattributetype(0)
    //getdataProductSelectorByAttribute("rebate/ProductSelectorByAttributeNew", paging.pageSize, paging.pageNumber, [])
  }, [0])


  const searchHandler = (event, pageNumber, pageSize) => {

    paging.pageSize = pageSize + 10;
    paging.pageNumber = pageNumber + 1;
    setpaging(paging);
    //console.log('search word ------', event.target.value)
    setfiltertext(event.target.value);
    if (event.target.value.length > 0) {
      console.log("12/9", data)
      console.log("12/9", event.target.value)
      let searchResults = data.filter((each) => {
        console.log("12/9", each, String(event.target.value))
        return each.Label?.toLowerCase().includes(String(event.target.value)?.toLowerCase()) ||
          each.ProductAttribute1Name?.toLowerCase().includes(String(event.target.value?.toLowerCase())) ||
          each.ProductAttribute2Name?.toLowerCase().includes(String(event.target.value?.toLowerCase())) ||
          each.ProductAttribute3Name?.toLowerCase().includes(String(event.target.value?.toLowerCase())) ||
          each.ProductAttribute4Name?.toLowerCase().includes(String(event.target.value?.toLowerCase())) ||
          each.Name?.toLowerCase().includes(String(event.target.value?.toLowerCase()))
      });

      let list = [];
      for (var key in multiFilterValue) {
        if (multiFilterValue[key].length > 0) {
          let values = ""
          multiFilterValue[key].map((i) => {
            values += i.Name + ";";
          })
          if (values !== null && values !== '') {
            list.push({
              filterKey: key,
              FilterValue: values
            })
          }
        }
      }

      for (var i = 0; i < list.length; i++) {
        var values = list[i].FilterValue;
        if (list[i].filterKey === 'ProductId') {
          searchResults = searchResults.filter((each) => {
            return each.Name.toLowerCase().includes(String(values).toLowerCase())
          });
        }
        if (list[i].filterKey === 'Product') {
          searchResults = searchResults.filter((each) => {
            return each.Label.toLowerCase().includes(String(values).toLowerCase())
          });
        }
        if (list[i].filterKey === 'Attribute1') {
          searchResults = searchResults.filter((each) => {
            return each.ProductAttribute1Name.toLowerCase().includes(String(values).toLowerCase())
          });
        }
        if (list[i].filterKey === 'Attribute2') {
          searchResults = searchResults.filter((each) => {
            return each.ProductAttribute2Name.toLowerCase().includes(String(values).toLowerCase())
          });
        }
        if (list[i].filterKey === 'Attribute3') {
          searchResults = searchResults.filter((each) => {
            return each.ProductAttribute3Name.toLowerCase().includes(String(values).toLowerCase())
          });
        }
        if (list[i].filterKey === 'Attribute4') {
          searchResults = searchResults.filter((each) => {
            return each.ProductAttribute4Name.toLowerCase().includes(String(values).toLowerCase())
          });
        }
      }
      setdualListBoxOptionsByAttribute(searchResults)
    }
    else {
      let list = [];
      //  let list = [];
      for (var key in multiFilterValue) {
        if (key !== filterCol && multiFilterValue[key].length > 0) {
          let values = ""
          multiFilterValue[key].map((i) => {
            values += i.Name + ";";
          })
          if (values !== null && values !== '') {
            list.push({
              filterKey: key,
              FilterValue: values
            })
          }
        }
      }
      var newData = data;
      let searchResults = [];
      for (var i = 0; i < list.length; i++) {
        var values = list[i].FilterValue;

        if (list[i].filterKey === 'ProductId') {
          newData = newData.filter((each) => {
            return each.Name.toLowerCase().includes(String(values).toLowerCase())
          });
        }
        if (list[i].filterKey === 'Product') {
          newData = newData.filter((each) => {
            return each.Label.toLowerCase().includes(String(values).toLowerCase())
          });
        }
        if (list[i].filterKey === 'Attribute1') {
          newData = newData.filter((each) => {
            return each.ProductAttribute1Name.toLowerCase().includes(String(values).toLowerCase())
          });
        }
        if (list[i].filterKey === 'Attribute2') {
          newData = newData.filter((each) => {
            return each.ProductAttribute2Name.toLowerCase().includes(String(values).toLowerCase())
          });
        }
        if (list[i].filterKey === 'Attribute3') {
          newData = newData.filter((each) => {
            return each.ProductAttribute3Name.toLowerCase().includes(String(values).toLowerCase())
          });
        }
        if (list[i].filterKey === 'Attribute4') {
          newData = newData.filter((each) => {
            return each.ProductAttribute4Name.toLowerCase().includes(String(values).toLowerCase())
          });
        }
      }

      setdualListBoxOptionsByAttribute(newData)
    }

    // if (event.target.value != '') {
    //   setSearchValue(String(event.target.value).toUpperCase())
    //   let searchResults = listViewData.filter((each) => {
    //     console.log(each)
    //     return each.Rebate_id.toUpperCase().includes(searchValue) || each.Customer.toUpperCase().includes(searchValue) || each.Status.toUpperCase().includes(searchValue) || each.CreatedBy.toUpperCase().includes(searchValue)
    //   })
    //   console.log('search data', searchResults)
    //   setFilteredArray(searchResults)
    // }
    // else {
    //   setFilteredArray(listViewData)
    // }
  }

  const onload = (e, status, id) => {
    // alert(id);
  }

  // const handleChange = (e, status, id) => {


  //   var selectedCus = data;

  //   if (e.target.checked == true) {
  //     for (var k = 0; k < selectedCus.length; k++) {
  //       if (id === selectedCus[k].Id) {
  //         selectedCus[k].Selected = true;
  //       }
  //     }
  //   }

  //   setData(selectedCus);


  //   let isChecked = e.target.checked;   // true if it is checked false if unchecked
  //   //console.log('checked', isChecked)
  //   // console.log('checked', id)
  //   let value = status + "_" + id;
  //   let olddata = [...dualListBoxOptionsByAttribute];
  //   if (e.target.checked === true) {
  //     olddata.map((i) => {
  //       if (i.Label === status) {
  //         i.Selected = true;
  //       }

  //     });
  //     setdualListBoxOptionsByAttribute(olddata);

  //     setcheckboxProduct(checkboxProduct.concat(value));
  //     SetFinalSelectedPdt(checkboxProduct.concat(value));
  //     //var SelectedData =props.SelectedData;
  //     //  props.SelectedData(SelectedData.concat(value));
  //     props.onCheckboxChecked(checkboxProduct.concat(value));
  //   }
  //   else {
  //     olddata.map((i) => {
  //       if (i.Label === status) {
  //         i.Selected = false;
  //       }

  //     });
  //     setdualListBoxOptionsByAttribute(olddata);

  //     var index = checkboxProduct.indexOf(value);
  //     setcheckboxProduct(checkboxProduct.filter((_, i) => i !== index))
  //     SetFinalSelectedPdt(checkboxProduct.filter((_, i) => i !== index))
  //     props.onCheckboxChecked(checkboxProduct.filter((_, i) => i !== index));
  //   }
  // }

  const handleChange = (e, status, id, policy) => {
    const selectedCus = [...data];
    const value = status + "_" + id;
    let olddata = [...dualListBoxOptionsByAttribute];

    if (policy === 'EngineOil') {
        if (e.target.checked) {
            
            olddata.forEach(item => {
                item.Selected = item.Id === id; 
            });
            setdualListBoxOptionsByAttribute(olddata);
            setcheckboxProduct([value]); 
            SetFinalSelectedPdt([value]);
            props.onCheckboxChecked([value]);
        } else {
            
            olddata.forEach(item => {
                item.Selected = false; 
            });
            setdualListBoxOptionsByAttribute(olddata);
            setcheckboxProduct([]); 
            SetFinalSelectedPdt([]);
            props.onCheckboxChecked([]);
        }
    } else {
        
        if (e.target.checked) {
            selectedCus.forEach(item => {
                if (item.Id === id) {
                    item.Selected = true; 
                }
            });
            setData(selectedCus);
            olddata.forEach(i => {
                if (i.Label === status) {
                    i.Selected = true;
                }
            });
            setdualListBoxOptionsByAttribute(olddata);
            setcheckboxProduct(prev => [...prev, value]);
            SetFinalSelectedPdt(prev => [...prev, value]);
            props.onCheckboxChecked([...checkboxProduct, value]);
        } else {
            selectedCus.forEach(item => {
                if (item.Id === id) {
                    item.Selected = false; 
                }
            });
            setData(selectedCus);
            olddata.forEach(i => {
                if (i.Label === status) {
                    i.Selected = false;
                }
            });
            setdualListBoxOptionsByAttribute(olddata);
            const index = checkboxProduct.indexOf(value);
            setcheckboxProduct(prev => prev.filter((_, i) => i !== index));
            SetFinalSelectedPdt(prev => prev.filter((_, i) => i !== index));
            props.onCheckboxChecked(checkboxProduct.filter((_, i) => i !== index));
        }
    }
};

  const handleChangeProduct = (e, status, id) => {
    let checkboxes = document.querySelectorAll("input[id = '" + id + "']:checked");

    // let checkboxes1 = document.querySelectorAll("input[id = '" + id + "']:not(:checked)'");
    // alert(checkboxes1.length)
    console.log("31/1", status)
    console.log("31/1", checkboxes)
    let isChecked = e.target.checked;   // true if it is checked false if unchecked
    let checkValue = false
    let value = status + "_" + id;
    let olddata = [...dualListBoxOptionsByAttribute];
    if (checkboxes.length < 1) {
      olddata.map((i) => {
        if (i.Label === status) {
          i.Selected = true;
        }
      });
      setdualListBoxOptionsByAttribute(olddata);
      setcheckboxProduct(checkboxProduct.concat(value));
      SetFinalSelectedPdt(checkboxProduct.concat(value));
      props.onCheckboxChecked(checkboxProduct.concat(value));
    }
    else {
      olddata.map((i) => {
        if (i.Label === status) {
          i.Selected = false;
        }

      });
      setdualListBoxOptionsByAttribute(olddata);
      var index = checkboxProduct.indexOf(value);
      setcheckboxProduct(checkboxProduct.filter((_, i) => i !== index))
      SetFinalSelectedPdt(checkboxProduct.filter((_, i) => i !== index))
      props.onCheckboxChecked(checkboxProduct.filter((_, i) => i !== index));
    }
  }

  const handledropdownChange = (e) => {
    // alert(e.target.value);
    setattributetype(e.target.value)
  }

  const [divleft, setdivleft] = useState('');
  const [divtop, setdivtop] = useState('');


  const FilterColumn = (name) => {
   
    var divtop1 = document.getElementById("tableA");
    setdivtop(divtop1.offsetTop + 30)

    setshowfilterpopup(true)
    setFilterCol(name)

    if (name === 'Product') {
      setfilterPopuptext("Item Name")
      var testDiv = document.getElementById("Product");
      setdivleft(testDiv.offsetLeft + "px");
    }
    if (name === 'ProductId') {
      setfilterPopuptext("Material No")
      var testDiv = document.getElementById("ProductId");
      setdivleft(testDiv.offsetLeft + "px");
    }


    if (name === 'Oem') {
      setfilterPopuptext("Oem")
      var testDiv = document.getElementById("Oem");
      setdivleft(testDiv.offsetLeft + "px");
    }
    if (name === 'Vehicletype') {
      setfilterPopuptext("Vechile Type")
      var testDiv = document.getElementById("Vehicletype");
      setdivleft(testDiv.offsetLeft + "px");
    }
    

    DropdownHandler(name)

  }


  const filterHandler = (flag) => {
    let list = [];

    // debugger;

    if (flag === 'Apply') {

      setshowfilterpopup(false)
      for (var key in multiFilterValue) {
        if (multiFilterValue[key].length > 0) {
          let values = ""
          multiFilterValue[key].map((i) => {
            values += i.Name + ";";
          })
          if (values !== null && values !== '') {
            list.push({
              filterKey: key,
              FilterValue: values
            })
          }
        }
      }
      console.log(list,"listed")
      if (list.length > 0) {
        // debugger;
        Service.getdataWithFilters('rebate/filterProductSelectorByAttribute', data, list).then((res) => {
          if (typeof res !== 'undefined') {
            if (res.data !== 'null' && typeof res.data !== 'undefined') {
              setdualListBoxOptionsByAttribute(res.data.DataList)
            }
          }
        });
      }

    }
    else {
      setshowfilterpopup(false)
      setMultiFilterValue(() => ({
        ...multiFilterValue, [filterCol]: '',
      }));
      for (var key in multiFilterValue) {
        if (key !== filterCol && multiFilterValue[key].length > 0) {
          let values = ""
          multiFilterValue[key].map((i) => {
            values += i.Name + ";";
          })
          if (values !== null && values !== '') {
            list.push({
              filterKey: key,
              FilterValue: values
            })
          }
        }
      }
      Service.getdataWithFilters('rebate/filterProductSelectorByAttribute', data, list).then((res) => {
        if (typeof res !== 'undefined') {
          if (res.data !== 'null' && typeof res.data !== 'undefined') {
            setdualListBoxOptionsByAttribute(res.data.DataList)
          }
        }
      });
      setFilterCol('')
      setDropdownData([])
      setFilterDropDownData([])
      setFilterSearch("")
      setcurrentFilterKey("")
    }
  }
  const onSelectedFile = (e) => {

    // this.props.onSelectedfile(e.target.files[0])
    // console.log("Onselectedfile",e.target.files[0])
    let text = "";
    let file = e.target.files[0], reader = new FileReader(), self = this;
    reader.onload = function () {

      text = reader.result;
      // alert(text);
      if (text !== "") {
        csvFileToArray(text);
      }

    }
    // 
    reader.readAsText(file);
    // reader.readAsDataURL(file);
    // alert(reader)

    // var fileReader = new FileReader();
    // if (e) {
    //   this.fileReader.onload = function (e) {
    //     const text = e.target.result;
    //     debugger;

    //     // setnewarr(val);
    //     // alert(newarr);
    //   };


    //}
  }

  const csvFileToArray = (string) => {
    // debugger;


    let csvh = [];
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");
    // csvHeader = csvHeader
    csvHeader.forEach((c) => {
      // console.log(c + " " + c.length);
      // console.log(c.trim() + " " + c.trim().length)
      csvh.push(c.trim());
    })

    const array = csvRows.map(i => {
      // const values = i.split(",");
      const values = i;
      const obj = csvh.reduce((object, header, index) => {
        //  object[header] = values[index];
        object[header] = values
        return object;
      }, {});
      return obj;
    });
    var removed = array.splice(-1);
    // console.log("csv array", array);
    var selectedValue = props.dualListBoxOptionsByAttribute.filter((e) => e.Selected === true);
    let arrayreturn = [];
    for (var i = 0; i < selectedValue.length; i++) {
      arrayreturn.push(selectedValue[i].Label + '_' + selectedValue[i].Id);
    }


    let ErrorArray = []

    array.forEach((a) => {
      let value = a.Product

      var returnvalue = findcsvValue(dualListBoxOptionsByAttribute, a.Product.trim().replace(/['"]+/g, ''))

      if (returnvalue !== undefined) {
        arrayreturn.push(returnvalue);
      }
      else {
        let val = {
          ProductName: value.trim().replace(/['"]+/g, ''),
          ErrorMessage: "Product Not available"
        }
        ErrorArray.push(val);
      }
    })


    // props.SelectedData(arrayreturn);
    //  console.log("test csvproductlist", arrayreturn);
    //  this.setState({ checked:arrayreturn });
    props.onCheckboxChecked(arrayreturn);
    // const myObjStr = JSON.stringify(ErrorArray);
    //  console.log("Error Json",ErrorArray)
    //this.setState({ ErrorProduct:ErrorArray})

  };
  const findcsvValue = (data, value) => {

    // debugger;
    // alert(value);
    if (data !== null) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].Label === value) {
          data[i].Selected = true;
          return data[i].Label + '_' + data[i].Id;

        } else if (data[i].Name === value) {
          data[i].Selected = true;
          return data[i].Label + '_' + data[i].Id;

        }

      }
    }
    setdualListBoxOptionsByAttribute(data)

    return;
  }

  const OndropdownChange = (e, id, name) => {
    let isChecked = e.target.checked;
    var lastfilter = [...filterDropDownData]
    if (isChecked === true) {
      let currentdata = lastfilter.map((i) => {
        if (i.Id == id) {
          i.selected = true;
        }
        return i;
      });
      console.log("20/3", filterDropDownData)
      console.log("Add New Column", currentdata)
      setFilterDropDownData(currentdata);
      let data1 = []
      const getallData = {
        Name: name,
      }
      data1.push(getallData)
      setFilterValue(filterValue => [...filterValue, ...data1]);
      // data1.push(getallData)
      let updateddata = ([...filterValue, ...data1]);
      setMultiFilterValue(() => ({
        ...multiFilterValue, [filterCol]: updateddata,
      }));
    }
    else {
      let currentdata = lastfilter.map((i) => {
        if (i.Id === id) {
          i.selected = false;
        }
        return i;
      });
      console.log("Add New Column", currentdata)
      setFilterDropDownData(currentdata);
      setFilterValue(filterValue => filterValue.filter(data => data.Name !== name))
      let data1 = filterValue.filter(data => data.Name !== name);
      setMultiFilterValue(() => ({
        ...multiFilterValue, [filterCol]: data1,
      }));
    }
  }

  const DropdownHandler = (key) => {
    if (currentFilterKey != key) {
      setcurrentFilterKey(key)
       
      Service.getfilterDropdownByGroup('Rebate/GetAllProductFilters', key).then((res) => {
        if (typeof res !== 'undefined') {
          if (typeof res.data !== 'undefined' && res.data !== 'null') {
            if (res.data !== 'null' && res.data !== 'undefined') {
              // setFilterDropDownData(res.data)
              console.log(res.data,"dropdown-res")
              addnewfield(res.data, key)
            }
          }
        }

      });
    }
  }

  const addnewfield = (data, key) => {
    // debugger
    // alert(key)multi
    let currentdata = data.map((i) => {
      i.selected = false;
      return i;
    });
    let selectedKeys = multiFilterValue[key];
    if (selectedKeys != '') {
      currentdata.forEach((each) => {
        selectedKeys.forEach((eachSelected) => {
          if (each.Name == eachSelected.Name) {
            each.selected = true
          }
        })
      })
    }
    console.log("multi", multiFilterValue)
    setDropdownData(currentdata);
    setFilterDropDownData(currentdata)
  }



  const UserfilterHandler = (e) => {
    // debugger;
    setFilterSearch(e.target.value)
    if (e.target.value !== "") {
      let searchResults = dropdownData.filter((each) => {
        return each.Name.toLowerCase().includes(String(e.target.value).toLowerCase())
      });
      setFilterDropDownData(searchResults);
    }
    else {
      setFilterSearch("")
      setFilterDropDownData(dropdownData)
    }
  }

  const checkAllFilter = (mycheckbox) => {
    var lastfilter = [...filterDropDownData]
    let data1 = []
    var checkboxes = document.querySelectorAll("input[name = 'filter-option-all']");
    if (mycheckbox.target.checked == true) {
      // checkboxes.forEach(function (checkbox) {
      //   checkbox.checked = true;
      // });
      let currentdata = lastfilter.map((i) => {
        i.selected = true;
        return i;
      });
      setFilterDropDownData(currentdata)
      console.log('20/3-dropdown', filterDropDownData)
      filterDropDownData.map((each) => {
        console.log('20/3-eacch', each)
        const getallData = {
          Name: each.Name,
        }
        data1.push(getallData)
      })
      setFilterValue(filterValue => [...filterValue, ...data1]);
      let updateddata = ([...filterValue, ...data1]);
      setMultiFilterValue(() => ({
        ...multiFilterValue, [filterCol]: updateddata,
      }));
    }
    else {
      // checkboxes.forEach(function (checkbox) {
      //   checkbox.checked = false;
      // });
      let currentdata = lastfilter.map((i) => {
        i.selected = false;
        return i;
      });
      setFilterValue([])
      setMultiFilterValue([])
    }

  }




  return (
    <>
      <div className="scrollbar-class productselectoroverflow productselecotordiv productselecotorpadding" style={{ height: "69vh" }}>
        <div className='row'>
          {/* <div className='col-md-4'>
            <select name="attribute" id="attributeid" className='col-md-12' value={attributetype} onChange={(event) => handledropdownChange(event)}>
              <option value="0">Select</option>
              <option value="1">Attribute 1</option>
              <option value="2">Attribute 2</option>
              <option value="3">Attribute 3</option>
              <option value="4">Attribute 4</option>
            </select>
          </div> */}  <div className='col-md-12'>
            {props.rebatelinestatus === 'New' ||  props.rebatelinestatus == 'Revising'?
              <input type='file' className="text-right" onChange={(event) => onSelectedFile(event)} ></input>
              : ""}  </div>
          <div className='col-md-12'>
            <input type="text" className="col-md-12 form-control-user" id="firstName" name="firstName" placeholder={`${t('Search')} ${t('Rebates.Product')}`} onChange={(event) => searchHandler(event, 0, 0)} />
          </div>
        </div>
        <table className="table table-bordered text-left mt-2 " id="tableA">
          <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
            <tr>
              <th className="tableheader">
                { props.policy !== 'EngineOil' ?
                  <input type="checkbox" id="option-all" disabled={productattributedisabled} onChange={checkAll} />
                  :
                  <input type="checkbox" id="option-all" disabled={true} onChange={checkAll} />
                }
                
              </th>
              <th id="ProductId" style={{ backgroundColor: (multiFilterValue.ProductId ? '#6cae55' : '') }}>
                {t('Rebates.MaterialNo')} <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('ProductId')}></span>
              </th>
              <th id="Product" style={{ backgroundColor: (multiFilterValue.Product ? '#6cae55' : '') }}>
                {t('Rebates.ItemName')} <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Product')}></span>
              </th>
              <th id="Oem" style={{ backgroundColor: (multiFilterValue.Oem ? '#6cae55' : '') }}>
                OEM <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Oem')}></span>
              </th>
              <th id="Vehicletype" style={{ backgroundColor: (multiFilterValue.Vehicletype ? '#6cae55' : '') }}>
                Vehicle Type <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Vehicletype')}></span>
              </th>
               

              {/* <th>Attribute4</th>
                        <th>Attribute5</th> */}

            </tr>
          </thead>
          <tbody>
            {dualListBoxOptionsByAttribute.length === 0 ?

              <>
                <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
              </>

              :
              <>

                {
                  dualListBoxOptionsByAttribute.sort((a, b) =>
                    a.Selected > b.Selected ? -1 : 1,
                  ).map((listVal) =>
                    <tr key={listVal.Id} onClick={(event) => handleChangeProduct(event, listVal.Label, listVal.Id)}>

                      <td style={{ width: "30px" }}>

                        {

                          <input id={listVal.Id} disabled={productattributedisabled || (props.policy === 'EngineOil' && checkboxProduct.length >= 1 && checkboxProduct[0] !== (listVal.Label + "_" + listVal.Id))}   checked={listVal.Selected} type="checkbox" name="chkBoxproduct" value={listVal.Id} onChange={(event) => handleChange(event, listVal.Label, listVal.Id,props.policy)} />

                        }

                      </td>
                      <td style={{ width: "100px" }}>
                        <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={listVal.Name}>{listVal.Name}</div>


                      </td>
                      <td style={{ width: "200px" }}>
                        <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={listVal.Label}>{listVal.Label}</div>


                      </td>
                      <td style={{ width: "100px" }}>
                        <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={listVal.Oem}>{listVal.Oem}</div>

                      </td>
                      <td style={{ width: "100px" }}>

                        <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={listVal.Vehicletype}>{listVal.Vehicletype}</div>

                      </td>
                      
                      {/* <td>{listVal.ProductAttribute4Name}</td>
                            <td>{listVal.ProductAttribute5Name}</td> */}

                    </tr>
                  )
                }
              </>
            }
          </tbody>
        </table>
        {
          showLoadMore === true ?
            <div className='text-center container row'>
              <div className='col-md-5'></div>
              <div className='col-md-2'>
                <button className='btn btn-sm btn-update ml-1' style={{width: "70px"}}>{t('LoadMore')}</button>
              </div>
              <div className='col-md-5'></div>

            </div>
            :
            ""
        }

        {
          showfilterpopup === true ?
            // <div className='pop-up' style={{ left: divleft, top: divtop }}>
            //   <div className="row">
            //     <span className='col-md-1 fa fa-filter' />
            //     <Multiselect
            //       onSelect={(Event) => OndropdownChange(Event)}
            //       onRemove={(Event) => OndropdownChange(Event)}
            //       options={filterDropDownData}
            //       displayValue="Name"
            //       // name="dropdown"
            //       showCheckbox={true}
            //       selectedValues={multiFilterValue[filterCol]}
            //       className='col-md-9 textbox-padding fontsizedropdown'
            //     //  style={customMultiStyles}
            //     />
            //   </div>
            //   <div style={{ paddingTop: '10px', paddingLeft: '47px', paddingBottom: '7px' }}>
            //     <button className="btn btn-sm btn-update" onClick={() => filterHandler('Apply')}>{t('Ok')}</button>
            //     <button className="btn btn-sm btn-update" style={{ marginLeft: '10px' }} onClick={() => filterHandler('Reset')}>{t('Remove')}</button>
            //   </div>
            // </div>
            // :
            // <></>
            <div className='pop-up' style={{ left: divleft, top: divtop, width: "200px" }}>
              <>
                <div className="container">
                  <div className="row">
                    <b className="forgettext col-md-11 textbox-padding ml-2">{filterpopuptext}</b>

                  </div>

                </div>

                <div className="container mt-2">
                  <div className="row">
                    <input type="text" placeholder="Search" value={filterSearch} onChange={(event) => UserfilterHandler(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                  </div>

                  <div className="row contentoverflow mt-2">
                    <table className="filtertable">
                      <tbody>
                        <tr>
                          <td><input type="checkbox" name="filter-option-all" id="option-all" onChange={checkAllFilter} /></td>
                          <td>Select All</td>
                        </tr>
                        {
                          filterDropDownData.map((item, index) => (

                            <tr key={index}>

                              <td><input id={item.Id} data-index="1" name="filter-option-all" checked={item.selected} type="checkbox" onChange={(event) => OndropdownChange(event, item.Id, item.Name)} /></td>

                              <td>{item.Name}</td>

                            </tr>


                          ))
                        }

                      </tbody>
                    </table>

                  </div>
                </div>
              </>
              <div style={{ paddingTop: '10px', paddingLeft: '33px', paddingBottom: '7px' }}>

                <button className="btn btn-sm btn-update" style={{ width: "45px" }} onClick={() => filterHandler('Apply')}>{t('Apply')}</button>
                <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "45px" }} onClick={() => filterHandler('Reset')}>{t('Reset')}</button>
              </div>
              <div>
              </div>
            </div>
            :
            <></>
        }
      </div>
    </>
  );


}

export default ProductSelectorAttribute;