import { Link, useLocation } from "react-router-dom"
import React, { useEffect, useState, useRef, forwardRef } from 'react';
import { CSVLink } from "react-csv";

import Service from '../../Services/Service';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import Multiselect from 'multiselect-react-dropdown';
import SessionManage from '../../SessionManagement/SessionManage';
import CustomErrorPopup from '../../UI/CustomErrorPopup';
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function LockedDocumentsComponent(props) {
    const csvInstance = useRef();
    const location = useLocation();
    const [csvList, setCsvList] = useState([]);
    const [sortColName, setSortColName] = useState("");
    const [sticky, setSticky] = useState("");
    const [t, i18n] = useTranslation('translation');
    const [userPerms, setuserPermission] = useState([]);
    const navigate = useNavigate();
    const [showbutton, setshowbutton] = useState('');
    const [showspinner, setshowspinner] = useState('');
    const [filterValue, setFilterValue] = useState('');
    const [filterCol, setFilterCol] = useState('');
    const [showfilterpopup, setshowfilterpopup] = useState(false);
    const [dropdownData, setDropdownData] = useState([]);
    const [dropdownChange, setDropdownChange] = useState('');
    let rowData = [];
    const [listViewData, setListViewData] = useState(rowData)
    const [filteredArray, setFilteredArray] = useState(rowData)
    const [state, setState] = useState({ status: '2' });
    const [checkedValueids, setCheckedValueids] = useState([]);
    const [checkedValueDocumentIds, setCheckedValueDocumentIds] = useState([]);
    const [searchValue, setSearchValue] = useState('')
    const [totalselectedshow, settotalselectedshow] = useState('');
    const [totalselectedcount, settotalselectedcount] = useState(0);
    const [totalCountlst, settotalCountlst] = useState('');
    const [columnAscending, setColumnAscending] = useState(true);
    const [paging, setpaging] = useState({
        pageSize: 20,
        pageNumber: 1
    })
    const [showLoadMore, setshowLoadMore] = useState('');
    const [filtertext, setfiltertext] = useState('');
    const [filterSearch, setFilterSearch] = useState("");
    const [filterdropdownData, setFilterDropdownData] = useState([]);
    const [filterValueDict, setFilterValueDict] = useState({
        rebate_agreement_id: '',
        rebate_program_id: '',
        amount: '',
        accrualid: '',
        version: ''
    });
    const [multiFilterValue, setMultiFilterValue] = useState({
        customer: '',
        lockeduser: ''
    });

    let loggedInUser = SessionManage.getuserid();
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorStatus, setErrorStatus] = useState("Error");

    // New state to manage the Check All checkbox
    const [checkAllChecked, setCheckAllChecked] = useState(false);
    const [checkedValue, setCheckedValue] = useState([]);
    const [checkedMultiValueid, setCheckedMultiValueid] = useState([]);
    const [checkedValueid, setCheckedValueid] = useState([]);
    const [checkedValueStatus, setCheckedValueStatus] = useState([]);
    const [allCheckedHistory, setAllCheckedHistory] = useState(false);
    const [uncheckedBoxesHistory, setuncheckedBoxesHistory] = useState([]);


    {
        useEffect(() => {
            setshowspinner(true);
            getUserPermission(location.pathname);
            getLockedDocumentsData("LockedDocuments/GetAll", paging.pageSize, paging.pageNumber, "Ascending","Id",null, []);
            window.addEventListener("scroll", isSticky);
            return () => {
                window.removeEventListener("scroll", isSticky);
            };
        }, [])
    }
    const isSticky = () => {
        const scrollTop = window.scrollY;
        const stickyClass = scrollTop >= 60 ? "is-sticky-thead" : "";
        setSticky(stickyClass);
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop(divtop1.offsetTop + scrollTopActual)
    };
    const class_thead = `text-white rt-thead  ${sticky}`;

    const ExporttoCSV = () => {
        const dateFormat = SessionManage.getcurrentdateformat();
        const timeFormat = "hh:mm A";

        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        let values = "";
        if (checkedBoxes.length >= 1) {
            for (let checkbox of checkedBoxes) {
                values = values === "" ? checkbox.id : values + ";" + checkbox.id;
            }
        }

        let objFilter = [
            {
                filterKey: "id",
                FilterValue: values,
            },
        ];

        // Make the API call without any filters
        Service.getdataWithPagingByGroupSearch("LockedDocuments/GetAll", 20000, 1, "Ascending", "Id", checkAllChecked ? filterLst() : objFilter, null).then((res) => {
            if (typeof res !== 'undefined' && res.data !== 'null' && res.data !== 'undefined') {
                console.log(res.data.DataList, 'data-list');

                let csvLst = res.data.DataList;

                let selectedIds = values.split(';');

                let operatedList = csvLst
                //If checkall box is not checked
                if (!checkAllChecked) {
                    //If it has not been checked in history/past
                    if (!allCheckedHistory)
                        operatedList = csvLst.filter(item => selectedIds.includes(`${item.Id}`))
                    //If it has been checked in history/past
                    else
                        operatedList = csvLst.filter(item => !uncheckedBoxesHistory.includes(item.Id))
                }

                // Filter the received data based on selected IDs and format Last Modified Date
                let filteredCsvList = operatedList.map(item => {
                    let newItem = {};
                    Object.keys(item).forEach(key => {
                        if (key === "LastModifiedDate") {
                            newItem[key] = moment(item[key]).format(`${dateFormat} ${timeFormat}`);
                        } else {
                            newItem[key] = item[key];
                        }
                    });
                    return newItem;
                });

                setCsvList(filteredCsvList);

                console.log(filteredCsvList, "filtered");

                setTimeout(() => {
                    toast.success('Exported Successfully!', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    csvInstance.current.link.click();
                });
            }
        });
    };

    const getLockedDocumentsData = (method, pageSize, pageNumber, sortType, SortProperty,search,filterValue) => {
     
        Service.getAll(method, pageSize, pageNumber, sortType, SortProperty,search,filterValue).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            rowData = res.data.DataList
                            console.log(rowData,": data")
                            LoadMore(res.data.DataList.length, res.data.Totalcount)
                            settotalCountlst(res.data.Totalcount)
                            setListViewData(rowData)
                            setFilteredArray(rowData)
                            setshowspinner(false);
                        }
                    }
                }
            }

        });
    }


    // const searchHandler = (event) => {
    //     const searchTerm = event.target.value.toLowerCase(); 
    //     if (searchTerm !== '') {
    //         setSearchValue(searchTerm);
    //         const searchResults = listViewData.filter((each) => {
    //             return (each.Document_id?.toLowerCase().includes(searchTerm) || '') ||
    //                (each.type?.toLowerCase().includes(searchTerm) || '') ||
    //                (each.LockedByUserName?.toLowerCase().includes(searchTerm) || '');
    //         });
    //         setFilteredArray(searchResults);
    //     } else {
    //         setFilteredArray(listViewData);
    //     }
    // }
    const searchHandler = (event) => {

        var sortType = columnAscending === true ? 'Ascending' : 'Descending';

        let pagenumber = 1;
        console.log(event.target.value,"Searched Value")
        paging.pageNumber = pagenumber;
        setpaging(paging);
        if (event.target.value != '') {

            setSearchValue(String(event.target.value).toLowerCase())
            getLockedDocumentsData("LockedDocuments/GetAll", paging.pageSize, pagenumber, sortType, "Id",event.target.value, filterLst(),);
            // getLockedDocumentsData("LockedDocuments/GetAll", paging.pageSize, paging.pageNumber, "Ascending", "Id", []);
        }  
        else {

            setSearchValue(null);
            getLockedDocumentsData("LockedDocuments/GetAll", paging.pageSize, pagenumber, sortType, "Id", null,filterLst());
        }
    }

    const handleLoadMore = () => {

        let pagenumber = paging.pageNumber + 1;
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        paging.pageNumber = pagenumber
        setpaging(paging);

        Service.getdataWithPagingByGroup("LockedDocuments/GetAll", paging.pageSize, pagenumber, null, null, []).then((res) => {

            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            let rowData = res.data.DataList;

                            if (res.data.DataList !== 'null' && res.data.DataList !== 'undefined') {
                                const currentlength = listViewData.length;
                                setListViewData(listViewData => [...listViewData, ...res.data.DataList])
                                setFilteredArray(filteredArray => [...filteredArray, ...res.data.DataList])
                                LoadMore(currentlength + rowData.length, res.data.Totalcount)

                                const allCheckBoxes = document.querySelectorAll("input[type='checkbox']");
                                console.log(allCheckBoxes, "21-6");

                                if (!checkAllChecked && totalselectedcount != 0) {
                                    allCheckBoxes.forEach((each) => {
                                        const checkboxId = parseInt(each.id, 10);
                                        if (!isNaN(checkboxId)) {
                                            if (uncheckedBoxesHistory.includes(checkboxId)) {
                                                each.checked = false;
                                            } else {
                                                // each.checked = true;
                                                each.checked = checkedMultiValueid.includes(checkboxId);
                                            }
                                        }
                                    });
                                } else {
                                    allCheckBoxes.forEach((each) => {
                                        each.checked = checkAllChecked;
                                    });
                                }
                            }
                        }
                    }
                }
            }
        });
    };

    console.log("27-6", checkedValueids)
    const handleChange = (e, id, listVal) => {

        const isChecked = e.target.checked;   // true if it is checked false if unchecked
        const checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        let count = totalselectedcount;

        let check_lst = checkedValueids



        if (isChecked) {
            let concatValue = listVal.Id + ";" + listVal.type;
            setCheckedValueids([...checkedValueids, concatValue])

            check_lst.push(listVal.Id)
            count += 1;

            let tempUncheckedBoxesHistory = [...uncheckedBoxesHistory];
            const index = tempUncheckedBoxesHistory.findIndex((item) => item === id);
            if (index !== -1) {
                tempUncheckedBoxesHistory.splice(index, 1);
            }
            setuncheckedBoxesHistory(tempUncheckedBoxesHistory);

        }
        else {
            setuncheckedBoxesHistory([...uncheckedBoxesHistory, id]);
            setCheckedValueids((prev) => {
                //["1093;Rebate" , "1094;Rebate"]
                return prev.filter((item) => parseInt(item.split(";")[0]) != id);
            })
            // setCheckedValueids(checkedValueids.filter((e) => e !== (listVal.Id || ';' || listVal.Type)))
            check_lst = check_lst.filter((e) => e !== (listVal.Id || ';' || listVal.Type))
            count -= 1;

        }
        if (check_lst.length > 0) {
            setshowbutton(true);
            settotalselectedshow(true);
            settotalselectedcount(check_lst.length);
        }
        else {
            setshowbutton(false);
            settotalselectedshow(false);
        }
        let checkedBoxess = document.querySelectorAll('input[name=chkBox]:checked');
        if (checkedBoxess.length > 0) {
            settotalselectedshow(true);
            settotalselectedcount(checkedBoxess.length);
        } else {
            settotalselectedcount(0);
            settotalselectedshow(false);
        }
        // const count = checkedBoxess.length;

        // if (isChecked) {
        //     setCheckedValue(id);  // check box value - id
        //     setCheckedValueStatus(status); // check box value - Active status
        //     setCheckedValueid(name); // check box value - RA-0000
        //     setCheckedMultiValueid([...checkedMultiValueid, id]); // Add the checked id to the array
        // } else {
        //     setCheckedValue([]);  // check box value - id
        //     setCheckedValueStatus([]); // check box value - Active status
        //     setCheckedValueid([]); // check box value - RA-0000
        //     setCheckedMultiValueid(checkedMultiValueid.filter(item => item !== id)); // Remove the unchecked id from the array
        // }

        settotalselectedcount(count);
        settotalselectedshow(count > 0);

        // Uncheck the "Check All" checkbox if any individual checkbox is unchecked
        if (!isChecked) {
            setCheckAllChecked(false);
        } else if (count === totalCountlst) {
            setCheckAllChecked(true);
        }


    };

    const getUserPermission = (pathname) => {
        Service.GetUserPermission(pathname).then((res) => {
            if (typeof res !== 'undefined') {

                if (res.data !== 'null' && typeof res.data !== 'undefined' && res.data !== '') {
                    if (typeof res.data.DataList !== 'undefined') {
                        setuserPermission(res.data.DataList)
                    }
                }
            }
        });
    }


    const documentTypeMatching = {
        'QuarterFixedValuePolicy': 'Fixed Value Policy',
        'AnnualPolicy': 'Annual Policy',
        'EccPolicy': 'ECC Policy',
        'MinThresholdPolicy':'Min Threshold Policy',
        'SlabBasedPolicy':'Slab Based Policy',
        'SparesTargetPolicy':'Spares Target Policy',
        'CMAPolicy':'CMA Policy',
        'TargetSettingPolicy':'Target Setting Policy',
        'TargetAchievementPolicy':'Target Achievement Policy',
        'ExceptionPolicy':'Exception Policy',
        'SettlementFrequency':'Settlement Frequency',
        'SettlementMethod':'Settlement Method',
        'RebatePayout':'Rebate Payout',
        'WorkflowRule':'Workflow Rule',
        'RebateType':'Rebate Type',
        'RebateMeasure':'Rebate Measure',
        'SegmentMappingPolicy':'Segment Mapping Policy',
        'DistributorGroupMappingPolicy':"Distributor Group Mapping Policy",
        'CustomerGroupMapping':"CustomerGroup Mapping",
        'EngineOilVolumeMappingPolicy':"Engine Oil Volume Mapping Policy"
    };


    const UnlockHandler = (e) => {
        console.log(checkedValueids , "c-v-ids",typeof checkedValueids);
        settotalCountlst(totalCountlst-checkedValueids.length)
        Service.PostWithIdAndUser("LockedDocuments/UnlockDocuments", checkedValueids, loggedInUser).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data.Status === 'Failure') {
                            setErrorStatus("Error")
                            setErrorMessage(res.data.ErrorMessage)
                            setShowErrorModal(true)
                        }
                        else{
                               let cvIds = [...checkedValueids];
                               cvIds = cvIds.map((value) => {
                                    return parseInt(value.split(";")[0]);
                               });

                                let temp = [...filteredArray];

                                temp.forEach((obj) => {
                                    let checkbox  = document.getElementById(`${obj.Id};${obj.type};`);
                                    checkbox.checked = false;
                                });

                                cvIds.forEach((id) => {
                                    let index = temp.findIndex((obj) => obj.Id === id);
                                    if(index != -1){
                                        temp.splice(index, 1);
                                    }
                                });

                                if(temp.length === 0)
                                    setCheckAllChecked(false);

                                setFilteredArray(temp)
                        }
                    }
                }
            }

        });
        setCheckedValueids([])
        settotalselectedcount(0);
        settotalselectedshow(false);

    }

    const filterInput = (e) => {
        let inputValue = e.target.value
        setFilterValue(inputValue)
        setFilterValueDict(() => ({
            ...filterValueDict, [filterCol]: e.target.value,
        }));
    }

    const [divleft, setdivleft] = useState('');
    const [divtop, setdivtop] = useState('');

    const FilterColumn = (name) => {

        const scrollTop = window.scrollY;
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop(divtop1.offsetTop + scrollTopActual+30+"px")

        setFilterCol(name)
        setshowfilterpopup(true)
        if (name === 'type') {
            setfiltertext(t('UserList.DocumentType'))
            var testDiv = document.getElementById("type");
            setdivleft(testDiv.offsetLeft + "px");
            DropdownHandler(name)
        }
        if (name === 'document_id') {
            setfiltertext(t('UserList.LockedDocumentID/Name'))
            var testDiv = document.getElementById("document_id");
            setdivleft(testDiv.offsetLeft + "px");
        }
        if (name === 'lockeduser') {
            setfiltertext(t('UserList.LockedBy'))
            var testDiv = document.getElementById("lockeduser");
            setdivleft(testDiv.offsetLeft + "px");
            DropdownHandler(name)
        }


        setshowfilterpopup(true)
    }


    const DropdownHandler = (key) => {
        Service.getfilterDropdownByGroup('LockedDocuments/GetDropDownFilterValue', key).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {

                            addnewfield(res.data, key)
                        }
                    }
                }
            }

        });
    }

    const addSeletAll = (optionsArray, alreadySelected) => {

        let result = optionsArray;
        let selectAllObj = {
            "Name": "Select All",
            "Value": "Select All",
            "ischecked": alreadySelected
        }

        result.splice(0, 0, selectAllObj);
        console.log(result, "9999")
        return result;
    }

    const addnewfield = (data, key) => {

        let selectedKeys = multiFilterValue[key] ? multiFilterValue[key] : [];
        let currentdata = data.map((i) => {
            i["ischecked"] = false;
            return i;
        });

        console.log(currentdata, "test-data");
        let alreadySelected = selectedKeys.find((obj) => obj.Name === "Select All") ? true : false; if (key != 'status') {
            if (selectedKeys) {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }

                    })
                })
            }


            let result = addSeletAll(currentdata, alreadySelected);
            setDropdownData(result);
            setFilterDropdownData(result)
        }
        else {
            let alreadySelected = selectedKeys.find((obj) => obj.Name === "Select All") ? true : false;
            if (selectedKeys) {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }
                    })
                })
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
            else {
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
        }
    }


    const checkAllFilter = (mycheckbox) => {
        var lastfilter = [...dropdownData]
        let data1 = []
        var checkboxes = document.querySelectorAll("input[name = 'filter-option-all']");
        if (mycheckbox.target.checked == true) {

            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = true;
                return i;
            });
            setDropdownData(currentdata)

            dropdownData.map((each) => {

                const getallData = {
                    Name: each.Name,
                }
                data1.push(getallData)
            })
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {

            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = false;
                return i;
            });
            setFilterValue([])
            setMultiFilterValue([])
        }

    }
    const OndropdownChange = (e, id, name, filterCol) => {
        let isChecked = e.target.checked;
        let allChecked = false;


        let allCheckboxes = document.getElementsByName("filter-option-all");
        let totalChecked = 0;
        allCheckboxes.forEach((checkbox) => {
            if (checkbox.checked)
                totalChecked += 1;
        });

        let tempData = [...dropdownData];
        //If all checkboxes are checked
        if (tempData.length - 1 === totalChecked) {
            tempData[0].ischecked = isChecked;
            allChecked = true;
        }

        setDropdownData(tempData);

        console.log(tempData, "12x");

        var lastfilter = [...dropdownData]
        if (isChecked === true) {
            let currentdata = lastfilter.map((i) => {
                if (i.Id == id) {
                    i["ischecked"] = true;
                }
                return i;
            });

            setDropdownData(currentdata);
            let data1 = []
            const getallData = {
                Name: name,
            }
            data1.push(getallData)
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);

            if (allChecked) {
                const selectAllObj = {
                    "Name": "Select All"
                };
                updateddata.splice(0, 0, selectAllObj);
            }

            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {
            let currentdata = lastfilter.map((i) => {
                if (i.Id === id) {
                    i["ischecked"] = false;
                }
                return i;
            });

            console.log(currentdata, "c-data");

            setDropdownData(currentdata);
            setFilterValue(filterValue => filterValue.filter(data => data.Name !== name))
            // let data1 = multiFilterValue[filterCol].filter(data => data.Name !== name);

            let data1 = multiFilterValue[filterCol] || [];
            data1 = data1.filter((data) => data.Name !== name);

            if (tempData.length - 1 >= totalChecked) {
                data1 = data1.filter(data => data.Name !== "Select All");
            }

            console.log(data1, "data");

            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: data1,
            }));
        }
    }
    const UserfilterHandler = (e) => {

        setFilterSearch(e.target.value)
        if (e.target.value !== "") {
            let searchResults = dropdownData.filter((each) => {
                return each.Name.toLowerCase().includes(String(e.target.value).toLowerCase())
            });
            setDropdownData(searchResults);
        }
        else {

            setFilterSearch("")
            setDropdownData(filterdropdownData)
        }
    }
    const filterHandler = (flag) => {
        if (flag === 'Apply') {
            setshowfilterpopup(false)
            let list = [];

            for (var key in multiFilterValue) {
                if (multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
            getLockedDocumentsData("LockedDocuments/GetAll", paging.pageSize, paging.pageNumber, null, null,null,list);

            let tempDropdownData = dropdownData;
            if (tempDropdownData.length > 0){
                tempDropdownData[0]["ischecked"] = true;}
            setDropdownData(tempDropdownData);
        }
        else {
            setshowfilterpopup(false)
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: '',
            }));
            setFilterValueDict(() => ({
                ...filterValueDict, [filterCol]: '',
            }));

            let list = [];
            for (var key in multiFilterValue) {
                if (key !== filterCol && multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '' && key !== filterCol) {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
            getLockedDocumentsData("LockedDocuments/GetAll", paging.pageSize, paging.pageNumber, null, null, null,list);
            setFilterCol('')
            setFilterValue('')
            setDropdownData([])
            setFilterSearch("")
            setFilterDropdownData([])
        }
    }

    const SortColumn = (colName) => {
        var sortType = '';
        if (columnAscending === true) {
            setColumnAscending(false);
            sortType = "Descending";
        } else {
            setColumnAscending(true);
            sortType = "Ascending";
        }
        setSortColName(colName);

        let pagenumber = 1;

        paging.pageNumber = pagenumber;
        setpaging(paging);
        getLockedDocumentsData("LockedDocuments/GetAll", paging.pageSize, paging.pageNumber, sortType, colName, null,filterLst());
    }
    const LoadMore = (lst, totalcount) => {

        if (lst < totalcount) {
            setshowLoadMore(true);
        }
        else if (lst >= totalcount) {
            setshowLoadMore(false);
        }
        else {
            setshowLoadMore(false);
        }
    }

    const customMultiStyles = {
        multiselectContainer: {
            maxWidth: '188px',
            marginLeft: '3px'
        },
        chips: {
            borderRadius: '5px'
        }
    };
    let headers = [
        { label: "Locked Document Type", key: "type" },
        { label: "Locked Document ID/Name", key: "Document_id" },
        { label: "Locked By User", key: "LockedByUserName" },

    ];
    const filterLst = () => {
        let list = [];
        for (var key in multiFilterValue) {
            if (multiFilterValue[key].length > 0) {
                let values = ""
                multiFilterValue[key].map((i) => {
                    values += i.Name + ";";
                })
                if (values !== null && values !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: values
                    })
                }
            }
        }

        for (var key in filterValueDict) {
            if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                list.push({
                    filterKey: key,
                    FilterValue: filterValueDict[key]
                })
            }
        }
        return list;
    }
    const checkAll = (mycheckbox) => {
        const value = []
        const checkboxes = document.querySelectorAll("input[type='checkbox']");
        if (mycheckbox.target.checked) {
            settotalselectedshow(true);
            setAllCheckedHistory(true);
            checkboxes.forEach(function (checkbox) {
                let idParts = checkbox.id.split(';');
                let firstPart = idParts[0];
                checkbox.checked = true;
                if (!isNaN(parseInt(firstPart))) {
                    value.push(checkbox.id);
                }
            });

            settotalselectedcount(totalCountlst);
            setCheckAllChecked(true); // Set state to manage Check All checkbox
            setCheckedValueids([...checkedValueids, ...value]);
        } else {
            settotalselectedshow(false);
            setAllCheckedHistory(false);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = false;
            });
            settotalselectedcount(0);
            setCheckAllChecked(false); // Set state to manage Check All checkbox
            setCheckedValueids([])
        }
    };
    return (
        <div className="container-fluid content-top-gap">


            <nav aria-label="breadcrumb">
                <ol className="breadcrumb my-breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
                    <li className="breadcrumb-item"><Link to="/admin">{t('Menu.Admin')}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{t('UserList.LockedDocuments')}</li>
                </ol>
            </nav>

            <div style={{ display: "none" }}>
                <CSVLink data={csvList} headers={headers} ref={csvInstance} filename="lockeddocuments.csv" > </CSVLink>
            </div>

            <div className="">
                <div className="row">
                    <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border overflow-auto">

                            <div className="card-body" style={{ minHeight: "70vh" }}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className='d-flex align-items-center mb-1'>
                                            <input type="text" className="form-control form-control-user col-md-10" id="firstName" name="firstName" autoComplete='off' placeholder={t('Search for Locked Document ID, Document Type or Locked By')} onChange={(event) => searchHandler(event)} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className='d-flex'>
                                            <div className="col-md-12 d-flex justify-content-end align-items-center" style={{ marginLeft: totalCountlst > 16 ? '0.6rem' : '0.75rem' }}>
                                                <div style={{ display: 'inline-block', marginRight: '10px' }}>
                                                    {totalselectedcount > 0 && (
                                                        <>
                                                            {Service.editPerms(userPerms) !== false && (
                                                                <button className="btn btn-update btn-sm ml-2" type="submit" style={{ width: "55px" }} onClick={UnlockHandler}>{t('UnLock')}</button>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="scrollbar-class mb-1">
                                    <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableA">
                                        <thead className="text-white is-sticky-thead-product" style={{ backgroundColor: "var(--primary)" }}>
                                            <tr style={{ position: "sticky", top: "0", backgroundColor: "var(--primary)" }}>
                                                <th className="tableheader" style={{ width: '30px' }}>
                                                    <input
                                                        type="checkbox"
                                                        onChange={checkAll}
                                                        checked={checkAllChecked}
                                                        id="option-all"
                                                    />
                                                </th>
                                                <th id="type" style={{ backgroundColor: (filterValueDict.type ? '#6cae55' : '') }}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('UserList.DocumentType')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('type')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('type')}></span>
                                                    </div>
                                                </th>
                                                <th id="document_id" style={{ backgroundColor: (filterValueDict.document_id ? '#6cae55' : '') }}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('UserList.LockedDocumentID/Name')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Document_id')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('document_id')}></span>
                                                    </div>
                                                </th>
                                                <th id="lockeduser" style={{ backgroundColor: (multiFilterValue.lockeduser ? '#6cae55' : '') }}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('UserList.LockedBy')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('LockedByUserName')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('lockeduser')}></span>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                showspinner === true ?

                                                    <>
                                                        <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                    </>
                                                    :
                                                    filteredArray.length === 0 ?
                                                        <tr><td></td>
                                                            <td colSpan="9" style={{ textAlign: 'center' }}>
                                                                {t('NoDataAvailable')}
                                                            </td>
                                                        </tr>
                                                        :
                                                        filteredArray.map((listVal, index) =>
                                                            <tr key={listVal.Rebate_id}>
                                                                <td>
                                                                    <input id={[listVal.Id + ";" + listVal.type + ";"]} type="checkbox" name="chkBox" value={listVal.Rebate_id} onChange={(event) => handleChange(event, listVal.Id, listVal)} />
                                                                </td>
                                                                <td>{documentTypeMatching[listVal.type] || listVal.type}</td>
                                                                <td>{listVal.Document_id === 'TestPermissionPolicyView' ? 'Test Permission Policy View' : listVal.Document_id}</td>
                                                                <td>{listVal.LockedByUserName}</td>

                                                            </tr>
                                                        )

                                            }

                                        </tbody>
                                    </table>
                                </div>

                               
                                <div className='row'>
                                    {
                                        totalselectedshow === true ?

                                            <div className='col-lg-2 forgettext col-sm-12 border form-control' style={{ textAlign: "center", marginLeft: "0.938rem" }}>
                                                {t('TotalRecordsSelected')} - <span id="totalselected">{totalselectedcount}</span>
                                            </div>

                                            :
                                            <div className='col-lg-2 col-sm-12' style={{ textAlign: "center", marginLeft: "0.938rem" }}>

                                            </div>
                                    }



                                    <div style={{flex: `0 0 ${totalCountlst > 16 ? "62.5%" : "63%"}`,maxWidth: totalCountlst > 16 ? "62.5%" : "63%"}}></div>


                                    <div className='col-lg-2 forgettext  col-sm-12 border form-control' style={{ textAlign: "center",marginLeft: "0.1rem"  }}>
                                        {t('TotalRecords')} - {totalCountlst}
                                    </div>
                                </div>
                            </div>
                            {
                                    showLoadMore === true ?
                                        <div className='text-center container row mb-1'>
                                            <div className='col-md-5'></div>
                                            <div className='col-md-2'>
                                                <button className='btn btn-sm btn-update ml-1' onClick={handleLoadMore} style={{ width: "70px" }}>{t('LoadMore')}</button>
                                            </div>
                                            <div className='col-md-5'></div>

                                        </div>
                                        :
                                        ""
                                }

                            {showfilterpopup === true ?
                                <div className='pop-up' style={{ left: divleft, top: divtop }}>

                                    {filterCol === 'type' || filterCol === 'document_id' ?
                                        <>
                                            <div className="container">
                                                <div className="row">
                                                    <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                </div>
                                            </div>
                                            <div className="container mt-2">
                                                <div className="row">
                                                    <input type='textbox' placeholder={t('Search')} className='filtertextboxbackground col-md-11 textbox-padding ml-2' style={{ outline: 'none' }} value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                                </div>
                                            </div>
                                        </>

                                        :
                                        <>
                                        </>

                                    }


                                    {filterCol === 'lockeduser' ?

                                        <>
                                            <div className="container">
                                                <div className="row">
                                                    <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>

                                                </div>

                                            </div>

                                            <div className="container mt-2">
                                                <div className="row">
                                                    <input type="text" placeholder={t('Search')} value={filterSearch} onChange={(event) => UserfilterHandler(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                                                </div>

                                                <div className="row contentoverflow mt-2">
                                                    <table className="filtertable">
                                                        <tbody>
                                                            {/* <tr>
                                                                <td><input type="checkbox" name="filter-option-all" id="option-all" onChange={checkAllFilter} /></td>
                                                                <td>{t('SelectAll')}</td>
                                                            </tr> */}
                                                            {
                                                                dropdownData.map((item, index) => (

                                                                    <tr key={index}>

                                                                        <td><input id={item.Id} data-index="1" name="filter-option-all" checked={item.ischecked} type="checkbox" onChange={item.Name === "Select All" ? checkAllFilter : (event) => OndropdownChange(event, item.Id, item.Name, filterCol)} /></td>

                                                                        <td>{item.Name}</td>

                                                                    </tr>


                                                                ))
                                                            }

                                                        </tbody>
                                                    </table>

                                                </div>
                                            </div>
                                        </>
                                        :
                                        <></>
                                    }
                                    <div style={{ paddingTop: '10px', paddingLeft: '47px', paddingBottom: '7px' }}>
                                        <button className="btn btn-sm btn-update" onClick={() => filterHandler('Apply')} style={{ width: "45px" }}>{t('Apply')}</button>
                                        <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "45px" }} onClick={() => filterHandler('Reset')}>{t('Reset')}</button>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                                :
                                <></>
                            }

                        </div>
                        <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                            onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
                    </div>

                </div>
            </div>

        </div >


    )
}
export default LockedDocumentsComponent;

