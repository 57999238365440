import axios from 'axios';
import $ from 'jquery';
import SessionManage from '../SessionManagement/SessionManage';
//const REBATE_API_BASE_URL = "https://localhost:7096/api/";
//const REBATE_BASE_URL = "https://localhost:7096/";
//dont remove below  lines
//const REBATE_BASE_URL = "http://15.207.68.142:8800/";
//const REBATE_API_BASE_URL = "http://15.207.68.142:8800/api/";
// const REBATE_BASE_URL = "http://192.168.0.201:8800/";
// const REBATE_API_BASE_URL = "http://192.168.0.201:8800/api/";
//const REBATE_BASE_URL = "https://apirebatedemo.qualitechgroup.in/";
//const REBATE_API_BASE_URL = "https://apirebatedemo.qualitechgroup.in/api/";
 const REBATE_BASE_URL = "https://apirebatedemo.qualitechsolution.com/";
 const REBATE_API_BASE_URL = "https://apirebatedemo.qualitechsolution.com/api/";

const headers = {
    'Content-Type': 'application/json',
    'loggedInUser': SessionManage.getuserid(),
    'Authorization': `Bearer ${SessionManage.gettokenid()}`
}

class RebateService {

    getBaseUrl() {
        return REBATE_BASE_URL;
    }
    async getdata(method) {
        try {

            const res = await axios.get(REBATE_API_BASE_URL + method.trim(), {
                headers: headers
            }).then(response => {
                return response;
            }).catch(error => {

            });
            return res;
        } catch (err) {

        }
    }
    async getGroupData(method, fiscalYear) {
        debugger;
        try {

            const res = await axios.get(REBATE_API_BASE_URL + method.trim() + "?fiscalYear=" + fiscalYear, {
                headers: headers
            }).then(response => {
                return response;
            }).catch(error => {

            });
            return res;
        } catch (err) {

        }
    }
    async getPolicydata(method) {
        try {

            const res = await axios.get(REBATE_BASE_URL + method.trim(), { headers: headers }).
                then(response => {

                    return response;
                }).catch(error => {

                });
            return res;
        } catch (err) {

        }
    }

    
    async getPolicydata(method) {
        try {

            const res = await axios.get(REBATE_BASE_URL + method.trim()).
                then(response => {
                    return response;
                }).catch(error => {

            });
            return res;
        } catch (err) {

        }
    }
    async getErpLoginToken(method) {
        try {
            const res = await axios.post(REBATE_API_BASE_URL + method).
                then(response => {
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async getdatawithPagingAccruals(method, pageSize, pageNumber, col, value) {
        try {

            const res = await axios.get(REBATE_API_BASE_URL + method.trim() + "?pageSize=" + pageSize + "&pageNumber=" + pageNumber + "&filterKey=" + col + "&FilterValue=" + value, { headers: headers }).
                then(response => {

                    return response;
                }).catch(error => {

                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {

            alert('Internal Server Error');
        }
    }

    async getdataWithPaging(method, pageSize, pageNumber, sortType, SortProperty, col, value) {
        try {

            const res = await axios.get(REBATE_API_BASE_URL + method.trim() + "?pageSize=" + pageSize + "&pageNumber=" + pageNumber + "&sortType=" + sortType + "&SortProperty=" + SortProperty + "&filterKey=" + col + "&FilterValue=" + value, { headers: headers }).
                then(response => {
                    console.log(response);

                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async getDatawithMultiFilter(method, pageSize, pageNumber, sortType, SortProperty, filterVal) {
        let filterValue = {
            objFilterValue: filterVal
        }
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method.trim() + "?pageSize=" + pageSize + "&pageNumber=" + pageNumber + "&sortType=" + sortType + "&SortProperty=" + SortProperty + "&groupids=" + this.getGroupIds(), filterValue, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }



    async getUserData(method, pageSize, pageNumber, sortType, SortProperty, filterVal) {
        let filterValue = {
            objFilterValue: filterVal
        }
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method.trim() + "?pageSize=" + pageSize + "&pageNumber=" + pageNumber + "&sortType=" + sortType + "&SortProperty=" + SortProperty + "&groupids=" + this.getGroupIds(), filterValue, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async getfilterDropdown(method, filterKey) {
        try {

            const res = await axios.get(REBATE_API_BASE_URL + method.trim() + "?filterKey=" + filterKey, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }
    async getAttributeValue(method, Attribute) {
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method.trim() + "?Attribute=" + Attribute, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async getRebatePolicyfilterDropdown(method, filterKey) {
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method.trim() + "?filterKey=" + filterKey, '', { headers: headers }).
                then(response => {
                    console.log(response);

                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async getAccrualProductfilterDropdown(method, Id) {
        try {

            const res = await axios.get(REBATE_API_BASE_URL + method.trim() + "?Id=" + Id, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async getdataWithPagingandSearching(method, search, pageSize, pageNumber) {
        try {

            const res = await axios.get(REBATE_API_BASE_URL + method.trim() + "?pageSize=" + pageSize + "&pageNumber=" + pageNumber + "&search=" + search, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async getdataWithPagingandattributeSearching(method, search, pageSize, pageNumber, attributetype) {
        try {

            const res = await axios.get(REBATE_API_BASE_URL + method.trim() + "?pageSize=" + pageSize + "&pageNumber=" + pageNumber + "&search=" + search + "&attributeid=" + attributetype, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async createorupdate(method, value) {
        try {
            const res = await axios.post(REBATE_API_BASE_URL + method, value, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }
    async createorupdateCashDiscount(method, flag, value) {
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method.trim() + "?flag=" + flag, value, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async submitCashDiscount(method, comments, value) {
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method.trim() + "?comments=" + comments, value, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async getdatabyId(method, Id) {
        try {
            // console.log(REBATE_API_BASE_URL + method + "?Id=" + Id, "data");
            const res = await axios.get(REBATE_API_BASE_URL + method + "?Id=" + Id, { headers: headers }).
                then(response => {
                    console.log("service file", response);
                    return response;
                }).catch(error => {
                    console.log("service file error", error);
                    console.log(error);

                });
            console.log("19-getbyidresult", res);
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);

        }
    }

    async getDistributorGroupMappingPolicybyId(method, Id, policyStatus, userId) {
        try {
            debugger;
            const res = await axios.get(REBATE_API_BASE_URL + method + "?Id=" + Id + "&policyStatus=" + policyStatus + "&userId=" + userId, { headers: headers }).
                then(response => {
                    console.log("service file", response);
                    return response;
                }).catch(error => {
                    console.log("service file error", error);
                    console.log(error);

                });
            console.log("19-getbyidresult", res);
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);

        }
    }

    async getdatabyIdAndData(method, Id, fiscalYear, data) {
        try {
            //   let encodedFiscalYear  = encodeURIComponent(fiscalYear)
            //debugger
            console.log(REBATE_API_BASE_URL + method + "?id=" + Id + "&fiscalYear=" + fiscalYear, data, 'Api', typeof (fiscalYear));
            const res = await axios.post(REBATE_API_BASE_URL + method + "?id=" + Id + "&fiscalYear=" + fiscalYear, data, { headers: headers }).
                then(response => {
                    console.log("service file", response);
                    return response;
                }).catch(error => {
                    console.log("service file error", error);
                    console.log(error);

                });
            console.log("19-getbyidresult", res);
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);

        }
    }




    async getAttributebyId(method, Id, key) {
        try {

            const res = await axios.get(REBATE_API_BASE_URL + method + "?id=" + Id + "&category=" + key, { headers: headers }).
                then(response => {
                    console.log("service file", response);
                    return response;
                }).catch(error => {
                    console.log("service file error", error);
                    console.log(error);

                });
            console.log("result", res);
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);

        }
    }

    async getregionbyId(method, Id) {
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method, '', { headers: headers }).
                then(response => {
                    console.log("service file", response);
                    return response;
                }).catch(error => {
                    console.log("service file error", error);
                    console.log(error);
                });
            console.log("result", res);
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
        }
    }


    async getdatabyData(method, customername, productname, startdate, enddate, rebateProgramLineId, accrualId, accrualItemsId, pageSize, pageNumber, sortType, SortProperty, filterVal) {
        let filterValue = {
            objFilterValue: filterVal
        }
        try {
            const res = await axios.post(REBATE_API_BASE_URL + method + "?customername=" + customername + "&productname=" + productname + "&startdate=" + startdate + "&enddate=" + enddate + "&Id=" + rebateProgramLineId + "&accrualId=" + accrualId + "&accrualItemsId=" + accrualItemsId + "&pageSize=" + pageSize + "&pageNumber=" + pageNumber + "&sortType=" + sortType + "&SortProperty=" + SortProperty, filterValue, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async getrebatebydesc(method, desc, rebateId) {
        try {
            const res = await axios.get(REBATE_API_BASE_URL + method + "?desc=" + desc + "&RebateId=" + rebateId, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async getdatabyIdWithPaging(method, Id, pageSize, pageNumber) {
        try {

            const res = await axios.get(REBATE_API_BASE_URL + method + "?Id=" + Id + "&pageSize=" + pageSize + "&pageNumber=" + pageNumber, { headers: headers }).
                then(response => {
                    console.log("service file", response);
                    return response;
                }).catch(error => {
                    console.log("service file error", error);
                    alert('Internal Server Error');
                    console.log(error);
                });
            console.log("result", res);
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async delete(method, Id) {
        try {
            console.log("17-6", REBATE_API_BASE_URL + method + "?Id=" + Id, '', { headers: headers })
            const res = await axios.post(REBATE_API_BASE_URL + method + "?Id=" + Id, '', { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async put(method, Id) {
        try {
            const res = await axios.put(REBATE_API_BASE_URL + method + "/" + Id + "/").
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            console.log(res)
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async putWithData(method, Id, inputData) {
        try {
            $.ajax({
                async: false,
                type: "PUT",
                url: REBATE_API_BASE_URL + method + "/" + Id + "/",
                data: inputData,
                dataType: "json",
                success: function (response) {
                    console.log(response)
                    return response;
                },
                error: function (response) {
                    console.log("Error while saving the record")
                    alert('Internal Server Error');
                    return false;
                }
            });
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async postWithData(method, Id, inputData) {
        try {

            // const res = await axios.post(REBATE_API_BASE_URL + method + "?rebateprogramid=" + Id, { headers: headers }).
            const res = await axios.post(REBATE_API_BASE_URL + method + "?rebateprogramid=" + Id,'',{headers: headers}).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            console.log(res)
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async postWithlineIdanduserId(method, Id, user_id) {
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method + "?rebateprogramid=" + Id + "&user_id=" + user_id,'', { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            console.log(res)
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async deletewithType(method, Id, data) {
        try {
            const res = await axios({
                method: 'put',
                url: REBATE_API_BASE_URL + method + "/" + Id + "/",
                data: data
            }).
                then(response => {
                    console.log(response);
                }).catch(error => {
                    alert('Internal Server Error');
                    console.log(error);
                });

            return res;
        } catch (err) {
            alert('Internal Server Error');
            console.log(`Error: ${err?.response?.data}`);
        }
    }

    async getAlldata(method) {
        try {
            const res = await axios.get(REBATE_API_BASE_URL + method.trim() + "/GetAll", { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }
    async getAllDaysduedata(method) {
        try {

            const res = await axios.get(REBATE_API_BASE_URL + method.trim() + "/GetALLDaysDue", { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }
    async getAllUserData(method, massActionId, sortType, SortProperty, filterVal, search) {
        let filterValue = {
            objFilterValue: filterVal
        }
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method.trim() + "?massActionId=" + massActionId + "&sortType=" + sortType + "&SortProperty=" + SortProperty + "&search=" + search, filterValue, '', { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }
    async getFiscalYear(method) {
        try {

            const res = await axios.get(REBATE_API_BASE_URL + method.trim() + "/GetFiscalYear", { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async getAllPolicydata(method, pagesize, pageNumber, sortType, SortProperty, filterVal) {
        let filterValue = {
            objFilterValue: filterVal
        }
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method.trim() + "/GetAll_Policy" + "?pageSize=" + pagesize + "&pageNumber=" + pageNumber + "&sortType=" + sortType + "&SortProperty=" + SortProperty, filterValue, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }
    async getAccrualItemsSortBy(method, Id, sortType, SortProperty, filterVal, type,fiscalYear) {
        let filterValue = {
            objFilterValue: filterVal
        }
        try {
            console.log("11-6 19-2", REBATE_API_BASE_URL + method.trim() + "?Id=" + Id + "&sortType=" + sortType + "&SortProperty=" + SortProperty + "&type=" + type + "&fiscalYear=" + fiscalYear, filterValue, { headers: headers })
            const res = await axios.post(REBATE_API_BASE_URL + method.trim() + "?Id=" + Id + "&sortType=" + sortType + "&SortProperty=" + SortProperty + "&type=" + type + "&fiscalYear=" + fiscalYear, filterValue, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async getdatabyRebateId(method, Id, pagesize, pageNumber, sortType, SortProperty, col, value) {
        try {

            const res = await axios.get(REBATE_API_BASE_URL + "rebate/" + method + "?RebateID=" + Id + "&pageSize=" + pagesize + "&pageNumber=" + pageNumber + "&sortType=" + sortType + "&SortProperty=" + SortProperty + "&filterKey=" + col + "&FilterValue=" + value, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async getPostdatabyRebateId(method, Id, pagesize, pageNumber, sortType, SortProperty, value) {
        let filtervalue = {
            objFilterValue: value
        }
        try {

            const res = await axios.post(REBATE_API_BASE_URL + "rebate/" + method + "?RebateID=" + Id + "&pageSize=" + pagesize + "&pageNumber=" + pageNumber + "&sortType=" + sortType + "&SortProperty=" + SortProperty, filtervalue, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }


   async getdatabyRebateProgramId(method, Id, type) {
        try {
            console.log("16-3 rebate", REBATE_API_BASE_URL + "rebate/" + method + "/RebateprogramlineId?RebateprogramlineId=" + Id + "&type=" + type, { headers: headers })
            const res = await axios.get(REBATE_API_BASE_URL + "rebate/" + method + "/RebateprogramlineId?RebateprogramlineId=" + Id + "&type=" + type, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }
    async getdatabyschemeProgramId(method, Id, type) {
        try {
            console.log("16-3", REBATE_API_BASE_URL + "scheme/" + method + "/RebateprogramlineId?RebateprogramlineId=" + Id + "&type=" + type, { headers: headers })
            const res = await axios.get(REBATE_API_BASE_URL + "scheme/" + method + "/RebateprogramlineId?RebateprogramlineId=" + Id + "&type=" + type, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }
    async postdata(method) {
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method.trim(), '', { headers: headers }).
                then(response => {
                    console.log("1506/2.ress", response);
                    return response;
                }).catch(error => {
                    console.log("1506/2.ress", error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async createorupdateImportproduct(method, data) {

        try {

            const config = {
                headers: headers
            }
            console.log("11-3", REBATE_API_BASE_URL + method, data);
            const res = await axios.post(REBATE_API_BASE_URL + method, data, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async createorupdateImport(method, value) {

        try {

            const config = {
                headers: headers
            }
            const res = await axios.post(REBATE_API_BASE_URL + method, value, config).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }
    async create_or_update(method, id, value,type) {
        try {


            var groupIds = this.getGroupIds();

            const res = await axios.post(REBATE_API_BASE_URL + "Rebate/" + method + "?RebateHeaderId=" + id + '&groupIds=' + groupIds + '&type=' + type, value, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }
    async importPolicy(method, value) {
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method, value, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async create_or_updateScheme(method, id, value, type) {
        try {


            var groupIds = this.getGroupIds();

            const res = await axios.post(REBATE_API_BASE_URL + "Scheme/" + method + "?RebateHeaderId=" + id + '&groupIds=' + groupIds + '&Type=' + type, value, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async UpdateProgramlineStatus(method, ProgramlineId, status, userid, comments) {
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method + "?RebateProgramlineId=" + ProgramlineId + "&Status=" + status + "&userId=" + userid + "&comments=" + comments, '', { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            console.log(res)
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }
    async UpdateMassProgramlineStatus(method, value, IsApproved) {
        try {
            const config = {
                headers: headers
            }
            const res = await axios.post(REBATE_API_BASE_URL + method + "?IsApproved=" + IsApproved, value, config).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            console.log(res)
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }
    async UpdateMassProgramlineStatus(method, value, IsApproved) {
        try {
            const config = {
                headers: headers
            }
            const res = await axios.post(REBATE_API_BASE_URL + method + "?IsApproved=" + IsApproved, value, config).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            console.log(res)
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }
    async getSalesOrgbyCustId(method, Id) {
        try {

            const res = await axios.post(REBATE_API_BASE_URL + "rebate/" + method + "?Customer_Id=" + Id, '', { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }



    async postDataWithValue(method, value) {
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method, value, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }


    async PostWithIdAndUser(method, Id, loggedInUser) {
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method + "?Id=" + Id + "&userId=" + loggedInUser, '', { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            console.log(res)
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }


    async deleteDataByRebateID_(method, Id, loggedInUser) {
        try {
            $.ajax({
                async: false,
                type: "PUT",
                url: REBATE_API_BASE_URL + method + "?userid=" + loggedInUser,
                data: "[" + Id + "]",
                dataType: "json",
                success: function (response) {
                    console.log(response)
                    return response;
                },
                error: function (response) {
                    console.log("Error while saving the record")
                    alert('Internal Server Error');
                    return false;
                }
            });
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }



    async deleteDataByRebateID(method, Id, loggedInUser) {
        try {


            const res = await axios.post(REBATE_API_BASE_URL + method + "?RebateID=" + Id + "&userid=" + loggedInUser, "[" + Id + "]", { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async deleteDataByPolicyID(method, Id, loggedInUser) {
        try {


            const res = await axios.post(REBATE_API_BASE_URL + method + "?Id=" + Id + "&userid=" + loggedInUser, '', { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async deleteDataBySDId(method, Id, loggedInUser) {
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method + "?Id=" + Id + "&loggedInUserId=" + loggedInUser, '', { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async EndDatebyId(method, Id, loggedInUser, date) {
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method + "?Id=" + Id + "&loggedInUserId=" + loggedInUser + "&date=" + date, '', { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async updatedatabyRebateProgramId(method, Id, userId) {
        try {

            const res = await axios.get(REBATE_API_BASE_URL + method + "?RebateProgramLineid=" + Id + "&Userid=" + userId, { headers: headers }).
                then(response => {
                    console.log("service file", response);
                    return response;
                }).catch(error => {
                    console.log("service file error", error);
                    console.log(error);
                    alert('Internal Server Error');
                });
            console.log("result", res);
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }
    async updatedatabySchemeProgramId(method, Id, userId) {
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method + "?RebateID=" + Id + "&userid=" + userId, { headers: headers }).
                then(response => {
                    console.log("service file", response);
                    return response;
                }).catch(error => {
                    console.log("service file error", error);
                    console.log(error);
                    alert('Internal Server Error');
                });
            console.log("result", res);
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }



    async databyRenew(method, userId, flag, renewData) {
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method + "?userid=" + userId + "&flag=" + flag, renewData, '', { headers: headers }).
                then(response => {
                    console.log("service file", response);
                    return response;
                }).catch(error => {
                    console.log("service file error", error);
                    console.log(error);
                    alert('Internal Server Error');
                });
            console.log("result", res);
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async workflowData(method, accrualId, userid, rebateprogramlineId) {
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method + "?accrualId=" + accrualId + "&userid=" + userid + "&rebateprogramlineId=" + rebateprogramlineId, '', { headers: headers }).
                then(response => {
                    console.log("service file", response);
                    return response;
                }).catch(error => {
                    console.log("service file error", error);
                    console.log(error);
                    alert('Internal Server Error');
                });
            console.log("result", res);
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async workflowDataRebate(method, rebateprogramlineId, userid) {
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method + "?rebateprogramlineId=" + rebateprogramlineId + "&userid=" + userid, '',
                {
                    headers: headers
                }).
                then(response => {
                    console.log("service file", response);
                    return response;
                }).catch(error => {
                    console.log("service file error", error);
                    console.log(error);
                    alert('Internal Server Error');
                });
            console.log("result", res);
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async workflowDataExcep(method, ExceptionId, userid) {
        try {
            const res = await axios.post(REBATE_API_BASE_URL + method + "?ExceptionId=" + ExceptionId + "&userid=" + userid, '',
                {
                    headers: headers

                }).then(response => {
                    console.log("service file", response);
                    return response;
                }).catch(error => {
                    console.log("service file error", error);
                    console.log(error);
                    alert('Internal Server Error');
                });
            console.log("result", res);
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async workflowDataDistributor(method, DistributorBudget, userid) {
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method + "?DistributorBudgetId=" + DistributorBudget + "&userid=" + userid, '', { headers: headers }).
                then(response => {
                    console.log("service file", response);
                    return response;
                }).catch(error => {
                    console.log("service file error", error);
                    console.log(error);
                    alert('Internal Server Error');
                });
            console.log("result", res);
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async workflowDataSd(method, AgreementId, userid) {
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method + "?AgreementId=" + AgreementId + "&userid=" + userid, '', { headers: headers }).
                then(response => {
                    console.log("service file", response);
                    return response;
                }).catch(error => {
                    console.log("service file error", error);
                    console.log(error);
                    alert('Internal Server Error');
                });
            console.log("result", res);
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async workflowDataConcile(method, concilationId, userid) {
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method + "?concilationId=" + concilationId + "&userid=" + userid, '', { headers: headers }).
                then(response => {
                    console.log("service file", response);
                    return response;
                }).catch(error => {
                    console.log("service file error", error);
                    console.log(error);
                    alert('Internal Server Error');
                });
            console.log("result", res);
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }


    async workflowDataClaim(method, ClaimId, userid) {
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method + "?ClaimId=" + ClaimId + "&userid=" + userid, '', { headers: headers }).
                then(response => {
                    console.log("service file", response);
                    return response;
                }).catch(error => {
                    console.log("service file error", error);
                    console.log(error);
                    alert('Internal Server Error');
                });
            console.log("result", res);
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async databyMassActions(method, Data) {
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method, Data, { headers: headers }
            ).then(response => {
                console.log("service file", response);
                return response;
            }).catch(error => {
                console.log("service file error", error);
                console.log(error);
                alert('Internal Server Error');
            });
            console.log("result", res);
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }



    async DeleteCategoryException(method, Id, userId) {
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method + "?Id=" + Id + "&Userid=" + userId, { headers: headers }).
                then(response => {
                    console.log("service file", response);
                    return response;
                }).catch(error => {
                    console.log("service file error", error);
                    console.log(error);
                    alert('Internal Server Error');
                });
            console.log("result", res);
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async SubmitRebateProgramLine(method, rebateHeaderId, status, userid, comments, type, value) {
        try {


            const res = await axios.post(REBATE_API_BASE_URL + method + "?RebateHeaderId=" + rebateHeaderId + "&status=" + status + "&userid=" + userid + "&comments=" + comments + "&type=" + type, value, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async copybyRebateProgramId(method, RebateHeaderID, ProgramLineID, userId) {
        try {

            const res = await axios.get(REBATE_API_BASE_URL + method + "?RebateHeaderID=" + RebateHeaderID + "&ProgramLineID=" + ProgramLineID + "&userId=" + userId, { headers: headers }).
                then(response => {
                    console.log("service file", response);
                    return response;
                }).catch(error => {
                    console.log("service file error", error);
                    alert('Internal Server Error');
                    console.log(error);
                });
            console.log("result", res);
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }
    async getdatabyRebId(method, Id) {
        try {

            const res = await axios.get(REBATE_API_BASE_URL + "rebate/" + method + "?RebateID=" + Id, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }
    async getproductbyLabel(method, label, id, pagesize, pagenumber) {
        try {

            const res = await axios.get(REBATE_API_BASE_URL + method + "?productname=" + label + "&RebateHeaderId=" + id + "&pageSize=" + pagesize + "&pageNumber=" + pagenumber, { headers: headers }).
                then(response => {
                    console.log(" search service file", response);
                    return response;
                }).catch(error => {
                    console.log("service file error", error);
                    alert('Internal Server Error');
                    console.log(error);
                });
            console.log("result", res);
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async activateRebateProgram(method, headerId, programLineId, userId) {
        try {

            const res = await axios.get(REBATE_API_BASE_URL + method + "?RebateHeaderId=" + headerId + "&RebateProgramLineid=" + programLineId + "&Userid=" + userId, { headers: headers }).
                then(response => {
                    console.log("service file", response);
                    return response;
                }).catch(error => {
                    console.log("service file error", error);
                    alert('Internal Server Error');
                    console.log(error);
                });
            console.log("result", res);
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async ToggleUserActiveStatus(method, Id, user_id) {
        try {

            const res = await axios.post(REBATE_API_BASE_URL + "Users/" + method + "?id=" + Id + "&Userid=" + user_id, '', { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async TogglePolicyActiveStatus(method, Id, user_id) {
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method + "?id=" + Id + "&Userid=" + user_id, '', { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async CreateOrUpdatePermission(method, roleid, moduleid, actionname) {

        const payload = {
            RolesId: roleid,
            ModuleId: moduleid,
            ActionName: '' + actionname + '',
            Method: '' + method + '',
        }

        try {

            const res = await axios({
                method: 'post',
                url: REBATE_API_BASE_URL + 'Roles/CreateOrUpdatePermission',
                data: payload, // you are sending body instead
                headers: {
                    headers
                },
            }).
                then(response => {
                    console.log(response);

                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async GetAllMenu(method, id) {
        try {

            const res = await axios({
                method: 'get',
                url: REBATE_API_BASE_URL + method + '?id=' + id, headers:
                    headers
            }).then(response => {
                console.log(response);
                return response;
            }).catch(error => {
                console.log(error);
                alert('Internal Server Error');
            });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }


    async GetUserPermissionNew(name) {
        try {
            var roleid = SessionManage.getroleid();
            const res = await axios({
                method: 'get',
                url: REBATE_API_BASE_URL + 'Permission/GetUserPermission?roleid=' + roleid + '&name=' + encodeURIComponent(name),
                headers: {
                    headers
                },
            }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }
    async GetUserPermission(pathname) {
        try {

            var roleid = SessionManage.getroleid();
            const res = await axios({
                method: 'get',
                url: REBATE_API_BASE_URL + 'Permission/GetUserPermissionByPathName?roleid=' + roleid + '&pathname=' + pathname,
                headers: headers
            }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            console.log("27-6", res)
            //debugger
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async GetUserPermissionByUrl(pathname, fullpath) {
        try {
            var roleid = SessionManage.getroleid();
            const res = await axios({
                method: 'get',
                url: REBATE_API_BASE_URL + 'Permission/GetUserPermissionByUrl?roleid=' + roleid + '&pathname=' + pathname + '&fullpath=' + fullpath,
                headers: headers
            }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async GetUserPermissionByName(method, roleid, menuname, action) {
        try {

            const res = await axios({
                method: 'get',
                url: REBATE_API_BASE_URL + method + '?roleid=' + roleid + '&menuname=' + menuname + '&action_name=' + action,
                headers: {
                    headers
                },
            }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }


    async TogglePermissionActiveStatus(method, Id, user_id) {
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method + "?id=" + Id + "&Userid=" + user_id, '', { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }


    async CreatePermissionSet(method, Label, Name, ModulesView, PermissionId, userid) {

        const createPermissionset = {

            ModulesView: ModulesView,
            Label: Label,
            Name: Name,
            PermissionId: PermissionId,
            UserId: userid
        }

        try {
            const res = await axios({
                method: 'post',
                url: REBATE_API_BASE_URL + method, headers: headers,
                data: JSON.stringify(
                    createPermissionset),
            }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }
    editPerms(userPermission) {

        if (typeof userPermission !== 'undefined') {
            if (userPermission.filter((item) => item.ActionName === 'Edit' || item.ActionName === 'Submit').length == 0) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }
    submitPerms(userPermission) {
        if (typeof userPermission !== 'undefined') {
            if (userPermission.filter((item) => item.ActionName === 'Submit').length == 0) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }
    submitRebatePerms(userPermission) {
        if (typeof userPermission !== 'undefined') {
            if (userPermission.filter((item) => item.ActionName === 'Submit').length == 0) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }
    getRoleId() {
        let roleId = SessionManage.getroleid();
        if (roleId && roleId != undefined) {
            return roleId;
        } else {
            return 0;
        }
    }

    async getdatabyname(method, name) {
        try {

            const res = await axios.get(REBATE_API_BASE_URL + method + "?name=" + name, { headers: headers }).
                then(response => {
                    console.log("service file", response);
                    return response;
                }).catch(error => {
                    console.log("service file error", error);
                    console.log(error);
                    alert('Internal Server Error');
                });
            console.log("result", res);
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async getdatabyDistId(method, type) {
        try {
            var groupIds = this.getGroupIds();
            const res = await axios.get(REBATE_API_BASE_URL + method + "?distributorId=" + this.getUserId() + '&type=' + encodeURIComponent(type) + '&groupIds=' + groupIds, { headers: headers }).
                then(response => {
                    console.log("service file", response);
                    return response;
                }).catch(error => {
                    console.log("service file error", error);
                    console.log(error);
                    alert('Internal Server Error');
                });
            console.log("result", res);
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async getdatabyDistDetails(method, id, type) {
        try {

            const res = await axios.get(REBATE_API_BASE_URL + method + "?agreementId=" + id + '&type=' + encodeURIComponent(type), { headers: headers }).
                then(response => {
                    console.log("service file", response);
                    return response;
                }).catch(error => {
                    console.log("service file error", error);
                    console.log(error);
                    alert('Internal Server Error');
                });
            console.log("result", res);
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async getdatabyProduct(method, agreementId, productId, type) {
        try {

            const res = await axios.get(REBATE_API_BASE_URL + method + "?agreementId=" + agreementId + "&productId=" + productId + "&type=" + encodeURIComponent(type), { headers: headers }).
                then(response => {

                    return response;
                }).catch(error => {

                    alert('Internal Server Error');
                });

            return res;
        } catch (err) {

            alert('Internal Server Error');
        }
    }

    async getdatabySdData(method, data) {

        try {

            const res = await axios.post(REBATE_API_BASE_URL + method, data, { headers: headers }).
                then(response => {
                    console.log("service file", response);
                    return response;
                }).catch(error => {
                    console.log("service file error", error);
                    console.log(error);
                    alert('Internal Server Error');
                });
            console.log("result", res);
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    getGroupIds() {
        let groupId = SessionManage.getgroupid()
        if (groupId && groupId != undefined) {
            return groupId;
        } else {
            return 0;
        }
    }
    async postdatawithId(method) {
        try {
            var groupIds = this.getGroupIds();
            const res = await axios.post(REBATE_API_BASE_URL + method.trim() + '?groupIds=' + groupIds, '', { headers: headers }).
                then(response => {
                    console.log("1506/2.ress", response);
                    return response;
                }).catch(error => {
                    console.log("1506/2.ress", error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }


    async getdataWithPagingandattributeSearchingByGroup(method, search, pageSize, pageNumber, attributetype, filterVal, programId) {
        let filterValue = {
            objFilterValue: filterVal
        }
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method.trim() + "?pageSize=" + pageSize + "&pageNumber=" + pageNumber + "&search=" + search + "&attributeid=" + attributetype + "&groupids=" + this.getGroupIds() + "&programId=" + programId, filterValue, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async productSelectorBySegment(method, search, pageSize, pageNumber, attributetype, filterVal, programId,mpgIds,mappingType,value) {

        let filterValue = {
            objFilterValue: filterVal
        }
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method.trim() + "?pageSize=" + pageSize + "&pageNumber=" + pageNumber + "&search=" + search + "&attributeid=" + attributetype + "&groupids=" + this.getGroupIds() + "&programId=" + programId + "&mpgIds=" + mpgIds + "&mappingType=" + mappingType + "&value=" + value , filterValue, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }



    async getdataWithPagingandfilter(method, pageSize, pageNumber, sortType, SortProperty, filterVal) {
        let filterValue = {
            objFilterValue: filterVal
        }
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method.trim() + "&pageSize=" + pageSize + "&pageNumber=" + pageNumber + "&sortType=" + sortType + "&SortProperty=" + SortProperty, filterValue, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async getdataWithPagingByGroup(method, pageSize, pageNumber, sortType, SortProperty, filterVal) {
        let filterValue = {
            objFilterValue: filterVal
        }
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method.trim() + "?pageSize=" + pageSize + "&pageNumber=" + pageNumber + "&sortType=" + sortType + "&SortProperty=" + SortProperty + "&groupids=" + this.getGroupIds(), filterValue, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }
    async getdataWithPagingByGroupSearch(method, pageSize, pageNumber, sortType, SortProperty, filterVal, search) {

        let filterValue = {
            objFilterValue: filterVal
        }
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method.trim() + "?pageSize=" + pageSize + "&pageNumber=" + pageNumber + "&sortType=" + sortType + "&SortProperty=" + SortProperty + "&groupids=" + this.getGroupIds() + "&search=" + search, filterValue, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }
    async getdataWithPagingByMassGroupSearch(method, pageSize, pageNumber, sortType, SortProperty, filterVal, search, ActionFor) {

        let filterValue = {
            objFilterValue: filterVal
        }
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method.trim() + "?pageSize=" + pageSize + "&pageNumber=" + pageNumber + "&sortType=" + sortType + "&SortProperty=" + SortProperty + "&groupids=" + this.getGroupIds() + "&search=" + search + "&MassActionFor=" + ActionFor, filterValue, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }
    async getdataByGroup(method) {
        try {

            const res = await axios.get(REBATE_API_BASE_URL + method.trim() + "?groupids=" + this.getGroupIds(), { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async getfilterDropdownByGroup(method, filterKey) {
        try {

            const res = await axios.get(REBATE_API_BASE_URL + method.trim() + "?filterKey=" + filterKey + '&groupids=' + this.getGroupIds(), { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }



    async getdatawithPagingAccrualsByGroup(method, pageSize, pageNumber, col, value) {


        try {

            const res = await axios.get(REBATE_API_BASE_URL + method.trim() + "?pageSize=" + pageSize + "&pageNumber=" + pageNumber + "&filterKey=" + col + "&FilterValue=" + value + '&groupids=' + this.getGroupIds(), { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async getfilterDropdownByGroup(method, filterKey) {
        try {

            const res = await axios.get(REBATE_API_BASE_URL + method.trim() + "?filterKey=" + filterKey + '&groupids=' + this.getGroupIds(), { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }
    // Added by bhagwat
    async deleteDataByconfigurationPropertyID(method, Id, loggedInUser) {
        try {
            const res = await axios.post(REBATE_API_BASE_URL + method + "?Id=" + Id + "&userid=" + loggedInUser, '', { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async getAllConfigurationProperty(method) {
        try {
            const res = await axios.get(REBATE_API_BASE_URL + method.trim() + "/GetAll", { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }
    async getComparisonDate(method, name, validFrom) {
        try {
            const res = await axios.get(REBATE_API_BASE_URL + method.trim() + "?name=" + name + "&validFrom=" + validFrom, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async getUserBasedOnBU(method, groupId) {
        try {


            const res = await axios.get(REBATE_API_BASE_URL + method.trim() + '?groupids=' + groupId, { headers: headers }).
                then(response => {
                    console.log("1506/2.ress", response);
                    return response;
                }).catch(error => {
                    console.log("1506/2.ress", error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }
    async getApprovalsData(method, id, pageSize, pageNumber, col, value) {
        try {

            const res = await axios.get(REBATE_API_BASE_URL + method.trim() + "?id=" + id + "&pageSize=" + pageSize + "&pageNumber=" + pageNumber + "&filterKey=" + col + "&FilterValue=" + value, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }
    async getdatawithPagingByLoggedInUser(method, pageSize, pageNumber, sortType, SortProperty, col, value) {

        try {

            const res = await axios.get(REBATE_API_BASE_URL + method.trim() + "?id=" + this.getUserId() + "&pageSize=" + pageSize + "&pageNumber=" + pageNumber + "&filterKey=" + col + "&FilterValue=" + value + "&sortType=" + sortType + "&SortProperty=" + SortProperty, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    getUserId() {
        let userId = SessionManage.getuserid();
        if (userId && userId != undefined) {
            return userId;
        } else {
            return 0;
        }
    }
    async getcheckIfExists(method, name, id) {
        try {

            const res = await axios.get(REBATE_API_BASE_URL + method + "?name=" + name + "&id=" + id, { headers: headers }).
                then(response => {
                    console.log("service file", response);
                    return response;
                }).catch(error => {
                    console.log("service file error", error);
                    console.log(error);
                    alert('Internal Server Error');
                });
            console.log("result", res);
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }
    async getdataWithPagingByUser(method, pageSize, pageNumber) {
        try {
            const res = await axios.get(REBATE_API_BASE_URL + method.trim() + "?id= " + this.getUserId() + "&pageSize=" + pageSize + "&pageNumber=" + pageNumber, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async UpdateNotifications(method, clearAll, id, pageSize, pageNumber) {
        try {

            const res = await axios.get(REBATE_API_BASE_URL + method + "?clearAll=" + clearAll + "&id=" + id + "&toUserId=" + this.getUserId() + "&pageSize=" + pageSize + "&pageNumber=" + pageNumber, { headers: headers }).
                then(response => {
                    console.log("service file", response);
                    return response;
                }).catch(error => {
                    console.log("service file error", error);
                    alert('Internal Server Error');
                    console.log(error);
                });

            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }
    async getdataWithPagingForApprovals(method, id, pageSize, pageNumber, sortType, SortProperty, filterVal) {
        let filterValue = {
            objFilterValue: filterVal
        }
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method.trim() + "?id=" + id + "&pageSize=" + pageSize + "&pageNumber=" + pageNumber + "&sortType=" + sortType + "&SortProperty=" + SortProperty, filterValue, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }
    checkUndefined(res) {

        if (typeof res !== 'undefined') {
            return true
        } else {
            return false;
        }
    }
    checkDataUndefined(res) {
        if (typeof res !== 'undefined' || res !== 'null' || res !== '') {
            return true
        } else {
            return false;
        }
    }
    async getAll(method, pagesize, pageNumber, sortType, SortProperty,search,filterVal) {
        let filterValue = {
            objFilterValue: filterVal
        }
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method.trim() + "?pageSize=" + pagesize + "&pageNumber=" + pageNumber + "&sortType=" + sortType + "&SortProperty=" + SortProperty + "&search=" + search,filterValue, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async GetWithIdAndUser(method, Id, loggedInUser) {
        try {

            const res = await axios.get(REBATE_API_BASE_URL + method + "?Id=" + Id + "&userId=" + loggedInUser + "&distributorId=" + SessionManage.getuserid() + "&groupids=" + this.getGroupIds(), { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            console.log(res)
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }
    async LockUnLockEntity(method, className, Id, loggedInUser) {
        try {

            const res = await axios.get(REBATE_API_BASE_URL + method + "?className=" + className + "&Id=" + Id + "&userId=" + loggedInUser, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {

                });
            console.log(res)
            return res;
        } catch (err) {

        }
    }


    async getDataExport(method, pageSize, pageNumber, sortType, SortProperty, filterVal) {
        let filterValue = {
            objFilterValue: filterVal
        }
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method.trim() + "&pageSize=" + pageSize + "&pageNumber=" + pageNumber + "&sortType=" + sortType + "&SortProperty=" + SortProperty + "&groupids=" + this.getGroupIds(), filterValue, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });

            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async getDatawithMultiFilterSearch(method, pageSize, pageNumber, sortType, SortProperty, filterVal, search) {
        let filterValue = {
            objFilterValue: filterVal
        }
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method.trim() + "?pageSize=" + pageSize + "&pageNumber=" + pageNumber + "&sortType=" + sortType + "&SortProperty=" + SortProperty + "&groupids=" + this.getGroupIds() + "&search=" + search, filterValue, {
                headers: headers
            }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async getDatawithMultiFilterSearchRebate(method, pageSize, pageNumber, sortType, SortProperty, filterVal, search, For) {
        let filterValue = {
            objFilterValue: filterVal
        }
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method.trim() + "?pageSize=" + pageSize + "&pageNumber=" + pageNumber + "&sortType=" + sortType + "&SortProperty=" + SortProperty + "&groupids=" + this.getGroupIds() + "&search=" + search + "&MassActionFor=" + For, filterValue, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async getDatawithMultiFilterSearchwithId(method, id, pageSize, pageNumber, sortType, SortProperty, filterVal, search, distributerCode = null) {
        let filterValue = {
            objFilterValue: filterVal
        }
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method.trim() + "?id=" + id + "&pageSize=" + pageSize + "&pageNumber=" + pageNumber + "&sortType=" + sortType + "&SortProperty=" + SortProperty + "&groupids=" + this.getGroupIds() + "&search=" + search + "&distributerCode=" + distributerCode, filterValue, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }
    async getDatawithMultiFilterSearchwithAgreementType(method, id, pageSize, pageNumber, sortType, SortProperty, filterVal, search, MassActionFor) {
        let filterValue = {
            objFilterValue: filterVal
        }
        //debugger
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method.trim() + "?id=" + id + "&pageSize=" + pageSize + "&pageNumber=" + pageNumber + "&sortType=" + sortType + "&SortProperty=" + SortProperty + "&groupids=" + this.getGroupIds() + "&search=" + search + "&MassActionFor=" + MassActionFor, filterValue, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }
    async getAllPolicydataSearch(method, pagesize, pageNumber, sortType, SortProperty, filterVal, search) {
        let filterValue = {
            objFilterValue: filterVal
        }
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method.trim() + "/GetAll_Policy" + "?pageSize=" + pagesize + "&pageNumber=" + pageNumber + "&sortType=" + sortType + "&SortProperty=" + SortProperty + "&search=" + search, filterValue, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }
    async getAllAudithistorySearch(method, pagesize, pageNumber, sortType, SortProperty, filterVal, search) {
        let filterValue = {
            objFilterValue: filterVal
        }
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method.trim() + "?pageSize=" + pagesize + "&pageNumber=" + pageNumber + "&sortType=" + sortType + "&SortProperty=" + SortProperty + "&search=" + search, filterValue, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async getAllPolicydataCashDiscount(method, pagesize, pageNumber, sortType, SortProperty, filterVal, search) {
        let filterValue = {
            objFilterValue: filterVal
        }
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method.trim() + "?pageSize=" + pagesize + "&pageNumber=" + pageNumber + "&sortType=" + sortType + "&SortProperty=" + SortProperty + "&search=" + search, filterValue, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async getAllDistributordataSearch(method, pagesize, pageNumber, sortType, SortProperty, filterVal, search) {
        let filterValue = {
            objFilterValue: filterVal
        }
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method.trim() + "/GetALL_DistributorBudget_List" + "?pageSize=" + pagesize + "&pageNumber=" + pageNumber + "&sortType=" + sortType + "&SortProperty=" + SortProperty + "&search=" + search, filterValue, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async getAllReportData(method,pagesize, pageNumber, sortType, SortProperty, data) {

        try {

            const res = await axios.post(REBATE_API_BASE_URL + method.trim() + "/GetAllReports" + "?pageSize=" + pagesize + "&pageNumber=" + pageNumber + "&sortType=" + sortType + "&SortProperty=" + SortProperty + "&groupids=" + this.getGroupIds(), data, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async getAllUserReportData(method, data) {

        try {
            console.log("7/5", REBATE_API_BASE_URL + method.trim() + "/GetUserRecords" + "?groupids=" + this.getGroupIds(), data, { headers: headers })
            const res = await axios.post(REBATE_API_BASE_URL + method.trim() + "/GetUserRecords" + "?groupids=" + this.getGroupIds(), data, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async getAllReportsdataSearch(method, pagesize, pageNumber, sortType, SortProperty, filterVal, search) {
        let filterValue = {
            objFilterValue: filterVal
        }
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method.trim() + "/GetAll" + "?sortType=" + sortType + "&SortProperty=" + SortProperty,filterValue, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }
    async getdataWithPagingForApprovalsSearch(method, id, pageSize, pageNumber, sortType, SortProperty, filterVal, search) {
        let filterValue = {
            objFilterValue: filterVal
        }
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method.trim() + "?id=" + id + "&pageSize=" + pageSize + "&pageNumber=" + pageNumber + "&sortType=" + sortType + "&SortProperty=" + SortProperty + "&search=" + search, filterValue, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async getPostdatabyRebateIdSearch(method, Id, pagesize, pageNumber, sortType, SortProperty, value, search, flag) {
        let filtervalue = {
            objFilterValue: value
        }
        try {

            const res = await axios.post(REBATE_API_BASE_URL + "rebate/" + method + "?RebateID=" + Id + "&pageSize=" + pagesize + "&pageNumber=" + pageNumber + "&sortType=" + sortType + "&SortProperty=" + SortProperty + "&search=" + search + "&flag=" + flag, filtervalue, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }
    async getPostdatabySchemeIdSearch(method, Id, pagesize, pageNumber, sortType, SortProperty, value, search, flag) {
        let filtervalue = {
            objFilterValue: value
        }
        try {

            const res = await axios.post(REBATE_API_BASE_URL + "scheme/" + method + "?RebateID=" + Id + "&pageSize=" + pagesize + "&pageNumber=" + pageNumber + "&sortType=" + sortType + "&SortProperty=" + SortProperty + "&search=" + encodeURIComponent(search) + "&flag=" + flag, filtervalue, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }
    async getPostdatabyClaimIdSearch(method, Id, pagesize, pageNumber, sortType, SortProperty, value, search) {
        let filtervalue = {
            objFilterValue: value
        }
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method + "?Id=" + Id + "&pageSize=" + pagesize + "&pageNumber=" + pageNumber + "&sortType=" + sortType + "&SortProperty=" + SortProperty + "&search=" + search, filtervalue, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async modifyProgramLineSalesLevel(method, value) {

        try {

            const config = {
                headers: headers
            }
            const res = await axios.post(REBATE_API_BASE_URL + method, value, config).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }
    async getdatabyIdUser(method, Id) {
        try {

            const res = await axios.get(REBATE_API_BASE_URL + method + "?Id=" + Id + "&loggedInUserId=" + this.getUserId(), { headers: headers }).
                then(response => {
                    console.log("service file", response);
                    return response;
                }).catch(error => {
                    console.log("service file error", error);
                    console.log(error);

                });
            console.log("result", res);
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);

        }
    }

    getSelectstyle() {
        const customStyles = {
            control: base => ({
                ...base,
                minHeight: 28,
                maxHeight: 100,
            }),
            dropdownIndicator: base => ({
                ...base,
                padding: 2,
                fontSize: "0.7rem",
            }),
            clearIndicator: base => ({
                ...base,
                padding: 2
            }),
            multiValue: base => ({
                ...base,
                fontSize: "0.7rem",
            }),
            valueContainer: base => ({
                ...base,
                padding: '0px 2px',
                fontSize: "0.7rem",
            }),
            input: base => ({
                ...base,
                margin: 0,
                padding: 0,
            })
        };
        return customStyles
    }
    async getfilterDropdownByUser(method, filterKey) {
        try {

            const res = await axios.get(REBATE_API_BASE_URL + method.trim() + "?filterKey=" + filterKey + "&loggedInUserId=" + this.getUserId(), { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }
    async getdataByGroupAndType(method, type) {
        try {

            const res = await axios.get(REBATE_API_BASE_URL + method.trim() + "?groupids=" + this.getGroupIds() + "&contract_Type=" + type, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }
    exportPerms(userPermission) {
        if (typeof userPermission !== 'undefined') {
            if (userPermission.filter((item) => item.ActionName === 'Export').length == 0) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }
    currencyFormat(value) {
        var newValue = 0;
        if (SessionManage.getLocale() == 'Indian') {


            newValue = new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(value);
        } else {

            newValue = Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
        }
        return newValue.replace("₹", "").replace("$", "");

    }
    numberFormat(value) {
        var newValue = 0;
        if (SessionManage.getLocale() == 'Indian') {

            newValue = new Intl.NumberFormat('en-IN', { currency: 'INR' }).format(value);
        } else {

            newValue = Intl.NumberFormat('en-US', { currency: 'USD' }).format(value);
        }
        return newValue;

    }

    async getdataWithFilters(method, lists, filterVal) {

        let filterValue = {
            objFilterValue: filterVal,
            lists: lists,
            Cuslists: lists
        }
        try {


            const res = await axios.post(REBATE_API_BASE_URL + method.trim(), filterValue, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async getApprovalsFilterDropdown(method, filterKey, loggedInUser) {
        try {

            const res = await axios.get(REBATE_API_BASE_URL + method.trim() + "?id=" + loggedInUser + "&filterKey=" + filterKey, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }
    async getdatabyIdsearch(method, Id, search, roleid) {
        try {

            const res = await axios.get(REBATE_API_BASE_URL + method + "?Id=" + Id + "&search=" + search + "&roleid=" + roleid, { headers: headers }).
                then(response => {
                    console.log("service file", response);
                    return response;
                }).catch(error => {
                    console.log("service file error", error);
                    console.log(error);

                });

            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);

        }
    }
    async SubmitExceptionPolicy(method, Id, userid, comments, value) {
        try {

            const res = await axios.post(REBATE_API_BASE_URL + method + "?Id=" + Id + "&userid=" + userid + "&comments=" + comments, value, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }
    async getConcilationdatabyData(method, customername, productname, concilationId, pageSize, pageNumber, sortType, SortProperty, filterVal) {
        let filterValue = {
            objFilterValue: filterVal
        }

        try {
            const res = await axios.post(REBATE_API_BASE_URL + method + "?customername=" + customername + "&productname=" + productname + "&concilationId=" + concilationId + "&pageSize=" + pageSize + "&pageNumber=" + pageNumber + "&sortType=" + sortType + "&SortProperty=" + SortProperty, filterValue, { headers: headers }).
                then(response => {
                    console.log(response);
                    return response;
                }).catch(error => {
                    console.log(error);
                    alert('Internal Server Error');
                });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }
    async getdatabyCountryId(method, Id) {
        try {
            var groupIds = this.getGroupIds();
            const res = await axios.get(REBATE_API_BASE_URL + method + "?Id=" + Id + '&groupIds=' + groupIds, { headers: headers }).
                then(response => {
                    console.log("service file", response);
                    return response;
                }).catch(error => {
                    console.log("service file error", error);
                    console.log(error);
                    alert('Internal Server Error');
                });
            console.log("result", res);
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async getColumnVisibilityListData(method, userId, modelName, columnLabel) {
        try {
            const res = await axios.get(REBATE_API_BASE_URL + method + "?userid=" + userId + '&modelname=' + modelName + '&columnlabel=' + columnLabel, { headers: headers }).
                then(response => {
                    console.log("getColumnVisibilityList", response);
                    return response;
                }).catch(error => {
                    console.log("getColumnVisibilityList error", error);
                    console.log(error);
                    alert('Internal Server Error');
                });
            console.log("result", res);
            return res;

        }
        catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }

    async setColumnVisibilityListData(method, recordId, userId, modelName, columnLabel, defaultView, jsonData) {
        try {
            const res = await axios.post(REBATE_API_BASE_URL + method + "?id=" + recordId + "&userid=" + userId + '&modelname=' + modelName + '&columnlabel=' + columnLabel + '&defaultview=' + defaultView, jsonData, { headers: headers }).
                then(response => {
                    console.log("setColumnVisibilityList", response);
                    return response;
                }).catch(error => {
                    console.log("setColumnVisibilityList error", error);
                    console.log(error);
                    alert('Internal Server Error');
                });
            console.log("result", res);
            return res;

        }
        catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }


    async getTodData() {
        try {
            const response = await axios.get(REBATE_API_BASE_URL + "SDCommon/GetAllTodtypes", {
                headers: headers
            });
            console.log(response.data, "tod"); // Assuming the relevant data is in response.data
            return response.data;
        } catch (err) {
            console.error('Error:', err);
            alert('Internal Server Error');
            throw err; // Rethrow the error if needed
        }
    }


    async getSchemeData() {
        try {
            const response = await axios.get(REBATE_API_BASE_URL + "SDCommon/GetAllSchemetypes", {
                headers: headers
            });
            console.log(response.data, "scheme"); // Assuming the relevant data is in response.data
            return response.data;
        } catch (err) {
            console.error('Error:', err);
            alert('Internal Server Error');
            throw err; // Rethrow the error if needed
        }
    }

    async getAllZones() {
        try {
            const response = await axios.get(REBATE_API_BASE_URL + "SDCommon/GetAllRebateZone", {
                headers: headers
            });
            console.log(response.data, "zones"); // Assuming the relevant data is in response.data
            return response.data;
        } catch (err) {
            console.error('Error:', err);
            alert('Internal Server Error');
            throw err; // Rethrow the error if needed
        }
    }
    async getAllGetAllMeasureType() {

        try {
            const response = await axios.get(REBATE_API_BASE_URL + "SDCommon/GetAllMeasureType", {
                headers: headers
            });
            console.log(response.data, "zones"); // Assuming the relevant data is in response.data
            return response.data;
        } catch (err) {
            console.error('Error:', err);
            alert('Internal Server Error');
            throw err; // Rethrow the error if needed
        }
    }

    async getAllStates() {
        try {
            const response = await axios.get(REBATE_API_BASE_URL + "SDCommon/GetAllRebateState", {
                headers: headers
            });
            console.log(response.data, "state"); // Assuming the relevant data is in response.data
            return response.data;
        } catch (err) {
            console.error('Error:', err);
            alert('Internal Server Error');
            throw err; // Rethrow the error if needed
        }
    }

    async getAllStations() {
        try {
            const response = await axios.get(REBATE_API_BASE_URL + "SDCommon/GetAllRebateStation", {
                headers: headers

            });
            console.log(response.data, "station"); // Assuming the relevant data is in response.data
            return response.data;
        } catch (err) {
            console.error('Error:', err);
            alert('Internal Server Error');
            throw err; // Rethrow the error if needed
        }
    }

    async getAllDropdownData(method) {
        try {

            const response = await axios.get(REBATE_API_BASE_URL + method, {
                headers: headers
            });
            console.log(response.data, "Test"); // Assuming the relevant data is in response.data
            return response.data;
        } catch (err) {
            console.error('Error:', err);
            alert('Internal Server Error');
            throw err; // Rethrow the error if needed
        }
    }
    async getSegementDetailByFiscalYear(method, year) {
        try {

            const res = await axios.get(REBATE_API_BASE_URL + method + "?fiscalYear=" + year).
                then(response => {
                    console.log("Segment file", response);
                    return response;
                }).catch(error => {
                    console.log("Segment file error", error);
                    console.log(error);

                });
            console.log("result", res);
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);

        }
    }
    async getDistributorGroups(method, year) {
        try {

            const res = await axios.get(REBATE_API_BASE_URL + method + "?fiscalYear=" + year).
                then(response => {
                    console.log("Segment file", response);
                    return response;
                }).catch(error => {
                    console.log("Segment file error", error);
                    console.log(error);

                });
            console.log("result", res);
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);

        }
    }


    async getDataBasedOnId(method, Id, type) {
        try {

            const res = await axios.get(REBATE_API_BASE_URL + method + "?ids=" + Id + "&type=" + type, { headers: headers }).
                then(response => {
                    console.log("service file", response);
                    return response;
                }).catch(error => {
                    console.log("service file error", error);
                    console.log(error);

                });
            console.log("result", res);
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);

        }
    }

    async getAllCustomerChange(type, name) {
        try {
            const response = await axios.get(REBATE_API_BASE_URL + "SDCommon/GetAllCustomer", {
                headers: {
                    headers
                },
                params: {
                    type: type,
                    name: name
                }
            });

            return response.data;
        } catch (err) {
            console.error('Error:', err);
            alert('Internal Server Error');
            throw err; // Rethrow the error if needed
        }
    }

    async fetchModuleData() {
        try {
            const response = await axios.get(REBATE_API_BASE_URL + "Permission/GetAllModules");
            return response.data;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error; // You can handle the error in the calling function
        }
    };
 async SubmitDistributorMappingPolicy(method, Id, userid, comments, groupComments,newDistributorGroup ,includeWorkflow) {
        try {
            const params = new URLSearchParams({Id,userid,comments,groupComments,newDistributorGroup,includeWorkflow});
            // const res = await axios.post(REBATE_API_BASE_URL + method + "?Id=" + Id + "&userid=" + userid + "&comments=" + comments+ "&groupComments=" + groupComments + "&newDistributorGroup=" +newDistributorGroup+ "&includeWorkflow=" +includeWorkflow, { headers: headers }).
                // then(response => {
                //     console.log(response);
                //     return response;
                // }).catch(error => {
                //     console.log(error);
                //     alert('Internal Server Error');
                // });
            const res = await axios.post(`${REBATE_API_BASE_URL}${method}?${params.toString()}`,{}, { headers: headers }).
                        then(response => {
                             console.log(response);
                             return response;
                        }).catch(error => {
                             console.log(error);
                             alert('Internal Server Error');
                        });
            return res;
        } catch (err) {
            console.log(`Error: ${err?.response?.data}`);
            alert('Internal Server Error');
        }
    }
}




export default new RebateService()