import { Link, useLocation } from "react-router-dom"
import React, { useState, useEffect } from 'react'
import classes from '../../RebateProgramComponent/CreateRebateComponent/Form.module.css'
import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal } from 'react-bootstrap';
import Service from '../../../Services/Service';
import 'react-multiple-select-dropdown-lite/dist/index.css'
import Multiselect from 'multiselect-react-dropdown';
import CustomErrorPopup from "../../../UI/CustomErrorPopup";
import usercss from "../UserManagement/users.css";
import $ from 'jquery';
import SessionManage from "../../../SessionManagement/SessionManage";

import { useTranslation } from "react-i18next";
function EditUserComponent(props) {

    const [t, i18n] = useTranslation('translation');

    const location = useLocation();
    const [editPerms, setEditPerms] = useState([]);
    const [useravailable, setuseravailable] = useState('');

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorStatus, setErrorStatus] = useState("Error");
    const [isValid, setIsValid] = useState(true);
    const [closeModal, setCloseModal] = useState(false);
    const [handleChangeValue, setHandleChangeValue] = useState(false);
    let loggedInUser = SessionManage.getuserid();
    const [isLocked, setIsLocked] = useState(false);
    const [lockedBy, setLockedBy] = useState('');
   
    const [showRolePopup, setShowRolePopup] = useState(false);
    const [roleSerach, setRoleSerach] = useState('');    
    const [roleCheckAll, setRoleCheckAll] = useState(false);
    const [roleDataFilter, setRoleDataFilter] = useState([]);
    const [selectedRole, setselectedRole] = useState([]); 
    
    const [showGroupPopup, setShowGroupPopup] = useState(false);
    const [groupSerach, setGroupSerach] = useState('');    
    const [groupCheckAll, setGroupCheckAll] = useState(false);
    const [groupDataFilter, setGroupDataFilter] = useState([]);
    const [selectedGroup, setselectedGroup] = useState([]);
    const [Roles, setRole] = useState([]); 
    const [Groups, setGroup] = useState([]);

    let disable = false;
    useEffect(() => {
        getUserPermission(location.pathname);
        getUserDatabyId("Users/GetById/", props.id);
        getData("Users/GetRoleAndGroup");

    }, [0])

    const [inputField, setInputField] = useState({
        Id: 0,
        FirstName: '',
        LastName: '',
        Username: '',
        Email: '',
        Password: '',
        ConfirmPassword: '',
        lstRole: [],
        lstGroup: [],
        Active: false,
        IsSuperuser: false,
        IsActive: false,
        LastModifiedUser: '',
        CreatedUser: '',
        dateformat: '',
        Locale: '',
        Languages: '',
    })
    const [file, setFile] = useState([]);
    const saveFileSelected = (e) => {
        const selectedFile = e.target.files[0];
        setFile([...file, ...e.target.files]);
    };
    const getUserPermission = (pathname) => {
        Service.GetUserPermission(pathname).then((res) => {

            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null' && res.data !== '') {
                    if (typeof res.data.DataList !== 'undefined') {
                        var permission = res.data.DataList;
                        if (typeof permission !== 'undefined') {
                            if (permission.filter((item) => item.ActionName === 'Edit' || item.ActionName === 'Submit').length == 0) {
                                setEditPerms(false);
                            } else {
                                setEditPerms(true);
                            }
                        }
                        else {
                            setEditPerms(false);
                        }
                    }
                }
            }
        });
    }
    const getData = (method) => {
        Service.getdata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && typeof res.data.DataList !== 'undefined') {

                    if (res.data.DataList.lstRoles !== 'undefined')
                        setRole(res.data.DataList.lstRoles);
                    if (res.data.DataList.lstGroups !== 'undefined')
                        setGroup(res.data.DataList.lstGroups);

                }
            }
        });
    }


    const getUserDatabyId = (method, id) => {
        Service.LockUnLockEntity('Configurationprop/LockEntity', "User", props.id, loggedInUser).then((res1) => {
            if (typeof res1 !== 'undefined') {
                Service.getdatabyId(method, id).then((res) => {
                    if (typeof res !== 'undefined') {
                        if (typeof res.data !== 'undefined' && res.data !== 'null') {
                            setInputField(res.data);
                            
                            let selectedList = [];
                            res.data.lstRole.map((i) => {
                                let ids = {id: i.Id};
                                selectedList.push(ids)
                            });      
                            setselectedRole(selectedList);

                            let selectedGroupList = [];
                            res.data.lstGroup.map((i) => {
                                let ids = {id: i.Id};
                                selectedGroupList.push(ids)
                            });                                 
                            setselectedGroup(selectedGroupList);
                            setLockedBy(res.data.LockedBy);
                            setIsLocked(res.data.Locked);
                            if ((res.data.Locked === true && res.data.LockedBy.toString() !== loggedInUser)) {
                                $("#libread").html(t('LockedBy') + ' ' + res.data.LockedByUserName);
                            }
                            // setFile([...file, ...res.data.UploadPic]);
                        }
                    }
                });
            }

        });
    }



    const emailinputhandler = (e) => {
        setHandleChangeValue(true)
        const { name, value } = e.target;
        setInputField((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        if (value !== null) {
            Service.getdatabyId("Users/CheckUserExist", value).then((res) => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        if (res.status === 200) {
                            setuseravailable(res.data);

                        }
                    }
                }


            });
        }

    }

    const checkValidation = () => {
        var has_errors = false;
        if (inputField.FirstName == null || inputField.FirstName == '') {


            setErrorMessage(t("Validation-Createuser.FirstName"))
            has_errors = true;
            setShowErrorModal(true)
        }
        else if (inputField.LastName == '' || inputField.LastName == null) {
            setErrorMessage(t("Validation-Createuser.LastName"))
            has_errors = true;
            setShowErrorModal(true)
        }
        else if (inputField.Email == '' || inputField.Email == null) {
            setErrorMessage(t("Validation-Createuser.Email"))
            has_errors = true;
            setShowErrorModal(true)
        }
        else if (inputField.Username == '' || inputField.Username == null) {
            setErrorMessage(t("Validation-Createuser.Username"))
            has_errors = true;
            setShowErrorModal(true)
        }
        else if (inputField.lstRole == '' || inputField.lstRole == null) {
            setErrorMessage(t("Validation-Createuser.Role "))
            has_errors = true;
            setShowErrorModal(true)
        }
        else if (inputField.lstGroup == '' || inputField.lstGroup == null) {
            setErrorMessage(t("Validation-Createuser.Group"))
            has_errors = true;
            setShowErrorModal(true)
        }
        else if (inputField.Password == '' || inputField.Password == null) {
            setErrorMessage(t("Validation-Createuser.Password"))
            has_errors = true;
            setShowErrorModal(true)
        }
        else if (inputField.ConfirmPassword != inputField.Password) {
            setErrorMessage('Your Password and Confirm Password do not match')
            setIsValid(false)
            setShowErrorModal(true)
            has_errors = true;
        }

        return has_errors;

    }

    const inputsHandler = (e) => {

        setHandleChangeValue(true)
        const { name, value } = e.target;
        setInputField((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const closeHandler = (flag) => {
        if (flag == "Open" && handleChangeValue == true) {
            setCloseModal(true)
        }
        if (flag == "Open" && handleChangeValue == false) {
            Service.LockUnLockEntity('Configurationprop/UnLockEntity', "User", props.id, loggedInUser).then((res) => {
            });
            props.navigate('/Users')
        }
        if (flag == "Close") {
            Service.LockUnLockEntity('Configurationprop/UnLockEntity', "User", props.id, loggedInUser).then((res) => {
            });
            props.navigate('/Users')
        }
        if (flag == "Cancel") {
            setCloseModal(false)
        }

    }



    let selectedData = [];
    const submitButton = (e) => {
        e.preventDefault();
        let id = SessionManage.getuserid();
        inputField.CreatedUser = id;
        inputField.LastModifiedUser = id;
        let Users = inputField;
        let name = SessionManage.getusername();
        let SendData = new FormData();
        SendData.append("data", JSON.stringify(inputField));
        if(file.length > 0){
            SendData.append('ProfileFile', file[0]);
        }
        /*for (let f of file) {
          SendData.append('ProfileFile', f);
        }*/

        var has_errors = checkValidation();

        if (has_errors === false) {
            Service.createorupdate("Users/CreateOrUpdate", SendData).then(res => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        if (id === props.id) {
                            SessionManage.setusername(Users.FirstName + ' ' + Users.LastName)
                            // sessionStorage.setItem('name', Users.FirstName + ' ' + Users.LastName);
                            SessionManage.setdateformat(Users.dateformat);
                        }
                        Service.LockUnLockEntity('Configurationprop/UnLockEntity', "User", props.id, loggedInUser).then((res) => {
                        });
                        props.navigate('/Users');
                    }
                }
            });
        }
    }




    const handleChange = (selectedList, selectedItem) => {
        setHandleChangeValue(true)
        const selected = [];
        const selectedId = [];
        inputField.lstRole = [];
        let selectedOption = (selectedList);

        for (let i = 0; i < selectedOption.length; i++) {
            selected.push(selectedOption[i].Name);
            selectedId.push(selectedOption[i].Id);
            const getallData = {
                id: selectedOption[i].Id,

            }
            inputField.lstRole.push(getallData);
        }


    }
    const handleChangeGroup = (selectedList, selectedItem) => {
        setHandleChangeValue(true)
        const selected = [];
        const selectedId = [];
        inputField.lstGroup = [];
        let selectedOption = (selectedList);

        for (let i = 0; i < selectedOption.length; i++) {
            selected.push(selectedOption[i].Name);
            selectedId.push(selectedOption[i].Id);
            const getallData = {
                id: selectedOption[i].Id,

            }
            inputField.lstGroup.push(getallData);
        }


    }
    if (isLocked === false || (isLocked === true && lockedBy.toString() === loggedInUser)) {
        disable = false;
    }
    else {
        disable = true;
    }
    window.onbeforeunload = function () {
        unlockEntity();

    };


    const regionStyles = {
        chips: {
            fontSize: "12px",
            lineHeight: "13px"
        },

        multiSelectContainer: {
            "&:hover": { background: "red" }
        }

    }

    const unlockEntity = () => {
        Service.LockUnLockEntity('Configurationprop/UnLockEntity', "User", props.id, loggedInUser).then((res) => {
        });
    }
    const class_textbox = `col-md-3 mt-1 textbox-padding form-control m_width ${editPerms == false ? "disabledClass" : ""}`;
    const class_multiselect = ` textbox-padding form-control m_width ${editPerms == false ? "disabledClass" : ""}`;

    const ClickRolePopup = () => {  
        let roleData = Roles;
        selectedRole.map((i) => {
            let valueSel =  roleData.filter((e) => {return e.Id == i.id});
            if (valueSel.length > 0){
                valueSel[0].Selected =true;
            }
        });      
        setRole(roleData);
        setShowRolePopup(true); 
    }
    const ClickRolePopupCancel = () => {
        setShowRolePopup(false);
        let roleData = Roles;
        roleData.map((i) => {
            let valueSel =  selectedRole.filter((e) => {return e.id == i.Id});
            if (valueSel.length == 0){
                i.Selected = false;
            }
        });
        setRole(roleData);
    }
    const okRole = () => {
        let selectedList = [];
        Roles.map((i) => {
            if(i.Selected === true){
                let ids = {id: i.Id};
                selectedList.push(ids)
            }
        });      
        setselectedRole(selectedList);
        inputField.lstRole = selectedList;
        setShowRolePopup(false);
    }
    const SearchRole =(e)=>{
        setRoleSerach(e.target.value)
        var list = []
        let lstSelected = Roles.filter((e) => e.Selected === true).map(item => {
          return { ...item }
        })
        if (roleDataFilter.length === 0) {
            list = Roles;
            setRoleDataFilter(list);
        }
        else {
            list = roleDataFilter;
        }
        if (e.target.value != '') {
            let searchResults = list.filter((each) => {      
              return each.Label.toLowerCase().includes(String(e.target.value).toLowerCase()) ||
                each.Name.toLowerCase().includes(String(e.target.value).toLowerCase())
            });
            let newlist = [...searchResults, ...lstSelected];
            const uniquelst = [];
            newlist.map((item) => {
              var findItem = uniquelst.find((x) => x.Id === item.Id);
              if (!findItem) uniquelst.push(item);
            });
            setRole(uniquelst);
        }        
        else {
            setRole(list);
        }
    };
    const checkAllRole =(e)=>{
        const isChecked = e.target.checked;
        setRoleCheckAll(isChecked);   
        let roleList = [...Roles]
        if (isChecked){
            roleList.map((i) => {
                i.Selected = true;
            })
            setRole(roleList);
        }
        else{
            roleList.map((i) => {
                i.Selected = false;
            })
            setRole(roleList);
        }     
    }
    const handleRoleSelection = (e, label, id) => {
        let roleList = [...Roles]
        roleList.map((i) => {
            if (i.Id === id) {
              i.Selected = true;
            }
        })
        setRole(roleList);

    }
    const handleRoleChanges = (e, label, id) => {
        const isChecked = e.target.checked;
        const updatedRoleData = Roles.map((rlist) => {
            if (rlist.Id === id) {
                return { ...rlist, Selected: isChecked };
            }
            return rlist;
        });
        setRole(updatedRoleData);
    }

    
    const ClickGroupPopup = () => {
        let groupData = Groups;
        selectedGroup.map((i) => {
            let valueSel =  groupData.filter((e) => {return e.Id == i.id});
            if (valueSel.length > 0){
                valueSel[0].Selected =true;
            }
        });
        setGroup(groupData);        
        setShowGroupPopup(true);
    }
    const ClickGroupPopupCancel = () => {
        setShowGroupPopup(false); 
        let groupData = Groups;
        groupData.map((i) => {
            let valueSel =  selectedGroup.filter((e) => {return e.id == i.Id});
            if (valueSel.length == 0){
                i.Selected = false;
            }
        });
        setGroup(groupData);
    }
    const okGroup = () => {
        let selectedList = [];
        Groups.map((i) => {
            if(i.Selected === true){
                let ids = {id: i.Id};
                selectedList.push(ids)
            }
        });      
        setselectedGroup(selectedList);
        inputField.lstGroup = selectedList;
        setShowGroupPopup(false);
    }
    const SearchGroup =(e)=>{
        setGroupSerach(e.target.value)
        var list = []
        let lstSelected = Groups.filter((e) => e.Selected === true).map(item => {
          return { ...item }
        })
        if (groupDataFilter.length === 0) {
            list = Groups;
            setGroupDataFilter(list);
        }
        else {
            list = groupDataFilter;
        }
        if (e.target.value != '') {
            let searchResults = list.filter((each) => {      
              return each.Label.toLowerCase().includes(String(e.target.value).toLowerCase()) ||
                each.Name.toLowerCase().includes(String(e.target.value).toLowerCase())
            });
            let newlist = [...searchResults, ...lstSelected];
            const uniquelst = [];
            newlist.map((item) => {
              var findItem = uniquelst.find((x) => x.Id === item.Id);
              if (!findItem) uniquelst.push(item);
            });
            setGroup(uniquelst);
        }        
        else {
            setGroup(list);
        }
    };
    const checkAllGroup =(e)=>{
        const isChecked = e.target.checked;
        setGroupCheckAll(isChecked);   
        let groupList = [...Groups]
        if (isChecked){
            groupList.map((i) => {
                i.Selected = true;
            })
            setGroup(groupList);
        }
        else{
            groupList.map((i) => {
                i.Selected = false;
            })
            setGroup(groupList);
        }     
    }
    const handleGroupSelection = (e, label, id) => {
        let groupList = [...Groups]
        groupList.map((i) => {
            if (i.Id === id) {
              i.Selected = true;
            }
        })
        setGroup(groupList);

    }
    const handleGroupChanges = (e, label, id) => {
        const isChecked = e.target.checked;
        const updatedGroupData = Groups.map((glist) => {
            if (glist.Id === id) {
                return { ...glist, Selected: isChecked };
            }
            return glist;
        });
        setGroup(updatedGroupData);
    }

    return (<>
        <div className="container-fluid content-top-gap">

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb my-breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">
                        {t('Home.title')}
                    </Link></li>
                    <li className="breadcrumb-item active" aria-current="page"><Link to="/Users">
                        {t('UserList.title')}

                    </Link></li>
                    <li className="breadcrumb-item active" aria-current="page">
                        {t('Createuser.titleEdit')} &nbsp;
                        <span id="libread"></span>
                    </li>

                </ol>
            </nav>

            <div className="row mt-3" id="rebateProgramdiv">
                <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                        <div className="card-header chart-grid__header">
                            <div className="row">
                                <div className="col-md-7 text-left">
                                    {t('Createuser.title')}
                                </div>

                                <div className="col-md-5">
                                    {editPerms == false ? "" : (isLocked === true && lockedBy.toString() == loggedInUser) ? <button className="btn btn-sm btn-update ml-2" style={{ float: "right", width: "50px" }} onClick={submitButton} type="submit">{t('Submit')}</button> : ''}
                                    <button className="btn btn-sm btn-update ml-2" id="close" style={{ width: "45px", backgroundColor: "buttonface", float: "right" }} onClick={(event) => closeHandler("Open")}>{t('Close')}    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body" style={{ minHeight: "60vh" }}>
                            <div className="container">
                                <div className="row">
                                    <label className="col-md-2 text-left l_width">
                                        {t('FirstName')}
                                        <span className={classes.mandatory}>*</span>
                                    </label>
                                    <input id="aid" disabled={disable} onChange={inputsHandler} value={inputField.FirstName} autoComplete='no' name="FirstName" type="text" className={class_textbox} />
                                    <div className="col-md-2"></div>
                                    <label className="col-md-2 text-left l_width">{t('LastName')} </label>
                                    <input id="aid" disabled={disable} onChange={inputsHandler} value={inputField.LastName} autoComplete='no' name="LastName" type="text" className={class_textbox} />

                                </div>
                                <div className="row">
                                    <label className="col-md-2 text-left l_width">{t('Email')}<span className={classes.mandatory}>*</span> </label>
                                    <input id="aid" disabled={true} onChange={emailinputhandler} value={inputField.Email} style={{ marginTop: "10px" }} autoComplete='no' name="Email" type="text" className={class_textbox} />
                                    <div className="col-md-2 mt-2 text-left">
                                        <span className='errormessage'>{useravailable}</span>
                                    </div>
                                    {/* <div className="col-md-2"></div> */}
                                    <label className="col-md-2 text-left l_width">{t('UserName')}<span className={classes.mandatory}>*</span> </label>
                                    <input id="aid" onChange={inputsHandler} value={inputField.Username} autoComplete='no' name="Username" type="text" className="col-md-3 mt-2 textbox-padding form-control m_width" disabled />
                                    
                                </div>
                                <div className="row">
                                    <label className="col-md-2 text-left l_width">{t('DateFormat')}<span className={classes.mandatory}>*</span> </label>
                                    <select disabled={disable} name="dateformat" id="dateformat" style={{ fontSize: "0.75rem" }} onChange={inputsHandler} value={inputField.dateformat} className={class_textbox}>
                                        <option>Select</option>
                                        <option value="dd/MM/yyyy">DD/MM/YYYY</option>
                                        <option value="MM/dd/yyyy">MM/DD/YYYY</option>
                                    </select>
                                    <div className="col-md-2"></div>
                                    <label className="col-md-2 mt-2 text-left l_width">{t('Password')} <span className={classes.mandatory}>*</span> </label>
                                    <input id="aid" disabled={disable} onChange={inputsHandler} value={inputField.Password} autoComplete='no' name="Password" type="password" className="col-md-3 mt-2 textbox-padding form-control m_width" />
                                </div>
                                <div className="row">
                                    <label className="col-md-2 mt-2 text-left l_width">{t('Locale')} <span className={classes.mandatory}>*</span></label>
                                    <select name="Locale" id="Locale" onChange={inputsHandler} style={{ fontSize: "0.75rem" }} value={inputField.Locale} className="col-md-3 textbox-padding mt-2 form-control m_width">
                                        <option >Select</option>
                                        <option value="Indian">Indian</option>
                                        <option value="International">International</option>
                                    </select>
                                    <div className="col-md-2"></div>
                                    <label className="col-md-2 mt-2 text-left l_width">{t('UserList.ConfirmPassword')} <span className={classes.mandatory}>*</span> </label>
                                    <input id="aid" disabled={disable} onChange={inputsHandler} value={inputField.ConfirmPassword} autoComplete='no' name="ConfirmPassword" type="password" className="col-md-3 mt-2 textbox-padding form-control m_width" />

                                </div>

                                <div className="row">
                                    <label className="col-md-2 mt-2 text-left l_width">{t('Language')} <span className={classes.mandatory}>*</span></label>
                                    <select name="Languages" id="Languages" onChange={inputsHandler} style={{ fontSize: "0.75rem" }} value={inputField.Languages} className="col-md-3 textbox-padding mt-2 form-control m_width">
                                        <option >Select</option>
                                        <option value="en">English</option>
                                        <option value="it">Italian</option>
                                    </select>     
                                    <div className="col-md-2"></div>
                                    <label className="col-md-2 mt-2 text-left l_width">{t('UserList.UploadPicture')}</label>
                                    < Form.Control type="file" onChange={saveFileSelected} 
                                        defaultValue={inputField.UploadPic}
                                        name="UploadPic" className="mt-2 col-md-3 mt-2 text-left m_width" />                           
                                </div>
                                
                                <div className="row mt-2">
                                    <label className="col-md-2 mt-2 text-left l_width">{t('Roles')} <span className={classes.mandatory}>*</span></label>
                                    {/* <Multiselect className={class_multiselect}
                                        onSelect={handleChange}
                                        onRemove={handleChange}
                                        options={Roles}
                                        displayValue="Name"
                                        showCheckbox={true}
                                        selectedValues={selectedRoles}
                                        disable={disable}
                                        style={regionStyles}

                                    /> */}
                                    <div className="col-md-3">
                                        <button className="btn  btn-update btn-sm ml-2" onClick={(event) => ClickRolePopup()} style={{ width: "50px" }}>{t('Select')}</button>
                                        <a href='javascript:void(0)' onClick={() => ClickRolePopup()} style={{ fontSize: "10px", marginLeft:"10px" }}>({selectedRole.length}) {t('UserList.RoleAdded')}</a>
                                    </div>
                                    <div class="col-md-2"></div>
                                        <label className="col-md-2 mt-2 text-left l_width">{t('IsActive')} <span className={classes.mandatory}>*</span> </label>
                                        <input style={{ fontStyle: "normal", pointerEvents: "none" }} type="checkbox" checked={inputField.IsActive} id="isActive" className="mt-2" />
                                    </div>
                                <div className="row mt-2">
                                    <label className="col-md-2 mt-2 text-left l_width">{t('Groups')}<span className={classes.mandatory}>*</span></label>
                                    {/* <Multiselect className={class_multiselect}
                                        onSelect={handleChangeGroup}
                                        onRemove={handleChangeGroup}
                                        options={Groups}
                                        displayValue="Label"
                                        selectedValues={selectedGroups}
                                        disable={disable}
                                        style={regionStyles}
                                    /> */}
                                    <div className="col-md-3">
                                        <button className="btn  btn-update btn-sm ml-2" onClick={(event) => ClickGroupPopup()} style={{ width: "50px" }}>{t('Select')}</button>
                                        <a href='javascript:void(0)' onClick={() => ClickGroupPopup()} style={{ fontSize: "10px", marginLeft:"10px" }}>({selectedGroup.length}) {t('UserList.GroupAdded')}</a>
                                    </div>
                                </div>

                            </div>


                        </div>

                    </div>
                </div>
            </div>
            {
                showRolePopup === true ?
                    <div id="productsidepanel" className="productsidepanel sidepanelborder mr-1" style={{ width: "50vw", padding: "0" }}>
                        <div className="container" style={{ padding: "0" }}>
                            <div className="row">
                                <div className="col-lg-12 pr-lg-2">
                                    <div className="card text-center card_border">
                                        <div className="card-header chart-grid__header">
                                            <div className="row">
                                                <div className="col-md-12 text-left">
                                                    {t('Roles')}
                                                    <button className="btn btn-sm btn-update ml-1" onClick={() => ClickRolePopupCancel()} type="submit" style={{ float: "right", width: "50px" }}>{t('Cancel')} </button>
                                                    <button style={{ float: "right", width: "80px" }} className='btn btn-sm btn-update ml-1' onClick={okRole} >{t('Save&Close')}</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row mt-1" id="Customer">
                                <div className="col-lg-12 pr-lg-2">
                                    <div className="card text-center card_border">
                                        <div className='scrollbar-class mt-1'>
                                            <div className="container">
                                                <div>
                                                    <input type="text" className='form-control form-control-user' value={roleSerach} onChange={(event) => SearchRole(event)} placeholder={`${t('Search')} ${t('Roles')}`} />
                                                </div>
                                                <table className="table table-bordered text-left mt-2 " id="Customer">
                                                    <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                        <tr>
                                                            <th>
                                                                <input
                                                                    checked={roleCheckAll}
                                                                    type="checkbox"
                                                                    id="option-all-role"
                                                                    onChange={checkAllRole}
                                                                />
                                                            </th>
                                                            <th id="Name">{t('RoleList.RoleName')}</th>
                                                            <th id="Label" >{t('RoleList.RoleLabel')}</th>
                                                        </tr>
                                                    </thead>
                                                    <thead>
                                                        {
                                                            Roles.map((rlist, index) => (
                                                                <tr onClick={(event) => handleRoleSelection(event, rlist.Label, rlist.Id)}>
                                                                    <td key={rlist.Id} style={{ width: "30px" }}>
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={rlist.Selected}
                                                                            name="role-check"
                                                                            value={rlist.Id}
                                                                            id={rlist.Id}
                                                                            onChange={(event) => handleRoleChanges(event, rlist.Label, rlist.Id)}
                                                                        />
                                                                    </td>
                                                                    <td style={{ width: "90px" }}>
                                                                        <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                            title={rlist.Name}>{rlist.Name}</div>
                                                                    </td>
                                                                    <td style={{ width: "260px" }}>
                                                                        <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                            title={rlist.Label}>{rlist.Label}</div>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </thead>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    ""
            }
            {
                showGroupPopup === true ?
                    <div id="productsidepanel" className="productsidepanel sidepanelborder mr-1" style={{ width: "50vw", padding: "0" }}>
                        <div className="container" style={{ padding: "0" }}>
                            <div className="row">
                                <div className="col-lg-12 pr-lg-2">
                                    <div className="card text-center card_border">
                                        <div className="card-header chart-grid__header">
                                            <div className="row">
                                                <div className="col-md-12 text-left">
                                                    {t('Groups')}
                                                    <button className="btn btn-sm btn-update ml-1" onClick={() => ClickGroupPopupCancel()} type="submit" style={{ float: "right", width: "50px" }}>{t('Cancel')} </button>
                                                    <button style={{ float: "right", width: "80px" }} className='btn btn-sm btn-update ml-1' onClick={okGroup} >{t('Save&Close')}</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row mt-1" id="Customer">
                                <div className="col-lg-12 pr-lg-2">
                                    <div className="card text-center card_border">
                                        <div className='scrollbar-class mt-1'>
                                            <div className="container">
                                                <div>
                                                    <input type="text" className='form-control form-control-user' value={groupSerach} onChange={(event) => SearchGroup(event)} placeholder={`${t('Search')} ${t('Groups')}`} />
                                                </div>
                                                <table className="table table-bordered text-left mt-2 " id="Customer">
                                                    <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                        <tr>
                                                            <th>
                                                                <input
                                                                    checked={groupCheckAll}
                                                                    type="checkbox"
                                                                    id="option-all-role"
                                                                    onChange={checkAllGroup}
                                                                />
                                                            </th>
                                                            <th id="Name">{t('Policies.Name')}</th>
                                                            <th id="Label" >{t('Policies.Label')}</th>
                                                        </tr>
                                                    </thead>
                                                    <thead>
                                                        {
                                                            Groups.map((glist, index) => (
                                                                <tr onClick={(event) => handleGroupSelection(event, glist.Label, glist.Id)}>
                                                                    <td key={glist.Id} style={{ width: "30px" }}>
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={glist.Selected}
                                                                            name="role-check"
                                                                            value={glist.Id}
                                                                            id={glist.Id}
                                                                            onChange={(event) => handleGroupChanges(event, glist.Label, glist.Id)}
                                                                        />
                                                                    </td>
                                                                    <td style={{ width: "90px" }}>
                                                                        <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                            title={glist.Name}>{glist.Name}</div>
                                                                    </td>
                                                                    <td style={{ width: "260px" }}>
                                                                        <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                                            title={glist.Label}>{glist.Label}</div>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </thead>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    ""
            }
            <Modal show={closeModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>Your changes will be lost! Are you sure you want to close?</Modal.Body>
                <Modal.Footer>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "45px" }} size='sm' onClick={() => closeHandler("Close")}>
                        Close
                    </Button>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => closeHandler("Cancel")}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
        </div>
    </>)
}
export default EditUserComponent