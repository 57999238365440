import $, { data, event } from 'jquery';
import React, { useRef, useState, forwardRef, useEffect } from 'react';
import { Dropdown, Button, InputGroup, FormControl, Modal } from 'react-bootstrap';
import Service from '../../Services/Service';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import Multiselect from 'multiselect-react-dropdown';
import { CSVLink } from "react-csv";
import SessionManage from '../../SessionManagement/SessionManage';
import { useTranslation } from "react-i18next";
import { faL } from '@fortawesome/free-solid-svg-icons';
import DatePicker from "react-datepicker";
import CustomErrorPopup from '../../UI/CustomErrorPopup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from "react-select";
import './ChangeHistory.css';


function ChangeHistoryList(props) {
    const location = useLocation();
    const [userPerms, setuserPermission] = useState([]);
    const [csvList, setCsvList] = useState([]);
    const [headers, setHeaders] = useState([
        { label: "Module", key: "ModuleName" },
        { label: "Record ID", key: "RecordId" },
        { label: "Modified Field", key: "ModifiedField" },
        { label: "Old Value", key: "OldValue" },
        { label: "New Value", key: "NewValue" },
        { label: "Action Done By", key: "ActionDone" },
        { label: "Modified User Name", key: "ModifiedUserName" },
        { label: "Date", key: "ChangeDate" },

    ]);
    const [searchValue, setSearchValue] = useState(null)
    const csvInstance = useRef();
    const [sortColName, setSortColName] = useState("");
    const [sticky, setSticky] = useState("");
    const [t, i18n] = useTranslation('translation');
    var currentDate = new Date();
    var year = currentDate.getFullYear();
    var month = currentDate.getMonth();
    var day = currentDate.getDate();
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorStatus, setErrorStatus] = useState("Error");
    const [PrevSelectedTypeState, setPrevSelectedTypeState] = useState('Heirarchy');
    const [selectedType, setSelectedType] = useState('Attribute');
    const [rebatelinestatus, setrebatelinestatus] = useState('New');
    const [Products, SetProducts] = React.useState([]);
    const [clickProduct, setclickProduct] = useState('');


    const [showDeleteModal, toggleModal] = useState(false)
    const [showbutton, setshowbutton] = useState(false);
    const [totalselectedshow, settotalselectedshow] = useState('');
    const [totalselectedcount, settotalselectedcount] = useState(0);
    const [deleteshowbutton, setDeleteshowbutton] = useState('');
    const [showLoadMore, setshowLoadMore] = useState('');
    const [showfilterpopup, setshowfilterpopup] = useState(false);
    const [showspinner, setshowspinner] = useState('');
    const [totalCountlst, settotalCountlst] = useState('');
    const [filtertext, setfiltertext] = useState('');
    const [actionsDone, setActionsDone] = useState(props.id)
    const [paging, setpaging] = useState({
        pageSize: 20,
        pageNumber: 1
    })
    const [showRenewPopup, setShowRenewPopup] = useState(false)
    const [showRenewProductPopup, setShowRenewProductPopup] = useState(false)
    const [showScreen, setShowScreen] = useState('')
    const [showRplData, setshowRplData] = useState([])
    const [rplExcludedData, setExcludedRplData] = useState([])
    const [BgColor, setBgColor] = useState("")
    const [autoSubmit1, setautoSubmit1] = useState(false)
    const [autoSubmit2, setautoSubmit2] = useState(true)
    const [FilteredProducts, SetFilteredProducts] = useState([]);
    const [closeHeaderModal, setCloseHeaderModal] = useState(false);
    const [renewPeriod, setrenewPeriod] = useState
        ({
            startDate: "",
            endDate: ""
        })
    const [rebateValidity, setrebateValidity] = useState
        ({
            validFrom: "",
            ValidTo: ""
        })

    const [inputField, setInputField] = useState({
        addproductdata: true,
        excludeproductdata: false
    })
    const [showpopupcontnent, setshowpopupcontnent] = useState({
        value: 1
    })
    const [ExcludedProductList, SetExcludedProductList] = useState([]);
    const [ProductList, SetProductList] = useState([]);
    const [dualListBoxOptionsByHeirarchy, setdualListBoxOptionsByHeirarchy] = useState([])
    const [dualListBoxOptionsByAttribute, setdualListBoxOptionsByAttribute] = useState([])
    const [checkExcludeRpl, setcheckExcludeRpl] = useState(false);
    const [checkRplProducts, setcheckRplProducts] = useState(false);
    const [checkRplValidity, setcheckRplValidity] = useState(false);
    const [loading, setLoading] = useState(false);
    const [rplId, setRplId] = useState("");

    // New state to manage the Check All checkbox
    const [checkAllChecked, setCheckAllChecked] = useState(false);
    const [checkedValue, setCheckedValue] = useState([]);
    const [checkedMultiValueid, setCheckedMultiValueid] = useState([]);
    const [checkedValueid, setCheckedValueid] = useState([]);
    const [checkedValueStatus, setCheckedValueStatus] = useState([]);
    const [allCheckedHistory, setAllCheckedHistory] = useState(false);
    const [uncheckedBoxesHistory, setuncheckedBoxesHistory] = useState([]);

    const [renewData, setRenewData] = useState({
        Rebateid: 0,
        validityfrom: "",
        validityto: "",
        autosubmit: "",
        submit_comments: "",
        lineitems: []
    });





    const disabledClass = `  ${Service.editPerms(userPerms) == false ? "disabledClass" : ""}`;

    const checkAll = (mycheckbox) => {
        const checkboxes = document.querySelectorAll("input[type='checkbox']");
        if (mycheckbox.target.checked) {
            settotalselectedshow(true);
            setAllCheckedHistory(true);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = true;
            });
            settotalselectedcount(totalCountlst);
            setCheckAllChecked(true); // Set state to manage Check All checkbox
        } else {
            settotalselectedshow(false);
            setAllCheckedHistory(false);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = false;
            });
            settotalselectedcount(0);
            setCheckAllChecked(false); // Set state to manage Check All checkbox
        }
    };

    let rowData = [];
    let statusTableData = [];
    const [statusData, setStatusData] = useState(statusTableData)
    const [listViewData, setListViewData] = useState(rowData)
    const [filteredArray, setFilteredArray] = useState(rowData)
    const [state, setState] = useState({ status: '2' });
    const [selectedStatusValue, setSelectedStatusValue] = useState('0')
    const [multiValueids, setMultiValueIds] = useState([]);
    const [errorDeleteModal, setErrorDeleteModal] = useState(false);
    const [columnAscending, setColumnAscending] = useState(true);
    const [filterValue, setFilterValue] = useState([]);
    const [filterCol, setFilterCol] = useState('');
    const [dropdownData, setDropdownData] = useState([]);
    const [filterdropdownData, setFilterDropdownData] = useState([]);
    const [filterSearch, setFilterSearch] = useState("");
    const [dropdownChange, setDropdownChange] = useState([{ filterKey: "", FilterValue: "" }]);

    const [filterValueDict, setFilterValueDict] = useState({
        modified_date: null,
        created_date: null,
        rebate_id: '',
        programlinestatus: '',
        ModuleName: '',
        RecordId: '',
        NewValue: '',
        ModifiedUserName: '',
    });
    const [multiFilterValue, setMultiFilterValue] = useState({
        customer: '',
        modifiedby: '',
        createdby: '',
        ModifiedField: '',
        OldValue: '',
        ActionDone: '',

    });
    let loggedInUser = SessionManage.getuserid();
    const navigate = useNavigate();

    const customStyles = {
        control: base => ({
            ...base,
            minHeight: 28
        }),
        dropdownIndicator: base => ({
            ...base,
            padding: 2
        }),
        clearIndicator: base => ({
            ...base,
            padding: 2
        }),
        multiValue: base => ({
            ...base,

        }),
        valueContainer: base => ({
            ...base,
            padding: '0px 2px'
        }),
        input: base => ({
            ...base,
            margin: 0,
            padding: 0
        })
    };

    {
        useEffect(() => {

            getUserPermission(location.pathname);
            settotalselectedshow(false);

            getStatusData("status")

            setColumnAscending(false);
            setSortColName("Id");
            setshowspinner(true);

            getData("AuditHistory/GetAll", paging.pageSize, paging.pageNumber, "Descending", "Id", [], null);


            getColumnVisibilityList();
            getColVisibilityListData();
            window.addEventListener("scroll", isSticky);
            return () => {
                window.removeEventListener("scroll", isSticky);
            };
        }, [selectedStatusValue])
    }
    const getData = (method, pageSize, pageNumber, sortType, SortProperty, filterValue, search) => {
        Service.getDatawithMultiFilterSearch(method, pageSize, pageNumber, sortType, SortProperty, filterValue, search).then((res) => {
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && res.data !== 'undefined') {

                    setFilteredArray(res.data.DataList)
                    console.log(res.data.DataList, "All-Data");
                    settotalCountlst(res.data.Totalcount)
                    LoadMore(res.data.DataList.length, res.data.Totalcount)
                    setshowspinner(false)
                }
            }
        });

    }
    const isSticky = () => {
        const scrollTop = window.scrollY;
        const stickyClass = scrollTop >= 60 ? "is-sticky-thead" : "";
        setSticky(stickyClass);
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop(divtop1.offsetTop + scrollTopActual)
    };
    const class_thead = `text-white rt-thead  ${sticky}`;

    const getUserPermission = (pathname) => {
        Service.GetUserPermission(pathname).then((res) => {
            if (typeof res !== 'undefined') {

                if (res.data !== 'null' && typeof res.data !== 'undefined' && res.data !== '') {
                    if (res.data.DataList !== undefined) {
                        setuserPermission(res.data.DataList)
                    }
                }
            }
        });
    }


    const getStatusData = (method) => {
        Service.getAlldata(method).then((res) => {

            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && res.data !== 'undefined') {
                    statusTableData = res.data

                    setStatusData(statusTableData)

                }
            }
        });
    }

    const LoadMore = (lst, totalcount) => {

        if (lst < totalcount) {
            setshowLoadMore(true);
        }
        else if (lst >= totalcount) {
            setshowLoadMore(false);
        } else {
            setshowLoadMore(false);
        }

    }



    const searchHandler = (event) => {

        var sortType = columnAscending === true ? 'Ascending' : 'Descending';

        let pagenumber = 1;

        paging.pageNumber = pagenumber;
        setpaging(paging);
        if (event.target.value != '') {

            setSearchValue(String(event.target.value).toLowerCase())
            getData("AuditHistory/GetAll", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), event.target.value);
        }
        else {

            setSearchValue(null);
            getData("AuditHistory/GetAll", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), null);
        }
    }


    const handleDropDownChange = (event) => {
        let temp = event.target.value
        setSelectedStatusValue(temp)

    }


    const handleChange = (e, id, name, status) => {
        const isChecked = e.target.checked;
        const checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        let count = totalselectedcount;

        if (isChecked) {
            setCheckedValue(id);  // check box value - id
            setCheckedValueStatus(status); // check box value - Active status
            setCheckedValueid(name); // check box value - RA-0000
            setCheckedMultiValueid([...checkedMultiValueid, id]); // Add the checked id to the array
            count += 1;

            let tempUncheckedBoxesHistory = [...uncheckedBoxesHistory];
            const index = tempUncheckedBoxesHistory.findIndex((item) => item === id);
            if (index !== -1) {
                tempUncheckedBoxesHistory.splice(index, 1);
            }
            setuncheckedBoxesHistory(tempUncheckedBoxesHistory);

        } else {
            setuncheckedBoxesHistory([...uncheckedBoxesHistory, id]);
            setCheckedValue([]);  // check box value - id
            setCheckedValueStatus([]); // check box value - Active status
            setCheckedValueid([]); // check box value - RA-0000
            setCheckedMultiValueid(checkedMultiValueid.filter(item => item !== id)); // Remove the unchecked id from the array
            count -= 1;

        }

        settotalselectedcount(count);
        settotalselectedshow(count > 0);

        // Uncheck the "Check All" checkbox if any individual checkbox is unchecked
        if (!isChecked) {
            setCheckAllChecked(false);
        } else if (count === totalCountlst) {
            setCheckAllChecked(true);
        }
        if (checkedBoxes.length == 1) {
            const [Recordname, Recordstatus] = checkedBoxes[0].value.split(',');
            setCheckedValue(checkedBoxes[0].id)  // check box value - id
            setCheckedValueStatus(Recordstatus) // check box value - Active status
            setCheckedValueid(Recordname) //check box value - RA-0000
        }
    };


    const filterLst = () => {
        let list = [];
        for (var key in multiFilterValue) {
            if (multiFilterValue[key].length > 0) {
                let values = ""
                multiFilterValue[key].map((i) => {
                    values += i.Name + ";";
                })
                if (values !== null && values !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: values
                    })
                }
            }
        }

        for (var key in filterValueDict) {
            if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                list.push({
                    filterKey: key,
                    FilterValue: filterValueDict[key]
                })
            }
        }
        return list;
    }

    const handleLoadMore = () => {

        let pagenumber = paging.pageNumber + 1;
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        paging.pageNumber = pagenumber
        setpaging(paging);

        Service.getAllAudithistorySearch("AuditHistory/GetAll", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), searchValue).then((res) => {

            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            let rowData = res.data.DataList;

                            if (res.data.DataList !== 'null' && res.data.DataList !== 'undefined') {
                                const currentlength = listViewData.length;
                                setListViewData(listViewData => [...listViewData, ...res.data.DataList])
                                setFilteredArray(filteredArray => [...filteredArray, ...res.data.DataList])

                                setActionsDone(0)

                                LoadMore(currentlength + rowData.length, res.data.Totalcount)

                                const allCheckBoxes = document.querySelectorAll("input[type='checkbox']");
                                console.log(allCheckBoxes, "21-6");

                                if (!checkAllChecked && totalselectedcount != 0) {
                                    allCheckBoxes.forEach((each) => {
                                        const checkboxId = parseInt(each.id, 10);
                                        if (!isNaN(checkboxId)) {
                                            if (uncheckedBoxesHistory.includes(checkboxId)) {
                                                each.checked = false;
                                            } else {
                                                // each.checked = true;
                                                each.checked = checkedMultiValueid.includes(checkboxId);
                                            }
                                        }
                                    });
                                } else {
                                    allCheckBoxes.forEach((each) => {
                                        each.checked = checkAllChecked;
                                    });
                                }
                            }
                        }
                    }
                }
            }
        });
    };



    const OpenRebate = (event, id) => {
        if (event.detail === 2) {

            navigate("/specialClaimAgreement/edit/" + checkedValue)
        }
    }


    const SortColumn = (colName) => {

        var sortType = '';
        if (columnAscending === true) {
            setColumnAscending(false);
            sortType = "Descending";
        } else {
            setColumnAscending(true);
            sortType = "Ascending";
        }
        setSortColName(colName);

        let pagenumber = 1;

        paging.pageNumber = pagenumber;
        setpaging(paging);
        getData("AuditHistory/GetAll", paging.pageSize, paging.pageNumber, sortType, colName, filterLst(), searchValue);
    }


    const filterInput = (e) => {

        let inputValue = e.target.value
        setFilterValue(inputValue)
        setFilterValueDict(() => ({
            ...filterValueDict, [filterCol]: e.target.value,
        }));
    }


    const OndropdownChange = (e, id, name, filterCol) => {

        let isChecked = e.target.checked;
        let allChecked = false;


        let allCheckboxes = document.getElementsByName("filter-option-all");
        let totalChecked = 0;
        allCheckboxes.forEach((checkbox) => {
            if (checkbox.checked)
                totalChecked += 1;
        });

        let tempData = [...dropdownData];
        //If all checkboxes are checked
        if (tempData.length - 1 === totalChecked) {
            tempData[0].ischecked = isChecked;
            allChecked = true;
        }

        setDropdownData(tempData);

        console.log(tempData, "12x");

        var lastfilter = [...dropdownData]
        console.log(lastfilter, "last-f")
        if (isChecked === true) {
            let currentdata = lastfilter.map((i) => {
                if (i.Name == name) {
                    i["ischecked"] = true;
                }
                return i;
            });

            setDropdownData(currentdata);
            let data1 = []
            const getallData = {
                Name: name,
            }
            data1.push(getallData)
            setFilterValue(filterValue => [...filterValue, ...data1]);

            let updateddata = ([...filterValue, ...data1]);

            if (allChecked) {
                const selectAllObj = {
                    "Name": "Select All"
                };
                updateddata.splice(0, 0, selectAllObj);
            }

            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {
            let currentdata = lastfilter.map((i) => {
                if (i.Name === name) {
                    i["ischecked"] = false;
                }
                return i;
            });

            console.log(currentdata, "c-data");

            setDropdownData(currentdata);
            setFilterValue(filterValue => filterValue.filter(data => data.Name !== name))
            // let data1 = multiFilterValue[filterCol].filter(data => data.Name !== name);

            let data1 = multiFilterValue[filterCol] || [];
            data1 = data1.filter((data) => data.Name !== name);

            if (tempData.length - 1 >= totalChecked) {
                data1 = data1.filter(data => data.Name !== "Select All");
            }

            console.log(data1, "data");

            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: data1,
            }));
        }

    }


    const checkAllFilter = (mycheckbox) => {
        var lastfilter = [...dropdownData]
        let data1 = []
        var checkboxes = document.querySelectorAll("input[name = 'filter-option-all']");
        if (mycheckbox.target.checked == true) {

            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = true;
                return i;
            });
            setDropdownData(currentdata)

            dropdownData.map((each) => {

                const getallData = {
                    Name: each.Name,
                }
                data1.push(getallData)
            })
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {

            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = false;
                return i;
            });
            setFilterValue([])
            setMultiFilterValue([])
        }
    }




    const [divleft, setdivleft] = useState('');
    const [divtop, setdivtop] = useState('');

    const FilterColumn = (name) => {

        const scrollTop = window.scrollY;
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop((divtop1.offsetTop + 30) + scrollTopActual)


        setshowfilterpopup(true)
        setFilterCol(name)


        if (name === 'ModuleName') {
            setfiltertext("Module")
            var testDiv = document.getElementById("ModuleName");
            setdivleft((testDiv.offsetLeft + 30) + "px");
        }
        if (name === 'ActionDone') {
            var testDiv = document.getElementById("ActionDone");
            setdivleft((testDiv.offsetLeft - 100) + "px");
            setfiltertext(t('ChangeHistory.ActionType'))
            DropdownHandler(name)
        }
        if (name === 'ModifiedField') {
            var testDiv = document.getElementById("ModifiedField");
            setdivleft((testDiv.offsetLeft + 30) + "px");
            setfiltertext(t('Field'));
            DropdownHandler(name);
        }
        if (name === 'OldValue') {
            var testDiv = document.getElementById("OldValue");
            setdivleft((testDiv.offsetLeft + 30) + "px");
            setfiltertext("Old Value")

        }

        if (name === 'NewValue') {
            var testDiv = document.getElementById("NewValue");
            setfiltertext(t('New Value'))
            setdivleft(((testDiv.offsetLeft + 30) - 100) + "px");
        }

        if (name === 'ChangeDate') {
            var testDiv = document.getElementById("ChangeDate");
            setfiltertext(t('Date'))
            setdivleft(((testDiv.offsetLeft + 30) - 300) + "px");
        }

        if (name === 'RecordId') {
            var testDiv = document.getElementById("RecordId");
            setfiltertext(t('Record ID/Name'))
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'ModifiedUserName') {
            var testDiv = document.getElementById("ModifiedUserName");
            setdivleft((testDiv.offsetLeft - 100) + "px");
            setfiltertext(t('Action Done By'))
            DropdownHandler(name)
        }
        setshowfilterpopup(true)

    }





    const DropdownHandler = (key) => {
        Service.getfilterDropdownByGroup('AuditHistory/GetAuditHistoryFilterValuelst', key).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            let filteredData = res.data.filter(item => item.Value !== null);
                            addnewfield(filteredData, key);
                            console.log(res.data, "Multi-Data");
                        }
                    }
                }
            }

        });
    }

    const addSeletAll = (optionsArray, alreadySelected) => {

        let result = optionsArray;
        let selectAllObj = {
            "Name": "Select All",
            "Value": "Select All",
            "ischecked": alreadySelected
        }

        result.splice(0, 0, selectAllObj);
        console.log(result, "9999")
        return result;
    }

    const addnewfield = (data, key) => {

        let currentdata = data.map((i) => {
            i["ischecked"] = false;
            return i;
        });

        let selectedKeys = multiFilterValue[key] ? multiFilterValue[key] : [];

        console.log(currentdata, "test-data");
        let alreadySelected = selectedKeys.find((obj) => obj.Name === "Select All") ? true : false;

        if (key != 'StatusLabel') {
            if (selectedKeys) {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }

                    })
                })
            }


            let result = addSeletAll(currentdata, alreadySelected);
            setDropdownData(result);
            setFilterDropdownData(result)
        }
        else {
            let alreadySelected = selectedKeys.find((obj) => obj.Name === "Select All") ? true : false;
            if (selectedKeys) {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }
                    })
                })
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
            else {
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
        }
    }


    const UserfilterHandler = (e) => {

        setFilterSearch(e.target.value)
        if (e.target.value !== "") {
            let searchResults = dropdownData.filter((each) => {
                return each.Name.toLowerCase().includes(String(e.target.value).toLowerCase())
            });
            setDropdownData(searchResults);
        }
        else {

            setFilterSearch("")
            setDropdownData(filterdropdownData)
        }
    }

    const filterHandler = (flag) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;

        if (flag === 'Apply') {
            setshowfilterpopup(false)
            let list = [];

            for (var key in multiFilterValue) {
                if (multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
            if (list.length > 0) {
                setpaging(paging);
                getData("AuditHistory/GetAll", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);

            }

            let tempDropdownData = dropdownData;
            if (tempDropdownData.length > 0){
                tempDropdownData[0]["ischecked"] = true;}
            setDropdownData(tempDropdownData);
        }
        else {
            setshowfilterpopup(false)
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: '',
            }));
            setFilterValueDict(() => ({
                ...filterValueDict, [filterCol]: '',
            }));

            let list = [];
            for (var key in multiFilterValue) {
                if (key !== filterCol && multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '' && key !== filterCol) {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
            setpaging(paging);
            getData("AuditHistory/GetAll", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);
            setFilterCol('')
            setFilterValue([])
            setFilterSearch("")
            setDropdownData([])
            setFilterDropdownData([])
        }
    }



    const ExporttoCSV = () => {

        let checkedBoxes = document.querySelectorAll("input[name=chkBox]:checked");
        let values = "";
        if (checkedBoxes.length >= 1) {
            for (let checkbox of checkedBoxes) {
                values = values === "" ? checkbox.id : values + ";" + checkbox.id;
            }
        }

        let objFilter = [
            {
                filterKey: "id",
                FilterValue: values,
            },
        ];

        Service.getDatawithMultiFilterSearch("AuditHistory/GetAll", 20000, 1, "Ascending", "Id", checkAllChecked ? filterLst() : objFilter, null).then((res) => {
            console.log(res, 'api-resp');
            if (res !== undefined && res.data !== null && res.data !== undefined) {
                let csvLst = res.data.DataList;

                let selectedIds = values.split(';');

                let operatedList = csvLst
                //If checkall box is not checked
                if (!checkAllChecked) {
                    //If it has not been checked in history/past
                    if (!allCheckedHistory)
                        operatedList = csvLst.filter(item => selectedIds.includes(`${item.Id}`))
                    //If it has been checked in history/past
                    else
                        operatedList = csvLst.filter(item => !uncheckedBoxesHistory.includes(item.Id))
                }

                let filteredCsvList = operatedList.map(item => {
                    let newItem = {};
                    Object.keys(item).forEach(key => {
                        if (!hideColumn.includes(key)) {
                            if (key === 'ChangeDate') {
                                newItem[key] = `${moment(item[key]).format(dateFormat)} ${moment(item[key]).format(timeFormat)}`;
                            } else {
                                newItem[key] = item[key];
                            }
                        }
                    });
                    return newItem;
                });

                let filteredHeaders = headers.filter(header => !hideColumn.includes(header.key));

                console.log(filteredCsvList, "filtered");

                setCsvList(filteredCsvList);
                setHeaders(filteredHeaders);

                setTimeout(() => {
                    toast.success('Exported Successfully !', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    csvInstance.current.link.click();
                });
            }
        });
    }

    const customMultiStyles = {
        multiselectContainer: {
            maxWidth: '188px',
            marginLeft: '3px'
        },
        chips: {
            borderRadius: '5px'
        }
    };



    // -------------------------Column Manager---------------

    const columnList = [
        { "label": "ChangeHistory.Module", "key": "ModuleName", "value": true },
        { "label": "ChangeHistory.RecordId", "key": "RecordId", "value": true },
        { "label": "ChangeHistory.ModifiedField", "key": "ModifiedField", "value": true },
        { "label": "ChangeHistory.OldValue", "key": "OldValue", "value": true },
        { "label": "ChangeHistory.NewValue", "key": "NewValue", "value": true },
        { "label": "ChangeHistory.ActionType", "key": "ActionDone", "value": true },
        { "label": "ChangeHistory.ModifiedUserName", "key": "ModifiedUserName", "value": true },
        { "label": "ChangeHistory.Date", "key": "ChangeDate", "value": true },

    ]
    const [addRemoveCol, setAddRemoveCol] = useState(columnList);
    const [createColumnListOption, setCreateColumnListOption] = useState(columnList)
    const [showAddColumnPopup, setShowAddColumnPopup] = useState(false)
    const [showCreateColumnListPopup, setShowCreateColumnListPopup] = useState(false)
    const [hideColumn, setHideColumn] = useState([]);
    const [resetColumnKey, setResetColumnKey] = useState([]);
    const [colVisibilityOption, setColVisibilityOption] = useState([]);
    const [colvisiOptionVal, setColvisiOptionVal] = useState({ value: null, label: 'Default' })
    const [divleft1, setdivleft1] = useState('');
    const [divtop1, setdivtop1] = useState('');
    const [existingViewVal, setExistingViewVal] = useState(false);
    const [defaultView, setDefaultView] = useState(false);
    const [colViewName, setColViewName] = useState('');
    const [recorddId, setRecordId] = useState(0);


    const OnChangedefaultView = (event,) => {
        setDefaultView(event.target.checked)
    }
    const OnChangeexistingViewVal = (event,) => {
        setExistingViewVal(event.target.checked)
    }

    const OnChangeSetViewName = (event,) => {
        setColViewName(event.target.value)
    }

    const OnChangeColVisibilityList = (data) => {
        setColvisiOptionVal({ value: data.value, label: data.label });
        getColVisibilityListData(data.value)
    }
    const CreateColumnListPopup = () => {
        setShowCreateColumnListPopup(true)
        var divtop1 = document.getElementById("addRemoveCol");
        setdivtop1(divtop1.offsetTop + 30)
        setdivleft1((divtop1.offsetLeft) + 100 + "px");
    }

    const CloseCreateColumnListPopup = () => {
        setShowCreateColumnListPopup(false)
        setColViewName('')
        setDefaultView(false)
    }

    const AddRemoveColumn = () => {
        setShowAddColumnPopup(true)
        var divtop1 = document.getElementById("addRemoveCol");
        setdivtop(divtop1.offsetTop + 45)
        setdivleft((divtop1.offsetLeft) + "px");
        getColumnVisibilityList()
    }
    const OnchangeAddRemoveCol = (event, column) => {
        debugger
        let isChecked = event.target.checked;
        let lst = [...addRemoveCol];
        for (let d in lst) {
            if (lst[d].key == column.key && isChecked) {
                lst[d]['value'] = true
            }
            else if (lst[d].key == column.key && !isChecked) {
                lst[d]['value'] = false
            }
        }
        setResetColumnKey([...resetColumnKey, column.key])
        setAddRemoveCol(lst)
    }
    const OnchangeCreateColumnListOption = (event, column) => {
        debugger
        let isChecked = event.target.checked;
        let lst = [...createColumnListOption];
        for (let d in lst) {
            if (lst[d].key == column.key && isChecked) {
                lst[d]['value'] = true
            }
            else if (lst[d].key == column.key && !isChecked) {
                lst[d]['value'] = false
            }
        }
        setCreateColumnListOption(lst)
    }
    const updateColumnVisibilityList = (tag) => {
        let lst = [...addRemoveCol];
        if (tag == 'reset') {
            for (let d in lst) {
                lst[d]['value'] = true
            }
            setHideColumn([])
            setAddRemoveCol(lst)
        }
        else {
            debugger
            let data = [];
            for (let d in lst) {
                if (!lst[d].value) {
                    data.push(lst[d].key)
                }
            }
            setHideColumn(data)
        }
        let viewName = colvisiOptionVal.value;
        Service.setColumnVisibilityListData('ColumnVisibility/CreateOrUpdateColumnList', recorddId, loggedInUser, 'changeHistoryList', viewName, existingViewVal, lst).then((res) => { })
        setShowAddColumnPopup(false)
        setResetColumnKey([])
    }

    const CreateColumnVisibilityList = (tag) => {
        let veiwName = colViewName;
        let idDefault = defaultView;
        if (veiwName.trim() == 'undefined' || veiwName.trim() == '' || veiwName.trim() == null) {
            setErrorStatus("Error")
            setErrorMessage("Please set View Name")
            setShowErrorModal(true)
        }
        else {
            let lst = [...createColumnListOption];
            if (tag == 'reset') {
                for (let d in lst) {
                    lst[d]['value'] = true
                }
                setHideColumn([])
                setAddRemoveCol(lst)
            }
            else {
                let data = [];
                for (let d in lst) {
                    if (!lst[d].value) {
                        data.push(lst[d].key)
                    }
                }
                setHideColumn(data)
            }
            Service.setColumnVisibilityListData('ColumnVisibility/CreateOrUpdateColumnList', 0, loggedInUser, 'rebateAgreementList', colViewName, defaultView, lst).then((res) => {
                getColumnVisibilityList()
                setColvisiOptionVal({ value: colViewName, label: colViewName });
                getColVisibilityListData(colViewName)
                CloseCreateColumnListPopup()
                setShowAddColumnPopup(false)
            })
        }
    }

    const closeAddRemoveColumnPopup = () => {
        let lst = [...addRemoveCol];
        let resetKey = [...resetColumnKey];
        for (let rk in resetKey) {
            for (let d in lst) {
                if (lst[d].key == resetKey[rk]) {
                    lst[d]['value'] = !lst[d].value
                }
            }
        }
        setAddRemoveCol(lst)
        setShowAddColumnPopup(false)
        setResetColumnKey([])

    }

    const getColumnVisibilityList = () => {
        Service.getColumnVisibilityListData('ColumnVisibility/GetColumnList', loggedInUser, 'changeHistoryList', null).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {

                        if (typeof res.data !== 'undefined' && res.data !== 'null') {
                            let data = []
                            for (let d in res.data) {
                                data.push({ value: res.data[d].label, label: res.data[d].label })

                            }

                            setColVisibilityOption(data)
                        }
                    }
                }
            }
        })
    }


    const getColVisibilityListData = (viewName = null) => {
        if (!viewName) {
            viewName = colvisiOptionVal.value
        }
        if (viewName == 'Deleted') {
            viewName = null
        }

        Service.getColumnVisibilityListData('ColumnVisibility/GetColumnListJsonData', loggedInUser, 'changeHistoryList', viewName).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (typeof res.data.jsonData !== 'undefined' && res.data.jsonData !== 'null') {

                            let jsonData = JSON.parse(res.data.jsonData)
                            let data = [];
                            for (let d in jsonData) {
                                if (!jsonData[d].value) {
                                    data.push(jsonData[d].key)
                                }
                            }
                            setColvisiOptionVal({ value: res.data.label, label: res.data.label });
                            setHideColumn(data)
                            setAddRemoveCol(jsonData)
                            setExistingViewVal(res.data.defaultView)
                            setResetColumnKey([])
                            setRecordId(res.data.id)
                        }
                    }
                }
            }
        })
    }

    const deleteColumnVisibilityView = () => {
        Service.deleteDataByPolicyID("ColumnVisibility/Delete", recorddId, loggedInUser).then(res => {
            if (res.status === 200) {
                getColumnVisibilityList()
                getColVisibilityListData('Deleted')
                setShowAddColumnPopup(false)
                toast.error('Deleted Successfully !', {
                    position: toast.POSITION.TOP_RIGHT,
                });

            }
        })
    }

    function splitByCapitalLetters(input) {
        // Handle specific cases first
        if (input === null || input === undefined) return ''; // or a default value or handling

        if (input === "Minthresholdpolicy") return "Min Threshold Policy";
        if (input === "Minthreshold Policies") return "Min Threshold Policies";
        if (input === "MinthresholdPolicy") return "Min Threshold Policy";
        if (input === "Targetachievementpolicy") return "Target Achievement Policy";
        if (input === "EECPolicy") return "ECC Policy";
        if (input === "Sparestarget Policies") return "Spares Target Policy";
        if (input === "Slabbased Policies") return "Slab Based Policy";
        if (input === "Slabbasedpolicy") return "Slab Based Policy";
        if (input === "QuarterfixedvaluePolicy") return "Quarter Fixed Value Policy";
        if (input === "Quarterfixedvalue Policies") return "Quarter Fixed Value Policies";

        // General case splitting logic
        return input
            .replace(/([A-Z]+)([A-Z][a-z])/g, '$1 $2') // Split before the last capital letter if followed by a lowercase letter
            .replace(/([a-z])([A-Z])/g, '$1 $2')       // Split between lowercase and uppercase
            .replace(/\s+/g, ' ')                      // Ensure no multiple spaces
            .trim();                                   // Remove leading/trailing spaces
    }


    const dateFormat = SessionManage.getcurrentdateformat();
    const timeFormat = "hh:mm:ss A";

    // ---------------------------------------






    return (
        <>
            <div id='overlay'>
                <div className="container-fluid content-top-gap">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb my-breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">{t('ChangeHistory.ChangeHistoryList')}</li>
                        </ol>
                    </nav>
                    {loading ? <><div className="loader-container"><div className="spinner"></div> </div> </> : ''}
                    <div style={{ display: "none" }}>
                        <CSVLink data={csvList} headers={headers} ref={csvInstance} filename="ChangeHistory.csv" > </CSVLink> </div>
                    <ToastContainer autoClose={1000} />

                    <div className="">
                        <div className="row">
                            <div className="col-lg-12 pr-lg-2">
                                <div className="card text-center card_border overflow-auto">

                                    <div className="card-body" style={{ minHeight: "70vh", width: "99.5%" }}>
                                        <div className='row'>
                                            <div className="col-md-6">
                                                <div className='d-flex align-items-center mb-1'>
                                                    <input type="text" className="form-control form-control-user col-md-10" id="firstName" name="firstName" autoComplete='off' placeholder={t('Search for Record ID, Module, Label, Field, Old Value, New Value Or Action Done By')} onChange={(event) => searchHandler(event)} />
                                                    <button id="addRemoveCol" icon className="btn btn-update ml-2  pa-0" type="submit"
                                                        style={{ float: "left", cursor: "pointer", padding: '0px', height: '28px', width: '26px' }}
                                                        onClick={AddRemoveColumn} title='Manage View'><i class="fa fa-columns" aria-hidden="true"></i></button>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className='row'>
                                                    <div className="col-md-12 d-flex justify-content-end align-items-center">
                                                        <div style={{ display: 'inline-block', marginRight: '10px' }}>

                                                            {totalselectedcount > 0 && Service.exportPerms(userPerms) && (
                                                                <button className="btn btn-update btn-sm ml-2" type="submit" style={{ width: "50px" }} onClick={(event) => ExporttoCSV()}>{t('Export')}</button>
                                                            )}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="scrollbar-class mb-1">
                                            <table className="table table-bordered text-left mt-2" id="tableA">
                                                <thead className="text-white is-sticky-thead-product" style={{ backgroundColor: "var(--primary)" }}>
                                                    <tr style={{ position: "sticky", top: "0", backgroundColor: "var(--primary)" }}>
                                                        <th className="tableheader" style={{ width: '30px' }}>
                                                            <input
                                                                type="checkbox"
                                                                onChange={checkAll}
                                                                checked={checkAllChecked}
                                                                id="option-all"
                                                            />
                                                        </th>


                                                        <th id="ModuleName" style={{ backgroundColor: (filterValueDict.ModuleName ? '#6cae55' : ''), minWidth: "130px" }} hidden={hideColumn.includes('ModuleName') ? true : false}>
                                                            <div className='d-flex text-nowrap'>
                                                                <span>{t('ChangeHistory.Module')} </span>
                                                                <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('ModuleName')}></span>
                                                                <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('ModuleName')}></span>
                                                            </div>
                                                        </th>
                                                        <th id="RecordId" style={{ backgroundColor: (filterValueDict.RecordId ? '#6cae55' : ''), minWidth: "110px" }} hidden={hideColumn.includes('RecordId') ? true : false}>
                                                            <div className='d-flex text-nowrap'>
                                                                <span>{t('ChangeHistory.RecordId')}/Name</span>
                                                                <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('RecordId')}></span>
                                                                <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('RecordId')}></span>
                                                            </div>
                                                        </th>
                                                        <th id="ModifiedField" style={{ backgroundColor: (multiFilterValue.ModifiedField ? '#6cae55' : ''), minWidth: "150px" }} hidden={hideColumn.includes('ModifiedField') ? true : false}>
                                                            <div className='d-flex text-nowrap'>
                                                                <span>{t('ChangeHistory.ModifiedField')}</span>
                                                                <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('ModifiedField')}></span>
                                                                <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('ModifiedField')}></span>
                                                            </div>
                                                        </th>
                                                        <th id="OldValue" style={{ backgroundColor: (filterValueDict.OldValue ? '#6cae55' : ''), minWidth: "150px" }} hidden={hideColumn.includes('OldValue') ? true : false}>
                                                            <div className='d-flex text-nowrap'>
                                                                <span>{t('ChangeHistory.OldValue')}</span>
                                                                <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('OldValue')}></span>
                                                                <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('OldValue')}></span>
                                                            </div>
                                                        </th>
                                                        <th id="NewValue" style={{ backgroundColor: (filterValueDict.NewValue ? '#6cae55' : ''), minWidth: "250px" }} hidden={hideColumn.includes('NewValue') ? true : false}>
                                                            <div className='d-flex text-nowrap'>
                                                                <span>{t('ChangeHistory.NewValue')}</span>
                                                                <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('NewValue')}></span>
                                                                <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('NewValue')}></span>
                                                            </div>
                                                        </th>
                                                        <th id="ActionDone" style={{ backgroundColor: (filterValueDict.ActionDone ? '#6cae55' : ''), minWidth: "110px" }} hidden={hideColumn.includes('ActionDone') ? true : false}>
                                                            <div className='d-flex text-nowrap'>
                                                                <span>{t('ChangeHistory.ActionType')}</span>
                                                                <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('ActionDone')}></span>
                                                                <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('ActionDone')}></span>
                                                            </div>
                                                        </th>
                                                        <th id="ModifiedUserName" style={{ backgroundColor: (filterValueDict.ModifiedUserName ? '#6cae55' : ''), minWidth: "110px" }} hidden={hideColumn.includes('ModifiedUserName') ? true : false}>
                                                            <div className='d-flex text-nowrap'>
                                                                <span>{t('ChangeHistory.ModifiedUserName')}</span>
                                                                <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('ModifiedUserName')}></span>
                                                                <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('ModifiedUserName')}></span>
                                                            </div>
                                                        </th>
                                                        <th id="ChangeDate" style={{ backgroundColor: (multiFilterValue.ChangeDate ? '#6cae55' : ''), minWidth: "140px" }} hidden={hideColumn.includes('ChangeDate') ? true : false}>
                                                            <div className='d-flex text-nowrap'>
                                                                <span>{t('ChangeHistory.Date')}</span>
                                                                <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('ChangeDate')}></span>
                                                                <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('ChangeDate')}></span>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        showspinner === true ?
                                                            <tr>
                                                                <>
                                                                  <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                                  <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                                  <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                                </>
                                                            </tr>

                                                            :
                                                            filteredArray.length === 0 ?

                                                                <tr><td></td>
                                                                    <td colSpan="9" style={{ textAlign: 'center' }}>
                                                                        {t('NoDataAvailable')}
                                                                    </td>
                                                                </tr>
                                                                :
                                                                filteredArray.map((listVal, index) =>
                                                                    <tr >

                                                                        <td><input data-index="1" id={listVal.Id} name="chkBox" value={`${listVal.RecordId},${""}`} type="checkbox" onChange={(event) => handleChange(event, listVal.Id)} /></td>

                                                                        <td hidden={hideColumn.includes('ModuleName') ? true : false} ><div style={{ textOverflow: "ellipsis", overflow: "hidden", maxHeight: "15px", minWidth: "190px", maxWidth: "240px" }} data-toggle="tooltip" data-placement="right" title={listVal.ModuleName}>{splitByCapitalLetters(listVal.ModuleName)}</div></td>
                                                                        <td hidden={hideColumn.includes('RecordId') ? true : false} ><div style={{ overflow: "hidden", maxWidth: "140px", minWidth: "100px", maxHeight: "15px" }} data-toggle="tooltip" data-placement="right" title={splitByCapitalLetters(listVal.RecordId)}>{listVal.RecordId}</div></td>
                                                                        <td hidden={hideColumn.includes('ModifiedField') ? true : false}><div style={{ overflow: "hidden", maxWidth: "140px", minWidth: "80px", maxHeight: "15px" }} data-toggle="tooltip" data-placement="right" title={splitByCapitalLetters(listVal.ModifiedField)}>{splitByCapitalLetters(listVal.ModifiedField)}</div></td>
                                                                        {/* <td hidden={hideColumn.includes('OldValue') ? true : false}><div style={{ textOverflow: "ellipsis", overflow: "hidden", maxHeight: "15px",minWidth:"150px",maxWidth:"240px" }} data-toggle="tooltip" data-placement="right" title={listVal.OldValue}>{listVal.OldValue}</div></td> */}
                                                                        {/* <td hidden={hideColumn.includes('OldValue') ? true : false}>
                                                                            <div style={{ textOverflow: "ellipsis", overflow: "hidden", maxHeight: "15px", minWidth: "150px", maxWidth: "240px" }} data-toggle="tooltip" data-placement="right" title={moment(listVal.OldValue, 'YYYY-MM-DD HH:mm:ssZ', true).isValid() ? `${moment(listVal.OldValue).format(dateFormat)} ${moment(listVal.OldValue).format(timeFormat)}` : listVal.OldValue}>
                                                                                {moment(listVal.OldValue, 'YYYY-MM-DD HH:mm:ssZ', true).isValid() ? `${moment(listVal.OldValue).format(dateFormat)} ${moment(listVal.OldValue).format(timeFormat)}` : listVal.OldValue}
                                                                            </div>
                                                                        </td> */}
                                                                        <td hidden={hideColumn.includes('OldValue') ? true : false}>
                                                                             <div style={{ textOverflow: "ellipsis", overflow: "hidden", maxHeight: "15px", minWidth: "150px", maxWidth: "240px" }} data-toggle="tooltip" data-placement="right" title={
                                                                                   moment(listVal.OldValue, ['YYYY-MM-DD HH:mm:ssZ', 'YYYY-MM-DD'], true).isValid() ? moment(listVal.OldValue, 'YYYY-MM-DD', true).isValid() ? moment(listVal.OldValue).format(dateFormat)
                                                                                         : `${moment(listVal.OldValue).format(dateFormat)} ${moment(listVal.OldValue).format(timeFormat)}`: listVal.OldValue}>
                                                                                         {moment(listVal.OldValue, ['YYYY-MM-DD HH:mm:ssZ', 'YYYY-MM-DD'], true).isValid() ? moment(listVal.OldValue, 'YYYY-MM-DD', true).isValid() ? moment(listVal.OldValue).format(dateFormat)
                                                                                         : `${moment(listVal.OldValue).format(dateFormat)} ${moment(listVal.OldValue).format(timeFormat)}`: listVal.OldValue}
                                                                             </div>
                                                                        </td>

                                                                        {/* <td hidden={hideColumn.includes('NewValue') ? true : false}><div style={{ textOverflow: "ellipsis", overflow: "hidden", maxHeight: "15px",minWidth:"150px",maxWidth:"240px" }} data-toggle="tooltip" data-placement="right" title={listVal.NewValue}>{listVal.NewValue}</div></td> */}
                                                                        {/* <td hidden={hideColumn.includes('NewValue') ? true : false}>
                                                                            <div style={{ textOverflow: "ellipsis", overflow: "hidden", maxHeight: "15px", minWidth: "150px", maxWidth: "240px" }} data-toggle="tooltip" data-placement="right" title={moment(listVal.NewValue, 'YYYY-MM-DD HH:mm:ssZ', true).isValid() ? `${moment(listVal.NewValue).format(dateFormat)} ${moment(listVal.NewValue).format(timeFormat)}` : listVal.NewValue}>
                                                                                {moment(listVal.NewValue, 'YYYY-MM-DD HH:mm:ssZ', true).isValid() ? `${moment(listVal.NewValue).format(dateFormat)} ${moment(listVal.NewValue).format(timeFormat)}` : listVal.NewValue}
                                                                            </div>
                                                                        </td> */}
                                                                        <td hidden={hideColumn.includes('NewValue') ? true : false}>
                                                                             <div style={{ textOverflow: "ellipsis", overflow: "hidden", maxHeight: "15px", minWidth: "150px", maxWidth: "240px" }} data-toggle="tooltip" data-placement="right" title={
                                                                                   moment(listVal.NewValue, ['YYYY-MM-DD HH:mm:ssZ', 'YYYY-MM-DD'], true).isValid() ? moment(listVal.NewValue, 'YYYY-MM-DD', true).isValid() ? moment(listVal.NewValue).format(dateFormat)
                                                                                         : `${moment(listVal.NewValue).format(dateFormat)} ${moment(listVal.NewValue).format(timeFormat)}`: listVal.NewValue}>
                                                                                         {moment(listVal.NewValue, ['YYYY-MM-DD HH:mm:ssZ', 'YYYY-MM-DD'], true).isValid() ? moment(listVal.NewValue, 'YYYY-MM-DD', true).isValid() ? moment(listVal.NewValue).format(dateFormat)
                                                                                         : `${moment(listVal.NewValue).format(dateFormat)} ${moment(listVal.NewValue).format(timeFormat)}`: listVal.NewValue}
                                                                             </div>
                                                                        </td>
                                                                        <td hidden={hideColumn.includes('ActionDone') ? true : false}><div style={{overflow: "hidden", maxWidth:"100px",minWidth:"80px", maxHeight: "15px"}}>{listVal.ActionDone}</div></td>
                                                                        <td hidden={hideColumn.includes('ModifiedUserName') ? true : false}><div style={{overflow: "hidden", maxWidth:"100px",minWidth:"80px", maxHeight: "15px"}}>{listVal.ModifiedUserName}</div></td>
                                                                        <td hidden={hideColumn.includes('ChangeDate') ? true : false}><div style={{overflow: "hidden", maxWidth:"140px",minWidth:"100px", maxHeight: "15px"}} >{`${moment(listVal.ChangeDate).format(dateFormat)} ${moment(listVal.ChangeDate).format(timeFormat)}`}</div></td>


                                                                    </tr>

                                                                )


                                                    }

                                                </tbody>

                                            </table>
                                        </div>

                                        <div className='row'>
                                            {
                                                totalselectedshow === true ?

                                                    <div className='col-lg-2 forgettext col-sm-12 border form-control' style={{ textAlign: "center", marginLeft: "0.938rem" }}>
                                                        {t('TotalRecordsSelected')} - <span id="totalselected">{totalselectedcount}</span>
                                                    </div>
                                                    :
                                                    <div className='col-lg-2 col-sm-12' style={{ textAlign: "center", marginLeft: "0.938rem" }}>

                                                    </div>
                                            }

                                            <div style={{flex: "0 0 62.5%",maxWidth:"62.5%"}}></div>
                                            <div className='col-lg-2 forgettext  col-sm-12 border form-control' style={{ textAlign: "center",marginLeft: "0.1rem" }}>
                                                {t('TotalRecords')} - {totalCountlst}
                                            </div>
                                        </div>
                                    </div>
                                    {
                                            showLoadMore === true ?
                                                <div className='text-center container row mb-1'>
                                                    <div className='col-md-5'></div>
                                                    <div className='col-md-2'>
                                                        <button className='btn btn-sm btn-update ml-1' onClick={handleLoadMore}>{t('LoadMore')}</button>
                                                    </div>
                                                    <div className='col-md-5'></div>

                                                </div>
                                                :
                                                ""
                                        }
                                    {showfilterpopup === true ?
                                        <div className='pop-up' style={{ left: divleft, top: divtop }}>
                                            {filterCol === 'ModuleName' || filterCol === 'RecordId' || filterCol == 'ModifiedField' || filterCol == 'OldValue' || filterCol == 'NewValue' || filterCol == 'ActionDone' ?
                                                <>
                                                    <div className="container">
                                                        <div className="row">
                                                            <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                        </div>
                                                    </div>
                                                    <div className="container mt-2">
                                                        <div className="row">
                                                            <input type='textbox' placeholder={t('Search')} className='filtertextboxbackground col-md-11 textbox-padding ml-2' style={{ outline: 'none' }} value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                                        </div>
                                                    </div>
                                                </>

                                                :
                                                <>
                                                </>
                                            }

                                            {filterCol === 'ChangeDate' ?
                                                <>
                                                    <div className="container">
                                                        <div className="row">
                                                            <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                        </div>
                                                    </div>
                                                    <div className="container mt-2">
                                                        <div className="row">
                                                            <input type='date' className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                            }

                                            {filterCol === 'ModifiedUserName' ?

                                                <>
                                                    <div className="container">
                                                        <div className="row">
                                                            <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>

                                                        </div>

                                                    </div>

                                                    <div className="container mt-2">
                                                        <div className="row">
                                                            <input type="text" placeholder="Search" value={filterSearch} onChange={(event) => UserfilterHandler(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                                                        </div>

                                                        <div className="row contentoverflow mt-2">
                                                            <table className="filtertable">
                                                                <tbody>
                                                                    {/* <tr>
                                                                        <td><input type="checkbox" name="filter-option-all" id="option-all" onChange={checkAllFilter} /></td>
                                                                        <td>Select All</td>
                                                                    </tr> */}
                                                                    {
                                                                        dropdownData.map((item, index) => (

                                                                            <tr key={index}>

                                                                                <td><input id={item.Id} data-index="1" name="filter-option-all" checked={item.ischecked} type="checkbox" onChange={item.Name === "Select All" ? checkAllFilter : (event) => OndropdownChange(event, item.Id, item.Name, filterCol)} /></td>

                                                                                <td>{item.Name}</td>

                                                                            </tr>


                                                                        ))
                                                                    }

                                                                </tbody>
                                                            </table>

                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                            }
                                            <div style={{ paddingTop: '10px', paddingLeft: '47px', paddingBottom: '7px' }}>
                                                <button className="btn btn-sm btn-update" onClick={() => filterHandler('Apply')} style={{ width: "45px" }}>{t('Apply')}</button>
                                                <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "45px" }} onClick={() => filterHandler('Reset')}>{t('Reset')}</button>
                                            </div>
                                            <div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                    }





                                    {showAddColumnPopup === true ?
                                        <div className='pop-up' style={{ left: divleft, top: divtop, width: "250px" }}>
                                            <div className="container">
                                                <div className="row">
                                                    <Select
                                                        className='col-md-10 textbox-padding fontsizedropdown'
                                                        options={colVisibilityOption}
                                                        placeholder="Select"
                                                        value={colvisiOptionVal}
                                                        onChange={OnChangeColVisibilityList}
                                                        styles={customStyles}
                                                    />
                                                    <button id="createColumnListPopup" icon className="btn btn-update ml-1 pa-0" type="submit"
                                                        style={{ float: "left", cursor: "pointer", padding: '0px', height: '28px', width: '26px' }}
                                                        onClick={CreateColumnListPopup} title='Add New View'><i class="fa fa-plus" aria-hidden="true"></i></button>
                                                </div>
                                                <div className="row mb-2" style={{ textAlign: 'right' }}>
                                                    <div className="col-md-10 mt-1 mr-1" style={{ padding: '0px', fontSize: '12px' }}>
                                                        <input id="existingViewVal" data-index="1" name="filter-option-all" checked={existingViewVal} type="checkbox"
                                                            onChange={(event) => OnChangeexistingViewVal(event)} /> <b>Is Default</b>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <table className='ml-2' style={{ fontSize: '0.80rem' }}>
                                                        {
                                                            addRemoveCol.map((item, index) => (
                                                                <tr>
                                                                    <td key={index}><input id={item.label} data-index="1" name="filter-option-all" checked={item.value} type="checkbox"
                                                                        onChange={(event) => OnchangeAddRemoveCol(event, item)}
                                                                    /> {t(item.label)}</td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </table>
                                                </div>
                                                <div className="row mr-2" style={{ float: "right" }}>
                                                    <div style={{ paddingTop: '10px', paddingLeft: '15px', paddingBottom: '7px' }}>
                                                        <button className="btn btn-sm btn-update" style={{ width: "45px" }} onClick={() => updateColumnVisibilityList('reset')}>{t('Reset')}</button>
                                                        <button className="btn btn-sm btn-update ml-1" style={{ width: "45px" }} onClick={closeAddRemoveColumnPopup}>{t('Close')}</button>
                                                        <button className="btn btn-sm btn-update ml-1" style={{ width: "55px", background: '#4755ab', color: 'white' }} onClick={() => updateColumnVisibilityList('apply')} title='Update View'>{t('Update')}</button>
                                                        <button className="btn btn-sm btn-update ml-1" style={{ width: "50px", background: 'rgb(233 43 0)', color: 'white' }} onClick={deleteColumnVisibilityView} title='Delete View'>{t('Delete')}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : <></>
                                    }


                                    {showCreateColumnListPopup === true ?
                                        <div className='pop-up' style={{ left: divleft1, top: divtop1, width: "190px" }}>
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-12 ml-2" style={{ padding: '0px' }}>
                                                        <input type='textbox' placeholder='View Name' style={{ height: '30px' }}
                                                            value={colViewName} name={colViewName} onChange={(event) => OnChangeSetViewName(event)}></input>
                                                    </div>
                                                </div>
                                                <div className="row mb-2" style={{ textAlign: 'right' }}>
                                                    <div className="col-10 mt-2" style={{ padding: '0px', fontSize: '12px' }}>
                                                        <input id="defaultView" data-index="1" name="filter-option-all" checked={defaultView} type="checkbox"
                                                            onChange={(event) => OnChangedefaultView(event)} /> <b>Set Default</b>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <table className='ml-2' style={{ fontSize: '0.80rem' }}>
                                                        {
                                                            createColumnListOption.map((item, index) => (
                                                                <tr>
                                                                    <td key={index}><input id={item.label} data-index="1" name="filter-option-all" checked={item.value} type="checkbox"
                                                                        onChange={(event) => OnchangeCreateColumnListOption(event, item)}
                                                                    /> {t(item.label)}</td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </table>
                                                </div>
                                                <div className="row mr-2" style={{ float: "right" }}>
                                                    <div style={{ paddingTop: '10px', paddingLeft: '35px', paddingBottom: '7px' }}>
                                                        <button className="btn btn-sm btn-update ml-1" style={{ width: "55px" }} onClick={CloseCreateColumnListPopup}>{t('Cancel')}</button>
                                                        <button className="btn btn-sm btn-update ml-1" style={{ width: "55px", background: '#4755ab', color: 'white' }} onClick={() => CreateColumnVisibilityList('apply')}>{t('Save')}</button>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                        : <></>
                                    }



                                    <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                                        onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
            </div >
        </>
    )
}
export default ChangeHistoryList
