import { Link, useLocation } from "react-router-dom"
import React, { useState, useEffect, useRef } from 'react'
import SessionManage from "../../SessionManagement/SessionManage";
import Service from "../../Services/Service";

import { useNavigate } from 'react-router-dom';
import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal } from 'react-bootstrap';
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import Multiselect from 'multiselect-react-dropdown';

import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AuditLogListComponent() {
    const [searchValue, setSearchValue] = useState(null);
    const location = useLocation();
    const csvInstance = useRef();
    const [csvList, setCsvList] = useState([]);
    const [sticky, setSticky] = useState("");
    const [t, i18n] = useTranslation('translation');
    const [sortColName, setSortColName] = useState("");
    const [userPerms, setuserPermission] = useState([]);
    const [data, setdata] = useState([]);
    const [showspinner, setshowspinner] = useState('');
    const [filteredArray, setFilteredArray] = useState(data)
    const [showLoadMore, setshowLoadMore] = useState('');
    const [showbutton, setshowbutton] = useState('');
    const [filterValue, setFilterValue] = useState('');
    const [filterCol, setFilterCol] = useState('');
    const [filtertext, setfiltertext] = useState('');
    const [filterSearch, setFilterSearch] = useState("");
    const [filterdropdownData, setFilterDropdownData] = useState([]);
    const [dropdownData, setDropdownData] = useState([]);
    const [showfilterpopup, setshowfilterpopup] = useState(false);
    const [columnAscending, setColumnAscending] = useState(true);
    const [totalselectedshow, settotalselectedshow] = useState('');
    const [totalselectedcount, settotalselectedcount] = useState(0);
    const [totalCountlst, settotalCountlst] = useState('');
    const [ToggleStatusModal, setToggleStatusModal] = useState(false);
    const [toggleChange, setToggleChange] = useState("");
    const [toggleId, setToggleId] = useState();
    const [toggleUserId, setToggleUserId] = useState();

    // New state to manage the Check All checkbox
    const [checkAllChecked, setCheckAllChecked] = useState(false);
    const [checkedValue, setCheckedValue] = useState([]);
    const [checkedMultiValueid, setCheckedMultiValueid] = useState([]);
    const [checkedValueid, setCheckedValueid] = useState([]);
    const [checkedValueStatus, setCheckedValueStatus] = useState([]);
    const [allCheckedHistory, setAllCheckedHistory] = useState(false);
    const [uncheckedBoxesHistory, setuncheckedBoxesHistory] = useState([]);

    const [dropdownChange, setDropdownChange] = useState('');
    const [filterValueDict, setFilterValueDict] = useState({
        Action: '',
        Section: '',
        ActionDetails: '',
        CreatedDate: null,
        DocumentId: '',
        Logtype: '',
        Response: '',
        Request: '',
    });
    const [paging, setpaging] = useState({
        pageSize: 20,
        pageNumber: 1
    });
    const [multiFilterValue, setMultiFilterValue] = useState({
        CreatedUserName: '',
    });
    const getUserPermission = (pathname) => {

        Service.GetUserPermission(pathname).then((res) => {
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && typeof res.data !== 'undefined') {
                    if (res.data.DataList !== 'undefined') {
                        setuserPermission(res.data.DataList)
                    }
                }
            }
        });
    }
    const activeOption = [
        { Name: "Active", Id: "1" },
        { Name: "In-Active", Id: "0" },

    ]
    const checkAll = (mycheckbox) => {
        const checkboxes = document.querySelectorAll("input[type='checkbox']");
        if (mycheckbox.target.checked) {
            settotalselectedshow(true);
            setAllCheckedHistory(true);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = true;
            });
            settotalselectedcount(totalCountlst);
            setCheckAllChecked(true); // Set state to manage Check All checkbox
        } else {
            settotalselectedshow(false);
            setAllCheckedHistory(false);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = false;
            });
            settotalselectedcount(0);
            setCheckAllChecked(false); // Set state to manage Check All checkbox
        }
    };

    const LoadMore = (lst, totalcount) => {

        if (lst < totalcount) {
            setshowLoadMore(true);
        }
        else if (lst >= totalcount) {
            setshowLoadMore(false);
        } else {
            setshowLoadMore(false);
        }

    }
    const navigate = useNavigate();

    useEffect(() => {
        setColumnAscending(false);
        setSortColName("Id");
        setshowspinner(true);
        getUserPermission(location.pathname);
        getData("AuditLog/GetAll", paging.pageSize, paging.pageNumber, "Descending", "Id", [], null);
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };

    }, [0]);
    const isSticky = () => {
        const scrollTop = window.scrollY;
        const stickyClass = scrollTop >= 60 ? "is-sticky-thead" : "";
        setSticky(stickyClass);
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop(divtop1.offsetTop + scrollTopActual)
    };
    const class_thead = `text-white rt-thead  ${sticky}`;

    const handleLoadMore = () => {

        let pagenumber = paging.pageNumber + 1;
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        paging.pageNumber = pagenumber
        setpaging(paging);

        Service.getDatawithMultiFilterSearch("AuditLog/GetAll", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), searchValue).then((res) => {

            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            let rowData = res.data.DataList;

                            if (res.data.DataList !== 'null' && res.data.DataList !== 'undefined') {
                                const currentlength = data.length;
                                setdata(data => [...data, ...res.data.DataList])
                                setFilteredArray(filteredArray => [...filteredArray, ...res.data.DataList])
                                LoadMore(currentlength + res.data.DataList.length, res.data.Totalcount)

                                const allCheckBoxes = document.querySelectorAll("input[type='checkbox']");
                                console.log(allCheckBoxes, "21-6");

                                if (!checkAllChecked && totalselectedcount != 0) {
                                    allCheckBoxes.forEach((each) => {
                                        const checkboxId = parseInt(each.id, 10);
                                        if (!isNaN(checkboxId)) {
                                            if (uncheckedBoxesHistory.includes(checkboxId)) {
                                                each.checked = false;
                                            } else {
                                                // each.checked = true;
                                                each.checked = checkedMultiValueid.includes(checkboxId);
                                            }
                                        }
                                    });
                                } else {
                                    allCheckBoxes.forEach((each) => {
                                        each.checked = checkAllChecked;
                                    });
                                }
                            }
                        }
                    }
                }
            }
        });
    };

    let role = [];

    const getData = (method, pageSize, pageNumber, sortType, SortProperty, filterValue, search) => {
        Service.getDatawithMultiFilterSearch(method, pageSize, pageNumber, sortType, SortProperty, filterValue, search).then((res) => {
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && res.data !== 'undefined') {
                    setdata(res.data.DataList);
                    setFilteredArray(res.data.DataList)
                    console.log(res.data.DataList, "All-Data");
                    settotalCountlst(res.data.Totalcount)
                    LoadMore(res.data.DataList.length, res.data.Totalcount)
                    setshowspinner(false)
                }
            }
        });

    }



    const handleChange = (e, id, name, status) => {
        const isChecked = e.target.checked;
        const checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        let count = totalselectedcount;

        if (isChecked) {
            setCheckedValue(id);  // check box value - id
            setCheckedValueStatus(status); // check box value - Active status
            setCheckedValueid(name); // check box value - RA-0000
            setCheckedMultiValueid([...checkedMultiValueid, id]); // Add the checked id to the array
            count += 1;

            let tempUncheckedBoxesHistory = [...uncheckedBoxesHistory];
            const index = tempUncheckedBoxesHistory.findIndex((item) => item === id);
            if (index !== -1) {
                tempUncheckedBoxesHistory.splice(index, 1);
            }
            setuncheckedBoxesHistory(tempUncheckedBoxesHistory);

        } else {
            setuncheckedBoxesHistory([...uncheckedBoxesHistory, id]);
            setCheckedValue([]);  // check box value - id
            setCheckedValueStatus([]); // check box value - Active status
            setCheckedValueid([]); // check box value - RA-0000
            setCheckedMultiValueid(checkedMultiValueid.filter(item => item !== id)); // Remove the unchecked id from the array
            count -= 1;

        }

        settotalselectedcount(count);
        settotalselectedshow(count > 0);

        // Uncheck the "Check All" checkbox if any individual checkbox is unchecked
        if (!isChecked) {
            setCheckAllChecked(false);
        } else if (count === totalCountlst) {
            setCheckAllChecked(true);
        }

        if (checkedBoxes.length == 1) {
            const [Recordname, Recordstatus] = checkedBoxes[0].value.split(',');
            setCheckedValue(checkedBoxes[0].id)  // check box value - id
            setCheckedValueStatus(Recordstatus) // check box value - Active status
            setCheckedValueid(Recordname) //check box value - RA-0000
        }
    };

    const addSeletAll = (optionsArray, alreadySelected) => {

        let result = optionsArray;
        let selectAllObj = {
            "Name": "Select All",
            "Value": "Select All",
            "ischecked": alreadySelected
        }

        result.splice(0, 0, selectAllObj);
        console.log(result, "9999")
        return result;
    }

    const addnewfield = (data, key) => {
        let selectedKeys = multiFilterValue[key] ? multiFilterValue[key] : [];
        let currentdata = data.map((i) => {
            i["ischecked"] = false;
            return i;
        });

        console.log(currentdata, "test-data");
        let alreadySelected = selectedKeys.find((obj) => obj.Name === "Select All") ? true : false;

        if (key != 'StatusLabel') {
            if (selectedKeys) {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }

                    })
                })
            }


            let result = addSeletAll(currentdata, alreadySelected);
            setDropdownData(result);
            setFilterDropdownData(result)
        }
        else {
            let alreadySelected = selectedKeys.find((obj) => obj.Name === "Select All") ? true : false;
            if (selectedKeys) {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }
                    })
                })
                if (filterCol == 'StatusLabel') {
                    let filteredData = currentdata.filter((x) => x.Name !== 'Overridden' && x.Name !== 'Rejected' && x.Name !== 'Pending Reconcilation' && x.Name !== 'Expired')

                    setDropdownData(filteredData)
                    setFilterDropdownData(filteredData)
                }
                else {
                    setDropdownData(currentdata)
                    setFilterDropdownData(currentdata)
                }
            }
            else {
                let filteredData = currentdata.filter((x) => x.Name !== 'Overridden' && x.Name !== 'Rejected' && x.Name !== 'Pending Reconcilation' && x.Name !== 'Expired')

                setDropdownData(filteredData)
                setFilterDropdownData(filteredData)
            }
        }
    }

    const checkAllFilter = (mycheckbox) => {
        var lastfilter = [...dropdownData]
        let data1 = []
        var checkboxes = document.querySelectorAll("input[name = 'filter-option-all']");
        if (mycheckbox.target.checked == true) {

            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = true;
                return i;
            });
            setDropdownData(currentdata)

            dropdownData.map((each) => {

                const getallData = {
                    Name: each.Name,
                }
                data1.push(getallData)
            })
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {

            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = false;
                return i;
            });
            setFilterValue([])
            setMultiFilterValue([])
        }

    }

    const OndropdownChange = (e, id, name, filterCol) => {
        let isChecked = e.target.checked;
        let allChecked = false;


        let allCheckboxes = document.getElementsByName("filter-option-all");
        let totalChecked = 0;
        allCheckboxes.forEach((checkbox) => {
            if (checkbox.checked)
                totalChecked += 1;
        });

        let tempData = [...dropdownData];
        //If all checkboxes are checked
        if (tempData.length - 1 === totalChecked) {
            tempData[0].ischecked = isChecked;
            allChecked = true;
        }

        setDropdownData(tempData);

        console.log(tempData, "12x");

        var lastfilter = [...dropdownData]
        if (isChecked === true) {
            let currentdata = lastfilter.map((i) => {
                if (i.Id == id) {
                    i["ischecked"] = true;
                }
                return i;
            });

            setDropdownData(currentdata);
            let data1 = []
            const getallData = {
                Name: name,
            }
            data1.push(getallData)
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);

            if (allChecked) {
                const selectAllObj = {
                    "Name": "Select All"
                };
                updateddata.splice(0, 0, selectAllObj);
            }

            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {
            let currentdata = lastfilter.map((i) => {
                if (i.Id === id) {
                    i["ischecked"] = false;
                }
                return i;
            });

            console.log(currentdata, "c-data");

            setDropdownData(currentdata);
            setFilterValue(filterValue => filterValue.filter(data => data.Name !== name))
            // let data1 = multiFilterValue[filterCol].filter(data => data.Name !== name);

            let data1 = multiFilterValue[filterCol] || [];
            data1 = data1.filter((data) => data.Name !== name);

            if (tempData.length - 1 >= totalChecked) {
                data1 = data1.filter(data => data.Name !== "Select All");
            }

            console.log(data1, "data");

            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: data1,
            }));
        }
    }


    const customMultiStyles = {
        multiselectContainer: {
            maxWidth: '188px',
            marginLeft: '3px'
        },
        chips: {
            borderRadius: '5px'
        }
    };
    const OpenHandler = (id) => {
        if (id == null || id == 0) {
            alert('Please select the User to view')
        }
        else {
            navigate("/auditlog/details/" + id);
        }
    }

    const searchHandler = (event) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';

        let pagenumber = 1;

        paging.pageNumber = pagenumber;
        setpaging(paging);
        if (event.target.value != '') {

            setSearchValue(String(event.target.value).toLowerCase())
            getData("AuditLog/GetAll", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), event.target.value);
        }
        else {

            setSearchValue(null);
            getData("AuditLog/GetAll", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), null);
        }
    }

    const ToggleUserActiveStatus = (id, user_id, flag) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        setToggleId(id)
        setToggleUserId(user_id)
        if (flag == "Open-A") {
            setToggleStatusModal(true)
            setToggleChange("DeActivate")
        }
        if (flag == "Open-D") {
            setToggleStatusModal(true)
            setToggleChange("Activate")
        }
        if (flag == "Ok") {
            Service.ToggleUserActiveStatus('ToggleUserActiveStatus', id, user_id).then((res) => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        if (res.status === 200) {
                            getData("AuditLog/GetAll", paging.pageSize, paging.pageNumber, sortType, sortColName, filterLst(), searchValue)
                        }
                        setToggleStatusModal(false)
                    }
                }

            });
        }
        if (flag == "Cancel") {
            setToggleStatusModal(false)
        }
    }

    const OpenUser = (event, id) => {
        if (event.detail === 2) {

            navigate("/auditlog/details/" + id);
        }
    }


    const filterInput = (e) => {

        let inputValue = e.target.value
        setFilterValue(inputValue)

        setFilterValueDict(() => ({
            ...filterValueDict, [filterCol]: e.target.value,
        }));
    }


    const DropdownHandler = (key) => {
        Service.getfilterDropdown('Permission/GetFilterValue', key).then((res) => {
            if (res && res.status === 200) {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        if (res.data !== 'null' && res.data !== 'undefined') {

                            addnewfield(res.data, key)
                        }
                    }
                }

            }
        });
    }

    const [divleft, setdivleft] = useState('');
    const [divtop, setdivtop] = useState('');


    const FilterColumn = (name) => {

        const scrollTop = window.scrollY;
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop(divtop1.offsetTop + scrollTopActual + 30)

        setshowfilterpopup(true)
        setFilterCol(name)

        const adjustPopupPosition = (left, popupWidth) => {
            const windowWidth = window.innerWidth;
            if (left + popupWidth > windowWidth) {
                return windowWidth - popupWidth;
            }
            return left;
        };

        if (name === 'Action') {
            setfiltertext(t('Action'))
            var testDiv = document.getElementById("Action");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }

        if (name === 'ActionDetails') {
            setfiltertext(t('Action Details'))
            var testDiv = document.getElementById("ActionDetails");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }

        if (name === 'Section') {
            setfiltertext(t('Section'))
            var testDiv = document.getElementById("Section");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }

        if (name === 'DocumentId') {
            setfiltertext(t('Document ID/Name'))
            var testDiv = document.getElementById("DocumentId");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }

        if (name === 'Logtype') {
            setfiltertext(t('Log Type'))
            var testDiv = document.getElementById("Logtype");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }

        if (name === 'Request') {
            setfiltertext(t('Request'))
            var testDiv = document.getElementById("Request");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }

        if (name === 'Response') {
            setfiltertext(t('Response'))
            var testDiv = document.getElementById("Response");
            //setdivleft(adjustPopupPosition(testDiv.offsetLeft + 20, 950) + "px");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }

        if (name === 'CreatedUserName') {
            setfiltertext(t('Created By'))
            var testDiv = document.getElementById("CreatedUserName");
            setdivleft(adjustPopupPosition(testDiv.offsetLeft + 20, 450) + "px");
            DropdownHandler(name)
        }

        if (name === 'CreatedDate') {
            setfiltertext(t('Created Date'))
            var testDiv = document.getElementById("CreatedDate");
            setdivleft(adjustPopupPosition(testDiv.offsetLeft + 20, 350) + "px");
        }
    }



    const UserfilterHandler = (e) => {

        setFilterSearch(e.target.value)
        if (e.target.value !== "") {
            let searchResults = dropdownData.filter((each) => {
                return each.Name.toLowerCase().includes(String(e.target.value).toLowerCase())
            });
            setDropdownData(searchResults);
        }
        else {

            setFilterSearch("")
            setDropdownData(filterdropdownData)
        }
    }


    const filterHandler = (flag) => {

        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;

        if (flag === 'Apply') {
            setshowfilterpopup(false)
            let list = [];

            for (var key in multiFilterValue) {
                if (multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
            if (list.length > 0) {
                setpaging(paging);
                getData("AuditLog/GetAll", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);
            }


            let tempDropdownData = dropdownData;
            if (tempDropdownData.length > 0) {
                tempDropdownData[0]["ischecked"] = true;
                setDropdownData(tempDropdownData);
            }
        }
        else {
            setshowfilterpopup(false)
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: '',
            }));
            setFilterValueDict(() => ({
                ...filterValueDict, [filterCol]: '',
            }));

            let list = [];
            for (var key in multiFilterValue) {
                if (key !== filterCol && multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '' && key !== filterCol) {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
            setpaging(paging);
            getData("AuditLog/GetAll", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);


            setFilterCol('')
            setFilterValue('')
            setDropdownData([])
            setDropdownChange("")
        }
    }



    const SortColumn = (colName) => {
        var sortType = '';
        if (columnAscending === true) {
            setColumnAscending(false);
            sortType = "Descending";
        } else {
            setColumnAscending(true);
            sortType = "Ascending";
        }
        setSortColName(colName);

        let pagenumber = 1;

        paging.pageNumber = pagenumber;
        setpaging(paging);
        getData("AuditLog/GetAll", paging.pageSize, paging.pageNumber, sortType, colName, filterLst(), searchValue);
    }

    const filterLst = () => {
        let list = [];
        for (var key in multiFilterValue) {
            if (multiFilterValue[key].length > 0) {
                let values = ""
                multiFilterValue[key].map((i) => {
                    values += i.Name + ";";
                })
                if (values !== null && values !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: values
                    })
                }
            }
        }

        for (var key in filterValueDict) {
            if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                list.push({
                    filterKey: key,
                    FilterValue: filterValueDict[key]
                })
            }
        }
        return list;
    }
    let headers = [
        { label: "Action", key: "Action" },
        { label: "ActionDetails ", key: "ActionDetails" },
        { label: "Section", key: "Section" },
        { label: "DocumentId", key: "DocumentId" },
        { label: "Log Type", key: "Logtype" },
        { label: "Request", key: "Request" },
        { label: "Response", key: "Response" },
        { label: "CreatedUserName", key: "CreatedUserName" },
        { label: "CreatedDate", key: "CreatedDate" },

    ];

    const ExporttoCSV = () => {
        const dateFormat = SessionManage.getcurrentdateformat();
        const timeFormat = "hh:mm A";

        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        let values = "";
        if (checkedBoxes.length >= 1) {
            for (let checkbox of checkedBoxes) {
                values = values === "" ? checkbox.id : values + ";" + checkbox.id;
            }
        }

        let objFilter = [
            {
                filterKey: "id",
                FilterValue: values,
            },
        ];

        // Make the API call without any filters
        Service.getdataWithPagingByGroupSearch("AuditLog/GetAll", 20000, 1, "Descending", "Id", checkAllChecked ? filterLst() : objFilter, null).then((res) => {
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && res.data !== 'undefined') {
                    let csvLst = res.data.DataList;

                    let selectedIds = values.split(';');

                    let operatedList = csvLst
                    //If checkall box is not checked
                    if (!checkAllChecked) {
                        //If it has not been checked in history/past
                        if (!allCheckedHistory)
                            operatedList = csvLst.filter(item => selectedIds.includes(`${item.Id}`))
                        //If it has been checked in history/past
                        else
                            operatedList = csvLst.filter(item => !uncheckedBoxesHistory.includes(item.Id))
                    }

                    // Format the LastModifiedDate before exporting
                    let filteredCsvList = operatedList.map(item => {
                        let newItem = { ...item };
                        if (newItem.CreatedDate) {
                            newItem.CreatedDate = `${moment(item.CreatedDate).format(dateFormat)} ${moment(item.CreatedDate).format(timeFormat)}`;
                        }
                        return newItem;
                    });

                    setCsvList(filteredCsvList);

                    console.log(filteredCsvList, "filtered");

                    setTimeout(() => {
                        toast.success('Exported Successfully !', {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        csvInstance.current.link.click();
                    });
                }
            }
        });
    };

    const dateFormat = SessionManage.getcurrentdateformat();
    const timeFormat = "hh:mm:ss A";

    //----------------------------------------------------------
    return (<>
        <div className="container-fluid content-top-gap">

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb my-breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
                    <li className="breadcrumb-item"><Link to="/admin">{t('Menu.Admin')}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{t('UserList.AuditLogList')}</li>

                </ol>
            </nav>
            <div style={{ display: "none" }}>
                <CSVLink data={csvList} headers={headers} ref={csvInstance} filename="AuditLog.csv" > </CSVLink>
            </div>
            <div className="">
                <div className="row">
                    <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border">

                            <div className="card-body" style={{ minHeight: "70vh" }}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className='d-flex align-items-center mb-1'>
                                            <input type="text" className="form-control form-control-user col-md-10" id="firstName" name="firstName" autoComplete='off' placeholder={t('Search for Document ID, Action, Action Details, Section, Log Type Or Created By')} onChange={(event) => searchHandler(event)} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className='row'>
                                            <div className="col-md-12 d-flex justify-content-end align-items-center">
                                                <div style={{ display: 'inline-block', marginRight: '10px' }}>

                                                    {totalselectedcount > 0 && Service.exportPerms(userPerms) && (
                                                        <button className="btn btn-update btn-sm ml-2" type="submit" style={{ width: "50px" }} onClick={(event) => ExporttoCSV()}>{t('Export')}</button>
                                                    )}
                                                    {/* {totalselectedcount === 1 && (
                                                        <>
                                                            <a className="btn btn-update btn-sm ml-2" type="submit" style={{ width: "45px", backgroundColor: "buttonface" }} onClick={() => OpenHandler(checkedValue)}>{t('Open')}</a>

                                                        </>
                                                    )} */}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Corrected The Classes */} 
                                <div className="scrollbar-class mb-1">
                                    <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableA">
                                        <thead className="text-white is-sticky-thead-product" style={{ backgroundColor: "var(--primary)" }}>
                                            <tr style={{ position: "sticky", top: "0", backgroundColor: "var(--primary)" }}>
                                                <th className="tableheader" style={{ width: '30px' }}>
                                                    <input
                                                        type="checkbox"
                                                        onChange={checkAll}
                                                        checked={checkAllChecked}
                                                        id="option-all"
                                                    />
                                                </th>

                                                <th id="Action" style={{ backgroundColor: (filterValueDict.Action ? '#6cae55' : ''), width: '250px' }}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('Action')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Action')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Action')}></span>
                                                    </div>
                                                </th>
                                                <th id="ActionDetails" style={{ backgroundColor: (filterValueDict.ActionDetails ? '#6cae55' : ''), width: '250px' }}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>Action Details</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('ActionDetails')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('ActionDetails')}></span>
                                                    </div>
                                                </th>
                                                <th id="Section" style={{ backgroundColor: (filterValueDict.Section ? '#6cae55' : ''), width: '250px' }}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>Section</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Section')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Section')}></span>
                                                    </div>
                                                </th>
                                                <th id="DocumentId" style={{ backgroundColor: (filterValueDict.DocumentId ? '#6cae55' : ''), width: '250px' }}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>Document ID/Name</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('DocumentId')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('DocumentId')}></span>
                                                    </div>
                                                </th>
                                                <th id="Logtype" style={{ backgroundColor: (filterValueDict.Logtype ? '#6cae55' : ''), width: '250px' }}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>Log Type</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Logtype')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Logtype')}></span>
                                                    </div>
                                                </th>
                                                <th id="Request" style={{ backgroundColor: (filterValueDict.Request ? '#6cae55' : ''), width: '250px' }}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>Request</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Request')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Request')}></span>
                                                    </div>
                                                </th>
                                                <th id="Response" style={{ backgroundColor: (filterValueDict.Response ? '#6cae55' : ''), width: '250px' }}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>Response</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Response')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Response')}></span>
                                                    </div>
                                                </th>
                                                <th id="CreatedUserName" style={{ backgroundColor: (multiFilterValue.CreatedUserName ? '#6cae55' : ''), width: '220px' }}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>Created By</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('CreatedUserName')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('CreatedUserName')}></span>
                                                    </div>
                                                </th>

                                                <th id="CreatedDate" style={{ backgroundColor: (filterValueDict.CreatedDate ? '#6cae55' : ''), maxWidth: '200px' }}>
                                                    <div className="d-flex text-nowrap">
                                                        <span>Created Date</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('CreatedDate')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('CreatedDate')}></span>
                                                    </div>
                                                </th>


                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                showspinner === true ?

                                                    <>
                                                        <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                    </>
                                                    :
                                                    filteredArray.length === 0 ?
                                                        <tr><td></td>
                                                            <td colSpan="9" style={{ textAlign: 'center' }}>
                                                                {t('NoDataAvailable')}
                                                            </td>
                                                        </tr>
                                                        :

                                                        filteredArray.map((item, index) => (

                                                            <tr >

                                                                <td><input id={item.Id} data-index="1" value={`${item.DocumentId},${""}`} name="chkBox" type="checkbox" onChange={(event) => handleChange(event, item.Id)} /></td>


                                                                <td style={{ whiteSpace: "nowrap" }} >{item.Action}</td>
                                                                <td style={{ whiteSpace: "nowrap" }} >{item.ActionDetails}</td>
                                                                <td style={{ whiteSpace: "nowrap" }}>
                                                                    {item.Section.length > 50 ? `${item.Section.substring(0, 50)}...` : item.Section}
                                                                </td>
                                                                <td style={{ whiteSpace: "nowrap" }} >{item.DocumentId}</td>
                                                                <td style={{ whiteSpace: "nowrap" }} >{item.Logtype}</td>
                                                                <td>{item.Request}</td>
                                                                <td >{item.Response}</td>
                                                                <td style={{ whiteSpace: "nowrap" }} >{item.CreatedUserName}</td>
                                                                <td style={{ whiteSpace: "nowrap" }}>
                                                                    {`${moment(item.CreatedDate).format(dateFormat)} ${moment(item.CreatedDate).format(timeFormat)}`}
                                                                </td>


                                                            </tr>


                                                        ))
                                            }

                                        </tbody>
                                    </table>
                                </div>

                                
                                <div className='row'>

                                    {
                                        totalselectedshow === true ?

                                            <div className='col-lg-2 forgettext col-sm-12 border form-control' style={{ textAlign: "center", marginLeft: "0.938rem" }}>
                                                Total Records Selected - <span id="totalselected">{totalselectedcount}</span>
                                            </div>

                                            :
                                            <div className='col-lg-2 col-sm-12' style={{ textAlign: "center", marginLeft: "0.938rem" }}>

                                            </div>
                                    }



                                    <div style={{flex: `0 0 ${totalCountlst > 14 ? "62.5%" : "63%"}`,maxWidth: totalCountlst > 14 ? "62.5%" : "63%"}}></div>


                                    <div className='col-lg-2 forgettext  col-sm-12 border form-control' style={{ textAlign: "center",marginLeft: "0.1rem"  }}>
                                        Total Records - {totalCountlst}
                                    </div>
                                </div>
                                {
                                    showLoadMore === true ?
                                        <div className='text-center container row mb-1'>
                                            <div className='col-md-5'></div>
                                            <div className='col-md-2'>
                                                <button className='btn btn-sm btn-update ml-1' onClick={handleLoadMore} style={{ width: "70px" }}>Load More</button>
                                            </div>
                                            <div className='col-md-5'></div>

                                        </div>
                                        :
                                        ""
                                }

                                {showfilterpopup === true ?
                                    <div className='pop-up' style={{ left: divleft, top: divtop }}>

                                        {filterCol === 'Action' || filterCol === 'Section' || filterCol === 'ActionDetails' || filterCol === 'DocumentId' || filterCol === 'Logtype' || filterCol == 'Request' || filterCol == 'Response' ?

                                            <>
                                                <div className="container">
                                                    <div className="row">
                                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                    </div>
                                                </div>
                                                <div className="container mt-2">
                                                    <div className="row">
                                                        <input type='textbox' placeholder={t('Search')} className='filtertextboxbackground col-md-11 textbox-padding ml-2' style={{ outline: 'none' }} value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                                    </div>
                                                </div>
                                            </>

                                            :
                                            <>
                                            </>
                                        }

                                        {filterCol === 'CreatedUserName' ?

                                            <>
                                                <div className="container">
                                                    <div className="row">
                                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>

                                                    </div>

                                                </div>

                                                <div className="container mt-2">
                                                    <div className="row">
                                                        <input type="text" placeholder={t('Search')} value={filterSearch} onChange={(event) => UserfilterHandler(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                                                    </div>

                                                    <div className="row contentoverflow mt-2">
                                                        <table className="filtertable">
                                                            <tbody>
                                                                {/* <tr>
                                                                    <td><input type="checkbox" name="filter-option-all" id="option-all" onChange={checkAllFilter} /></td>
                                                                    <td>{t('SelectAll')}</td>
                                                                </tr> */}
                                                                {
                                                                    dropdownData.map((item, index) => (

                                                                        <tr key={index}>

                                                                            <td><input id={item.Id} data-index="1" name="filter-option-all" checked={item.ischecked} type="checkbox" onChange={item.Name === "Select All" ? checkAllFilter : (event) => OndropdownChange(event, item.Id, item.Name, filterCol)} /></td>

                                                                            <td>{item.Name}</td>

                                                                        </tr>


                                                                    ))
                                                                }

                                                            </tbody>
                                                        </table>

                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                        }

                                        {filterCol === 'CreatedDate' ?

                                            <>
                                                <div className="container">
                                                    <div className="row">
                                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                    </div>
                                                </div>
                                                <div className="container mt-2">
                                                    <div className="row">
                                                        <input type='date' className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                        }

                                        <div style={{ paddingTop: '10px', paddingLeft: '47px', paddingBottom: '7px' }}>
                                            <button className="btn btn-sm btn-update" onClick={() => filterHandler('Apply')} style={{ width: "45px" }}>{t('Apply')}</button>
                                            <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "45px" }} onClick={() => filterHandler('Reset')}>{t('Reset')}</button>
                                        </div>
                                        <div>
                                        </div>
                                    </div>
                                    :
                                    <></>
                                }

                                <Modal show={ToggleStatusModal}
                                    size="sm"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered>
                                    <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>Are you sure you want to {toggleChange}?</Modal.Body>
                                    <Modal.Footer>
                                        <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "35px" }} size='sm' onClick={() => ToggleUserActiveStatus(toggleId, toggleUserId, "Ok")}>
                                            Yes
                                        </Button>
                                        <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => ToggleUserActiveStatus(toggleId, toggleUserId, "Cancel")}>
                                            Cancel
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>

                        </div>
                    </div>

                </div>
            </div>



        </div>
    </>)
}
export default AuditLogListComponent