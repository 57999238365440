import { Link, Navigate, useLocation } from "react-router-dom"
import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal } from 'react-bootstrap';
import React, { useState, useEffect } from 'react'
import classes from '../../RebateProgramComponent/CreateRebateComponent/Form.module.css'
import Service from '../../../Services/Service';
import 'react-multiple-select-dropdown-lite/dist/index.css'
import CustomErrorPopup from '../../../UI/CustomErrorPopup';
import $ from 'jquery';
import Multiselect from 'multiselect-react-dropdown';
import ProductTreeDialog from '../../RebateProgramComponent/CreateRebateComponent/ProductTreeDialog.js';
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SessionManage from "../../../SessionManagement/SessionManage";

function CreateNewEngineOilVolumeMappingPolicy(props) {
    const location = useLocation();
    const [userPerms, setuserPermission] = useState([]);
    const [t, i18n] = useTranslation('translation');
    let rowData = [];
    const [listViewData, setListViewData] = useState(rowData);
    const [errorMessage, setErrorMessage] = useState("");
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorStatus, setErrorStatus] = useState("Error");
    const [isValid, setIsValid] = useState(true);
    const [policyStatus, setPolicyStatus] = useState(false);
    let loggedInUser = SessionManage.getuserid();
    const [closeModal, setCloseModal] = useState(false);
    const [handleChange, setHandleChange] = useState(false);
    const [isLocked, setIsLocked] = useState(false);
    const [lockedBy, setLockedBy] = useState('');
    const [showproductpopup, setshowproductpopup] = useState(false);
    const [sidebarwidth, setsidebarwidth] = useState('');
    const [clickProduct, setclickProduct] = useState('');
    const [showModal, setShowModal] = useState(true);
    const [FiltedProducts, SetFiltedProducts] = useState([]);
    const [open, setOpen] = useState(true);
    const [selectedType, setSelectedType] = useState('Attribute');
    const [dualListBoxOptionsByAttribute, setdualListBoxOptionsByAttribute] = useState([])
    const [rebatelinestatus, setrebatelinestatus] = useState('New');
    const [showProdModal, setShowProdModal] = useState(true);
    const [showExcludeModal, setShowExcludeModal] = useState(false);
    const [fromOpen, setFromOpen] = useState(false)
    const [matrialDesc, setMataerialDesc] = useState('')
    let disabled = false;
    const [inputField, setInputField] = useState({
        Part: '',
        MaterialDescription: '',
        ConversionFraction: '',
        CreatedUserId: loggedInUser,
        LastModifiedUserId: loggedInUser
    })
    const [productId, setProductId] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [showSelectedProductPopup, setShowSelectedProductPopup] = useState(false)

    useEffect(() => {
        getUserPermission(location.pathname);
        if (props.id !== 0 && props.id !== '0') {
            getdata("EngineOilVolumeMapping/GetById", props.id);
        }
        else {
            setLockedBy(loggedInUser);
            setIsLocked(true);
        }
        getdataProductSelectorByAttribute(0)
        setclickProduct(false);
    }, [props.id])

    const getUserPermission = (pathname) => {
        console.log("10-9", pathname, props, loggedInUser)
        Service.GetUserPermission(pathname).then((res) => {
            if (res !== undefined) {

                if (res.data !== 'null' && res.data !== undefined && res.data !== '') {
                    if (res.data.DataList !== undefined) {
                        console.log("10-9", res.data.DataList)
                        setuserPermission(res.data.DataList)
                    }
                }
            }
        });
    }
    const SelectedProductTypeHandler = (event) => {
        //setChangeHandle(true)
        setSelectedType(event.target.value);
    }
    const getdata = (method, id) => {
        Service.LockUnLockEntity('Configurationprop/LockEntity', "EngineOilVolumeMappingPolicy", props.id, loggedInUser).then((res1) => {
            if (typeof res1 !== 'undefined') {

                Service.getdatabyId(method, id).then((res) => {
                    if (typeof res !== 'undefined') {
                        if (typeof res.data !== 'undefined' && res.data !== 'null') {
                            if (res.status === 200) {
                                if (res.data !== 'null' && res.data !== 'undefined') {
                                    console.log(res.data, "19-2")
                                    setProductId(res.data.ProductId)
                                    setInputField(res.data);
                                    setMataerialDesc(res.data.MaterialDescription)
                                    setPolicyStatus(true)
                                    setFromOpen(true)
                                    if ((res.data.Locked === true && res.data.LockedBy.toString() !== loggedInUser)) {
                                        $("#libread").html(res.data.Part + " " + `Locked By ${res.data.LockedByUserName}`);
                                    } else {
                                        $("#libread").html(res.data.Part)
                                    }
                                    setLockedBy(res.data.LockedBy);
                                    setIsLocked(res.data.Locked);
                                    setclickProduct(false);
                                }
                            }
                        }
                    }

                });
            }
        });
    }

    const clickShowProductPopup = (e) => {
        setshowproductpopup(true);
        setsidebarwidth("70vw");
    }
    const clickSelectedShowProductPopup = () => {
        if(productId !== null)
        {
        const FilteredProducts = dualListBoxOptionsByAttribute.filter(option => option.Id === productId)
        setSelectedProduct(FilteredProducts[0])
        }
        
        setShowSelectedProductPopup(true)
        setsidebarwidth("70vw");
    }
    const clickCloseProductListing = () => {
        setShowSelectedProductPopup(false)
    }
    const inputsHandler = (e) => {
        setHandleChange(true)

        if (policyStatus === false) {
            const { name, value } = e.target;
            setInputField(() => ({
                ...inputField, [name]: value,
            }));
        }
        else {
            const { name, value } = e.target;
            setInputField(() => ({
                ...inputField, [name]: value,
            }));
        }
    }

    const clickClose = () => {
        setshowproductpopup(false);
    }


    const checkValidation = () => {
        setIsValid(true)

        if (inputField.Part == null || inputField.Part == '') {
            setErrorMessage(`${t('Please select')} ${t('Policies.Part')}`)
            setIsValid(false)
            setShowErrorModal(true)
        }
        else if (inputField.MaterialDescription == '' || inputField.MaterialDescription == null) {
            setErrorMessage(`${t('Please Enter')} ${t('Policies.MaterialDescription')}`)
            setIsValid(false)
            setShowErrorModal(true)
        }
        else if (inputField.ConversionFraction == '' || inputField.ConversionFraction == null) {
            setErrorMessage(`${t('Please enter')} ${t('Policies.ConversionValue')}`)
            setIsValid(false)
            setShowErrorModal(true)
        }
        else {

            saveHandler(true)
        }
    }

    const getdataProductSelectorByAttribute = () => {
        Service.getdataWithPagingandattributeSearchingByGroup('Scheme/ProductSelectorByAttributeNew', '', 200000, 1, 0, [], 0).then((res) => {
            if (typeof res !== 'undefined' && typeof res.data !== 'undefined') {
                setdualListBoxOptionsByAttribute(res.data.DataList)
                console.log("15-3api", res.data.DataList)
            }
        });
    };

    const saveHandler = (isValid) => {
        let data = inputField
        data['LastModifiedUserId'] = loggedInUser
        console.log("19-2", data)
        if (isValid === true) {

            Service.createorupdate("EngineOilVolumeMapping/CreateOrUpdate", data).then(res => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        console.log("10-9", res.data)
                        if (res.data.Id > 0) {

                            Service.LockUnLockEntity('Configurationprop/UnLockEntity', "EngineOilVolumeMappingPolicy", props.id, loggedInUser).then((res) => {
                            });
                            toast.success('Saved Successfully !', {
                                onClose: () => props.navigate('/policies/engineoilvolumemapping'),
                                position: toast.POSITION.TOP_RIGHT,
                            });


                        }
                        else {
                            setErrorMessage(res.data.ErrorMessage)
                            setShowErrorModal(true)
                        }
                    }
                }

            });
        }
    }

    const UpdateProducts = (_SelectedProducts, saveStatus) => {
        console.log(_SelectedProducts, "19-2", saveStatus)

        SetFiltedProducts(_SelectedProducts);
        //FilterProductList(_SelectedProducts);

        // dualListBoxOptionsByAttribute.forEach((each) => {
        //    // console.log(each,"19-2 selectedProducts",each.Selected)
        // });
        const filteredOptions = dualListBoxOptionsByAttribute.filter(option => option.Selected === true);
        console.log(filteredOptions, "19-2 filteredOptions")
        setProductId(filteredOptions[0].Id)
        // setSelectedProduct(filteredOptions[0])
        setInputField(prevState => ({
            ...prevState,
            Part: filteredOptions[0].Name,
            MaterialDescription: filteredOptions[0].Label
        }));
        setMataerialDesc(filteredOptions.Label)


    }

    const CloseProductPicker = () => {

        setOpen(false);
        setShowProdModal(false);
        setShowExcludeModal(false)
    }

    const closeHandler = (flag) => {
        if (flag == "Open" && handleChange == true) {
            setCloseModal(true)
        }
        if (flag == "Open" && handleChange == false) {
            Service.LockUnLockEntity('Configurationprop/UnLockEntity', "EngineOilVolumeMappingPolicy", props.id, loggedInUser).then((res) => {
            });
            props.navigate("/policies/engineoilvolumemapping")
        }
        if (flag == "Close") {
            Service.LockUnLockEntity('Configurationprop/UnLockEntity', "EngineOilVolumeMappingPolicy", props.id, loggedInUser).then((res) => {

            });
            props.navigate("/policies/engineoilvolumemapping")
        }
        if (flag == "Cancel") {
            setCloseModal(false)
        }

    }
    if (isLocked === false || (isLocked === true && lockedBy.toString() === loggedInUser)) {
        disabled = false;
    }
    else {
        disabled = true;
    }
    window.onbeforeunload = function () {
        unlockEntity();

    };
    const unlockEntity = () => {
        Service.LockUnLockEntity('Configurationprop/UnLockEntity', "EngineOilVolumeMappingPolicy", props.id, loggedInUser).then((res) => {
        });
    }
    const class_textbox = `col-md-2 mt-2 textbox-padding form-control m_width  ${Service.editPerms(userPerms) == false ? "disabledClass" : ""}`;


    console.log("10-9", Service.editPerms(userPerms), isLocked, lockedBy)

    //--------------------------------------------------------------------------------------------------

    return (
        <>
            <div className="container-fluid content-top-gap">
                <    nav aria-label="breadcrumb">
                    <ol className="breadcrumb my-breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
                        <li className="breadcrumb-item active" aria-current="page"><Link to="/policies/engineoilvolumemapping">{`${t('EngineOilVolumeMappingPolicy.EngineOil')}${t('Policies.PolicyList')}`}</Link></li>
                        <li className="breadcrumb-item active" id="libread" aria-current="page">{`${t('Policies.CreateNew')}${t('EngineOilVolumeMappingPolicy.EngineOil')}${t('Policies.Policy')}`}</li>
                    </ol>
                </nav>
                <ToastContainer autoClose={1000} />
                <div className="row mt-3" id="rebateProgramdiv">
                    <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border">
                            <div className="card-header chart-grid__header">
                                <div className="row">
                                    <div className="col-md-7 text-left">
                                        {`${t('Policies.CreateNew')}${t('EngineOilVolumeMappingPolicy.EngineOil')}${t('Policies.Policy')}`}
                                    </div>
                                    <div className="col-md-5">
                                        {Service.editPerms(userPerms) == false ? ""
                                            : (isLocked === true && lockedBy.toString() === loggedInUser)
                                                ?
                                                <button className="btn  btn-update btn-sm ml-1" style={{ width: "40px", float: "right" }} onClick={checkValidation}>{t('Save')}
                                                </button>
                                                :
                                                ''}
                                        <button className="btn  btn-update btn-sm ml-1" style={{ width: "40px", float: "right" }} id="close" onClick={(event) => closeHandler("Open")} >{t('Close')}</button>
                                    </div>
                                    <div className="card-body" style={{ minHeight: "60vh" }}>
                                        <div className="container">
                                            <div className="row">
                                                <label className="col-md-2 text-left l_width">{t('Policies.Part')}<span className={classes.mandatory}>*</span></label>
                                                <button className="btn btn-outline-dark  btn-sm ml-2" onClick={(e) => clickShowProductPopup(e)} style={{ width: 50 + "px" }} type="submit">{t('Select')}</button>
                                                {/* <input name="Part" type="text" autoComplete='no' onChange={inputsHandler} className="col-md-2 mt-2 textbox-padding form-control m_width" value={inputField.Part} disabled={policyStatus} /> */}
                                                <div className="col-md-2">
                                                    <a href='javascript:void(0)' onClick={(e) => clickSelectedShowProductPopup()}><label style={{ paddingTop: "5px" }}>({inputField.MaterialDescription === "" ? 0 : 1}) </label></a>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <label className="col-md-2 text-left l_width">{t('Policies.MaterialDescription')}<span className={classes.mandatory}>*</span></label>
                                                <input disabled={true} onKeyPress={(e) => { e.key === ' ' && e.preventDefault(); }} name="MaterialDescription" type="text" autoComplete='off' onChange={inputsHandler} className={class_textbox} value={inputField.MaterialDescription} />
                                                <div className="col-md-2"></div>
                                            </div>
                                            <div className="row">
                                                <label className="col-md-2 text-left l_width">{t('Policies.ConversionValue')}<span className={classes.mandatory}>*</span></label>
                                                <input
                                                    disabled={disabled}
                                                    onKeyPress={(e) => {
                                                        if (e.key === ' ' || e.key === '-') {
                                                            e.preventDefault();
                                                        }
                                                    }
                                                    }
                                                    name="ConversionFraction"
                                                    type="number"
                                                    autoComplete='off'
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        if (value === "" || (/^\d*\.?\d*$/.test(value) && parseFloat(value) >= 0)) {
                                                            inputsHandler(e);
                                                        }
                                                    }
                                                    }
                                                    className={class_textbox}
                                                    value={inputField.ConversionFraction}
                                                />
                                                <div className="col-md-2"></div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    (showproductpopup === true && fromOpen === false) ?
                        <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0" }}>
                            <div className="container" style={{ padding: "0" }}>
                                <div className="row">
                                    <div className="col-lg-12 pr-lg-2">
                                        <div className="card text-center card_border">
                                            <div className="card-header chart-grid__header">
                                                <div className="row">
                                                    <div className="col-md-10 text-left">
                                                        {t('Rebates.ProductListing')}
                                                    </div>
                                                    <div className="col-md-2">

                                                        <button className="btn btn-outline-dark  btn-sm ml-1" onClick={clickClose} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-1">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-lg-12 pr-lg-2">
                                                <div className="card text-center card_border">
                                                    <div className="card-header chart-grid__header">

                                                    </div>
                                                    <div style={{ minHeight: "66vh" }}>
                                                        <div className="container">
                                                            <div className='col-md-12'>

                                                                {clickProduct === false ?
                                                                    <ProductTreeDialog
                                                                        show={showModal}
                                                                        onOkClick={UpdateProducts}
                                                                        onHide={() => CloseProductPicker}
                                                                        dialogType="Product"
                                                                        rebatelinestatus={rebatelinestatus}
                                                                        SelectedData={FiltedProducts}
                                                                        selectedType={selectedType}
                                                                        policy={"EngineOil"}
                                                                        dualListBoxOptionsByAttribute={dualListBoxOptionsByAttribute}
                                                                    >
                                                                        <div className={classes['Radiobutton-header']}>
                                                                            <div className={classes['Radiobutton-internal-div']} >
                                                                                <input type="radio" checked={selectedType === 'Attribute'}
                                                                                    value="Attribute" onChange={SelectedProductTypeHandler}
                                                                                    name="product" className={classes['radio-button-Right']} /> {t('Rebates.Attribute')}
                                                                            </div>
                                                                        </div>
                                                                    </ProductTreeDialog>
                                                                    : ''
                                                                }

                                                            </div>

                                                        </div>




                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        ""
                }
                {
                    showSelectedProductPopup === true ?
                        <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, marginRight: "10px", padding: "0" }}>
                            <div className="container" style={{ padding: "0" }}>
                                <div className="row">
                                    <div className="col-lg-12 pr-lg-2">
                                        <div className="card text-center card_border">
                                            <div className="card-header chart-grid__header">
                                                <div className="row" style={{ "margin-left": "-20px" }} >
                                                    <div className="col-md-9 text-left">
                                                        {t('Products Listing')}
                                                    </div>
                                                    {/* <div style={{  backgroundColor: "#fff","margin-left":"55px" }}>
                                                  <button className='btn btn-outline-dark  btn-sm ml-1' onClick={okSelectedCustomer} style={{ width: "80px" }}>{t('Save&Close')}</button>
                                                 </div> */}
                                                    <div className="" style={{ marginLeft: "135px" }}>
                                                        <button className="btn btn-outline-dark  btn-sm ml-1" onClick={clickCloseProductListing} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-1">
                                    <div className="col-lg-12 pr-lg-2">
                                        <div className="card text-center card_border">
                                            <div className="card-header chart-grid__header">

                                            </div>
                                            <div className='scrollbar-class'>

                                                <div className="container">
                                                    <div>
                                                    </div>
                                                    <table className="table table-bordered text-left mt-2 " id="tableregion">
                                                        <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                                            <tr>
                                                                <th>

                                                                </th>
                                                                <th>{t('Material No')}</th>
                                                                <th>{t('Product')}</th>
                                                                <th>{t('Oem')}</th>
                                                                <th>{t('Vehicle Type')}</th>

                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {selectedProduct !== null ? (
                                                                <tr>
                                                                    <td style={{ width: "30px" }}>
                                                                        <input
                                                                            type="checkbox"
                                                                            disabled={true}
                                                                            checked={true}
                                                                            name="product-check"
                                                                        />
                                                                    </td>
                                                                    <td style={{ width: "90px" }}>
                                                                        <div
                                                                            style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }}
                                                                            data-toggle="tooltip"
                                                                            data-placement="right"
                                                                            title={selectedProduct.Name}
                                                                        >
                                                                            {selectedProduct.Name}
                                                                        </div>
                                                                    </td>
                                                                    <td style={{ width: "260px" }}>
                                                                        <div
                                                                            style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }}
                                                                            data-toggle="tooltip"
                                                                            data-placement="right"
                                                                            title={selectedProduct.Label}
                                                                        >
                                                                            {selectedProduct.Label}
                                                                        </div>
                                                                    </td>
                                                                    <td style={{ width: "260px" }}>
                                                                        <div
                                                                            style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }}
                                                                            data-toggle="tooltip"
                                                                            data-placement="right"
                                                                            title={selectedProduct.Oem}
                                                                        >
                                                                            {selectedProduct.Oem}
                                                                        </div>
                                                                    </td>
                                                                    <td style={{ width: "260px" }}>
                                                                        <div
                                                                            style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }}
                                                                            data-toggle="tooltip"
                                                                            data-placement="right"
                                                                            title={selectedProduct.Vehicletype}
                                                                        >
                                                                            {selectedProduct.Vehicletype}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan="5">No product selected</td>
                                                                </tr>
                                                            )}
                                                        </tbody> </table>


                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    {/* <div style={{ paddingTop: '11px', paddingLeft: '47px', paddingBottom: '3px', backgroundColor: "#fff" }}>
                                <button className='btn btn-outline-dark  btn-sm ml-1' onClick={okSelectedCustomer} style={{ width: "80px" }}>{t('Save&Close')}</button>
                            </div> */}
                                </div>
                            </div>
                        </div>
                        :
                        ""
                }
                <Modal show={closeModal}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>{t('Policies.YourChangesWillBeLost!AreYouSureYouWantToClose?')}</Modal.Body>
                    <Modal.Footer>
                        <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black' }} size='sm' onClick={() => closeHandler("Close")}>
                            {t('Close')}
                        </Button>
                        <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black' }} size='sm' onClick={() => closeHandler("Cancel")}>
                            {t('Cancel')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                    onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
            </div>
        </>
    )
}

export default CreateNewEngineOilVolumeMappingPolicy