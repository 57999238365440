import { Link, useLocation, useNavigate } from "react-router-dom"
import React, { useState, useEffect } from 'react'
import classes from '../../RebateProgramComponent/CreateRebateComponent/Form.module.css'
import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal } from 'react-bootstrap';
import Service from '../../../Services/Service';
import 'react-multiple-select-dropdown-lite/dist/index.css'
import CustomErrorPopup from '../../../UI/CustomErrorPopup';
import Multiselect from 'multiselect-react-dropdown';
import $, { each } from 'jquery';
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment'
import SessionManage from '../../../SessionManagement/SessionManage';
import Spinner from 'react-bootstrap/Spinner'
import DatePicker from "react-datepicker";
import ProductTreeDialog from '../../RebateProgramComponent/CreateRebateComponent/ProductTreeDialog';
import Validation from '../../../Services/Validation';
import { NumericFormat } from "react-number-format";
import * as XLSX from 'xlsx';




function NewMaterialPricingGroupMappingPolicy(props) {
  const navigate = useNavigate();
  let loggedInUser = SessionManage.getuserid();
  const [t, i18n] = useTranslation('translation');
  const [userPerms, setuserPermission] = useState([]);
  const [changeHandle, setChangeHandle] = useState(false);
  const [showAccruals, setshowAccruals] = useState(false);
  const [showtargetselection, setShowTargetSelection] = useState('');
  const [showExceptionTypeListselection, setshowExceptionTypeListselection] = useState('');
  const [subdivideList, setsubdivideList] = useState({ 'Period': "", 'Operator': "", Value: "" })
  const [fyThresholdList, setfyThresholdList] = useState({ 'ThresholdValue': "", 'PayoutValue': "", Operator: "" })
  const [showtargettable, setShowTargetTable] = useState(true);
  const [subdivideTargetTypeList, setSubdivideTargetTypeList] = useState([]);
  const [subdivideTargetid, setSubdivideTargetid] = useState();
  const [ExceptionTypeid, setExceptionTypeid] = useState();
  const [addCategory, setaddCategory] = useState(false);
  const [CategoryValues, setCategoryValues] = useState([]);
  const [SubCategoryValues, setSubCategoryValues] = useState([]);
  const [ProductValues, setProductValues] = useState([]);
  const [CustomerValues, setCustomerValues] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [CustomerGroupValues, setCustomerGroupValues] = useState([]);
  const [attribute1Values, setAttribute1Values] = useState([]);
  const [attribute2Values, setAttribute2Values] = useState([]);
  const [attribute3Values, setAttribute3Values] = useState([]);
  const [attribute4Values, setAttribute4Values] = useState([]);
  const [attribute5Values, setAttribute5Values] = useState([]);
  const [UpdatedCategoryValues, setUpdatedCategoryValues] = useState([]);
  const [showWorkflowAlert, setShowWorkflowAlert] = useState(false)

  const [attribute1ValuesHold, setAttribute1ValuesHold] = useState([]);
  const [attribute2ValuesHold, setAttribute2ValuesHold] = useState([]);
  const [attribute3ValuesHold, setAttribute3ValuesHold] = useState([]);
  const [attribute4ValuesHold, setAttribute4ValuesHold] = useState([]);
  const [attribute5ValuesHold, setAttribute5ValuesHold] = useState([]);


  const [selectedOptionsRebateType, setSelectedOptionsRebateType] = useState('');
  const [selectedOptionsRebateMeasure, setSelectedOptionsRebateMeasure] = useState('');
  const [rebateTypeValue, setRebateTypeValue] = useState('')
  const [measureValueLabel, setmeasureValueLabel] = useState("");
  const [selectedOptionsPayout, setSelectedOptionsPayout] = useState();
  const [rebateMeasureData, setRebateMeasureData] = useState([]);
  const [rebatePayoutCondData, setRebatePayoutCondData] = useState([]);
  const [rebateTypeData, setRebateTypeData] = useState([]);
  const [targetedPayCond, setTargetPayCond] = useState(false);
  const [rebatePayoutCond, setRebatePayoutCond] = useState('')
  const [rebateIcon, setRebateIcon] = useState('');
  const [VolumeRebateList, setVolumeRebateList] = useState([{
    id: 0,
    product: "",
    Targetcondition: "",
    Measure_Value: "",
    Target_Measure: "",
    NoOfDays: "",
    Rebate: "",
    Rebate_Value: ""
  },]);
  const [dateRange, setDateRange] = useState([]);
  const [selectedOptionsDateRange, setselectedOptionsDateRange] = useState();

  const [minDate, setminDate] = useState(null);
  const [maxDate, setmaxDate] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedOptionIW, setSelectedOptionIW] = useState(null);
  const [includeworkglowoptions,setIncludeworkglowoptions] = useState([
    {
      label: "Yes",
      value: true,
    },
    {
      label: "No",
      value: false,
    },
  ])

  const [selectedMPG, setSelectedMPG] = useState([]);
  const [selectedParts, setSelectedParts] = useState([]);
  const [selectSettlementFrequency,setSelectedSettlementFrequency] = useState();

  const onChangeSelectedSettlFreq=(selectedOption)=>{
    setSelectedSettlementFrequency(selectedOption.label)
    console.log(selectedOption,"Selected option label")
    exceptionPolicyData.SettlementFrequency=selectedOption.label
    exceptionPolicyData.SettlementfrequencyId=selectedOption.Id
  }

  const OnChangeHandlerStartDate = (e) => {

    setSelectedDate(true)
    let today = moment(new Date()).format(SessionManage.getdateformat());
    setState({ ...state, ['StartDate']: e });
    setExceptionPolicyData({ ...exceptionPolicyData, StartDate: e })

  };

  const OnChangeHandlerEndDate = (e) => {
    setSelectedDate(true)
    let today = moment(new Date()).format(SessionManage.getdateformat());
    setState({ ...state, ['EndDate']: e });
    setExceptionPolicyData({ ...exceptionPolicyData, EndDate: e })

  };

  const [segmentValues, setSegmentValues] = useState([]);
  const [variedSegmentValue, setVariedSegmentValue] = useState([])
  const [VehicleTypeValues, setVehicleTypeValues] = useState([]);
  const [OemValues, setOemValues] = useState([]);
  const [SubcategoryValues, setSubcategoryValues] = useState([]);
  const [variedSubcategoryValues, setVariedSubcategoryValues] = useState([])
  const [MappingAttributeValues, setMappingAttributeValues] = useState([]);
  const [selectedGroupBy, setSelectedGroupby] = useState('');

  const [groupbyValues, setgroupbyValues] = useState([{ label: "By MPG", Id: 1, value: 1 }, { label: "By Part#", Id: 2, value: 2 }]);


  const [MPGList, SetMPGList] = useState([{
    id: 0,
    segmentId: 0,
    subcategory: [],
    attributes: [{ name: "", id: 0, value: [] }],
    rebate: 0,
  }])

  const [PartHashList, SetPartHashList] = useState([{
    id: 0,
    segmentId: 0,
    values: [],
    ValueString: "",
    rebate: 0,
  }])

  const [showparthashSegmentPopup, setshowparthashSegmentPopup] = useState(false)

  const [showmpgPopup, setshowmpgPopup] = useState(false);
  const [showSelectedmpgPopup, setShowSelectedmpgPopup] = useState(false)
  const [showSelectedPartPopup, setShowSelectedPartPopup] = useState(false)
  const [showValuePopup, setshowValuePopup] = useState(false);
  const [loadPopup, setloadPopup] = useState('');

  const [loadValue, setloadValue] = useState('');

  const [selectedIndex, setselectedIndex] = useState({ segmentIndex: -1, attributeIndex: -1 });

  const [selectedParthashIndex, setselectedParthashIndex] = useState({ segmentIndex: -1 });

  const [settlFreqValues, setSettlFreqValues] = useState([]);
  const [rebateTypValues, setRebateTypeValues] = useState([]);
  const [SCDropDownValues, setSCDropDownValues] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedValues, setSelectedValues] = useState("");
  const [showProductPopup, setShowProductPopup] = useState("");
  const [showCategoryPopup, setshowCategoryPopup] = useState("");
  const [showCustomerPopup, setshowCustomerPopup] = useState("");
  const [showCustomerGroupPopup, setshowCustomerGroupPopup] = useState("");
  const [showSetlFreqPopup, setshowSetlFreqPopup] = useState("");
  const [showRebateTypePopup, setshowRebateTypePopup] = useState("");
  const [sidebarwidth, setsidebarwidth] = useState('');
  const [clickProduct, setclickProduct] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [dualListBoxOptionsByHeirarchy, setdualListBoxOptionsByHeirarchy] = useState([])
  const [dualListBoxOptionsByAttribute, setdualListBoxOptionsByAttribute] = useState([])
  const [PrevSelectedTypeState, setPrevSelectedTypeState] = useState('Heirarchy');
  const [selectedType, setSelectedType] = useState('Attribute');
  const [rebatelinestatus, setrebatelinestatus] = useState('New');


  const [parthashNo, setparthashNo] = useState(-1);
  const [FilteredProducts, SetFilteredProducts] = useState([]);
  const [attbCheckAll, setattbCheckAll] = useState(false);
  const [keyValue, SetKeyValue] = useState("");
  const [itemNo, setItemNo] = useState(0);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState("Error");
  const [datagroupCust, setDataGroupCust] = useState([]);
  const [commentsForSubmit, setCommentsForSubmit] = useState("")
  const [showSubmitModal, setShowSubmitModal] = useState(false)
  const [loading, setLoading] = useState(false);
  const [selectedOptionsSetlFreq, setSelectedOptionsSetlFreq] = useState("");
  let disableField = false;
  let catValues = [];
  const [dropDownValues, setDropDownValues] = useState([{
    id: 0,
    Category: [],
    SubCategory: [],
  }])
  let initial_value = [
    { id: 0, Category: "", Operator: "", Value: "", Condition: "", dropDownValues: [], ValueListString: [], SelectedItemSubCategory: "", SubCategory: [] },
  ]
  let initExceptionValues = [
    { id: 0, Operator: "", ValueListString: "", Condition: "", MaterialPricingDataId: 0, SelectedItemSubCategory: '', Category: "" },
  ]
  const [ExceptionValueList, setExceptionValueList] = useState([]);
  const [exceptionValues, setExceptionValues] = useState([])


  const [attbSearch, SetAttbSearch] = useState({
    "Category1": [],
    "Category2": [],
    "Category3": [],
    "Category4": [],
    "Category5": [],
  })
  const [paging, setpaging] = useState({
    pageSize: 20,
    pageNumber: 1
  })
  let [exceptionPolicyData, setExceptionPolicyData] = useState({
    Id: 0,
    CreatedUserId: parseInt(loggedInUser),
    LastModifiedUserId: parseInt(loggedInUser),
    PolicyName: "",
    PolicyId: "",
    FiscalYear: "",
    Validity_From: "",
    Validity_To: "",
    StatusLabel: null,
    CreatedDate: moment(new Date()).format(SessionManage.getcurrentdateformat()),
    IncludeWorkflow: true,
    CategorySubcategoryData: {
      Category: "",
      Subcategory: ""
    }
  });
  let disabled = false;

  const [showExceptionWorkflow, setshowExceptionWorkflow] = useState('');
  const [showWorkflow, setshowWorkflow] = useState('');
  const [workflowchart, setworkflowchart] = useState([]);
  const [showspinnerworkflow, setshowspinnerworkflow] = useState('');
  const [workflowData, setWorkflowData] = useState([]);
  const [woData, setWoData] = useState('');
  const [totalAccrualMoney, setTotalAccrualMoney] = useState(0);
  const [showspinneraccural, setshowspinneraccural] = useState('');
  const [activepolicyProgramLine, setActivePolicyProgramLine] = useState([])
  const [expandAccurals, setExpandAccruals] = useState('')
  const [icon, setIcon] = useState('fa fa-plus');
  const [loadedFrom, setLoadedFrom] = useState('');
  const [checkedValue, setCheckedValue] = useState([])
  const [state, setState] = useState({})
  const clickCloseAccuralWorkflow = () => {
    setshowExceptionWorkflow(false);
  }
  const [workflowApprovers, setWorkflowApprovers] = useState([])
  const [workflowLevelCount, setWorkflowLevelCount] = useState('');
  let wData = [0]
  const [rebateprogrmlinedetail, setrebateProgramLinedetail] = useState({ id: 0 })
  var currentDate = new Date();
  var year = currentDate.getFullYear();
  var month = currentDate.getMonth();
  var day = currentDate.getDate();
  const [defaultDate, setDefaultDate] = useState({ from_date: new Date(), to_date: (new Date(year + 1, month, day)) })
  const [saveProgramLine, setSaveProgramLine] = useState(false);

  const [showSubCategoryPopup, setShowSubCategoryPopup] = useState(false);
  const [SubCategoryList, setSubCategoryList] = useState([]);
  const [SubCategoryIndex, setSubCategoryIndex] = useState(false);

  const [CategoryPopup, setCategoryPopup] = useState(false);
  const [CategoryList, setCategoryList] = useState([]);
  const [CategoryIndex, setCategoryIndex] = useState(false);



  const [AllCategoryValues, setAllCategoryValues] = useState([])
  const [AllSubCategoryValues, setAllSubcategoryValues] = useState([])
  const [regioncheckAll, setRegioncheckAll] = useState(false);
  const [zoneOptions, setZoneOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [stationOptions, setStationOptions] = useState([]);
  const [stockistOptions, setStockistOptions] = useState([]);
  const [showZonePopup, setShowZonePopup] = useState(false);
  const [showStatePopup, setShowStatePopup] = useState(false);
  const [showStationPopup, setShowStationPopup] = useState(false);
  const [showStockistPopup, setShowStockistPopup] = useState(false);
  const [showSelectedZonepopup, setShowSelectedZonepopup] = useState(false);
  const [showSelectedStatepopup, setShowSelectedStatepopup] = useState(false);
  const [showSelectedStationpopup, setShowSelectedStationpopup] = useState(false);
  const [showSelectedStockistpopup, setShowSelectedStockistpopup] = useState(false);
  const [SalesLevelData, setSalesLevelData] = useState([])
  const [regionDataSelected, setRegionDataSelected] = useState([]);

  const [customerDataFilter, setCustomerDataFilter] = useState([]);
  const [customerSearch, setCustomerSearch] = useState('');
  const [data, setData] = useState([]);
  const [filtertextCust, setfiltertextCust] = useState('');
  const [custFilterCol, setCustomerFilterCol] = useState('');
  const [showCustomerfilterpopup, setshowCustomerfilterpopup] = useState(false);
  const [divleft, setdivleft] = useState('');
  const [divtop, setdivtop] = useState('');
  const [filterCusDropDownData, setFilterCusDropDownData] = useState([]);
  const [filterValueCust, setFilterValueCust] = useState([]);
  const [filterValue, setFilterValue] = useState([]);
  const [customercheckAll, setCustomercheckAll] = useState(false);
  const [totalselectedshow, settotalselectedshow] = useState('');
  const [customerChanged, setCustomerChanged] = useState(false);
  const disabledClass = `  ${Service.editPerms(userPerms) == false ? "disabledClass" : ""}`;
  const [filterSearchCust, setFilterSearchCust] = useState("");
  const [filtercustData, setfilterCustData] = useState([]);
  const [customerGroupSearch, setCustomerGroupSearch] = useState('');
  const [showCustomerGroupfilterpopup, setshowCustomerGroupfilterpopup] = useState(false);
  const [custGroupFilterCol, setCustomerGroupFilterCol] = useState('');
  const [filterSearchCustGroup, setFilterSearchCustGroup] = useState("");
  const [filtertextCustGroup, setfiltertextCustGroup] = useState('');
  const [currentCustGroupFilterKey, setcurrentCustGroupFilterKey] = useState('');
  const [filtercustGroupData, setfilterCustGroupData] = useState([]);
  const [filterValueCustGroup, setFilterValueCustGroup] = useState([]);
  const [columnAscending, setColumnAscending] = useState(true);
  const [sortColName, setSortColName] = useState('Id');
  const [searchValue, setSearchValue] = useState("")
  const [filterSearch, setFilterSearch] = useState("");
  const [settlFreqcheckAll, setsettlFreqcheckAll] = useState(false);
  const [RebateTypecheckAll, setRebateTypecheckAll] = useState(false);
  const [filterCustGroupDropDownData, setFilterCustGroupDropDownData] = useState([]);
  const [customerGroupDataFilter, setCustomerGroupDataFilter] = useState([]);
  const [filterValueGroupCustomer, setFilterValuegroupCustomer] = useState({
    label: '',
  });
  const [filterValueCustomer, setFilterValueCustomer] = useState({
    Name: '',
    Label: '',
    Station: '',
    State: '',
    Zone: '',

  });

  const [showSelectedCustomerpopup, setShowSelectedCustomerpopup] = useState(false);
  const [showSelectedCustomerGrouppopup, setShowSelectedCustomerGrouppopup] = useState(false);
  const [showSelectedProductpopup, setShowSelectedProductpopup] = useState(false);
  const [showSelectedCategorypopup, setShowSelectedCategorypopup] = useState(false);
  const [showSelectedRebateType, setShowSelectedRebateType] = useState(false);
  const [showSelectedSettlFreq, setShowSelectedSettlFreq] = useState(false);
  const [countKeyValue, SetCountKeyValue] = useState("");
  const [SettlFreqDataSelected, setSettlFreqDataSelected] = useState([])
  const [RebateTypeDataSelected, setRebateTypeDataSelected] = useState([])
  const [customerDataSelected, setCustomerDataSelected] = useState([]);
  const [customerGroupDataSelected, setCustomerGroupDataSelected] = useState([]);
  const [showApprovedModal, setShowApprovedModal] = useState(false)
  const [commentsForApproved, setCommentsForApproved] = useState("")
  const [showDeniedModal, setShowDeniedModal] = useState(false)
  const [commentsForDenied, setCommentsForDenied] = useState("")
  const [showAddButton, setShowAddButton] = useState(false)
  const [isLocked, setIsLocked] = useState(false);
  const [lockedBy, setLockedBy] = useState('');
  const [modifiedLst, setModifiedLst] = useState([])

  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [jsonList, setJsonList] = useState([]);
  {
    useEffect(() => {
      setLoadedFrom(props.name);

      // Conditionally set the permission URL based on the value of props.name
      const permissionUrl = props.name === "fromApproval"
        ? "/SegmentMappingPolicyList/pending"
        : "/SegmentMapping-Policy/List";

      // Call the function with the conditional URL
      getUserPermission(permissionUrl);

      //  ExceptionValueList.length = 0
      console.log("24/1", props)
      GetSettlFreqData("settlementfrequency")
      GetProductPopUpValues('rebate/ProductSelectorByAttributeNew');
      GetProductAtrribute1("SDCommon/GetAllCategories")
      GetProductAtrribute2("SDCommon/GetAllSubCategories")
      GetCustomerData("Customer/GetIndividualCustomersWithoutGroup")
      getAlltableData()
      GetGroupCustomerData("CustomerGroups/GetAll", paging.pageSize, paging.pageNumber, "Descending", "Id", [], null)
      GetDateRangeAndProducts("DashBoard/GetDateRangeAndProducts");
      getdataProductSelectorByAttribute(0)
      AddProductHandler()
      if (props.id !== '0') {
        getExceptionPolicyById("MaterialPricingPolicy/GetById", props.id)
      }
      else {
        setLockedBy(loggedInUser);
        setIsLocked(true);
        GetExceptionCategorySubCategoryValues("TargetSettingPolicy/GetDropdownValues");
      }
      getZoneData()
    }, [props.id])
  }




  const getZoneData = () => {
    let data1 = []
    let data2 = []
    let data3 = []
    let data4 = []
    Service.getAllZones().then(result => {
      if (typeof result !== 'undefined') { // Fixed variable name from 'res' to 'result'
        console.log(result, 'zoneszzz');
        data1 = result.map((each) => ({
          ...each,
          selected: false
        }));
        setZoneOptions(data1);
      }
    })

    const GetSettlFreqData = (method) => {
      Service.getAlldata(method).then((res) => {
        if (typeof res !== 'undefined') {
          if (typeof res.data !== 'undefined' && res.data !== 'null') {
            if (res.status === 200) {
              if (res.data !== 'null' && res.data !== 'undefined') {
                res.data.forEach((each) => {
                  each['Selected'] = false;
                })
                setSettlFreqValues(res.data)
              }
            }
          }
        }
  
      });
    }
  


    Service.getAllStates().then(res => {
      if (typeof res !== 'undefined') {
        data2 = res.map((each) => ({
          ...each,
          selected: false
        }));
        setStateOptions(data2);
      }
    })


    Service.getAllStations().then(response => {
      if (typeof response !== 'undefined') {
        data3 = response.map((each) => ({
          ...each,
          selected: false
        }));
        setStationOptions(data3);
      }
    })
    Service.getdata("SDCommon/GetAllStockists").then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          data4 = res.data.map((each) => ({
            ...each,
            selected: false
          }));
          setStockistOptions(data4);
        }
      }
    })
  }



  const [ExceptionTypeList, setExceptionTypeList] = useState([]);
  const GetSubdivideTargetType = () => {
    Service.getdata("Rebate/GetSubdivideTargetType").then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          setSubdivideTargetTypeList(res.data);
        }
      }
    });
  }

  const GetExceptionType = () => {
    Service.getdata("ExceptionPolicy/GetAllExceptionType").then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {

          setExceptionTypeList(res.data);
        }
      }
    });
  }
  const GetExceptionCategorySubCategoryValues = (method) => {
    Service.getdata(method).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {

          // res.data.DataList.map((each) => {
          //   each.SubCategory.map((eachsub) => {
          //     eachsub["dropdownState"] = {
          //       'selected': false,
          //       'row': -1
          //     }
          //   })
          // })

          setDropDownValues(res.data.DataList);

          let CatData = [];
          let SubCatData = [];
          res.data.DataList.map((i) => {
            const data = {
              id: i.Id,
              Id: i.Id,
              Name: i.Category,
              label: i.Category,
              value: i.value,
            }

            CatData.push(data);
            i.SubCategory.map((s) => {
              const subdata = {
                id: s.Id,
                Id: s.value,
                Name: s.label,
                label: s.label,
                value: s.value,
              }
              SubCatData.push(subdata);
            })

          });

          setCategoryValues(CatData);
          setSubCategoryValues(SubCatData);

          setUpdatedCategoryValues(list => ([...CatData]));
          catValues = CatData;

          setModifiedLst(res.data.DataList)
        }
      }
    });
  }
  const GetProductPopUpValues = (programId) => {

    Service.getdataWithPagingandattributeSearchingByGroup('rebate/ProductSelectorByAttributeNew', '', 200000, 1, 0, [], 0).then((res) => {
      if (typeof res !== 'undefined' && typeof res.data !== 'undefined') {

        setProductValues(ProductValues => ([...ProductValues, ...res.data.DataList]));
      }
    });
  };

  const getAlltableData = () => {
    Service.getdata('MaterialPricingPolicy/GetSegmentDetail').then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
              res.data.forEach((each) => {
                each['selected'] = false
              })
              setSegmentValues(res.data)
              setVariedSegmentValue(res.data)
            }
          }
        }
      }
    });
    Service.getdata('MaterialPricingPolicy/GetOemDetail').then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
              res.data.forEach((each) => {
                each['selected'] = false
              })
              setOemValues(res.data)
            }
          }
        }
      }
    });
    Service.getdata('MaterialPricingPolicy/GetVehicleType').then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
              res.data.forEach((each) => {
                each['selected'] = false
              })
              setVehicleTypeValues(res.data)
            }
          }
        }
      }
    });
    Service.getdata('MaterialPricingPolicy/GetSubCategory').then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
              res.data.forEach((each) => {
                each['selected'] = false
                each['hidden'] = false
              })
              setSubcategoryValues(res.data)
            }
          }
        }
      }
    });
    Service.getdata('MaterialPricingPolicy/GetMappingAttributes').then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
              res.data.forEach((each) => {
                each['selected'] = false
              })
              const defaultOption = {
                Id: 0,
                CreatedDate: "0001-01-01T00:00:00",
                CreatedUserId: null,
                DefaultGroupId: null,
                Deleted: false,
                ISActive: false,
                IsActiveValue: null,
                LastModifiedDate: "0001-01-01T00:00:00",
                LastModifiedUser: null,
                LastModifiedUserId: null,
                Locked: false,
                LockedBy: null,
                LockedByUserName: null,
                Name: "Select",
                PaymentMethodId: null,
                SettlementPeriodId: null,
                label: "Select",
                selected: false,
                value: null,
            };
            
              res.data.unshift(defaultOption);
              setMappingAttributeValues(res.data)
              console.log(res.data,"19-2 MappingAttributeValues")
            }
          }
        }
      }
    });
  }

  const GetCustomerData = (method) => {

    Service.getdata(method).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {

              setCustomerValues(res.data)

              setCustomerData(res.data)
            }
          }
        }
      }

    });
  }
  const GetGroupCustomerData = (method, pageSize, pageNumber, sortType, SortProperty, filterValue, search) => {

    Service.getdataWithPagingByGroupSearch(method, pageSize, pageNumber, sortType, SortProperty, filterValue, search).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {

              setCustomerGroupValues(res.data.DataList)

            }
          }
        }
      }

    });
  }
  const GetProductAtrribute1 = (method) => {
    Service.getdata(method).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          // if (res.status === 200) {
          if (res.data !== 'null' && res.data !== 'undefined') {
            let data1 = res.data.map((each) => ({
              ...each,
              selected: false
            }));
            setAllCategoryValues(data1)
          }
          // }
        }
      }

    });
  }
  const GetProductAtrribute2 = (method) => {

    Service.getdata(method).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
              let data1 = res.data.map((each) => ({
                ...each,
                selected: false
              }));
              setAllSubcategoryValues(data1)
            }
          }
        }
      }

    });
  }
  const GetProductAtrribute3 = (method) => {
    let key = "Cat-III"
    Service.getAttributeValue(method, key).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {

              setAttribute3Values(res.data)
              setAttribute3ValuesHold(res.data)
              SetAttbSearch({ ...attbSearch, ['Category3']: res.data })
            }
          }
        }
      }

    });
  }
  const GetProductAtrribute4 = (method) => {
    let key = "Cat-IV"
    Service.getAttributeValue(method, key).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {

              setAttribute4Values(res.data)
              setAttribute4ValuesHold(res.data)
              SetAttbSearch({ ...attbSearch, ['Category4']: res.data })
            }
          }
        }
      }

    });
  }
  const GetProductAtrribute5 = (method) => {
    let key = "Cat-V"
    Service.getAttributeValue(method, key).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {

              setAttribute5Values(res.data)
              setAttribute5ValuesHold(res.data)
              SetAttbSearch({ ...attbSearch, ['Category5']: res.data })
            }
          }
        }
      }

    });
  }
  const GetSettlFreqData = (method) => {
    Service.getAlldata(method).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
              res.data.forEach((each) => {
                each['Selected'] = false;
              })
              setSettlFreqValues(res.data)
            }
          }
        }
      }

    });
  }

  const GetRebateTypeData = (method) => {
    Service.getdata(method).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
              res.data.forEach((each) => {
                each['Selected'] = false;
              })
              setRebateTypeValues(res.data)
            }
          }
        }
      }

    });
  }

  const onChangeTargetSelection = (e) => {

    setShowTargetSelection(e.name)

    setSubdivideTargetid(e.value);


    if (e.value === "Select") {
      setShowTargetTable(false)
    }
    else {
      setShowTargetTable(true)
      setShowTargetSelection(e.name)
    }
    setExceptionPolicyData({ ...exceptionPolicyData, ['SubTierTargetTypeId']: e.value, ['SubDivideTargetType']: e.name });
  }

  const [subdividefield, setSubdividefield] = useState(false);
  const [FYThresholdfield, setFYThresholdfield] = useState(false);

  const getdataProductSelectorByAttribute = () => {
    Service.getdataWithPagingandattributeSearchingByGroup('Scheme/ProductSelectorByAttributeNew', '', 200000, 1, 0, [], 0).then((res) => {
      if (typeof res !== 'undefined' && typeof res.data !== 'undefined') {
        setdualListBoxOptionsByAttribute(res.data.DataList)
        console.log("15-3api", res.data.DataList)
      }
    });
  };

  const AddProductHandler = () => {

    if (dualListBoxOptionsByHeirarchy.length == 0) {
      Service.postdatawithId("Rebate/GetProductSelectorUpdatedByGroup").then((res) => {
        if (typeof res !== 'undefined') {
          if (typeof res.data !== 'undefined' && res.data !== 'null') {
            if (res.status === 200) {
              if (res.data !== 'null' && res.data !== 'undefined') {
                console.log("15-3api", res.data)
                setdualListBoxOptionsByHeirarchy(res.data)

              }
            }
          }
        }

      });
    }
  }


  const OnChangeHandlerGroupBy = (data) => {
    setSelectedGroupby(data.label)
    let item1 = [{
      id: 0,
      segmentId: 0,
      subcategory: [],
      attributes: [],//[{ name: "", id: 0, value: [] }],
      rebate: 0,
    }]
    let item2 = [
      {
        id: 0,
        segmentId: 0,
        values: [],
        ValueString: "",
        rebate: 0,
      }
    ]
    SetMPGList(item1)
    SetPartHashList(item2)
  }

  const HandlePartHashTier = (index, action) => {
    let list = [...PartHashList];

    if (action === "add") {
      list.push({
        id: 0,
        segmentId: 0,
        values: [],
        ValueString: "",
        rebate: 0,
      });
    }
    else if (action === "remove") {
      list = list.filter((_, i) => i !== index);
    }

    SetPartHashList(list);
  }


  const HandleAttributeTier = (index, innerindex, action) => {
    let list = [...MPGList];
    let list2 = [...list[index].attributes];

    if (action === "add") {
      list2.push({ name: "", id: 0, value: [] });
    }
    else if (action === "remove") {
      list2 = list2.filter((_, i) => i !== innerindex);
    }

    list[index].attributes = list2;

    SetMPGList(list);
  };

  // const add = (flag) => {
  //   setExceptionValues(initExceptionValues);
  //   if (ExceptionValueList.length == 0) {
  //     setExceptionValueList(initial_value)
  //   }
  // }

  const add = (flag) => {
    setExceptionValues(initExceptionValues);
    if (ExceptionValueList.length == 0) {
    console.log('13/11',ExceptionValueList)
  
      let list  = [...ExceptionValueList];
       dropDownValues.map((i) => {
          if (i.Category === 'Customer') {
           setExceptionValueList([{ id: 0, Category: i.Category, Operator: "", Value: "", Condition: "", dropDownValues: [], ValueListString: [], SelectedItemSubCategory: "", SubCategory: i.SubCategory }]);
  
          }
        });
        console.log('13/11',ExceptionValueList)
    }
  
    setaddCategory(true);
  }


  const HandleSegmentTier = (index, action) => {
    let list = [...MPGList];
    if (action === "remove") {
      list = list.filter((_, i) => i !== index)
    }
    if (action === "add") {
      if (list[index].segmentId !== 0 && list[index].segmentId !== "" && list[index].subcategory?.length > 0) {

        list.push(
          {
            id: 0,
            segmentId: 0,
            subcategory: [],
            attributes: [],//[{ name: "", id: 0, value: [] }],
            rebate: 0,
          }
        )
      }
      else {
        setShowErrorModal(true)
        setErrorMessage(`${"Please select all values in row "} ${index + 1} `)
      }
    }


    SetMPGList(list);
  }

  const handleattribute = (e, attributeindex, segmentindex) => {
    let list1 = [...MPGList]
    let list2 = list1[segmentindex].attributes

    const selectedAttribute = MappingAttributeValues.find(each => each.Name === e.target.value);

    list2[attributeindex].name = selectedAttribute.Name
    list2[attributeindex].id = selectedAttribute.Id
    list2[attributeindex].value = []

    list1[segmentindex].attributes = list2
    
    SetMPGList(list1)
  }
  const mpgpopup = (e, index, dropdown) => {
    console.log("19-9", e.target.value, index, dropdown);

    let list = [...MPGList];
    let segmentId = 0;

    if (dropdown === 'segment' || dropdown === 'mpg') {
      if (dropdown === 'segment') {
        const variedSegmentValues = segmentValues.filter((segmentValue) =>
          !list.some((val, i) => i !== index && val.segmentId === segmentValue.Id)
        );
    
        // Update the state with the filtered segment values
      
        console.log(variedSegmentValue,"Varied Segment Value")
        segmentId = list[index]?.segmentId;
        variedSegmentValue.forEach((each) => {
          each.selected = each.Id === segmentId;
        });
        setVariedSegmentValue(variedSegmentValues);
       
      }
      else {
        const variedSubcategoryValuess = SubcategoryValues.filter((subcategoryValue) =>
          !list.some((val, i) =>
            i !== index &&  
            val.subcategory.includes(subcategoryValue.Id) // Check if subcategory exists in other indices
          )
        );
        console.log(variedSubcategoryValuess,"Varied Subcategory")
        variedSubcategoryValuess.forEach((each) => {
          let isMatched = false;
        
          
      
          // Update the state with the filtered segment values
        
         
          const isAlreadySelectedInOtherIndexes = list.some((item, idx) =>
            item.segmentId === list[index].segmentId &&
            idx !== index && item.subcategory.includes(each.Id)
          );


          const isOriginalSelectionIndex = list[index]?.subcategory.includes(each.Id);

          if (!isAlreadySelectedInOtherIndexes || isOriginalSelectionIndex) {
            list[index]?.subcategory.forEach((element) => {
              if (each.Id === element) {
                isMatched = true;
              }
            });

            each.selected = isMatched;
            each.hidden = false;
          } else {
            each.hidden = true;
          }
        });
        setVariedSubcategoryValues(variedSubcategoryValuess)
      }


      setselectedIndex({ ...selectedIndex, ['segmentIndex']: index });
      setloadPopup(dropdown);
      setshowmpgPopup(true);
      setsidebarwidth("60vw");
    }
    else {
      if (list[index]) {
        console.log('Updating rebate:', e.target.value);
        list[index].rebate = e.target.value;
        SetMPGList(list);
      }
    }
  }



  const mpgSelectedpopup = (e, index, dropdown) => {
    if (dropdown === "segment") {
      // Get the segmentId at the specified index in MPGList
      const segmentId = MPGList[index]?.segmentId;

      // Find the matching segment object from segmentValues
      const selectedSegment = segmentValues.find((each) => each.Id === segmentId);

      // Set the selected segment in state (assuming setSelectedSegment is defined for segment selection)
      if (selectedSegment) {
        setSelectedMPG(selectedSegment);
      }
    }
    
    else {
      // Get the subcategory array at the specified index in MPGList
      const subcategoryIds = MPGList[index]?.subcategory || [];

      // Find matching subcategory objects in SubcategoryValues
      const selectedMPGItems = subcategoryIds
        .map((id) => SubcategoryValues.find((each) => each.Id === id))
        .filter(Boolean); // Filter out undefined values

      // Set the selected MPG items in state
      setSelectedMPG(selectedMPGItems);

    }

    setloadPopup(dropdown)
    setShowSelectedmpgPopup(true)
    setsidebarwidth("60vw");
  };

  const mpgValueSelectedPopup = (e,index,innerIndex)=>{
        
    
     if (MPGList[index].attributes[innerIndex].name === "Vehicle Type")
       {
        const SelectedValue = MPGList[index]?.attributes[innerIndex].value || []; 
        const selectedValueId = SelectedValue
        .map((id) => VehicleTypeValues.find((each) => each.Id === id))
        .filter(Boolean); // Filter out undefined values

      // Set the selected MPG items in state
      setSelectedMPG(selectedValueId);

       }
       else  
       {

        const SelectedValue = MPGList[index]?.attributes[innerIndex].value || [];
        const selectedValueId = SelectedValue
        .map((id) => OemValues.find((each) => each.Id === id))
        .filter(Boolean); // Filter out undefined values

      // Set the selected MPG items in state
      setSelectedMPG(selectedValueId); 
      
      }

       setloadPopup(MPGList[index].attributes[innerIndex].name)
       setShowSelectedmpgPopup(true)
       setsidebarwidth("60vw");
  
  }

  const partSelectedpopup = (e, index, dropdown) => {

    if (dropdown === "segment") {
      // Get the segmentId at the specified index in MPGList
      const segmentId = PartHashList[index]?.segmentId;

      // Find the matching segment object from segmentValues
      const selectedSegment = segmentValues.find((each) => each.Id === segmentId);

      // Set the selected segment in state (assuming setSelectedSegment is defined for segment selection)
      if (selectedSegment) {
        setSelectedParts(selectedSegment);
      }
    }


    else {

      const productIds = PartHashList[index]?.values || [];
      console.log("Product Ids", productIds)
      const filteredProducts = ProductValues.filter(product => productIds.includes(product.Id.toString()))
      setSelectedParts(filteredProducts);

    }

    setloadPopup(dropdown)
    setShowSelectedPartPopup(true)
    setsidebarwidth("60vw");
  };

  // const mpgpopup = (e, index, dropdown) => {
  //   console.log("19-9", e.target.value, index, dropdown);

  //   let list = [...MPGList];
  //   let segmentId = 0;

  //   if (dropdown === 'segment' || dropdown === 'mpg') {
  //     if (dropdown === 'segment') {
  //       segmentId = list[index]?.segmentId;
  //       segmentValues.forEach((each) => {
  //         each.selected = each.Id === segmentId;
  //       });
  //     }
  //     else {
  //       SubcategoryValues.forEach((each) => {
  //         let isMatched = false;
  //         const isAlreadySelectedInOtherIndexes = list.some((item, idx) =>
  //           item.segmentId === list[index].segmentId &&
  //           idx !== index && item.subcategory.includes(each.Id)
  //         );


  //         const isOriginalSelectionIndex = list[index]?.subcategory.includes(each.Id);

  //         if (!isAlreadySelectedInOtherIndexes || isOriginalSelectionIndex) {
  //           list[index]?.subcategory.forEach((element) => {
  //             if (each.Id === element) {
  //               isMatched = true;
  //             }
  //           });

  //           each.selected = isMatched;
  //           each.hidden = false;
  //         } else {
  //           each.hidden = true;
  //         }
  //       });
  //     }


  //     setselectedIndex({ ...selectedIndex, ['segmentIndex']: index });
  //     setloadPopup(dropdown);
  //     setshowmpgPopup(true);
  //     setsidebarwidth("60vw");
  //   }
  //   else {
  //     if (list[index]) {
  //       console.log('Updating rebate:', e.target.value);
  //       list[index].rebate = e.target.value;
  //       SetMPGList(list);
  //     }
  //   }
  // }


  const parthashsegmentpopup = (e, index, dropdown) => {


    let list = [...PartHashList];
    let segmentId = 0;

    setselectedParthashIndex(index)

    if (dropdown === 'segment') {
      if (dropdown === 'segment') {
        segmentId = list[index]?.segmentId;
        segmentValues.forEach((each) => {
          each.selected = each.Id === segmentId;
        });
      }
      setshowparthashSegmentPopup(true)
      setsidebarwidth("60vw");
    }
    else {
      if (list[index]) {
        list[index].rebate = e.target.value;
        SetPartHashList(list);
      }
    }
  }

  const OpenparthashProduct = (index) => {
    let list = [...PartHashList];
    let selectedProducts = [];

    let selectedIdsAcrossOtherIndexes = [];
    list.forEach((part, idx) => {
      if (idx !== index) {
        selectedIdsAcrossOtherIndexes = [
          ...selectedIdsAcrossOtherIndexes,
          ...part.values
        ];
      }
    });

    dualListBoxOptionsByAttribute.forEach((each) => {
      each.Selected = false;
    });


    dualListBoxOptionsByAttribute.forEach((each) => {
      if (list[index].values.includes(each.Id.toString())) {
        each.Selected = true;
        selectedProducts.push(`${each.Label}_${each.Id}`);
      }
      each.Hidden = selectedIdsAcrossOtherIndexes.includes(each.Id.toString());
    });
    console.log("30-9s", selectedProducts)
    SetFilteredProducts(selectedProducts);

    setparthashNo(index);
    setShowModal(true);
    setSelectedType('Attribute');
    setShowProductPopup(true);
    setclickProduct(true);
    setsidebarwidth("70vw");
  };



  const closempgpopup = () => {
    setshowmpgPopup(false)
  }
  const closeSelectedMpgPopup = () => {
    setShowSelectedmpgPopup(false)
  }
  const closeSelectedPartPopup = () => {
    setShowSelectedPartPopup(false)
  }

  const handleValueClose = () => {
    setshowValuePopup(false)
  }

  const handlempgvalueChanges = (e, name, id, mapattrtype) => {
    let list1 = [...MPGList]
    let list2 = list1[selectedIndex.segmentIndex].attributes

    if (e.target.checked) {
      list2[selectedIndex.attributeIndex].value?.push(id)
      if (mapattrtype === "Vehicle Type") {
        VehicleTypeValues.forEach((each) => {
          if (each.Id == id) {
            each['selected'] = true
          }
        })
      }
      else {
        OemValues.forEach((each) => {
          if (each.Id == id) {
            each['selected'] = true
          }
        })
      }
    }
    else {
      list2[selectedIndex.attributeIndex].value = list2[selectedIndex.attributeIndex].value.filter((each) => each !== id)
      if (mapattrtype === "Vehicle Type") {
        VehicleTypeValues.forEach((each) => {
          if (each.Id == id) {
            each['selected'] = false
          }
        })
      }
      else {
        OemValues.forEach((each) => {
          if (each.Id == id) {
            each['selected'] = false
          }
        })
      }
    }
    list1[selectedIndex.segmentIndex].attributes = list2
    SetMPGList(list1)
  }


  const openvaluepopup = (segmentIndex, attributeIndex) => {
    let list1 = [...MPGList]
    let list2 = list1[segmentIndex].attributes

    setselectedIndex({ ...selectedIndex, ['segmentIndex']: segmentIndex, ['attributeIndex']: attributeIndex })

    if (list1[segmentIndex].attributes[attributeIndex].name === "Vehicle Type") {
      setloadValue('Vehicle Type');


      VehicleTypeValues.forEach(each => {
        each.selected = false;
      });


      if (list2[attributeIndex].value?.length > 0) {
        list2[attributeIndex].value.forEach(element => {
          VehicleTypeValues.forEach(each => {
            if (each.Id === element) {
              each.selected = true;
            }
          });
        });
      }
    } else  if (list1[segmentIndex].attributes[attributeIndex].name === "OEM") {
      setloadValue('OEM');


      OemValues.forEach(each => {
        each.selected = false;
      });


      if (list2[attributeIndex].value?.length > 0) {
        list2[attributeIndex].value.forEach(element => {
          OemValues.forEach(each => {
            if (each.Id === element) {
              each.selected = true;
            }
          });
        });
      }
    }
   else
   {
      setloadValue('Mapping 27');


      OemValues.forEach(each => {
        each.selected = false;
      });


      if (list2[attributeIndex].value?.length > 0) {
        list2[attributeIndex].value.forEach(element => {
          OemValues.forEach(each => {
            if (each.Id === element) {
              each.selected = true;
            }
          });
        });
      }
    }


    setshowValuePopup(true)
    setsidebarwidth("60vw")
  }

  const handlempgChanges = (event, label, id, dropdown) => {
    let list = [...MPGList]
    if (event.target.checked) {
      if (dropdown === 'segment') {
        list[selectedIndex.segmentIndex].segmentId = id
        const item = variedSegmentValue.find((each) => each.Id === id);
        if (item) {
          item.selected = true;
        }
      }
      else {
        list[selectedIndex.segmentIndex].subcategory.push(id)
        const item = variedSubcategoryValues.find((each) => each.Id === id);
        if (item) {
          item.selected = true;
        }
      }
    }
    else {
      if (dropdown === 'segment') {
        list[selectedIndex.segmentIndex].segmentId = 0
        const item = segmentValues.find((each) => each.Id === id);
        if (item) {
          item.selected = false;
        }
      }
      else {
        list[selectedIndex.segmentIndex].subcategory = list[selectedIndex.segmentIndex].subcategory.filter((each) => each !== id)
        const item = SubcategoryValues.find((each) => each.Id === id);
        if (item) {
          item.selected = false;
        }
      }
    }
    SetMPGList(list)
  }

  const handlepartHashselection = (event, label, id, dropdown) => {
    let list = [...PartHashList]
    if (event.target.checked) {
      if (dropdown === 'segment') {
        list[selectedParthashIndex].segmentId = id
        const item = segmentValues.find((each) => each.Id === id);
        if (item) {
          item.selected = true;
        }
      }

    }
    else {
      if (dropdown === 'segment') {
        list[selectedParthashIndex].segmentId = 0
        const item = segmentValues.find((each) => each.Id === id);
        if (item) {
          item.selected = false;
        }
      }
    }
    SetPartHashList(list)
  }
  const closeparthashsegmentpopup = () => {
    setshowparthashSegmentPopup(false)
  }


  const AddVolume = () => {
    if (VolumeRebateList[0].Targetcondition == null || VolumeRebateList[0].Targetcondition == '' || VolumeRebateList[0].Targetcondition === 'Select') {
      setErrorMessage(`${t('Please Select')}${t('Rebates.TargetCond')}`);
      setErrorStatus("Error")
      setShowErrorModal(true)
    }
    else if (VolumeRebateList[0].Target_Measure && VolumeRebateList[0].Rebate_Value != null && (VolumeRebateList[0].Targetcondition != null && VolumeRebateList[0].Targetcondition != '' && VolumeRebateList[0].Targetcondition != 'Select')) {
      const _item = {
        id: 0,
        product: "",
        Targetcondition: VolumeRebateList[0].Targetcondition,
        Measure_Value: "",
        Target_Measure: VolumeRebateList[0].Target_Measure,
        Rebate: "",
        Rebate_Value: VolumeRebateList[0].Rebate_Value
      };
      setVolumeRebateList([...VolumeRebateList, _item]);
    }
    else {
      setErrorMessage(`${t('PleaseSelect')}${t('Rebates.Rebate')} ${'₹ / % / LS'}`);
      setErrorStatus("Error")
      setShowErrorModal(true)
    }
  }

  const RemoveVolume = (idx) => {
    setVolumeRebateList(VolumeRebateList.filter((item, index) => index !== idx));
    setChangeHandle(true)
  }




  const AddVolumeCat = (index) => {

    let volCheck = false

    if (ExceptionValueList.length >= 0) {
      ExceptionValueList.forEach((each, index) => {
        let idx = parseInt(ExceptionValueList.indexOf(each)) + 1

        if (each.Category == '') {
        setShowErrorModal(true)
        setErrorMessage(`${"Please select Category in row"} ${index + 1} `)
          // alert("Please select Category in row " + idx)
          volCheck = true
        }
        else if (each.SelectedItemSubCategory == '') {
          setShowErrorModal(true)
          setErrorMessage(`${"Please select SubCategory in row"} ${index + 1} `)
          // alert(" " + idx)
          volCheck = true
        }
        else if (each.Operator == '') {
          setShowErrorModal(true)
          setErrorMessage(`${"Please select Operator in row"} ${index + 1} `)
          // alert("Please select Operator in row " + idx)
          volCheck = true
        }
        else if (each.ValueListString.length == 0) {
          setShowErrorModal(true)
          setErrorMessage(`${"Please select Value in row"} ${index + 1} `)
          // alert("Please select Value in row " + idx)
          volCheck = true
        }
      })
    }
    if (volCheck == false) {
      const _item = {
        id: 0,
        Category: "",
        Operator: "",
        Value: "",
        Condition: "",
        SubCategory: [],
        ValueListString: [],
        SelectedItemSubCategory: '',
      }
      const item2 = {
        id: 0,
        c: 0,
        Operator: "",
        ValueListString: [],
        Condition: "",
        Category: "",
        SelectedItemSubCategory: '',
      }
      setExceptionValueList([...ExceptionValueList, _item]);
      setExceptionValues([...exceptionValues, item2])
    }
  }
  const RemoveVolumeCat = (idx) => {
    let list = [...ExceptionValueList]

    if (list[idx] && list[idx].SelectedItemSubCategory) {
      switch (list[idx].SelectedItemSubCategory) {
        case "Zone":
          zoneOptions.map((each) => { each.selected = false });
          break;
        case "Station":
          stationOptions.map((each) => { each.selected = false });
          break;
        case "State":
          stateOptions.map((each) => { each.selected = false });
          break;
        case "Stockist":
          stockistOptions.map((each) => { each.selected = false });
          break;
        // case "ECC":
        //   [].map((each) => { each.selected = false });
        //   break;
        case "Label":
          settlFreqValues.map((each) => { each.Selected = false });
          break;
        case "Rebate Type":
          rebateTypValues.map((each) => { each.selected = false });
          break;
        case "Individual Customer":
          CustomerValues.map((each) => { each.selected = false });
          break;
        case "Group Customer":
          CustomerGroupValues.map((each) => { each.selected = false });
          break;
        case "Category":
          AllCategoryValues.map((each) => { each.selected = false });
          break;
        case "SubCategory":
          AllSubCategoryValues.map((each) => { each.selected = false });
          break;

          list[idx]['SelectedItemSubCategory'] = '';
          list[idx]['MaterialPricingDataId'] = '';
          list[idx]['ValueListString'] = [];
          list[idx]['Operator'] = "";
      }
    } else {
      list[idx]['SelectedItemSubCategory'] = '';
      list[idx]['MaterialPricingDataId'] = '';
      list[idx]['ValueListString'] = [];
      list[idx]['Operator'] = "";
    }

    setExceptionValueList(list);
    setExceptionValueList(ExceptionValueList.filter((item, index) => index !== idx));
    setExceptionValues(exceptionValues.filter((item, index) => index !== idx));

    setChangeHandle(true)
  }


  const ClickValueCategoryPopup = (index) => {

    let categoryOptions = [...CategoryValues]
    console.log(categoryOptions, "Catdata");
    let list = [...ExceptionValueList]
    console.log(ExceptionTypeList, "Listdata");
    if (list[index]?.Category === '') {
      categoryOptions.forEach((each) => each['selected'] = false)
      setCategoryList(categoryOptions)
    }
    else {
      categoryOptions.forEach((each) => {
        if (each.Name === list[index].Category) {
          each['selected'] = true
        }
      })
      setCategoryList(categoryOptions)
    }
    setCategoryIndex(index)
    setCategoryPopup(true)
    setsidebarwidth("60vw")
  }

  const CloseCategory = () => {
    setCategoryPopup(false)
    setCategoryList([])
  }

  const ClickValueSubCategoryPopup = (subcatData, index) => {
    setSubCategoryList(subcatData)
    setSubCategoryIndex(index)
    setShowSubCategoryPopup(true)
    setsidebarwidth("60vw")
  }

  const CloseSubCategory = () => {
    setShowSubCategoryPopup(false)
  }
  //MaterialPricingDataId

  const handleSubCatChanges = (event, data) => {
    const list = [...ExceptionValueList];
    const subcatarr = [...list[SubCategoryIndex].SubCategory];
    subcatarr.forEach((each) => {
      if (event.target.checked && each.label === data.label && data !== null) {
        list[SubCategoryIndex]['SelectedItemSubCategory'] = data.label;
        list[SubCategoryIndex]['MaterialPricingDataId'] = data.value;
      }
      else if (!event.target.checked) {
        switch (list[SubCategoryIndex]?.SelectedItemSubCategory) {
          case "Zone":
            zoneOptions.forEach((each) => { each.selected = false });
            break;
          case "Station":
            stationOptions.forEach((each) => { each.selected = false });
            break;
          case "State":
            stateOptions.forEach((each) => { each.selected = false });
            break;
          case "Stockist":
            stockistOptions.forEach((each) => { each.selected = false });
            break;
          // case "ECC":
          //   [].forEach((each) => { each.selected = false });
          //   break;
          case "Label":
            settlFreqValues.forEach((each) => { each.Selected = false });
            break;
          case "Rebate Type":
            rebateTypValues.forEach((each) => { each.selected = false });
            break;
          case "Individual Customer":
            CustomerValues.forEach((each) => { each.selected = false });
            break;
          case "Group Customer":
            CustomerGroupValues.forEach((each) => { each.selected = false });
            break;
          case "Category":
            AllCategoryValues.forEach((each) => { each.selected = false });
            break;
          case "SubCategory":
            AllSubCategoryValues.forEach((each) => { each.selected = false });
            break;
        }
        list[SubCategoryIndex]['SelectedItemSubCategory'] = '';
        list[SubCategoryIndex]['MaterialPricingDataId'] = '';
        list[SubCategoryIndex]['ValueListString'] = [];
        list[SubCategoryIndex]['Operator'] = "";
      }
    });
    setExceptionValueList(list);
  }



  const OnCategoryChange = (e, data) => {
    const list = [...ExceptionValueList];
    const catarr = [...CategoryList];

    catarr.forEach((each) => {
      if (e.target.checked) {
        if (each.label === data.label) {
          each.selected = true;
          list[CategoryIndex]['Category'] = data.Name;
          let SubCatData = [];
          dropDownValues.map((i) => {
            if (i.Category === data.label) {
              list[CategoryIndex]['SubCategory'] = i.SubCategory;
            }
          });
        }
      }
      else {
        list[CategoryIndex]['Category'] = '';
        list[CategoryIndex]['SubCategory'] = []
      }
    });

    if (list[CategoryIndex]?.Category === "") {
      switch (list[CategoryIndex]?.SelectedItemSubCategory) {
        case "Zone":
          zoneOptions.map((each) => { each.selected = false });
          break;
        case "Station":
          stationOptions.map((each) => { each.selected = false });
          break;
        case "State":
          stateOptions.map((each) => { each.selected = false });
          break;
        case "Stockist":
          stockistOptions.map((each) => { each.selected = false });
          break;
        // case "ECC":
        //   [].map((each) => { each.selected = false });
        //   break;
        case "Label":
          settlFreqValues.map((each) => { each.Selected = false });
          break;
        case "Rebate Type":
          rebateTypValues.map((each) => { each.selected = false });
          break;
        case "Individual Customer":
          CustomerValues.map((each) => { each.selected = false });
          break;
        case "Group Customer":
          CustomerGroupValues.map((each) => { each.selected = false });
          break;
        case "Category":
          AllCategoryValues.map((each) => { each.selected = false });
          break;
        case "SubCategory":
          AllSubCategoryValues.map((each) => { each.selected = false });
          break;

          list[CategoryIndex]['SelectedItemSubCategory'] = '';
          list[CategoryIndex]['MaterialPricingDataId'] = '';
          list[CategoryIndex]['ValueListString'] = [];
          list[CategoryIndex]['Operator'] = "";
      }
    } else {
      list[CategoryIndex]['SelectedItemSubCategory'] = '';
      list[CategoryIndex]['MaterialPricingDataId'] = '';
      list[CategoryIndex]['ValueListString'] = [];
      list[CategoryIndex]['Operator'] = "";
    }


    setCategoryList(catarr)

    setExceptionValueList(list)
  }

  const OnOperatorChange = (data, index) => {
    const list = [...ExceptionValueList];
    const list2 = [...exceptionValues];

    list[index]['Operator'] = data;

    setExceptionValueList(list);
    setExceptionValues(list2);

  }


  const UpdateProducts = (_SelectedProducts, saveStatus) => {

    setChangeHandle(true)

    setPrevSelectedTypeState(selectedType);

    let ids = []
    SetFilteredProducts(_SelectedProducts)
    if (saveStatus) {
      setShowProductPopup(false);
    }

    _SelectedProducts.forEach((each) => {
      const pieces = each.split("_")
      const last = pieces[pieces.length - 1]
      ids.push(last)
    })

    console.log("30-9selec", _SelectedProducts)
    console.log("30-9ids", ids)
    const list = [...PartHashList];
    list[parthashNo]['values'] = ids;

    SetPartHashList(list)
  }

  console.log("30-9parthash", PartHashList)

  const SelectedProductTypeHandler = (event) => {
    setChangeHandle(true)
    setSelectedType(event.target.value);

    if (event.target.value === 'Heirarchy') {
      setPrevSelectedTypeState("Attribute");
    } else {
      setPrevSelectedTypeState("Heirarchy");
    }
  }

  const CloseProductPicker = () => {


  }

  const ClickValueCountPopup = (item, index) => {
    if (item.SelectedItemSubCategory != null && item.SelectedItemSubCategory != '' && item.SelectedItemSubCategory != undefined) {
      if ((item.SelectedItemSubCategory).toLowerCase() == ('Label').toLowerCase() && (item.Category).toLowerCase() == ('Product').toLowerCase()) {
        let selectedValue = item.ValueListString;
        let selectedData = []
        let productData = [...ProductValues]
        let filteredData = []
        if (selectedValue != null && selectedValue.length > 0) {
          selectedValue.forEach((selectedpro, index) => {
            ProductValues.forEach((eachpro) => {
              if (selectedpro == eachpro.Id) {
                eachpro.Selected = true
                let z = `${eachpro.Label}${"_"}${eachpro.Id}`
                filteredData.push(z)
              }
            })
          })
        }
        setdualListBoxOptionsByAttribute(productData)
        setShowSelectedProductpopup(true);
        setsidebarwidth("70vw")
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('Category1').toLowerCase()) {

        let selectedValue = item.ValueListString;
        let selectedCat1 = [];

        if (selectedValue && selectedValue.length > 0) {
          for (let item of attribute1Values) {
            if (selectedValue.includes(item.Id.toString())) {
              item.Selected = true;
            }
            else {
              item.Selected = false;
            }
          }
        }
        setShowSelectedCategorypopup(true)
        setsidebarwidth("60vw");
        SetCountKeyValue(item.SelectedItemSubCategory)
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('zone') && (item.Category).toLowerCase() == 'customer') {

        let selectedValue = item.ValueListString;
        let selectedCat1 = [];

        if (selectedValue && selectedValue.length > 0) {
          for (let item of zoneOptions) {
            if (selectedValue.includes(item.value.toString())) {
              item.selected = true;
            }
            else {
              item.selected = false;
            }
          }
        }
        setShowSelectedZonepopup(true)
        setsidebarwidth("60vw");
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('state') && (item.Category).toLowerCase() == 'customer') {

        let selectedValue = item.ValueListString;
        let selectedCat1 = [];

        if (selectedValue && selectedValue.length > 0) {
          for (let item of stateOptions) {
            if (selectedValue.includes(item.value.toString())) {
              item.selected = true;
            }
            else {
              item.selected = false;
            }
          }
        }
        setShowSelectedStatepopup(true)
        setsidebarwidth("60vw");
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('station') && (item.Category).toLowerCase() == 'customer') {

        let selectedValue = item.ValueListString;
        let selectedCat1 = [];

        if (selectedValue && selectedValue.length > 0) {
          for (let item of stationOptions) {
            if (selectedValue.includes(item.value.toString())) {
              item.selected = true;
            }
            else {
              item.selected = false;
            }
          }
        }
        setShowSelectedStationpopup(true)
        setsidebarwidth("60vw");
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('stockist') && (item.Category).toLowerCase() == 'customer') {

        let selectedValue = item.ValueListString;
        let selectedCat1 = [];

        if (selectedValue && selectedValue.length > 0) {
          for (let item of stockistOptions) {
            if (selectedValue.includes(item.value.toString())) {
              item.selected = true;
            }
            else {
              item.selected = false;
            }
          }
        }
        setShowSelectedStockistpopup(true)
        setsidebarwidth("60vw");
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('Category2').toLowerCase()) {
        let selectedValue = item.ValueListString;
        let selectedCat1 = [];

        if (selectedValue && selectedValue.length > 0) {
          for (let item of attribute2Values) {
            if (selectedValue.includes(item.Id.toString())) {
              item.Selected = true;
            }
            else {
              item.Selected = false;
            }
          }
        }
        setShowSelectedCategorypopup(true)
        setsidebarwidth("60vw");
        SetCountKeyValue(item.SelectedItemSubCategory)
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('Category3').toLowerCase()) {
        let selectedValue = item.ValueListString;
        let selectedCat1 = [];

        if (selectedValue && selectedValue.length > 0) {
          for (let item of attribute3Values) {
            if (selectedValue.includes(item.Id.toString())) {
              item.Selected = true;
            }
            else {
              item.Selected = false;
            }
          }
        }
        setShowSelectedCategorypopup(true)
        setsidebarwidth("60vw");
        SetCountKeyValue(item.SelectedItemSubCategory)
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('Category4').toLowerCase()) {
        let selectedValue = item.ValueListString;
        let selectedCat1 = [];

        if (selectedValue && selectedValue.length > 0) {
          for (let item of attribute4Values) {
            if (selectedValue.includes(item.Id.toString())) {
              item.Selected = true;
            }
            else {
              item.Selected = false;
            }
          }
        }
        setShowSelectedCategorypopup(true)
        setsidebarwidth("60vw");
        SetCountKeyValue(item.SelectedItemSubCategory)
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('Category5').toLowerCase()) {
        let selectedValue = item.ValueListString;
        let selectedCat1 = [];

        if (selectedValue && selectedValue.length > 0) {
          for (let item of attribute5Values) {
            if (selectedValue.includes(item.Id.toString())) {
              item.Selected = true;
            }
            else {
              item.Selected = false;
            }
          }
        }
        setShowSelectedCategorypopup(true)
        setsidebarwidth("60vw");
        SetCountKeyValue(item.SelectedItemSubCategory)
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('Individual Customer').toLowerCase()) {
        let selectedValue = item.ValueListString;
        let selectedCat1 = [];

        if (selectedValue && selectedValue.length > 0) {
          for (let item of CustomerValues) {
            if (selectedValue.includes(item.Id.toString())) {
              item.Selected = true;
            }
            else {
              item.Selected = false;
            }
          }
        }
        setShowSelectedCustomerpopup(true)
        setsidebarwidth("60vw");
        clickSelectedShowCustomerPopup()
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('Customer Group Name').toLowerCase()) {
        let selectedValue = item.ValueListString;
        let selectedCat1 = [];

        if (selectedValue && selectedValue.length > 0) {
          for (let item of CustomerValues) {
            if (selectedValue.includes(item.Id.toString())) {
              item.Selected = true;
            }
            else {
              item.Selected = false;
            }
          }
        }
        setShowSelectedCustomerGrouppopup(true)
        setsidebarwidth("60vw");
        clickSelectedShowCustomerGroupPopup()
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('Label').toLowerCase() && (item.Category).toLowerCase() == ('Settlementfrequency').toLowerCase()) {
        setShowSelectedSettlFreq(true);
        clickShowSelectedSettlFreq()
        setsidebarwidth("70vw")
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('Label').toLowerCase() && (item.Category).toLowerCase() == ('Rebate Type').toLowerCase()) {
        let selectedValue = item.ValueListString;
        let selectedCat1 = [];

        if (selectedValue && selectedValue.length > 0) {
          for (let item of rebateTypValues) {
            if (selectedValue.includes(item.Id.toString())) {
              item.Selected = true;
            }
            else {
              item.Selected = false;
            }
          }
        }
        setShowSelectedRebateType(true);
        clickShowSelectedRebateType()
        setsidebarwidth("70vw")
      }

    }
    else {
      alert('Please select Category & SubCategory')
    }
  }

  const checkAllRegionchange = (mycheckbox) => {
    let Ids = [];
    var checkboxes = document.querySelectorAll("input[name = 'region-check']");
    if (mycheckbox.target.checked == true) {
      setRegioncheckAll(true);

      let olddata = [...SalesLevelData];
      olddata.map((i) => {
        if (i.avalRegion == true) {
          i.selected = true;
        }

      })
      setSalesLevelData(olddata);

    }
    else {
      setRegioncheckAll(false);


      let olddata = [...SalesLevelData];
      olddata.map((i) => {
        i.selected = false;
      })
      setSalesLevelData(olddata);
    }
  }

  const handleregionChanges = (e, label, id, flag) => {
    let selected = [];
    let updatedData = [];
    let olddata = [];
    let list = [...ExceptionValueList];

    let obj = [
      { name: 'Zone', data: zoneOptions, setter: setZoneOptions },
      { name: 'State', data: stateOptions, setter: setStateOptions },
      { name: 'Station', data: stationOptions, setter: setStationOptions },
      { name: 'Stockist', data: stockistOptions, setter: setStockistOptions },
    ];

    obj.forEach((each) => {
      if (flag === each.name) {
        olddata = [...each.data];
        updatedData = olddata.map((i) => {
          if (i.value === id) {
            i.selected = e.target.checked;
          }
          if (i.selected) {
            selected.push(i.value.toString());
          }
          return i;
        });

        each.setter(updatedData);
      }
    });

    list[itemNo]['ValueListString'] = selected;

  }

  const onClickOk = (popupOpen) => {
    if (popupOpen == 'category') {
      setCategoryPopup(false)
    }
    else if (popupOpen == 'subCategory') {
      setShowSubCategoryPopup(false)
    }
    else if (popupOpen == 'zone') {
      setShowZonePopup(false);
    }
    else if (popupOpen == 'state') {
      setShowStatePopup(false);
    }
    else if (popupOpen == 'station') {
      setShowStationPopup(false);
    }
    else if (popupOpen == 'stockist') {
      setShowStockistPopup(false);
    }
  }



  const onClickClose = (popupClose) => {
    if (popupClose == 'zone') {
      setShowZonePopup(false);
    }
    else if (popupClose == 'state') {
      setShowStatePopup(false);
    }
    else if (popupClose == 'station') {
      setShowStationPopup(false);
    }
    else if (popupClose == 'stockist') {
      setShowStockistPopup(false);
    }
  }

  const onClickCloseSelectedPopup = (popupClose) => {
    if (popupClose == 'zone') {
      setShowSelectedZonepopup(false);
    }
    else if (popupClose == 'state') {
      setShowSelectedStatepopup(false);
    }
    else if (popupClose == 'station') {
      setShowSelectedStationpopup(false);
    }
    else if (popupClose == 'stockist') {
      setShowSelectedStockistpopup(false);
    }
  }


  const clickSelectedShowPopup = (val) => {
    if (val == 'zone') {
      setShowZonePopup(false);
      setShowSelectedZonepopup(true);
    }
    else if (val == 'state') {
      setShowStatePopup(false);
      setShowSelectedStatepopup(true);
    }
    else if (val == 'station') {
      setShowStationPopup(false);
      setShowSelectedStationpopup(true);
    }
    else if (val == 'stockist') {
      setShowStockistPopup(false);
      setShowSelectedStockistpopup(true);
    }
  }
  const ClickValuePopupClose = (flag, value, popup) => {
    if (flag == 'Close' && value == 'Product' && popup == 'All') {
      setShowProductPopup(false);
      setShowModal(false)
    }
    if (flag == 'Close' && value == 'Product' && popup == 'Selected') {
      setShowSelectedProductpopup(false);
      setShowModal(false)
    }
    if (flag == 'Close' && value == 'Category' && popup == 'All') {
      setshowCategoryPopup(false);
      SetKeyValue("")
    }
    if (flag == 'Close' && value == 'Category' && popup == 'Selected') {
      setShowSelectedCategorypopup(false);
      SetCountKeyValue("")
    }
    if (flag == 'Close' && value == 'SubCategory' && popup == 'All') {
      setshowCategoryPopup(false);
      SetKeyValue("")
    }
    if (flag == 'Close' && value == 'SubCategory' && popup == 'Selected') {
      setShowSelectedCategorypopup(false);
      SetCountKeyValue("")
    }

    if (flag == 'Close' && value == 'Customer' && popup == 'All') {
      setshowCustomerPopup(false);
    }
    if (flag == 'Close' && value == 'Customer' && popup == 'Selected') {
      setShowSelectedCustomerpopup(false);
    }
    if (flag == 'Close' && value == 'CustomerGroup' && popup == 'All') {
      setshowCustomerGroupPopup(false);
    }
    if (flag == 'Close' && value == 'GroupCustomer' && popup == 'Selected') {
      setShowSelectedCustomerGrouppopup(false);
    }
    if (flag == 'Close' && value == 'SettlementFreq' && popup == 'All') {
      setshowSetlFreqPopup(false);
    }
    if (flag == 'Close' && value == 'SettlementFreq' && popup == 'Selected') {
      setShowSelectedSettlFreq(false);
    }
    if (flag == 'Close' && value == 'RebateType' && popup == 'All') {
      setshowRebateTypePopup(false);
    }
    if (flag == 'Close' && value == 'RebateType' && popup == 'Selected') {
      setShowSelectedRebateType(false);
    }
  }

  const ClickValuePopupCancel = () => {
    setshowCategoryPopup(false);
  }

  const clickShowWorkflow = (val, id) => {
    setsidebarwidth("70vw");
    if (val === "Workflow")
      setshowExceptionWorkflow(true);
    setshowWorkflow(false);
  }
  const _ = require('lodash');



  const getExceptionWorkflowApprovers = (method, id) => {
    Service.getdatabyId(method, id).then((res) => {

      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          setWorkflowApprovers(res.data)
          setWorkflowLevelCount(res.data.length)
          const sortdata = _.orderBy(res.data, ['Workflowlevel'], ['asc'])
          setworkflowchart(sortdata);

        }

      }

    });
  }

  const getExceptionWorkflowDetails = (id, pagesize, pagenumber) => {
    Service.getdatabyId('MaterialPricingPolicy/GetMaterialPricingWorkflowById', id, pagesize, pagenumber).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data.DataList !== 'null' && res.data.DataList !== 'undefined') {

              wData = res.data.DataList;
              setWorkflowData(wData);
              setshowspinnerworkflow(false)
              setWoData('get data');
            }
          }
        }
      }
    });

  }



  const changeIcon = (e, id) => {
    setExpandAccruals(id)

    if (icon == 'fa fa-plus') {
      setIcon('fa fa-minus');
    }
    if (icon == 'fa fa-minus') {
      setIcon('fa fa-plus');
    }

  }


  const ClickValuePopup = (item, index) => {

    setItemNo(index);
    if (item.SelectedItemSubCategory != null && item.SelectedItemSubCategory != '' && item.SelectedItemSubCategory != undefined) {
      if ((item.SelectedItemSubCategory).toLowerCase() == ('Label').toLowerCase() && item.Category == 'Product') {
        if (props.id != 0) {
          let selectedData = []
          let productData = [...ProductValues]
          let filteredData = []

          let selectedValue = item.ValueListString;
          if (selectedValue != null && selectedValue.length > 0) {
            selectedValue.forEach((selectedpro, index) => {
              ProductValues.forEach((eachpro) => {
                if (selectedpro == eachpro.Id) {
                  eachpro.Selected = true
                  let z = `${eachpro.Label}${"_"}${eachpro.Id}`
                  filteredData.push(z)
                }
              })
            })
          }
          setdualListBoxOptionsByAttribute(productData)
          SetFilteredProducts(filteredData)
        }

        setdualListBoxOptionsByAttribute(ProductValues)
        setclickProduct(true)
        setShowModal(true)
        setSelectedType('Attribute')
        setShowProductPopup(true);
        setsidebarwidth("70vw")
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('label') && item.Category == 'Category') {
        if (props.id != 0) {
          let selectedValue = item.ValueListString;
          let selectedCat1 = [];

          if (selectedValue && selectedValue.length > 0) {
            for (let item of AllCategoryValues) {
              if (selectedValue.includes(item.value.toString())) {
                item.selected = true;
              }
              else {
                item.selected = false;
              }
            }
          }
        }
        SetKeyValue('Category')
        setshowCategoryPopup(true)
        setsidebarwidth("60vw");
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('label') && item.Category.toLowerCase() == 'subcategory') {
        if (props.id != 0) {
          let selectedValue = item.ValueListString;
          let selectedCat1 = [];

          if (selectedValue && selectedValue.length > 0) {
            for (let item of AllSubCategoryValues) {
              if (selectedValue.includes(item.value.toString())) {
                item.selected = true;
              }
              else {
                item.selected = false;
              }
            }
          }
        }
        SetKeyValue('SubCategory')
        setshowCategoryPopup(true)
        setsidebarwidth("60vw");
      }

      else if ((item.SelectedItemSubCategory).toLowerCase() == ('Individual Customer').toLowerCase()) {
        if (props.id != 0) {
          let selectedValue = item.ValueListString;
          let selectedCat1 = [];

          if (selectedValue && selectedValue.length > 0) {
            for (let item of CustomerValues) {
              if (selectedValue.includes(item.Id.toString())) {
                item.Selected = true;
              }
              else {
                item.Selected = false;
              }
            }
          }
        }
        setshowCustomerPopup(true)
        setsidebarwidth("60vw");
      }

      else if ((item.SelectedItemSubCategory).toLowerCase() == ('Customer Group').toLowerCase()) {
        if (props.id != 0) {
          let selectedValue = item.ValueListString;
          let selectedCat1 = [];

          if (selectedValue && selectedValue.length > 0) {
            for (let item of CustomerValues) {
              if (selectedValue.includes(item.Id.toString())) {
                item.Selected = true;
              }
              else {
                item.Selected = false;
              }
            }
          }
        }
        setshowCustomerGroupPopup(true)
        setsidebarwidth("60vw");
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('Label').toLowerCase() && (item.Category).toLowerCase() == ("settlementfrequency").toLowerCase()) {
        if (props.id != 0) {
          let selectedValue = item.ValueListString;
          let selectedCat1 = [];

          if (selectedValue && selectedValue.length > 0) {
            for (let item of settlFreqValues) {
              if (selectedValue.includes(item.Id.toString())) {
                item.Selected = true;
              }
              else {
                item.Selected = false;
              }
            }
          }
        }
        setshowSetlFreqPopup(true);
        setsidebarwidth("70vw")
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('zone') && (item.Category).toLowerCase() == ("customer")) {
        if (props.id != 0) {
          let selectedValue = item.ValueListString;
          let selectedCat1 = [];

          if (selectedValue && selectedValue.length > 0) {
            for (let item of zoneOptions) {
              if (selectedValue.includes(item.value.toString())) {
                item.selected = true;
              }
              else {
                item.selected = false;
              }
            }
          }
        }
        setShowZonePopup(true);
        setsidebarwidth("70vw")
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('station') && (item.Category).toLowerCase() == ("customer")) {
        if (props.id != 0) {
          let selectedValue = item.ValueListString;
          let selectedCat1 = [];

          if (selectedValue && selectedValue.length > 0) {
            for (let item of stationOptions) {
              if (selectedValue.includes(item.value.toString())) {
                item.selected = true;
              }
              else {
                item.selected = false;
              }
            }
          }
        }
        setShowStationPopup(true);
        setsidebarwidth("70vw")
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('state') && (item.Category).toLowerCase() == ("customer")) {
        if (props.id != 0) {
          let selectedValue = item.ValueListString;
          let selectedCat1 = [];

          if (selectedValue && selectedValue.length > 0) {
            for (let item of stateOptions) {
              if (selectedValue.includes(item.value.toString())) {
                item.selected = true;
              }
              else {
                item.selected = false;
              }
            }
          }
        }
        setShowStatePopup(true);
        setsidebarwidth("70vw")
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('stockist') && (item.Category).toLowerCase() == ("customer")) {
        if (props.id != 0) {
          let selectedValue = item.ValueListString;
          let selectedCat1 = [];

          if (selectedValue && selectedValue.length > 0) {
            for (let item of stockistOptions) {
              if (selectedValue.includes(item.value.toString())) {
                item.selected = true;
              }
              else {
                item.selected = false;
              }
            }
          }
        }
        setShowStockistPopup(true);
        setsidebarwidth("70vw")
      }
      else if ((item.SelectedItemSubCategory).toLowerCase() == ('Label').toLowerCase() && (item.Category).toLowerCase() == ('Rebate Type').toLowerCase()) {
        if (props.id != 0) {
          let selectedValue = item.ValueListString;
          let selectedCat1 = [];

          if (selectedValue && selectedValue.length > 0) {
            for (let item of rebateTypValues) {
              if (selectedValue.includes(item.Id.toString())) {
                item.Selected = true;
              }
              else {
                item.Selected = false;
              }
            }
          }
        }
        setshowRebateTypePopup(true);
        setsidebarwidth("70vw")
      }
    }
    else if (item.Category == '') {
      let idx = itemNo + 1
      setShowErrorModal(true)
      setErrorMessage(`${"Please select Category in row "} ${index + 1} `)
      // alert("Please select Category in row " + idx)
    }
    else if (item.SelectedItemSubCategory == '') {
      let idx = itemNo + 1
      setShowErrorModal(true)
      setErrorMessage(`${"Please select SubCategory in row "} ${index + 1} `)
      // alert("Please select SubCategory in row " + idx)
    }
    else if (item.Operator == '') {
      let idx = itemNo + 1
      setShowErrorModal(true)
      setErrorMessage(`${"Please select Operator in row "} ${index + 1} `)
      // alert("Please select Operator in row " + idx)
    }

  }
  const getSegmentByFiscalYear = (year) => {
    Service.getSegementDetailByFiscalYear('Categoryminimumachievementpolicy/GetSegmentDetailByFiscalYear', year).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            console.log(res.data, "Segment datass")
          }
        }
      }
    });

  }


  const OnNameChange = (e) => {

    let value = e.target.value;
    setExceptionPolicyData({ ...exceptionPolicyData, ['PolicyName']: value })

    console.log(MPGList, "MPG List")
    console.log(selectedMPG, "Selected MPG")
    console.log(selectedParts, "Selcted Parts")
    console.log(loadPopup, "loadpopup")
    console.log(PartHashList, "Part Hashlist")
    console.log(ProductValues, "Product Attribute")
    getSegmentByFiscalYear("FY 2024-2025")
  }

  const validateField = () => {
    let isValid = true;
    // Move Fiscal Year validation to the top to prioritize it
    if (selectedOptionsDateRange == "" || selectedOptionsDateRange == null) {
      setErrorMessage("Please select Fiscal Year in the header")
      isValid = false;
    } else if (selectedGroupBy === '' || selectedGroupBy === null) {
      setErrorMessage("Please select group by");
      isValid = false;
    }
    if (selectedGroupBy) {
      console.log("19-2", MPGList, MPGList[0])
      if (selectedGroupBy === "By MPG") {
        MPGList.forEach((each,index) => {
        if (each.segmentId === '' || each.segmentId === null || each.segmentId === 0) {
          if(MPGList.length === 1){
            setErrorMessage("Please select Segment by MPG");
          }else{
            setErrorMessage(`Please select Segment by MPG in row ${index+1} of Segment`);
          }
          isValid = false;
        } else if (each.subcategory.length === 0) {
          if(MPGList.length === 1){
          setErrorMessage("Please select Material Pricing Group by MPG");
          }else{
          setErrorMessage(`Please select Material Pricing Group by MPG in row ${index+1}`);
          }
          isValid = false;
        } 
        else if(each.rebate === '' || each.rebate === null){
          if(MPGList.length === 1){
            setErrorMessage("Please enter Rebate Value (%)");
          }else{
            setErrorMessage(`Please enter Rebate Value (%) in row ${index+1}`);
          }
          isValid = false;
        }
        else if (MPGList.length > 0 && each.attributes && each.attributes.length > 0) {
          each.attributes.forEach((item) => {
            if((item.name !== '' || item.name !== 'Select') && item.id !== 0){
              if(item.value.length === 0){
                setErrorMessage(`Please select Value for ${item.name} Attribute`);
              isValid = false;
            }
          }
          })
        }
      }) 
      } else {
        PartHashList.forEach((each,index)=>{
        if (each.segmentId === '' || each.segmentId === null || each.segmentId === 0) {
          if(PartHashList.length === 1){
            setErrorMessage("Please select Segment by Part");
          }else{
            setErrorMessage(`Please select Segment by Part in row ${index+1}`);
          }
          isValid = false;
        } else if (each.values.length === 0) {
          if(PartHashList.length === 1){
            setErrorMessage("Please select part#");
          }else{
            setErrorMessage(`Please select part# in row ${index+1}`);
          }
          isValid = false;
        } else if (each.rebate === null || PartHashList[0].rebate === '') {
          if(PartHashList.length === 1){
          setErrorMessage("Please enter Rebate Value by Part");
          }else{
            setErrorMessage(`Please enter Rebate Value by Part in row ${index+1}`);
          }
          isValid = false;
        }
      })
      }
    }
    // Only check ExceptionValueList if isValid is still true  selectedGroupBy === "By MPG"
    if (isValid) {
      ExceptionValueList.forEach((item, index) => {
        if (item.Category !== '' && item.Category !== null) {
          if (item.SelectedItemSubCategory === "" || item.SelectedItemSubCategory === null || item.SelectedItemSubCategory === "Select") {
            setErrorMessage("Please select Sub-Category for all rows");
            isValid = false;
          } else if (item.ValueListString.length === 0 && !['ECC', 'SubCategory', 'Category', 'Vehicletype'].includes(item.SelectedItemSubCategory)) {
            setErrorMessage("Please select Values for all Rows");
            isValid = false;
          }
        }
      });
    }
    return isValid;
  };




  const SaveHandler = (e, action) => {


    let isValid = validateField()

    console.log('6/3', ExceptionValueList)
    console.log('6/3', exceptionValues)


    if (isValid == true) {
      let inputData = []
      let mpgdata = MPGList.map(item => ({
        Id: item.id,
        PolicyId: exceptionPolicyData.PolicyId,
        PolicyName: exceptionPolicyData.PolicyName,
        FiscalYear: selectedOptionsDateRange,
        LastModifiedUserId: loggedInUser,
        CreatedUserId: loggedInUser,
        CategorySubcategoryData: jsonList,
        Validity_From: exceptionPolicyData.Validity_From,
        Validity_To: exceptionPolicyData.Validity_To,
        SegmentId: item.segmentId,
        SegmentName: "",
        MPGListString: item.subcategory.map(sub => sub.toString()),
        ValueListString: item.attributes.map(attr => attr.value.toString()),
        RebateValue: item.rebate,
        Attributes: item.attributes.map(attr => ({
          id: attr.id,
          name: attr.name || "",
          ValueListString: attr.value.map(val => val.toString()),
        })),
      }))

      let partdata = PartHashList.map(item => ({
        Id: item.id,
        PolicyId: exceptionPolicyData.PolicyId,
        PolicyName: exceptionPolicyData.PolicyName,
        FiscalYear: selectedOptionsDateRange,
        LastModifiedUserId: loggedInUser,
        CreatedUserId: loggedInUser,
        CategorySubcategoryData: jsonList,
        Validity_From: exceptionPolicyData.Validity_From,
        Validity_To: exceptionPolicyData.Validity_To,
        SegmentId: item.segmentId,
        SegmentName: "",
        ValueListString: item.values.map(attr => attr?.toString()),
        RebateValue: item.rebate,
      }))

      inputData = {
        Id: exceptionPolicyData.Id,
        PolicyId: exceptionPolicyData.PolicyId,
        PolicyName: exceptionPolicyData.PolicyName,
        FiscalYear: selectedOptionsDateRange,
        GroupBy: selectedGroupBy,
        LastModifiedUserId: loggedInUser,
        CreatedUserId: loggedInUser,
        CategorySubcategoryData: jsonList,
        Validity_From: exceptionPolicyData.Validity_From,
        Validity_To: exceptionPolicyData.Validity_To,
        MaterialPricingPolicyListByMPG: mpgdata,
        MaterialPricingPolicyListByPart: partdata,
        MaterialPricingValueList: ExceptionValueList,
        SettlementfrequencyId:exceptionPolicyData.SettlementfrequencyId,
        IncludeWorkflow:exceptionPolicyData.IncludeWorkflow,
      }

      console.log("25-9", mpgdata);

      console.log("13/2 19-2", inputData)


      Service.createorupdate('MaterialPricingPolicy/CreateOrUpdate', inputData).then((res) => {
        if (typeof res !== 'undefined') {
          if (res.data !== 'null' && typeof res.data !== 'undefined') {
            let rowData = res.data
            let id = parseInt(res.data.Id)
            if (action != "Submit") {
              toast.success('Saved Successfully !', {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
            if (action == 'SaveClose') {
              Service.LockUnLockEntity('Configurationprop/UnLockEntity', "SegmentMappingPolicy", id, loggedInUser).then((res) => {
              });
              navigate("/SegmentMapping-Policy/List");
            }
            else {
              navigate("/SegmentMappingPolicyList/edit/" + id + "/fromPolicy");
              getExceptionPolicyById("MaterialPricingPolicy/GetById", id)
            }
          }
        }
      });
    }
    else {
      setErrorStatus("Error")
      setShowErrorModal(true);
      return false;
    }

  }

  const getExceptionPolicyById = (method, id) => {
    let apiData = []
    Service.getdatabyId(method, id).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          // debugger;
          if (res.status === 200) {
            if (typeof res.data !== 'undefined' && res.data !== 'null') {
              let rData = res.data[0];
              console.log("14-2", rData)
              setExceptionPolicyData({
                Id: rData.Id,
                PolicyId: rData.PolicyId,
                PolicyName: rData.PolicyName,
                Validity_From: moment(rData.Validity_From).toDate(), //rData.StartDate,
                Validity_To: moment(rData.Validity_To).toDate(), //rData.EndDate
                LastModifiedUserId: rData.LastModifiedUserId,
                CreatedUserId: rData.CreatedUserId,
                StatusLabel: rData.StatusLabel,
                CreatedUser: rData.CreatedUser,
                CreatedDate: moment(rData.CreatedDate).format(SessionManage.getcurrentdateformat()),
                IncludeWorkflow : rData.IncludeWorkflow
              })
              setRebatePayoutCond(rData.RebatePayoutCond)
              setSelectedOptionsSetlFreq(rData.SettlementfrequencyId);
              const matchedOption = includeworkglowoptions.find(
                (option) => option.value === rData.IncludeWorkflow
              );
              setSelectedOptionIW(matchedOption || null);
              let setid = rData.SettlementfrequencyId 
                Service.getAlldata("settlementfrequency").then((res) => {
                  if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                      if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                          res.data.forEach((each) => {
                            each['Selected'] = false;
                          })
                          console.log(setid,res.data,"14-2 ",res.data.find(x => x.Id === setid)?.label)
                          setSelectedSettlementFrequency(res.data.find(x => x.Id === setid)?.label)
                          setSettlFreqValues(res.data)
                        }
                      }
                    }
                  }
                });

              if ((rData.Locked == true && rData.LockedById.toString() !== loggedInUser)) {
                $("#libread").html(rData.PolicyId + " " + `Locked By ${rData.LockedByUserName}`);
              }
              else {
                $("#libread").html(rData.PolicyId);
              }

              setselectedOptionsDateRange(rData.FiscalYear);

              const mpglist = rData?.MaterialPricingPolicyListByMPG?.map(item => ({
                id: item.Id,
                segmentId: item.SegmentId,
                subcategory: item.MPGListString.map(value => Number(value)),
                attributes: item.Attributes.map(attr => ({
                  name: attr.name,
                  id: attr.id,
                  value: attr.ValueListString.map(val => Number(val))
                })),
                rebate: item.RebateValue,
              }));

              const partlist = rData?.MaterialPricingPolicyListByPart?.map(item => ({
                id: item.Id,
                segmentId: item.SegmentId,
                values: item.ValueListString,
                rebate: item.RebateValue,
              }));


              console.log('25-9', mpglist);

              SetMPGList(mpglist)
              SetPartHashList(partlist)

              if (((props.name == 'fromApproval' || props.name == 'fromapprovalcompleted') && rData.StatusLabel == 'InReview' && rData.Locked == false) || (rData.StatusLabel == 'New' && rData.Locked == false)) {
                Service.LockUnLockEntity('Configurationprop/LockEntity', "SegmentMappingPolicy", id, loggedInUser).then((res1) => {
                  if (typeof res1 !== 'undefined') {
                    setLockedBy(loggedInUser);
                    setIsLocked(true);
                  }
                });
              }
              else {
                setLockedBy(rData.LockedById);
                setIsLocked(rData.Locked);
              }

              setExceptionValueList(rData.MaterialPricingValueList)
              setSelectedGroupby(rData.GroupBy)
              setDropDownValues(rData.dropDownData)
              setaddCategory(true);
              let CatData = [];
              let SubCatData = [];
              rData.dropDownData.map((i) => {
                const data = {
                  id: i.Id,
                  Id: i.Id,
                  Name: i.Category,
                  label: i.Category,
                  value: i.Category,
                }

                CatData.push(data);
                i.SubCategory.map((s) => {
                  const subdata = {
                    id: s.Id,
                    Id: s.value,
                    Name: s.label,
                    label: s.label,
                    value: s.value,
                  }
                  SubCatData.push(subdata);
                })

              });

              setCategoryValues(CatData);
              setSubCategoryValues(SubCatData);

              setUpdatedCategoryValues(list => ([...CatData]));
              catValues = CatData;

              setModifiedLst(rData.dropDownData)

              setsubdivideList(rData.subdivideMinThresholdValue);
              if (rData.subdivideMinThresholdValue != null) {
                if (rData.subdivideMinThresholdValue.Period != null) {
                  setSubdividefield(true)
                  setShowTargetTable(true)
                  setShowTargetSelection(rData.SubDivideTargetType)
                  if (rData.ExceptionValueList.length > 0) {
                    setaddCategory(true);
                  } else {
                    setShowAddButton(true)
                  }
                }
              }

              if (rData.StatusLabel == 'New') {
                setrebatelinestatus('New')
              }
              else {
                setrebatelinestatus('')
                setShowAddButton(false)
              }
              if (rData.StatusLabel == 'New') {
                insertWorkflowDetails(id, loggedInUser)
              }
              setTimeout(() => {
                getExceptionWorkflowDetails(id, paging.pageSize, paging.pageNumber)
                getExceptionWorkflowApprovers('MaterialPricingPolicy/GetMaterialPricingWorkflowApprovers', id);
              }, 500);
            }
          }
        }
      }
    });
  }
  console.log('6/3 ExceptionValueList', ExceptionValueList)
  const updatedropdownValues = () => {

    ExceptionValueList.map((each, index) => {
      dropDownValues.map((val) => {
        each.SubCategory.map((sub) => {
          if (each.SelectedItemSubCategory == sub.label) {

            sub.dropdownState.selected = true
            sub.dropdownState.row = index + 1
          }
        })
      })
    })
  }

  console.log("13/2d", dropDownValues)
  console.log("13/2c", CategoryValues)
  console.log("13/2s", SubCategoryValues)
  console.log("13/2e", ExceptionValueList)

  const GetDateRangeAndProducts = (method) => {
    Service.getdata(method).then((res) => {
      if (Service.checkUndefined(res) === true) {
        if (Service.checkDataUndefined(res.data) == true) {
          setDateRange(res.data.lstDateRange);
        }
      }
    });
  };

  const OnChangeIncludeWorkflow = (selected) => {
    console.log(selected,"19-2 n")
    setSelectedOptionIW(selected);
    setExceptionPolicyData((prevData) => ({
      ...prevData,
      IncludeWorkflow: selected.value,
    }));
  }

  const insertWorkflowDetails = (id, userId) => {
    Service.workflowDataExcep('MaterialPricingPolicy/insertworkflow', id, userId).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {

          if (res.status == 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {

            }
          }
        }
      }
    })
  }



  const attributeSearch = (event) => {

    let names = [
      { key: "Category1", data: attribute1ValuesHold, setter: setAttribute1Values },
      { key: "Category2", data: attribute2ValuesHold, setter: setAttribute2Values },
      { key: "Category3", data: attribute3ValuesHold, setter: setAttribute3Values },
      { key: "Category4", data: attribute4ValuesHold, setter: setAttribute4Values },
      { key: "Category5", data: attribute5ValuesHold, setter: setAttribute5Values }
    ]
    let list = []

    names.forEach((each) => {
      if (each.key == keyValue) {
        list = each.data
        if (event.target.value != '') {
          let searchResults = list.filter((each) => {

            return each.Name.toLowerCase().includes(String(event.target.value).toLowerCase())
          });
          each.setter(searchResults)
        }
        else {
          each.setter(each.data)
        }
      }
    })
  }

  const checkAllAttribute = (mycheckbox) => {
    let names = [
      { key: "Cat-I", data: attribute1Values, setter: setAttribute1Values, cat: 'lstcat1' },
      { key: "Cat-II", data: attribute2Values, setter: setAttribute2Values, cat: 'lstcat2' },
      { key: "Cat-III", data: attribute3Values, setter: setAttribute3Values, cat: 'lstcat3' },
      { key: "Cat-IV", data: attribute4Values, setter: setAttribute4Values, cat: 'lstcat4' },
      { key: "Cat-V", data: attribute5Values, setter: setAttribute5Values, cat: 'lstcat5' },
    ]
    let list = []
    names.forEach((each) => {
      if (each.key == keyValue) {
        list = each.data

        var checkboxes = document.querySelectorAll("input[name = 'attribute-check']");
        if (mycheckbox.target.checked == true) {
          setattbCheckAll(true);

        }
        else {
          setattbCheckAll(false);

        }
      }
    })
  }


  const OnChangeHandlerAttribute = (e, label, id, flag) => {
    let names = [
      { key: "Category", data: AllCategoryValues, setter: setAllCategoryValues, cat: 'lstcat' },
      { key: "SubCategory", data: AllSubCategoryValues, setter: setAllSubcategoryValues, cat: 'lstsubcat' },
    ]

    let temp = []
    let ids = [];
    names.forEach((each) => {  //iterating names array
      if (each.key == flag) {
        each.data.forEach((cat) => {  // iterating attribute data
          if (cat.value === id) {
            e.target.checked ? cat.selected = true : cat.selected = false

          }
        })
        temp = each //storing selected obj in var
      }
    })
    switch (flag) {
      case "Category":
        setAllCategoryValues(temp.data)
        ids = formatcat(temp.data)
        break;

      case "SubCategory":
        setAllSubcategoryValues(temp.data)
        ids = formatcat(temp.data)
        break;

    }
    const list = [...ExceptionValueList];
    const list2 = [...exceptionValues];
    console.log("6/2i", ids)
    list[itemNo]['ValueListString'] = ids;
    list2[itemNo]['ValueListString'] = ids;
    setExceptionValueList(list);
    setExceptionValues(list2);
  }

  const formatcat = (data) => {
    let temp = []
    data.forEach((i) => {
      if (i.selected == true) {
        temp.push(i.value.toString())
      }
    })

    return temp
  }

  const formatAttributes = (data) => {
    let temp = []
    data.forEach((i) => {
      if (i.Selected == true) {
        temp.push(i.Id.toString())
      }
    })

    return temp
  }



  const okSelectedCustomer = () => {


  }
  const okCustomer = () => {
    setshowCustomerPopup(false);
    let ids = [];
    ids = formatAttributes(CustomerValues);
    const list = [...ExceptionValueList];
    const list2 = [...exceptionValues];

    list[itemNo]['ValueListString'] = ids;

    setExceptionValueList(list);
    setExceptionValues(list2);


  }



  const FilterColumnCustomer = (name) => {
    var divtop1 = document.getElementById("Customer");
    var cardtop1 = document.getElementById("Customer");
    setdivtop(divtop1.offsetTop + cardtop1.offsetTop + 30)
    setCustomerFilterCol(name)
    setshowCustomerfilterpopup(true)

    if (name === 'Name') {
      setfiltertextCust("Customer Code")
      var testDiv = document.getElementById("Name");
      setdivleft((testDiv.offsetLeft + 30) + "px");
    }
    if (name === 'Label') {
      setfiltertextCust("Customer Name")
      var testDiv = document.getElementById("Label");
      setdivleft((testDiv.offsetLeft + 30) + "px");
    }

    if (name === 'Station') {
      setfiltertextCust("Station")
      var testDiv = document.getElementById("Station");
      setdivleft((testDiv.offsetLeft + 30) + "px");

    }
    if (name === 'State') {
      setfiltertextCust("State")
      var testDiv = document.getElementById("State");
      setdivleft((testDiv.offsetLeft + 30) + "px");

    }
    if (name === 'Zone') {
      setfiltertextCust("Zone")
      var testDiv = document.getElementById("Zone");
      setdivleft(((testDiv.offsetLeft + 30) - 100) + "px");
    }
    DropdownHandlerCus(name);
  }
  const DropdownHandlerCus = (key) => {

    Service.getfilterDropdownByGroup('Rebate/GetAllCustomerFilters', key).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.data !== 'null' && res.data !== 'undefined') {

            addnewfieldCustomer(res.data, key)
          }
        }
      }

    });

  }
  const addnewfieldCustomer = (data, key) => {

    let currentdata = data.map((i) => {
      i.Selected = false;
      return i;
    });
    let selectedKeys = filterValueCustomer[key];

    if (selectedKeys != '') {

      currentdata.forEach((each) => {
        selectedKeys.forEach((eachSelected) => {
          if (each.Name == eachSelected.Name) {
            each.Selected = true
          }
        })
      })
    }
    setFilterCusDropDownData(currentdata);
    setfilterCustData(currentdata)
  }


  const OnCusdropdownChange = (e, id, name) => {

    let isChecked = e.target.checked;
    var lastfilter = [...filterCusDropDownData]
    if (isChecked === true) {
      let currentdata = lastfilter.map((i) => {
        if (i.Name == name) {
          i.Selected = true;
        }
        return i;
      });
      setFilterCusDropDownData(currentdata);
      let data1 = []
      const getallData = {
        Name: name,
      }
      data1.push(getallData)
      setFilterValueCust(filterValueCust => [...filterValueCust, ...data1]);
      let updateddata = ([...filterValueCust, ...data1]);
      setFilterValueCustomer(() => ({
        ...filterValueCustomer, [custFilterCol]: updateddata,
      }));
    }
    else {
      let currentdata = lastfilter.map((i) => {
        if (i.Id === id) {
          i.Selected = false;
        }
        return i;
      });

      setFilterCusDropDownData(currentdata);
      setFilterValueCust(filterValueCust => filterValueCust.filter(data => data.Name !== name))
      let data1 = filterValueCust.filter(data => data.Name !== name);
      setFilterValueCustomer(() => ({
        ...filterValueCustomer, [custFilterCol]: data1,
      }));
    }
  }

  const UserfilterHandlerCustomer = (e) => {

    setFilterSearchCust(e.target.value)
    if (e.target.value !== "") {
      let searchResults = filterCusDropDownData.filter((each) => {
        return each.Name.toLowerCase().includes(String(e.target.value).toLowerCase())
      });
      setFilterCusDropDownData(searchResults);
    }
    else {

      setFilterSearchCust("")
      setFilterCusDropDownData(filtercustData)
    }
  }

  const checkAllFilterCustomer = (mycheckbox) => {
    var lastfilter = [...filterCusDropDownData]
    let data1 = []
    var checkboxes = document.querySelectorAll("input[name = 'filter-option-all']");
    if (mycheckbox.target.checked == true) {

      let currentdata = lastfilter.map((i) => {
        i.Selected = true;
        return i;
      });

      setFilterCusDropDownData(currentdata)
      filterCusDropDownData.map((each) => {

        const getallData = {
          Name: each.Name,
        }
        data1.push(getallData)
      })
      setFilterValueCust(filterValueCust => [...filterValueCust, ...data1]);
      let updateddata = ([...filterValueCust, ...data1]);
      setFilterValueCustomer(() => ({
        ...filterValueCustomer, [custFilterCol]: updateddata,
      }));
    }
    else {

      let currentdata = lastfilter.map((i) => {
        i.Selected = false;
        return i;
      });
      setFilterValueCust([])
      setFilterValueCustomer([])

    }

  }

  const CustFilterHandler = (flag) => {
    let list = [];

    var selectedCus = data;
    for (var i = 0; i < CustomerValues.length; i++) {
      if (CustomerValues[i].Selected == true) {
        for (var k = 0; k < selectedCus.length; k++) {
          if (CustomerValues[i].Id === selectedCus[k].Id) {
            selectedCus[k].Selected = true;
          }
        }
      }
    }
    setData(selectedCus);
    setshowCustomerfilterpopup(false)
    if (flag === 'Apply') {

      for (var key in filterValueCustomer) {
        if (filterValueCustomer[key].length > 0) {
          let values = ""
          filterValueCustomer[key].map((i) => {
            values += i.Name + ";";
          })
          if (values !== null && values !== '') {
            list.push({
              filterKey: key,
              FilterValue: values
            })
          }
        }
      }

      Service.getdataWithFilters('rebate/filterCustomerAll', customerData, list).then((res) => {
        if (typeof res !== 'undefined') {
          if (res.data !== 'null' && typeof res.data !== 'undefined') {
            setCustomerValues(res.data.DataList)
          }
        }
      });

    }
    else {
      setshowCustomerfilterpopup(false)
      setFilterValueCustomer(() => ({
        ...filterValueCustomer, [custFilterCol]: '',
      }));

      for (var key in filterValueCustomer) {
        if (key !== custFilterCol && filterValueCustomer[key].length > 0) {
          let values = ""
          filterValueCustomer[key].map((i) => {
            values += i.Name + ";";
          })
          if (values !== null && values !== '') {
            list.push({
              filterKey: key,
              FilterValue: values
            })
          }
        }
      }

      Service.getdataWithFilters('rebate/filterCustomerAll', customerData, list).then((res) => {
        if (typeof res !== 'undefined') {
          if (res.data !== 'null' && typeof res.data !== 'undefined') {
            setCustomerValues(res.data.DataList)
          }
        }
      });
      setFilterValueCust([])
      setfilterCustData([])
      setFilterCusDropDownData([])

      setFilterSearchCust('')
    }

  }
  const SearchCustomer = (event) => {
    var list = []
    let lstSelected = CustomerValues.filter((e) => e.Selected === true).map(item => {
      return { ...item }
    })
    setCustomerSearch(event.target.value)
    if (customerDataFilter.length === 0) {
      list = CustomerValues;

      setCustomerDataFilter(list);
    }
    else {
      list = customerDataFilter
    }
    if (event.target.value != '') {
      let searchResults = list.filter((each) => {

        return each.label.toLowerCase().includes(String(event.target.value).toLowerCase()) ||
          each.Name.toLowerCase().includes(String(event.target.value).toLowerCase()) ||
          each.Station.toLowerCase().includes(String(event.target.value.toLowerCase())) ||
          each.State.toLowerCase().includes(String(event.target.value.toLowerCase())) ||
          each.Zone.toLowerCase().includes(String(event.target.value.toLowerCase()))

      });

      let newlist = [...searchResults, ...lstSelected];
      const uniquelst = [];
      newlist.map((item) => {
        var findItem = uniquelst.find((x) => x.Id === item.Id);
        if (!findItem) uniquelst.push(item);
      });

      setCustomerValues(uniquelst)


    }
    else {
      setCustomerValues(list)
    }
  }

  const checkAllCustomer = (mycheckbox) => {

    let Ids = [];
    var checkboxes = document.querySelectorAll("input[name = 'customer-check']");
    if (mycheckbox.target.checked == true) {
      setCustomercheckAll(true);
      checkboxes.forEach(function (checkbox) {
        checkbox.checked = true;
      });
      let olddata = [...CustomerValues];
      olddata.map((i) => {
        i.Selected = true;
        Ids.push(i.Id);
      })


    }
    else {
      setCustomercheckAll(false);
      settotalselectedshow(false);
      checkboxes.forEach(function (checkbox) {
        checkbox.checked = false;
      });
      let olddata = [...CustomerValues];
      olddata.map((i) => {
        i.Selected = false;

      })

    }

  }

  const OnChangeHandlerRebateType = (data) => {
    setChangeHandle(true)
    setRebateTypeValue(data.Name);
    setSelectedOptionsRebateType(data.Id)
    exceptionPolicyData.RebateTypeId = data.Id
    setSelectedOptionsPayout('')
    getRebateMeasureData("RebateMeasure/GetAll/", data.Id, 'RebateType');
    getRebatePayoutData("RebatePayout/GetAll", data.Id)
  }

  const OnChangeHandlerRebateMeasure = (data) => {
    setChangeHandle(true)
    setSelectedOptionsRebateMeasure(data.Id)
    exceptionPolicyData.RebateMeasureId = data.Id
    setrebateProgramLinedetail({ ...rebateprogrmlinedetail, ['rebateMeasureId']: data.Id })

    if (data.Name === 'Quantity' || data.Name === 'Growth in Qty (Abs)') {
      setmeasureValueLabel('Unit')
    }
    else if (data.Name === 'Revenue' || data.Name === 'Growth in Rev (Abs)') {
      setmeasureValueLabel('Amount')
    }
    else if (data.Name === 'Growth in Qty (%)' || data.Name === 'Growth in Rev (%)') {
      setmeasureValueLabel('%')
    }
    else {
      setmeasureValueLabel('')
    }
  }




  const OnChangeHandlerPayout = (data) => {
    setSelectedOptionsPayout(data.Id)
    exceptionPolicyData.RebatePayoutCondId = data.Id
    setChangeHandle(true)
    setrebateProgramLinedetail({ ...rebateprogrmlinedetail, ['Rebate_Payout_Cond_Id']: data.Id })

    setRebatePayoutCond(data.Name)
    if (data.Name === 'Stepped' || 'Targeted') {
      setTargetPayCond(false)
      setVolumeRebateList([{
        id: 0,
        product: "",
        Targetcondition: "",
        Measure_Value: "",
        Target_Measure: "",
        Rebate: "",
        Rebate_Value: ""
      }])
      setRebateIcon("")
    }
    if (data.label === 'Lumpsum') {
      setTargetPayCond(true)
      setVolumeRebateList([{
        id: 0,
        product: "",
        Targetcondition: "",
        Measure_Value: "",
        Target_Measure: "Lumpsum",
        Rebate: "",
        Rebate_Value: "LS"
      }])
      setRebateIcon("LS")
    }
    if (data.label === 'Unit') {
      setTargetPayCond(true)
      setVolumeRebateList([{
        id: 0,
        product: "",
        Targetcondition: "",
        Measure_Value: "",
        Target_Measure: "Quantity",
        Rebate: "",
        Rebate_Value: "₹"
      }])
      setRebateIcon("₹")
    }
    if (data.label === 'Percent') {
      setTargetPayCond(true)
      setVolumeRebateList([{
        id: 0,
        product: "",
        Targetcondition: "",
        Measure_Value: "",
        Target_Measure: "Invoice Price",
        Rebate: "",
        Rebate_Value: "%"
      }])
      setRebateIcon("%")
    }

    if (data.label === 'Select') {
      setTargetPayCond(true)
      setVolumeRebateList([{
        id: 0,
        product: "",
        Targetcondition: "",
        Measure_Value: "",
        Target_Measure: "",
        Rebate: "",
        Rebate_Value: ""
      }])
      setRebateIcon("")
    }

  }


  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    setChangeHandle(true);
    console.log("23-1", name, index);


    let list = [...VolumeRebateList];

    if (name === 'Rebate_Value') {

      if (value === '%') {
        setRebateIcon(value);
        list[index]['Target_Measure'] = 'Invoice Price';
      } else if (value === 'Select') {
        setRebateIcon('');
        list[index]['Target_Measure'] = '';
      } else if (value === '$' || value === '₹') {
        setRebateIcon(value);
        list[index]['Target_Measure'] = 'Quantity';
      } else if (value === 'LS') {
        setRebateIcon(value);
        list[index]['Target_Measure'] = 'Lumpsum';
      }

      list[index][name] = value;


      if (VolumeRebateList.length > 1) {
        let currentRebateValue = VolumeRebateList[0].Rebate_Value;
        let currentTargetMeasure = VolumeRebateList[0].Target_Measure;

        VolumeRebateList.forEach((item) => {
          if (item['Rebate_Value'] !== currentRebateValue) {
            item['Rebate_Value'] = currentRebateValue;
          }
          if (item['Target_Measure'] !== currentTargetMeasure) {
            item['Target_Measure'] = currentTargetMeasure;
          }
        });
      }
    }

    else if (name === 'Targetcondition') {

      for (let i = 0; i <= list.length - 1; i++) {
        list[i].Targetcondition = e.target.value;
      }
    }
    else {

      list[index][name] = value;
    }


    setVolumeRebateList(list);
  };


  const getRebatePayoutData = (method, Id) => {
    Service.getdatabyId(method, Id).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
              setRebatePayoutCondData(res.data)
            }
          }
        }
      }

    });
  }



  const getRebateMeasureData = (method, Id, flag) => {
    Service.getdatabyId(method, Id).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
              console.log("dataaa", res.data);
              setRebateMeasureData(res.data)
              if (res.data[0].Name === 'Quantity' || res.data[0].Name === 'Revenue') {
                setSelectedOptionsRebateMeasure(res.data[0].Id)
                setExceptionPolicyData({ ...exceptionPolicyData, ['RebateMeasureId']: res.data[0].Id, ['RebateTypeId']: Id })
              }
              if (res.data[0].Name === 'Quantity') {
                setmeasureValueLabel('Unit')
              }
              else if (res.data[0].Name === 'Revenue') {
                // setmeasureValueLabel('Amount')
                setmeasureValueLabel('%');
              }
              // else if (res.data[0].Name === 'Growth in Qty (%)' || res.data[0].Name === 'Growth in Rev (%)') {
              //   setmeasureValueLabel('%')
              // }
              if (flag == 'RebateType') {
                // res.data.map((i) => {
                //   if (i.Name === 'Growth in Qty (%)' || i.Name === 'Growth in Qty (Abs)') {
                //     if (i.Name === 'Growth in Qty (%)') {
                //       setSelectedOptionsRebateMeasure(i.Id)
                //       setExceptionPolicyData({ ...exceptionPolicyData, ['RebateMeasureId']: i.Id , ['RebateTypeId']: Id})
                //       setmeasureValueLabel("%")
                //     }
                //   }
                //   if (i.Name === 'Growth in Rev (%)' || i.Name === 'Growth in Rev (Abs)') {
                //     if (i.Name === 'Growth in Rev (%)') {
                //       setSelectedOptionsRebateMeasure(i.Id)
                //       setExceptionPolicyData({ ...exceptionPolicyData, ['RebateMeasureId']: i.Id , ['RebateTypeId']: Id })
                //       setmeasureValueLabel("%")
                //     }
                //   }
                // })
              }
            }
          }
        }
      }
    });
  }



  const getRebateTypeData = (method) => {
    Service.getAlldata(method).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
              setRebateTypeData(res.data)
              let revenueId = res.data.find((x) => x.Name === 'Revenue')?.Id
              setSelectedOptionsRebateType(revenueId);
              if (props.id === 0 || props.id === '0') {
                getRebateMeasureData("RebateMeasure/GetAll/", revenueId, 'RebateType');
                getRebatePayoutData("RebatePayout/GetAll", revenueId)
              }
            }
          }
        }
      }

    });
  }


  const handleCustSelection = (e, label, id, State) => {


    let checkboxes = document.querySelectorAll("input[id = '" + id + "']:checked");
    let Selected = [];
    let update = [];

    let olddata = [...CustomerValues];

    if (checkboxes.length >= 1) {
      olddata.map((i) => {
        if (i.Id === id) {
          i.Selected = true;
        }
      })
      data.map((i) => {
        if (i.Id === id) {
          i.Selected = true;
        }
      })
      setData(data)
      setCustomerData(olddata)

      setCustomerValues(olddata);

    }
    else {
      olddata.map((i) => {
        if (i.Id === id) {
          i.Selected = false;
        }
        update.push(i);
      })
      data.map((i) => {
        if (i.Id === id) {
          i.Selected = false;
        }
      })
      setData(data)

      setCustomerValues(olddata);
    }
    setCustomerChanged(true)
  }




  const handleCustomerChanges = (e, label, id, State) => {

    let Selected = [];
    let update = [];

    let olddata = [...CustomerValues];
    if (e.target.checked === true) {

      olddata.map((i) => {
        if (i.Id === id) {
          i.Selected = true;
        }

      })

      customerData.map((i) => {
        if (i.Id === id) {
          i.Selected = true;
        }
      })
      setData(customerData)


      setCustomerValues(customerData);

    }
    else {
      olddata.map((i) => {
        if (i.Id === id) {
          i.Selected = false;
        }
        update.push(i);
      })

      customerData.map((i) => {
        if (i.Id === id) {
          i.Selected = false;
        }
      })
      setData(customerData)


      setCustomerValues(customerData);
    }

  }

  const SearchGroupCustomer = (event) => {
    var list = []
    let lstSelected = CustomerGroupValues.filter((e) => e.Selected === true).map(item => {
      return { ...item }
    })

    if (event.target.value != '') {
      let searchResults = list.filter((each) => {

        return each.label.toLowerCase().includes(String(event.target.value).toLowerCase()) ||
          each.Name.toLowerCase().includes(String(event.target.value).toLowerCase())


      });

      let newlist = [...searchResults, ...lstSelected];
      const uniquelst = [];
      newlist.map((item) => {
        var findItem = uniquelst.find((x) => x.Id === item.Id);
        if (!findItem) uniquelst.push(item);
      });


    }
    else {

    }
  }


  const checkAllGroupCustomer = (mycheckbox) => {


  }

  const OnChangeDateRange = (e) => {
    // Extract label and value from selected fiscal year range
    setselectedOptionsDateRange(e.label);
    let dateArray = e.value.split("~");
    let startDate = new Date(dateArray[0]);
    let endDate = new Date(dateArray[1]);
    const fyStartDate = startDate; // April 1st
    const fyEndDate = endDate; // March 31st of the next year 
    // Set the state for the StartDate and EndDate fields with the selected fiscal year range
    setExceptionPolicyData({ ...exceptionPolicyData, ['Validity_From']: fyStartDate, ['Validity_To']: fyEndDate });
    // Set minimum and maximum selectable dates in the date picker
    setminDate(fyStartDate); // Set minimum date as April 1st of the current fiscal year
    setmaxDate(fyEndDate);   // Set maximum date as March 31st of the next fiscal year
    // Ensure fiscal year label is set in the state
    state.FiscalYear = e.label;
    // If endDate is a string, handle parsing (for safety, you might not need this part)
    if (typeof endDate === 'string') {
      const [day, month, year] = endDate.split('/');
      const parsedDate = new Date(`${year}-${month}-${day}`);
      setmaxDate(parsedDate ? new Date(parsedDate) : new Date());
    } else {
      console.error('endDate is not a string:', endDate);
    }
    state.FiscalYear = e.label;
  };

  console.log("25/1", exceptionPolicyData);
  console.log("25/1", SubCategoryValues);
  const FilterColumnGroupCustomer = (name) => {
    var divtop1 = document.getElementById("CustomerGroup");
    var cardtop1 = document.getElementById("CustomerGroup");
    setdivtop(divtop1.offsetTop + cardtop1.offsetTop + 30)
    setCustomerGroupFilterCol(name)
    setshowCustomerGroupfilterpopup(true)

    if (name === 'label') {
      setfiltertextCustGroup("Customer Group Label")
      var testDiv = document.getElementById("GroupLabel");
      setdivleft((testDiv.offsetLeft + 30) + "px");
    }
    DropdownHandlerCustGroup(name);
  }

  const DropdownHandlerCustGroup = (key) => {

    setcurrentCustGroupFilterKey(key)
    Service.getfilterDropdownByGroup('CustomerGroups/GetFilterValue', key).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.data !== 'null' && res.data !== 'undefined') {

            addnewfieldCustomerGroup(res.data, key)
          }
        }
      }

    });

  }

  const addnewfieldCustomerGroup = (data, key) => {

    let currentdata = data.map((i) => {
      i.Selected = false;
      return i;
    });
    let selectedKeys = filterValueGroupCustomer[key];

    if (selectedKeys != '') {

      currentdata.forEach((each) => {
        selectedKeys.forEach((eachSelected) => {
          if (each.Value == eachSelected.Name) {
            each.Selected = true
          }
        })
      })
    }
    setFilterCustGroupDropDownData(currentdata);
    setfilterCustGroupData(currentdata)
  }




  const OnChangeHandlerGroupCustomer = (e, label, id, State) => {
    let Selected = [];
    let update = [];
    const getallData = [];
    let olddata = [...CustomerGroupValues];

    if (e.target.checked === true) {

      olddata.map((i) => {
        if (i.Id === id) {
          i.Selected = true;
        }

      })

      datagroupCust.map((i) => {
        if (i.Id === id) {
          i.Selected = true;
        }

      })

      getallData.push(id);
      setCustomerGroupValues(olddata);
    }
    else {
      olddata.map((i) => {
        if (i.Id === id) {
          i.Selected = false;
        }
        update.push(i);
      })

      datagroupCust.map((i) => {
        if (i.Id === id) {
          i.Selected = false;
        }
        update.push(i);
      })

      setCustomerGroupValues(olddata);
    }
  }


  const okCustomerGroup = () => {
    setshowCustomerGroupPopup(false);
    let ids = [];
    ids = formatAttributes(CustomerGroupValues);
    const list = [...ExceptionValueList];
    const list2 = [...exceptionValues];

    list[itemNo]['ValueListString'] = ids;
    list2[itemNo]['ValueListString'] = ids;
    setExceptionValueList(list);
    setExceptionValues(list2);
  }

  const UserfilterHandlerCustomerGroup = (e) => {

    setFilterSearchCustGroup(e.target.value)
    if (e.target.value !== "") {
      let searchResults = filterCustGroupDropDownData.filter((each) => {
        return each.Value.toLowerCase().includes(String(e.target.value).toLowerCase())
      });
      setFilterCustGroupDropDownData(searchResults);
    }
    else {
      setFilterSearchCustGroup("")
      setFilterCustGroupDropDownData(filtercustGroupData)
    }
  }
  const checkAllFilterCustomerGroup = (mycheckbox) => {
    var lastfilter = [...filterCustGroupDropDownData]
    let data1 = []
    var checkboxes = document.querySelectorAll("input[name = 'filter-option-all']");
    if (mycheckbox.target.checked == true) {

      let currentdata = lastfilter.map((i) => {
        i.Selected = true;
        return i;
      });

      setFilterCustGroupDropDownData(currentdata)
      filterCustGroupDropDownData.map((each) => {
        const getallData = {
          Name: each.label,
        }
        data1.push(getallData)
      })
      setFilterValueCustGroup(filterValueCustGroup => [...filterValueCustGroup, ...data1]);
      let updateddata = ([...filterValueCustGroup, ...data1]);
      setFilterValuegroupCustomer(() => ({
        ...filterValueGroupCustomer, [custGroupFilterCol]: updateddata,
      }));
    }
    else {

      let currentdata = lastfilter.map((i) => {
        i.Selected = false;
        return i;
      });
      setFilterValuegroupCustomer([])
      setFilterValueCustGroup([])

    }

  }
  const OnCustGroupdropdownChange = (e, id, name) => {

    let isChecked = e.target.checked;
    var lastfilter = [...filterCustGroupDropDownData]
    if (isChecked === true) {
      let currentdata = lastfilter.map((i) => {
        if (i.label == name) {
          i.Selected = true;
        }
        return i;
      });
      setFilterCustGroupDropDownData(currentdata);
      let data1 = []
      const getallData = {
        Name: name,
      }
      data1.push(getallData)

      setFilterValueCustGroup(filterValueCustGroup => [...filterValueCustGroup, ...data1]);
      let updateddata = ([...filterValueCustGroup, ...data1]);
      setFilterValuegroupCustomer(() => ({
        ...filterValueGroupCustomer, [custGroupFilterCol]: updateddata,
      }));
    }
    else {
      let currentdata = lastfilter.map((i) => {
        if (i.Id === id) {
          i.Selected = false;
        }
        return i;
      });

      setFilterCustGroupDropDownData(currentdata);
      setFilterValueCustGroup(filterValueCustGroup => filterValueCustGroup.filter(data => data.label !== name))
      let data1 = filterValueCustGroup.filter(data => data.label !== name);
      setFilterValuegroupCustomer(() => ({
        ...filterValueGroupCustomer, [custGroupFilterCol]: data1,
      }));
    }
  }
  const filterHandlerCustomerGroup = (flag) => {

    var sortType = columnAscending === true ? 'Ascending' : 'Descending';
    let pagenumber = 1;
    paging.pageNumber = pagenumber;

    if (flag === 'Apply') {
      setshowCustomerGroupfilterpopup(false)
      let list = [];

      for (var key in filterValueGroupCustomer) {
        if (filterValueGroupCustomer[key].length > 0) {
          let values = ""
          filterValueGroupCustomer[key].map((i) => {
            values += i.Name + ";";
          })
          if (values !== null && values !== '') {
            list.push({
              filterKey: key,
              FilterValue: values
            })
          }
        }
      }



      if (list.length > 0) {
        setpaging(paging);
        GetGroupCustomerData("CustomerGroups/GetAll", paging.pageSize, paging.pageNumber, sortType, sortColName, list, searchValue)
      }


    }
    else {
      setshowCustomerGroupfilterpopup(false)

      setFilterValuegroupCustomer(() => ({
        ...filterValueGroupCustomer, [custGroupFilterCol]: '',
      }));


      let list = [];
      for (var key in filterValueGroupCustomer) {
        if (key !== custGroupFilterCol && filterValueGroupCustomer[key].length > 0) {
          let values = ""
          filterValueGroupCustomer[key].map((i) => {
            values += i.Name + ";";
          })
          if (values !== null && values !== '') {
            list.push({
              filterKey: key,
              FilterValue: values
            })
          }
        }
      }


      setpaging(paging);

      GetGroupCustomerData("CustomerGroups/GetAll", paging.pageSize, paging.pageNumber, sortType, sortColName, list, searchValue)

      setCustomerGroupFilterCol('')
      setFilterValueCustGroup([])
      setFilterSearchCustGroup("")
      setFilterCustGroupDropDownData([])
      setfilterCustGroupData([])
    }
  }



  const checkAllSettlFreqChange = (mycheckbox) => {
    var checkboxes = document.querySelectorAll("input[name = 'settlfreq-check']");
    if (mycheckbox.target.checked == true) {
      setsettlFreqcheckAll(true);
      checkboxes.forEach(function (checkbox) {
        checkbox.checked = true;
      });


      settlFreqValues.map((i) => {
        i.Selected = true

      })


    }
    else {
      setsettlFreqcheckAll(false);
      checkboxes.forEach(function (checkbox) {
        checkbox.checked = false;
      });
      settlFreqValues.map((i) => {
        i.Selected = false;
      })


    }
  }

  const checkAllRebateTypeChange = (mycheckbox) => {
    var checkboxes = document.querySelectorAll("input[name = 'settlfreq-check']");
    if (mycheckbox.target.checked == true) {
      setRebateTypecheckAll(true);
      checkboxes.forEach(function (checkbox) {
        checkbox.checked = true;
      });


      rebateTypValues.map((i) => {
        i.Selected = true

      })


    }
    else {
      setRebateTypecheckAll(false);
      checkboxes.forEach(function (checkbox) {
        checkbox.checked = false;
      });
      rebateTypValues.map((i) => {
        i.Selected = false;
      })


    }
  }

  const handleSettlFreqChanges = (e, label, id) => {
    let Selected = [];
    let updatedSetlFreq = [];


    let olddata = [...settlFreqValues];
    if (e.target.checked === true) {

      olddata.map((i) => {
        if (i.Id === id) {
          i.Selected = e.target.checked;
        }
      })


      setSettlFreqValues(olddata)


    }
    else {


      olddata.map((i) => {
        if (i.Id === id) {
          i.Selected = e.target.checked;
        }
      })



      setSettlFreqValues(olddata)


    }

  }
  const handleRebateTypeChanges = (e, label, id) => {
    let Selected = [];
    let updatedSetlFreq = [];


    let olddata = [...rebateTypValues];
    if (e.target.checked === true) {

      olddata.map((i) => {
        if (i.Id === id) {
          i.Selected = e.target.checked;
        }
      })

      setRebateTypeValues(olddata)


    }
    else {


      olddata.map((i) => {
        if (i.Id === id) {
          i.Selected = e.target.checked;
        }
      })



      setRebateTypeValues(olddata)


    }

  }

  const oksettlFreq = () => {
    setshowSetlFreqPopup(false);
    let ids = [];
    ids = formatAttributes(settlFreqValues);
    const list = [...ExceptionValueList];
    const list2 = [...exceptionValues];

    list[itemNo]['ValueListString'] = ids;
    list2[itemNo]['ValueListString'] = ids;
    setExceptionValueList(list);
    setExceptionValues(list2);
  }
  const okRebateType = () => {
    setshowRebateTypePopup(false);
    let ids = [];
    ids = formatAttributes(rebateTypValues);
    const list = [...ExceptionValueList];
    const list2 = [...exceptionValues];

    list[itemNo]['ValueListString'] = ids;
    list2[itemNo]['ValueListString'] = ids;
    setExceptionValueList(list);
    setExceptionValues(list2);
  }


  const clickShowSelectedSettlFreq = (val) => {

    let lstcopy = settlFreqValues.filter((e) => e.Selected == true).map(item => {
      return { ...item }
    })

    let lst1 = [];
    setSettlFreqDataSelected([...lstcopy])
    setsidebarwidth("63vw");

  }
  const clickShowSelectedRebateType = (val) => {

    let lstcopy = rebateTypValues.filter((e) => e.Selected == true).map(item => {
      return { ...item }
    })

    let lst1 = [];
    setRebateTypeDataSelected([...lstcopy])
    setsidebarwidth("63vw");

  }

  const clickSelectedShowCustomerPopup = (val) => {

    let lstcopy = CustomerValues.filter((e) => e.Selected == true).map(item => {
      return { ...item }
    })

    let lst1 = [];
    setCustomerDataSelected([...lstcopy])
  }

  const okSelectedCustomerGroup = () => {
    setShowSelectedCustomerGrouppopup(false);
  }

  const clickSelectedShowCustomerGroupPopup = (val) => {

    let lstcopy = CustomerGroupValues.filter((e) => e.Selected === true).map(item => {
      return { ...item }
    })

    let lst1 = [];
    setCustomerGroupDataSelected([...lstcopy])
  }

  const CloseHandler = (e) => {
    Service.LockUnLockEntity('Configurationprop/UnLockEntity', "SegmentMappingPolicy", exceptionPolicyData.Id, loggedInUser).then((res) => {

    });
    if (loadedFrom === 'fromApproval') {
      navigate("/SegmentMappingPolicyList/pending");
    }
    else if (loadedFrom === 'fromCompletedWorkflow') {
      navigate("/SegmentMappingPolicyList/archive");
    }
    else if (loadedFrom == "fromPolicy") {
      navigate("/SegmentMapping-Policy/List")
    }

  }
  const OnFYThresholdOperatorChange = (e) => {
    let value = e.target.value;
    setfyThresholdList({ ...fyThresholdList, ['Operator']: value })
  }
  const OnFYThresholdValueChange = (e) => {
    let value = e.target.value;
    setfyThresholdList({ ...fyThresholdList, ['ThresholdValue']: value })
  }

  const OnFYThresholdPayoutChange = (e) => {
    let value = e.target.value;
    setfyThresholdList({ ...fyThresholdList, ['PayoutValue']: value })
  }




  const workflowAlert = (e, flag) => {
    let isValid = validateField()
    if (isValid) {

      if (flag == 'open') {
        if (workflowApprovers.length > 0 && exceptionPolicyData.IncludeWorkflow === true) {
          setShowWorkflowAlert(true)
        }
        else {
          showSubmitDialog(e, 'showSubmitDialog')
        }
      }
      else if (flag == 'close') {
        setShowWorkflowAlert(false)
        showSubmitDialog(e, 'showSubmitDialog')
      }
    }
    else {
      setErrorStatus("Error")
      setShowErrorModal(true);
      return false;
    }
  }



  const showSubmitDialog = (e, flag) => {

    
    if (flag === 'showSubmitDialog') {
      setShowSubmitModal(!showSubmitModal)

    }
    else if (flag === 'proceed') {
      setShowSubmitModal(false)
      SubmitHandler()

    }
  }

  const SubmitHandler = (e) => {

    let isValid = validateField()

    if (isValid == true) {
      let inputData = []
      let mpgdata = MPGList.map(item => ({
        Id: item.id,
        PolicyId: exceptionPolicyData.PolicyId,
        PolicyName: exceptionPolicyData.PolicyName,
        FiscalYear: selectedOptionsDateRange,
        LastModifiedUserId: loggedInUser,
        CreatedUserId: loggedInUser,
        CategorySubcategoryData: jsonList,
        Validity_From: exceptionPolicyData.Validity_From,
        Validity_To: exceptionPolicyData.Validity_To,
        SegmentId: item.segmentId,
        SegmentName: "",
        MPGListString: item.subcategory.map(sub => sub.toString()),
        ValueListString: item.attributes.map(attr => attr.value.toString()),
        RebateValue: item.rebate,
        Attributes: item.attributes.map(attr => ({
          id: attr.id,
          name: attr.name || "",
          ValueListString: attr.value.map(val => val.toString()),
        })),
      }))

      let partdata = PartHashList.map(item => ({
        Id: item.id,
        PolicyId: exceptionPolicyData.PolicyId,
        PolicyName: exceptionPolicyData.PolicyName,
        FiscalYear: selectedOptionsDateRange,
        LastModifiedUserId: loggedInUser,
        CreatedUserId: loggedInUser,
        CategorySubcategoryData: jsonList,
        Validity_From: exceptionPolicyData.Validity_From,
        Validity_To: exceptionPolicyData.Validity_To,
        SegmentId: item.segmentId,
        SegmentName: "",
        ValueListString: item.values.map(attr => attr.toString()),
        RebateValue: item.rebate,
      }))

      inputData = {
        Id: exceptionPolicyData.Id,
        PolicyId: exceptionPolicyData.PolicyId,
        PolicyName: exceptionPolicyData.PolicyName,
        FiscalYear: selectedOptionsDateRange,
        GroupBy: selectedGroupBy,
        LastModifiedUserId: loggedInUser,
        CreatedUserId: loggedInUser,
        CategorySubcategoryData: jsonList,
        Validity_From: exceptionPolicyData.Validity_From,
        Validity_To: exceptionPolicyData.Validity_To,
        MaterialPricingPolicyListByMPG: mpgdata,
        MaterialPricingPolicyListByPart: partdata,
        MaterialPricingValueList: ExceptionValueList,
        IncludeWorkflow:exceptionPolicyData.IncludeWorkflow,
      }
      setLoading(true);
      Service.SubmitExceptionPolicy("MaterialPricingPolicy/SubmitMaterialPricingPolicy", props.id, loggedInUser, commentsForSubmit, inputData).then((res) => {

        if (typeof res !== 'undefined') {

          if (typeof res.data !== 'undefined' && res.data !== 'null') {
            let rData = res.data;
            if (res.data.Code == 200) {
              if (res.data !== 'null' && res.data !== 'undefined') {
                if (res.data.Status != 'Failure') {

                  let id = parseInt(res.data.Id)
                  setCommentsForSubmit('')
                  setShowSubmitModal(!showSubmitModal)
                  toast.success('Submitted Successfully !', {
                    position: toast.POSITION.TOP_RIGHT,
                    // onClose: () => navigate("/SegmentMappingPolicyList")
                  });
                  setTimeout(() => {
                    CloseHandler('close');
                  }, 200)
                  getExceptionPolicyById("MaterialPricingPolicy/GetById", res.data.Id)
                }
                else {
                  toast.error('Error in submission !', {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                  setLoading(false)
                  setErrorMessage(res.data.ErrorMessage);
                  setErrorStatus("Error")
                  setShowErrorModal(true)
                  setShowSubmitModal(!showSubmitModal)
                  getExceptionPolicyById("MaterialPricingPolicy/GetById", res.data.Id)
                }
              }
            }
          }
        }
      });
    }
    else {
      setErrorStatus("Error")
      setShowErrorModal(true);
      return false;
    }
  }

  const ApproveException = () => {

    let Id = exceptionPolicyData.Id
    let Comments = commentsForApproved
    let UserId = SessionManage.getuserid();//state.createdUserId
    let data = { Id, Comments, UserId }

    Service.createorupdate('MaterialPricingPolicy/ApproveMaterialPricingPolicy', data).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {

            if (res.data !== 'null' && res.data !== 'undefined') {

              Service.LockUnLockEntity('Configurationprop/UnLockEntity', "SegmentMappingPolicy", Id, loggedInUser).then((res) => {
                if (typeof res !== 'undefined') {
                  if (typeof res.data !== 'undefined' && res.data !== 'null') {

                  }
                }

              });
              toast.success('Approved Successfully !', {
                position: toast.POSITION.TOP_RIGHT,
                onClose: () => navigate("/SegmentMappingPolicyList/pending")
              });

            }

          }
          else if (res.status !== 200) {
            toast.error('Error in approval !', {
              position: toast.POSITION.TOP_RIGHT,
            });
            setErrorMessage(res.data.ErrorMessage);
            setErrorStatus("Error")
            setShowErrorModal(true)
          }
        }
      }

    });
  }

  const DenyException = () => {

    let Id = exceptionPolicyData.Id
    let Comments = commentsForDenied
    let UserId = SessionManage.getuserid();//state.createdUserId
    let data = { Id, Comments, UserId }

    Service.createorupdate('MaterialPricingPolicy/DenyMaterialPricingPolicy', data).then((res) => {

      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {

            if (res.data !== 'null' && res.data !== 'undefined') {

              Service.LockUnLockEntity('Configurationprop/UnLockEntity', "SegmentMappingPolicy", Id, loggedInUser).then((res) => {
                if (typeof res !== 'undefined') {
                  if (typeof res.data !== 'undefined' && res.data !== 'null') {

                  }
                }

              });
              toast.success('Denied Successfully', {
                position: toast.POSITION.TOP_RIGHT,
                onClose: () => navigate("/SegmentMappingPolicyList/pending")
              });

            }

          }
          else if (res.status !== 200) {
            toast.error('Error in deny !', {
              position: toast.POSITION.TOP_RIGHT,
            });
            setErrorMessage(res.data.ErrorMessage);
            setErrorStatus("Error")
            setShowErrorModal(true)
          }
        }
      }

    });
  }
  const getUserPermission = (pathname) => {
    Service.GetUserPermission(pathname).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null' && res.data !== '') {
          if (res.data.DataList !== undefined) {
            setuserPermission(res.data.DataList)
          }
        }
      }
    });
  }
  const showApprovedDialog = (flag) => {
    if (flag === 'showApprovedDialog') {
      setCommentsForApproved("")
      setShowApprovedModal(!showApprovedModal)
    }
    else if (flag === 'proceed') {
      ApproveException()
      setShowApprovedModal(!showApprovedModal)

    }
  }


  const showDeniedDialog = (flag) => {
    if (flag === 'showDeniedDialog') {
      setCommentsForDenied("")
      setShowDeniedModal(!showDeniedModal)
    }
    else if (flag === 'proceed') {
      DenyException()
      setShowDeniedModal(!showDeniedModal)

    }
  }
  const OnConditionChange = (data, index) => {
    const list = [...ExceptionValueList];
    const list2 = [...exceptionValues];

    list[index]['Condition'] = data;
    list2[index]['Condition'] = data;
    setExceptionValueList(list);
    setExceptionValues(list2);

  }

  const UpdateCategoryValues = () => {
    const updatedList = catValues.filter((item) => item.Name !== "Product");
    setCategoryValues(list => [...updatedList])

  }
  const UpdateCategoryValuesFromType = () => {
    const updatedList = CategoryValues.filter((item) => item.Name !== "Product");
    setCategoryValues(list => [...updatedList])

  }

  const customStyles = {
    control: base => ({
      ...base,
      minHeight: 26
    }),
    dropdownIndicator: base => ({
      ...base,
      padding: 2
    }),
    clearIndicator: base => ({
      ...base,
      padding: 2
    }),
    multiValue: base => ({
      ...base,

    }),
    valueContainer: base => ({
      ...base,
      padding: '0px 2px'
    }),
    input: base => ({
      ...base,
      margin: 0,
      padding: 0
    })
  };

  // if (isLocked === false || (isLocked === true && lockedBy == loggedInUser)) {
  //   if (exceptionPolicyData.StatusLabel == null || exceptionPolicyData.StatusLabel == "New") {
  //     disableField = false;
  //   }
  //   else if (exceptionPolicyData.StatusLabel != "New" || exceptionPolicyData.StatusLabel == "Approved" || exceptionPolicyData.StatusLabel == "InReview") {
  //     disableField = true;
  //   }

  // }
  // else {
  //   disableField = true;
  // }

  if (isLocked === false || (isLocked === true && lockedBy == loggedInUser)) {
    if ((exceptionPolicyData.StatusLabel == null || exceptionPolicyData.StatusLabel == "New") && (Service.editPerms(userPerms) != false)) {
      disableField = false;
    }
    else if (exceptionPolicyData.StatusLabel != "New" || exceptionPolicyData.StatusLabel == "Approved" || exceptionPolicyData.StatusLabel == "InReview") {
      disableField = true;
    }
    else {
      disableField = true;
    }
  }
  else {
    disableField = true;
  }

  const OnChangeHandlerSetFreq = (data) => {
    setSelectedOptionsSetlFreq(data.Id)
    setExceptionPolicyData({ ...exceptionPolicyData, ['SettlementFrequencyId']: data.Id })
  }
  const onChangeHandlerMissedTarget = (data) => {
    setExceptionPolicyData({ ...exceptionPolicyData, ['Allowedmissedperiods']: parseFloat(data.target.value) })
  }
  console.log('16-3', exceptionValues)
  console.log('16-3', ExceptionValueList)
  console.log('16-3', exceptionPolicyData)

  const getFileData = () => {
    document.getElementById('fileInput').click();
  }


  const ImportCSVData = (event, action) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
    // setLoading(true)
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = async (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });

        let result = [];

        workbook.SheetNames.forEach((sheetName) => {
          const sheet = workbook.Sheets[sheetName];
          const sheetData = XLSX.utils.sheet_to_json(sheet, { raw: false });
          result = sheetData;
        });


        console.log(result, 'CSV-result');

        setLoading(true);

        // Store original result in a temporary variable named temp
        const temp = result;

        // Update keys in result array
        // result = result.map(item => {
        //     return {
        //         Category: item.Category,
        //         Subcategory: item.MaterialPricingGroup
        //     };
        // });

        let inputData = []
        inputData = {
          Id: exceptionPolicyData.Id,
          PolicyId: exceptionPolicyData.PolicyId,
          PolicyName: exceptionPolicyData.PolicyName,
          FiscalYear: selectedOptionsDateRange,
          LastModifiedUserId: loggedInUser,
          CreatedUserId: loggedInUser,
          CategorySubcategoryData: result,
        }

        console.log("15/2", inputData)


        Service.createorupdate('MaterialPricingPolicy/CreateOrUpdate', inputData).then((res) => {
          if (typeof res !== 'undefined') {
            if (res.data !== 'null' && typeof res.data !== 'undefined') {
            }
          }
        });

        setLoading(false);
        setJsonList(result);
      };

      reader.readAsBinaryString(file);


    }
  };

  // const ImportCSVData = (event) => {
  //   const file = event.target.files[0];

  //   if (!file) {
  //     return;
  //   }

  //   const fileName = file.name;
  //   const fileType = fileName.slice(fileName.lastIndexOf('.') + 1).toLowerCase();

  //   if (fileType !== 'csv') {
  //     alert('Please upload a CSV file.');
  //     event.target.value = null;
  //     return;
  //   }

  //   setSelectedFile(file);
  //   setIsFilePicked(true);

  //   // Proceed with CSV file processing using Papa Parse
  //   const reader = new FileReader();
  //   reader.onload = (e) => {
  //     const csvData = e.target.result;
  //     Papa.parse(csvData, {
  //       header: true,
  //       complete: (results) => {
  //         console.log('Parsed CSV data:', results.data);
  //         setJsonList(results.data)
  //       },
  //       error: (error) => {
  //         console.error('Error parsing CSV:', error);
  //       },
  //       //dynamicTyping: true, // Attempt to convert numeric and boolean values automatically
  //       skipEmptyLines: true, // Skip empty lines
  //     });
  //   };
  //   reader.readAsText(file);
  // };


  const dateFormat = SessionManage.getcurrentdateformat();
  const timeFormat = "hh:mm A";

  //--------------------------------------------------------

  return (
    <>
      <div className="container-fluid content-top-gap">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb my-breadcrumb">
            <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
            <li className="breadcrumb-item active" aria-current="page"><Link to={props.name === "fromApproval" ? "/SegmentMappingPolicyList/pending" : props.name === "fromCompletedWorkflow" ? "/SegmentMappingPolicyList/archive" : "/SegmentMapping-Policy/List"}>{props.name === "fromApproval" ? "Pending Segment Mapping Policy List" : props.name === "fromCompletedWorkflow" ? "Completed Segment Mapping Policy List" : "Segment Mapping Policy List"}</Link></li>
            <li className="breadcrumb-item active" id="libread" aria-current="page">{props.name === "fromPolicy" ? t('MaterialPricingGroupMappingPolicy.MPGMPNew') : exceptionPolicyData.PolicyId}</li>
          </ol>
        </nav>
        <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
          onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
        {loading ? <><div className="loader-container"><div className="spinner"></div></div> </> : ''}
        <ToastContainer autoClose={1000} />
        <div className="row">
          <div className="col-lg-12">
            <div className="card text-center card_border btn_res">
              <div className="card-header chart-grid__header align-items-center mt-1" style={{ height: "35px" }}>
                <div className="row">
                  <div className="col-md-6 text-left" /*className="col-md-8 text-left"*/>
                    {t('MaterialPricingGroupMappingPolicy.MPGMPHeader')}
                  </div>

                  <div className="col-md-6 btn_margin" /* style={{"margin-left":"83px"}}*/>

                    {Service.editPerms(userPerms) == false ? "" : disableField === false ?
                      <>
                        <button className="btn  btn-update btn-sm ml-1" style={{ width: "40px", float: "right" }} type="submit" onClick={(e) => SaveHandler(e, 'Save')} >{t('Save')}</button>
                        <button className="btn  btn-update btn-sm ml-1" style={{ width: "90px", float: "right" }} type="submit" onClick={(e) => SaveHandler(e, 'SaveClose')}>{t('Save & Close')}</button>
                        <button className="btn  btn-update btn-sm ml-1" style={{ width: "50px", float: "right" }} type="submit" onClick={(event) => workflowAlert(event, 'open')}>{t('Submit')}</button>
                      </>
                      : ''}
                    <button className="btn  btn-update btn-sm ml-1" style={{ width: "50px", float: "right" }} type="submit" onClick={CloseHandler}>{t('Cancel')}</button>
                    <button className="btn  btn-update btn-sm ml-1" style={{ width: "65px", float: "right" }} type="submit" onClick={() => { clickShowWorkflow("Workflow", exceptionPolicyData.Id) }}>{t('Workflow')}</button>

                    {Service.editPerms(userPerms) == false ? "" :
                      (loadedFrom == 'fromApproval' && exceptionPolicyData.StatusLabel == 'InReview') ?
                        (isLocked == true && lockedBy.toString() == loggedInUser) ?
                          <>
                            <button type="button" style={{ float: "right", width: "60px" }} className="btn btn-sm btn-update ml-1" onClick={() => showApprovedDialog('showApprovedDialog')} >{t('Approve')}</button>
                            <button type="button" style={{ float: "right", width: "40px" }} className="btn btn-sm btn-update ml-1" onClick={() => showDeniedDialog('showDeniedDialog')} >{t('Deny')}</button>
                          </>
                          : ""
                        : <></>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row col-lg-14 pr-lg-2 mt-1">
          <div className="col-sm-4">
            <div className="row">
              <div className="col-lg-12 pr-lg-2">
                <div className="card text-center card_border">
                  <div className="card-body" style={{ minHeight: "180px" }} >
                    <div className="container">
                      <div className="row">
                        <label className="col-md-6 text-left l_width">{t('Policy ID')}</label>
                        <input disabled={true} name="PolicyId" type="text" className="col-md-6 mb-2 textbox-padding form-control m_width" value={exceptionPolicyData.PolicyId} />

                        <label className="col-md-6 mb-2 text-left l_width">{t('Policy Name')}</label>
                        <input name="PolicyName" type="text" className="col-md-6 mb-2 textbox-padding form-control m_width" value={exceptionPolicyData.PolicyName} disabled={disableField} onChange={(e) => OnNameChange(e)} />

                        <label className="col-md-6 text-left l_width" >{"Settlement Frequency"}<span className={classes.mandatory}>*</span></label>
                        <Select
                          className={`col-md-6 mb-2 textbox-padding-dropdown mt-2 fontsizedropdown m_width ${disabledClass}`}
                          options={settlFreqValues}
                          value={settlFreqValues.filter(function (option) {
                            return option.label === `${selectSettlementFrequency}`;
                          })}
                          placeholder="Select"

                          onChange={(selectedOption) => onChangeSelectedSettlFreq(selectedOption)}
                          styles={customStyles}
                        />
                        

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-sm-4">
            <div className="row">
              <div className="col-lg-12 pr-lg-2">
                <div className="card text-center card_border">

                  <div className="card-body" style={{ minHeight: "135px" }} >
                    <div className="container">
                      <div className="row">
                        <label className="col-md-6 text-left">{t('Rebates.EndDate')}</label>
                        <div className='col-md-6 mt-2 textbox-padding'>
                          <DatePicker name="EndDate" className={`drodownwidth form-control form-class ${disabledClass}`} disabled={disableField}
                            dateFormat={SessionManage.getdateformat()} selected={exceptionPolicyData.EndDate} onChange={(event) => OnChangeHandlerEndDate(event)} />
                        </div>

                        <label className="col-md-6 text-left" style={{ paddingBottom: "10px" }}>{t('Rebates.RebateType')}<span className={classes.mandatory}>*</span></label>

                        <Select
                          className={`col-md-6 mb-2 textbox-padding-dropdown mt-2 fontsizedropdown ${disabledClass}`}
                          options={rebateTypeData}
                          placeholder="Select"
                          value={rebateTypeData.filter(function (option) {
                            return option.value === `${selectedOptionsRebateType}`;
                          })}

                          onChange={OnChangeHandlerRebateType}
                          isSearchable={true}
                          styles={customStyles}
                          isDisabled={true}
                        />


                        <label className="col-md-6 text-left" style={{ paddingBottom: '7px' }}>{t('Rebates.RebateMeasure')}<span className={classes.mandatory}>*</span></label>
                        {(rebateTypeValue === 'Growth in Rev' || rebateTypeValue === 'Growth in Vol') ?
                          <Select
                            className={`col-md-6 mb-2 textbox-padding-dropdown mt-2 fontsizedropdown ${disabledClass}`}
                            options={rebateMeasureData}
                            placeholder="Select"

                            value={rebateMeasureData.filter((option) => {
                              return option.value === `${selectedOptionsRebateMeasure}`;
                            })}
                            onChange={OnChangeHandlerRebateMeasure}
                            isSearchable={true}
                            styles={customStyles}
                            isDisabled={disableField}
                          />
                          :
                          <Select
                            className={`col-md-6 mb-2 textbox-padding-dropdown mt-2 fontsizedropdown ${disabledClass}`}
                            options={rebateMeasureData}
                            placeholder="Select"
                            value={rebateMeasureData.filter(function (option) {
                              return option.value === `${selectedOptionsRebateMeasure}`;
                            })}
                            isSearchable={true}
                            styles={customStyles}
                            isDisabled={true}
                          />
                        }
                        <label className="col-md-6 text-left" >{t('Rebates.PayoutCond')}<span className={classes.mandatory}>*</span></label>
                        <Select
                          className={`col-md-6 mb-2 textbox-padding-dropdown mt-2 fontsizedropdown ${disabledClass}`}
                          options={rebatePayoutCondData}
                          placeholder="Select"
                          value={rebatePayoutCondData.filter(function (option) {
                            return option.value === `${selectedOptionsPayout}`
                          })}
                          onChange={OnChangeHandlerPayout}
                          isSearchable={true}
                          styles={customStyles}
                          isDisabled={disableField}
                          name="Rebate_Payout_Cond_Id"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-sm-4">
            <div className="row">
              <div className="col-lg-12 pr-lg-2">
                <div className="card text-center card_border">
                  <div className="card-body" style={{ minHeight: "180px" }} >
                    <div className="container">
                      <div className="row">
                      <label className="col-md-6  mb-2 mt-2 text-left l_width">{t('Accruals.fiscalYear')}<span className={classes.mandatory}>*</span></label>
                        <Select styles={customStyles}
                          className={`col-md-6  mb-2 mt-2 textbox-padding-dropdown fontsizedropdown  textbox-padding m_width ${disabledClass}`}
                          options={dateRange}
                          placeholder="Select"
                          isSearchable={true}
                          value={dateRange.filter(function (option) {
                            return option.label === `${selectedOptionsDateRange}`;
                          })}
                          onChange={OnChangeDateRange}
                          isDisabled={disableField}
                        />

                        <label className="col-md-6 mb-2 mt-2 text-left l_width">{t('Rebates.StartDate')}</label>
                        <div className='col-md-6 mt-2 mb-2 textbox-padding m_width'>
                          <DatePicker name="Validity_From" value={exceptionPolicyData.Validity_From}
                            className={`drodownwidth form-control form-class ${disabledClass}`}
                            disabled={disableField}
                            dateFormat={SessionManage.getdateformat()}
                            selected={exceptionPolicyData.Validity_From}
                            onChange={(event) => OnChangeHandlerStartDate(event)}
                            minDate={minDate || exceptionPolicyData.Validity_From}  // January 1, 2023
                            maxDate={maxDate || exceptionPolicyData.Validity_To} // March 31, 2024
                            filterDate={(date) => date.getDate()}
                          />
                        </div>
                        <label className="col-md-6 mb-2 mt-2 text-left l_width">{t('Rebates.EndDate')}</label>
                        <div className='col-md-6 mb-2 mt-2 textbox-padding m_width'>
                          <DatePicker name="EndDate" className={`drodownwidth form-control form-class ${disabledClass}`} disabled={true}
                            dateFormat={SessionManage.getdateformat()} selected={exceptionPolicyData.Validity_To} onChange={(event) => OnChangeHandlerEndDate(event)} />
                        </div>

                        
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="row">
              <div className="col-lg-12 pr-lg-2">
                <div className="card text-center card_border">
                  <div className="card-body" style={{ minHeight: "180px" }} >
                    <div className="container">
                      <div className="row">
                      <label className="col-md-6 mb-2 mt-2 text-left l_width">{t('ExceptionPolicy.Status')}</label>
                      <input disabled={true} name="Status" type="text" className="col-md-6 mb-2 mt-2 textbox-padding form-control m_width" value={exceptionPolicyData.StatusLabel} />

                      <label className="col-md-6  mb-2 mt-2 text-left l_width">{t('DataImport.CreatedBy')}</label>
                      <input disabled={true} name="CreatedBy" type="text" className="col-md-6 mb-2 mt-2 textbox-padding form-control m_width" value={exceptionPolicyData.CreatedUser} />

                      <label className="col-md-6 mb-2 mt-2 text-left l_width">{t('DataImport.CreatedDate')}</label>
                      <input type="text" className="col-md-6  mb-2 mt-2 textbox-padding datetimepicker-input form-control m_width" data-target="#reservationdate" disabled={true}
                             name="createdDate" value={exceptionPolicyData.CreatedDate} />   

                      <label className="col-md-6 text-left mt-2 mb-2 l_width">{t('Include Workflow')}<span className={classes.mandatory}>*</span></label>
                        <Select
                          styles={customStyles}
                          className={`col-md-6 mb-2 mt-2 textbox-padding-dropdown fontsizedropdown  textbox-padding m_width ${disabledClass}`}
                          options={includeworkglowoptions}
                          placeholder="Select"
                          isSearchable={true}
                          value={selectedOptionIW}
                          onChange={(selected) => {
                                OnChangeIncludeWorkflow(selected);
                          }}
                          isDisabled={disableField}
                          />                    
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>




        <div className="col-lg-14 mt-3">
          <div className="card text-center card_border">
            <div className="card-header chart-grid__header">
              <div className="row">
                <div className="col-md-8 text-left">{t('MaterialPricingGroupMappingPolicy.MPGMPDef')}</div>
              </div>
            </div>
            <div>
              <div>
                <div className="container">
                  <div className="row">
                    <label className="col-md-2 mt-2 mb-2 ml-5 ">{t('GroupBy')}</label>

                    <Select
                      className={`col-md-2 mb-2 textbox-padding-dropdown mt-2 mr-6 fontsizedropdown  ${disabledClass}`}
                      options={groupbyValues}
                      placeholder="Select"
                      value={groupbyValues.filter((option) => {
                        return option.label === `${selectedGroupBy}`;
                      })}
                      onChange={OnChangeHandlerGroupBy}
                      isSearchable={true}
                      styles={customStyles}
                      isDisabled={disableField}
                    />
                  </div>
                </div>
              </div>
              <div className="container" style={{ display: 'flex', alignItems: 'flex-start', gap: '10px',flexDirection:"column",flexWrap:"nowrap",alignContent:"stretch",justifyContent:"center" }}>
              {selectedGroupBy === "By MPG" ?
                <div className="card-body" style={{ minHeight: "0vh" }}>
                  <div className="container" style={{ marginLeft: "0px" }}>
                    <div className="table-responsive">
                      <table className="table table-bordered table-head-fixed text-nowrap table-bordered table-striped" style={{ width: "50vw" }}>
                        <thead className="thead-light">
                          <tr className="text-left">
                            <th>{t("Segment.Segment")}</th>
                            <th>{t("Segment.MPG")}</th>
                            <th>{t("Rebate Value (%)")}</th>
                            <th>{t("Segment.Attribute")}</th>
                            <th>{t("Segment.Value")}</th>
                            {exceptionPolicyData.StatusLabel === "New" || exceptionPolicyData.StatusLabel === null ?
                              <>
                                <th></th>
                                <th></th>
                              </>
                              :
                              ''
                            }
                          </tr>
                        </thead>
                        <tbody>
                          {MPGList.map((item, index) => (
                            <React.Fragment key={index}>
                              {/* First row rendering for segment, MPG, rebate, and the first attribute-value */}
                              <tr>
                                 {/* Segment */}
                                 <td>
                                  <div className="d-flex align-items-center">
                                  <div className="m-1">
                                    <button onClick={(e) => mpgpopup(e, index, "segment")} className="btn btn-outline-dark btn-sm">{`${t("Select")} ${t("")}`}</button>
                                  </div>
                                  <div className='mx-1'>
                                    <a href='javascript:void(0)' onClick={(e) => mpgSelectedpopup(e, index, "segment")}><p style={{ fontSize: "10px" }}>({MPGList[index].segmentId === 0 ? 0 : 1})</p></a> 
                                  </div>
                                  </div>
                                </td>

                                {/* MPG */}
                                <td>
                                <div className="d-flex align-items-center">
                                  <div className="m-1">
                                    <button onClick={(e) => mpgpopup(e, index, "mpg")} className="btn btn-outline-dark btn-sm">{`${t("Select")} ${t("")}`}</button>
                                  </div>
                                  <div className='mx-1'>
                                    <a href='javascript:void(0)' onClick={(e) => mpgSelectedpopup(e, index, "mpg")}><p style={{ fontSize: "10px" }}>({MPGList[index].subcategory === 0 ? 0 : MPGList[index].subcategory.length})</p></a>
                                  </div>
                                  </div>
                                </td>

                                {/* RebateType */}
                                <td>
                                  <Form.Control
                                    className={`${classes["Form-Input-control"]} ${disabledClass}`}
                                    autoComplete="off"
                                    style={{ width: "100px" }}
                                    value={item.rebate}
                                    name="rebate"
                                    onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()}
                                    onChange={(e) => mpgpopup(e, index, "rebate")}
                                    disabled={disableField}
                                  />
                                </td>

                                {item?.attributes?.[0] && (
                                  <React.Fragment>
                                    <td>
                                      <Form.Control
                                        style={{ width: "100px" }}
                                        className={`${classes["Form-Input-control"]} ${disabledClass}`}
                                        as="select"
                                        value={item.attributes[0].name}  // Ensure this reflects the updated state
                                        onChange={(e) => handleattribute(e, 0, index)}
                                        name="attribute"
                                        disabled={disableField}
                                      >
                                        {/* <option>Select</option> */}
                                        {MappingAttributeValues.map((each) => (
                                          <option
                                            key={each.Id}
                                            value={each.Name}
                                            id={each.Id}
                                          >
                                            {each.Name}
                                          </option>
                                        ))}
                                      </Form.Control>
                                    </td>

                                    <td>
                                    <div className="d-flex align-items-center">
                                      <div className="m-1">
                                        <button onClick={() => openvaluepopup(index, 0)} className="btn btn-outline-dark btn-sm">{`${t("Select")}`}</button>
                                      </div>
                                      <div className='mx-1'>
                                        <a href='javascript:void(0)' onClick={(e) => mpgValueSelectedPopup(e, index,0)}><p style={{ fontSize: "10px" }}>({MPGList[index].attributes[0].value.length === 0 ? 0 : MPGList[index].attributes[0].value.length })</p></a>
                                      </div>
                                      </div>
                                    </td>
                                  </React.Fragment>
                                )}
                                {exceptionPolicyData.StatusLabel === "New" || exceptionPolicyData.StatusLabel == null ?
                                  <td>
                                    <div className={classes["gridwidth"]} style={{ margin: "5px" }}>

                                      <div className="btn-group">
                                        {(item.attributes?.length === 1 || (item.attributes?.length === 0)) && (
                                          <a onClick={() => HandleAttributeTier(index, 0, "add")} className="btn btn-outline-dark btn-sm" style={{ width: "80px" }}>
                                            Add Attribute
                                          </a>
                                        )}

                                      </div>
                                    </div>
                                  </td>
                                  :
                                  ''}
                                {exceptionPolicyData.StatusLabel === "New" || exceptionPolicyData.StatusLabel == null ?
                                  <td>
                                    {Service.editPerms(userPerms) && (
                                      <div className={classes["gridwidth"]} style={{ marginTop: "5px" }}>
                                        <div className="btn-group">
                                          {MPGList.length - 1 === index && (
                                            <a
                                              onClick={() => HandleSegmentTier(index, "add")}
                                              className="btn btn-outline-dark btn-sm"
                                              style={{ marginLeft: "5px", width: "105px" }}
                                            >
                                              Add Segment
                                            </a>
                                          )}
                                          {MPGList.length > 1 && index != 0 && (
                                            <a
                                              onClick={() => HandleSegmentTier(index, "remove")}
                                              className="btn btn-outline-dark btn-sm"
                                              style={{ marginLeft: "5px", width: "105px" }}
                                            >
                                              Remove Segment
                                            </a>
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </td>
                                  :
                                  ''}
                              </tr>


                              {item?.attributes?.slice(1).map((innerArray, innerIndex) => {
                                // Get a list of already selected attribute names
                                const selectedAttributes = item?.attributes.slice(0, innerIndex + 1).map(attr => attr.name);

                                // Filter the MappingAttributeValues to exclude already selected options
                                const availableOptions = MappingAttributeValues.filter(
                                  (each) => !selectedAttributes.includes(each.Name)
                                );

                                return (
                                  <tr key={innerIndex + 1}>
                                    <td></td>
                                    <td></td>
                                    <td></td>

                                    <td>
                                      <Form.Control
                                        style={{ width: "100px" }}
                                        className={`${classes["Form-Input-control"]} ${disabledClass}`}
                                        as="select"
                                        value={innerArray.name}
                                        defaultValue={innerArray.name}
                                        name="Attribute"
                                        onChange={(e) => handleattribute(e, innerIndex + 1, index)}
                                        disabled={disableField}
                                      >
                                        {/* <option>Select</option> */}
                                        {availableOptions.map((each) => (
                                          <option key={each.Id} value={each.Name} id={each.Id}>
                                            {each.Name}
                                          </option>
                                        ))}
                                      </Form.Control>
                                    </td>

                                    <td>
                                    <div className="d-flex align-items-center">
                                      <div className="m-1">
                                        <button onClick={() => openvaluepopup(index, innerIndex+1)} className="btn btn-outline-dark btn-sm">{`${t("Select")}`}</button>
                                      </div>
                                      <div className='mx-1'>
                                        <a href='javascript:void(0)' onClick={(e) => mpgValueSelectedPopup(e, index,innerIndex+1)}><p style={{ fontSize: "10px" }}>({MPGList[index].attributes[innerIndex+1].value.length === 0 ? 0 : MPGList[index].attributes[innerIndex+1].value.length })</p></a>
                                      </div>
                                      </div>
                                    </td>
                                    {exceptionPolicyData.StatusLabel === "New" || exceptionPolicyData.StatusLabel == null ?
                                      <>
                                        <td>
                                          <div className={classes["gridwidth"]} style={{ marginTop: "5px" }}>
                                            <div className="btn-group">
                                              {item?.attributes.length - 2 === innerIndex && (
                                                <a
                                                  onClick={() => HandleAttributeTier(index, innerIndex + 1, "add")}
                                                  className="btn btn-outline-dark btn-sm"
                                                  style={{ marginLeft: "5px", width: "105px" }}
                                                >
                                                  Add Attribute
                                                </a>
                                              )}
                                              {item?.attributes.length > 1 && (
                                                <a
                                                  onClick={() => HandleAttributeTier(index, innerIndex + 1, "remove")}
                                                  className="btn btn-outline-dark btn-sm"
                                                  style={{ marginLeft: "5px", width: "105px" }}
                                                >
                                                  Remove Attribute
                                                </a>
                                              )}
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                        </td>
                                      </>
                                      :
                                      ''}
                                  </tr>
                                );
                              })}

                            </React.Fragment>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                :
                null
              }

              {selectedGroupBy === "By Part#" ? (
                <div className="card-body" style={{ minHeight: "0vh" }}>
                  <div className="container" style={{ marginLeft: "0px" }}>
                    <div className="table-responsive">
                      <table className="table table-bordered table-head-fixed text-nowrap table-bordered table-striped" style={{ width: "50vw" }}>
                        <thead className="thead-light">
                          <tr className="text-left">
                            <th>{t("Segment.Segment")}</th>
                            <th>{t("Segment.Part#")}</th>
                            <th>{t("Rebate Value (%)")}</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {PartHashList.map((item, index) => (
                            <tr key={index}>
                              <td>
                              <div className="d-flex align-items-center">
                              <div className="m-1">
                                  <button onClick={(e) => parthashsegmentpopup(e, index, "segment")} className="btn btn-outline-dark btn-sm">{`${t("Select")} ${t("")}`}</button>
                                </div>
                                <div className="mx-1">
                                  <a href='javascript:void(0)' onClick={(e) => partSelectedpopup(e, index, "segment")}><p style={{ fontSize: "10px" }}>({PartHashList[index].segmentId === 0 ? 0 : 1})</p></a>
                                </div>
                                </div>
                              </td>
                              <td>
                              <div className="d-flex align-items-center">
                              <div className="m-1">
                                  <button onClick={() => OpenparthashProduct(index)} className="btn btn-outline-dark btn-sm">{`${t("Select")} ${t("")}`}</button>
                                </div>
                                <div className="mx-1">
                                  <a href='javascript:void(0)' onClick={(e) => partSelectedpopup(e, index, "product")}><p style={{ fontSize: "10px" }}>({PartHashList[index].values.length === 0 ? 0 : PartHashList[index].values.length})</p></a>
                                </div>
                                </div>
                              </td>
                              <td>

                                <Form.Control
                                  className={`${classes["Form-Input-control"]} ${disabledClass}`}
                                  autoComplete="off"
                                  style={{ width: "100px" }}
                                  value={item.rebate}
                                  name="RebateValue"
                                  onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()}
                                  onChange={(e) => parthashsegmentpopup(e, index, "rebate type")}
                                />



                              </td>
                              {(disableField == false) ?
                                <td>
                                  <div className={classes['gridwidth']} hidden={disableField} style={{ marginLeft: "5px", marginTop: "5px" }}>
                                    {Service.editPerms(userPerms) == false ? "" : PartHashList.length - 1 === index ? <a onClick={() => HandlePartHashTier(index, "add")} style={{ marginLeft: "5px" }} className="btn btn-sm btn-update">Add</a> : null}
                                    {Service.editPerms(userPerms) == false ? "" : index !== 0 && PartHashList.length !== 1 ? <a style={{ marginLeft: "5px" }} onClick={() => HandlePartHashTier(index, "remove")} className="btn btn-sm btn-update">Remove</a> : null}

                                  </div>
                                </td>
                                : null}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : null}


              <div style={{ paddingTop: '0px', paddingLeft: '30px', paddingBottom: '10px', display: ExceptionValueList?.length == 0 ? 'block' : 'none' }} hidden={disableField} >
                {ExceptionValueList?.length == 0 ?
                  <button className="btn btn-sm btn-update" onClick={() => add('ADD')} style={{ width: "50px" }}>{t('ExceptionPolicy.ADD')}</button>
                  : ''}
              </div>

              {ExceptionValueList.length > 0 ?
                <div className="card-body" style={{ minHeight: "27vh", paddingTop: "0px" }}>
                  <div className="container">
                    <div className="table-responsive">
                      <table className="table table-bordered table-head-fixed text-nowrap table-bordered table-striped table-responsive-sm" style={{ width: "850px", marginBottom: "170px" }}>
                        <thead className="thead-light">
                          <tr className='text-left'>
                            <th>{t('ExceptionPolicy.Category')}</th>
                            <th>{t('ExceptionPolicy.SubCategory')}</th>
                            <th>{t('ExceptionPolicy.Operator')}</th>
                            <th>{t('ExceptionPolicy.Value')}</th>
                            <th>{t('ExceptionPolicy.And/Or')}</th>
                            {exceptionPolicyData.StatusLabel == 'New' || exceptionPolicyData.StatusLabel == undefined ? <th>{t('')}</th> : ''}
                          </tr>
                        </thead>
                        <tbody>
                          {console.log('24-1', ExceptionValueList.length)}
                          {console.log('24-1', ExceptionValueList)}
                          {
                            ExceptionValueList.map((item, index) => (
                              <tr >
                                <td>

                                  <button className="btn  btn-update btn-sm ml-2 mt-2" onClick={(event) => ClickValueCategoryPopup(index)} style={{ width: "50px" }}>{t('Select')}</button>
                                  <span style={{ marginLeft: "20px" }}><a><label style={{ paddingTop: "5px" }}>({item.Category?.length == 0 ? 0 : 1})</label></a></span>
                                </td>
                                <td>

                                  <button className="btn  btn-update btn-sm ml-2" onClick={(event) => ClickValueSubCategoryPopup(item.SubCategory, index)} style={{ width: "50px" }}>{t('Select')}</button>
                                  <span style={{ marginLeft: "20px" }}><a><label style={{ paddingTop: "5px" }}>({item.SelectedItemSubCategory?.length == 0 ? 0 : 1})</label></a></span>
                                </td>
                                <td>

                                  <Form.Control style={{ width: "100px" }}
                                    onChange={(event) => OnOperatorChange(event.target.value, index)}
                                    className={`${classes['Form-Input-control']}`} as="select" disabled value={item.Operator = 'Equals'} name="Operator"
                                  >
                                    <option>{t('Select')}</option>
                                    <option>Equals</option>
                                    <option>Does not equal</option>
                                  </Form.Control>
                                </td>
                                <td>
                                  <button className="btn  btn-update btn-sm ml-2" onClick={(event) => ClickValuePopup(item, index)} style={{ width: "50px" }}>{t('Select')}</button>
                                  <span style={{ marginLeft: "20px" }}><a href='javascript:void(0)' onClick={(event) => ClickValueCountPopup(item, index)}>
                                    <label style={{ paddingTop: "5px" }}>({(item.ValueListString?.length)})</label></a></span>
                                </td>
                                <td>


                                  <div className={classes['gridwidth']} style={{ margin: "5px" }}>
                                    <Form.Control className={`${classes['Form-Input-control']}`} as="select" disabled={disableField} autoComplete="off" style={{ width: "80px", margin: "auto" }} value={item.Condition}
                                      onChange={(event) => OnConditionChange(event.target.value, index)} >
                                      <option value="AND">{t('ExceptionPolicy.And')}</option>
                                      <option value="OR">{t('ExceptionPolicy.OR')}</option>
                                    </Form.Control>
                                  </div>

                                </td>

                                <td>
                                  <div className={classes['gridwidth']} disabled={disableField} style={{ margin: "5px" }} hidden={disableField}>
                                    {ExceptionValueList.length - 1 === index ? <a onClick={() => AddVolumeCat(index)} className="btn btn-sm btn-update">{t('ExceptionPolicy.ADD')}</a> : null}
                                    {ExceptionValueList.length !== 0 ? <a style={{ marginLeft: "5px" }} onClick={() => RemoveVolumeCat(index)} className="btn btn-sm btn-update">{t('Remove')}</a> : null}
                                  </div>
                                </td>

                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>

                  </div>
                </div>
                :
                ''
              }
              </div>
              {/* <div style={{ float: "right", "margin-top": "5px", "margin-right": "1px" }}>
                <label className="mb-3" htmlFor="fileInput" style={{ marginRight: "10px" }}>
                  <span>
                    {selectedFile ? `Selected file: ${selectedFile.name}` : 'No file selected'}
                  </span>
                </label>
                <input
                  type="file"
                  id="fileInput"
                  accept=".csv"
                  onChange={ImportCSVData}
                  style={{ display: 'none' }}
                />
                <button
                  className="btn btn-update btn-sm mt-1 ml-1 mr-3 mb-3"
                  style={{
                    width: "6.25rem",
                    height: "1.375rem",
                  }}
                  onClick={getFileData}
                >
                  Import File
                </button>
              </div> */}


              {/* <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableA">
                <thead className="text-white rt-thead" style={{ backgroundColor: "var(--primary)" }}>
                  <tr>
                    <th style={{ width: '70px' }}><input type="checkbox" /> S No</th>
                    <th style={{ width: '250px' }} >
                      <div className='d-flex text-nowrap'>
                        <span>Material Pricing Group</span>
                      </div>
                    </th>
                    <th style={{ width: '250px' }} >
                      <div className='d-flex text-nowrap'>
                        <span>Category</span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    (jsonList.length > 0 && !loading) && jsonList.map((policyData, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td style={{ whiteSpace: "nowrap" }} >{policyData["MaterialPricingGroup"]}</td>
                        <td style={{ whiteSpace: "nowrap" }} >{policyData["Category"]}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table> */}
            </div>
          </div>
        </div>


        {
          showExceptionWorkflow === true ?
            <div id="mySidepanel" className="sidepanel sidepanelborder" style={{ width: sidebarwidth }}>

              {showWorkflow === false ?
                <div className="container" id="workflow">
                  <div className="row mt-3">
                    <div className="col-lg-12 pr-lg-2">
                      <div className="card text-center card_border">
                        <div className="card-header chart-grid__header">
                          <div className="row">
                            <div className="col-md-11 text-left">
                              {t('Workflow')}
                            </div>
                            <div className="ml-4">
                              <button className="btn btn-outline-dark  btn-sm" onClick={clickCloseAccuralWorkflow} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                            </div>
                          </div>
                        </div>

                        <div className="card-body" style={{ minHeight: "55vh" }}>


                          {(workflowchart.length > 0 && exceptionPolicyData.IncludeWorkflow) ?
                            <div className='col-md-12'>
                              <table className="table table-responsive-sm">
                                <thead>
                                  <tr>
                                    <th className='workflowchart-levelwidth workflowchart-font'>Level</th>
                                    <th className='font-center workflowchart-font'>Users</th>
                                    <th className='font-center workflowchart-font'>Approval</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {
                                    workflowchart.map((i) =>
                                      <tr key={i.Id}>
                                        <td className='workflowchart-font square-levels'>{i.Levels}</td>
                                        <td className='font-center'>
                                          <ul >
                                            {
                                              i.lstworkflowapprover.map((j, index) => {
                                                return <>

                                                  {
                                                    index < 2 ?
                                                      j.IsApproved === true ?
                                                        <li className='workflowchartperson'>
                                                          <span className='workflowtpersoncolor'><i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                                            <br></br> {j.FullName}</span>
                                                        </li>
                                                        :
                                                        <>
                                                          <li className='workflowchartperson'>
                                                            <i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                                            <br></br> {j.FullName}
                                                          </li>
                                                        </>
                                                      :
                                                      ''
                                                  }
                                                </>

                                              })
                                            }

                                            {
                                              i.lstworkflowapprover.length > 2 ?
                                                <li className='workflowchartperson tooltip_workflow'>
                                                  <i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                                  <br></br> {t('MoreUsers')}
                                                  <span class="tooltiptext">

                                                    {
                                                      i.lstworkflowapprover.map((k, index) => {
                                                        return <span>
                                                          {
                                                            index > 1 ? <><i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                                              &nbsp; {k.FullName}
                                                              <hr></hr></> : null
                                                          }


                                                        </span>
                                                      })
                                                    }

                                                  </span>
                                                </li>
                                                :
                                                ''
                                            }


                                          </ul>

                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                          {
                                            i.IsApproved === true ?
                                              <span className='workflowrightcolor'>
                                                <i class="fa fa-check" aria-hidden="true"></i>
                                              </span>

                                              : i.IsDenied == true ?
                                                <span className='workflowrightcolor'>
                                                  <i class="fa fa-times" aria-hidden="true" style={{ color: "red" }}></i>
                                                </span>
                                                : ''
                                          }
                                        </td>
                                      </tr>)

                                  }
                                </tbody>

                              </table>

                            </div>
                            :
                            <div className={classes.flow}>

                            </div>
                          }


                          <div className="row">
                            <div className="col-md-12">
                              <table className="table table-bordered text-left mt-2 table-responsive-sm">
                                <thead className='text-white' style={{ backgroundColor: "var(--primary)" }}>
                                  <tr>

                                    <th>{t('Rebates.Event')}</th>
                                    <th>{t('Rebates.CreatedDate')}</th>
                                    <th>{t('Rebates.User')}</th>
                                    <th>{t('Rebates.Comments')}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    showspinnerworkflow === true ?

                                      <>
                                        <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                                        <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                                        <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                                      </>
                                      :
                                      workflowData.length === 0 ?
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td>
                                            {t('NoDataAvailable')}
                                          </td> <td></td></tr>
                                        :
                                        woData === '' || woData === 'undefined' ?
                                          <tr></tr> :
                                          workflowData.map((i) =>
                                            <tr key={i.id}>
                                              <td>{i.EventName}</td>
                                              {i.CreatedDate === null ? <td></td> : <td>{`${moment(i.CreatedDate).format(dateFormat)} ${moment(i.CreatedDate).format(timeFormat)}`}</td>}
                                              <td>{i.CreateUserName}</td>
                                              <td>{i.Comments}</td>
                                            </tr>)

                                  }

                                </tbody>
                              </table>
                            </div>

                          </div>


                        </div>

                      </div>
                    </div>

                  </div>
                </div>
                :
                ''
              }

            </div>
            :
            ""
        }



        {
          showmpgPopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, marginRight: "10px", padding: "0" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          {loadPopup != 'mpg' ?
                          <div className="col-md-10 text-left">
                            {loadPopup ? loadPopup.charAt(0).toUpperCase() + loadPopup.slice(1).toLowerCase() : ''}       
                          </div>
                          :
                          <div className="col-md-10 text-left">
                             {t('Material Pricing Group')}
                            </div>
                          }
                          <div className="col-md-2">
                            <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => closempgpopup('close')} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                            <button className='btn btn-outline-dark  btn-sm ml-1' onClick={() => closempgpopup("ok")} style={{ width: "35px" }}>{t('Add')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                      </div>
                      <div className='scrollbar-class'>
                        <div className="container">
                          <div>
                          </div>
                          <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableregion">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                <th >
                                </th>
                                {loadPopup != 'mpg' ?
                                <th id="Product">
                                  {loadPopup ? loadPopup.charAt(0).toUpperCase() + loadPopup.slice(1).toLowerCase() : ''}
                                </th>
                                :
                                <th id="Product">
                                  {t('Material Pricing Group')}
                                </th>
                               } 
                              </tr>
                            </thead>
                            <tbody>
                              {loadPopup === 'segment' ? (
                                variedSegmentValue.map((rebateType) => (
                                  <tr key={rebateType.Id} >
                                    <td style={{ width: '40px' }}>
                                      <input
                                        type="checkbox"
                                        disabled={
                                          disableField ||
                                          (MPGList[selectedIndex.segmentIndex]?.segmentId &&
                                            MPGList[selectedIndex.segmentIndex]?.segmentId !== rebateType.Id)
                                        }
                                        checked={MPGList[selectedIndex.segmentIndex]?.segmentId === rebateType.Id}
                                        name="rebateType-check"
                                        value={rebateType.Id}
                                        id={rebateType.Id}
                                        onChange={(event) => handlempgChanges(event, rebateType.Name, rebateType.Id, "segment")}
                                      />
                                    </td>
                                    <td>
                                      <label>{rebateType.label}</label>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                variedSubcategoryValues.filter((each) => each?.hidden === false).map((rebateType) => (
                                  <tr key={rebateType.Id}>
                                    <td>
                                      <input
                                        type="checkbox"
                                        disabled={
                                          disableField
                                        }
                                        checked={rebateType.selected}
                                        name="rebateType-check"
                                        value={rebateType.Id}
                                        id={rebateType.Id}
                                        onChange={(event) => handlempgChanges(event, rebateType.Name, rebateType.Id, "mpg")}
                                      />
                                    </td>
                                    <td>
                                      <label>{rebateType.label}</label>
                                    </td>
                                  </tr>
                                ))
                              )}
                            </tbody>

                          </table>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
            :
            ""
        }
        {
          showSelectedmpgPopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, marginRight: "10px", padding: "0" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                         {loadPopup != 'mpg' ?
                          <div className="col-md-10 text-left">
                            {loadPopup ? loadPopup.charAt(0).toUpperCase() + loadPopup.slice(1).toLowerCase() : ''}
                          </div>
                          :
                          <div className="col-md-10 text-left">
                            {t('Material Pricing Group')}
                          </div>
                         }
                          <div className="col-md-2">
                            <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => closeSelectedMpgPopup('close')} type="submit" style={{ width: "45px" }}>{t('Close')}</button>

                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                      </div>
                      <div className='scrollbar-class'>
                        <div className="container">
                          <div>
                          </div>
                          <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableregion">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                              {loadPopup != 'mpg' ?
                                <th id="Product">
                                  {loadPopup ? loadPopup.charAt(0).toUpperCase() + loadPopup.slice(1).toLowerCase() : ''}
                                </th>
                                :
                                <th id="Product">
                                  {t('Material Pricing Group')}
                                </th>
                              }
                              </tr>
                            </thead>
                            <tbody>
                              {loadPopup !== "segment" ?
                                selectedMPG.map((selectedValue, index) => (
                                  <tr>
                                    <td key={index}>

                                      {selectedValue.label}
                                    </td>
                                  </tr>
                                ))
                                :
                                <tr>
                                  <td>
                                    {selectedMPG.label}
                                  </td>
                                </tr>}


                            </tbody>

                          </table>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
            :
            ""
        }
        {
          showSelectedPartPopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, marginRight: "10px", padding: "0" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-10 text-left">
                            {loadPopup ? loadPopup.charAt(0).toUpperCase() + loadPopup.slice(1).toLowerCase() : ''}
                          </div>
                          <div className="col-md-2">
                            <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => closeSelectedPartPopup('close')} type="submit" style={{ width: "45px" }}>{t('Close')}</button>

                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                      </div>
                      <div className='scrollbar-class'>
                        <div className="container">
                          <div>
                          </div>
                          <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableregion">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                {/*<th id="Product">
                                 {loadPopup ? loadPopup.charAt(0).toUpperCase() + loadPopup.slice(1).toLowerCase() : ''}
                                 </th>*/}
                                {loadPopup === "product" && (
                                  <>
                                    <th>{t("Material No")}</th>
                                    <th>{t("Product")}</th>
                                    <th>{t('OEM')}</th>
                                    <th>{t("Vehicle Type")}</th>
                                  </>
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {loadPopup === "product" ? (
                                selectedParts.map((selectedValue, index) => (
                                  <tr key={index}>
                                    {/* <td></td> */}
                                    <td>{selectedValue.Name}</td>
                                    <td>{selectedValue.Label}</td>
                                    <td>{selectedValue.Oem}</td>
                                    <td>{selectedValue.Vehicletype}</td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td>{selectedParts.label}</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
            :
            ""
        }
        {
          showparthashSegmentPopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, marginRight: "10px", padding: "0" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-10 text-left">
                            {t('Segment.Segment')}
                          </div>
                          <div className="col-md-2">
                            <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => closeparthashsegmentpopup('close')} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                            <button className='btn btn-outline-dark  btn-sm ml-1' onClick={() => closeparthashsegmentpopup("ok")} style={{ width: "35px" }}>{t('Add')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                      </div>
                      <div className='scrollbar-class'>
                        <div className="container">
                          <div>
                          </div>
                          <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableregion">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                <th >
                                </th>
                                <th id="Product">
                                  {t('Segment.Segment')}
                                </th>
                              </tr>
                            </thead>
                            <tbody>

                              {segmentValues.map((rebateType) => (
                                <tr key={rebateType.Id} >
                                  <td style={{ width: '40px' }}>
                                    <input
                                      type="checkbox"
                                      disabled={
                                        disableField ||
                                        (PartHashList[selectedParthashIndex]?.segmentId &&
                                          PartHashList[selectedParthashIndex]?.segmentId !== rebateType.Id)
                                      }
                                      checked={rebateType.selected}
                                      name="segmentId"
                                      value={rebateType.Id}
                                      id={rebateType.Id}
                                      onChange={(event) => handlepartHashselection(event, rebateType.Name, rebateType.Id, "segment")}
                                    />
                                  </td>
                                  <td>
                                    <label>{rebateType.label}</label>
                                  </td>
                                </tr>
                              ))
                              }

                            </tbody>

                          </table>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
            :
            ""
        }




        {
          showValuePopup === true ?
            < div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: "60vh" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="text-left">
                            {loadValue + " Values"}
                          </div>
                          <div className="d-flex align-items-center">
                            <div style={{ paddingTop: '1px', paddingLeft: '33px', paddingBottom: '6px', backgroundColor: "#fff" }}>
                              <button className='btn btn-outline-dark btn-sm ml-1' onClick={() => handleValueClose()} style={{ width: "80px", height: "20px" }}>{t('Save&Close')}</button>
                            </div>
                            <button className="btn btn-outline-dark btn-sm ml-1" onClick={() => handleValueClose()} type="submit" style={{ width: "50px", height: "20px" }}>{t('Cancel')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                      </div>
                      <div style={{ minHeight: "60vh" }}>
                        <div className="container">
                          <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableregion">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                <th id="Product">
                                  {loadValue + " Values"}
                                </th>
                              </tr>
                            </thead>
                          </table>

                          <div className='d-flex flex-wrap' id="div_customer">
                            {loadValue === 'Vehicle Type' ? (
                              OemValues.length > 0 && (
                                <table className="table table-bordered text-left mt-2">
                                  <tbody>
                                    {VehicleTypeValues.map((each) => (
                                      <tr key={each.Id}>
                                        <td style={{ width: "40px" }}>
                                          <input
                                            type="checkbox"
                                            disabled={disableField}
                                            checked={each.selected}
                                            name="value"
                                            value={each.Id}
                                            id={each.Id}
                                            onChange={(event) => handlempgvalueChanges(event, each.Name, each.Id, "Vehicle Type")}
                                          />
                                        </td>
                                        <td>
                                          <label htmlFor={each.Id}>{each.Name}</label>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              )
                            )
                              :
                              (
                                OemValues.length > 0 && (
                                  <table className="table table-bordered text-left mt-2">
                                    <tbody>
                                      {OemValues.map((each) => (
                                        <tr key={each.Id}>
                                          <td style={{ width: "40px" }}>
                                            <input
                                              type="checkbox"
                                              disabled={disableField}
                                              checked={each.selected}
                                              name="value"
                                              value={each.Id}
                                              id={each.Id}
                                              onChange={(event) => handlempgvalueChanges(event, each.Name, each.Id, "OEM")}
                                            />
                                          </td>
                                          <td>
                                            <label htmlFor={each.Id}>{each.Name}</label>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                )
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            :
            ""

        }

        {
          showProductPopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-11 text-left" style={{ marginLeft: "-5px" }}>
                            {t('Rebates.ProductListing')}
                          </div>

                          <div className="ml-3">
                            <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => ClickValuePopupClose("Close", "Product", "All")} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-1">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border">
                          <div className="card-header chart-grid__header">
                          </div>
                          <div style={{ minHeight: "66vh" }}>
                            <div className="container">
                              <div className='col-md-14' >
                                {clickProduct === true ?
                                  <ProductTreeDialog
                                    show={showModal}
                                    onOkClick={UpdateProducts}
                                    onHide={() => CloseProductPicker}
                                    dataByHeirarchy={dualListBoxOptionsByHeirarchy}
                                    rebatelinestatus={rebatelinestatus}
                                    dialogType="Product"
                                    SelectedData={FilteredProducts}
                                    selectedType={selectedType}
                                    dualListBoxOptionsByAttribute={dualListBoxOptionsByAttribute.filter((each) => each?.Hidden === false)}
                                  >
                                    <div className={classes['Radiobutton-header']}>
                                      <div className={classes['Radiobutton-internal-div']} >
                                        <input type="radio" checked={selectedType === 'Attribute'}
                                          value="Attribute" onChange={SelectedProductTypeHandler}
                                          name="product" className={classes['radio-button-Right form-control']} /> {t('Rebates.Attribute')}
                                        <input type="radio" checked={selectedType === 'Heirarchy'}
                                          onChange={SelectedProductTypeHandler}
                                          className={classes['radio-button-Left']} value="Heirarchy" name="product" /> {t('Rebates.Hierarchy')}

                                      </div>
                                    </div>
                                  </ProductTreeDialog>
                                  : ''
                                }

                              </div>

                            </div>




                          </div>

                        </div>
                      </div>

                    </div>
                  </div>
                </div>


              </div>
            </div>
            :
            ""
        }

        {
          showCategoryPopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-12 text-left">
                            {t('Policies.AttributeListing')}


                            <button className="btn btn-sm btn-update ml-1" onClick={() => ClickValuePopupCancel()} type="submit" style={{ float: "right", width: "50px" }}>{t('Cancel')} </button>
                            <button className="btn btn-sm btn-update ml-1" onClick={(event) => ClickValuePopupClose('Close', keyValue, "All")} type="submit" style={{ float: "right", width: "40px" }}>Add</button>

                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="row mt-1">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border">
                          <div className="card-header chart-grid__header">
                          </div>
                          <div className='scrollbar-class'>
                            <div className="container">
                              <div style={{ paddingTop: "5px" }}>
                                <input type="text" className='form-control form-control-user' placeholder={`${t('SearchFor')} ${"Categories"}`} onChange={(event) => attributeSearch(event)} />
                              </div>
                              <div className='col-md-12'>
                                <table className="table table-bordered text-left mt-2 table-responsive-sm " id="tableA">
                                  <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                    <tr>
                                      <th className="tableheader"><input checked={attbCheckAll} type="checkbox" name="attribute-check" id="option-all-customer" onChange={checkAllAttribute} /><label></label></th>
                                      <th id="Attribute1" >
                                        {keyValue}
                                      </th>
                                    </tr>
                                  </thead>
                                  {keyValue === "Category" ?
                                    <tbody>
                                      {AllCategoryValues.map((i, index) => (
                                        <tr>
                                          <td><input type="checkbox" checked={i.selected == null || i.selected == false ? false : true} value={i.value} id={i.Id} onChange={(event) => OnChangeHandlerAttribute(event, i.label, i.value, "Category")} disabled={disableField}></input></td>
                                          <td>
                                            {i.label}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                    : ""}
                                  {keyValue === "SubCategory" ?
                                    <tbody>
                                      {AllSubCategoryValues.map((i, index) => (
                                        <tr>
                                          <td><input type="checkbox" checked={i.selected == null || i.selected == false ? false : true} value={i.value} id={i.Id} onChange={(event) => OnChangeHandlerAttribute(event, i.label, i.value, "SubCategory")} disabled={disableField}></input></td>
                                          <td>
                                            {i.label}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                    : ""}
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            :
            ""
        }


        {

          showCustomerPopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, marginRight: "10px", padding: "0" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-12 text-left">
                            {t('Rebates.CustomerListing')}
                            <button className="btn btn-sm btn-update ml-1" onClick={() => ClickValuePopupClose("Close", "Customer", "All")} type="submit" style={{ float: "right", width: "50px" }}>{t('Cancel')}</button>
                            <button style={{ float: "right", width: "80px" }} className='btn btn-sm btn-update ml-1' onClick={okCustomer} >{t('Save&Close')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1" id="Customer">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">

                      </div>

                      <div className='scrollbar-class'>

                        <div className="container">
                          <div>

                            <input type="text" className='form-control form-control-user' value={customerSearch} onChange={(event) => SearchCustomer(event)} placeholder={`${t('Search')} ${t('Rebates.Customer')}`} />
                          </div>
                          <table className="table table-bordered text-left mt-2 table-responsive-sm" id="Customer">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                <th>
                                  <input checked={customercheckAll} type="checkbox" disabled={disableField} id="option-all-customer" onChange={checkAllCustomer} /><label></label>
                                </th>
                                <th id="Name" style={{ backgroundColor: (filterValueCustomer.Name ? '#6cae55' : ''), width: "110px" }}>{t('Rebates.CustomerCode')} <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnCustomer('Name')}></span></th>
                                <th id="Label" style={{ backgroundColor: (filterValueCustomer.Label ? '#6cae55' : ''), maxWidth: "110px" }}>{t('Rebates.CustomerName')} <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnCustomer('Label')}></span></th>
                                <th id="Station" style={{ backgroundColor: (filterValueCustomer.Station ? '#6cae55' : ''), maxWidth: "110px" }}>{t('Rebates.Station')}	 <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnCustomer('Station')}></span></th>
                                <th id="State" style={{ backgroundColor: (filterValueCustomer.State ? '#6cae55' : ''), maxWidth: "110px" }}>{t('Rebates.State')}	 <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnCustomer('State')}></span></th>
                                <th id="Zone" style={{ backgroundColor: (filterValueCustomer.Zone ? '#6cae55' : ''), maxWidth: "110px" }}>{t('Rebates.Zone')}  <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnCustomer('Zone')}></span></th>
                              </tr>
                            </thead>
                            <thead>
                              {
                                CustomerValues.map((cslist, index) => (

                                  <tr onClick={(event) => handleCustSelection(event, cslist.label, cslist.Id, cslist.State)}>
                                    <td style={{ width: "30px" }}>

                                      {disableField == true ? <>
                                        <input type="checkbox"
                                          disabled={true}
                                          className={disabledClass}
                                          checked={cslist.Selected == null || cslist.Selected == false ? false : true}
                                          name="customer-check" value={cslist.Id} id={cslist.Id} onChange={(event) => handleCustomerChanges(event, cslist.label, cslist.Id, cslist.State)} />

                                      </> : <>
                                        <input type="checkbox"
                                          className={disabledClass}
                                          checked={cslist.Selected == null || cslist.Selected == false ? false : true} name="customer-check" value={cslist.Id} id={cslist.Id} onChange={(event) => handleCustomerChanges(event, cslist.label, cslist.Id, cslist.State)} />

                                      </>

                                      }</td>
                                    <td style={{ width: "90px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.Name}>{cslist.Name}</div>


                                    </td>
                                    <td style={{ width: "260px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.label}>{cslist.label}</div>


                                    </td>
                                    <td style={{ width: "100px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.Station}>{cslist.Station}</div>

                                    </td>
                                    <td style={{ width: "100px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.State}>{cslist.State}</div>

                                    </td>
                                    <td style={{ width: "100px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.Zone}>{cslist.Zone}</div>

                                    </td>
                                  </tr>
                                ))
                              }
                            </thead>
                          </table>


                        </div>

                      </div>
                    </div>
                  </div>

                </div>

              </div>
              {
                showCustomerfilterpopup === true ?
                  <div className='pop-up' style={{ left: divleft, top: divtop, width: "200px" }}>

                    {custFilterCol === 'Name' || custFilterCol === 'Label' || custFilterCol === 'Station' || custFilterCol === 'State' || custFilterCol === 'Zone' ?


                      <>
                        <div className="container">
                          <div className="row">
                            <b className="forgettext col-md-11 textbox-padding ml-2">{filtertextCust}</b>

                          </div>

                        </div>

                        <div className="container mt-2">
                          <div className="row">
                            <input type="text" placeholder="Search" value={filterSearchCust} onChange={(event) => UserfilterHandlerCustomer(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2 form-control' />
                          </div>

                          <div className="row contentoverflow mt-2">
                            <table className="filtertable table-responsive-sm">
                              <tbody>
                                <tr>
                                  <td><input type="checkbox" name="filter-option-all" id="option-all" onChange={checkAllFilterCustomer} /></td>
                                  <td>Select All</td>
                                </tr>
                                {
                                  filterCusDropDownData.map((item, index) => (

                                    <tr key={index}>

                                      <td><input id={item.Id} data-index="1" name="filter-option-all" checked={item.Selected} type="checkbox" onChange={(event) => OnCusdropdownChange(event, item.Id, item.Name)} /></td>

                                      <td>{item.Name}</td>

                                    </tr>


                                  ))
                                }

                              </tbody>
                            </table>

                          </div>
                        </div>
                      </>
                      :
                      <></>

                    }
                    <div style={{ paddingTop: '10px', paddingLeft: '33px', paddingBottom: '7px' }}>

                      <button className="btn btn-sm btn-update" style={{ width: "45px" }} onClick={() => CustFilterHandler('Apply')}>{t('Apply')}</button>
                      <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "45px" }} onClick={() => CustFilterHandler('Reset')}>{t('Reset')}</button>
                    </div>
                    <div>
                    </div>
                  </div>
                  :
                  <></>
              }
            </div>
            :
            ""
        }


        {
          showCustomerGroupPopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, marginRight: "10px", padding: "0" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-12 text-left">
                            {t('Rebates.GroupCustomerListing')}

                            <button className="btn btn-sm btn-update ml-1" onClick={() => ClickValuePopupClose("Close", "CustomerGroup", "All")} type="submit" style={{ float: "right", width: "50px" }}>{t('Cancel')}</button>
                            <button style={{ float: "right", width: "80px" }} className='btn btn-sm btn-update ml-1' onClick={okCustomerGroup}>{t('Save&Close')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1" id="CustomerGroup">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">

                      </div>

                      <div className='scrollbar-class'>

                        <div className="container">
                          <div>
                            <input type="text" className='form-control form-control-user' value={customerGroupSearch} onChange={(event) => SearchGroupCustomer(event)} placeholder={`${t('Search')} ${t('Rebates.GroupCustomer')}`} />
                          </div>
                          <table className="table table-bordered text-left mt-2 table-responsive-sm" id="CustomerGroup">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                <th>
                                  <input checked={customercheckAll} disabled={disableField} type="checkbox" id="option-all-customer" onChange={checkAllGroupCustomer} /><label></label>
                                </th>

                                <th id="GroupLabel" style={{ backgroundColor: (filterValueGroupCustomer.label ? '#6cae55' : ''), maxWidth: "110px" }}>{`${t('Rebates.CustomerGroup')} ${t('Policies.Label')}`}<span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnGroupCustomer('label')}></span></th>
                              </tr>
                            </thead>
                            <thead>
                              {
                                CustomerGroupValues.map((cslist, index) => (

                                  <tr>
                                    <td style={{ width: "30px" }}>


                                      <input type="checkbox"
                                        disabled={disableField}

                                        checked={cslist.Selected == null || cslist.Selected == false ? false : true}
                                        name="customer-check" value={cslist.Id} id={cslist.Id} onChange={(event) => OnChangeHandlerGroupCustomer(event, cslist.label, cslist.Id, cslist.State)} />

                                    </td>


                                    <td style={{ width: "260px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.label}>{cslist.label}</div>
                                    </td>

                                  </tr>

                                ))
                              }


                            </thead>
                          </table>
                        </div>

                      </div>
                    </div>
                  </div>
                  {
                    showCustomerGroupfilterpopup === true ?
                      <div className='pop-up' style={{ left: divleft, top: divtop, width: "200px" }}>

                        {custGroupFilterCol === 'label' ?
                          <>
                            <div className="container">
                              <div className="row">
                                <b className="forgettext col-md-11 textbox-padding ml-2">{filtertextCustGroup}</b>

                              </div>

                            </div>

                            <div className="container mt-2">
                              <div className="row">
                                <input type="text" placeholder="Search" value={filterSearchCustGroup} onChange={(event) => UserfilterHandlerCustomerGroup(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                              </div>

                              <div className="row contentoverflow mt-2">
                                <table className="filtertable table-responsive-sm">
                                  <tbody>
                                    <tr>
                                      <td><input type="checkbox" name="filter-option-all" id="option-all" onChange={checkAllFilterCustomerGroup} /></td>
                                      <td>Select All</td>
                                    </tr>
                                    {
                                      CustomerGroupValues.map((item, index) => (

                                        <tr key={index}>

                                          <td><input id={item.Id + item.label} data-index="1" name="filter-option-all" checked={item.Selected} type="checkbox" onChange={(event) => OnCustGroupdropdownChange(event, item.Id, item.label)} /></td>

                                          <td>{item.label}</td>

                                        </tr>


                                      ))
                                    }

                                  </tbody>
                                </table>

                              </div>
                            </div>
                          </>
                          :
                          <></>

                        }
                        <div style={{ paddingTop: '10px', paddingLeft: '33px', paddingBottom: '7px' }}>

                          <button className="btn btn-sm btn-update" style={{ width: "45px" }} onClick={() => filterHandlerCustomerGroup('Apply')}>{t('Apply')}</button>
                          <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "45px" }} onClick={() => filterHandlerCustomerGroup('Reset')}>{t('Reset')}</button>
                        </div>
                        <div>
                        </div>
                      </div>
                      :
                      <></>
                  }
                </div>
              </div>
            </div>
            :
            ""
        }

        {
          showSetlFreqPopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: "72vh" }}
            >
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-12 text-left">
                            {t('MassAction.SettlementsFreq')}

                            <button className="btn btn-sm btn-update ml-1" onClick={() => ClickValuePopupClose("Close", "SettlementFreq", "All")} type="submit" style={{ float: "right", width: "45px" }}>{t('Close')}</button>
                            <button className='btn btn-sm btn-update ml-1' onClick={oksettlFreq} style={{ float: "right", width: "35px" }}>{t('Add')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">

                      </div>
                      <div style={{ minHeight: "58vh" }}>
                        <div className="container">
                          <table className="table table-bordered text-left mt-2 table-responsive-sm " id="tableregion">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                <th style={{ width: "20px" }}>
                                  <input checked={settlFreqcheckAll} disabled={disableField} onChange={checkAllSettlFreqChange} type="checkbox" name="option-all-region" />
                                </th>

                                <th id="Product">
                                  {t('MassAction.SettlementsFreq')}
                                </th>

                              </tr>
                            </thead>
                          </table>
                          < div className='d-flex flex-wrap' style={{ "margin-left": "-10px" }} id="div_customer">
                            {
                              settlFreqValues.map((settlFreq) =>
                                <div className='col-md-3' style={{ padding: "5px;" }}>
                                  <input type="checkbox"
                                    disabled={disableField}

                                    checked={settlFreq.Selected}
                                    name="settlfreq-check" value={settlFreq.Id} id={settlFreq.Id} onChange={(event) => handleSettlFreqChanges(event, settlFreq.label, settlFreq.Id)} />
                                  &nbsp;<label>{settlFreq.label}</label>
                                </div>
                              )
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            :
            ""
        }

        {
          showRebateTypePopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: "72vh" }}
            >
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-12 text-left">
                            {t('MassAction.RebateType')}

                            <button className="btn btn-sm btn-update ml-1" onClick={() => ClickValuePopupClose("Close", "RebateType", "All")} type="submit" style={{ float: "right", width: "45px" }}>{t('Close')}</button>
                            <button className='btn btn-sm btn-update ml-1' onClick={okRebateType} style={{ float: "right", width: "35px" }}>{t('Add')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">

                      </div>
                      <div style={{ minHeight: "58vh" }}>
                        <div className="container">
                          <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableregion">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>


                              </tr>
                            </thead>
                          </table>
                          < div className='row' id="div_customer">
                            {
                              rebateTypValues.map((rebateType) =>
                                <div className='col-md-3' style={{ padding: "5px;" }}>
                                  <input type="checkbox"
                                    disabled={disableField}

                                    checked={rebateType.Selected}
                                    name="rebateType-check" value={rebateType.Id} id={rebateType.Id} onChange={(event) => handleRebateTypeChanges(event, rebateType.label, rebateType.Id)} />
                                  &nbsp;<label>{rebateType.label}</label>
                                </div>
                              )
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            :
            ""
        }

        {
          showSelectedProductpopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-11 text-left" style={{ marginLeft: "-5px" }}>
                            {t('Rebates.ProductListing')}
                          </div>
                          <div className="ml-3">
                            <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => ClickValuePopupClose("Close", "Product", "Selected")} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border">
                          <div className="card-header chart-grid__header">

                          </div>

                          <div className='scrollbar-class'>

                            <div className="container">
                              <div className='col-md-14'>

                                <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableA">
                                  <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                    <tr>
                                      <th className="tableheader">

                                      </th>
                                      <th id="ProductId" >
                                        {t('Rebates.MaterialNo')}
                                      </th>
                                      <th id="Product" >
                                        {t('Rebates.ItemName')}
                                      </th>
                                      <th id="Oem" >
                                        Oem
                                      </th>
                                      <th id="Vehicletype" >
                                        Vehicle Type
                                      </th>



                                    </tr>
                                  </thead>
                                  <tbody>
                                    {dualListBoxOptionsByAttribute.filter((e) => e.Selected == true).map((listVal) =>
                                      <tr key={listVal.Id} >

                                        <td style={{ width: "30px" }}>

                                          {

                                            <input id={listVal.Id} disabled={true} checked={listVal.Selected} type="checkbox" name="chkBoxproduct" value={listVal.Id} />

                                          }

                                        </td>
                                        <td style={{ width: "100px" }}>
                                          <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={listVal.Name}>{listVal.Name}</div>


                                        </td>
                                        <td style={{ width: "200px" }}>
                                          <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={listVal.Label}>{listVal.Label}</div>


                                        </td>
                                        <td style={{ width: "100px" }}>
                                          <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={listVal.Oem}>{listVal.Oem}</div>

                                        </td>
                                        <td style={{ width: "100px" }}>

                                          <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={listVal.Vehicletype}>{listVal.Vehicletype}</div>

                                        </td>

                                      </tr>
                                    )}
                                  </tbody>
                                </table>


                              </div>
                            </div>
                          </div>

                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            :
            ""
        }

        {
          showSelectedCategorypopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-12 text-left">
                            {t('Policies.AttributeListing')}
                          </div>
                          <div className="col-md-2">
                            <button className="btn btn-outline-dark  btn-sm ml-1" onClick={(event) => ClickValuePopupClose('Close', countKeyValue, "Selected")} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border">
                          <div className="card-header chart-grid__header">
                          </div>
                          <div className='scrollbar-class'>
                            <div className="container">
                              <div className='col-md-12'>
                                <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableA">
                                  <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                    <tr>
                                      <th className="tableheader"><input type="checkbox" disabled="true" ></input></th>
                                      <th id="Attribute1" >
                                        {countKeyValue}
                                      </th>
                                    </tr>
                                  </thead>
                                  {countKeyValue === "Category1" ?
                                    <tbody>
                                      {attribute1Values.filter((e) => e.Selected === true).map((i, index) => (
                                        <tr>
                                          <td>
                                            <input type="checkbox"
                                              checked={i.Selected == null || i.Selected == false ? false : true} name="customer-check" value={i.Id} id={i.Id} disabled="true" />
                                          </td>
                                          <td>
                                            {i.Name}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                    : ""}
                                  {countKeyValue === "Category2" ?
                                    <tbody>
                                      {attribute2Values.filter((e) => e.Selected === true).map((i, index) => (
                                        <tr>
                                          <td>
                                            <input type="checkbox"
                                              checked={i.Selected == null || i.Selected == false ? false : true} name="customer-check" value={i.Id} id={i.Id} disabled="true" />
                                          </td>
                                          <td>
                                            {i.Name}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                    : ""}
                                  {countKeyValue === "Category3" ?
                                    <tbody>
                                      {attribute3Values.filter((e) => e.Selected === true).map((i, index) => (
                                        <tr>
                                          <td>
                                            <input type="checkbox"
                                              checked={i.Selected == null || i.Selected == false ? false : true} name="customer-check" value={i.Id} id={i.Id} disabled="true" />
                                          </td>
                                          <td>
                                            {i.Name}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                    : ""}
                                  {countKeyValue === "Category4" ?
                                    <tbody>
                                      {attribute4Values.filter((e) => e.Selected === true).map((i, index) => (
                                        <tr>
                                          <td>
                                            <input type="checkbox"
                                              checked={i.Selected == null || i.Selected == false ? false : true} name="customer-check" value={i.Id} id={i.Id} disabled="true" />
                                          </td>
                                          <td>
                                            {i.Name}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                    : ""
                                  }
                                  {countKeyValue === "Category5" ?
                                    <tbody>
                                      {attribute5Values.filter((e) => e.Selected === true).map((i, index) => (
                                        <tr>
                                          <td>
                                            <input type="checkbox"
                                              checked={i.Selected == null || i.Selected == false ? false : true} name="customer-check" value={i.Id} id={i.Id} disabled="true" />
                                          </td>
                                          <td>
                                            {i.Name}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                    : ""
                                  }
                                </table>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            :
            ""
        }



        {
          CategoryPopup === true ?
            < div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: "60vh" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="text-left">
                            {t('ExceptionPolicy.Category')}
                          </div>
                          <div className="d-flex align-items-center">
                            <div style={{ paddingTop: '1px', paddingLeft: '33px', paddingBottom: '6px', backgroundColor: "#fff" }}>
                              <button className='btn btn-outline-dark btn-sm ml-1' onClick={() => onClickOk('category')} style={{ width: "80px", height: "20px" }}>{t('Save&Close')}</button>
                            </div>
                            <button className="btn btn-outline-dark btn-sm ml-1" onClick={() => CloseCategory()} type="submit" style={{ width: "50px", height: "20px" }}>{t('Cancel')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">

                      </div>
                      <div style={{ minHeight: "60vh" }}>
                        <div className="container">
                          <table className="table table-bordered text-left mt-2 " id="tableregion">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                {/* <th style={{ width: "20px" }}>
                                  <input checked={regioncheckAll} disabled={disableField} onChange={checkAllRegionchange} type="checkbox" name="option-all-region" />
                                </th> */}

                                <th id="Product">
                                  {t('ExceptionPolicy.Category')}
                                </th>

                              </tr>
                            </thead>
                          </table>
                          < div className='d-flex flex-wrap' style={{ "margin-left": "-10px" }} id="div_customer">
                            {
                              CategoryList.map((each, index) => (
                                <div className='col-md-3' style={{ padding: "5px;" }}>
                                  <input
                                    type="checkbox"
                                    disabled={
                                      disableField ||
                                      CategoryIndex === 0 ||
                                      (ExceptionValueList[CategoryIndex]?.Category && ExceptionValueList[CategoryIndex]?.Category !== each.label)
                                    }
                                    checked={
                                      ExceptionValueList[CategoryIndex]?.Category === each.label
                                      //||
                                      // (index !== CategoryIndex)
                                    }
                                    name="region-check"
                                    value={each.label}
                                    id={each.Id}
                                    onChange={(event) => OnCategoryChange(event, each)}
                                  />
                                  &nbsp;<label>{each.label}</label>
                                </div>
                              ))
                            }
                          </div>


                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                {/* <div style={{ paddingTop: '6px', paddingLeft: '33px', paddingBottom: '6px', backgroundColor: "#fff" }}>
                  <button className='btn btn-outline-dark  btn-sm ml-1' onClick={() => onClickOk('zone')} style={{ width: "30px" }}>{t('Ok')}</button>
                </div> */}
              </div>
            </div>
            :
            ""

        }




        {
          showSubCategoryPopup === true ?
            < div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: "60vh" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="text-left">
                            {t('ExceptionPolicy.SubCategory')}
                          </div>
                          <div className="d-flex align-items-center">
                            <div style={{ paddingTop: '1px', paddingLeft: '33px', paddingBottom: '6px', backgroundColor: "#fff" }}>
                              <button className='btn btn-outline-dark btn-sm ml-1' onClick={() => onClickOk('subCategory')} style={{ width: "80px", height: "20px" }}>{t('Save&Close')}</button>
                            </div>
                            <button className="btn btn-outline-dark btn-sm ml-1" onClick={() => CloseSubCategory()} type="submit" style={{ width: "50px", height: "20px" }}>{t('Cancel')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">

                      </div>
                      <div style={{ minHeight: "60vh" }}>
                        <div className="container">
                          <table className="table table-bordered text-left mt-2 " id="tableregion">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                {/* <th style={{ width: "20px" }}>
                                  <input checked={regioncheckAll} disabled={disableField} onChange={checkAllRegionchange} type="checkbox" name="option-all-region" />
                                </th> */}

                                <th id="Product">
                                  {t('ExceptionPolicy.SubCategory')}
                                </th>

                              </tr>
                            </thead>
                          </table>
                          < div className='d-flex flex-wrap' id="div_customer">
                            {
                              SubCategoryList.map((each) => {


                                const isSelectedInException = ExceptionValueList.some(obj => obj.SelectedItemSubCategory === each.label);
                                const isSelectedInCurrentException = ExceptionValueList[SubCategoryIndex]?.SelectedItemSubCategory === each.label;
                                const shouldDisplay = !isSelectedInException || isSelectedInCurrentException;

                                return shouldDisplay ? (
                                  <div className='col-md-3' style={{ padding: "5px" }} key={each.Id}>
                                    <input
                                      type="checkbox"
                                      disabled={
                                        disableField ||
                                        (ExceptionValueList[SubCategoryIndex]?.SelectedItemSubCategory &&
                                          ExceptionValueList[SubCategoryIndex]?.SelectedItemSubCategory !== each.label)
                                      }
                                      checked={isSelectedInCurrentException}
                                      name="region-check"
                                      value={each.label}
                                      id={each.Id}
                                      onChange={(event) => handleSubCatChanges(event, each)}
                                    />
                                    &nbsp;
                                    <label>
                                      {each.label}
                                    </label>
                                  </div>
                                )
                                  : null;
                              })
                            }

                          </div>


                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                {/* <div style={{ paddingTop: '6px', paddingLeft: '33px', paddingBottom: '6px', backgroundColor: "#fff" }}>
                  <button className='btn btn-outline-dark  btn-sm ml-1' onClick={() => onClickOk('zone')} style={{ width: "30px" }}>{t('Ok')}</button>
                </div> */}
              </div>
            </div>
            :
            ""

        }

        {
          showSelectedRebateType === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, marginRight: "10px", padding: "0" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-10 text-left">
                            {t('Rebates.RebateType')}
                          </div>
                          <div className="col-md-2">
                            <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => ClickValuePopupClose("Close", "RebateType", "Selected")} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">

                      </div>
                      <div className='scrollbar-class'>

                        <div className="container">
                          <div>
                          </div>
                          <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableregion">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                <th >
                                </th>
                                <th id="Product">
                                  {t('Rebates.RebateType')}
                                </th>
                              </tr>
                            </thead>

                            <tbody>

                              {
                                RebateTypeDataSelected.map((cslist, index) => (
                                  <tr>

                                    <td style={{ width: "30px" }}>
                                      {disableField == true ? <>
                                        <input type="checkbox"
                                          disabled={true}
                                          className={disabledClass}
                                          checked={cslist.Selected} name="customer-check" value={cslist.Id} id={cslist.Id} />

                                      </> : <>
                                        <input type="checkbox"
                                          className={disabledClass}
                                          checked={cslist.Selected} name="customer-check" value={cslist.Id} id={cslist.Id} />

                                      </>

                                      } </td>

                                    <td style={{ width: "90px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.Name}>{cslist.Name}</div>


                                    </td>
                                  </tr>


                                ))
                              }

                            </tbody> </table>


                        </div>

                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
            :
            ""
        }

        {
          showSelectedSettlFreq === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, marginRight: "10px", padding: "0" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-11 text-left" style={{ marginLeft: "-5px" }}>
                            {t('Rebates.SettlementFreq')}
                          </div>
                          <div className="ml-3">
                            <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => ClickValuePopupClose("Close", "SettlementFreq", "Selected")} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">

                      </div>
                      <div className='scrollbar-class'>

                        <div className="container">
                          <div>


                          </div>
                          <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableregion">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                <th >
                                </th>
                                <th id="Product">
                                  {t('Rebates.SettlementFreq')}
                                </th>
                              </tr>
                            </thead>

                            <tbody>

                              {
                                SettlFreqDataSelected.map((cslist, index) => (

                                  <tr>

                                    <td style={{ width: "30px" }}>
                                      {disableField == true ? <>
                                        <input type="checkbox"
                                          disabled={true}
                                          className={disabledClass}
                                          checked={cslist.Selected} name="customer-check" value={cslist.Id} id={cslist.Id} />

                                      </> : <>
                                        <input type="checkbox"
                                          className={disabledClass}
                                          checked={cslist.Selected} name="customer-check" value={cslist.Id} id={cslist.Id} />

                                      </>

                                      } </td>

                                    <td style={{ width: "90px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.Name}>{cslist.Name}</div>


                                    </td>
                                  </tr>


                                ))
                              }

                            </tbody> </table>


                        </div>

                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
            :
            ""
        }

        {
          showSelectedCustomerpopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, marginRight: "10px", padding: "0" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-11 text-left" style={{ marginLeft: "-5px" }}>
                            {t('Rebates.CustomerListing')}
                          </div>
                          <div className="ml-2">

                            <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => ClickValuePopupClose("Close", "Customer", "Selected")} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">

                      </div>
                      <div className='scrollbar-class'>

                        <div className="container">
                          <div>


                          </div>
                          <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableregion">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                <th>

                                </th>
                                <th>{t('Rebates.CustomerCode')}</th>
                                <th>{t('Rebates.CustomerName')}</th>
                                <th>{t('Rebates.Station')}</th>
                                <th>{t('Rebates.State')}</th>
                                <th>{t('Rebates.Zone')}</th>
                              </tr>
                            </thead>

                            <tbody>

                              {
                                customerDataSelected.map((cslist, index) => (

                                  <tr>

                                    <td style={{ width: "30px" }}>
                                      {disableField == true ? <>
                                        <input type="checkbox"
                                          disabled={true}
                                          className={disabledClass}
                                          checked={cslist.Selected} name="customer-check" value={cslist.Id} id={cslist.Id} />

                                      </> : <>
                                        <input type="checkbox"
                                          className={disabledClass}
                                          checked={cslist.Selected} name="customer-check" value={cslist.Id} id={cslist.Id} />

                                      </>

                                      } </td>

                                    <td style={{ width: "90px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.Name}>{cslist.Name}</div>


                                    </td>
                                    <td style={{ width: "260px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.label}>{cslist.label}</div>


                                    </td>
                                    <td style={{ width: "100px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.Station}>{cslist.Station}</div>

                                    </td>
                                    <td style={{ width: "100px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.State}>{cslist.State}</div>

                                    </td>
                                    <td style={{ width: "100px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.Zone}>{cslist.Zone}</div>

                                    </td>



                                  </tr>


                                ))


                              }

                            </tbody> </table>


                        </div>

                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
            :
            ""
        }

        {
          showSelectedCustomerGrouppopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, marginRight: "10px", padding: "0" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-12 text-left">
                            {t('Rebates.GroupCustomerListing')}
                          </div>
                          <div className="col-md-2">

                            <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => ClickValuePopupClose("Close", "GroupCustomer", "Selected")} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">

                      </div>
                      <div className='scrollbar-class'>

                        <div className="container">
                          <div>


                          </div>
                          <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableregion">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                <th>

                                </th>
                                <th>{t('Rebates.CustomerCode')}</th>
                                <th>{t('Rebates.CustomerName')}</th>

                              </tr>
                            </thead>

                            <tbody>

                              {
                                customerGroupDataSelected.map((cslist, index) => (

                                  <tr>

                                    <td style={{ width: "30px" }}>
                                      {disableField == true ? <>
                                        <input type="checkbox"
                                          disabled={true}
                                          className={disabledClass}
                                          checked={cslist.Selected} name="customer-check" value={cslist.Id} id={cslist.Id} />

                                      </> : <>
                                        <input type="checkbox"
                                          className={disabledClass}
                                          checked={cslist.Selected} name="customer-check" value={cslist.Id} id={cslist.Id} />

                                      </>

                                      } </td>

                                    <td style={{ width: "90px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.Name}>{cslist.Name}</div>


                                    </td>
                                    <td style={{ width: "260px" }}>
                                      <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                        title={cslist.label}>{cslist.label}</div>
                                    </td>

                                  </tr>


                                ))
                              }

                            </tbody> </table>


                        </div>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
            :
            ""
        }

        {
          showZonePopup === true ?
            < div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: "72vh" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="text-left">
                            {t('Rebates.ZoneListing')}
                          </div>
                          <div className="d-flex align-items-center">
                            <div style={{ paddingTop: '1px', paddingLeft: '33px', paddingBottom: '6px', backgroundColor: "#fff" }}>
                              <button className='btn btn-outline-dark btn-sm ml-1' onClick={() => onClickOk('zone')} style={{ width: "80px", height: "20px" }}>{t('Save&Close')}</button>
                            </div>
                            <button className="btn btn-outline-dark btn-sm ml-1" onClick={() => onClickClose('zone')} type="submit" style={{ width: "50px", height: "20px" }}>{t('Cancel')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">

                      </div>
                      <div style={{ minHeight: "58vh" }}>
                        <div className="container">
                          <table className="table table-bordered text-left mt-2 " id="tableregion">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                <th style={{ width: "20px" }}>
                                  <input checked={regioncheckAll} disabled={disableField} onChange={checkAllRegionchange} type="checkbox" name="option-all-region" />
                                </th>

                                <th id="Product">
                                  {t('Rebates.Zone')}
                                </th>

                              </tr>
                            </thead>
                          </table>
                          < div className='d-flex flex-wrap' style={{ "margin-left": "-10px" }} id="div_customer">

                            {
                              zoneOptions.map((zonelist) =>
                                <div className='col-md-3' style={{ padding: "5px;" }}>
                                  <input type="checkbox"
                                    disabled={disableField}

                                    checked={zonelist.selected} name="region-check" value={zonelist.value} id={zonelist.Id} onChange={(event) => handleregionChanges(event, zonelist.label, zonelist.value, "Zone")} />
                                  &nbsp;<label>{zonelist.label}</label>
                                </div>
                              )
                            }
                          </div>


                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                {/* <div style={{ paddingTop: '6px', paddingLeft: '33px', paddingBottom: '6px', backgroundColor: "#fff" }}>
                  <button className='btn btn-outline-dark  btn-sm ml-1' onClick={() => onClickOk('zone')} style={{ width: "30px" }}>{t('Ok')}</button>
                </div> */}
              </div>
            </div>
            :
            ""

        }
        {
          showSelectedZonepopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: "72vh" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-11 text-left" style={{ marginLeft: "-5px" }}>
                            {t('Rebates.ZoneListing')}
                          </div>
                          <div className="ml-2">

                            <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => onClickCloseSelectedPopup('zone')} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">

                      </div>
                      <div style={{ minHeight: "58vh", overflowY: "scroll" }}>
                        <div className="container">
                          <table className="table table-bordered text-left mt-2 " id="tableregion">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                <th>
                                  <input disabled="true" type="checkbox" name="option-all-region" />
                                </th>

                                <th id="Product">
                                  {t('Rebates.Zone')}
                                </th>

                              </tr>
                            </thead>
                            <tbody>
                              {
                                zoneOptions.filter((x) => x.selected == true).map((i) =>
                                  <tr key={i.Id}>
                                    <td><input type="checkbox"
                                      disabled={true}
                                      className={disabledClass}
                                      checked={i.Selected} name="region-check" value={i.value} id={i.Id} /></td>
                                    <td>{i.label}</td>
                                  </tr>
                                )
                              }
                            </tbody>

                          </table>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            :
            ""
        }
        {
          showStatePopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: "72vh" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="text-left">
                            {t('Rebates.StateListing')}
                          </div>
                          <div className="d-flex align-items-center">
                            <div style={{ paddingTop: '1px', paddingLeft: '33px', paddingBottom: '6px', backgroundColor: "#fff" }}>
                              <button className='btn btn-outline-dark btn-sm ml-1' onClick={() => onClickOk('state')} style={{ width: "80px", height: "20px" }}>{t('Save&Close')}</button>
                            </div>
                            <button className="btn btn-outline-dark btn-sm ml-1" onClick={() => onClickClose('state')} type="submit" style={{ width: "50px", height: "20px" }}>{t('Cancel')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">

                      </div>
                      <div style={{ minHeight: "58vh" }}>
                        <div className="container">
                          <table className="table table-bordered text-left mt-2 " id="tableregion">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                <th style={{ width: "20px" }}>
                                  <input checked={regioncheckAll} disabled={disableField} onChange={checkAllRegionchange} type="checkbox" name="option-all-region" />
                                </th>

                                <th id="Product">
                                  {t('Rebates.State')}
                                </th>

                              </tr>
                            </thead>
                          </table>
                          < div className='d-flex flex-wrap' style={{ "margin-left": "-10px" }} id="div_customer">

                            {
                              stateOptions.map((statelist) =>
                                <div className='col-md-3' style={{ padding: "5px;" }}>
                                  <input type="checkbox"
                                    disabled={disableField}

                                    checked={statelist.selected} name="region-check" value={statelist.value} id={statelist.Id} onChange={(event) => handleregionChanges(event, statelist.label, statelist.value, "State")} />
                                  &nbsp;<label>{statelist.label}</label>
                                </div>
                              )
                            }
                          </div>


                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                {/* <div style={{ paddingTop: '6px', paddingLeft: '33px', paddingBottom: '6px', backgroundColor: "#fff" }}>
                  <button className='btn btn-outline-dark  btn-sm ml-1' onClick={() => onClickOk('state')} style={{ width: "30px" }}>{t('Ok')}</button>
                </div> */}
              </div>
            </div>
            :
            ""
        }
        {
          showSelectedStatepopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: "72vh" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-11 text-left" style={{ marginLeft: "-5px" }}>
                            {t('Rebates.StateListing')}
                          </div>
                          <div className="ml-2">

                            <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => onClickCloseSelectedPopup('state')} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">

                      </div>
                      <div style={{ minHeight: "58vh", overflowY: "scroll" }}>
                        <div className="container">
                          <table className="table table-bordered text-left mt-2 " id="tableregion">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                <th>
                                  <input disabled="true" type="checkbox" name="option-all-region" />
                                </th>

                                <th id="Product">
                                  {t('Rebates.State')}
                                </th>

                              </tr>
                            </thead>
                            <tbody>
                              {
                                stateOptions.filter((x) => x.selected == true).map((i) =>
                                  <tr key={i.Id}>
                                    <td><input type="checkbox"
                                      disabled={true}
                                      className={disabledClass}
                                      checked={i.Selected} name="region-check" value={i.Id} id={i.Id} /></td>
                                    <td>{i.label}</td>
                                  </tr>
                                )
                              }
                            </tbody>

                          </table>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            :
            ""
        }
        {
          showStationPopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: "72vh" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="text-left">
                            {t('Rebates.StationListing')}
                          </div>
                          <div className="d-flex align-items-center">
                            <div style={{ paddingTop: '1px', paddingLeft: '33px', paddingBottom: '6px', backgroundColor: "#fff" }}>
                              <button className='btn btn-outline-dark btn-sm ml-1' onClick={() => onClickOk('station')} style={{ width: "80px", height: "20px" }}>{t('Save&Close')}</button>
                            </div>
                            <button className="btn btn-outline-dark btn-sm ml-1" onClick={() => onClickClose('station')} type="submit" style={{ width: "50px", height: "20px" }}>{t('Cancel')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">

                      </div>
                      <div style={{ minHeight: "58vh" }}>
                        <div className="container">
                          <table className="table table-bordered text-left mt-2 " id="tableregion">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                <th style={{ width: "20px" }}>
                                  <input checked={regioncheckAll} disabled={disableField} onChange={checkAllRegionchange} type="checkbox" name="option-all-region" />
                                </th>

                                <th id="Product">
                                  {t('Rebates.Station')}
                                </th>

                              </tr>
                            </thead>
                          </table>
                          < div className='d-flex flex-wrap' style={{ "margin-left": "-10px" }} id="div_customer">

                            {
                              stationOptions.map((stationlist) =>
                                <div className='col-md-3' style={{ padding: "5px;" }}>
                                  <input type="checkbox"
                                    disabled={disableField}

                                    checked={stationlist.selected} name="region-check" value={stationlist.value} id={stationlist.Id} onChange={(event) => handleregionChanges(event, stationlist.label, stationlist.value, "Station")} />
                                  &nbsp;<label>{stationlist.label}</label>
                                </div>
                              )
                            }
                          </div>


                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                {/* <div style={{ paddingTop: '6px', paddingLeft: '33px', paddingBottom: '6px', backgroundColor: "#fff" }}>
                  <button className='btn btn-outline-dark  btn-sm ml-1' onClick={() => onClickOk('station')} style={{ width: "30px" }}>{t('Ok')}</button>
                </div> */}
              </div>
            </div>
            :
            ""
        }
        {
          showSelectedStationpopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: "72vh" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-11 text-left" style={{ marginLeft: "-5px" }}>
                            {t('Rebates.StationListing')}
                          </div>
                          <div className="ml-2">

                            <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => onClickCloseSelectedPopup('station')} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">

                      </div>
                      <div style={{ minHeight: "58vh", overflowY: "scroll" }}>
                        <div className="container">
                          <table className="table table-bordered text-left mt-2 " id="tableregion">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                <th>
                                  <input disabled="true" type="checkbox" name="option-all-region" />
                                </th>

                                <th id="Product">
                                  {t('Rebates.Station')}
                                </th>

                              </tr>
                            </thead>
                            <tbody>
                              {
                                stationOptions.filter((x) => x.selected == true).map((i) =>
                                  <tr key={i.Id}>
                                    <td><input type="checkbox"
                                      disabled={true}
                                      className={disabledClass}
                                      checked={i.Selected} name="region-check" value={i.Id} id={i.Id} /></td>
                                    <td>{i.label}</td>
                                  </tr>
                                )
                              }
                            </tbody>

                          </table>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            :
            ""
        }
        {
          showStockistPopup === true ?
            < div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: "72vh" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="text-left">
                            {t('Rebates.StockistListing')}
                          </div>
                          <div className="d-flex align-items-center">
                            <div style={{ paddingTop: '1px', paddingLeft: '33px', paddingBottom: '6px', backgroundColor: "#fff" }}>
                              <button className='btn btn-outline-dark btn-sm ml-1' onClick={() => onClickOk('stockist')} style={{ width: "80px", height: "20px" }}>{t('Save&Close')}</button>
                            </div>
                            <button className="btn btn-outline-dark btn-sm ml-1" onClick={() => onClickClose('stockist')} type="submit" style={{ width: "50px", height: "20px" }}>{t('Cancel')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">

                      </div>
                      <div style={{ minHeight: "58vh" }}>
                        <div className="container">
                          <table className="table table-bordered text-left mt-2 " id="tableregion">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                <th style={{ width: "20px" }}>
                                  <input checked={regioncheckAll} disabled={disableField} onChange={checkAllRegionchange} type="checkbox" name="option-all-region" />
                                </th>

                                <th id="Product">
                                  {t('Rebates.Stockist')}
                                </th>

                              </tr>
                            </thead>
                          </table>
                          <div className='d-flex flex-wrap' style={{ "margin-left": "-10px" }} id="div_customer">

                            {
                              stockistOptions.map((stockistlist) =>
                                <div className='col-md-3' style={{ padding: "5px;" }}>
                                  <input type="checkbox"
                                    disabled={disableField}

                                    checked={stockistlist.selected} name="region-check" value={stockistlist.value} id={stockistlist.Id} onChange={(event) => handleregionChanges(event, stockistlist.label, stockistlist.value, "Stockist")} />
                                  &nbsp;<label>{stockistlist.label}</label>
                                </div>
                              )
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div style={{ paddingTop: '6px', paddingLeft: '33px', paddingBottom: '6px', backgroundColor: "#fff" }}>
                  <button className='btn btn-outline-dark  btn-sm ml-1' onClick={() => onClickOk('stockist')} style={{ width: "30px" }}>{t('Ok')}</button>
                </div> */}
              </div>
            </div>
            :
            ""

        }
        {
          showSelectedStockistpopup === true ?
            <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: "72vh" }}>
              <div className="container" style={{ padding: "0" }}>
                <div className="row">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">
                        <div className="row">
                          <div className="col-md-11 text-left" style={{ marginLeft: "-5px" }}>
                            {t('Rebates.StockistListing')}
                          </div>
                          <div className="ml-2">

                            <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => onClickCloseSelectedPopup('stockist')} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-lg-12 pr-lg-2">
                    <div className="card text-center card_border">
                      <div className="card-header chart-grid__header">

                      </div>
                      <div style={{ minHeight: "58vh", overflowY: "scroll" }}>
                        <div className="container">
                          <table className="table table-bordered text-left mt-2 " id="tableregion">
                            <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                              <tr>
                                <th>
                                  <input disabled="true" type="checkbox" name="option-all-region" />
                                </th>

                                <th id="Product">
                                  {t('Rebates.Stockist')}
                                </th>

                              </tr>
                            </thead>
                            <tbody>
                              {
                                stockistOptions.filter((x) => x.selected == true).map((i) =>
                                  <tr key={i.Id}>
                                    <td><input type="checkbox"
                                      disabled={true}
                                      className={disabledClass}
                                      checked={i.Selected} name="region-check" value={i.value} id={i.Id} /></td>
                                    <td>{i.label}</td>
                                  </tr>
                                )
                              }
                            </tbody>

                          </table>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            :
            ""
        }

        <Modal show={showApprovedModal} size="bg"
          centered>
          <Modal.Header>
            <Modal.Title>{t('Rebates.ApprovalComments')}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }} >
            <Form.Control className={classes['Textarea-width']} as="textarea" rows={2} value={commentsForApproved} name="commentsForApproved"
              onChange={(e) => { setCommentsForApproved(e.target.value) }} />
          </Modal.Body>
          <Modal.Footer>
            <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} onClick={() => showApprovedDialog('showApprovedDialog')}>
              {t('Cancel')}
            </Button>
            <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} onClick={() => showApprovedDialog('proceed')}>
              {t('Submit')}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showWorkflowAlert}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <Modal.Body style={{ "textAlign": 'center' }}>{t('Accruals.WorkflowAlert')}</Modal.Body>
          <Modal.Footer>
            <Button size='sm' style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "30px" }} onClick={(e) => workflowAlert(e, 'close')}>
              {t('Ok')}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showDeniedModal} size="bg"
          centered>
          <Modal.Header>
            <Modal.Title>{t('Rebates.DeniedComments')}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }} >
            <Form.Control className={classes['Textarea-width']} as="textarea" rows={2} value={commentsForDenied} name="commentsForDenied"
              onChange={(e) => { setCommentsForDenied(e.target.value) }} />
          </Modal.Body>
          <Modal.Footer>
            <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} onClick={() => showDeniedDialog('showDeniedDialog')}>
              {t('Cancel')}
            </Button>
            <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} onClick={() => showDeniedDialog('proceed')}>
              {t('Submit')}
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showSubmitModal} size="bg"
          centered>
          <Modal.Header>
            <Modal.Title>{t('Rebates.SubmitComments')}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ "textAlign": 'center' }} >
            <Form.Control className={classes['Textarea-width']} as="textarea" rows={2} value={commentsForSubmit} name="commentsForSubmit"
              onChange={(e) => { setCommentsForSubmit(e.target.value) }} />
          </Modal.Body>
          <Modal.Footer>
            <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} onClick={(event) => showSubmitDialog(event, 'showSubmitDialog')}>
              {t('Cancel')}
            </Button>
            <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} onClick={(event) => showSubmitDialog(event, 'proceed')}>
              {t('Submit')}
            </Button>
          </Modal.Footer>
        </Modal>

      </div >
    </>
  )
}

export default NewMaterialPricingGroupMappingPolicy