import React from "react";
import Content from "./Pages/Layout/Content/Content";
import Login from "./Pages/Login/Login";
import SessionManage from "./SessionManagement/SessionManage"
import "./customStyle.css";
import { useSearchParams } from 'react-router-dom';
import Service from './Services/Service';

let name = SessionManage.getusername();
let correctuserid = SessionManage.getuserid();

const App = () => {

  const [searchParams, setSearchParams] = useSearchParams();

  const renderPage = (token) => {
    let url = "Login/login?token=" + token;
    try {
      Service.getErpLoginToken(url).
        then(res => {
          if (res && res.data.Status == "Successfully") {

            SessionManage.setuserid(res.data.Id);
            SessionManage.setusername(res.data.FullName);
            SessionManage.settokenid(res.data.Token);
            SessionManage.setroleid(res.data.RoleId);
            SessionManage.setgroupid(res.data.GroupId);

            SessionManage.setNavBar(JSON.stringify(res.data.navItem));
            SessionManage.setdateformat(res.data.dateformat);
            SessionManage.setFiscalEndDate(res.data.FiscalEndDate);

            SessionManage.setLocale(res.data.Locale);
            SessionManage.setLanguages(res.data.Languages);
            SessionManage.setUsersType(res.data.UsersType);
            var currentdata = res.data.ConfigurationPropertyValues;
            currentdata.map(i => {
              if (i.Name === "roundingprecision") {
                SessionManage.setroundingPrecision(i.value)
              }
              else if (i.Name === "AccrualCalc") {
                SessionManage.setAccrualCalc(i.value)
              }
              else if (i.Name === "ComparisonPeriod") {
                SessionManage.setComparisonPeriod(i.value)
              }
              else if (i.Name === "DefaultCurrency") {
                SessionManage.setdefaultCurrency(JSON.stringify(i.objselectedDropdown))
              }
              else if (i.Name === "FiscalStartMonth") {
                SessionManage.setfiscalstartmonth(i.value)
              }
              else if (i.Name === "FiscalEndMonth") {
                SessionManage.setfiscalendmonth(i.value)
              }
              else if (i.Name === "Session-Idle-Timeout") {
                SessionManage.setidletimeout(i.value)
              }
              else if (i.Name === "IncludeSubDivideTarget") {
                SessionManage.setIncludeSubDivideTarget(i.IncludeSubDivideTarget)
              }
              else if (i.Name === "IncludeOutStandingAmount") {
                SessionManage.setIncludeOutstandingAmount(i.IncludeOutStandingAmount)
              }
              else if (i.Name === "AccrualThresholdCriteria") {
                SessionManage.setAccrualThresholdCriteria(i.AccrualThresholdCriteria)
              }
              else if (i.Name === "AccrualThresholdCriteria") {
                SessionManage.setAccrualThresholdCriteria(i.AccrualThresholdCriteria)
              }

            });
            window.location.href = "/";

          }
          else if (res && res.data.Status?.toLowerCase() == "password not matched") {
            alert("Invalid username or password.")
          }
          else if (res && res.data.Status?.toLowerCase() == "your account is inactive") {
            alert("Invalid username or password.")
          }
          else if (res && res.data.Status?.toLowerCase() == "email id not exists") {
            alert("Invalid username or password.")
          }
          else {
            alert("Invalid server request")
          }
          return res;
        }).catch(error => {
          alert(error)
          return null;
        });
    } catch (err) {
      alert(`Error: ${err?.response?.data}`)
    }
  }

  return (
    <>
      {searchParams.get("token") ? renderPage(searchParams.get("token")) :
        correctuserid === null ?
          <Login></Login>
          :
          <Content />

      }

    </>
    // <React.Fragment>
    //   <Switch>
    //     {/* <Route path='/' exact> <Redirect to="/Home" /></Route> */}
    //     <Route path='/home' exact>
    //       <Header />
    //       <Menu />
    //       <Home />
    //       <Footer />
    //     </Route>


    //     <Route path='/' exact >

    //       <Login />

    //     </Route>

    //     <Route path='/rebate-program' exact >
    //       <Header />
    //       <Menu />
    //       <RebateListing />
    //       <Footer />
    //     </Route>

    //     <Route path='/new-rebate' exact >
    //       <Header />
    //       <Menu />
    //       <NewRebate />
    //       <Footer />
    //     </Route>

    //     <Route path='/accruals' exact >
    //       <Header />
    //       <Menu />
    //       <AccrualsListing />
    //       <Footer />
    //     </Route>

    //     <Route path='/new-accruals' exact >
    //       <Header />
    //       <Menu />
    //       <NewAccruals />
    //       <Footer />
    //     </Route>


    //   </Switch>
    // </React.Fragment>
  )
}

export default App;