import React, { Component } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import CustomerGroupMappingPolicyListComponent from '../../../Components/EndurancePolicies/CustomerGroupMappingPolicyComponent/CustomerGroupMappingPolicyListComponent';

function CustomerGroupMappingPolicyList(props) {
    let navigate = useNavigate();
    const params = useParams();

    return (
        <div>
            <CustomerGroupMappingPolicyListComponent {...props} navigate={navigate} id={params.id} name={params.name} />
        </div>
    );

}

export default CustomerGroupMappingPolicyList