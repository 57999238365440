import { Link } from "react-router-dom"
import { useNavigate, useLocation } from 'react-router-dom';
import React, { useEffect, useState, useRef, forwardRef } from 'react';
import { CSVLink } from "react-csv";
import Service from '../../../Services/Service';
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import Multiselect from 'multiselect-react-dropdown';
import SessionManage from "../../../SessionManagement/SessionManage";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from "react-select";
import CustomErrorPopup from '../../../UI/CustomErrorPopup';

function ArchiveRebateComponent() {
  const csvInstance = useRef();
  const [csvList, setCsvList] = useState([]);
  const [headers, setHeaders] = useState([
    { label: "Agreement ID", key: "RebateId" },
    { label: "Customer Type ", key: "CustomerType" },
    { label: "Rebate Program ID", key: "RebateProgramLineIdStr" },
    { label: "Rebate Program Desc", key: "RebateProgramLineDesc" },
    { label: "Distributor Name", key: "DistributorName" },
    { label: "Validity Start Date", key: "ValidityFrom" },
    { label: "Validity End Date ", key: "ValidityTo" },
    { label: "Submitted By", key: "SubmittedBy" },
    { label: "Status", key: "StatusName" },

  ]);
  const [sortColName, setSortColName] = useState("");
  const [t, i18n] = useTranslation('translation');
  const [sticky, setSticky] = useState("");

  const navigate = useNavigate();
  const [paging, setpaging] = useState({
    pageSize: 20,
    pageNumber: 1
  })
  const [userPerms, setuserPermission] = useState([]);
  const location = useLocation();
  const [showspinner, setshowspinner] = useState('');
  const [showLoadMore, setshowLoadMore] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [checkedRebateLineId, setCheckedRebateLineId] = useState([]);
  let loggedInUser = SessionManage.getuserid();
  const [showbutton, setshowbutton] = useState('');
  const [filterValue, setFilterValue] = useState('');
  const [filterCol, setFilterCol] = useState('');
  const [dropdownData, setDropdownData] = useState([]);
  const [showfilterpopup, setshowfilterpopup] = useState(false);
  const [columnAscending, setColumnAscending] = useState(true);
  const [showCustomerPopup, setShowCustomerpopup] = useState([])
  const [customerData, setCustomerData] = useState([])
  const [sidebarwidth, setsidebarwidth] = useState('');
  const [filtertext, setfiltertext] = useState('');
  const [filterdropdownData, setFilterDropdownData] = useState([]);
  const [filterSearch, setFilterSearch] = useState("");
  const [filterValueDict, setFilterValueDict] = useState({
    rebateagreement_id: '',
    rebateprogram_id: '',
    desc: '',
    ValidityFrom: null,
    ValidityTo: null,
    CustomerGroup: ''
  });
  const [multiFilterValue, setMultiFilterValue] = useState({
    customer: '',
    submittedby: '',
    DistributorName: ''
  });
  const [totalselectedshow, settotalselectedshow] = useState('');
  const [totalselectedcount, settotalselectedcount] = useState(0);
  const [totalCountlst, settotalCountlst] = useState('');
  const LoadMore = (lst, totalcount) => {

    if (lst < totalcount) {
      setshowLoadMore(true);
    }
    else if (lst >= totalcount) {
      setshowLoadMore(false);
    } else {
      setshowLoadMore(false);
    }

  }
  const customStyles = {
    control: base => ({
      ...base,
      minHeight: 28
    }),
    dropdownIndicator: base => ({
      ...base,
      padding: 2
    }),
    clearIndicator: base => ({
      ...base,
      padding: 2
    }),
    multiValue: base => ({
      ...base,

    }),
    valueContainer: base => ({
      ...base,
      padding: '0px 2px'
    }),
    input: base => ({
      ...base,
      margin: 0,
      padding: 0
    })
  };
  const [CompletedRebateData, setCompletedRebateData] = useState([]);


  // New state to manage the Check All checkbox
  const [checkAllChecked, setCheckAllChecked] = useState(false);
  const [checkedValue, setCheckedValue] = useState([]);
  const [checkedMultiValueid, setCheckedMultiValueid] = useState([]);
  const [checkedValueid, setCheckedValueid] = useState([]);
  const [checkedValueStatus, setCheckedValueStatus] = useState([]);
  const [allCheckedHistory, setAllCheckedHistory] = useState(false);
  const [uncheckedBoxesHistory, setuncheckedBoxesHistory] = useState([]);

  {
    useEffect(() => {
      getUserPermission(location.pathname);
      setshowspinner(true);
      settotalselectedshow(false);
      setColumnAscending(false);
      setSortColName("Id");
      getRebateCompletedApprovalData("Approval/GetCompletedRebate", paging.pageSize, paging.pageNumber, "Descending", "Id", [], null)
      getColumnVisibilityList()
      getColVisibilityListData()
      window.addEventListener("scroll", isSticky);
      return () => {
        window.removeEventListener("scroll", isSticky);
      };
    }, [])
  }

  const getUserPermission = (pathname) => {
    Service.GetUserPermission(pathname).then((res) => {
      if (typeof res !== 'undefined') {

        if (res.data !== 'null' && typeof res.data !== 'undefined' && res.data !== '') {
          if (typeof res.data.DataList !== 'undefined') {
            setuserPermission(res.data.DataList)
          }
        }
      }
    });
  }
  const isSticky = () => {
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 60 ? "is-sticky-thead" : "";
    setSticky(stickyClass);
    const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
    var divtop1 = document.getElementById("tableA");
    setdivtop(divtop1.offsetTop + scrollTopActual)
  };


  const ExporttoCSV = () => {

    let checkedBoxes = document.querySelectorAll("input[name=chkBox]:checked");
    let values = "";
    if (checkedBoxes.length >= 1) {
      for (let checkbox of checkedBoxes) {
        values = values === "" ? checkbox.id : values + ";" + checkbox.id;
      }
    }

    let objFilter = [
      {
        filterKey: "id",
        FilterValue: values,
      },
    ];

    Service.getDatawithMultiFilterSearch("Approval/GetCompletedRebate", 20000, 1, "Ascending", "Id", checkAllChecked ? filterLst() : objFilter, null).then((res) => {
      console.log(res, 'api-resp');
      if (res !== undefined && res.data !== null && res.data !== undefined) {
        let csvLst = res.data.DataList;

        let selectedIds = values.split(';');

        let operatedList = csvLst
        //If checkall box is not checked
        if (!checkAllChecked) {
          //If it has not been checked in history/past
          if (!allCheckedHistory)
            operatedList = csvLst.filter(item => selectedIds.includes(`${item.Id}`))
          //If it has been checked in history/past
          else
            operatedList = csvLst.filter(item => !uncheckedBoxesHistory.includes(item.Id))
        }

        let filteredCsvList = operatedList.map(item => {
          let newItem = {};
          Object.keys(item).forEach(key => {
            if (!hideColumn.includes(key)) {
              if (key === 'LastModifiedDate') {
                newItem[key] = `${moment(item[key]).format(dateFormat)} ${moment(item[key]).format(timeFormat)}`;
              } else if (key === 'StartDate' || key === 'EndDate') {
                newItem[key] = moment(item[key]).format(dateFormat);
              } else {
                newItem[key] = item[key];
              }
            }
          });
          return newItem;
        });

        let filteredHeaders = headers.filter(header => !hideColumn.includes(header.key));

        console.log(filteredCsvList, "filtered");

        setCsvList(filteredCsvList);
        setHeaders(filteredHeaders);

        setTimeout(() => {
          toast.success('Exported Successfully !', {
            position: toast.POSITION.TOP_RIGHT,
          });
          csvInstance.current.link.click();
        });
      }
    });
  }

  const class_thead = `text-white rt-thead  ${sticky}`;

  const checkAll = (mycheckbox) => {
    const checkboxes = document.querySelectorAll("input[type='checkbox']");
    if (mycheckbox.target.checked) {
      settotalselectedshow(true);
      setAllCheckedHistory(true);
      checkboxes.forEach(function (checkbox) {
        checkbox.checked = true;
      });
      settotalselectedcount(totalCountlst);
      setCheckAllChecked(true); // Set state to manage Check All checkbox
    } else {
      settotalselectedshow(false);
      setAllCheckedHistory(false);
      checkboxes.forEach(function (checkbox) {
        checkbox.checked = false;
      });
      settotalselectedcount(0);
      setCheckAllChecked(false); // Set state to manage Check All checkbox
    }
  };

  const getRebateCompletedApprovalData = (method, pageSize, pageNumber, sortType, SortProperty, filterValue, search) => {
    Service.getdataWithPagingForApprovalsSearch(method, loggedInUser, pageSize, pageNumber, sortType, SortProperty, filterValue, search).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res && res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
              let rData = res.data.DataList;
              setCompletedRebateData(rData)
              LoadMore(res.data.DataList.length, res.data.Totalcount)

              setshowspinner(false);
              settotalCountlst(res.data.Totalcount)
            }
          }
        }
      }

    });
  }

  const handleLoadMore = () => {

    let pagenumber = paging.pageNumber + 1;
    var sortType = columnAscending === true ? 'Ascending' : 'Descending';
    paging.pageNumber = pagenumber
    setpaging(paging);

    Service.getdataWithPagingForApprovalsSearch("Approval/GetCompletedRebate", loggedInUser, paging.pageSize, pagenumber, sortType, sortColName, filterLst(), searchValue).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
              let rowData = res.data.DataList;
              const currentlength = CompletedRebateData.length;
              setCompletedRebateData(CompletedRebateData => [...CompletedRebateData, ...res.data.DataList])

              LoadMore(currentlength + res.data.DataList.length, res.data.Totalcount)

              const allCheckBoxes = document.querySelectorAll("input[type='checkbox']");
              console.log(allCheckBoxes, "21-6");

              if (!checkAllChecked && totalselectedcount != 0) {
                allCheckBoxes.forEach((each) => {
                  const checkboxId = parseInt(each.id, 10);
                  if (!isNaN(checkboxId)) {
                    if (uncheckedBoxesHistory.includes(checkboxId)) {
                      each.checked = false;
                    } else {
                      // each.checked = true;
                      each.checked = checkedMultiValueid.includes(checkboxId);
                    }
                  }
                });
              } else {
                allCheckBoxes.forEach((each) => {
                  each.checked = checkAllChecked;
                });
              }

            }
          }
        }
      }

    });

  };

  const searchHandler = (event) => {

    var sortType = columnAscending === true ? 'Ascending' : 'Descending';
    let pagenumber = 1;
    paging.pageNumber = pagenumber;
    setpaging(paging);
    if (event.target.value != '') {

      setSearchValue(String(event.target.value).toLowerCase())
      getRebateCompletedApprovalData("Approval/GetCompletedRebate", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), event.target.value);
    }
    else {
      setSearchValue(null);
      getRebateCompletedApprovalData("Approval/GetCompletedRebate", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), null);
    }
  }


  const handleChange = (e, status, id, rebateProgramLineId) => {
    const isChecked = e.target.checked;
    const checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
    let count = totalselectedcount;

    if (isChecked) {
      setCheckedValue(id);  // check box value - id
      setCheckedValueStatus(status); // check box value - Active status
      setCheckedValueid(id)
      setCheckedRebateLineId(rebateProgramLineId)// Add the checked id to the array
      count += 1;

      let tempUncheckedBoxesHistory = [...uncheckedBoxesHistory];
      const index = tempUncheckedBoxesHistory.findIndex((item) => item === id);
      if (index !== -1) {
        tempUncheckedBoxesHistory.splice(index, 1);
      }
      setuncheckedBoxesHistory(tempUncheckedBoxesHistory);

    } else {
      setuncheckedBoxesHistory([...uncheckedBoxesHistory, id]);
      setCheckedValue([]);  // check box value - id
      setCheckedValueStatus([]); // check box value - Active status
      setCheckedValueid([])
      setCheckedRebateLineId([]) // Remove the unchecked id from the array
      count -= 1;

    }

    settotalselectedcount(count);
    settotalselectedshow(count > 0);

    // Uncheck the "Check All" checkbox if any individual checkbox is unchecked
    if (!isChecked) {
      setCheckAllChecked(false);
    } else if (count === totalCountlst) {
      setCheckAllChecked(true);
    }

    if (checkedBoxes.length == 1) {
      const [Recordname, Recordstatus] = checkedBoxes[0].value.split(',');
      setCheckedValueid(checkedBoxes[0].id)  // check box value - id
      setCheckedValue(checkedBoxes[0].id);
      setCheckedValueStatus(Recordstatus) // check box value - Active status
      setCheckedRebateLineId(Recordname) //check box value - RA-0000
      setshowbutton(true);
    }
    else{
      setshowbutton(false);
    }
  };


  const navigateToRebate = (id, rebateProgramLineId,AgreementType) => {
    if (id == null || id == 0) {
      alert('Please select the Accrual to view')
    }
    else {
      if (AgreementType === "Engine oil TOD") {
        navigate("/new-engineoil/" + id + "/fromApprovalCompleted/" + rebateProgramLineId)
      }
      else {
        navigate("/new-rebate/" + id + "/fromApprovalCompleted/" + rebateProgramLineId);
      }
      
    }
  }

  const OpenRebate = (event, id, rebateProgramLineId,AgreementType) => {
    if (event.detail === 2) {
      if (AgreementType === "Engine oil TOD") {
        navigate("/new-engineoil/" + id + "/fromApprovalCompleted/" + rebateProgramLineId)
      }
      else {
        navigate("/new-rebate/" + id + "/fromApprovalCompleted/" + rebateProgramLineId);
      }
      //navigate("/new-rebate/" + id + "/fromApprovalCompleted/" + rebateProgramLineId);
    }
  }


  const filterInput = (e) => {

    let inputValue = e.target.value
    setFilterValue(inputValue)
    setFilterValueDict(() => ({
      ...filterValueDict, [filterCol]: e.target.value,
    }));
  }

  const OndropdownChange = (e, id, name) => {
    let isChecked = e.target.checked;
    let allChecked = false;


    let allCheckboxes = document.getElementsByName("filter-option-all");
    let totalChecked = 0;
    allCheckboxes.forEach((checkbox) => {
      if (checkbox.checked)
        totalChecked += 1;
    });

    let tempData = [...dropdownData];
    //If all checkboxes are checked
    if (tempData.length - 1 === totalChecked) {
      tempData[0].ischecked = isChecked;
      allChecked = true;
    }

    setDropdownData(tempData);

    console.log(tempData, "12x");

    var lastfilter = [...dropdownData]
    if (isChecked === true) {
      let currentdata = lastfilter.map((i) => {
        if (i.Id == id) {
          i["ischecked"] = true;
        }
        return i;
      });

      setDropdownData(currentdata);
      let data1 = []
      const getallData = {
        Name: name,
      }
      data1.push(getallData)
      setFilterValue(filterValue => [...filterValue, ...data1]);

      let updateddata = ([...filterValue, ...data1]);

      if (allChecked) {
        const selectAllObj = {
          "Name": "Select All"
        };
        updateddata.splice(0, 0, selectAllObj);
      }

      setMultiFilterValue(() => ({
        ...multiFilterValue, [filterCol]: updateddata,
      }));
    }
    else {
      let currentdata = lastfilter.map((i) => {
        if (i.Id === id) {
          i["ischecked"] = false;
        }
        return i;
      });

      console.log(currentdata, "c-data");

      setDropdownData(currentdata);
      setFilterValue(filterValue => filterValue.filter(data => data.Name !== name))
      // let data1 = filterValue.filter(data => data.Name !== name);

      let data1 = filterValue[filterCol] || [];
      data1 = data1.filter((data) => data.Name !== name);

      if (tempData.length - 1 >= totalChecked) {
        data1 = data1.filter(data => data.Name !== "Select All");
      }

      console.log(data1, "data");

      setMultiFilterValue(() => ({
        ...multiFilterValue, [filterCol]: data1,
      }));
    }
  }

  const UserfilterHandler = (e) => {

    setFilterSearch(e.target.value)
    if (e.target.value !== "") {
      let searchResults = dropdownData.filter((each) => {
        return each.Name.toLowerCase().includes(String(e.target.value).toLowerCase())
      });
      setDropdownData(searchResults);
    }
    else {

      setFilterSearch("")
      setDropdownData(filterdropdownData)
    }
  }

  const checkAllFilter = (mycheckbox) => {
    var lastfilter = [...dropdownData]
    let data1 = []
    var checkboxes = document.querySelectorAll("input[name = 'filter-option-all']");
    if (mycheckbox.target.checked == true) {

      let currentdata = lastfilter.map((i) => {
        i["ischecked"] = true;
        return i;
      });
      setDropdownData(currentdata)

      lastfilter.map((each) => {

        const getallData = {
          Name: each.Name,
        }
        data1.push(getallData)
      })
      setFilterValue(filterValue => [...filterValue, ...data1]);
      let updateddata = ([...filterValue, ...data1]);
      setMultiFilterValue(() => ({
        ...multiFilterValue, [filterCol]: updateddata,
      }));
    }
    else {

      let currentdata = lastfilter.map((i) => {
        i["ischecked"] = false;
        return i;
      });
      setFilterValue([])
      setMultiFilterValue([])
    }
  }




  const [divleft, setdivleft] = useState('');
  const [divtop, setdivtop] = useState('');

  const FilterColumn = (name) => {

    const scrollTop = window.scrollY;
    const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
    var divtop1 = document.getElementById("tableA");
    setdivtop(divtop1.offsetTop + scrollTopActual + 30)

    setshowfilterpopup(true)
    setFilterCol(name)
    if (name === 'CustomerType') {
      // DropdownHandler(name)
      setfiltertext(t('Rebates.CustomerType'))
      var testDiv = document.getElementById("CustomerType");
      setdivleft((testDiv.offsetLeft + 30) + "px");
    }
    if (name === 'ValidityFrom') {
      DropdownHandler(name)
      setfiltertext(t('Rebates.ValidityStartDate'))
      var testDiv = document.getElementById("ValidityFrom");
      setdivleft((testDiv.offsetLeft + 30) + "px");
    }
    if (name === 'ValidityTo') {
      DropdownHandler(name)
      setfiltertext(t('Rebates.ValidityEndDate'))
      var testDiv = document.getElementById("ValidityTo");
      setdivleft((testDiv.offsetLeft + 30) + "px");
    }

    if (name === 'SubmittedBy') {
      setfiltertext(t('Rebates.SubmittedBy'))
      var testDiv = document.getElementById("SubmittedBy");
      setdivleft(((testDiv.offsetLeft + 30) - 150) + "px");
      DropdownHandler(name)
    }
    if (name === 'RebateId') {
      setfiltertext(t('Rebates.RebateAgreementId'))
      var testDiv = document.getElementById("RebateId");
      setdivleft((testDiv.offsetLeft + 30) + "px");
    }
    if (name === 'RebateProgramLineIdStr') {
      setfiltertext(t('Rebates.RebateProgramId'))
      var testDiv = document.getElementById("RebateProgramLineIdStr");
      setdivleft((testDiv.offsetLeft + 30) + "px");
    }
    if (name === 'RebateProgramLineDesc') {
      setfiltertext(t('Rebates.RebateProgramLineDesc'))
      var testDiv = document.getElementById("RebateProgramLineDesc");
      setdivleft((testDiv.offsetLeft + 30) + "px");
    }
    if (name === 'DistributorName') {
      var testDiv = document.getElementById("DistributorName");
      setdivleft((testDiv.offsetLeft + 30) + "px");
      setfiltertext(t('DistributorCustomer.DistributorName'))
      DropdownHandler(name)
    }
    if (name === 'StatusName') {
      var testDiv = document.getElementById("StatusName");
      // setdivleft((testDiv.offsetLeft + 30) + "px");
      setdivleft(((testDiv.offsetLeft) - 150) + "px");

      setfiltertext(t('Status'))
      DropdownHandler(name)
    }
  }


  const DropdownHandler = (key) => {
    Service.getfilterDropdown('Approval/GetRebateApprovalFilterValue', key).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {

            if (res.data !== 'null' && res.data !== 'undefined') {

              addnewfield(res.data, key);
            }
          }
        }
      }

    });
  }

  const addSeletAll = (optionsArray, alreadySelected) => {

    let result = optionsArray;
    let selectAllObj = {
      "Name": "Select All",
      "Value": "Select All",
      "ischecked": alreadySelected
    }

    result.splice(0, 0, selectAllObj);
    console.log(result, "9999")
    return result;
  }

  const addnewfield = (data, key) => {

    let currentdata = data.map((i) => {
      i["ischecked"] = false;
      return i;
    });
    let selectedKeys = multiFilterValue[key] ? multiFilterValue[key] : [];

    console.log(currentdata, "test-data");
    let alreadySelected = selectedKeys.find((obj) => obj.Name === "Select All") ? true : false;

    if (key != 'active') {
      if (selectedKeys) {
        currentdata.forEach((each) => {
          selectedKeys.forEach((eachSelected) => {
            if (each.Name == eachSelected.Name) {
              each.ischecked = true
            }

          })
        })
      }


      let result = addSeletAll(currentdata, alreadySelected);
      setDropdownData(result);
      setFilterDropdownData(result)
    }
    else {
      let alreadySelected = selectedKeys.find((obj) => obj.Name === "Select All") ? true : false;
      if (selectedKeys) {
        currentdata.forEach((each) => {
          selectedKeys.forEach((eachSelected) => {
            if (each.Name == eachSelected.Name) {
              each.ischecked = true
            }
          })
        })
        setDropdownData(currentdata)
        setFilterDropdownData(currentdata)
      }
      else {
        setDropdownData(currentdata)
        setFilterDropdownData(currentdata)
      }
    }
  }


  const filterHandler = (flag) => {

    var sortType = columnAscending === true ? 'Ascending' : 'Descending';
    let pagenumber = 1;
    paging.pageNumber = pagenumber;

    if (flag === 'Apply') {
      setshowfilterpopup(false)
      let list = [];

      for (var key in multiFilterValue) {
        if (multiFilterValue[key].length > 0) {
          let values = ""
          multiFilterValue[key].map((i) => {
            values += i.Name + ";";
          })
          if (values !== null && values !== '') {
            list.push({
              filterKey: key,
              FilterValue: values
            })
          }
        }
      }

      for (var key in filterValueDict) {
        if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
          list.push({
            filterKey: key,
            FilterValue: filterValueDict[key]
          })
        }
      }
      if (list.length > 0) {
        setpaging(paging);
        getRebateCompletedApprovalData("Approval/GetCompletedRebate", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);
      }

      let tempDropdownData = dropdownData;
      if (tempDropdownData.length > 0){
        tempDropdownData[0]["ischecked"] = true;}
      setDropdownData(tempDropdownData);
    }
    else {
      setshowfilterpopup(false)
      setMultiFilterValue(() => ({
        ...multiFilterValue, [filterCol]: '',
      }));
      setFilterValueDict(() => ({
        ...filterValueDict, [filterCol]: '',
      }));

      let list = [];
      for (var key in multiFilterValue) {
        if (key !== filterCol && multiFilterValue[key].length > 0) {
          let values = ""
          multiFilterValue[key].map((i) => {
            values += i.Name + ";";
          })
          if (values !== null && values !== '') {
            list.push({
              filterKey: key,
              FilterValue: values
            })
          }
        }
      }

      for (var key in filterValueDict) {
        if (filterValueDict[key] !== null && filterValueDict[key] !== '' && key !== filterCol) {
          list.push({
            filterKey: key,
            FilterValue: filterValueDict[key]
          })
        }
      }

      setpaging(paging);
      getRebateCompletedApprovalData("Approval/GetCompletedRebate", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);


      setFilterCol('')
      setFilterValue('')
      setFilterSearch("")
      setDropdownData([])
      setFilterDropdownData([])
    }
  }

  const filterLst = () => {
    let list = [];
    for (var key in multiFilterValue) {
      if (multiFilterValue[key].length > 0) {
        let values = ""
        multiFilterValue[key].map((i) => {
          values += i.Name + ";";
        })
        if (values !== null && values !== '') {
          list.push({
            filterKey: key,
            FilterValue: values
          })
        }
      }
    }

    for (var key in filterValueDict) {
      if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
        list.push({
          filterKey: key,
          FilterValue: filterValueDict[key]
        })
      }
    }
    return list;
  }



  const SortColumn = (colName) => {

    var sortType = '';
    if (columnAscending === true) {
      setColumnAscending(false);
      sortType = "Descending";
    } else {
      setColumnAscending(true);
      sortType = "Ascending";
    }
    setSortColName(colName);

    let pagenumber = 1;

    paging.pageNumber = pagenumber;
    setpaging(paging);


    getRebateCompletedApprovalData("Approval/GetCompletedRebate", paging.pageSize, pagenumber, sortType, colName, filterLst(), searchValue);

  }


  const clickShowCustomerPopup = (e, data, flag) => {

    if (flag === 'Open') {
      setShowCustomerpopup(true)
      setsidebarwidth("60vw");
      setCustomerData(data)
    }
    if (flag === 'Close') {
      setShowCustomerpopup(false)
    }
  }

  // -----------------------------------------------------------------
  const columnList = [
    { "label": "Rebates.RebateAgreementId", "key": "rebateagreement_id", "value": true },
    { "label": "Rebates.CustomerType", "key": "CustomerGroup", "value": true },
    { "label": "Rebates.DistributorName", "key": "DistributorName", "value": true },
    { "label": "Rebates.RebateProgramId", "key": "rebateprogram_id", "value": true },
    { "label": "Rebates.RebateProgramLineDesc", "key": "desc", "value": true },
    { "label": "Rebates.ValidityStartDate", "key": "ValidityFrom", "value": true },
    { "label": "Rebates.ValidityEndDate", "key": "ValidityTo", "value": true },
    { "label": "Rebates.SubmittedBy", "key": "submittedby", "value": true },
    { "label": "Rebates.Status", "key": "status", "value": true }
  ]
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState("Error");
  const [addRemoveCol, setAddRemoveCol] = useState(columnList);
  const [createColumnListOption, setCreateColumnListOption] = useState(columnList)
  const [showAddColumnPopup, setShowAddColumnPopup] = useState(false)
  const [showCreateColumnListPopup, setShowCreateColumnListPopup] = useState(false)
  const [hideColumn, setHideColumn] = useState([]);
  const [resetColumnKey, setResetColumnKey] = useState([]);
  const [colVisibilityOption, setColVisibilityOption] = useState([]);
  const [colvisiOptionVal, setColvisiOptionVal] = useState({ value: null, label: 'Default' })
  const [divleft1, setdivleft1] = useState('');
  const [divtop1, setdivtop1] = useState('');
  const [existingViewVal, setExistingViewVal] = useState(false);
  const [defaultView, setDefaultView] = useState(false);
  const [colViewName, setColViewName] = useState('');
  const [recorddId, setRecordId] = useState(0);


  const OnChangedefaultView = (event,) => {
    setDefaultView(event.target.checked)
  }
  const OnChangeexistingViewVal = (event,) => {
    setExistingViewVal(event.target.checked)
  }

  const OnChangeSetViewName = (event,) => {
    setColViewName(event.target.value)
  }

  const OnChangeColVisibilityList = (data) => {
    setColvisiOptionVal({ value: data.value, label: data.label });
    getColVisibilityListData(data.value)
  }
  const CreateColumnListPopup = () => {
    setShowCreateColumnListPopup(true)
    var divtop1 = document.getElementById("addRemoveCol");
    setdivtop1(divtop1.offsetTop + 30)
    setdivleft1((divtop1.offsetLeft) + 100 + "px");
  }

  const CloseCreateColumnListPopup = () => {
    setShowCreateColumnListPopup(false)
    setColViewName('')
    setDefaultView(false)
  }

  const AddRemoveColumn = () => {
    setShowAddColumnPopup(true)
    var divtop1 = document.getElementById("addRemoveCol");
    setdivtop(divtop1.offsetTop + 45)
    setdivleft((divtop1.offsetLeft) + "px");
    getColumnVisibilityList()
  }
  const OnchangeAddRemoveCol = (event, column) => {
    let isChecked = event.target.checked;
    let lst = [...addRemoveCol];
    for (let d in lst) {
      if (lst[d].key == column.key && isChecked) {
        lst[d]['value'] = true
      }
      else if (lst[d].key == column.key && !isChecked) {
        lst[d]['value'] = false
      }
    }
    setResetColumnKey([...resetColumnKey, column.key])
    setAddRemoveCol(lst)
  }
  const OnchangeCreateColumnListOption = (event, column) => {
    let isChecked = event.target.checked;
    let lst = [...createColumnListOption];
    for (let d in lst) {
      if (lst[d].key == column.key && isChecked) {
        lst[d]['value'] = true
      }
      else if (lst[d].key == column.key && !isChecked) {
        lst[d]['value'] = false
      }
    }
    setCreateColumnListOption(lst)
  }
  const updateColumnVisibilityList = (tag) => {
    let lst = [...addRemoveCol];
    if (tag == 'reset') {
      for (let d in lst) {
        lst[d]['value'] = true
      }
      setHideColumn([])
      setAddRemoveCol(lst)
    }
    else {
      let data = [];
      for (let d in lst) {
        if (!lst[d].value) {
          data.push(lst[d].key)
        }
      }
      setHideColumn(data)
    }
    let viewName = colvisiOptionVal.value;
    Service.setColumnVisibilityListData('ColumnVisibility/CreateOrUpdateColumnList', recorddId, loggedInUser, 'rebateProgramApprovalWorkFlowComplete', viewName, existingViewVal, lst).then((res) => { })
    setShowAddColumnPopup(false)
    setResetColumnKey([])
  }


  const CreateColumnVisibilityList = (tag) => {
    let veiwName = colViewName;
    let idDefault = defaultView;
    if (veiwName.trim() == 'undefined' || veiwName.trim() == '' || veiwName.trim() == null) {
      setErrorStatus("Error")
      setErrorMessage("Please set View Name")
      setShowErrorModal(true)
    }
    else {
      let lst = [...createColumnListOption];
      if (tag == 'reset') {
        for (let d in lst) {
          lst[d]['value'] = true
        }
        setHideColumn([])
        setAddRemoveCol(lst)
      }
      else {
        let data = [];
        for (let d in lst) {
          if (!lst[d].value) {
            data.push(lst[d].key)
          }
        }
        setHideColumn(data)
      }
      Service.setColumnVisibilityListData('ColumnVisibility/CreateOrUpdateColumnList', 0, loggedInUser, 'rebateProgramApprovalWorkFlowComplete', colViewName, defaultView, lst).then((res) => {
        getColumnVisibilityList()
        setColvisiOptionVal({ value: colViewName, label: colViewName });
        getColVisibilityListData(colViewName)
        CloseCreateColumnListPopup()
        setShowAddColumnPopup(false)
      })
    }
  }

  const closeAddRemoveColumnPopup = () => {
    let lst = [...addRemoveCol];
    let resetKey = [...resetColumnKey];
    for (let rk in resetKey) {
      for (let d in lst) {
        if (lst[d].key == resetKey[rk]) {
          lst[d]['value'] = !lst[d].value
        }
      }
    }
    setAddRemoveCol(lst)
    setShowAddColumnPopup(false)
    setResetColumnKey([])

  }

  const getColumnVisibilityList = () => {
    Service.getColumnVisibilityListData('ColumnVisibility/GetColumnList', loggedInUser, 'rebateProgramApprovalWorkFlowComplete', null).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {

            if (typeof res.data !== 'undefined' && res.data !== 'null') {
              let data = []
              for (let d in res.data) {
                data.push({ value: res.data[d].label, label: res.data[d].label })

              }

              setColVisibilityOption(data)
            }
          }
        }
      }
    })
  }


  const getColVisibilityListData = (viewName = null) => {
    if (!viewName) {
      viewName = colvisiOptionVal.value
    }
    if (viewName == 'Deleted') {
      viewName = null
    }

    Service.getColumnVisibilityListData('ColumnVisibility/GetColumnListJsonData', loggedInUser, 'rebateProgramApprovalWorkFlowComplete', viewName).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (typeof res.data.jsonData !== 'undefined' && res.data.jsonData !== 'null') {

              let jsonData = JSON.parse(res.data.jsonData)
              let data = [];
              for (let d in jsonData) {
                if (!jsonData[d].value) {
                  data.push(jsonData[d].key)
                }
              }
              setColvisiOptionVal({ value: res.data.label, label: res.data.label });
              setHideColumn(data)
              setAddRemoveCol(jsonData)
              setExistingViewVal(res.data.defaultView)
              setResetColumnKey([])
              setRecordId(res.data.id)
            }
          }
        }
      }
    })
  }

  const deleteColumnVisibilityView = () => {
    Service.deleteDataByPolicyID("ColumnVisibility/Delete", recorddId, loggedInUser).then(res => {
      if (res.status === 200) {
        getColumnVisibilityList()
        getColVisibilityListData('Deleted')
        setShowAddColumnPopup(false)
        toast.error('Deleted Successfully !', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    })
  }

  const dateFormat = SessionManage.getcurrentdateformat();
  const timeFormat = "hh:mm:ss A";

  // ------------------------------------------------------------

  return (<>
    <div className="container-fluid content-top-gap">

      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-breadcrumb">
          <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
          <li className="breadcrumb-item active" aria-current="page">{t('Rebates.RebateProgramApprovalWorkflowCompleted')}</li>
        </ol>
      </nav>
      <div style={{ display: "none" }}>
        <CSVLink data={csvList} headers={headers} ref={csvInstance} filename="CompletedRebate.csv" > </CSVLink>
      </div>
      <ToastContainer autoClose={1000} />
      <div className="">
        <div className="row">
          <div className="col-lg-12 pr-lg-2">
            <div className="card text-center card_border">

              <div className="card-body" style={{ minHeight: "70vh" }}>
                <div className="row">
                  <div className="col-md-10">
                    <div className='d-flex align-items-center mb-1'>
                      <input type="text" className="form-control form-control-user col-md-7" id="firstName" name="firstName" autoComplete='off' placeholder={`${t('SearchFor')} ${t('Rebates.AgreementId')} , ${t('Rebates.RebateProgram')} ,${t('Rebates.SubmittedBy')} `} onChange={(event) => searchHandler(event)} />
                      <button id="addRemoveCol" icon className="btn btn-update ml-2 pa-0" type="submit"
                        style={{ float: "left", cursor: "pointer", padding: '0px', height: '28px', width: '26px' }}
                        onClick={AddRemoveColumn} title="Manage View"><i class="fa fa-columns" aria-hidden="true"></i></button>
                    </div>
                  </div>
                  <div className="col-md-2">
                    {totalselectedcount <= 0 ? "" : Service.exportPerms(userPerms) == false ? "" : <button className="btn btn-update  btn-sm ml-2" type="submit" style={{ float: "right", width: "50px" }} onClick={(event) => ExporttoCSV()}>{t('Export')}</button>}
                    {showbutton === true ?
                      <>
                        <a className="btn btn-update btn-sm ml-2" style={{ backgroundColor: "buttonface", float: "right", width: "40px" }} onClick={() => {
                          const agreementType = CompletedRebateData.find(each => each.Id == checkedValue)?.AgreementType;
                          navigateToRebate(checkedValueid, checkedRebateLineId,agreementType)
                          }}>{t('View')}</a>
                      </> : ''}
                  </div>
                </div>

                <div className="scrollbar-class mb-1">
                <table className="table table-responsive-sm  table-bordered text-left mt-2" id="tableA">
                  <thead className={class_thead} style={{ backgroundColor: "var(--primary)" }}>
                    <tr>
                      <th className="tableheader" style={{ width: '30px' }}>
                        <input
                          type="checkbox"
                          onChange={checkAll}
                          checked={checkAllChecked}
                          id="option-all"
                        />
                      </th>
                      <th id="RebateId" style={{ backgroundColor: (filterValueDict.RebateId ? '#6cae55' : ''), width: "100px" }} hidden={hideColumn.includes('RebateId') ? true : false}>
                        <div className='d-flex text-nowrap'>
                          <span>{t('Rebates.RebateAgreementId')}</span>
                          <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('RebateId')}></span>
                          <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('RebateId')}></span>
                        </div>
                      </th>

                      <th id="customer" style={{ backgroundColor: (multiFilterValue.customer ? '#6cae55' : ''), maxWidth: "110px" }} hidden={hideColumn.includes('customer') ? true : false}>
                        <div className='d-flex text-nowrap'>
                          <span>{t('Rebates.Customer')}</span>
                        </div>

                      </th>
                      <th id="CustomerType" style={{ backgroundColor: (filterValueDict.CustomerType ? '#6cae55' : ''), width: "110px" }} hidden={hideColumn.includes('CustomerType') ? true : false}>
                        <div className='d-flex text-nowrap'>
                          <span>{t('Rebates.CustomerType')}</span>
                          <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('CustomerType')}></span>
                          <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('CustomerType')}></span>
                        </div>
                      </th>

                      <th id="DistributorName" style={{ backgroundColor: (multiFilterValue.DistributorName ? '#6cae55' : ''), width: "130px" }} hidden={hideColumn.includes('DistributorName') ? true : false}>
                        <div className='d-flex text-nowrap'>
                          <span>{t('ship&debit.DistributorName')}</span>
                          <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('DistributorName')}></span>
                          <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('DistributorName')}></span>
                        </div>
                      </th>

                      <th id="RebateProgramLineIdStr" style={{ backgroundColor: (filterValueDict.RebateProgramLineIdStr ? '#6cae55' : ''), width: "130px" }} hidden={hideColumn.includes('RebateProgramLineIdStr') ? true : false}>
                        <div className='d-flex text-nowrap'>
                          <span> {t('Rebates.RebateProgramId')}</span>
                          <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('RebateProgramLineIdStr')}></span>
                          <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('RebateProgramLineIdStr')}></span>
                        </div>
                      </th>

                      <th id="RebateProgramLineDesc" style={{ backgroundColor: (filterValueDict.RebateProgramLineDesc ? '#6cae55' : ''), width: "150px" }} hidden={hideColumn.includes('RebateProgramLineDesc') ? true : false}>
                        <div className='d-flex text-nowrap'>
                          <span> {t('Rebates.RebateProgramLineDesc')}</span>
                          <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('RebateProgramLineDesc')}></span>
                          <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('RebateProgramLineDesc')}></span>
                        </div>
                      </th>

                      <th id="ValidityFrom" style={{ backgroundColor: (filterValueDict.ValidityFrom ? '#6cae55' : ''), width: "90px" }} hidden={hideColumn.includes('ValidityFrom') ? true : false}>
                        <div className='d-flex text-nowrap'>
                          <span>{t('Rebates.ValidityStartDate')}</span>
                          <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('ValidityFrom')}></span>
                          <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('ValidityFrom')}></span>
                        </div>
                      </th>

                      <th id="ValidityTo" style={{ backgroundColor: (filterValueDict.ValidityTo ? '#6cae55' : ''), width: "90px" }} hidden={hideColumn.includes('ValidityTo') ? true : false}>
                        <div className='d-flex text-nowrap'>
                          <span>{t('Rebates.ValidityEndDate')}</span>
                          <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('ValidityTo')}></span>
                          <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('ValidityTo')}></span>
                        </div>
                      </th>

                      <th id="SubmittedBy" style={{ backgroundColor: (multiFilterValue.SubmittedBy ? '#6cae55' : ''), width: "100px" }} hidden={hideColumn.includes('SubmittedBy') ? true : false}>
                        <div className='d-flex text-nowrap'>
                          <span>{t('Rebates.SubmittedBy')}</span>
                          <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('SubmittedBy')}></span>
                          <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('SubmittedBy')}></span>
                        </div>
                      </th>

                      <th id="StatusName" style={{ backgroundColor: (multiFilterValue.StatusName ? '#6cae55' : ''), width: "70px" }} hidden={hideColumn.includes('StatusName') ? true : false}>
                        <div className='d-flex text-nowrap'>
                          <span>{t('Rebates.Status')}</span>
                          <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('StatusName')}></span>
                          <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('StatusName')}></span>
                        </div>
                      </th>

                    </tr>
                  </thead>
                  <tbody>
                    {
                      showspinner === true ?

                        <>
                          <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                          <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                          <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                        </>
                        :
                        CompletedRebateData.length === 0 ?
                          <tr>
                            {/* Removed one <td> and changed to 11 -- by dheeraj S */}
                            <td colSpan="11" style={{ textAlign: 'center' }}>
                              {t('NoDataAvailable')}
                            </td>
                          </tr>
                          :
                          CompletedRebateData.map((listVal) =>
                            <tr onDoubleClick={(event) => OpenRebate(event, listVal.Id, listVal.RebateProgramLineId,listVal.AgreementType)}>
                              <td><input id={listVal.RebateProgramLineId} type="checkbox" name="chkBox" value={`${listVal.RebateProgramLineIdStr},${listVal.StatusName}`} onChange={(event) => handleChange(event, listVal.Status, listVal.Id, listVal.RebateProgramLineId)} /></td>
                              <td style={{ maxWidth: "110px" }} hidden={hideColumn.includes('rebateagreement_id') ? true : false}>{listVal.RebateId}</td>

                              {listVal.lstCustomers.length > 0 ?
                                <td hidden={hideColumn.includes('customer') ? true : false}><a href='javascript:void(0)' onClick={(event) => clickShowCustomerPopup(event, listVal.lstCustomers, "Open")}><label>({listVal.lstCustomers.length}) Selected Customers</label></a></td>
                                : <td></td>}
                              <td hidden={hideColumn.includes('CustomerGroup') ? true : false}>{listVal.CustomerType}</td>

                              <td style={{ minWidth: "110px" }} hidden={hideColumn.includes('DistributorName') ? true : false}>{listVal.DistributorName}</td>
                              <td style={{ maxWidth: "110px" }} hidden={hideColumn.includes('rebateprogram_id') ? true : false}>{listVal.RebateProgramLineIdStr}</td>
                              <td style={{ maxWidth: "150px" }} hidden={hideColumn.includes('desc') ? true : false}><div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={listVal.RebateProgramLineDesc}>{listVal.RebateProgramLineDesc}</div></td>
                              <td hidden={hideColumn.includes('ValidityFrom') ? true : false}>{moment(listVal.ValidityFrom).format("MM/DD/YYYY")}</td>
                              <td hidden={hideColumn.includes('ValidityTo') ? true : false}>{moment(listVal.ValidityTo).format("MM/DD/YYYY")}</td>
                              <td style={{ maxWidth: "110px" }} hidden={hideColumn.includes('submittedby') ? true : false}>{listVal.SubmittedBy}</td>
                              <td style={{ maxWidth: "110px" }} hidden={hideColumn.includes('status') ? true : false}>{listVal.StatusName}</td>

                            </tr>
                          )
                    }

                  </tbody>
                </table>
                </div>
                

                <div className='row'>

                  {
                    totalselectedshow === true ?

                      <div className='col-lg-2 forgettext col-sm-12 border form-control' style={{ textAlign: "center", marginLeft: "0.938rem" }}>
                        T{t('TotalRecordsSelected')} - <span id="totalselected">{totalselectedcount}</span>
                      </div>

                      :
                      <div className='col-lg-2 col-sm-12' style={{ textAlign: "center", marginLeft: "0.938rem" }}>

                      </div>
                  }



                  <div style={{flex: `0 0 ${totalCountlst > 14 ? "63%" : "64%"}`,maxWidth: totalCountlst > 14 ? "63%" : "64%"}}></div>


                  <div className='col-lg-2 forgettext  col-sm-12 border form-control' style={{ textAlign: "center",marginLeft: "0.1rem" }}>
                    {t('TotalRecords')} - {totalCountlst}
                  </div>
                </div>
                {
                  showLoadMore === true ?
                    <div className='text-center container row mb-1'>
                      <div className='col-md-5'></div>
                      <div className='col-md-2'>
                        <button className='btn btn-sm btn-update ml-1' onClick={handleLoadMore} style={{ width: "70px" }}>{t('LoadMore')}</button>
                      </div>
                      <div className='col-md-5'></div>

                    </div>
                    :
                    ""
                }

                {showfilterpopup === true ?
                  <div className='pop-up' style={{ left: divleft, top: divtop }}>
                    {filterCol === 'RebateProgramLineIdStr' || filterCol === 'RebateProgramLineIdStr' || filterCol === 'RebateProgramLineDesc' || filterCol === 'CustomerType' || filterCol === 'RebateId' ?

                      <>
                        <div className="container">
                          <div className="row">
                            <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                          </div>
                        </div>
                        <div className="container mt-2">
                          <div className="row">
                            <input type='textbox' placeholder={t('Search')} className='filtertextboxbackground col-md-11 textbox-padding ml-2' style={{ outline: 'none' }} value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                          </div>
                        </div>
                      </>

                      :
                      <>
                      </>


                    }

                    {filterCol === 'ValidityFrom' || filterCol === 'ValidityTo' ?

                      <>
                        <div className="container">
                          <div className="row">
                            <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                          </div>
                        </div>
                        <div className="container mt-2">
                          <div className="row">
                            <input type='date' className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                          </div>
                        </div>
                      </>
                      :
                      <></>
                    }

                    {filterCol === 'customer' || filterCol === 'SubmittedBy' || filterCol === 'DistributorName' || filterCol === 'StatusName' ?
                      <>
                        <div className="container">
                          <div className="row">
                            <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>

                          </div>

                        </div>

                        <div className="container mt-2">
                          <div className="row">
                            <input type="text" placeholder={t('Search')} value={filterSearch} onChange={(event) => UserfilterHandler(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                          </div>

                          <div className="row contentoverflow mt-2">
                            <table className="filtertable">
                              <tbody>
                                {/* <tr>
                                  <td><input type="checkbox" name="filter-option-all" id="option-all" onChange={checkAllFilter} /></td>
                                  <td>{t('SelectAll')}</td>
                                </tr> */}
                                {
                                  dropdownData.map((item, index) => (

                                    <tr key={index}>

                                      <td><input id={item.Id} data-index="1" name="filter-option-all" checked={item.ischecked} type="checkbox" onChange={item.Name === "Select All" ? checkAllFilter : (event) => OndropdownChange(event, item.Id, item.Name, filterCol)} /></td>

                                      <td>{item.Name}</td>

                                    </tr>


                                  ))
                                }

                              </tbody>
                            </table>

                          </div>
                        </div>
                      </>
                      :
                      <></>
                    }
                    <div style={{ paddingTop: '10px', paddingLeft: '33px', paddingBottom: '7px' }}>
                      <button className="btn btn-sm btn-update" style={{ maxWidth: "45px" }} onClick={() => filterHandler('Apply')}>{t('Apply')}</button>
                      <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', maxWidth: "45px" }} onClick={() => filterHandler('Reset')}>{t('Reset')}</button>
                    </div>
                    <div>
                    </div>
                  </div>
                  :
                  <></>
                }

                {
                  showCustomerPopup === true ?
                    <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, marginRight: "10px", padding: "0" }}>
                      <div className="container" style={{ padding: "0" }}>
                        <div className="row">
                          <div className="col-lg-12 pr-lg-2">
                            <div className="card text-center card_border">
                              <div className="card-header chart-grid__header">
                                <div className="row">
                                  <div className="col-md-10 text-left">
                                    {t('Rebates.CustomerListing')}
                                  </div>
                                  <div className="col-md-2">
                                    <button className="btn btn-outline-dark  btn-sm ml-1" onClick={(event) => clickShowCustomerPopup(0, 0, 'Close')} type="submit" style={{ width: "40px" }}>{t('Close')}</button>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>

                        <div className="row mt-1" id="Customer">
                          <div className="col-lg-12 pr-lg-2">
                            <div className="card text-center card_border">
                              <div className="card-header chart-grid__header">

                              </div>

                              <div className='scrollbar-class'>

                                <div className="container">

                                  <table className="table table-bordered text-left mt-2 " id="Customer">
                                    <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                      <tr>

                                        <th id="Name" style={{ width: "110px" }}>{t('Policies.Name')}</th>
                                        <th id="Label" style={{ maxWidth: "110px" }}>{t('Policies.Label')}</th>
                                      </tr>
                                    </thead>
                                    <tbody>

                                      {
                                        customerData.map((cslist, index) => (

                                          <tr>

                                            <td style={{ width: "90px" }}>
                                              <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                title={cslist.Name}>{cslist.Name}</div>
                                            </td>
                                            <td style={{ width: "260px" }}>
                                              <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                title={cslist.label}>{cslist.label}</div>
                                            </td>
                                          </tr>
                                        ))
                                      }
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div>

                              </div>
                            </div>
                          </div>

                        </div>

                      </div>
                    </div>
                    :
                    ""
                }

                {showAddColumnPopup === true ?
                  <div className='pop-up' style={{ left: divleft, top: divtop, width: "250px" }}>
                    <div className="container">
                      <div className="row">
                        <Select
                          className='col-md-10 textbox-padding fontsizedropdown'
                          options={colVisibilityOption}
                          placeholder="Select"
                          value={colvisiOptionVal}
                          onChange={OnChangeColVisibilityList}
                          styles={customStyles}
                        />
                        <button id="createColumnListPopup" icon className="btn btn-update ml-1 pa-0" type="submit"
                          style={{ float: "left", cursor: "pointer", padding: '0px', height: '28px', width: '26px' }}
                          onClick={CreateColumnListPopup} title='Add New View'><i class="fa fa-plus" aria-hidden="true"></i></button>
                      </div>
                      <div className="row mb-2" style={{ textAlign: 'right' }}>
                        <div className="col-md-10 mt-1 mr-1" style={{ padding: '0px', fontSize: '12px' }}>
                          <input id="existingViewVal" data-index="1" name="filter-option-all" checked={existingViewVal} type="checkbox"
                            onChange={(event) => OnChangeexistingViewVal(event)} /> <b>Is Default</b>
                        </div>
                      </div>
                      <div className="row">
                        <table className='ml-2' style={{ fontSize: '0.80rem' }}>
                          {
                            addRemoveCol.map((item, index) => (
                              <tr>
                                <td key={index}><input id={item.label} data-index="1" name="filter-option-all" checked={item.value} type="checkbox"
                                  onChange={(event) => OnchangeAddRemoveCol(event, item)}
                                /> {t(item.label)}</td>
                              </tr>
                            ))
                          }
                        </table>
                      </div>
                      <div className="row mr-2" style={{ float: "right" }}>
                        <div style={{ paddingTop: '10px', paddingLeft: '15px', paddingBottom: '7px' }}>
                          <button className="btn btn-sm btn-update" style={{ width: "45px" }} onClick={() => updateColumnVisibilityList('reset')}>{t('Reset')}</button>
                          <button className="btn btn-sm btn-update ml-1" style={{ width: "45px" }} onClick={closeAddRemoveColumnPopup}>{t('Close')}</button>
                          <button className="btn btn-sm btn-update ml-1" style={{ width: "50px", background: '#4755ab', color: 'white' }} onClick={() => updateColumnVisibilityList('apply')} title='Update View'>{t('Update')}</button>
                          <button className="btn btn-sm btn-update ml-1" style={{ width: "50px", background: 'rgb(233 43 0)', color: 'white' }} onClick={deleteColumnVisibilityView} title='Delete View'>{t('Delete')}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  : <></>
                }


                {showCreateColumnListPopup === true ?
                  <div className='pop-up' style={{ left: divleft1, top: divtop1, width: "190px" }}>
                    <div className="container">
                      <div className="row">
                        <div className="col-12 ml-2" style={{ padding: '0px' }}>
                          <input type='textbox' placeholder='View Name' style={{ height: '30px' }}
                            value={colViewName} name={colViewName} onChange={(event) => OnChangeSetViewName(event)}></input>
                        </div>
                      </div>
                      <div className="row mb-2" style={{ textAlign: 'right' }}>
                        <div className="col-10 mt-2" style={{ padding: '0px', fontSize: '12px' }}>
                          <input id="defaultView" data-index="1" name="filter-option-all" checked={defaultView} type="checkbox"
                            onChange={(event) => OnChangedefaultView(event)} /> <b>Set Default</b>
                        </div>
                      </div>
                      <div className="row">
                        <table className='ml-2' style={{ fontSize: '0.80rem' }}>
                          {
                            createColumnListOption.map((item, index) => (
                              <tr>
                                <td key={index}><input id={item.label} data-index="1" name="filter-option-all" checked={item.value} type="checkbox"
                                  onChange={(event) => OnchangeCreateColumnListOption(event, item)}
                                /> {t(item.label)}</td>
                              </tr>
                            ))
                          }
                        </table>
                      </div>
                      <div className="row mr-2" style={{ float: "right" }}>
                        <div style={{ paddingTop: '10px', paddingLeft: '35px', paddingBottom: '7px' }}>
                          <button className="btn btn-sm btn-update ml-1" style={{ width: "50px" }} onClick={CloseCreateColumnListPopup}>{t('Cancel')}</button>
                          <button className="btn btn-sm btn-update ml-1" style={{ width: "40px", background: '#4755ab', color: 'white' }} onClick={() => CreateColumnVisibilityList('apply')}>{t('Save')}</button>
                        </div>
                      </div>
                    </div>


                  </div>
                  : <></>
                }

              </div>
              <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                onHide={() => setShowErrorModal(false)}></CustomErrorPopup>

            </div>
          </div>

        </div>
      </div>



    </div>
  </>)
}
export default ArchiveRebateComponent