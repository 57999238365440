import React, { useState, forwardRef, useEffect, useRef } from 'react';
import { Form, FormGroup, Col, Row, Button, InputGroup, Modal } from 'react-bootstrap';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import classes from '../CreateAccuralComponent/Form.module.css';
import classes1 from '../CreateAccuralComponent/NewAccruals.css';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProductCustomDialog from '../../../UI/ProductCustomDialog';
import CustomErrorPopup from '../../../UI/CustomErrorPopup';
import Popup from '../CreateAccuralComponent/Popup';
import { navigate, useLocation } from 'react-router-dom';
import $ from 'jquery';
import Service from '../../../Services/Service';
import { useNavigate } from 'react-router-dom';
import 'react-multiple-select-dropdown-lite/dist/index.css'
import Multiselect from 'multiselect-react-dropdown';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import Select from "react-select";
import SessionManage from '../../../SessionManagement/SessionManage';
import { CSVLink } from "react-csv";



function CreateAccuralComponent(props) {
  const [t, i18n] = useTranslation('translation');
  const csvInstance = useRef();
  const [csvList, setCsvList] = useState([]);
  const [isexport, setIsexport] = useState('')
  const [indexeligibleinvoice, setindexeligibleinvoice] = useState('');
  const location = useLocation();
  const [workflowchart, setworkflowchart] = useState([]);
  const [userPerms, setuserPermission] = useState([]);
  const [excludeheader, setexcludeheader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [accrualId, setAccrualId] = useState('0')
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [commentsForSubmit, setCommentsForSubmit] = useState("")
  const [showSubmitModal, setShowSubmitModal] = useState(false)
  const [showWorkflowAlert, setShowWorkflowAlert] = useState(false)
  const [showApprovedModal, setShowApprovedModal] = useState(false)
  const [commentsForApproved, setCommentsForApproved] = useState("")
  const [showDeniedModal, setShowDeniedModal] = useState(false)
  const [commentsForDenied, setCommentsForDenied] = useState("")
  const [errorMessage, setErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState("Error");
  const [showErrorModal, setShowErrorModal] = useState(false);
  let rowPopupData = [];
  const [totalselectedshow, settotalselectedshow] = useState('');
  const [totalselectedcount, settotalselectedcount] = useState(0);
  const [totalCountlst, settotalCountlst] = useState('');
  const [transactionData, setTransactionData] = useState(rowPopupData)

  let accrualItemsData = [];
  const [accrualid, setAccrualid] = useState()
  const [itemsData, setItemsData] = useState([0])
  const [allItemsData, setAllItemsData] = useState([]);
  const [currentSelection, setCurrentSelection] = useState("By Item");

  let existingTabs = [];
  var currentDate = new Date();
  var year = currentDate.getFullYear();
  var month = currentDate.getMonth();
  var day = currentDate.getDate();
  const navigate = useNavigate();

  const [rebateId, setrebateId] = useState();
  const [productTitle, setProductTitle] = useState("Product Picker");
  const [products, setProducts] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [show, setShow] = useState(false);
  const [showRecalculated, setshowRecalculated] = useState(false);
  const [state, setState] = useState({ rebateType: 0, rebateMeasure: 0, product: "", startDate: new Date(), endDate: (new Date(year + 1, month, day)), createdDate: new Date().toISOString().slice(0, 10), createdBy: 0, rebateId: 0, accrualId: accrualId, rebatePayoutCond: 0, settlFreq: 0, rebateProgramLineId: 0, currency: '$', Customername: "", Rebate_Period: "", EnforcePaymentTerms: "", MinThresholdValue: "", MinThresholdPayout: "", CreatedUserId: 0, rebateHeaderId: 0, customerId: 0, customerID: "", rebateMesaureLabel: "", accrualType: "", comparisonPeriod: "", comparisonStartDate: new Date().toISOString().slice(0, 10), comparisonEndData: new Date().toISOString().slice(0, 10), deltaToReachTarget: 0, targetAbs: 0, rebateValue: "", CurrentTier: "", paymentTermsDays: "", TotalQuantity: 0, TotalRevenue: 0, TotalApprovedAccrual: 0, TotalUnpostedAccrual: 0, PaymentReceived: false, SubDivideTargetName: "", FiscalYearPeriod: "", CustomerCodeName: "", TotalAccrualRevenue: 0, TotalOutstandingAmount: 0, TotalExcludedAmount: 0, CustomerGroup: false,FiscalYear:"" })
  let disable = false;
  const [addproductdata, setAddProductData] = useState(false)
  const [workflowData, setWorkflowData] = useState([0]);
  let wData = [0]
  const [loadedFrom, setLoadedFrom] = useState('')
  const [product_id, setProduct_Id] = useState([])
  const [accrlItems_id, setAccrlItems_Id] = useState([])
  const [Index, setIndex] = useState([])
  const [accrualStatus, setAccrualStatus] = useState('') //from accrual page 
  const [accStatus, setAccStatus] = useState('') // from approval page
  const [errorPopup, setErrorPopup] = useState(false)
  const [submitErrorPopup, setSubmitErrorPopup] = useState(false)
  const [showspinner, setshowspinner] = useState('');
  const [rebateIcon, setRebateIcon] = useState('');
  const headerHeight = 50;
  const defaultColDef = {
    width: 130,
    editable: true
  };
  const [volumeTier, setVolumeTier] = useState([]);
  const [subdivTarget, setSubDivTarget] = useState([]);
  const [Validity, setValidity] = useState('');
  const [showAccuralWorkflow, setshowAccuralWorkflow] = useState('');
  const [sidebarwidth, setsidebarwidth] = useState('');
  const [iconClass, setIconClass] = useState('fas fa-angle-left')

  const [workflowApprovers, setWorkflowApprovers] = useState([])
  const [workflowLevelCount, setWorkflowLevelCount] = useState('');
  const [firstApprover, setFirstApprover] = useState('');
  const [secondApprover, setSecondApprover] = useState('');
  const [thirdApprover, setThirdApprover] = useState('');
  const [CurrentWorkflowLevel, setCurrentWorkflowLevel] = useState('');
  const [currentLevel1, setCurrentLevel1] = useState("{`${classes['square-levels']} `}");
  const [currentLevel2, setCurrentLevel2] = useState("{`${classes['square-levels']}  `}");
  const [currentLevel3, setCurrentLevel3] = useState("{`${classes['square-levels']} `}");
  const [salesLevelData, setSalesLevelData] = useState([]);
  const [excludetransactionid, setexcludetransactionid] = useState([]);
  const [initialexcludetransactionid, setinitialexcludetransactionid] = useState([]);
  const [rebateMeasureValue, setRebateMeasureValue] = useState('')
  const [columnAscending, setColumnAscending] = useState(true);
  const [headerdisable, setheaderdisable] = useState(false);
  const [rebateTypeValue, setRebateTypeValue] = useState('')
  const [filterCol, setFilterCol] = useState('');
  const [dropdownData, setDropdownData] = useState([]);
  const [showfilterpopup, setshowfilterpopup] = useState(false);
  const [isAnalyzeRebate, setIsAnalyzeRebate] = useState(false);
  const [checkTotal, setCheckTotal] = useState(false);
  const [dropdownChange, setDropdownChange] = useState([{ filterKey: "", FilterValue: "" }]);
  const [filterCol1, setFilterCol1] = useState('');
  const [dropdownData1, setDropdownData1] = useState([]);
  const [showfilterpopup1, setshowfilterpopup1] = useState(false);
  const [dropdownChange1, setDropdownChange1] = useState([{ filterKey: "", FilterValue: "" }]);
  const [isLocked, setIsLocked] = useState(false);
  const [lockedBy, setLockedBy] = useState('');
  const [measureValueLabel, setmeasureValueLabel] = useState();
  const [totalItemCountlst, settotalItemCountlst] = useState('');
  const [totalItemSelectedCountlst, settotalItemSelectedCountlst] = useState(0);
  const [hfPayoutList, setHfPayoutList] = useState([])
  const [showRebateDefn, setRebateDefn] = useState(false)
  const [showRebateDefnIcon, setRebateDefnIcon] = useState("fa fa-plus")
  const [customerList, setCustomerList] = useState([])
  const [customerName, setCustomerName] = useState('')
  const [filterValue, setFilterValue] = useState([]);
  const [filterdropdownData, setFilterDropdownData] = useState([]);
  const [filterSearch, setFilterSearch] = useState("");
  const [filtertext, setfiltertext] = useState('');

  const [selectedProductsId, setSelectedProductsId] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [sortColName, setSortColName] = useState("");
  const [divleft, setdivleft] = useState('');
  const [divtop, setdivtop] = useState('');

  const [divleft1, setdivleft1] = useState('');
  const [divtop1, setdivtop1] = useState('');


  const [selectedNameOption, setSelectedNameOption] = useState({ value: 'ByItem', label: 'By Item' });

  const columnList = [
    { "label": "Accruals.Customer", "key": "CustomerName", "value": true },
    { "label": "Accruals.MaterialNo", "key": "ProductName", "value": true },
    { "label": "Accruals.ItemName", "key": "ProductLabel", "value": true },
    { "label": "Accruals.Exclude", "key": "Exclude", "value": true },
    { "label": "Qty", "key": "Quantity", "value": true },
    { "label": "Accruals.Revenue", "key": "Revenue", "value": true },
    { "label": "Accruals.ExcludedQty", "key": "ExcludedQuantity", "value": true },
    { "label": "Accruals.ExcludedRevenue", "key": "ExcludedRevenue", "value": true },
    { "label": "Accruals.AccrualQty", "key": "AccrualQuantity", "value": true },
    { "label": "Accruals.AccrualRevenue", "key": "AccrualRevenue", "value": true },
    { "label": "Accruals.Rebate/Unit", "key": "WeightedAveragePerUnit", "value": true },
    { "label": "Accruals.TotalAccrued", "key": "TotalApprovedAccrual", "value": true },
    { "label": "Accruals.AccrualsInReview", "key": "UnpostedAccruals", "value": true },
    { "label": "Accruals.CurrentAccrued", "key": "ApprovedAccrual", "value": true }
  ];

  const [addRemoveCol, setAddRemoveCol] = useState(columnList);
  const [createColumnListOption, setCreateColumnListOption] = useState(columnList)
  const [showAddColumnPopup, setShowAddColumnPopup] = useState(false)
  const [showCreateColumnListPopup, setShowCreateColumnListPopup] = useState(false)
  const [hideColumn, setHideColumn] = useState([]);
  const [resetColumnKey, setResetColumnKey] = useState([]);
  const [colVisibilityOption, setColVisibilityOption] = useState([]);
  const [colvisiOptionVal, setColvisiOptionVal] = useState({ value: null, label: 'Default' })
  const [existingViewVal, setExistingViewVal] = useState(false);
  const [defaultView, setDefaultView] = useState(false);
  const [colViewName, setColViewName] = useState('');
  const [recorddId, setRecordId] = useState(0);

  const [filterValuePopup, setFilterValuePopup] = useState([]);
  const [filterSearchPopup, setFilterSearchPopup] = useState("");
  const [filtertextPopup, setfiltertextPopup] = useState('');
  const [filterdropdownDataPopup, setFilterDropdownDataPopup] = useState([]);
  const [updateNav, setUpdateNav] = useState("/accruals")
  const [accrualExceptionPolicies, setAccrualExceptionPolicies] = useState([]);
  const [showExceptionPolicies, setShowExceptionPolicies] = useState('');
  const [needGeneration, setneedGeneration] = useState(false);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);

  const [filteredArray, setFilteredArray] = useState()
  const [searchValue, setSearchValue] = useState('')
  const [visibleData, setVisibleData] = useState([]);
  const pageSize = 300;
  const tableRef = useRef(null);
  const scrollContainerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false); // Loading state for data fetching
  const [showSpinner, setShowSpinner] = useState(true);
  const [currentPage, setCurrentPage] = useState(1); // Current page for pagination
  // Number of rows per page
  const [totalPages, setTotalPages] = useState(1);
  const [selectedItems, setSelectedItems] = useState(new Set()); // Using a Set to store selected item IDs
  const [checkAllCheckeds, setCheckAllCheckeds] = useState(false);

  let loggedInUser = SessionManage.getuserid();
  const [loading, setLoading] = useState(false);
  // const [operatorVal, setOperatorValue] = useState({
  //   Quantity: { value: "=", label: "equal to" },
  //   ComparisonQuantity: { value: "=", label: "equal to" },
  //   ComparisonNetprice: { value: "=", label: "equal to" },
  //   Netprice: { value: "=", label: "equal to" },
  //   ApprovedAccrual: { value: "=", label: "equal to" },
  //   UnpostedAccruals: { value: "=", label: "equal to" },
  //   WeightedAveragePerUnit: { value: "=", label: "equal to" },
  //   TotalApprovedAccrual: { value: "=", label: "equal to" },
  //   DeltaReachNextTier: { value: "=", label: "equal to" },
  //   TotalRevenue: { value: "=", label: "equal to" },
  //   ExcludedAmount: { value: "=", label: "equal to" },
  //   AccrualRevenue: { value: "=", label: "equal to" },
  //   ExcludedQuantity: { value: "=", label: "equal to" },
  //   AccrualQuantity: { value: "=", label: "equal to" },

  // })
  const [filterValue1, setFilterValue1] = useState({
    Quantity: '',
    ComparisonQuantity: '',
    ComparisonNetprice: '',
    Netprice: '',
    ApprovedAccrual: '',
    UnpostedAccruals: '',
    WeightedAveragePerUnit: '',
    TotalApprovedAccrual: '',
    DeltaReachNextTier: '',
    CurrentTier: '',
    TotalRevenue: '',
    ExcludedAmount: '',
    ExcludedQuantity: '',
    AccrualQuantity: '',
    AccrualRevenue: '',
    CustomerName: '',
    ProductLabel: '',
    CategoryLabel: '',
    SubCategoryLabel: ''
  });

  const [operatorVal, setOperatorValue] = useState({})

  const [operatorValOption, setOperatorValueOption] = useState([
    { value: "=", label: "equal to" },
    { value: "<", label: "less than" },
    { value: ">", label: "greater than" },
    { value: "<=", label: "less than or equal to" },
    { value: ">=", label: "greater than or equal to" }
  ])

  const [popFilterValue, setPopFilterValue] = useState({
    Quantity: '',
    Netprice: '',
    WeightedAverage: '',
    LineItem: '',
    IsExclude: ''
  });
  const [popOperatorValue, setPopOperatorValue] = useState({
    Quantity: { value: "=", label: "equal to" },
    Netprice: { value: "=", label: "equal to" },
    WeightedAverage: { value: "=", label: "equal to" },
    LineItem: { value: "=", label: "equal to" },
  })

  const [filterValueDict, setFilterValueDict] = useState({
    InvoiceNumber: "",
    TransactionDate: "",
    Region: ''
  });

  const [multiFilterValue, setMultiFilterValue] = useState({
    customer: '',
    modifiedby: '',
    createdby: '',
    IsExclude: ''
  });
  const [multiFilterValue1, setMultiFilterValue1] = useState({
    ProductName: '',
    Exclude: ''
  });

  // New state to manage the Check All checkbox
  const [checkAllChecked, setCheckAllChecked] = useState(false);
  const [checkedValue, setCheckedValue] = useState([]);
  const [checkedMultiValueid, setCheckedMultiValueid] = useState([]);
  const [checkedValueid, setCheckedValueid] = useState([]);
  const [checkedValueStatus, setCheckedValueStatus] = useState([]);
  const [allCheckedHistory, setAllCheckedHistory] = useState(false);
  const [uncheckedBoxesHistory, setuncheckedBoxesHistory] = useState([]);


  useEffect(() => {
    if (window.location.href.includes('Analyze')) {
      setIsAnalyzeRebate(true);

    }

    getColumnVisibilityList()
    getColVisibilityListData()

    if (props.name.toLowerCase() == 'fromaccrual') {
      getUserPermission('/accruals');
    }
    else if (props.name.toLowerCase() == 'fromapproval' || props.name.toLowerCase() == 'fromapprovalcompleted') {
      getUserPermission('/accrualapprovals/pendingaction/0');
    }
    else {
      getUserPermission(location.pathname);
    }
    setColumnAscending(false);
    setSortColName("Id");
    setshowspinner(true)


    sessionStorage.setItem('ids', props.id);

    if (props.id !== 0) {
      getAccrualDetails("Accurals/GetByAccuralId", props.id);// we will pass accrual id

    }
    else {
      setWorkflowData([0])
    }
    if (props.name !== '')
      setLoadedFrom(props.name)


    // Initial setup of the IntersectionObserver
    // const observer = new IntersectionObserver(handleScroll, {
    //    // Set the root to the scrollable div
    //   threshold: 1.0, // Trigger when the sentinel is fully visible
    // });

    // if (tableRef.current) {
    //   observer.observe(tableRef.current);
    // }

    // Cleanup on unmount
    // return () => {

    // };


  }, [props.id])


  // useEffect(() => {


  //   const observer = new IntersectionObserver(handleScroll, {
  //     // Set the root to the scrollable div
  //     threshold: 1.0, // Trigger when the sentinel is fully visible
  //   });

  //   if (tableRef.current) {
  //     observer.observe(tableRef.current);
  //   }

  //   // Cleanup before re-establishing the observer
  //   return () => {
  //     observer.disconnect();
  //   };
  // }, [visibleData, itemsData]);

  let headers = [];
  if (accStatus === 'New' || accStatus === 'InReview' || accStatus === 'Denied') {
    if (selectedNameOption.value == "ByItem") {
      headers = [
        { label: "Customer", key: "CustomerName" },
        { label: "Material No", key: "ProductName" },
        { label: "Item Name ", key: "ProductLabel" },
        { label: "Exclude ", key: "Exclude" },
        { label: "Quantity ", key: "Quantity" },
        { label: "Revenue ", key: "TotalRevenue" },
        { label: "Excluded Quantity", key: "ExceptionQuantity" },
        { label: "Excluded Revenue ", key: "ExcludedAmount" },
        { label: "Accrual Quantity", key: "AccrualQuantity" },
        { label: "Accrual Revenue ", key: "AccrualRevenue" },
        { label: "Rebate/Unit", key: "WeightedAveragePerUnit" },
        { label: "Total Accrued", key: "TotalApprovedAccrual" },
        { label: "Accruals In Review", key: "UnpostedAccruals" },
      ];
    }
    else if (selectedNameOption.value == "ByCategory") {
      headers = [
        { label: "Customer", key: "Customer" },
        { label: "Category", key: "CategoryName" },
        { label: "Exclude ", key: "Exclude" },
        { label: "Quantity ", key: "Quantity" },
        { label: "Revenue ", key: "TotalRevenue" },
        { label: "Excluded Quantity", key: "ExceptionQuantity" },
        { label: "Excluded Revenue ", key: "ExcludedAmount" },
        { label: "Accrual Quantity", key: "AccrualQuantity" },
        { label: "Accrual Revenue ", key: "AccrualRevenue" },
        { label: "Rebate/Unit", key: "WeightedAveragePerUnit" },
        { label: "Total Accrued", key: "TotalApprovedAccrual" },
        { label: "Accruals In Review", key: "UnpostedAccruals" },
      ];
    }
    else {
      headers = [
        { label: "Customer", key: "Customer" },
        { label: "Category", key: "CategoryName" },
        { label: "Sub Category", key: "SubCategoryName" },
        { label: "Vehicle Type", key: "VehicleType" },
        { label: "Exclude ", key: "Exclude" },
        { label: "Quantity ", key: "Quantity" },
        { label: "Revenue ", key: "TotalRevenue" },
        { label: "Excluded Quantity", key: "ExceptionQuantity" },
        { label: "Excluded Revenue ", key: "ExcludedAmount" },
        { label: "Accrual Quantity", key: "AccrualQuantity" },
        { label: "Accrual Revenue ", key: "AccrualRevenue" },
        { label: "Rebate/Unit", key: "WeightedAveragePerUnit" },
        { label: "Total Accrued", key: "TotalApprovedAccrual" },
        { label: "Accruals In Review", key: "UnpostedAccruals" },
      ];
    }

  }
  else {
    headers = [
      { label: "Customer", key: "CustomerName" },
      { label: "Material No", key: "ProductName" },
      { label: "Item Name ", key: "ProductLabel" },
      { label: "Exclude ", key: "Exclude" },
      { label: "Quantity ", key: "Quantity" },
      { label: "Revenue ", key: "TotalRevenue" },
      { label: "Excluded Quantity", key: "ExceptionQuantity" },
      { label: "Excluded Revenue ", key: "ExcludedAmount" },
      { label: "Accrual Quantity", key: "AccrualQuantity" },
      { label: "Accrual Revenue ", key: "AccrualRevenue" },
      { label: "Rebate/Unit", key: "WeightedAveragePerUnit" },
      { label: "Total Accrued", key: "TotalApprovedAccrual" },
      { label: "Current Accrued", key: "ApprovedAccrual" },
    ];
  }

  const customStyles = {
    control: base => ({
      ...base,
      minHeight: 28
    }),
    dropdownIndicator: base => ({
      ...base,
      padding: 2
    }),
    clearIndicator: base => ({
      ...base,
      padding: 2
    }),
    multiValue: base => ({
      ...base,

    }),
    valueContainer: base => ({
      ...base,
      padding: '0px 2px'
    }),
    input: base => ({
      ...base,
      margin: 0,
      padding: 0
    })
  };





  // const loadMoreData = () => {
  //   const newData = itemsData.slice(currentPage * pageSize, (currentPage + 1) * pageSize);
  //   setVisibleData((prev) => [...prev, ...newData]);
  //   setCurrentPage((prev) => prev + 1);
  // };
  // const loadMoreData = () => {
  //   setIsLoading(true);
  //   // Simulate network delay if needed

  //   setTimeout(() => {
  //     const start = currentPage * pageSize;
  //     const end = start + pageSize;
  //     console.log("start",start,"end",end)
  //     const nextData = itemsData.slice(start, end);
  //     console.log("Next Data",nextData)
  //     setVisibleData((prev) => [...prev, ...nextData]);
  //     setCurrentPage((prev) => prev + 1);
  //     setIsLoading(false);
  //   },50);
  //    // Adjust the delay as needed
  // };

  // const handleScroll = (entries) => {
  //   console.log("Nextt Data")
  //   const entry = entries[0];
  //   if (entry.isIntersecting && visibleData.length < itemsData.length && !isLoading) {
  //     loadMoreData();
  //   }
  // };
  const goToNextPage = () => {
    if (currentPage < totalPages) {
      const nextPage = currentPage + 1;
      const start = (nextPage - 1) * pageSize;
      const end = start + pageSize;
      const nextData = itemsData.slice(start, end);
      setVisibleData(nextData);
      setCurrentPage(nextPage);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      const prevPage = currentPage - 1;
      const start = (prevPage - 1) * pageSize;
      const end = start + pageSize;
      const prevData = itemsData.slice(start, end);
      setVisibleData(prevData);
      setCurrentPage(prevPage);
    }
  };

  const handleCheckAll = (mycheckbox) => {
    const isChecked = mycheckbox.target.checked;
    
    const newCheckedIds = itemsData.map((i) => {
      if (selectedNameOption?.value === 'ByItem') {
          return i.Id;
      } else if (selectedNameOption?.value === 'ByCategory') {
          return i.CategoryName;
      } else if (selectedNameOption?.value === 'BySubCategory') {
          return `${i.SubCategoryName},${i.CategoryLabel},${i.VehicleType}`;
      }
      return null; // Fallback if none match
  }).filter(id => id !== null);

    if (isChecked) {
      // Update state to mark all items as checked
      // const allIds = itemsData.map((item) => item.Id);
      setCheckedMultiValueid(newCheckedIds); // Set all item IDs as selected
      settotalItemSelectedCountlst(itemsData.length);
      setCheckAllCheckeds(true);
      settotalselectedshow(true);
      
    } else {
      // Uncheck all items
      setCheckedMultiValueid([]); // Clear the selection
      settotalItemSelectedCountlst(0);
      setCheckAllCheckeds(false);
      settotalselectedshow(false);
    }
  };

  const handleRowCheckboxChange = (e, id) => {
    const isChecked = e.target.checked;
    let updatedCheckedItemss = [...checkedMultiValueid];
    console.log(id, updatedCheckedItemss, "Updated Items")
    console.log(itemsData, "Updated Itemss")
    if (isChecked) {
      // Add the ID if it's checked
      updatedCheckedItemss.push(id);
    } else {
      // Remove the ID if it's unchecked
      updatedCheckedItemss = updatedCheckedItemss.filter((itemId) => itemId !== id);
    }

    setCheckedMultiValueid(updatedCheckedItemss);
    settotalItemSelectedCountlst(updatedCheckedItemss.length);

    // Manage the "Check All" checkbox state based on the total count
    if (updatedCheckedItemss.length === itemsData.length) {
      setCheckAllCheckeds(true);
    } else {
      setCheckAllCheckeds(false);
    }

    if (updatedCheckedItemss.length !== 0)
      setIsexport(true)
    else
      setIsexport(false)
    // let updatedCheckedItems = [...checkedMultiValueid];

    // if (isChecked) {
    //     // Add the ID if it's checked
    //     updatedCheckedItemss.push(id);
    // } else {
    //     // Remove the ID if it's unchecked
    //     updatedCheckedItemss = updatedCheckedItemss.filter((itemId) => itemId !== id);
    // }

    // setCheckedMultiValueid(updatedCheckedItems);
    // settotalItemSelectedCountlst(updatedCheckedItems.length);

    // // Manage the "Check All" checkbox state based on the total count
    // if (updatedCheckedItems.length === itemsData.length) {
    //     setCheckAllChecked(true);
    // } else {
    //     setCheckAllChecked(false);
    // }

    // setSelectedItems((prevSelectedItems) => {
    //   const updatedSet = new Set(prevSelectedItems);
    //   console.log(updatedSet,"Updated Set")
    //   if (isChecked) {
    //     updatedSet.add(itemId);
    //   } else {
    //     updatedSet.delete(itemId);
    //   }
    //   // Update check all state based on the selection
    //   setCheckAllCheckeds(updatedSet.size === itemsData.length);
    //   return updatedSet;
    // });
  };

  const getUserPermission = (pathname) => {
    Service.GetUserPermission(pathname).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res !== undefined) {
            if (res.data !== 'null' && res.data !== undefined && res.data !== '') {
              if (res.data.DataList !== undefined) {
                setuserPermission(res.data.DataList)
              }
            }
          }
        }
      }

    });
  }

  const ExporttoCSV = () => {
    // let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
    let values = "";

    if (checkedMultiValueid.length >= 1) {
      for (let checkbox of checkedMultiValueid) {
        values = values + ';' + checkbox;
      }
    }
    console.log("7-10", checkedMultiValueid)
    console.log("7-6", values);

    let objFilter = [];
    let type = '';
    const selectedvalues = values.split(";").filter(value => value !== "");
    // console.log(selectedNameOption.value,"Selected Option Value")
    if (selectedNameOption.value == "ByItem") {
      type = "Product";
      let objFilterValue = [{
        filterKey: "ids",
        FilterValue: values,
      }];
      objFilter = objFilterValue;

    }
    else if (selectedNameOption.value == "ByCategory") {
      type = "Category";

      const filteredItems = itemsData.filter(item => selectedvalues.includes(item.CategoryName));
      const filterValues = filteredItems.map(item => item.CategoryName).join(';');
      objFilter = [{
        filterKey: "CategoryName",
        FilterValue: filterValues,
      }];

    }
    else {
      type = "SubCategory";
      // const filteredItems = itemsData.filter(item => selectedvalues.includes(item.SubCategoryName));
      // const subCategoryNames = filteredItems.map(item => item.SubCategoryName).join(',');
      // const CategoryNames = filteredItems.map(item => item.CategoryLabel).join(',');

      objFilter.push({
        filterKey: "SubCategory",
        FilterValue: values,
      });

      // objFilter.push({
      //   filterKey: "SubCategoryName",
      //   FilterValue: subCategoryNames,
      // });

      // filteredItems.forEach((each) => {
      //   objFilter.push({
      //     filterKey: "VehicleType",
      //     FilterValue: each.VehicleType,
      //   });
      // });


    }

    console.log("11-6", objFilter);

    Service.getAccrualItemsSortBy("Accurals/AccrualItemsSortBy", props.id, "Ascending", "Id", objFilter, type,state.FiscalYear).then((res) => {
      if (res && res.data) {
        const data = res.data.DataList;
        console.log("Fetched Data", data);
        debugger
        if (data.length > 0) {

          data.push({ Exclude: "Total" });

          setCsvList(data);
          setTimeout(() => {
            csvInstance.current.link.click();
          }, 0);
        }
      }
    });
  }

  console.log("11-6", itemsData);


  const getAccrualWorkflowDetails = (accrualId) => {
    Service.getdatabyId('Accurals/GetAccuralWorkflowById', accrualId).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {

            if (res.data !== 'null' && res.data !== 'undefined') {
              wData = res.data.DataList;

              setWorkflowData(wData);
            }
          }
        }
      }
    });
  }


  const togglePopup = () => {
    setexcludetransactionid([])
    setIsOpen(true);
  }


  const fetchEligibleInvoice = (accrualId, rebateId) => {
    Service.getdatabyId('fetchEligibleInvoices', accrualId).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {

              
              accrualItemsData = res.data['data'];
              setItemsData(accrualItemsData)
              setTotalQuantity(accrualItemsData.reduce((acc, item) => acc + item.Quantity, 0));
              setTotalRevenue(accrualItemsData.reduce((acc, item) => acc + item.TotalRevenue, 0));

              setAccrualId(res.data['accrual_id']);
            }
          }
        }
      }

    });

  }





  if (state.status === 'Draft') {
    disable = false;
  }
  else {
    disable = true;
  }

  const getProductsList = () => {

    setShow(true);


    let ProductTableData = [];
    Service.getdatabyId('Accurals/GetAccuralProductsById', props.id).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {

              setProducts(res.data)
            }
          }
        }
      }

    });


  }

  let list = [];
  list = products.map((item) => {
    if (!productsList.includes(item)) {
      productsList.push(item);
    }
  });




  const GetTransactionData = (params, Id, Customer, Index, sortType, SortProperty, filterVal) => {

    setindexeligibleinvoice(Index);


    let customername = Customer
    let productname = params
    let startdate = state.startDate
    let enddate = state.endDate
    let rebateProgramId = state.rebateProgramLineId

    setAccrlItems_Id(Id)
    setProduct_Id(params)
    setCustomerName(customername)

    let param = { customername, productname, startdate, enddate, rebateProgramId }


    Service.getdatabyData('Accurals/GetAccuralTransactionData', customername, productname, startdate, enddate, rebateProgramId, state.accrualId, Id, 20, 1, sortType, SortProperty, filterVal).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
              rowPopupData = res.data.DataList
              setTransactionData(res.data.DataList)

              settotalCountlst(res.data.DataList.length)


              var test = rowPopupData.find(x => x.IsExclude === false);
              if (test !== undefined) {

                setexcludeheader(false)

              } else {

                setexcludeheader(true)

              }

              var approv = rowPopupData.find(x => x.Status === "New")
              if (approv === undefined) {
                setheaderdisable(true)
              } else {
                setheaderdisable(false)
              }

              if (rowPopupData !== undefined) {
                var excludeid = [];
                rowPopupData.forEach((item) => {

                  if (item.IsExclude === true) {

                    setexcludetransactionid(excludetransactionid => [...excludetransactionid, item.TransactionId]);
                    var previousarray = [...initialexcludetransactionid];

                    var index1 = previousarray.indexOf(item.TransactionId)
                    if (index1 === -1) {
                      setinitialexcludetransactionid(initialexcludetransactionid => [...initialexcludetransactionid, item.TransactionId]);


                    }
                    excludeid.push(item.TransactionId)

                  }
                });
                if (excludeid.length !== 0) {
                  settotalselectedcount(excludeid.length);
                  settotalselectedshow(true);
                } else {
                  settotalselectedcount(0);
                  settotalselectedshow(false);
                }



              }


            }
          }
        }
      }

    });

    togglePopup();
  }




  let rdata = [{}]



  const GetEligibleInvoices = () => {

    setLoading(true);
    setshowRecalculated(false);

    let rebateProgramLineId = state.rebateProgramLineId
    let rebateId = state.rebateHeaderId
    let createdUserId = SessionManage.getuserid();//state.createdUserId
    let createdFromRebate = false;
    let IsProvisionalRebate = false;
    const excludedId = [...new Set(excludetransactionid)];
    let selectedCustomers = [{
      'CustomerId': state.customerId,
      'CustomerName': state.Customername
    }]
    let accrualPeriod = state.Rebate_Period;
    let accrualType = state.accrualType
    let data = { rebateId, rebateProgramLineId, selectedProducts, createdUserId, excludedId, createdFromRebate, selectedCustomers, accrualPeriod, accrualType, IsProvisionalRebate }

    Service.createorupdate('Accurals/GenerateEligibleInvoice', data).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {

          if (res.status === 200) {

            if (res.data !== 'null' && res.data !== 'undefined') {
              if (res.data.status !== "Failure") {
                setLoadedFrom('fromAccrual')
                setLoading(false);

                let id_accrual = res.data.AccuralId
                setAccrualStatus(res.data.AccuralStatus)
                getAccrualDetails("Accurals/GetByAccuralId", res.data.AccuralId);

                setAccrualid(res.data.AccuralId)



                navigate("/accruals/new/" + res.data.AccuralId + "/fromAccrual");
              }
              else {
                setLoading(false);
                setErrorPopup(true)
                setErrorStatus("Error")
                setErrorMessage(t('Accruals.FailedToCreateAccruals'))
              }
            }


          }
          else {
            setLoading(false);
            setErrorStatus("Error")
            setErrorMessage(t('Accruals.FailedToCreateAccruals'))
            setShowErrorModal(true)
          }
        } else {
          setLoading(false);
          setErrorStatus("Error")
          setErrorMessage(t('Accruals.FailedToCreateAccruals'))
          setShowErrorModal(true)
        }

      } else {
        setLoading(false);
        setErrorStatus("Error")
        setErrorMessage(t('Accruals.FailedToCreateAccruals'))
        setShowErrorModal(true)
      }


    });

  }


  const CloseHandler = (flag) => {
    Service.LockUnLockEntity('Configurationprop/UnLockEntity', "Accrual", state.accrualId, loggedInUser).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
        }
      }

    });
    if (flag === 'openclose') {
      if (accrualStatus == 'New')
        setShowDeleteModal(!showDeleteModal)
    }
    else if (flag === 'openclose') {
      if (accrualStatus == '')
        setShowDeleteModal(!showDeleteModal)
    }
    else if (flag === 'proceed') {
      if (accrualid > '0') {

        deleteUnsavedRecords()
        setShowDeleteModal(!showDeleteModal)
      }
      else {

        setShowDeleteModal(!showDeleteModal)
        navigate("/accruals")
      }
    }
    else {
      navigate("/accruals")
    }
  }


  const navigateHandler = () => {
    Service.LockUnLockEntity('Configurationprop/UnLockEntity', "Accrual", state.accrualId, loggedInUser).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {

        }
      }

    });
    if (loadedFrom == 'fromApproval') {
      navigate("/accrualapprovals/pendingaction/1")
    }
    else {
      navigate("/accrualapprovals/archive/0")
    }
  }

  const deleteUnsavedRecords = () => {

    Service.delete('Accurals/DeleteAccural', accrualid).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {

            if (res.data !== 'null' && res.data !== 'undefined') {
              navigate("/accruals")
            }
          }
        }
      }

    });

  }
  const SubmitAccruals = () => {

    let AccuralId = accrualid
    let Comments = commentsForSubmit

    let UserId = SessionManage.getuserid();//state.createdUserId

    let data = { AccuralId, Comments, UserId }
    setLoading(true);

    Service.createorupdate('Accurals/SubmitAccural', data).then((res) => {

      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {

          if (res.status === 200) {
            if (res.data.Status != 'Failure') {

              if (res.data !== 'null' && res.data !== 'undefined') {
                setLoading(false);
                toast.success((t('Accruals.AccrualSubmittedsuccessfully')), {
                  position: toast.POSITION.TOP_RIGHT,
                });

                setTimeout(() => {

                  CloseHandler('close');
                }, 200)
              }
            } else {
              setShowSubmitModal(!showSubmitModal)
              setLoading(false);
              setErrorStatus("Error")
              setErrorMessage(res.data.ErrorMessage)
              setShowErrorModal(true)
              setCommentsForSubmit('')
            }

          }
        }
      }

      else if (res.status !== 200) {
        setLoading(false);
        setErrorPopup(true)
      }
    });

  }



  const ApproveAccruals = () => {

    let AccuralId = state.accrualId
    let Comments = commentsForApproved
    let UserId = SessionManage.getuserid();//state.createdUserId
    let data = { AccuralId, Comments, UserId }

    Service.createorupdate('Accurals/ApproveAccural', data).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {

            if (res.data !== 'null' && res.data !== 'undefined') {
              toast.success((t('Accruals.Accrualapprovedsuccessfully')), {
                position: toast.POSITION.TOP_RIGHT,
              });
              Service.LockUnLockEntity('Configurationprop/UnLockEntity', "Accrual", AccuralId, loggedInUser).then((res) => {
                if (typeof res !== 'undefined') {
                  if (typeof res.data !== 'undefined' && res.data !== 'null') {

                  }
                }

              });
              navigate("/accrualapprovals/pendingaction/1")
            }

          }
          else if (res.status !== 200) {
            setErrorPopup(true)
          }
        }
      }

    });
  }

  const DenyAccruals = () => {

    let AccuralId = state.accrualId
    let Comments = commentsForDenied
    let UserId = SessionManage.getuserid();
    let data = { AccuralId, Comments, UserId }

    Service.createorupdate('Accurals/DenyAccural', data).then((res) => {

      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {

            if (res.data !== 'null' && res.data !== 'undefined') {
              setLoading(false)
              toast.success((t('Accruals.Accrualdeniedsuccessfully')), {
                position: toast.POSITION.TOP_RIGHT,
              });

              Service.LockUnLockEntity('Configurationprop/UnLockEntity', "Accrual", AccuralId, loggedInUser).then((res) => {
                if (typeof res !== 'undefined') {
                  if (typeof res.data !== 'undefined' && res.data !== 'null') {

                  }
                }

              });
              navigate("/accrualapprovals/pendingaction/2")
            }

          }
          else if (res.status !== 200) {
            setErrorPopup(true)
          }
        }
      }

    });

  }

  const OndropdownChangeExcludeItems = (e) => {

    let selectedOption = (e);

    let data = []

    for (let i = 0; i < selectedOption.length; i++) {

      const getallData = {
        Name: selectedOption[i].Name,
      }
      data.push(getallData)
    }

    setMultiFilterValue1(() => ({
      ...multiFilterValue1, [filterCol1]: data,
    }));

  }
  const showSubmitDialog = (flag) => {

    if (flag === 'showSubmitDialog') {
      setCommentsForSubmit("")
      setShowSubmitModal(!showSubmitModal)
    }
    else if (flag === 'proceed') {

      if (showRecalculated === false) {
        setshowRecalculated(false)
        SubmitAccruals()
        setShowSubmitModal(!showSubmitModal)
      }
      else {
        alert(t('Accruals.Validations.PleaseYouCanRecalculateAgainExcludeTransactionNotRecacluated'))
      }

    }

  }


  const OndropdownChangeIsExcludeItems = (e) => {

    let selectedOption = (e);

    let data = []

    for (let i = 0; i < selectedOption.length; i++) {

      const getallData = {
        Name: selectedOption[i].Name,
      }
      data.push(getallData)
    }

    setMultiFilterValue(() => ({
      ...multiFilterValue, [filterCol]: data,
    }));

  }



  const workflowAlert = (flag) => {

    if (flag == 'open') {
      if (workflowApprovers.length > 0) {
        setShowWorkflowAlert(true)
      }
      else {
        zeroAccrual()
      }
    }
    else if (flag == 'close') {
      setShowWorkflowAlert(false)
      zeroAccrual()
    }
  }



  const zeroAccrual = () => {

    setSubmitErrorPopup(false)
    showSubmitDialog('showSubmitDialog')

  }

  const validateSubmit = () => {

    var count = false;

    if (state.PaymentReceived == true) {
      if ((state.accrualAggregate) > 0 || state.accrualAggregate < 0) {
        if (submitErrorPopup == false) {
          if (itemsData.length > 0) {
            itemsData.map(i => {
              if (i.UnpostedAccruals === 0) {
                count = true;
              }
            })
            if (count == true) {
              setSubmitErrorPopup(true)
            }
            else {
              showSubmitDialog('showSubmitDialog')
            }
          }
        }
      }
      else {
        setErrorStatus("Error")
        setErrorMessage(t('Accruals.Validations.TheTotalAccruedAmountIsZeroAndCannotBeSubmitted'))
        setShowErrorModal(true);
      }
    }
    else {
      setErrorStatus("Error")
      setErrorMessage(t('Accruals.Validations.AccrualCannotBeSubmittedPaymentNotReceivedForOneOrMoreInvoice'))
      setShowErrorModal(true);
    }

  }


  const showApprovedDialog = (flag) => {
    if (flag === 'showApprovedDialog') {
      setCommentsForApproved("")
      setShowApprovedModal(!showApprovedModal)
    }
    else if (flag === 'proceed') {
      ApproveAccruals()
      setLoading(true)
      setShowApprovedModal(!showApprovedModal)

    }
  }


  const showDeniedDialog = (flag) => {

    if (flag === 'showDeniedDialog') {
      setCommentsForDenied("")
      setShowDeniedModal(!showDeniedModal)
    }
    else if (flag === 'proceed') {
      setLoading(true)
      DenyAccruals()
      setShowDeniedModal(!showDeniedModal)

    }
  }


  const handleChange = (selectedList, selectedItem) => {

    const selected = [];
    const selectedId = [];
    const selectedData = [];
    let selectedOption = (selectedList);

    for (let i = 0; i < selectedOption.length; i++) {
      selected.push(selectedOption[i].ProductName);
      selectedId.push(selectedOption[i].ProductId);
      const getallData = {
        ProductId: selectedOption[i].ProductId,
        ProductName: selectedOption[i].ProductName
      }
      selectedData.push(getallData);
    }

    selectedProducts.length = 0;
    for (let i = 0; i < selectedData.length; i++) {
      const getall = {
        ProductId: selectedData[i].ProductId,
        ProductName: selectedData[i].ProductName
      }
      selectedProducts.push(getall)
    }
  }

  const getAccrualDetails = (method, id) => {
    $("#libread").html();
    setLoading(true)
    Service.getdatabyId(method, id).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
              setLoading(false)
              console.log('12/2', res.data)
              setneedGeneration(res.data.DataList.NeedRegeneration);
              if (res.data.status !== "Failure") {
                if (((props.name == 'fromApproval' || props.name == 'fromapprovalcompleted') && res.data.DataList.StatusName == 'InReview' && res.data.DataList.Locked == false) || (res.data.DataList.StatusName == 'New' && res.data.DataList.Locked == false)) {
                  Service.LockUnLockEntity('Configurationprop/LockEntity', "Accrual", id, loggedInUser).then((res) => {
                    if (typeof res !== 'undefined') {
                      if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        setLockedBy(loggedInUser);
                        setIsLocked(true);

                        if (res.data.DataList) {
                          res.data.DataList.LockedBy = loggedInUser
                          res.data.DataList.Locked = true;
                        }
                      }
                    }
                  });
                }
                else {
                  setLockedBy(res.data.DataList.LockedBy);
                  setIsLocked(res.data.DataList.Locked);
                }

                if (window.location.href.includes('fromAccrual') && res.data.DataList.Locked === true && res.data.DataList.LockedBy.toString() !== loggedInUser) {
                  $("#libread").html(res.data.DataList.AccrualId + " " + `Locked By ${res.data.DataList.LockedByUserName}`);
                }

                if (res.data.DataList.StatusName == 'New') {
                  if ((res.data.DataList.RebatePeriod !== 'FY') && (res.data.DataList.RebatePeriod !== 'Q1') && (res.data.DataList.RebatePeriod !== 'H1') && (res.data.DataList.RebatePeriod !== 'M1') && (res.data.DataList.settlFreq !== 'Annual')) {
                    if (res.data.DataList.IsPreviousApproved == false && res.data.DataList.NeedRegeneration == false) {
                      $("#libread").html(res.data.DataList.AccrualId + "  Accrual values might vary as the previous version is not approved yet");
                    }
                    if (res.data.DataList.IsPreviousApproved == true && res.data.DataList.NeedRegeneration == true) {
                      $("#libread").html(res.data.DataList.AccrualId + "  Please regenerate the accrual again as the previous version is approved after the current accrual creation");
                    }
                  }
                }
                else {
                  $("#libread").html(res.data.DataList.AccrualId);
                }

                if (window.location.href.includes('fromRebate') && res.data.DataList.Locked === true && res.data.DataList.LockedBy.toString() !== loggedInUser) {
                  $("#libread").html(res.data.DataList.AccrualId + " " + `Locked By ${res.data.DataList.LockedByUserName}`);
                }

                if (window.location.href.includes('fromApproval') && res.data.DataList.Locked === true && res.data.DataList.LockedBy.toString() !== loggedInUser) {
                  $("#libread").html(res.data.DataList.AccrualId + " " + `Locked By ${res.data.DataList.LockedByUserName}`);
                }
                if (window.location.href.includes('fromApproval') && res.data.DataList.Locked === true && res.data.DataList.LockedBy.toString() == loggedInUser) {
                  $("#libread").html(res.data.DataList.AccrualId)
                }


                var month_num = 0;

                if (window.location.href.includes('accrualsposting') && res.data.DataList.Locked === true && res.data.DataList.LockedBy.toString() !== loggedInUser) {
                  $("#libread").html(res.data.DataList.AccrualId + " " + `Locked By ${res.data.DataList.LockedByUserName}`);
                }
                if (window.location.href.includes('accrualsposting') && res.data.DataList.Locked === true && res.data.DataList.LockedBy.toString() == loggedInUser) {
                  $("#libread").html(res.data.DataList.AccrualId);
                }

                else {
                  $("#libread").html(res.data.DataList.AccrualId);
                }

                if (props.name != '' && props.name != undefined) {
                  if (props.name.toLocaleLowerCase() == 'fromapproval') {
                    $("#headertxt").html('Accrual Approval- Pending Action');
                    setUpdateNav("/accrualapprovals/pendingaction/0")
                  }
                  else if (props.name.toLocaleLowerCase() == 'fromapprovalcompleted') {
                    setUpdateNav("/accrualapprovals/archive/0")
                    $("#headertxt").html('Accrual Approval- Workflow Completed');
                  }
                }
                else {
                  $("#headertxt").html(t('Policies.DistributorBudgetedAmountPolicyList'));
                  setUpdateNav("/accruals")
                }


                setshowspinner(false)
                setState({
                  rebateId: res.data.DataList.RebateProgramId, rebateType: res.data.DataList.rebateTypeLabel,
                  rebatePayoutCond: res.data.DataList.rebatePayoutLabel, rebateProgramDesc: res.data.DataList.rebateProgramdesc,
                  startDate: moment(res.data.DataList.StartDate).format("YYYY-MM-DD"), endDate: moment(res.data.DataList.EndDate).format("YYYY-MM-DD"),
                  createdDate: moment(res.data.DataList.CreatedDate).format("YYYY-MM-DD"), createdBy: res.data.DataList.CreatedUserName,
                  createdUserId: res.data.DataList.CreatedUserId, accrualId: res.data.DataList.Id, region: res.data.DataList.SalesLevel,
                  rebateMeasure: res.data.DataList.rebateMesaureLabel, settlFreq: res.data.DataList.SettlementFrequencyLabel,
                  rebateHeaderId: res.data.DataList.RebateIdId, accrualAggregate: res.data.DataList.AccrualsAggregate,
                  customerID: res.data.DataList.Customername,
                  rebateProgramLineId: res.data.DataList.RebateProgramIdId,
                  Customername: res.data.DataList.Customername,
                  Rebate_Period: res.data.DataList.RebatePeriod,
                  EnforcePaymentTerms: res.data.DataList.EnforcePaymentTerms,
                  MinThresholdPayout: res.data.DataList.MinThresholdPayout,
                  MinThresholdValue: res.data.DataList.MinThresholdValue,
                  currency: res.data.DataList.Currency,
                  rebateMesaureLabel: res.data.DataList.rebateMesaureLabel,
                  accrualType: res.data.DataList.accrualType,
                  comparisonPeriod: res.data.DataList.comparisonPeriod,
                  comparisonStartDate: moment(res.data.DataList.comparisonStartDate).add(month_num, 'month').format("YYYY-MM-DD"),
                  comparisonEndData: moment(res.data.DataList.comparisonEndDate).add(month_num, 'month').format("YYYY-MM-DD"),
                  deltaToReachTarget: res.data.DataList.DeltaToReachNextTier,
                  targetAbs: res.data.DataList.TargetAbs,
                  rebateValue: res.data.DataList.RebateValue,
                  currentTier: res.data.DataList.CurrentTier,
                  paymentTermsDays: res.data.DataList.PaymentTermsDays,
                  TotalQuantity: res.data.DataList.TotalQuantity,
                  TotalRevenue: res.data.DataList.TotalRevenue,
                  TotalApprovedAccrual: res.data.DataList.TotalApprovedAccrual,
                  TotalUnpostedAccrual: res.data.DataList.TotalUnpostedAccrual,
                  customerId: res.data.DataList.CustomerId,
                  PaymentReceived: res.data.DataList.PaymentReceived,
                  SubDivideTargetName: res.data.DataList.SubDivideTargetName,
                  FiscalYearPeriod: res.data.DataList.FiscalYearPeriod,
                  CustomerCodeName: res.data.DataList.CustomerCodeName,
                  TotalAccrualRevenue: res.data.DataList.TotalAccrualRevenue,
                  TotalOutstandingAmount: res.data.DataList.TotalOutstandingAmount,
                  TotalExcludedAmount: res.data.DataList.TotalExcludedAmount,
                  CustomerGroup: res.data.DataList.CustomerGroup,
                  ComparisonQuantity: res.data.DataList.ComparisonQuantity,
                  ComparisonRevenue: res.data.DataList.ComparisonRevenue,
                  AccrualQuantity: res.data.DataList.AccrualQuantity,
                  ExcludedQuantity: res.data.DataList.ExcludedQuantity,
                  HasMinThresholdCriteria: res.data.DataList.HasMinThresholdCriteria,
                  TotalValue: res.data.DataList.TotalValue,
                  TotalLatePayment: res.data.DataList.TotalLatePayment,
                  IsPreviousApproved: res.data.DataList.IsPreviousApproved,
                  NeedRegeneration: res.data.DataList.NeedRegeneration,
                  URL: res.data.DataList.URL,
                  TargetAmount: res.data.DataList.TargetAmount,
                  TargetPercent: res.data.DataList.TargetPercent,
                  FiscalYear: res.data.DataList.FiscalYear,
                })
                const calculatedTotalPages = Math.ceil(res.data.DataList.lstAccuralItemVIew.length / pageSize);
                setTotalPages(calculatedTotalPages);

                // Initialize visibleData with the first page
                const initialData = res.data.DataList.lstAccuralItemVIew.slice(0, pageSize);
                setVisibleData(initialData);
                setCurrentPage(1);
                setItemsData(res.data.DataList.lstAccuralItemVIew)
                setAllItemsData(res.data.DataList);
                setAccStatus(res.data.DataList.StatusName)
                setVolumeTier(res.data.DataList.lstvolumeties)
                console.log("2906", res.data.DataList.lstvolumeties)
                setRebateIcon(res.data.DataList.lstvolumeties[0].RebateValue)
                setSubDivTarget(res.data.DataList.lstSubDivideItems)
                setAccrualStatus(res.data.DataList.StatusName)
                setAccrualid(res.data.DataList.Id)
                setSalesLevelData(res.data.DataList.SalesLevel)
                setCurrentWorkflowLevel(res.data.DataList.CurrentWorkflowLevel)
                setRebateMeasureValue(res.data.DataList.rebateMeasureName)
                setRebateTypeValue(res.data.DataList.RebateTypeName)
                settotalItemCountlst(res.data.Totalcount)
                setCustomerList(res.data.DataList.lstCustomers)
                setAccrualExceptionPolicies(res.data.DataList.lstAccrualExceptionPolicy)
                setTotalQuantity(res.data.DataList.lstAccuralItemVIew.reduce((acc, item) => acc + item.Quantity, 0));
                setTotalRevenue(res.data.DataList.lstAccuralItemVIew.reduce((acc, item) => acc + item.TotalRevenue, 0));
                if (res.data.DataList.rebateMeasureName === 'Quantity' || res.data.DataList.rebateMeasureName === 'Growth in Qty (Abs)') {
                  setmeasureValueLabel('Unit')
                }
                else if (res.data.DataList.rebateMeasureName === 'Revenue' || res.data.DataList.rebateMeasureName === 'Growth in Rev (Abs)') {
                  setmeasureValueLabel('Amount')
                }
                else if (res.data.DataList.rebateMeasureName === 'Growth in Qty (%)' || res.data.DataList.rebateMeasureName === 'Growth in Rev (%)') {
                  setmeasureValueLabel('%')
                }


                let dt = moment(res.data.DataList.StartDate).add(month_num, 'month').format(SessionManage.getcurrentdateformat()) + " to " + moment(res.data.DataList.EndDate).add(month_num, 'month').format(SessionManage.getcurrentdateformat());

                setValidity(dt)
              }
              if (res.data.DataList.StatusName == 'New') {
                insertWorkflowDetails(res.data.DataList.Id, loggedInUser, res.data.DataList.RebateProgramIdId)
              }
              setTimeout(() => {
                getAccrualWorkflowDetails(res.data.DataList.Id);
                getAccrualWorkflowApprovers('Accurals/GetAccrualWorkflowApprovers', res.data.DataList.Id)
              }, 500)
            }
            else {
              setErrorStatus("Error")
              setErrorMessage(res.data.Message)
              setShowErrorModal(true)
            }
          }
        }
      }
    });
  }




  const handleSearch = (event) => {
    if (event.target.value != '') {
      setSearchValue(String(event.target.value).toLowerCase())
    }
    else {
      setFilteredArray()
    }

  }

  const CancelHandler = () => {
    Service.LockUnLockEntity('Configurationprop/UnLockEntity', "Accrual", state.accrualId, loggedInUser).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {

        }
      }

    });

    navigate("/accruals")
  }

  const insertWorkflowDetails = (accrualId, userId, rplId) => {
    Service.workflowData('Accurals/InsertWorkflow', accrualId, userId, rplId).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {

          if (res.status == 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {

            }
          }
        }
      }
    })
  }

  const clickShowAccuralWorkflow = (val) => {

    setshowAccuralWorkflow(true);
    setsidebarwidth("60vw");

  }

  const clickCloseAccuralWorkflow = () => {

    setshowAccuralWorkflow(false);
    setsidebarwidth("0vw");
  }

  const excludeProducts = (mycheckbox) => {
    {
      var currentcount = 0;
      var autoExcludeCount = 0;
      let tra = [...transactionData];
      let updatetrans = [];
      let transid = initialexcludetransactionid;
      tra.map((i) => {

        if (i.Message == null || i.Message == 'Manually excluded') {
          currentcount += 1;
          i.IsExclude = mycheckbox.target.checked;
          updatetrans.push(i);
          transid.push(i.TransactionId)
        } else {
          updatetrans.push(i);
          currentcount += 1;
          autoExcludeCount += 1;
        }
      });

      setTransactionData(updatetrans);
      setinitialexcludetransactionid(transid);

      let checkboxes = document.querySelectorAll('input[name=chkBox]:checked');

      if (mycheckbox.target.checked == true) {
        settotalselectedshow(true);
        setexcludeheader(true)
        settotalselectedcount(currentcount)
        let tra1 = [...itemsData];
        tra1[indexeligibleinvoice].Exclude = true
        setItemsData(tra1);


        checkboxes.forEach(function (checkbox) {
          if ("input[id = 'option-all']") {
            checkbox.checked = true;
          }
        });
      }
      else {
        if (autoExcludeCount > 0) {
          settotalselectedshow(true);
          settotalselectedcount(autoExcludeCount)
        } else {
          settotalselectedshow(false);
          settotalselectedcount(0)
        }
        let tra1 = [...itemsData];
        tra1[indexeligibleinvoice].Exclude = false
        setItemsData(tra1);
        setexcludeheader(false)
        var array = [...initialexcludetransactionid];
        const array1 = [...new Set(array)];
        tra.forEach(function (checkbox) {
          checkbox.checked = false;


          // make a separate copy of the array
          var index = array1.indexOf(checkbox.TransactionId)
          if (index !== -1) {
            array1.splice(index, 1);
            setinitialexcludetransactionid(array1);
          }




        });

      }
    }
  }


  const _ = require('lodash');
  const getAccrualWorkflowApprovers = (method, id) => {

    Service.getdatabyId(method, id).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {

          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {

              setWorkflowApprovers(res.data)
              setWorkflowLevelCount(res.data.length)


              const sortdata = _.orderBy(res.data, ['Workflowlevel'], ['asc'])
              setworkflowchart(sortdata);

            }

          }
        }
      }

    });
  }
  const activeOption = [
    { Name: "Yes", Id: "1" },
    { Name: "No", Id: "0" },

  ]
  const handlevalue = (e, id, index) => {

    let isChecked = e.target.checked;
    let tra = [...transactionData];
    tra[index].IsExclude = !tra[index].IsExclude
    setTransactionData(tra);
    var test = tra.find(x => x.IsExclude === false);

    if (test !== undefined) {

      setexcludeheader(false)
    } else {
      setexcludeheader(true)
    }
    let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');

    if (isChecked === true) {
      settotalselectedshow(true);
      settotalselectedcount(checkedBoxes.length);
      document.getElementById(id).checked = true;
      var array = [...initialexcludetransactionid];
      var available = array.indexOf(id);
      if (available === -1) {
        setinitialexcludetransactionid(initialexcludetransactionid => [...initialexcludetransactionid, id])

      }
    }
    else {
      if (checkedBoxes.length === 0) {
        settotalselectedshow(false);
      }
      settotalselectedcount(checkedBoxes.length);
      document.getElementById(id).checked = false;
      var array = [...initialexcludetransactionid]; // make a separate copy of the array
      var index = array.indexOf(id)
      if (index !== -1) {
        array.splice(index, 1);
        setinitialexcludetransactionid(array);
      }
    }

  }

  const apply = () => {
    setexcludetransactionid(initialexcludetransactionid)


    setIsOpen(!isOpen);
    setshowRecalculated(true);
    setshowfilterpopup(false)
    setFilterValueDict(() => ({
      ...filterValueDict, [filterCol]: '',
    }));
    console.log(filterValueDict, "filter");
  }
  const cancel = () => {

    setAccrlItems_Id([])
    setProduct_Id([])
    setIndex([])
    setIsOpen(!isOpen);
    setshowfilterpopup(false)
    setFilterValueDict(() => ({
      ...filterValueDict, [filterCol]: '',
    }));
    console.log(filterValueDict, "filter");
  }

  const AccrualItemsSortBy = (method, Id, sortType, SortProperty, Value) => {


    let type = '';
    if (selectedNameOption.value == "ByItem") { type = "Product"; }
    else if (selectedNameOption.value == "ByCategory") {
      type = "Category";
    }
    else {
      type = "SubCategory";
    }

    Service.getAccrualItemsSortBy(method, Id, sortType, SortProperty, Value, type,state.FiscalYear).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status !== undefined) {

            if (res.data.DataList !== 'null' && res.data.DataList !== 'undefined') {
                
               
              const calculatedTotalPages = Math.ceil(res.data.DataList.length / pageSize);
              setTotalPages(calculatedTotalPages);

              if(calculatedTotalPages === 1)
              {
                const initialData = res.data.DataList.slice(0, pageSize);
                setVisibleData(initialData);
              }
              else
              {
                const initialData = res.data.DataList.slice((currentPage - 1) * pageSize, (currentPage - 1) * pageSize + pageSize);
                setVisibleData(initialData);
              }
              // setCurrentPage(1);
             
              
              setItemsData(res.data.DataList)

              setTotalQuantity(res.data.DataList.reduce((acc, item) => acc + item.Quantity, 0));
              setTotalRevenue(res.data.DataList.reduce((acc, item) => acc + item.TotalRevenue, 0));

            }
          }
        }
      }

    });
  }




  const SortColumn = (colName) => {
    var sortType = '';
    if (columnAscending === true) {
      setColumnAscending(false);
      sortType = "Descending";
    } else {
      setColumnAscending(true);
      sortType = "Ascending";
    }
    setSortColName(colName);
    AccrualItemsSortBy("Accurals/AccrualItemsSortBy", accrualid, sortType, colName, filterEligibleInvoiceLst());
  }


  const SortColumnItems = (colName) => {
    if (colName === 'Qty') {
      colName = 'Quantity'
    }
    setColumnAscending(!columnAscending);
    var sortType = columnAscending === true ? 'Ascending' : 'Descending';

    GetTransactionData(product_id, accrlItems_id, customerName, Index, sortType, colName, []);
  }

  const filterHandler = (flag) => {

    if (flag === 'Apply') {
      setshowfilterpopup(false)
      let list = [];

      for (var key in multiFilterValue) {

        if (multiFilterValue[key].length > 0) {
          let values = ""
          multiFilterValue[key].map((i) => {
            values += i.Name + ";";
          })
          if (values !== null && values !== '') {
            list.push({
              filterKey: key,
              FilterValue: values
            })
          }
        }
      }

      //commented out this and added this
      // for (var key in filterValueDict) {
      //   
      //   if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
      //     list.push({
      //       filterKey: key,
      //       FilterValue: filterValueDict[key]
      //     })
      //   }
      // }
      for (var key in filterValueDict) {

        if (filterValueDict[key] !== null && filterValueDict[key] !== '') {

          if (key != 'InvoiceNumber' && key != 'TransactionDate' && key != 'Region') {
            if (operatorVal[filterCol]) { // Check if operatorVal[key] is defined
              const operator = operatorVal[filterCol]?.value || '';
              //  I have added this if condition for floating table by changing 'Quantity' to 'Qty' everywhere necessary (because it is causing issue in the frontend)
              //  but this can be erased if the change was made in the backend .same in case of sortColumnitems function        
              if (key === 'Qty') {
                list.push({
                  filterKey: 'Quantity',
                  FilterValue: filterValueDict[key],
                  operatorValue: operator
                })
              } else {
                list.push({
                  filterKey: key,
                  FilterValue: filterValueDict[key],
                  operatorValue: operator
                })
              }
            } else {
              console.warn(`operatorVal?[${filterCol}] is undefined`);
            }
          } else {
            list.push({
              filterKey: key,
              FilterValue: filterValueDict[key],
            })
          }
        }
      }



      for (var key in popFilterValue) {
        if (popFilterValue[key] !== null && popFilterValue[key] !== '') {
          list.push({
            filterKey: key,
            FilterValue: popFilterValue[key],
            operatorValue: popOperatorValue[key].value
          })
        }
      }
      GetTransactionData(product_id, accrlItems_id, customerName, Index, "Ascending", "Id", list)

      let tempDropdownData = dropdownData;
      if (tempDropdownData.length > 0){
        tempDropdownData[0]["ischecked"] = true;}
      setDropdownData(tempDropdownData);
    }
    else {
      setshowfilterpopup(false)

      setMultiFilterValue(() => ({
        ...multiFilterValue, [filterCol]: '',
      }));
      setFilterValueDict(() => ({
        ...filterValueDict, [filterCol]: '',
      }));

      let list = [];
      for (var key in multiFilterValue) {
        if (key !== filterCol && multiFilterValue[key].length > 0) {
          let values = ""
          multiFilterValue[key].map((i) => {
            values += i.Name + ";";
          })
          if (values !== null && values !== '') {
            list.push({
              filterKey: key,
              FilterValue: values
            })
          }
        }
      }

      for (var key in filterValueDict) {
        if (filterValueDict[key] !== null && filterValueDict[key] !== '' && key !== filterCol) {
          list.push({
            filterKey: key,
            FilterValue: filterValueDict[key]
          })
        }
      }

      for (var key in popFilterValue) {


        if (popFilterValue[key] !== null && popFilterValue[key] !== '' && key !== filterCol) {
          list.push({
            filterKey: key,
            FilterValue: popFilterValue[key],
            operatorValue: popOperatorValue[key].value
          })
        }
      }

      GetTransactionData(product_id, accrlItems_id, customerName, Index, "Ascending", "Id", list)
      setFilterCol('')
      setDropdownData([])
      setDropdownChange("")

      setPopFilterValue(() => ({
        ...popFilterValue, [filterCol]: '',
      }));
      setPopOperatorValue(() => ({
        ...popOperatorValue, [filterCol]: { value: "=", label: "equal to" },
      }));
      //added this
      setOperatorValue(() => ({
        ...operatorVal, [filterCol]: '',
      }));
    }
  }
  const filterEligibleInvoiceLst = () => {


    let list = [];

    for (var key in multiFilterValue1) {

      if (multiFilterValue1[key].length > 0) {
        let values = ""
        multiFilterValue1[key].map((i) => {
          values += key === 'Exclude' ? i.Name + ";" : i.ProductName + ";";
        })

        if (values !== null && values !== '') {
          list.push({
            filterKey: key,
            FilterValue: values
          })
        }
      }
    }

    for (var key in filterValue1) {
      if (filterValue1[key] !== null && filterValue1[key] !== '') {

        list.push({
          filterKey: key,
          FilterValue: filterValue1[key],
          operatorValue: key === 'CurrentTier' ? null : 'ProductLabel' ? null : operatorVal[key].value
        })
      }
    }

    return list;
  }



  const filterHandlerItems = (flag) => {
    debugger;
    var sortType = columnAscending === true ? 'Ascending' : 'Descending';
    if (flag === 'Apply') {
      setshowfilterpopup1(false);
      let list = [];

      for (var key in multiFilterValue1) {
        if (multiFilterValue1[key].length > 0) {
          let values = "";
          multiFilterValue1[key].forEach((i) => {
            values += key === 'Exclude' ? i.Name + ";" : i.ProductName + ";";
          });

          if (values !== null && values !== '') {
            list.push({
              filterKey: key,
              FilterValue: values
            });
          }
        }
      }

      for (var key in filterValue1) {
        if (filterValue1[key] !== null && filterValue1[key] !== '') {
          if (key != 'ProductLabel' && key != 'CategoryLabel' && key != 'SubCategoryLabel' && key != 'VehicleType') {
            if (operatorVal[key]) { // Check if operatorVal[key] is defined
              list.push({
                filterKey: key,
                FilterValue: filterValue1[key],
                operatorValue: operatorVal[key].value
              });
            } else {
              console.warn(`operatorVal?[${key}] is undefined`);
            }
          }
          else {
            list.push({
              filterKey: key,
              FilterValue: filterValue1[key]
            });
          }

        }
      }
      setFilterValue([]);
      // setCurrentPage(1)
      AccrualItemsSortBy("Accurals/AccrualItemsSortBy", accrualid, sortType, sortColName, list);
    } else {
      setshowfilterpopup1(false);

      setMultiFilterValue1(() => ({
        ...multiFilterValue1, [filterCol1]: '',
      }));

      let list = [];
      for (var key in multiFilterValue1) {
        if (key !== filterCol1 && multiFilterValue1[key].length > 0) {
          let values = "";
          multiFilterValue1[key].forEach((i) => {
            values += key === 'Exclude' ? i.Name + ";" : i.ProductName + ";";
          });
          if (values !== null && values !== '') {
            list.push({
              filterKey: key,
              FilterValue: values
            });
          }
        }
      }
      for (var key in filterValue1) {
        if (filterValue1[key] !== null && filterValue1[key] !== '' && key !== filterCol1) {
          if (operatorVal[key]) { // Check if operatorVal[key] is defined
            list.push({
              filterKey: key,
              FilterValue: filterValue1[key],
              operatorValue: key === 'CurrentTier' || key === 'CustomerName' ? null : operatorVal[key].value
            });
          } else {
            console.warn(`operatorVal[${key}] is undefined`);
          }
        }
      }
      AccrualItemsSortBy("Accurals/AccrualItemsSortBy", accrualid, sortType, sortColName, list);
      setFilterCol1('');
      setDropdownData1([]);
      setDropdownChange1("");
      setFilterSearch("");
      setFilterValue([]);
      setFilterDropdownData([]);


      setFilterValue1(() => ({
        ...filterValue1, [filterCol1]: '',
      }));

      setOperatorValue(() => ({
        ...operatorVal, [filterCol1]: '',
      }));
    }
  }




  const OnChangeOperatorValue = (data) => {
    setOperatorValue(() => ({
      ...operatorVal, [filterCol1]: { value: data.value, label: data.label },
    }));
  }

  // commented out this and added below -- by dheeraj s
  // const OnChangePopOperatorValue = (data) => {
  //   setPopOperatorValue(() => ({
  //     ...popOperatorValue, [filterCol]: { value: data.value, label: data.label },
  //   }));
  // }



  const OnChangePopOperatorValue = (data) => {
    setOperatorValue(() => ({ ...operatorVal, [filterCol]: { value: data.value, label: data.label } }));
  };

  // const OnChangePopOperatorValue = (selectedOption) => {
  //   setOperatorValue((prev) => ({ ...prev, [filterCol]: selectedOption }));
  // };

  const filterInput1 = (e) => {
    debugger;
    setFilterValue1(() => ({
      ...filterValue1, [filterCol1]: e.target.value,
    }));
  }

  const numericFilterInput = (e) => {
    setPopFilterValue(() => ({
      ...popFilterValue, [filterCol]: e.target.value,
    }));
  }


  const filterInput = (e) => {
    let inputValue = e.target.value
    setFilterValueDict(() => ({
      ...filterValueDict, [filterCol]: e.target.value,
    }));
  }



  const OndropdownChangeItems = (e, id, name, key) => {

    let isChecked = e.target.checked;
    var lastfilter = [...dropdownData1]
    let data1 = []
    if (isChecked === true) {
      let currentdata = lastfilter.map((i) => {
        if (i.Id == id) {
          i["ischecked"] = true;
        }
        return i;
      });

      setDropdownData1(currentdata);

      if (key == 'Product') {
        const getallData = { ProductName: name }
        data1.push(getallData)
      }
      if (key == 'Exclude') {
        const getallData = { Name: name }
        data1.push(getallData)
      }
      setFilterValue(filterValue => [...filterValue, ...data1]);
      setFilterValue(data1);
      let updateddata = ([...filterValue, ...data1]);
      setMultiFilterValue1(() => ({
        ...multiFilterValue1, [filterCol1]: updateddata,
      }));
    }
    else {
      let currentdata = lastfilter.map((i) => {
        if (i.Id === id) {
          i["ischecked"] = false;
        }
        return i;
      });

      setDropdownData1(currentdata);
      if (key == 'product') {
        setFilterValue(filterValue => filterValue.filter(data => data.ProductName !== name))
        let values = filterValue.filter(data => data.Name !== name);
        setMultiFilterValue1(() => ({
          ...multiFilterValue1, [filterCol1]: values,
        }));
      }
      else {
        setFilterValue(filterValue => filterValue.filter(data => data.Name !== name))
        let values = filterValue.filter(data => data.Name !== name);
        setMultiFilterValue1(() => ({
          ...multiFilterValue1, [filterCol1]: values,
        }));
      }
      data1 = []
    }
  }

  const OndropdownChange = (e, id, name, key) => {
    //selectOnlyOne(e.target);
    let isChecked = e.target.checked;
    let allChecked = false;


    let allCheckboxes = document.getElementsByName("filter-option-all");
    let totalChecked = 1;
    allCheckboxes.forEach((checkbox) => {
      if (checkbox.checked)
        totalChecked += 1;
    });

    let tempData = [...dropdownData];
    //If all checkboxes are checked
    if (tempData.length - 1 === totalChecked) {
      tempData[0].ischecked = isChecked;
      allChecked = true;
    }


    setDropdownData(tempData);

    console.log(tempData, "12x");

    var lastfilter = [...dropdownData]
    let data1 = []
    if (isChecked === true) {
      let currentdata = lastfilter.map((i) => {
        if (i.Id == id) {
          i["ischecked"] = true;
        }
        return i;
      });

      setDropdownData(currentdata);

      const getallData = { Name: name }
      data1.push(getallData)
      setFilterValuePopup(filterValuePopup => [...filterValuePopup, ...data1]);
      setFilterValuePopup(data1);
      let updateddata = ([...filterValuePopup, ...data1]);

      if (allChecked) {
        const selectAllObj = {
          "Name": "Select All"
        };
        updateddata.splice(0, 0, selectAllObj);
      }

      setMultiFilterValue(() => ({
        ...multiFilterValue, [filterCol]: updateddata,
      }));
    }
    else {
      let currentdata = lastfilter.map((i) => {
        if (i.Id === id) {
          i["ischecked"] = false;
        }
        return i;
      });

      setDropdownData(currentdata);
      setFilterValuePopup(filterValuePopup => filterValuePopup.filter(data => data.Name !== name))
      let values = filterValuePopup.filter(data => data.Name !== name);
      setMultiFilterValue(() => ({
        ...multiFilterValue, [filterCol]: values,
      }));
    }
  }

  //added this function
  function selectOnlyOne(selectedCheckbox) {
    let allCheckboxes = document.getElementsByName("filter-option-all");
    for (let checkbox of allCheckboxes) {
      if (checkbox !== selectedCheckbox) {
        checkbox.checked = false;
      }
    }
  }



  const UserfilterHandler = (e) => {

    let searchResults = [];
    setFilterSearch(e.target.value)
    if (e.target.value !== "") {
      if (filterCol1 == 'ProductName') {
        searchResults = dropdownData1.filter((each) => {
          return each.ProductName.toLowerCase().includes(String(e.target.value).toLowerCase())
        });
      }
      else {
        searchResults = dropdownData1.filter((each) => {
          return each.Name.toLowerCase().includes(String(e.target.value).toLowerCase())
        });
      }
      setDropdownData1(searchResults);
    }
    else {

      setFilterSearch("")
      setDropdownData1(filterdropdownData)
    }
  }
  const UserfilterHandlerPopup = (e) => {

    setFilterSearchPopup(e.target.value)
    if (e.target.value !== "") {
      let searchResults = dropdownData.filter((each) => {
        return each.Name.toLowerCase().includes(String(e.target.value).toLowerCase())
      });
      setDropdownData(searchResults);
    }
    else {

      setDropdownData(filterdropdownDataPopup)
      setFilterSearchPopup("")
    }
  }

  const checkAllFilter = (mycheckbox) => {
    var lastfilter = [...dropdownData1]
    let data1 = []
    var checkboxes = document.querySelectorAll("input[name = 'filter-option-all']");
    if (mycheckbox.target.checked == true) {

      let currentdata = lastfilter.map((i) => {
        i["ischecked"] = true;
        return i;
      });
      setDropdownData1(currentdata)

      dropdownData1.map((each) => {

        if (filtertext == 'Material No') {
          const getallData = {
            ProductName: each.ProductName,
          }
          data1.push(getallData)
        }
        else {
          const getallData = {
            Name: each.Name,
          }
          data1.push(getallData)
        }
      })
      setFilterValue(filterValue => [...filterValue, ...data1]);
      let updateddata = ([...filterValue, ...data1]);
      setMultiFilterValue1(() => ({
        ...multiFilterValue1, [filterCol1]: updateddata,
      }));
    }
    else {

      let currentdata = lastfilter.map((i) => {
        i["ischecked"] = false;
        return i;
      });
      setFilterValue([])
      setMultiFilterValue1([])
    }

  }

  const checkAllFilterPopup = (mycheckbox) => {
    var lastfilter = [...dropdownData]
    let data1 = []
    var checkboxes = document.querySelectorAll("input[name = 'filter-option-all']");
    if (mycheckbox.target.checked == true) {

      let currentdata = lastfilter.map((i) => {
        i["ischecked"] = true;
        return i;
      });
      setDropdownData(currentdata)

      dropdownData.map((each) => {

        const getallData = {
          Name: each.Name,
        }
        data1.push(getallData)
      })
      setFilterValuePopup(filterValuePopup => [...filterValuePopup, ...data1]);
      let updateddata = ([...filterValuePopup, ...data1]);
      setMultiFilterValue(() => ({
        ...multiFilterValue, [filterCol]: updateddata,
      }));
    }
    else {

      let currentdata = lastfilter.map((i) => {
        i["ischecked"] = false;
        return i;
      });
      setFilterValuePopup([])
      setMultiFilterValue([])
    }

  }

  const addSeletAll = (optionsArray, alreadySelected) => {

    let result = optionsArray;
    let selectAllObj = {
      "Name": "Select All",
      "Value": "Select All",
      "ischecked": alreadySelected
    }

    result.splice(0, 0, selectAllObj);
    console.log(result, "9999")
    return result;
  }

  const addnewfield = (data, key) => {


    let currentdata = data.map((i) => {
      i["ischecked"] = false;
      return i;
    });
    let selectedKeys = multiFilterValue[key] ? multiFilterValue[key] : [];

    console.log(currentdata, "test-data");
    let alreadySelected = selectedKeys.find((obj) => obj.Name === "Select All") ? true : false;
    if (key != 'Exclude') {
      if (selectedKeys) {
        currentdata.forEach((each) => {
          selectedKeys.forEach((eachSelected) => {
            if (each.Name == eachSelected.Name) {
              each.ischecked = true
            }

          })
        })
      }


      let result = addSeletAll(currentdata, alreadySelected);
      setDropdownData(result);
      setFilterDropdownData(result)
    }
    else {
      let alreadySelected = selectedKeys.find((obj) => obj.Name === "Select All") ? true : false;
      if (selectedKeys) {
        currentdata.forEach((each) => {
          selectedKeys.forEach((eachSelected) => {
            if (each.Name == eachSelected.Name) {
              each.ischecked = true
            }
          })
        })
        setDropdownData1(currentdata)
        setFilterDropdownData(currentdata)
      }
      else {
        setDropdownData1(currentdata)
        setFilterDropdownData(currentdata)
      }
    }
  }
  const addnewfieldPopup = (data, key) => {

    let currentdata = data.map((i) => {
      i["ischecked"] = false;
      return i;
    });
    let selectedKeys = multiFilterValue[key];
    if (selectedKeys != '') {
      currentdata.forEach((each) => {
        selectedKeys.forEach((eachSelected) => {
          if (each.Name == eachSelected.Name) {
            each.ischecked = true
          }
        })
      })
      setDropdownData(currentdata)
      setFilterDropdownDataPopup(currentdata)
    }
    else {
      setDropdownData(currentdata)
      setFilterDropdownDataPopup(currentdata)
    }
  }






  const FilterColumn = (name) => {
    var divtop = document.getElementById("tableB");
    var cardtop = document.getElementById("card-RPL2");

    console.log(divtop.offsetTop, "div");
    console.log(cardtop.offsetTop, "card");
    setdivtop(divtop.offsetTop + cardtop.offsetTop - 90)
    setshowfilterpopup(true)
    setFilterCol(name)
    if (name === 'InvoiceNumber') {
      setfiltertextPopup(t('Accruals.Invoice'))
      var testDiv = document.getElementById("InvoiceNumber");
      setdivleft(testDiv.offsetLeft + 175 + "px");
    }
    if (name === 'TransactionDate') {
      setfiltertextPopup(t('Accruals.InvoiceDate'))
      var testDiv = document.getElementById("TransactionDate");
      setdivleft(testDiv.offsetLeft + 203 + "px");
    }
    if (name === 'Qty') {
      setfiltertextPopup(t('Accruals.Qty'))
      var testDiv = document.getElementById("Qty");
      setdivleft(testDiv.offsetLeft + 156 + "px");
    }
    if (name === 'Netprice') {
      setfiltertextPopup(t('Accruals.InvoicePrice/Unit'))
      var testDiv = document.getElementById("Netprice");
      setdivleft(testDiv.offsetLeft + 233 + "px");
    }
    if (name === 'WeightedAverage') {
      setfiltertextPopup(t('Accruals.WeightedAvg'))
      var testDiv = document.getElementById("WeightedAverage");
      setdivleft(testDiv.offsetLeft + 216 + "px");
    }
    if (name === 'LineItem') {
      setfiltertextPopup(t('Accruals.Line'))
      var testDiv = document.getElementById("LineItem");
      setdivleft(testDiv.offsetLeft + 156 + "px");
    }
    if (name === 'Message') {
      setfiltertextPopup(t('Accruals.Message'))
      var testDiv = document.getElementById("Message");
      setdivleft((testDiv.offsetLeft - 25) + "px");
    }
    if (name === 'IsExclude') {
      setfiltertextPopup(t('Accruals.Exclude'))
      var testDiv = document.getElementById("IsExclude");
      setdivleft(testDiv.offsetLeft + 156 + "px");
      addnewfieldPopup(activeOption, name)
    }
    if (name === 'Revenue') {
      setfiltertextPopup(t('Accruals.Revenue'))
      var testDiv = document.getElementById("Revenue");
      setdivleft((testDiv.offsetLeft + 59) + "px");
    }
    if (name === 'Region') {
      setfiltertextPopup(t('Accruals.Region'))
      var testDiv = document.getElementById("Region");
      setdivleft((testDiv.offsetLeft - 35) + "px");
    }
    console.log("testDiv", testDiv)
    setshowfilterpopup(true)
  }



  const FilterColumnItems = (name) => {

    var divtop1 = document.getElementById("tableB");
    var cardtop1 = document.getElementById("card-RPL");


    setdivtop1(divtop1.offsetTop + cardtop1.offsetTop + 30)
    setshowfilterpopup1(true)

    setFilterCol1(name)

    debugger;
    if (name === 'ProductName') {
      var testDiv = document.getElementById("ProductName");
      setfiltertext(t('Accruals.MaterialNo'))
      setdivleft1((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");
      DropdownHandler(props.id, name)
    }
    if (name === 'Quantity') {
      setfiltertext(t('Accruals.Quantity'))
      var testDiv = document.getElementById("Quantity");
      setdivleft1((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");
    }
    if (name === 'ComparisonQuantity') {
      setfiltertext("Comparison Quantity")
      var testDiv = document.getElementById("ComparisonQuantity");
      setdivleft1((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");
    }
    if (name === 'ComparisonNetprice') {
      setfiltertext("Comparison Netprice")
      var testDiv = document.getElementById("ComparisonNetprice");
      setdivleft1((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");
    }
    if (name === 'AccrualRevenue') {
      setfiltertext(t('Accruals.AccrualRevenue'))
      var testDiv = document.getElementById("AccrualRevenue");
      setdivleft1((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");
    }
    if (name === 'TotalRevenue') {
      setfiltertext(t('Accruals.Revenue'))
      var testDiv = document.getElementById("Revenue");
      setdivleft1((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");
    }
    if (name === 'ApprovedAccrual') {
      setfiltertext(t('Accruals.CurrentAccrued'))
      var testDiv = document.getElementById("ApprovedAccrual");
      setdivleft1(((testDiv.offsetLeft + cardtop1.offsetLeft + 30) - 100) + "px");
    }
    if (name === 'UnpostedAccruals') {
      setfiltertext(t('Accruals.AccrualsInReview'))
      var testDiv = document.getElementById("UnpostedAccruals");
      setdivleft1(((testDiv.offsetLeft + cardtop1.offsetLeft + 30) - 100) + "px");
    }
    if (name === 'WeightedAveragePerUnit') {
      setfiltertext(t('Accruals.Rebate/Unit'))
      var testDiv = document.getElementById("WeightedAveragePerUnit");
      setdivleft1((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");
    }
    if (name === 'TotalApprovedAccrual') {
      setfiltertext(t('Accruals.TotalAccrued'))
      var testDiv = document.getElementById("TotalApprovedAccrual");
      setdivleft1((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");
    }
    if (name === 'DeltaReachNextTier') {
      setfiltertext("Delta To Reach Tier")
      var testDiv = document.getElementById("DeltaReachNextTier");
      setdivleft1((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");
    }
    if (name === 'CurrentTier') {
      setfiltertext("Current Tier")
      var testDiv = document.getElementById("CurrentTier");
      setdivleft1((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");
    }
    if (name === 'Exclude') {
      setfiltertext(t('Accruals.Exclude'))
      var testDiv = document.getElementById("Exclude");
      setdivleft1((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");
      addnewfield(activeOption, name)
    }
    if (name === 'CategoryLabel') {
      setfiltertext(t('Accruals.CategoryLabel'))
      var testDiv = document.getElementById("CategoryLabel");
      setdivleft1((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");
    }
    if (name === 'SubCategoryLabel') {
      setfiltertext(t('Accruals.SubCategoryLabel'))
      var testDiv = document.getElementById("SubCategoryLabel");
      setdivleft1((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");
    }
    if (name === 'VehicleType') {
      setfiltertext(t('TargetSetting.VehicleType'))
      var testDiv = document.getElementById("VehicleType");
      setdivleft1((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");
    }
    if (name === 'ProductLabel') {
      setfiltertext(t('Accruals.ItemName'))
      var testDiv = document.getElementById("ProductLabel");
      setdivleft1((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");
    }
    if (name === 'CustomerName') {
      setfiltertext(t('Accruals.Customer'))
      var testDiv = document.getElementById("CustomerName");
      setdivleft1((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");
    }
    if (name === 'ExcludedAmount') {
      setfiltertext(t('Accruals.ExcludedRevenue'))
      var testDiv = document.getElementById("ExcludedRevenue");
      setdivleft1((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");
    }
    if (name === 'ExcludedQuantity') {
      setfiltertext(t('Accruals.ExcludedQuantity'))
      var testDiv = document.getElementById("ExcludedQuantity");
      setdivleft1((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");
    }
    if (name === 'AccrualQuantity') {
      setfiltertext(t('Accruals.AccrualQty'))
      var testDiv = document.getElementById("AccrualQuantity");
      setdivleft1((testDiv.offsetLeft + cardtop1.offsetLeft + 30) + "px");
    }
    setshowfilterpopup1(true)

  }

  const DropdownHandler = (key, name) => {
    Service.getAccrualProductfilterDropdown('Accurals/GetAccrualProdcutsFilterValue', key).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {

            if (res.data !== 'null' && res.data !== 'undefined') {

              addnewfield(res.data, name)

            }
          }
        }
      }

    });
  }
  const customMultiStyles = {
    multiselectContainer: {
      maxWidth: '170px',
      marginLeft: '3px'
    },
    chips: {
      borderRadius: '5px'
    }
  };

  const regionStyles = {
    chips: {
      fontSize: "12px",
      lineHeight: "13px"
    },

    multiSelectContainer: {
      "&:hover": { background: "red" }
    }

  }



  const showDefn = () => {
    if (showRebateDefn === false) {
      setRebateDefn(true)
    }
    if (showRebateDefn === true) {
      setRebateDefn(false)
    }
    if (showRebateDefnIcon === "fa fa-plus") {
      setRebateDefnIcon("fa fa-minus")
    }
    if (showRebateDefnIcon === "fa fa-minus") {
      setRebateDefnIcon("fa fa-plus")
    }
  }


  const checkAll = (mycheckbox) => {
    const checkboxes = document.querySelectorAll("input[type='checkbox']");
    if (mycheckbox.target.checked) {
      settotalselectedshow(true);
      setAllCheckedHistory(true);
      checkboxes.forEach(function (checkbox) {
        checkbox.checked = true;
      });
      // settotalItemCountlst(totalItemCountlst);
      // var totalselected = totalItemCountlst
      settotalItemSelectedCountlst(totalItemCountlst)
      setCheckAllChecked(true); // Set state to manage Check All checkbox
    } else {
      settotalselectedshow(false);
      setAllCheckedHistory(false);
      checkboxes.forEach(function (checkbox) {
        checkbox.checked = false;
      });
      settotalItemSelectedCountlst(0);
      setCheckAllChecked(false); // Set state to manage Check All checkbox
    }
  };


  const clickShowAccuralException = (val) => {
    setShowExceptionPolicies(true);
    setsidebarwidth("60vw");

  }

  const clickCloseAccuralException = () => {
    setShowExceptionPolicies(false);
    setsidebarwidth("0vw");
  }

  const handleItemChange = (e, id, name, status) => {
    const isChecked = e.target.checked;
    const checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
    let count = totalItemSelectedCountlst;

    if (isChecked) {
      setIsexport(true);

      setCheckedValue(id);  // check box value - id
      setCheckedValueStatus(status); // check box value - Active status
      setCheckedValueid(name); // check box value - RA-0000
      setCheckedMultiValueid([...checkedMultiValueid, id]); // Add the checked id to the array
      count += 1;

      let tempUncheckedBoxesHistory = [...uncheckedBoxesHistory];
      const index = tempUncheckedBoxesHistory.findIndex((item) => item === id);
      if (index !== -1) {
        tempUncheckedBoxesHistory.splice(index, 1);
      }
      setuncheckedBoxesHistory(tempUncheckedBoxesHistory);

    } else {
      setIsexport(false);
      setuncheckedBoxesHistory([...uncheckedBoxesHistory, id]);

      setCheckedValue([]);  // check box value - id
      setCheckedValueStatus([]); // check box value - Active status
      setCheckedValueid([]); // check box value - RA-0000
      setCheckedMultiValueid(checkedMultiValueid.filter(item => item !== id)); // Remove the unchecked id from the array
      count -= 1;
    }

    settotalItemSelectedCountlst(count);
    settotalselectedshow(count > 0);

    // Uncheck the "Check All" checkbox if any individual checkbox is unchecked
    if (!isChecked) {
      setCheckAllChecked(false);
    } else if (count === totalItemCountlst) {
      setCheckAllChecked(true);
    }

    if (checkedBoxes.length == 1) {
      const [Recordname, Recordstatus] = checkedBoxes[0].value.split(',');
      setCheckedValue(checkedBoxes[0].id)  // check box value - id
      setCheckedValueStatus(Recordstatus) // check box value - Active status
      setCheckedValueid(Recordname) //check box value - RA-0000
    }
  };

  console.log("7-6", totalselectedshow, totalselectedcount)


  // -----------------------------------------------------------------






  const OnChangedefaultView = (event,) => {
    setDefaultView(event.target.checked)
  }
  const OnChangeexistingViewVal = (event,) => {
    setExistingViewVal(event.target.checked)
  }

  const OnChangeSetViewName = (event,) => {
    setColViewName(event.target.value)
  }

  const OnChangeColVisibilityList = (data) => {
    setColvisiOptionVal({ value: data.value, label: data.label });
    getColVisibilityListData(data.value)
  }
  const CreateColumnListPopup = () => {
    setShowCreateColumnListPopup(true)
    var divtop1 = document.getElementById("addRemoveCol");
    setdivtop1(divtop1.offsetTop + 280)
    setdivleft1(divtop1.offsetLeft - 200 + "px");
  }

  const CloseCreateColumnListPopup = () => {
    setShowCreateColumnListPopup(false)
    setColViewName('')
    setDefaultView(false)
  }

  const AddRemoveColumn = () => {
    setShowAddColumnPopup(true)
    var divtop1 = document.getElementById("addRemoveCol");
    setdivtop(divtop1.offsetTop + 295)
    setdivleft(divtop1.offsetLeft - 200 + "px");
    getColumnVisibilityList()
  }
  const OnchangeAddRemoveCol = (event, column) => {
    let isChecked = event.target.checked;
    let lst = [...addRemoveCol];
    for (let d in lst) {
      if (lst[d].key == column.key && isChecked) {
        lst[d]['value'] = true
      }
      else if (lst[d].key == column.key && !isChecked) {
        lst[d]['value'] = false
      }
    }
    setResetColumnKey([...resetColumnKey, column.key])
    setAddRemoveCol(lst)
  }
  const OnchangeCreateColumnListOption = (event, column) => {
    let isChecked = event.target.checked;
    let lst = [...createColumnListOption];
    for (let d in lst) {
      if (lst[d].key == column.key && isChecked) {
        lst[d]['value'] = true
      }
      else if (lst[d].key == column.key && !isChecked) {
        lst[d]['value'] = false
      }
    }
    setCreateColumnListOption(lst)
  }
  const updateColumnVisibilityList = (tag) => {
    let lst = [...addRemoveCol];
    if (tag == 'reset') {
      for (let d in lst) {
        lst[d]['value'] = true
      }
      setHideColumn([])
      setAddRemoveCol(lst)
    }
    else {
      let data = [];
      for (let d in lst) {
        if (!lst[d].value) {
          data.push(lst[d].key)
        }
      }
      setHideColumn(data)
    }
    let viewName = colvisiOptionVal.value;
    Service.setColumnVisibilityListData('ColumnVisibility/CreateOrUpdateColumnList', recorddId, loggedInUser, 'accrualInvoiceLine', viewName, existingViewVal, lst).then((res) => { })
    setShowAddColumnPopup(false)
    setResetColumnKey([])
  }


  const CreateColumnVisibilityList = (tag) => {
    let veiwName = colViewName;
    let idDefault = defaultView;
    if (veiwName.trim() == 'undefined' || veiwName.trim() == '' || veiwName.trim() == null) {
      setErrorStatus("Error")
      setErrorMessage("Please set View Name")
      setShowErrorModal(true)
    }
    else {
      let lst = [...createColumnListOption];
      if (tag == 'reset') {
        for (let d in lst) {
          lst[d]['value'] = true
        }
        setHideColumn([])
        setAddRemoveCol(lst)
      }
      else {
        let data = [];
        for (let d in lst) {
          if (!lst[d].value) {
            data.push(lst[d].key)
          }
        }
        setHideColumn(data)
      }
      Service.setColumnVisibilityListData('ColumnVisibility/CreateOrUpdateColumnList', 0, loggedInUser, 'accrualInvoiceLine', colViewName, defaultView, lst).then((res) => {
        getColumnVisibilityList()
        setColvisiOptionVal({ value: colViewName, label: colViewName });
        getColVisibilityListData(colViewName)
        CloseCreateColumnListPopup()
        setShowAddColumnPopup(false)
      })
    }
  }

  const closeAddRemoveColumnPopup = () => {
    let lst = [...addRemoveCol];
    let resetKey = [...resetColumnKey];
    for (let rk in resetKey) {
      for (let d in lst) {
        if (lst[d].key == resetKey[rk]) {
          lst[d]['value'] = !lst[d].value
        }
      }
    }
    setAddRemoveCol(lst)
    setShowAddColumnPopup(false)
    setResetColumnKey([])

  }

  const getColumnVisibilityList = () => {
    Service.getColumnVisibilityListData('ColumnVisibility/GetColumnList', loggedInUser, 'accrualInvoiceLine', null).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {

            if (typeof res.data !== 'undefined' && res.data !== 'null') {
              let data = []
              for (let d in res.data) {
                data.push({ value: res.data[d].label, label: res.data[d].label })

              }

              setColVisibilityOption(data)
            }
          }
        }
      }
    })
  }


  const getColVisibilityListData = (viewName = null) => {
    if (!viewName) {
      viewName = colvisiOptionVal.value
    }
    if (viewName == 'Deleted') {
      viewName = null
    }
    Service.getColumnVisibilityListData('ColumnVisibility/GetColumnListJsonData', loggedInUser, 'accrualInvoiceLine', viewName).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (typeof res.data.jsonData !== 'undefined' && res.data.jsonData !== 'null') {

              let jsonData = JSON.parse(res.data.jsonData)
              let data = [];
              for (let d in jsonData) {
                if (!jsonData[d].value) {
                  data.push(jsonData[d].key)
                }
              }
              setColvisiOptionVal({ value: res.data.label, label: res.data.label });
              setHideColumn(data)
              setAddRemoveCol(jsonData)
              setExistingViewVal(res.data.defaultView)
              setResetColumnKey([])
              setRecordId(res.data.id)
            }
          }
        }
      }
    })
  }

  const deleteColumnVisibilityView = () => {
    Service.deleteDataByPolicyID("ColumnVisibility/Delete", recorddId, loggedInUser).then(res => {
      if (res.status === 200) {
        getColumnVisibilityList()
        getColVisibilityListData('Deleted')
        setShowAddColumnPopup(false)
        toast.error('Deleted Successfully !', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    })
  }





  const nameOptions = [
    { value: 'ByCategory', label: 'By Category' },
    { value: 'ByItem', label: 'By Item' },
    { value: 'BySubCategory', label: 'By Sub Category' }
  ];


  //on-change-cat
  const handleSelectedNameChange = (selectedOption) => {
    console.log(selectedOption, 'sel');
    setSelectedNameOption(selectedOption);


    // Reset all checkboxes
    const checkboxes = document.querySelectorAll('input[name=chkBox]');
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });

    // Reset relevant states
    setCheckAllChecked(false);
    settotalItemSelectedCountlst(0);
    setCheckedValue([]);
    setCheckedValueStatus([]);
    setCheckedValueid([]);
    setCheckedMultiValueid([]);


    // alert(selectedOption.label);
    let filteredData = []
    let Value = [];
    if (selectedOption.label === "By Category") {


         Service.getAccrualItemsSortBy("Accurals/AccrualItemsSortBy",props.id, "Ascending", "Id", Value, "Category",state.FiscalYear).then((res) => {
          if (typeof res !== 'undefined') {
                 if (typeof res.data !== 'undefined' && res.data !== 'null') {
                   if (res.status !== undefined) {
                   console.log('Selected Option by label',res.data.DataList)
                    filteredData = res.data.DataList.map(item => ({

        CategoryLabel: item.CategoryLabel,
        CategoryName: item.CategoryName,
        Quantity: item.Quantity,
        TotalRevenue: item.TotalRevenue,
        Customer: item.Customer,

      }));
      console.log(filteredData, "Filtered Selected Data");
      const calculatedTotalPages = Math.ceil(filteredData.length / pageSize);
      setTotalPages(calculatedTotalPages);

      // Initialize visibleData with the first page
      const initialData = filteredData.slice(0, pageSize);
      setVisibleData(initialData);
      setCurrentPage(1)
      setItemsData(filteredData);
      setTotalQuantity(filteredData.reduce((acc, item) => acc + item.Quantity, 0));
      setTotalRevenue(filteredData.reduce((acc, item) => acc + item.TotalRevenue, 0));
                   }
                 }
            }
         });
// debugger;
      
    
    }

    else if (selectedOption.label === "By Sub Category") {

      Service.getAccrualItemsSortBy("Accurals/AccrualItemsSortBy",props.id, "Ascending", "Id", Value, "SubCategory",state.FiscalYear).then((res) => {
       if (typeof res !== 'undefined') {
          if (typeof res.data !== 'undefined' && res.data !== 'null') {
            if (res.status !== undefined) {
            console.log('11/11',res.data.DataList)
             filteredData = res.data.DataList.map(item => ({
        SubCategoryLabel: item.SubCategoryLabel,
        SubCategoryName: item.SubCategoryName,
        VehicleType: item.VehicleType,
        Quantity: item.Quantity,
        TotalRevenue: item.TotalRevenue,
        Customer: item.Customer,
        CategoryLabel: item.CategoryName,
        VehicleType: item.VehicleType,
      }));
      const calculatedTotalPages = Math.ceil(filteredData.length / pageSize);
      setTotalPages(calculatedTotalPages);
      const initialData = filteredData.slice(0, pageSize);
      setVisibleData(initialData);
      setCurrentPage(1)

      setItemsData(filteredData);
      setTotalQuantity(filteredData.reduce((acc, item) => acc + item.Quantity, 0));
      setTotalRevenue(filteredData.reduce((acc, item) => acc + item.TotalRevenue, 0));
                 }
               }
          }
       });
     
    }
    else {
      const calculatedTotalPages = Math.ceil(allItemsData.lstAccuralItemVIew.length / pageSize);
      setTotalPages(calculatedTotalPages);
      const initialData = allItemsData.lstAccuralItemVIew.slice(0, pageSize);
      setVisibleData(initialData);
      setCurrentPage(1)

      setItemsData(allItemsData.lstAccuralItemVIew);
      setTotalQuantity(allItemsData.lstAccuralItemVIew.reduce((acc, item) => acc + item.Quantity, 0));
      setTotalRevenue(allItemsData.lstAccuralItemVIew.reduce((acc, item) => acc + item.TotalRevenue, 0));
    }

    let totalRecordscount = selectedOption.label !== "By Item" ? filteredData?.length : allItemsData?.lstAccuralItemVIew?.length
    settotalItemCountlst(totalRecordscount)
    setCurrentSelection(selectedOption.label)
    let temp_list = columnList;
    let newArray = [];
    if (selectedOption.label !== "By Item") {
      newArray = temp_list.filter(item => item.key !== "ProductName");
      newArray = newArray.filter(item => item.key !== "Exclude");

      let indexToUpdate = newArray.findIndex(item => item.key === "ProductLabel");
      if (indexToUpdate !== -1) {
        newArray[indexToUpdate].label = selectedOption.label === "By Category" ? "Category Name" : "By Sub Category" ? "Sub Category Name" : "Item Name";
      }

      //call , id = ?
      setAddRemoveCol(newArray)
    }
    else {
      setAddRemoveCol(temp_list)
    }
  };
  // ------------------------------------------------------------


  const getLink = (event, obj) => {
    let selectedLink = "";
    let string = obj.ExceptionPolicyId;
    let beforeHyphen = string?.split("-")[0];
    switch (beforeHyphen) {
      case "TID":
        selectedLink = `/TargetSettingPolicylist/edit/${obj.PolicyId}/fromPolicy`;
        break;
      case "MTID":
        selectedLink = `/MinThresholdPolicylist/edit/${obj.PolicyId}/fromPolicy`;
        break;
      case "ECCID":
        selectedLink = `/ECCPolicylist/edit/${obj.PolicyId}/fromPolicy`;
        break;
      case "BID":
        selectedLink = `/SlabBasedPolicylist/edit/${obj.PolicyId}/fromPolicy`;
        break;
      case "EID":
        selectedLink = `/ExceptionPolicylist/edit/${obj.PolicyId}/fromPolicy`;
        break;
      case "AID":
        selectedLink = `/AnnualPolicylist/edit/${obj.PolicyId}/fromPolicy`;
        break;
      default:
        selectedLink = "/";
    }
    return selectedLink;
  };

  const dateFormat = SessionManage.getcurrentdateformat();
  const timeFormat = "hh:mm:ss A";

  //-------------------------------------------------------------

  return (
    <div className="container-fluid content-top-gap">
      <div style={{ display: "none" }}>
        <CSVLink data={csvList} headers={headers} ref={csvInstance} filename="accrualproducts.csv" > </CSVLink>
      </div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-breadcrumb">
          <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
          <li className="breadcrumb-item active" aria-current="page"><Link to={updateNav} id="headertxt">{t('Accruals.AccrualList')}</Link></li>
          <li className="breadcrumb-item active" id="libread" aria-current="page">Create Accrual</li>
        </ol>
      </nav>
      {loading ? <><div className="loader-container"><div className="spinner"></div> </div> </> : ""}
      {
        showAccuralWorkflow === true ?
          <div id="mySidepanel" className="sidepanel sidepanelborder" style={{ width: sidebarwidth }}>
            <div className="container" id="workflow">
              <div className="row mt-3">
                <div className="col-lg-12 pr-lg-2">
                  <div className="card text-center card_border">
                    <div className="card-header chart-grid__header">
                      <div className="row">
                        <div className="col-md-8 text-left">
                          {t('Workflow')}
                        </div>
                        <div className="col-md-4">
                          <button className="btn btn-outline-dark  btn-sm" style={{ float: "right", width: "45px" }} onClick={clickCloseAccuralWorkflow} type="submit">{t('Close')}</button>
                        </div>
                      </div>
                    </div>
                    <div className="card-body" style={{ minHeight: "55vh" }} >

                      {workflowchart.length > 0 ?
                        <div className='col-md-12'>
                          <table className="table">
                            <thead>
                              <tr>
                                <th className='workflowchart-levelwidth workflowchart-font'>{t('Accruals.Level')}</th>
                                <th className='font-center workflowchart-font'>{t('Accruals.Users')}</th>
                                <th className='font-center workflowchart-font'>{t('Accruals.Approval')}</th>
                              </tr>
                            </thead>

                            <tbody>
                              {
                                workflowchart.map((i) =>
                                  <tr key={i.Id}>
                                    <td className='workflowchart-font square-levels'>{i.Levels}</td>
                                    <td className='font-center'>
                                      <ul >

                                        {
                                          i.lstworkflowapprover.map((j, index) => {
                                            return <>

                                              {
                                                index < 2 ?
                                                  j.IsApproved === true || j.IsDenied == true ?
                                                    <li className='workflowchartperson'>
                                                      <span className='workflowtpersoncolor'><i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                                        <br></br> {j.FullName}</span>
                                                    </li>
                                                    :
                                                    <>
                                                      <li className='workflowchartperson'>
                                                        <i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                                        <br></br> {j.FullName}
                                                      </li>
                                                    </>
                                                  :
                                                  ''
                                              }
                                            </>

                                          })
                                        }

                                        {
                                          i.lstworkflowapprover.length > 2 ?
                                            <li className='workflowchartperson tooltip_workflow'>
                                              <i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                              <br></br> {t('Accruals.MoreUsers')}
                                              <span class="tooltiptext">

                                                {
                                                  i.lstworkflowapprover.map((k, index) => {
                                                    return <span>
                                                      {
                                                        index > 1 ? <><i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                                          &nbsp; {k.FullName}
                                                          <hr></hr></> : null
                                                      }


                                                    </span>
                                                  })
                                                }

                                              </span>
                                            </li>
                                            :
                                            ''
                                        }


                                      </ul>

                                    </td>

                                    <td className='font-center'>
                                      {
                                        i.IsApproved === true ?
                                          <span className='workflowrightcolor'>
                                            <i class="fa fa-check" aria-hidden="true"></i>
                                          </span>
                                          : i.IsDenied == true ?
                                            <span className='workflowrightcolor'>
                                              <i class="fa fa-times" aria-hidden="true" style={{ color: "red" }}></i>
                                            </span>
                                            : ''
                                      }
                                    </td>
                                  </tr>)

                              }
                            </tbody>
                          </table>

                        </div>
                        :
                        // <div className={classes.flow}>
                        //   <div id="status1" className={`${classes['square']} `}>
                        //     <p className={classes['statusText']}>{t('Accruals.InReview')}</p>
                        //   </div>
                        //   {CurrentWorkflowLevel === 0 && workflowData.length > 1 ? <>

                        //     <div className={classes.line}></div>
                        //     <div class="status3" className={`${classes['square']} ${classes['Active-Status']}  `}>
                        //       <p className={classes['statusText']}>{t('Accruals.Approved')}</p>
                        //     </div>
                        //   </> : <>
                        //     <div className={classes.line}></div>
                        //     <div class="status3" className={`${classes['square']} `}>
                        //       <p className={classes['statusText']}>{t('Accruals.Approved')}</p>
                        //     </div>
                        //   </>}
                        // </div>
                        ''
                      }

                      <div className="col-md-12">
                        <table className="table text-left mt-2">
                          <thead className='text-white' style={{ backgroundColor: "var(--primary)" }}>
                            <tr>

                              <th>{t('Accruals.Event')}</th>
                              <th>{t('Accruals.CreatedDate')}</th>
                              <th>{t('Accruals.User')}</th>
                              <th>{t('Accruals.Comments')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {workflowData.map((i) =>
                              <tr>
                                <td>{i.EventName}</td>
                                <td>{`${moment(i.CreatedDate).format(dateFormat)} ${moment(i.CreatedDate).format(timeFormat)}`}</td>
                                <td>{i.CreateUserName}</td>
                                <td>{i.Comments}</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>




                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>
          :
          ''
      }

      {console.log("30-7", accrualExceptionPolicies)}

      {showExceptionPolicies == true ?
        <>
          <div id="mySidepanel" className="sidepanel sidepanelborder" style={{ width: sidebarwidth }}>
            <div className="container" id="workflow">
              <div className="row mt-3">
                <div className="col-lg-12 pr-lg-2">
                  <div className="card text-center card_border">
                    <div className="card-header chart-grid__header">
                      <div className="row">
                        <div className="col-md-8 text-left">
                          {t('Policies Used')}
                        </div>
                        <div className="col-md-4">
                          <button className="btn btn-outline-dark  btn-sm" style={{ float: "right", width: "45px" }} onClick={clickCloseAccuralException} type="submit">{t('Close')}</button>
                        </div>
                      </div>
                    </div>
                    <div className="card-body" style={{ minHeight: "55vh" }} >
                      <div className="col-md-12">
                        <table className="table text-left mt-2">
                          <thead className='text-white' style={{ backgroundColor: "var(--primary)" }}>
                            <tr>
                              <th>{t('Accruals.PolicyId')}</th>
                              <th>{t('Accruals.ExceptionType')}</th>
                              <th>{t('Accruals.ApprovedDate')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {accrualExceptionPolicies != null ?
                              <>{accrualExceptionPolicies.map((i) =>
                                <tr>
                                  {/* {i.ExceptionType == "Bimonthly" ?
                                    <td className="Form-Input-control">{i.ExceptionPolicyId != null ? <Link to={`/SlabBasedPolicylist/edit/${i.Id}/fromAccrual`}>{i.ExceptionPolicyId}</Link> : ''}</td>
                                    :
                                    i.ExceptionType == "Quarterly" ?
                                      <td className="Form-Input-control">{i.ExceptionPolicyId != null ? <Link to={`/TargetSettingPolicylist/edit/${i.Id}/fromAccrual`}>{i.ExceptionPolicyId}</Link> : ''}</td>
                                      : <td className="Form-Input-control">{i.ExceptionPolicyId != null ? <Link to={`/ExceptionPolicylist/new/${i.Id}/fromAccrual`}>{i.ExceptionPolicyId}</Link> : ''}</td>
                                  } */}
                                  <td className="Form-Input-control">
                                    <Link to={getLink(null, i)}>{i.ExceptionPolicyId}</Link>
                                  </td>
                                  <td>{i.ExceptionType}</td>
                                  {i.ApprovedDate === null ? <td></td> : <td>{moment(i.ApprovedDate).format(SessionManage.getcurrentdateformat())}</td>}
                                </tr>
                              )}</>
                              : <td></td>}
                          </tbody>
                        </table>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </> : ''}



      <div className="row mt-3" id="rebateProgramdiv">
        <div className="col-lg-12 pr-lg-2">
          <div className="card text-center card_border">
            <div className="card-header chart-grid__header">
              <div className="row">
                <div className="col-md-7 text-left">
                  {t('Accruals.RebateProgram')}
                </div>
                <div className="col-md-5">

                  {isAnalyzeRebate == true ? <><button type="button" className="btn btn-sm btn-update ml-2" style={{ float: "right", width: "45px" }} onClick={() => CloseHandler('openclose')}>{t('Close')}</button></> :
                    <>


                      {accrualExceptionPolicies != null ? <button className="btn btn-sm btn-update ml-2" style={{ float: "right", width: "85px" }} onClick={() => { clickShowAccuralException("Exception") }} type="submit">{t('Policies Used')}</button> : ''}
                      <button className="btn btn-sm btn-update ml-2" style={{ float: "right", width: "65px" }} onClick={() => { clickShowAccuralWorkflow("Workflow") }} type="submit">{t('Workflow')}</button>
                      {Service.submitPerms(userPerms) == false || needGeneration == true ? "" : accStatus != 'InReview' && accStatus != 'Approved' && accStatus != 'Denied' ? (isLocked === true && lockedBy.toString() === loggedInUser) ? <button type="button" className="btn btn-sm btn-update ml-2" style={{ float: "right", width: "50px" }} onClick={() => workflowAlert('open')}>{t('Submit')}</button> : <></> : <></>}
                      {accStatus != 'InReview' && accStatus != 'Approved' && accStatus != 'Denied' ? <button type="button" className="btn btn-sm btn-update ml-2" style={{ float: "right", width: "45px" }} onClick={() => CloseHandler('openclose')}>{t('Close')}</button> : <></>}
                      {accStatus != 'InReview' && accStatus == 'Approved' && accStatus != 'Denied' ? <button type="button" className="btn btn-sm btn-update ml-2" style={{ float: "right", width: "55px" }} >{t('Publish')}</button> : <></>}

                      {Service.editPerms(userPerms) == false ? "" :
                        (loadedFrom === 'fromApproval' && accStatus == 'InReview') ?
                          (isLocked === true && lockedBy.toString() === loggedInUser) ?
                            <>
                              <button type="button" className="btn btn-sm btn-update ml-2" style={{ float: "right", width: "58px" }} onClick={() => showApprovedDialog('showApprovedDialog')} >Approve</button>
                              <button type="button" className="btn btn-sm btn-update ml-2" style={{ float: "right", width: "40px" }} onClick={() => showDeniedDialog('showDeniedDialog')}> Deny </button>
                            </>
                            : ""
                          : <></>}
                      {Service.editPerms(userPerms) == false ? "" : (loadedFrom != "fromApproval" && loadedFrom != 'fromApprovalCompleted') && (accStatus == 'InReview' || accStatus == 'Approved' || accStatus == 'Denied') ? <button type="button" className="btn btn-sm btn-update ml-2" style={{ float: "right", width: "50px" }} onClick={() => CancelHandler()}>  Cancel</button> : <></>}
                      {Service.editPerms(userPerms) == false ? "" : loadedFrom == 'fromApproval' || loadedFrom == 'fromApprovalCompleted' ? <button type="button" className="btn btn-sm btn-update ml-2" style={{ float: "right", width: "50px" }} onClick={() => navigateHandler()}>  Cancel</button> : <></>}
                    </>}

                </div>
              </div>
            </div>
            <div className="card-body" >
              <div className="container">
                <div className="row">

                  <label className="col-md-2 text-left l_width">{t('Accruals.ProgramId')}</label>
                  <input id="aid" disabled={true} value={state.rebateId} type="text" className="col-md-2 m_width" />

                  <label className="col-md-2 text-left l_width">{t('Accruals.Validity')}</label>
                  <input id="aid" disabled={true} value={Validity} type="text" className=" col-md-2 m_width" />

                  <label className="col-md-2 text-left l_width">{t('Accruals.Description')}</label>
                  <input id="aid" name="startdate" disabled={true} value={state.rebateProgramDesc} type="text" className="col-md-2 m_width" />

                  <label className="col-md-2 mt-2 text-left l_width">{t('Rebates.RebateType')}</label>
                  <input id="aid" name="agreementid" disabled={true} value={state.rebateType} type="text" className="col-md-2 mt-2 m_width" />


                  <label className="col-md-2 mt-2 text-left l_width">{t('Accruals.RebateMeasure')}</label>
                  <input id="aid" name="startdate" disabled={true} value={state.rebateMeasure} type="text" className="col-md-2 mt-2 m_width" />


                  {/* <label className="col-md-2 mt-2 text-left">{t('Accruals.PayoutCond')}</label>
                  <input id="aid" name="startdate" disabled={true} value={state.rebatePayoutCond} type="text" className=" col-md-2 mt-2" />
*/}
                  <label className="col-md-2 mt-2 text-left l_width">{t('Accruals.SettlementFreq')}</label>
                  <input id="aid" disabled={true} value={state.settlFreq} type="text" className="col-md-2 mt-2 m_width" />
                  <div></div>
                  <label className="col-md-2 mt-2 text-left l_width">{t('Accruals.Customer')}</label>
                  <input id="aid" disabled={true} value={state.CustomerCodeName} type="text" className="col-md-2 mt-2 m_width" title={state.CustomerCodeName} />

                  <label className="col-md-2 mt-2 text-left l_width">{t('Accruals.RebatePeriod')}</label>
                  <input id="aid" disabled={true} value={state.FiscalYearPeriod} type="text" className="col-md-2 mt-2 m_width" />
                  <div></div>

                  {(rebateTypeValue === 'Growth in Rev' || rebateTypeValue === 'Growth in Vol') ? <>
                    <label className="col-md-2 mt-2 text-left">Comparison Period</label>
                    <input id="aid" name="comparisonPeriod" disabled={true} value={state.comparisonPeriod} type="text" className="col-md-2 mt-2" />
                    <label className="col-md-2 mt-2 text-left">Comparison Start Date</label>
                    <input id="aid" name="comparisonStartDate" disabled={true} value={moment(state.comparisonStartDate).format(SessionManage.getcurrentdateformat())} type="text" className="col-md-2 mt-2" />
                    <label className="col-md-2 mt-2 text-left">Comparison End Date</label>
                    <input id="aid" name="comparisonEndData" disabled={true} value={moment(state.comparisonEndData).format(SessionManage.getcurrentdateformat())} type="text" className="col-md-2 mt-2" />


                  </> : <></>}
                </div>


                <div className="row mt-2">
                  {state.CustomerGroup === true ?
                    <>
                      <label className="col-md-2 text-left">{t('Accruals.Customer')}</label>
                      <div className="col-md-10 RegionInput">
                        <Multiselect
                          options={customerList}
                          displayValue="label"
                          name="region"
                          showCheckbox={true}
                          disable={true}
                          selectedValues={customerList}
                          placeholder=''
                          style={regionStyles}
                        />
                      </div>
                    </> : ""}


                </div>
              </div>

              <div className="col-lg-12 pr-lg-2 mt-3">
                <div className="card text-center card_border">
                  <div className="card-header chart-grid__header">
                    <div className="row">
                      <div className="col-md-8 text-left">
                        <i className={showRebateDefnIcon} style={{ cursor: "pointer" }} onClick={() => showDefn()}></i> &nbsp;{t('Accruals.Rebate-Definition')}
                      </div>
                    </div>
                  </div>
                  {showRebateDefn === true ?
                    <div className="card-body" style={{ minHeight: "15vh" }} >
                      <div className="container-fluid">
                        <div className="table-responsive">
                          <table className="table table-head-fixed text-nowrap table-bordered table-striped" style={{ width: "760px" }}>
                            <thead className="thead-light">
                              {state.settlFreq == "Bimonthly" ?
                                <>
                                  <tr>
                                    <th>{t('Accruals.Slab')}</th>
                                    <th>{t('Accruals.MeasureValue')}</th>
                                    <th>{t('Accruals.Rebate') + '₹/%/LS'}</th>
                                    <th>{t('Accruals.RebateValue')}</th>
                                  </tr>
                                </> : state.settlFreq == "Quarterly" ?
                                  <tr>
                                    <th>{t('Target Setting Policy')}</th>
                                  </tr>
                                  : state.settlFreq == "Annual" ?
                                    <tr>


                                      <th>{t('Accruals.TargetCond')}</th>
                                      <th>{t('Accruals.MeasureValue')}</th>
                                      <th>{t('Accruals.Rebate') + '₹/%/LS'}</th>
                                      <th>{t('Accruals.RebateValue')}</th>
                                      <th>{t('Accruals.TargetMeasure')}</th>
                                    </tr> :
                                    <tr>
                                      <th>{t('Scheme')}</th>
                                    </tr>
                              }

                            </thead>
                            <tbody>
                              {
                                volumeTier.map((item, index) => {
                                  if (state.settlFreq == "Bimonthly") {
                                    return (
                                      <tr key={index}>
                                        <td>{item.TargetMeasure}</td>
                                        <td>{item.MeasureValue}%</td>
                                        <td>{item.RebateValue}</td>
                                        <td>{item.Rebate}</td>
                                      </tr>
                                    );
                                  } else if (state.settlFreq == "Quarterly") {
                                    return (
                                      <tr key={index}>
                                        <td>{state.URL != null ? <Link to={state.URL}>Policy</Link> : ''}</td>
                                      </tr>
                                    );
                                  } else if (state.settlFreq == "Annual") {
                                    return (
                                      <tr key={index}>


                                        <td>{item.TargetCond}</td>
                                        <td>{item.MeasureValue}</td>
                                        <td>{item.RebateValue}</td>
                                        <td>{item.Rebate}</td>
                                        <td>{item.TargetMeasure}</td>
                                      </tr>
                                    );
                                  } else {
                                    return (
                                      <tr key={index}>
                                        <td>{state.URL != null ? <Link to={state.URL}>Scheme</Link> : ''}</td>
                                      </tr>
                                    )
                                  }
                                })
                              }
                            </tbody>
                          </table>
                        </div>
                        {subdivTarget != null ? <>
                          <div className="card-header chart-grid__header">
                            <div className="row">
                              <div className="col-md-8 text-left">
                                {t('Accruals.SubDivisionTarget')}
                              </div>
                            </div>
                          </div>
                          <div style={{ marginTop: "10px" }}>
                            <label className="col-md-2 text-left">{t('Accruals.SubDivideTarget')}</label>
                            <input type="checkbox" checked="true" disabled="true"></input>
                            <div style={{ display: "inline" }}>
                              <label className="col-md-2 text-left">{t('Accruals.DefineTargetBy')}</label>
                              <input style={{ textAlign: "center", width: "120px" }} className="col-md-2 textbox-padding mt-2" disabled="true" type="text" value={state.SubDivideTargetName}></input>
                            </div>
                          </div>
                          <br></br>
                          <div className="table-responsive">
                            <table style={{ width: "65%" }} className="table table-bordered table-head-fixed text-nowrap table-bordered table-striped">
                              <thead className="thead-light">
                                {subdivTarget.length > 3 ?
                                  <>

                                    <tr className='text-left'>
                                      <th style={{ width: "200px" }}>{t('Accruals.AccrualPeriod')}</th>
                                      <th style={{ width: "200px" }}>Q1</th>
                                      <th style={{ width: "200px" }}>Q2</th>
                                      <th style={{ width: "200px" }}>Q3</th>
                                      <th style={{ width: "200px" }}>Q4</th>
                                    </tr>
                                    <tr className='text-left'>
                                      <th style={{ width: "200px" }}>{t('Accruals.Target') + "%"}</th>
                                      <td className="Form-Input-control"   >{subdivTarget[0].MeasureValue}</td>
                                      <td className="Form-Input-control"   >{subdivTarget[1].MeasureValue} </td>
                                      <td className="Form-Input-control"   >{subdivTarget[2].MeasureValue} </td>
                                      <td className="Form-Input-control"   >{subdivTarget[3].MeasureValue}</td>
                                    </tr>
                                    <tr className='text-left'>
                                      <th style={{ width: "200px" }}>{t('Accruals.Payout') + " " + rebateIcon}</th>
                                      <td className="Form-Input-control"  >{subdivTarget[0].RebateValue}</td>
                                      <td className="Form-Input-control"  >{subdivTarget[1].RebateValue} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[2].RebateValue}</td>
                                      <td className="Form-Input-control"  >{subdivTarget[3].RebateValue} </td>
                                    </tr>

                                    {state.rebateType == 'Volume' ?
                                      <tr className='text-left'>
                                        <th style={{ width: "100px" }}>{t('Accruals.Quantity')}</th>
                                        <td className="Form-Input-control" >{subdivTarget[0].lstAccrualView != null ? (subdivTarget[0].lstAccrualView.TotalQuantity) : ""} </td>
                                        <td className="Form-Input-control" >{subdivTarget[1].lstAccrualView != null ? (subdivTarget[1].lstAccrualView.TotalQuantity) : ""} </td>
                                        <td className="Form-Input-control" > {subdivTarget[2].lstAccrualView != null ? (subdivTarget[2].lstAccrualView.TotalQuantity) : ""} </td>
                                        <td className="Form-Input-control" >{subdivTarget[3].lstAccrualView != null ? (subdivTarget[3].lstAccrualView.TotalQuantity) : ""} </td>
                                      </tr>
                                      :
                                      <tr className='text-left'>
                                        <th style={{ width: "100px" }}>{t('Accruals.Revenue')}</th>
                                        <td className="Form-Input-control" >{subdivTarget[0].lstAccrualView != null ? state.currency : ''} {subdivTarget[0].lstAccrualView != null ? Service.currencyFormat((subdivTarget[0].lstAccrualView.TotalRevenue).toFixed(SessionManage.getroundingPrecision())) : ""} </td>
                                        <td className="Form-Input-control" >{subdivTarget[1].lstAccrualView != null ? state.currency : ''}{subdivTarget[1].lstAccrualView != null ? Service.currencyFormat((subdivTarget[1].lstAccrualView.TotalRevenue).toFixed(SessionManage.getroundingPrecision())) : ""} </td>
                                        <td className="Form-Input-control" >{subdivTarget[2].lstAccrualView != null ? state.currency : ''} {subdivTarget[2].lstAccrualView != null ? Service.currencyFormat((subdivTarget[2].lstAccrualView.TotalRevenue).toFixed(SessionManage.getroundingPrecision())) : ""} </td>
                                        <td className="Form-Input-control" >{subdivTarget[3].lstAccrualView != null ? state.currency : ''}{subdivTarget[3].lstAccrualView != null ? Service.currencyFormat((subdivTarget[3].lstAccrualView.TotalRevenue).toFixed(SessionManage.getroundingPrecision())) : ""} </td>
                                      </tr>
                                    }
                                    <tr className='text-left'>
                                      <th className="Form-Input-control" >{t('Accruals.AccrualThreshold')}</th>
                                      <td className="Form-Input-control"  >{subdivTarget[0].lstAccrualView != null ? subdivTarget[0].lstAccrualView.SubDivMinThreshold != null ? (subdivTarget[0].lstAccrualView.SubDivMinThreshold) : "" : ""} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[1].lstAccrualView != null ? subdivTarget[1].lstAccrualView.SubDivMinThreshold != null ? (subdivTarget[1].lstAccrualView.SubDivMinThreshold) : "" : ""} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[2].lstAccrualView != null ? subdivTarget[2].lstAccrualView.SubDivMinThreshold != null ? (subdivTarget[2].lstAccrualView.SubDivMinThreshold) : "" : ""} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[3].lstAccrualView != null ? subdivTarget[3].lstAccrualView.SubDivMinThreshold != null ? (subdivTarget[3].lstAccrualView.SubDivMinThreshold) : "" : ""} </td>
                                    </tr>
                                    <tr className='text-left'>
                                      <th style={{ width: "200px" }}>{t('Accruals.AccrualAmount')}</th>
                                      <td className="Form-Input-control" >{subdivTarget[0].lstAccrualView != null ? state.currency : ''}{subdivTarget[0].lstAccrualView != null ? Service.currencyFormat((subdivTarget[0].lstAccrualView.AccrualsAggregate).toFixed(SessionManage.getroundingPrecision())) : ""} </td>
                                      <td className="Form-Input-control" >{subdivTarget[1].lstAccrualView != null ? state.currency : ''}{subdivTarget[1].lstAccrualView != null ? Service.currencyFormat((subdivTarget[1].lstAccrualView.AccrualsAggregate).toFixed(SessionManage.getroundingPrecision())) : ""} </td>
                                      <td className="Form-Input-control" >{subdivTarget[2].lstAccrualView != null ? state.currency : ''}{subdivTarget[2].lstAccrualView != null ? Service.currencyFormat((subdivTarget[2].lstAccrualView.AccrualsAggregate).toFixed(SessionManage.getroundingPrecision())) : ""} </td>
                                      <td className="Form-Input-control" >{subdivTarget[3].lstAccrualView != null ? state.currency : ''}{subdivTarget[3].lstAccrualView != null ? Service.currencyFormat((subdivTarget[3].lstAccrualView.AccrualsAggregate).toFixed(SessionManage.getroundingPrecision())) : ""} </td>

                                    </tr>
                                    <tr className='text-left'>
                                      <th style={{ width: "200px" }} >{t('Accruals.AccrualId')}</th>
                                      <td className="Form-Input-control">{subdivTarget[0].lstAccrualView != null ? <Link to={`/accruals/new/${subdivTarget[0].lstAccrualView.Id}/fromAccrual`}>{subdivTarget[0].lstAccrualView.AccrualId}</Link> : ''}</td>
                                      <td className="Form-Input-control">{subdivTarget[1].lstAccrualView != null ? <Link to={`/accruals/new/${subdivTarget[1].lstAccrualView.Id}/fromAccrual`}>{subdivTarget[1].lstAccrualView.AccrualId}</Link> : ''}</td>
                                      <td className="Form-Input-control">{subdivTarget[2].lstAccrualView != null ? <Link to={`/accruals/new/${subdivTarget[2].lstAccrualView.Id}/fromAccrual`}>{subdivTarget[2].lstAccrualView.AccrualId}</Link> : ''}</td>
                                      <td className="Form-Input-control">{subdivTarget[3].lstAccrualView != null ? <Link to={`/accruals/new/${subdivTarget[3].lstAccrualView.Id}/fromAccrual`}>{subdivTarget[3].lstAccrualView.AccrualId}</Link> : ''}</td>
                                    </tr>
                                  </>
                                  :
                                  ""
                                }
                                {subdivTarget.length == 2 ?
                                  <>
                                    <tr className='text-left'>
                                      <th style={{ width: "100px" }}>{t('Accruals.AccrualPeriod')}</th>
                                      <th style={{ width: "130px" }}>H1</th>
                                      <th style={{ width: "130px" }}>H2</th>
                                    </tr>
                                    <tr className='text-left'>
                                      <th style={{ width: "100px" }}>{t('Accruals.Target') + "%"}</th>
                                      <td className="Form-Input-control"  >{subdivTarget[0].MeasureValue} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[1].MeasureValue} </td>
                                    </tr>
                                    <tr className='text-left'>
                                      <th style={{ width: "100px" }}>{t('Accruals.Payout') + " " + rebateIcon}</th>
                                      <td className="Form-Input-control"  >{subdivTarget[0].RebateValue} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[1].RebateValue} </td>
                                    </tr>
                                    {state.rebateType == 'Volume' ?
                                      <tr className='text-left'>
                                        <th style={{ width: "100px" }}>{t('Accruals.Quantity')}</th>
                                        <td className="Form-Input-control" >{subdivTarget[0].lstAccrualView != null ? (subdivTarget[0].lstAccrualView.TotalQuantity) : ""} </td>
                                        <td className="Form-Input-control" >{subdivTarget[1].lstAccrualView != null ? (subdivTarget[1].lstAccrualView.TotalQuantity) : ""} </td>

                                      </tr>
                                      :
                                      <tr className='text-left'>
                                        <th style={{ width: "100px" }}>{t('Accruals.Revenue')}</th>
                                        <td className="Form-Input-control" >{subdivTarget[0].lstAccrualView != null ? state.currency : ''} {subdivTarget[0].lstAccrualView != null ? Service.currencyFormat((subdivTarget[0].lstAccrualView.TotalRevenue).toFixed(SessionManage.getroundingPrecision())) : ""} </td>
                                        <td className="Form-Input-control" >{subdivTarget[1].lstAccrualView != null ? state.currency : ''}{subdivTarget[1].lstAccrualView != null ? Service.currencyFormat((subdivTarget[1].lstAccrualView.TotalRevenue).toFixed(SessionManage.getroundingPrecision())) : ""} </td>
                                      </tr>
                                    }
                                    <tr className='text-left'>
                                      <th className="Form-Input-control" >{t('Accruals.AccrualThreshold')}</th>
                                      <td className="Form-Input-control"  >{subdivTarget[0].SubDivMinThreshold != null ? (subdivTarget[0].SubDivMinThreshold) : ""} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[1].SubDivMinThreshold != null ? (subdivTarget[1].SubDivMinThreshold) : ""} </td>
                                    </tr>
                                    <tr className='text-left'>
                                      <th style={{ width: "100px" }}>{t('Accruals.AccrualAmount')}</th>
                                      <td className="Form-Input-control" >{subdivTarget[0].lstAccrualView != null ? state.currency : ''} {subdivTarget[0].lstAccrualView != null ? Service.currencyFormat((subdivTarget[0].lstAccrualView.AccrualsAggregate).toFixed(SessionManage.getroundingPrecision())) : ""} </td>
                                      <td className="Form-Input-control" >{subdivTarget[1].lstAccrualView != null ? state.currency : ''}{subdivTarget[1].lstAccrualView != null ? Service.currencyFormat((subdivTarget[1].lstAccrualView.AccrualsAggregate).toFixed(SessionManage.getroundingPrecision())) : ""} </td>
                                    </tr>
                                    <tr className='text-left'>
                                      <th style={{ width: "100px" }}>{t('Accruals.AccrualId')}</th>
                                      <td className="Form-Input-control">{subdivTarget[0].lstAccrualView != null ? <Link to={`/accruals/new/${subdivTarget[0].lstAccrualView.Id}/fromAccrual`}>{subdivTarget[0].lstAccrualView.AccrualId}</Link> : ''}</td>
                                      <td className="Form-Input-control">{subdivTarget[1].lstAccrualView != null ? <Link to={`/accruals/new/${subdivTarget[1].lstAccrualView.Id}/fromAccrual`}>{subdivTarget[1].lstAccrualView.AccrualId}</Link> : ''}</td>
                                    </tr>
                                  </>
                                  :
                                  ""
                                }
                                {subdivTarget.length > 11 ?
                                  <>
                                    <tr className='text-left'>
                                      <th>{t('Accruals.AccrualPeriod')}</th>
                                      <th>M1</th>
                                      <th>M2</th>
                                      <th>M3</th>
                                      <th>M4</th>
                                      <th>M5</th>
                                      <th>M6</th>
                                      <th>M7</th>
                                      <th>M8</th>
                                      <th>M9</th>
                                      <th>M10</th>
                                      <th>M11</th>
                                      <th>M12</th>
                                    </tr>
                                    <tr className='text-left'>
                                      <th className="Form-Input-control" >{t('Accruals.Target') + "%"}</th>
                                      <td className="Form-Input-control"  >{subdivTarget[0].MeasureValue} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[1].MeasureValue} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[2].MeasureValue} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[3].MeasureValue} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[4].MeasureValue} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[5].MeasureValue} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[6].MeasureValue} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[7].MeasureValue} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[8].MeasureValue} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[9].MeasureValue} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[10].MeasureValue} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[11].MeasureValue} </td>
                                    </tr>
                                    <tr className='text-left'>
                                      <th className="Form-Input-control" >{t('Accruals.Payout') + " " + rebateIcon}</th>
                                      <td className="Form-Input-control"  >{subdivTarget[0].RebateValue} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[1].RebateValue} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[2].RebateValue} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[3].RebateValue} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[4].RebateValue} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[5].RebateValue} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[6].RebateValue} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[7].RebateValue} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[8].RebateValue} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[9].RebateValue} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[10].RebateValue} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[11].RebateValue} </td>
                                    </tr>
                                    {state.rebateType == 'Volume' ?
                                      <tr className='text-left'>
                                        <th style={{ width: "100px" }}>{t('Accruals.Quantity')}</th>
                                        <td className="Form-Input-control" >{subdivTarget[0].lstAccrualView != null ? (subdivTarget[0].lstAccrualView.TotalQuantity) : ""} </td>
                                        <td className="Form-Input-control" >{subdivTarget[1].lstAccrualView != null ? (subdivTarget[1].lstAccrualView.TotalQuantity) : ""} </td>
                                        <td className="Form-Input-control" > {subdivTarget[2].lstAccrualView != null ? (subdivTarget[2].lstAccrualView.TotalQuantity) : ""} </td>
                                        <td className="Form-Input-control" >{subdivTarget[3].lstAccrualView != null ? (subdivTarget[3].lstAccrualView.TotalQuantity) : ""} </td>
                                        <td className="Form-Input-control" >{subdivTarget[4].lstAccrualView != null ? (subdivTarget[4].lstAccrualView.TotalQuantity) : ""} </td>
                                        <td className="Form-Input-control" >{subdivTarget[5].lstAccrualView != null ? (subdivTarget[5].lstAccrualView.TotalQuantity) : ""} </td>
                                        <td className="Form-Input-control" >{subdivTarget[6].lstAccrualView != null ? (subdivTarget[6].lstAccrualView.TotalQuantity) : ""} </td>
                                        <td className="Form-Input-control" >{subdivTarget[7].lstAccrualView != null ? (subdivTarget[7].lstAccrualView.TotalQuantity) : ""} </td>
                                        <td className="Form-Input-control" >{subdivTarget[8].lstAccrualView != null ? (subdivTarget[8].lstAccrualView.TotalQuantity) : ""} </td>
                                        <td className="Form-Input-control" >{subdivTarget[9].lstAccrualView != null ? (subdivTarget[9].lstAccrualView.TotalQuantity) : ""} </td>
                                        <td className="Form-Input-control" >{subdivTarget[10].lstAccrualView != null ? (subdivTarget[10].lstAccrualView.TotalQuantity) : ""} </td>
                                        <td className="Form-Input-control" >{subdivTarget[11].lstAccrualView != null ? (subdivTarget[11].lstAccrualView.TotalQuantity) : ""} </td>
                                      </tr>
                                      :
                                      <tr className='text-left'>
                                        <th style={{ width: "100px" }}>{t('Accruals.Revenue')}</th>
                                        <td className="Form-Input-control" >{subdivTarget[0].lstAccrualView != null ? state.currency : ''} {subdivTarget[0].lstAccrualView != null ? Service.currencyFormat((subdivTarget[0].lstAccrualView.TotalRevenue).toFixed(SessionManage.getroundingPrecision())) : ""} </td>
                                        <td className="Form-Input-control" >{subdivTarget[1].lstAccrualView != null ? state.currency : ''}{subdivTarget[1].lstAccrualView != null ? Service.currencyFormat((subdivTarget[1].lstAccrualView.TotalRevenue).toFixed(SessionManage.getroundingPrecision())) : ""} </td>
                                        <td className="Form-Input-control" >{subdivTarget[2].lstAccrualView != null ? state.currency : ''} {subdivTarget[2].lstAccrualView != null ? Service.currencyFormat((subdivTarget[2].lstAccrualView.TotalRevenue).toFixed(SessionManage.getroundingPrecision())) : ""} </td>
                                        <td className="Form-Input-control" >{subdivTarget[3].lstAccrualView != null ? state.currency : ''}{subdivTarget[3].lstAccrualView != null ? Service.currencyFormat((subdivTarget[3].lstAccrualView.TotalRevenue).toFixed(SessionManage.getroundingPrecision())) : ""} </td>
                                        <td className="Form-Input-control" >{subdivTarget[4].lstAccrualView != null ? state.currency : ''} {subdivTarget[4].lstAccrualView != null ? Service.currencyFormat((subdivTarget[4].lstAccrualView.TotalRevenue).toFixed(SessionManage.getroundingPrecision())) : ""} </td>
                                        <td className="Form-Input-control" >{subdivTarget[5].lstAccrualView != null ? state.currency : ''}{subdivTarget[5].lstAccrualView != null ? Service.currencyFormat((subdivTarget[5].lstAccrualView.TotalRevenue).toFixed(SessionManage.getroundingPrecision())) : ""} </td>
                                        <td className="Form-Input-control" >{subdivTarget[6].lstAccrualView != null ? state.currency : ''} {subdivTarget[6].lstAccrualView != null ? Service.currencyFormat((subdivTarget[6].lstAccrualView.TotalRevenue).toFixed(SessionManage.getroundingPrecision())) : ""} </td>
                                        <td className="Form-Input-control" >{subdivTarget[7].lstAccrualView != null ? state.currency : ''}{subdivTarget[7].lstAccrualView != null ? Service.currencyFormat((subdivTarget[7].lstAccrualView.TotalRevenue).toFixed(SessionManage.getroundingPrecision())) : ""} </td>
                                        <td className="Form-Input-control" >{subdivTarget[8].lstAccrualView != null ? state.currency : ''} {subdivTarget[8].lstAccrualView != null ? Service.currencyFormat((subdivTarget[8].lstAccrualView.TotalRevenue).toFixed(SessionManage.getroundingPrecision())) : ""} </td>
                                        <td className="Form-Input-control" >{subdivTarget[9].lstAccrualView != null ? state.currency : ''}{subdivTarget[9].lstAccrualView != null ? Service.currencyFormat((subdivTarget[9].lstAccrualView.TotalRevenue).toFixed(SessionManage.getroundingPrecision())) : ""} </td>
                                        <td className="Form-Input-control" >{subdivTarget[10].lstAccrualView != null ? state.currency : ''} {subdivTarget[10].lstAccrualView != null ? Service.currencyFormat((subdivTarget[10].lstAccrualView.TotalRevenue).toFixed(SessionManage.getroundingPrecision())) : ""} </td>
                                        <td className="Form-Input-control" >{subdivTarget[11].lstAccrualView != null ? state.currency : ''}{subdivTarget[11].lstAccrualView != null ? Service.currencyFormat((subdivTarget[11].lstAccrualView.TotalRevenue).toFixed(SessionManage.getroundingPrecision())) : ""} </td>
                                      </tr>
                                    }
                                    <tr className='text-left'>
                                      <th className="Form-Input-control" >{t('Accruals.AccrualThreshold')}</th>
                                      <td className="Form-Input-control"  >{subdivTarget[0].SubDivMinThreshold != null ? (subdivTarget[0].SubDivMinThreshold) : ""} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[1].SubDivMinThreshold != null ? (subdivTarget[1].SubDivMinThreshold) : ""} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[2].SubDivMinThreshold != null ? (subdivTarget[2].SubDivMinThreshold) : ""} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[3].SubDivMinThreshold != null ? (subdivTarget[3].SubDivMinThreshold) : ""} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[4].SubDivMinThreshold != null ? (subdivTarget[4].SubDivMinThreshold) : ""} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[5].SubDivMinThreshold != null ? (subdivTarget[5].SubDivMinThreshold) : ""} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[6].SubDivMinThreshold != null ? (subdivTarget[6].SubDivMinThreshold) : ""} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[7].SubDivMinThreshold != null ? (subdivTarget[7].SubDivMinThreshold) : ""} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[8].SubDivMinThreshold != null ? (subdivTarget[8].SubDivMinThreshold) : ""} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[9].SubDivMinThreshold != null ? (subdivTarget[9].SubDivMinThreshold) : ""} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[10].SubDivMinThreshold != null ? (subdivTarget[10].SubDivMinThreshold) : ""} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[11].SubDivMinThreshold != null ? (subdivTarget[11].SubDivMinThreshold) : ""} </td>
                                    </tr>
                                    <tr className='text-left'>
                                      <th className="Form-Input-control" >{t('Accruals.AccrualAmount')}</th>
                                      <td className="Form-Input-control"  >{subdivTarget[0].lstAccrualView != null ? state.currency : ''}{subdivTarget[0].lstAccrualView != null ? Service.currencyFormat((subdivTarget[0].AccrualsAggregate).toFixed(SessionManage.getroundingPrecision())) : ""} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[1].lstAccrualView != null ? state.currency : ''}{subdivTarget[1].lstAccrualView != null ? Service.currencyFormat((subdivTarget[1].AccrualsAggregate).toFixed(SessionManage.getroundingPrecision())) : ""} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[2].lstAccrualView != null ? state.currency : ''}{subdivTarget[2].lstAccrualView != null ? Service.currencyFormat((subdivTarget[2].AccrualsAggregate).toFixed(SessionManage.getroundingPrecision())) : ""} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[3].lstAccrualView != null ? state.currency : ''}{subdivTarget[3].lstAccrualView != null ? Service.currencyFormat((subdivTarget[3].AccrualsAggregate).toFixed(SessionManage.getroundingPrecision())) : ""} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[4].lstAccrualView != null ? state.currency : ''}{subdivTarget[4].lstAccrualView != null ? Service.currencyFormat((subdivTarget[4].AccrualsAggregate).toFixed(SessionManage.getroundingPrecision())) : ""} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[5].lstAccrualView != null ? state.currency : ''}{subdivTarget[5].lstAccrualView != null ? Service.currencyFormat((subdivTarget[5].AccrualsAggregate).toFixed(SessionManage.getroundingPrecision())) : ""} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[6].lstAccrualView != null ? state.currency : ''}{subdivTarget[6].lstAccrualView != null ? Service.currencyFormat((subdivTarget[6].AccrualsAggregate).toFixed(SessionManage.getroundingPrecision())) : ""} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[7].lstAccrualView != null ? state.currency : ''}{subdivTarget[7].lstAccrualView != null ? Service.currencyFormat((subdivTarget[7].AccrualsAggregate).toFixed(SessionManage.getroundingPrecision())) : ""} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[8].lstAccrualView != null ? state.currency : ''}{subdivTarget[8].lstAccrualView != null ? Service.currencyFormat((subdivTarget[8].AccrualsAggregate).toFixed(SessionManage.getroundingPrecision())) : ""} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[9].lstAccrualView != null ? state.currency : ''}{subdivTarget[9].lstAccrualView != null ? Service.currencyFormat((subdivTarget[9].AccrualsAggregate).toFixed(SessionManage.getroundingPrecision())) : ""} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[10].lstAccrualView != null ? state.currency : ''}{subdivTarget[10].lstAccrualView != null ? Service.currencyFormat((subdivTarget[10].AccrualsAggregate).toFixed(SessionManage.getroundingPrecision())) : ""} </td>
                                      <td className="Form-Input-control"  >{subdivTarget[11].lstAccrualView != null ? state.currency : ''}{subdivTarget[11].lstAccrualView != null ? Service.currencyFormat((subdivTarget[11].AccrualsAggregate).toFixed(SessionManage.getroundingPrecision())) : ""} </td>
                                    </tr>
                                    <tr className='text-left'>
                                      <th className="Form-Input-control" >{t('Accruals.AccrualId')}</th>
                                      <td className="Form-Input-control">{subdivTarget[0].lstAccrualView != null ? <Link to={`/accruals/new/${subdivTarget[0].lstAccrualView.Id}/fromAccrual`}>{subdivTarget[0].lstAccrualView.AccrualId}</Link> : ''}</td>
                                      <td className="Form-Input-control">{subdivTarget[1].lstAccrualView != null ? <Link to={`/accruals/new/${subdivTarget[1].lstAccrualView.Id}/fromAccrual`}>{subdivTarget[1].lstAccrualView.AccrualId}</Link> : ''}</td>
                                      <td className="Form-Input-control">{subdivTarget[2].lstAccrualView != null ? <Link to={`/accruals/new/${subdivTarget[2].lstAccrualView.Id}/fromAccrual`}>{subdivTarget[2].lstAccrualView.AccrualId}</Link> : ''}</td>
                                      <td className="Form-Input-control">{subdivTarget[3].lstAccrualView != null ? <Link to={`/accruals/new/${subdivTarget[3].lstAccrualView.Id}/fromAccrual`}>{subdivTarget[3].lstAccrualView.AccrualId}</Link> : ''}</td>
                                      <td className="Form-Input-control">{subdivTarget[4].lstAccrualView != null ? <Link to={`/accruals/new/${subdivTarget[4].lstAccrualView.Id}/fromAccrual`}>{subdivTarget[4].lstAccrualView.AccrualId}</Link> : ''}</td>
                                      <td className="Form-Input-control">{subdivTarget[5].lstAccrualView != null ? <Link to={`/accruals/new/${subdivTarget[5].lstAccrualView.Id}/fromAccrual`}>{subdivTarget[5].lstAccrualView.AccrualId}</Link> : ''}</td>
                                      <td className="Form-Input-control">{subdivTarget[6].lstAccrualView != null ? <Link to={`/accruals/new/${subdivTarget[6].lstAccrualView.Id}/fromAccrual`}>{subdivTarget[6].lstAccrualView.AccrualId}</Link> : ''}</td>
                                      <td className="Form-Input-control">{subdivTarget[7].lstAccrualView != null ? <Link to={`/accruals/new/${subdivTarget[7].lstAccrualView.Id}/fromAccrual`}>{subdivTarget[7].lstAccrualView.AccrualId}</Link> : ''}</td>
                                      <td className="Form-Input-control">{subdivTarget[8].lstAccrualView != null ? <Link to={`/accruals/new/${subdivTarget[8].lstAccrualView.Id}/fromAccrual`}>{subdivTarget[8].lstAccrualView.AccrualId}</Link> : ''}</td>
                                      <td className="Form-Input-control">{subdivTarget[9].lstAccrualView != null ? <Link to={`/accruals/new/${subdivTarget[9].lstAccrualView.Id}/fromAccrual`}>{subdivTarget[9].lstAccrualView.AccrualId}</Link> : ''}</td>
                                      <td className="Form-Input-control">{subdivTarget[10].lstAccrualView != null ? <Link to={`/accruals/new/${subdivTarget[10].lstAccrualView.Id}/fromAccrual`}>{subdivTarget[10].lstAccrualView.AccrualId}</Link> : ''}</td>
                                      <td className="Form-Input-control">{subdivTarget[11].lstAccrualView != null ? <Link to={`/accruals/new/${subdivTarget[11].lstAccrualView.Id}/fromAccrual`}>{subdivTarget[11].lstAccrualView.AccrualId}</Link> : ''}</td>
                                    </tr>
                                  </>
                                  :
                                  ""
                                }
                              </thead>
                              <tbody>

                              </tbody>

                            </table>
                          </div>
                        </> : ''}
                        {/* {rebateMeasureValue === 'Revenue' ? <>
                          <div>
                            <label className="col-md-2 text-left">{t('Accruals.EnforcePaymentTerms')}</label>
                            <input type="checkbox" checked={state.EnforcePaymentTerms} disabled="true"></input>
                            <div style={{ display: "inline" }}>
                              <label className="col-md-2 text-left">{t('Accruals.PaymentTerms')}</label>
                              <input style={{ textAlign: "center", width: "50px" }} className="col-md-2 textbox-padding mt-2" disabled="true" type="text" value={state.paymentTermsDays}></input>
                              <label className="col-md-2 text-left">{t('Accruals.Days')}</label>
                            </div>
                          </div>
                        </> : ''}
                        {state.settlFreq === 'Monthly' ?
                          '' :
                          <div>
                            <label className="col-md-2 text-left">{t('Accruals.MinimumThresholdCriteria')}</label>
                            <input type="checkbox" checked={state.HasMinThresholdCriteria} disabled="true"></input>
                            <div style={{ display: "inline" }}>
                              <label className="col-md-2 text-left">{t('Accruals.ThresholdValue')}</label>
                              <input style={{ textAlign: "center", width: "50px" }} className="col-md-2 textbox-padding mt-2" disabled="true" type="text" value={state.MinThresholdValue == null ? 0 : state.MinThresholdValue + " %"}></input>
                              <label className="col-md-2 text-left">{`${t('Rebates.PayoutPercent')} ${(rebateIcon)}`}</label>
                              <input style={{ textAlign: "center", width: "50px" }} className="col-md-2 textbox-padding mt-2" disabled="true" type="text" value={state.MinThresholdPayout == null ? 0 : state.MinThresholdPayout + ""}></input>
                            </div>
                          </div>
                        }*/}
                      </div>
                    </div>
                    :
                    ""
                  }
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="row mt-3" id="card-RPL">
        <div className="col-lg-12 pr-lg-2">
          <div className="card text-center card_border">
            <div className="card-header chart-grid__header">
              <div className="row">
                <div className="col-md-9 text-left">
                  {t('Accruals.EligibleInvoice')}
                </div>
                <div className="col-md-3">


                  {isAnalyzeRebate == true ? "" : Service.editPerms(userPerms) == false ? "" : accStatus != 'InReview' && accStatus != 'Approved' && accStatus != 'Denied' ? (isLocked === true && lockedBy.toString() === loggedInUser) ? <button type="button" className="btn btn-sm btn-update ml-2" onClick={GetEligibleInvoices} style={{ width: '75px', float: "right" }} >{t('Recalculate')}</button> : <></> : <></>}
                  {isexport == false ? "" : Service.exportPerms(userPerms) == false ? "" : <button type="button" className="btn btn-sm btn-update ml-2" style={{ width: '50px', float: "right" }} onClick={(event) => ExporttoCSV()}>{t('Export')}</button>}

                </div>
              </div>
            </div>
            <div className="card-body" style={{ minHeight: "35vh" }}>
              <br></br>
              {state.Rebate_Period == "FY" ?
              <div className='table-responsive-sm'>
                 <table className="table  table-bordered text-left mt-2">
                  <thead className='text-black'>
                    <tr>
                      <th style={{ width: "110px", whiteSpace: "nowrap" }}>{t('Accruals.RebateValue')}</th>
                      <th style={{ width: "110px", whiteSpace: "nowrap" }}>{t('Accruals.CurrentTier')}</th>

                      {/* {volumeTier.length > 1 ?
                        <th style={{ width: "100px" }}>{t('Accruals.DeltaToNextTier')}</th>
                        : <th style={{ width: "100px" }}>{t('Accruals.DeltaToReachTarget')}</th>}*/}
                      <th style={{ width: "110px", whiteSpace: "nowrap" }}>{t('Target')}</th>
                      <th style={{ width: "110px", whiteSpace: "nowrap" }}>{t('Accruals.TotalValue')}</th>
                      <th style={{ width: "110px", whiteSpace: "nowrap" }}>{t('Accruals.TotalRevenue')}</th>
                      <th style={{ width: "110px", whiteSpace: "nowrap" }}>{t('Accruals.TotalQuantity')}</th>
                      <th style={{ width: "130px", whiteSpace: "nowrap" }}>{t('Accruals.ExcludedRevenue')}</th>
                      {rebateMeasureValue === 'Growth in Qty (Abs)' || rebateMeasureValue === 'Growth in Qty (%)' ?
                        <th style={{ width: "110px", whiteSpace: "nowrap" }}>{t('Accruals.ComparisonQty')}</th>
                        : rebateMeasureValue === 'Quantity' ? '' :
                          rebateMeasureValue === 'Growth in Rev (Abs)' || rebateMeasureValue === 'Growth in Rev (%)' ?
                            <th style={{ width: "110px", whiteSpace: "nowrap" }}>{t('Accruals.ComparisonRevenue')}</th> :
                            <th style={{ width: "110px", whiteSpace: "nowrap" }}>{t('Accruals.Outstanding')}</th>
                      }
                      <th style={{ width: "130px", whiteSpace: "nowrap" }}>{t('Accruals.TotalLatePayment')}</th>
                      <th style={{ width: "170px", whiteSpace: "nowrap" }}>{t('Accruals.TotalAccrualRevenue')}</th>
                      <th style={{ width: "130px", whiteSpace: "nowrap" }}>{t('Accruals.TotalAccrued')}</th>
                      {accStatus === 'New' || accStatus === 'InReview' || accStatus === 'Denied' ?
                        <th style={{ width: "150px", whiteSpace: "nowrap" }}>{t('Accruals.TotalAccrualsInReview')}</th>
                        :
                        <th style={{ width: "150px", whiteSpace: "nowrap" }}>{t('Accruals.TotalCurrentAccrued')}</th>}
                    </tr>
                  </thead>
                  <tbody>

                    {
                      showspinner === true ?
                        <>
                          <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                          <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                          <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                        </>
                        :

                        state.rebateValue == null ?
                          <tr className='text-center'>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>

                            {rebateMeasureValue === 'Quantity' ? '' :
                              <td></td>}
                            <td>{t('NoDataAvailable')}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          :
                          <tr>
                            {state.rebateValue === 0 || state.rebateValue === null || state.rebateValue === undefined ?
                              <td></td>
                              : <td>{state.rebateValue}</td>}
                            <td>{state.currentTier}</td>
                            {state.TargetAmount === 0 || state.TargetAmount === null || state.TargetAmount === undefined ?
                              <td style={{ whiteSpace: "nowrap" }}>{state.rebateType == 'Revenue' ? (state.currency + " " + state.TargetAmount) : (state.TargetAmount)}</td>
                              :
                              <td style={{ whiteSpace: "nowrap" }}>{state.rebateType == 'Revenue' ? (state.currency + " " + Service.currencyFormat((state.TargetAmount).toFixed(SessionManage.getroundingPrecision()))) : (Service.currencyFormat((state.TargetAmount).toFixed(SessionManage.getroundingPrecision())))}</td>
                            }
                            {/* CreateAccuralComponent problem fixed - I just commented out below line --by Dheeraj S */}
                            {/* <td>{(parseFloat(state.TargetPercent).toFixed(SessionManage.getroundingPrecision()))} %</td> */}
                            <td>{state.currency + Service.currencyFormat(parseFloat(state.TotalValue).toFixed(SessionManage.getroundingPrecision()))}</td>
                            <td>{state.currency + Service.currencyFormat(parseFloat(state.TotalRevenue).toFixed(SessionManage.getroundingPrecision()))}</td>
                            <td>{Service.numberFormat(state.TotalQuantity)}</td>
                            <td style={{ whiteSpace: "nowrap" }} >{state.currency + Service.currencyFormat(parseFloat(state.TotalExcludedAmount).toFixed(SessionManage.getroundingPrecision()))}</td>
                            {rebateMeasureValue === 'Growth in Qty (Abs)' || rebateMeasureValue === 'Growth in Qty (%)' ?
                              <td>{Service.currencyFormat(parseFloat(state.ComparisonQuantity))}</td>
                              : rebateMeasureValue === 'Quantity' ? '' :
                                rebateMeasureValue === 'Growth in Rev (Abs)' || rebateMeasureValue === 'Growth in Rev (%)' ?
                                  <td>{state.currency + Service.currencyFormat(parseFloat(state.ComparisonRevenue).toFixed(SessionManage.getroundingPrecision()))}</td>
                                  :
                                  <td>{state.currency + Service.currencyFormat(parseFloat(state.TotalOutstandingAmount).toFixed(SessionManage.getroundingPrecision()))}</td>

                            }
                            <td>{state.currency + Service.currencyFormat(parseFloat(state.TotalLatePayment).toFixed(SessionManage.getroundingPrecision()))}</td>

                            <td>{state.currency + Service.currencyFormat(parseFloat(state.TotalAccrualRevenue).toFixed(SessionManage.getroundingPrecision()))}</td>
                            <td>{state.currency + Service.currencyFormat(parseFloat(state.TotalApprovedAccrual).toFixed(SessionManage.getroundingPrecision()))}</td>
                            <td style={{ whiteSpace: "nowrap" }} >{state.currency + Service.currencyFormat(parseFloat(state.TotalUnpostedAccrual).toFixed(SessionManage.getroundingPrecision()))}</td>
                          </tr>
                    }
                  </tbody>
                </table>
                </div>
                :
                <div className='table-responsive-sm'>
                <table className="table table-bordered text-left mt-2">
                  <thead className='text-black'>
                    <tr>
                      {state.SubDivideTargetName === "Quarterly %" ?
                        <th style={{ width: "70px" }}>{t('Accruals.QtrTargetAbs')}</th>
                        :
                        <></>
                      }
                      {state.SubDivideTargetName === "Monthly %" ?
                        <th style={{ width: "70px" }}>{t('Accruals.MonthlyTargetAbs')}</th>
                        :
                        <></>
                      }
                      {state.SubDivideTargetName === "Half Yearly %" ?
                        <th style={{ width: "90px" }}>{t('Accruals.HalfYearlyTargetAbs')}</th>
                        :
                        <></>
                      }

                      {/*volumeTier.length > 1 ?
                        <th style={{ width: "100px" }}>{t('Accruals.DeltaToNextTier')}</th>
                        : <th style={{ width: "100px" }}>{t('Accruals.DeltaToReachTarget')}</th>*/}
                      <th style={{ width: "110px", whiteSpace: "nowrap" }}>{t('Accruals.Target')}</th>
                      <th style={{ width: "170px", whiteSpace: "nowrap" }}>{t('Accruals.AchievedPercent')}</th>
                      <th style={{ width: "110px", whiteSpace: "nowrap" }}>{t('Accruals.TotalValue')}</th>
                      <th style={{ width: "110px", whiteSpace: "nowrap" }}>{t('Accruals.TotalRevenue')}</th>
                      <th style={{ width: "130px", whiteSpace: "nowrap" }}>{t('Accruals.TotalQuantity')}</th>
                      <th style={{ width: "170px", whiteSpace: "nowrap" }}>{t('Accruals.ExcludedRevenue')}</th>
                      {rebateMeasureValue === 'Growth in Qty (Abs)' || rebateMeasureValue === 'Growth in Qty (%)' ?
                        <th style={{ width: "110px", whiteSpace: "nowrap" }}>{t('Accruals.ComparisonQty')}</th>
                        : rebateMeasureValue === 'Quantity' ? '' :
                          rebateMeasureValue === 'Growth in Rev (Abs)' || rebateMeasureValue === 'Growth in Rev (%)' ?
                            <th style={{ width: "110px", whiteSpace: "nowrap" }}>{t('Accruals.ComparisonRevenue')}</th> :
                            <th style={{ width: "110px", whiteSpace: "nowrap" }}>{t('Accruals.Outstanding')}</th>
                      }
                      <th style={{ width: "190px", whiteSpace: "nowrap" }}>{t('Accruals.TotalLatePayment')}</th>
                      <th style={{ width: "210px", whiteSpace: "nowrap" }}>{t('Accruals.TotalAccrualRevenue')}</th>
                      <th style={{ width: "130px", whiteSpace: "nowrap" }}>{t('Accruals.TotalAccrued')}</th>
                      {accStatus === 'New' || accStatus === 'InReview' || accStatus === 'Denied' ?
                        <th style={{ width: "250px", whiteSpace: "nowrap" }}>{t('Accruals.TotalAccrualsInReview')}</th>
                        :
                        <th style={{ width: "150px", whiteSpace: "nowrap" }}>{t('Accruals.TotalCurrentAccrued')}</th>}

                    </tr>
                  </thead>
                  <tbody>
                    {
                      showspinner === true ?
                        <>
                          <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                          <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                          <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                        </>
                        :
                        state.targetAbs.length === 0 ?
                          <tr className='text-center'>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            {rebateMeasureValue === 'Quantity'
                              ? '' :
                              <td></td>}
                            <td>{t('NoDataAvailable')}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          :
                          <tr>
                            {state.SubDivideTargetName === "Quarterly %" || state.SubDivideTargetName === "Monthly %" || state.SubDivideTargetName === "Half Yearly %" ?
                              <>
                                {state.targetAbs === 0 || state.targetAbs === null || state.targetAbs === undefined ?
                                  <td>{state.rebateType == 'Revenue' ? (state.currency + " " + state.targetAbs) : (state.targetAbs)}</td>
                                  :
                                  <td>{state.rebateType == 'Revenue' ? (state.currency + " " + Service.currencyFormat((state.targetAbs).toFixed(SessionManage.getroundingPrecision()))) : Service.numberFormat((state.targetAbs))}</td>}
                              </> : ''}
                            {state.TargetAmount === 0 || state.TargetAmount === null || state.TargetAmount === undefined ?
                              <td style={{ whiteSpace: "nowrap" }}>{state.rebateType == 'Revenue' ? (state.currency + " " + state.TargetAmount) : (state.TargetAmount)}</td>
                              :
                              <td style={{ whiteSpace: "nowrap" }}>{state.rebateType == 'Revenue' ? (state.currency + " " + Service.currencyFormat((state.TargetAmount).toFixed(SessionManage.getroundingPrecision()))) : Service.numberFormat(((state.TargetAmount)))}</td>
                            }
                            <td>{(parseFloat(state.TargetPercent).toFixed(SessionManage.getroundingPrecision()))} %</td>
                            <td>{state.currency + Service.currencyFormat(parseFloat(state.TotalValue).toFixed(SessionManage.getroundingPrecision()))}</td>
                            <td>{state.currency + Service.currencyFormat(parseFloat(state.TotalRevenue).toFixed(SessionManage.getroundingPrecision()))}</td>
                            <td>{Service.numberFormat(state.TotalQuantity)}</td>
                            <td style={{ whiteSpace: "nowrap" }} >{state.currency + Service.currencyFormat(parseFloat(state.TotalExcludedAmount).toFixed(SessionManage.getroundingPrecision()))}</td>
                            {rebateMeasureValue === 'Growth in Qty (Abs)' || rebateMeasureValue === 'Growth in Qty (%)' ?
                              <td>{Service.currencyFormat(parseFloat(state.ComparisonQuantity))}</td>
                              : rebateMeasureValue === 'Quantity' ? '' :
                                rebateMeasureValue === 'Growth in Rev (Abs)' || rebateMeasureValue === 'Growth in Rev (%)' ?
                                  <td>{state.currency + Service.currencyFormat(parseFloat(state.ComparisonRevenue).toFixed(SessionManage.getroundingPrecision()))}</td>
                                  :
                                  <td>{state.currency + Service.currencyFormat(parseFloat(state.TotalOutstandingAmount).toFixed(SessionManage.getroundingPrecision()))}</td>
                            }
                            <td>{state.currency + Service.currencyFormat(parseFloat(state.TotalLatePayment).toFixed(SessionManage.getroundingPrecision()))}</td>

                            <td>{state.currency + Service.currencyFormat(parseFloat(state.TotalAccrualRevenue).toFixed(SessionManage.getroundingPrecision()))}</td>
                            <td>{state.currency + Service.currencyFormat(parseFloat(state.TotalApprovedAccrual).toFixed(SessionManage.getroundingPrecision()))}</td>
                            <td style={{ whiteSpace: "nowrap" }} >{state.currency + Service.currencyFormat(parseFloat(state.TotalUnpostedAccrual).toFixed(SessionManage.getroundingPrecision()))}</td>
                          </tr>
                    }
                  </tbody>
                </table>
                </div>
              }

              <div className="d-flex align-items-center">
                <Select
                  className={"col-md-6 textbox-padding-dropdown mt-2 fontsizedropdown m_width"}
                  value={selectedNameOption}
                  onChange={handleSelectedNameChange}
                  options={nameOptions}
                />
                <div className='row ml-2 l_width' style={{ float: 'right' }}>
                  <button id="addRemoveCol" icon className="btn btn-update ml-2 mt-1 pa-0" type="submit"
                    style={{ float: "left", cursor: "pointer", padding: '0px', height: '29px', width: '26px' }}
                    onClick={AddRemoveColumn} title='Manage View'><i class="fa fa-columns" aria-hidden="true"></i></button>
                </div>
              </div>

              <div className='table-responsive-sm'>
              <table className="table table-bordered text-left mt-2" id='tableB'>

                <thead className='text-white' style={{ backgroundColor: "var(--primary)" }}>
                  <tr>
                    {/* <th className="tableheader" style={{ width: '30px' }}>
                      <input
                        type="checkbox"
                        onChange={checkAll}
                        checked={checkAllChecked}
                        id="option-all"
                      />
                    </th> */}
                    <th className="tableheader" style={{ width: "30px" }}>
                      <input
                        type="checkbox"
                        onChange={handleCheckAll}
                        checked={checkAllCheckeds}
                      />
                    </th>
                    <th id="CustomerName" style={{ backgroundColor: (filterValue1.CustomerName ? '#6cae55' : '') }}
                      title='CustomerName' hidden={hideColumn.includes('CustomerName') ? true : false}>
                      <div className="d-flex text-nowrap">
                        <span>{t('Accruals.Customer')}</span>
                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('CustomerName')}>
                        </span> <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnItems('CustomerName')}></span>
                      </div>
                    </th>

                    <th id="ProductName" style={{ backgroundColor: (multiFilterValue1.ProductName ? '#6cae55' : ''), maxWidth: '120px' }}
                      title='Product ID'
                      hidden={(selectedNameOption && (selectedNameOption.value === 'ByCategory' || selectedNameOption.value == 'BySubCategory')) || hideColumn.includes('ProductName')}>
                      <div className="d-flex text-nowrap">
                        <span>{t('Accruals.MaterialNo')}</span>
                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('ProductName')}></span>
                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnItems('ProductName')}></span>
                      </div>
                    </th>
                    <th id="CategoryLabel" style={{ backgroundColor: (filterValue1.CategoryLabel ? '#6cae55' : ''), maxWidth: '120px' }}
                      title='CategoryLabel' hidden={(selectedNameOption && (selectedNameOption.value === 'ByItem')) || hideColumn.includes('Category')}>
                      <div className="d-flex text-nowrap">
                        <span>{t('Category')}</span>
                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('CategoryLabel')}></span>
                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnItems('CategoryLabel')}></span>
                      </div>
                    </th>
                    <th id="SubCategoryLabel" style={{ backgroundColor: (filterValue1.SubCategoryLabel ? '#6cae55' : ''), maxWidth: '120px' }}
                      title='SubCategoryLabel' hidden={(selectedNameOption && (selectedNameOption.value == 'ByCategory' || selectedNameOption.value === 'ByItem')) || hideColumn.includes('SubCategoryLabel')}>
                      <div className="d-flex text-nowrap">
                        <span>{t('Sub Category')}</span>
                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('SubCategoryLabel')}></span>
                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnItems('SubCategoryLabel')}></span>
                      </div>
                    </th>

                    <th id="ProductLabel" style={{ backgroundColor: (filterValue1.ProductLabel ? '#6cae55' : ''), maxWidth: '120px' }}
                      title='ProductLabel' hidden={(selectedNameOption && (selectedNameOption.value === 'ByCategory' || selectedNameOption.value === 'BySubCategory')) || hideColumn.includes('ProductLabel')}>
                      <div className="d-flex text-nowrap">
                        <span>{t('Item Name')}</span>
                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('ProductLabel')}></span>
                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnItems('ProductLabel')}></span>
                      </div>
                    </th>
                    <th id="VehicleType" style={{ backgroundColor: (filterValue1.VehicleType ? '#6cae55' : ''), maxWidth: '120px' }}
                      title='VehicleType' hidden={(selectedNameOption && (selectedNameOption.value === 'ByCategory' || selectedNameOption.value === 'ByItem')) || hideColumn.includes('VehicleType')}>
                      <div className="d-flex text-nowrap">
                        <span>{t('TargetSetting.VehicleType')}</span>
                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('VehicleType')}></span>
                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnItems('VehicleType')}></span>
                      </div>
                    </th>

                    <th id='Quantity' style={{ backgroundColor: (filterValue1.Quantity ? '#6cae55' : ''), maxWidth: "100px" }} title='Qty'
                      hidden={hideColumn.includes('Quantity') ? true : false}>
                      <div className="d-flex text-nowrap">
                        <span>{t('Qty')} </span>
                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Quantity')}></span>
                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnItems('Quantity')}></span>
                      </div>
                    </th>

                    <th id='Revenue' style={{ backgroundColor: (filterValue1.TotalRevenue ? '#6cae55' : ''), maxWidth: "110px" }} title='Sale'
                      hidden={hideColumn.includes('Revenue') ? true : false}>
                      <div className="d-flex text-nowrap">
                        <span>{t('Accruals.Revenue')}
                        </span><span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('TotalRevenue')}></span>
                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumnItems('TotalRevenue')}></span>
                      </div>
                    </th>

                  </tr>
                </thead>
                {<tbody>
                  {
                    showspinner === true ?
                      <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                      :
                      visibleData.length === 0 ?
                        <tr className='text-center'>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>{t('NoDataAvailable')}</td>
                          <td></td>
                          <td></td>

                        </tr>
                        :
                        visibleData.map((i, index) =>
                          <tr
                            onDoubleClick={
                              selectedNameOption?.value === "ByItem"
                                ? () => GetTransactionData(i.ProductName, i.Id, i.Customer, index, "Ascending", "Id", [])
                                : undefined
                            }
                          >
                            {/* `${i.SubCategoryName},${i.CategoryName},${i.VehicleType}` */}
                            {/* <td><input onChange={(event) => handleItemChange(event, i.Id)} type="checkbox" name="chkBox" id={i.Id} value={selectedNameOption?.value === 'ByCategory' ? i.CategoryName : selectedNameOption?.value === 'BySubCategory' ? `${i.SubCategoryName},${i.CategoryLabel},${i.VehicleType}` : i.Id}></input></td> */}
                            <td>
                              
                              <input
                                onChange={(event) => handleRowCheckboxChange(event, selectedNameOption?.value === 'ByItem' ? i.Id : selectedNameOption?.value === 'ByCategory' ? i.CategoryName : `${i.SubCategoryName},${i.CategoryLabel},${i.VehicleType}`)}
                                type="checkbox"
                                name="chkBox"
                                id={i.Id}
                                checked={checkedMultiValueid.includes(
                                  selectedNameOption?.value === 'ByItem'
                                    ? i.Id
                                    : selectedNameOption?.value === 'ByCategory'
                                      ? i.CategoryName
                                      : `${i.SubCategoryName},${i.CategoryLabel},${i.VehicleType}`
                                )}
                              />


                             
                            </td>
                            <td style={{ maxWidth: "100px" }} hidden={hideColumn.includes('CustomerName') ? true : false}>
                              <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={i.Customer}>{i.Customer}</div>
                            </td>
                            <td title={i.ProductName} style={{ width: "120px" }} hidden={(selectedNameOption && (selectedNameOption.value === 'ByCategory' || selectedNameOption.value === 'BySubCategory')) || hideColumn.includes('ProductName')}>
                              <div>
                                {(i.HasPaymentReceivedLate === true) ?

                                  <i className="fa fa-exclamation" style={{ color: 'orange' }} title="Payment received after the payment terms period for one or more Invoice"></i>

                                  : <></>}
                                {(i.HasPaymentNotReceived === true) ?
                                  <i className="fa fa-exclamation" style={{ color: 'red' }} title="Payment not received for one or more Invoice"></i>
                                  : <></>}
                                <a href="#" onClick={() => GetTransactionData(i.ProductName, i.Id, i.Customer, index, "Ascending", "Id", [])}> {i.ProductName}</a>
                              </div>
                            </td>
                            <td title={i.CategoryLabel} style={{ textAlign: 'center' }} hidden={(selectedNameOption && (selectedNameOption.value === 'ByCategory' || selectedNameOption.value === 'ByItem')) || hideColumn.includes('Category')}>{i.CategoryLabel}</td>

                            <td style={{ maxWidth: "120px" }} hidden={hideColumn.includes('ProductLabel') ? true : false}>
                              <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={selectedNameOption.value === 'ByCategory' ? i.CategoryLabel : selectedNameOption.value === 'BySubCategory' ? i.SubCategoryLabel : i.ProductLabel}>
                                {selectedNameOption.value === 'ByCategory' ? i.CategoryLabel : selectedNameOption.value === 'BySubCategory' ? i.SubCategoryLabel : i.ProductLabel}
                              </div>
                            </td>
                            <td title={i.VehicleType} style={{ textAlign: 'center' }} hidden={(selectedNameOption && (selectedNameOption.value === 'ByCategory' || selectedNameOption.value === 'ByItem')) || hideColumn.includes('VehicleType')}>{i.VehicleType}</td>

                            <td title={i.Quantity} hidden={hideColumn.includes('Quantity') ? true : false}>{Service.numberFormat(i.Quantity)}</td>

                            {i.TotalRevenue === null ? <td>0</td> :
                              <td title={i.TotalRevenue} hidden={hideColumn.includes('Revenue') ? true : false}>{state.currency} {Service.currencyFormat(parseFloat(i.TotalRevenue).toFixed(SessionManage.getroundingPrecision()))}</td>}

                          </tr>

                        )}


                  {
                    showspinner === true ?
                      <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                      :
                      rebateMeasureValue.length === 0 ?
                        <tr className='text-center'>
                          <td></td>
                          <td></td>
                          <td>{t('NoDataAvailable')}</td>
                          <td></td>
                          <td></td>
                        </tr>
                        :
                        rebateMeasureValue !== 'Growth in Qty (Abs)' & rebateMeasureValue !== 'Growth in Qty (%)' & rebateMeasureValue !== 'Growth in Rev (Abs)' & rebateMeasureValue !== 'Growth in Rev (%)' ?
                          <>

                            <td></td>
                            <td hidden={hideColumn.includes('CustomerName') ? true : false}></td>
                            <td hidden={(selectedNameOption && (selectedNameOption.value === 'ByCategory' || selectedNameOption.value === 'BySubCategory')) || hideColumn.includes('ProductName')}></td>
                            <td style={{ textAlign: "center", fontWeight: "bold" }} hidden={(selectedNameOption && (selectedNameOption.value === 'ByCategory' || selectedNameOption.value === 'ByItem')) || hideColumn.includes('CategoryLabel')}> </td>

                            <td hidden={hideColumn.includes('ProductLabel') ? true : false}></td>
                            <td style={{ textAlign: "center", fontWeight: "bold" }} hidden={(selectedNameOption && (selectedNameOption.value === 'ByCategory' || selectedNameOption.value === 'ByItem')) || hideColumn.includes('VehicleType')}>{t('Total')} </td>

                            <td style={{ fontWeight: "bold" }} hidden={hideColumn.includes('Quantity') ? true : false}>{Service.numberFormat(totalQuantity)}</td>
                            <td style={{ fontWeight: "bold" }} hidden={hideColumn.includes('Revenue') ? true : false}>{state.currency + Service.currencyFormat(parseFloat(totalRevenue).toFixed(SessionManage.getroundingPrecision()))}</td>

                          </>
                          :
                          <></>
                  }
                  {
                    showspinner === true ?
                      <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                      :
                      rebateMeasureValue.length === 0 ?
                        <tr className='text-center'>
                          <td></td>
                          <td></td>
                          <td>{t('NoDataAvailable')}</td>
                          <td></td>
                          <td></td>
                        </tr>
                        :
                        rebateMeasureValue === 'Growth in Qty (Abs)' || rebateMeasureValue === 'Growth in Qty (%)' || rebateMeasureValue === 'Growth in Rev (Abs)' || rebateMeasureValue === 'Growth in Rev (%)' ?
                          <>
                            <td></td>
                            <td></td>
                            <td hidden={hideColumn.includes('CustomerName') ? true : false}></td>
                            <td hidden={(selectedNameOption && (selectedNameOption.value === 'ByCategory' || selectedNameOption.value === 'BySubCategory')) || hideColumn.includes('ProductName')}></td>
                            <td hidden={hideColumn.includes('ProductLabel') ? true : false}></td>
                            <td style={{ fontWeight: "bold" }} hidden={hideColumn.includes('Quantity') ? true : false}>{(totalQuantity)}</td>
                            <td style={{ fontWeight: "bold" }} hidden={hideColumn.includes('Revenue') ? true : false}>
                              {state.currency + Service.currencyFormat(parseFloat(totalRevenue).toFixed(SessionManage.getroundingPrecision()))}</td>
                          </>
                          :
                          <></>
                  }
                </tbody>
                }
              </table>
              </div>

              {totalPages > 1 && (<div className="pagination-controls d-flex justify-content-center align-items-center mt-3">
                <button
                  className="btn btn-primary mr-2"
                  onClick={goToPreviousPage}
                  disabled={currentPage === 1}
                >
                  &#8592; {/* Left Arrow */}
                </button>
                <span>
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  className="btn btn-primary ml-2"
                  onClick={goToNextPage}
                  disabled={currentPage === totalPages}
                >
                  &#8594; {/* Right Arrow */}
                </button>
              </div>)}






              <br />
              <div className='col-lg-8 col-sm-12'></div>
              <div className='row'>
                {
                  totalselectedshow === true ?

                    <div className='col-lg-2 forgettext col-sm-12 border form-control' style={{ textAlign: "center", marginLeft: "0.938rem" }}>
                      {t('TotalRecordsSelected')} - <span id="totalselected">{totalItemSelectedCountlst}</span>
                    </div>
                    :
                    <div className='col-lg-2 col-sm-12' style={{ textAlign: "center", marginLeft: "0.938rem" }}>

                    </div>
                }

                <div style={{ flex: "0 0 64%", maxWidth: "64%" }}></div>
                <div className='col-lg-2 forgettext  col-sm-12 border form-control' style={{ textAlign: "center", marginLeft: "0.1rem" }}>
                  {t('TotalRecords')} - {totalItemCountlst}
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      {console.log("13-6", totalItemSelectedCountlst, totalItemCountlst)}

      {
        isOpen && <Popup

          content={<>
            <b></b>


            <div style={{ marginTop: '10px', height: '80px' }} className={`ag-theme-alpine ${classes1['aggrid-Container']}`} id="card-RPL2">

              <div className="scrollbar-class card-body table-responsive" style={{ height: "500px", overflowX: "hidden", overflowY: "scroll" }}>
                <span className="close-icon" onClick={cancel}>x</span>
                <table class="table table-head-fixed text-nowrap table-bordered table-striped" id="tableB">
                  <thead className='text-white' style={{ backgroundColor: "var(--primary)" }}>
                    <tr>

                      <th id="InvoiceNumber" style={{ backgroundColor: (filterValueDict.InvoiceNumber ? '#6cae55' : '') }}>
                        <div className='d-flex text-nowrap'>
                          <span>{t('Accruals.Invoice')}</span>
                          <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumnItems('InvoiceNumber')}></span>
                          <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('InvoiceNumber')}></span>
                        </div>
                      </th>
                      <th id="TransactionDate" style={{ backgroundColor: (filterValueDict.TransactionDate ? '#6cae55' : '') }}>
                        <div className='d-flex text-nowrap'>
                          <span> {t('Invoice Date')} </span>
                          <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumnItems('TransactionDate')}></span>
                          <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('TransactionDate')}></span>
                        </div>
                      </th>

                      <th id="IsExclude" style={{ backgroundColor: (multiFilterValue.IsExclude ? '#6cae55' : ''), maxWidth: "100px" }}>
                        <input name="chkBox" type="checkbox" disabled={isAnalyzeRebate == true ? true : headerdisable} checked={excludeheader} onChange={excludeProducts}></input>
                        &nbsp;
                        <span className='fa fa-sort' onClick={() => SortColumnItems('IsExclude')}></span>
                        <span className='fa fa-filter' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('IsExclude')}></span>
                      </th>


                      <th id='LineItem' style={{ backgroundColor: (filterValueDict.LineItem ? '#6cae55' : '')/*(popFilterValue.LineItem ? '#6cae55' : '') */ }}>
                        <div className='d-flex text-nowrap'>
                          <span>{t('Accruals.Line')} </span>
                          <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumnItems('LineItem')}></span>
                          <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('LineItem')}></span>
                        </div>
                      </th>
                      <th id='Qty' style={{ backgroundColor: (filterValueDict.Qty ? '#6cae55' : '') /*(popFilterValue.Quantity ? '#6cae55' : '')*/ }}>
                        <div className='d-flex text-nowrap'>
                          <span>{t('Accruals.Qty')} </span>
                          <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumnItems('Qty')}></span>
                          <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Qty')}></span>
                        </div>
                      </th>
                      <th id='Revenue' style={{ backgroundColor: (filterValueDict.Revenue ? '#6cae55' : '') /*(popFilterValue.Revenue ? '#6cae55' : '')*/ }}>
                        <div className='d-flex text-nowrap'>
                          <span>{t('Accruals.Revenue')} </span>
                          <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumnItems('Revenue')}></span>
                          <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Revenue')}></span>
                        </div>
                      </th>

                      <th id='Netprice' style={{ backgroundColor: (filterValueDict.Netprice ? '#6cae55' : '')/*(popFilterValue.Netprice ? '#6cae55' : '')*/ }}>
                        <div className='d-flex text-nowrap'>
                          <span>{t('Invoice Price/Unit')} </span>
                          <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumnItems('Netprice')}></span>
                          <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Netprice')}></span>
                        </div>
                      </th>
                      <th id='WeightedAverage' style={{ backgroundColor: (filterValueDict.WeightedAverage ? '#6cae55' : '')/*commented this(popFilterValue.WeightedAverage ? '#6cae55' : '')*/ }}>
                        <div className='d-flex text-nowrap'>
                          <span> {t('Accruals.WeightedAvg')} </span>
                          <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumnItems('WeightedAverage')}></span>
                          <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('WeightedAverage')}></span>
                        </div>
                      </th>
                      <th id='Region' style={{ backgroundColor: (filterValueDict.Region ? '#6cae55' : '') }}>
                        <div className='d-flex text-nowrap'>
                          <span>{t('Accruals.Region')} </span>
                          <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumnItems('Region')}></span>
                          <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Region')}></span>
                        </div>
                      </th>
                      <th id='Message' style={{ backgroundColor: (filterValueDict.Message ? '#6cae55' : '') }}>
                        <div className='d-flex text-nowrap'>
                          <span>{t('Accruals.Message')} </span>
                          <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumnItems('Message')}></span>
                          <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Message')}></span>
                        </div>
                      </th>

                    </tr>
                  </thead>
                  {<tbody>
                    {transactionData.length === 0 ?
                      <tr>
                        <td colSpan="10" style={{ textAlign: 'center' }}>
                          {t('NoDataAvailable')}
                        </td>
                      </tr>
                      :
                      transactionData.map((i, index) =>
                        <tr>

                          <td>{i.InvoiceNumber}</td>
                          <td>{moment(i.TransactionDate).format(SessionManage.getcurrentdateformat())}</td>

                          <td style={{ textAlign: 'center' }}>
                            {i.Status === 'New' && isAnalyzeRebate == false && (i.Message == null || i.Message == 'Manually excluded') ?
                              <input name="chkBox" type="checkbox" checked={i.IsExclude} id={i.TransactionId} onChange={(event) => handlevalue(event, i.TransactionId, index)}></input>
                              :
                              <input name="chkBox" type="checkbox" checked={i.IsExclude} id={i.TransactionId} disabled={true}></input>
                            }
                          </td>
                          <td>{i.LineItem}</td>
                          <td>{Service.numberFormat(i.Quantity)}</td>

                          <td>{state.currency} {Service.currencyFormat(parseFloat(i.Revenue).toFixed(SessionManage.getroundingPrecision()))}</td>

                          <td>{state.currency} {Service.currencyFormat(parseFloat(i.Netprice).toFixed(SessionManage.getroundingPrecision()))}</td>
                          {i.WeightedAverage === null ? <td>0</td> :
                            <td>{state.currency} {Service.currencyFormat(parseFloat(i.WeightedAverage).toFixed(SessionManage.getroundingPrecision()))}</td>
                          }
                          <td>{i.Region}</td>

                          <td style={{ maxWidth: "250px" }}>{i.Message}</td>
                        </tr>
                      )}
                  </tbody>}
                </table>

                <div className='row'>

                  <div className='col-md-2 border' style={{ textAlign: "center", marginLeft: "15px", paddingTop: "5px" }}>
                    {t('TotalRecords')} -{totalCountlst}
                  </div>
                  {
                    totalselectedshow === true ?
                      <>
                        <div className='col-md-2 border' style={{ textAlign: "center", paddingTop: "5px", marginLeft: "10px" }}>
                          {t('Accruals.TotalRecordsExcluded')} -<span id="totalselected">{totalselectedcount}</span>
                        </div>
                        <div className='col-md-2' style={{ marginLeft: "82px" }}></div>
                      </>

                      :
                      <>
                        <div className='col-md-3  col-sm-12' style={{ textAlign: "center" }}>  </div>
                        <div className='col-md-2'></div>
                      </>
                  }

                  <div className="col-md-4" style={{ "margin-left": "75px" }}>
                    {isAnalyzeRebate == true ? "" : Service.editPerms(userPerms) == false ? "" : <Button type="button" className="btn btn-primary btn-sm m-1" onClick={apply} style={{ float: "right", width: "45px" }}>{t('Apply')}</Button>}
                    <Button type="button" className="btn btn-primary btn-sm m-1" style={{ float: "right", width: "45px" }} onClick={cancel} >{t('Close')}</Button>
                  </div>
                </div>

              </div>
            </div>
          </>}
          handleClose={togglePopup}
        />
      }

      <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
        onHide={() => setShowErrorModal(false)}></CustomErrorPopup>

      <Modal show={showDeleteModal}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Body style={{ "textAlign": 'center' }}>{t('Accruals.Validations.AccrualIsNotSubmittedDoYouWantToContinue ?')}</Modal.Body>
        <Modal.Footer>
          <Button size='sm' style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "45px" }} onClick={() => CloseHandler('openclose')}>
            {t('Close')}
          </Button>
          <Button size='sm' style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "55px" }} onClick={() => CloseHandler('proceed')}>
            {t('Proceed')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showWorkflowAlert}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Body style={{ "textAlign": 'center' }}>{t('Accruals.WorkflowAlert')}</Modal.Body>
        <Modal.Footer>
          <Button size='sm' style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "30px" }} onClick={() => workflowAlert('close')}>
            {t('Ok')}
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={showSubmitModal} size="bg"
        centered>
        <Modal.Header>
          <Modal.Title>{t('Accruals.SubmitComments')}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ "textAlign": 'center' }} >
          <Form.Control className={classes['Textarea-width']} as="textarea" rows={2} value={commentsForSubmit} name="commentsForSubmit"
            onChange={(e) => { setCommentsForSubmit(e.target.value) }} />
        </Modal.Body>
        <Modal.Footer>
          <Button size='sm' style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "45px" }} onClick={() => showSubmitDialog('showSubmitDialog')}>
            {t('Close')}
          </Button>
          <Button size='sm' style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "50px" }} onClick={() => showSubmitDialog('proceed')}>
            {t('Submit')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showApprovedModal} size="bg"
        centered>
        <Modal.Header>
          <Modal.Title>{t('Accruals.ApprovalComments')}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ "textAlign": 'center' }} >
          <Form.Control className={classes['Textarea-width']} as="textarea" rows={2} value={commentsForApproved} name="commentsForApproved"
            onChange={(e) => { setCommentsForApproved(e.target.value) }} />
        </Modal.Body>
        <Modal.Footer>
          <Button size='sm' style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "50px" }} onClick={() => showApprovedDialog('showApprovedDialog')}>
            {t('Cancel')}
          </Button>
          <Button size='sm' style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "55px" }} onClick={() => showApprovedDialog('proceed')}>
            {t('Approve')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeniedModal} size="bg"
        centered>
        <Modal.Header>
          <Modal.Title>{t('Accruals.DeniedComments')}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ "textAlign": 'center' }} >
          <Form.Control className={classes['Textarea-width']} as="textarea" rows={2} value={commentsForDenied} name="commentsForDenied"
            onChange={(e) => { setCommentsForDenied(e.target.value) }} />
        </Modal.Body>
        <Modal.Footer>
          <Button size='sm' style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "50px" }} onClick={() => showDeniedDialog('showDeniedDialog')}>
            {t('Cancel')}
          </Button>
          <Button size='sm' style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "40px" }} onClick={() => showDeniedDialog('proceed')}>
            {t('Deny')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={errorPopup} size="sm"
        centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ "textAlign": 'center' }} >
          <label>{t('Accruals.AccrualFailed')}</label>
        </Modal.Body>
        <Modal.Footer>
          <Button size='sm' style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "45px" }} onClick={() => setErrorPopup(false)}>
            {t('Close')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={submitErrorPopup} size="sm"
        centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ "textAlign": 'center' }} >
          <p>{t('Accruals.Validations.TheProductsWithAccruedAmountEqualToZeroWillNotBeSubmittedForApproval')}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button size='sm' style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "30px" }} onClick={() => zeroAccrual()}>
            {t('Ok')}
          </Button>
        </Modal.Footer>
      </Modal>

      {
        showfilterpopup === true ?
          //changes made here
          <div className='pop-up' style={{
            left: divleft, top: "145px", width: "200px",
            position: 'fixed',
          }}>
            {/* <div className='pop-up' style={{ left: divleft, top: "140px", width: "200px",position:'fixed',overflow:'hidden' }}> */}
            {/* {filterCol === 'InvoiceNumber' || filterCol === "Status" || filterCol === "Region" ?
              <>
                <div className="container">
                  <div className="row">
                    <b className="forgettext col-md-11 textbox-padding ml-2">{filtertextPopup}</b>
                  </div>
                </div>
                <div className="container mt-2">
                  <div className="row">
                    <input type='textbox' placeholder={t('Search')} className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                  </div>
                </div>
              </>
              :
              <></>

            } */}
            {filterCol === 'InvoiceNumber' || filterCol === "Message" || filterCol === "Region" ?
              <>
                <div className="container">
                  <div className="row">
                    <b className="forgettext col-md-11 textbox-padding ml-2">{filtertextPopup}</b>
                  </div>

                </div>
                <div className="container mt-2">
                  <div className="row">
                    <input type='textbox' placeholder={t('Search')} className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                  </div>
                </div>
              </>
              :
              <></>

            }

            {filterCol === 'TransactionDate' ?
              <>
                <div className="container">
                  <div className="row">
                    <b className="forgettext col-md-11 textbox-padding ml-2">{filtertextPopup}</b>
                  </div>
                </div>
                <div className="container mt-2">
                  <div className="row">
                    <input type='date' placeholder='Search...' className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                  </div>
                </div>
              </>
              :
              <></>
            }

            {filterCol === 'IsExclude' ?

              <>
                <div className="container">
                  <div className="row">
                    <b className="forgettext col-md-11 textbox-padding ml-2">{filtertextPopup}</b>

                  </div>

                </div>

                <div className="container mt-2">
                  <div className="row">
                    <input type="text" placeholder="Search" value={filterSearchPopup} onChange={(event) => UserfilterHandlerPopup(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                  </div>

                  <div className="row contentoverflow mt-2">
                    <table className="filtertable">
                      <tbody>
                        {/* <tr>
                          <td><input type="checkbox" name="filter-option-all" id="option-all" onChange={checkAllFilterPopup} /></td>
                          <td>Select All</td>
                        </tr> */}
                        {
                          dropdownData.map((item, index) => (

                            <tr key={index}>
                              <>
                                <td><input id={item.Id} data-index="1" name="filter-option-all" checked={item.ischecked} type="checkbox" onChange={item.Name === "Select All" ? checkAllFilterPopup : (event) => OndropdownChange(event, item.Id, item.Name, filterCol)} /></td>
                                <td>{item.Name}</td>
                              </>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>

                  </div>
                </div>
              </>
              :
              <></>
            }

            {filterCol === 'Qty' || filterCol === 'Netprice' || filterCol === 'WeightedAverage' || filterCol === 'LineItem' || filterCol === 'Revenue' ?

              <div>
                <div className="row">
                  <div className="row" style={{ marginLeft: "40px", paddingBottom: "2px" }}>
                    <b>{filtertextPopup}</b>
                  </div>
                  {/* changes made here */}
                  <Select
                    className='col-md-9 textbox-padding ml-4 fontsizedropdown'
                    options={operatorValOption}
                    placeholder="Select"
                    value={operatorVal[filterCol]}
                    onChange={OnChangePopOperatorValue} //changed this from OnChangeOperatorValue to OnChangePopOperatorValue 
                    // styles={customStyles}
                    styles={{
                      ...customStyles,
                      menuList: (provided) => ({
                        ...provided,
                        maxHeight: '150px',
                        overflowY: 'auto',
                      }),
                    }}
                  />
                </div>
                <div className="mt-2">
                  {/* changes here -- by dheeraj s */}
                  <input type='text' className='col-md-10 filtertextboxbackground ' style={{ "margin-left": "12px", "padding-left": "1px" }} onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()}
                    value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)} />
                </div>
              </div>
              :
              <></>
            }
            <div style={{ paddingTop: '10px', paddingLeft: '47px', paddingBottom: '7px' }}>
              <button className="btn btn-sm btn-update" onClick={() => filterHandler('Apply')} style={{ width: "45px" }}>{t('Apply')}</button>
              <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "45px" }} onClick={() => filterHandler('Reset')}>{t('Reset')}</button>
            </div>
            <div>
            </div>
          </div>
          :
          <></>
      }

      {
        showfilterpopup1 === true ?
          <div className='pop-up' style={{ left: divleft1, top: divtop1, width: "200px" }}>
            {filterCol1 === 'ProductName' || filterCol1 === 'Exclude' ?

              <>
                <div className="container">
                  <div className="row">
                    <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>

                  </div>

                </div>

                <div className="container mt-2">
                  <div className="row">
                    <input type="text" placeholder="Search" value={filterSearch} onChange={(event) => UserfilterHandler(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                  </div>

                  <div className="row contentoverflow mt-2">
                    <table className="filtertable">
                      <tbody>
                        {/* <tr>
                          <td><input type="checkbox" name="filter-option-all" id="option-all" onChange={checkAllFilter} /></td>
                          <td>Select All</td>
                        </tr> */}
                        {
                          dropdownData1.map((item, index) => (

                            <tr key={index}>
                              {filtertext === 'Material No' ?
                                <>
                                  <td><input id={item.Id} data-index="1" name="filter-option-all" checked={item.ischecked} type="checkbox" onChange={item.Name === "Select All" ? checkAllFilter : (event) => OndropdownChangeItems(event, item.Id, item.ProductName, "Product", filterCol)} /></td>
                                  <td> {item.ProductName}</td>
                                </>
                                :
                                <>
                                  <td><input id={item.Id} data-index="1" name="filter-option-all" checked={item.ischecked} type="checkbox" onChange={item.Name === "Select All" ? checkAllFilter : (event) => OndropdownChangeItems(event, item.Id, item.Name, "Exclude", filterCol)} /></td>
                                  <td>{item.Name}</td>
                                </>
                              }
                            </tr>
                          ))
                        }

                      </tbody>
                    </table>

                  </div>
                </div>
              </>
              :
              <></>
            }
            {filterCol1 === 'Quantity' || filterCol1 === 'ComparisonQuantity' || filterCol1 === 'ComparisonNetprice' || filterCol1 === 'Netprice' || filterCol1 === 'ApprovedAccrual' || filterCol1 === 'UnpostedAccruals' || filterCol1 === 'WeightedAveragePerUnit' || filterCol1 === 'TotalApprovedAccrual' || filterCol1 === 'DeltaReachNextTier' || filterCol1 === 'AccrualRevenue' || filterCol1 === 'ExcludedAmount' || filterCol1 === 'ExcludedQuantity' || filterCol1 === 'AccrualQuantity' || filterCol1 === 'TotalRevenue' ?
              <div>
                <div className="row">
                  <div className="row" style={{ marginLeft: "40px", paddingBottom: "2px" }}>
                    <b>{filtertext}</b>
                  </div>
                  {/* changes made here */}
                  <Select
                    className='col-md-9 textbox-padding ml-4 fontsizedropdown'
                    options={operatorValOption}
                    placeholder="Select"
                    value={operatorVal[filterCol1]}
                    onChange={OnChangeOperatorValue}
                    // styles={customStyles}
                    styles={{
                      ...customStyles,
                      menuList: (provided) => ({
                        ...provided,
                        maxHeight: '150px',
                        overflowY: 'auto',
                      }),
                    }}
                  />
                </div>
                <div className="mt-2">
                  {/* changes here - by dheeraj s */}
                  <input type='text' className='filtertextboxbackground col-md-10 textbox-padding' style={{ "margin-left": "12px" }} onKeyPress={(e) => !/^(\d)*(\.)?([0-9]{1})?$/.test(e.key) && e.preventDefault()}
                    value={filterValue1[filterCol1]} onChange={(event) => filterInput1(event)} />
                </div>
              </div>
              :
              <></>
            }
            {filterCol1 === 'CurrentTier' || filterCol1 === 'CustomerName' || filterCol1 === 'ProductLabel' || filterCol1 == 'CategoryLabel' || filterCol1 == 'SubCategoryLabel' || filterCol1 == 'VehicleType' ?
              <>
                <div className="container">
                  <div className="row">
                    <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                  </div>
                </div>
                <div className="container mt-2">
                  <div className="row">
                    <input type='textbox' placeholder={t('Search')} className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValue1[filterCol1]} onChange={(event) => filterInput1(event)}></input>
                  </div>
                </div>
              </>
              :
              <></>
            }
            {filterCol1 === 'Category1' || filterCol1 == 'CategoryName' ?

              <>
                <div className="container">
                  <div className="row">
                    <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                  </div>
                </div>
                <div className="container mt-2">
                  <div className="row">
                    <input type='textbox' placeholder='Search...' className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValueDict[filterCol]} name={filterCol} onChange={(event) => filterInput(event)}></input>
                  </div>
                </div>
              </>
              :
              <></>
            }

            <div style={{ paddingTop: '10px', paddingLeft: '47px', paddingBottom: '7px' }}>
              <button className="btn btn-sm btn-update" onClick={() => filterHandlerItems('Apply')} style={{ width: "45px" }}>{t('Apply')}</button>
              <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "45px" }} onClick={() => filterHandlerItems('Reset')}>{t('Reset')}</button>
            </div>
            <div>
            </div>
          </div>
          :
          <></>
      }


      {
        showAddColumnPopup === true ?
          <div className='pop-up' style={{ left: divleft, top: divtop, width: "250px" }}>
            <div className="container">
              <div className="row">
                <Select
                  className='col-md-10 textbox-padding fontsizedropdown'
                  options={colVisibilityOption}
                  placeholder="Select"
                  value={colvisiOptionVal}
                  onChange={OnChangeColVisibilityList}
                  styles={customStyles}
                />
                <button id="createColumnListPopup" icon className="btn btn-update ml-1 pa-0" type="submit"
                  style={{ float: "left", cursor: "pointer", padding: '0px', height: '28px', width: '26px' }}
                  onClick={CreateColumnListPopup} title='Add New View'><i class="fa fa-plus" aria-hidden="true"></i></button>
              </div>
              <div className="row mb-2" style={{ textAlign: 'right' }}>
                <div className="col-md-10 mt-1 mr-1" style={{ padding: '0px', fontSize: '12px' }}>
                  <input id="existingViewVal" data-index="1" name="filter-option-all" checked={existingViewVal} type="checkbox"
                    onChange={(event) => OnChangeexistingViewVal(event)} /> <b>Is Default</b>
                </div>
              </div>
              <div className="row">
                <table className='ml-2' style={{ fontSize: '0.80rem' }}>
                  {
                    addRemoveCol.map((item, index) => (
                      <tr>
                        <td key={index}><input id={item.label} data-index="1" name="filter-option-all" checked={item.value} type="checkbox"
                          onChange={(event) => OnchangeAddRemoveCol(event, item)}
                        /> {t(item.label)}</td>
                      </tr>
                    ))
                  }
                </table>
              </div>
              <div className="row mr-2" style={{ float: "right" }}>
                <div style={{ paddingTop: '10px', paddingLeft: '15px', paddingBottom: '7px' }}>
                  <button className="btn btn-sm btn-update" style={{ width: "45px" }} onClick={() => updateColumnVisibilityList('reset')}>{t('Reset')}</button>
                  <button className="btn btn-sm btn-update ml-1" style={{ width: "45px" }} onClick={closeAddRemoveColumnPopup}>{t('Close')}</button>
                  <button className="btn btn-sm btn-update ml-1" style={{ width: "50px", background: '#4755ab', color: 'white' }} onClick={() => updateColumnVisibilityList('apply')} title='Update View'>{t('Update')}</button>
                  <button className="btn btn-sm btn-update ml-1" style={{ width: "50px", background: 'rgb(233 43 0)', color: 'white' }} onClick={deleteColumnVisibilityView} title='Delete View'>{t('Delete')}</button>
                </div>
              </div>
            </div>
          </div>
          : <></>
      }


      {
        showCreateColumnListPopup === true ?
          <div className='pop-up' style={{ left: divleft1, top: divtop1, width: "190px" }}>
            <div className="container">
              <div className="row">
                <div className="col-12 ml-2" style={{ padding: '0px' }}>
                  <input type='textbox' placeholder='View Name' style={{ height: '30px' }}
                    value={colViewName} name={colViewName} onChange={(event) => OnChangeSetViewName(event)}></input>
                </div>
              </div>
              <div className="row mb-2" style={{ textAlign: 'right' }}>
                <div className="col-10 mt-2" style={{ padding: '0px', fontSize: '12px' }}>
                  <input id="defaultView" data-index="1" name="filter-option-all" checked={defaultView} type="checkbox"
                    onChange={(event) => OnChangedefaultView(event)} /> <b>Set Default</b>
                </div>
              </div>
              <div className="row">
                <table className='ml-2' style={{ fontSize: '0.80rem' }}>
                  {
                    createColumnListOption.map((item, index) => (
                      <tr>
                        <td key={index}><input id={item.label} data-index="1" name="filter-option-all" checked={item.value} type="checkbox"
                          onChange={(event) => OnchangeCreateColumnListOption(event, item)}
                        /> {t(item.label)}</td>
                      </tr>
                    ))
                  }
                </table>
              </div>
              <div className="row mr-2" style={{ float: "right" }}>
                <div style={{ paddingTop: '10px', paddingLeft: '35px', paddingBottom: '7px' }}>
                  <button className="btn btn-sm btn-update ml-1" style={{ width: "50px" }} onClick={CloseCreateColumnListPopup}>{t('Cancel')}</button>
                  <button className="btn btn-sm btn-update ml-1" style={{ width: "40px", background: '#4755ab', color: 'white' }} onClick={() => CreateColumnVisibilityList('apply')}>{t('Save')}</button>
                </div>
              </div>
            </div>


          </div>
          : <></>
      }
    </div >

  )

}

export default CreateAccuralComponent