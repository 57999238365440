import { Link, useLocation, useNavigate } from "react-router-dom"
import React, { useState, useEffect } from 'react'
import classes from '../../RebateProgramComponent/CreateRebateComponent/Form.module.css'
import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal } from 'react-bootstrap';
import Service from '../../../Services/Service';
import 'react-multiple-select-dropdown-lite/dist/index.css'
import CustomErrorPopup from '../../../UI/CustomErrorPopup';
import Multiselect from 'multiselect-react-dropdown';
import $ from 'jquery';
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment'
import SessionManage from '../../../SessionManagement/SessionManage';
import Spinner from 'react-bootstrap/Spinner'
import DatePicker from "react-datepicker";
import ProductTreeDialog from '../../RebateProgramComponent/CreateRebateComponent/ProductTreeDialog';
import Validation from '../../../Services/Validation';
import { NumericFormat } from "react-number-format";



function NewDistributorGroupMappingPolicy(props) {
  const navigate = useNavigate();

  let loggedInUser = sessionStorage.getItem("id")
  const location = useLocation();
  const { RecordStatus, isLockedRecord } = location.state || {};
  const { pathname } = location;
  const isEdit = pathname.includes("edit");
  const isOpen = pathname.includes("open");
  const [t, i18n] = useTranslation('translation');
  const [userPerms, setuserPermission] = useState([]);
  const [changeHandle, setChangeHandle] = useState(false);
  const [showAccruals, setshowAccruals] = useState(false);
  const [showtargetselection, setShowTargetSelection] = useState('');
  const [showExceptionTypeListselection, setshowExceptionTypeListselection] = useState('');
  const [subdivideList, setsubdivideList] = useState({ 'Period': "", 'Operator': "", Value: "" })
  const [fyThresholdList, setfyThresholdList] = useState({ 'ThresholdValue': "", 'PayoutValue': "", Operator: "" })
  const [showtargettable, setShowTargetTable] = useState(true);
  const [subdivideTargetTypeList, setSubdivideTargetTypeList] = useState([]);
  const [subdivideTargetid, setSubdivideTargetid] = useState();
  const [ExceptionTypeid, setExceptionTypeid] = useState();
  const [addCategory, setaddCategory] = useState(false);
  const [CategoryValues, setCategoryValues] = useState([]);
  const [SubCategoryValues, setSubCategoryValues] = useState([]);
  const [ProductValues, setProductValues] = useState([]);
  const [CustomerValues, setCustomerValues] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [CustomerGroupValues, setCustomerGroupValues] = useState([]);
  const [attribute1Values, setAttribute1Values] = useState([]);
  const [attribute2Values, setAttribute2Values] = useState([]);
  const [attribute3Values, setAttribute3Values] = useState([]);
  const [attribute4Values, setAttribute4Values] = useState([]);
  const [attribute5Values, setAttribute5Values] = useState([]);
  const [UpdatedCategoryValues, setUpdatedCategoryValues] = useState([]);
  const [showWorkflowAlert, setShowWorkflowAlert] = useState(false)

  const [attribute1ValuesHold, setAttribute1ValuesHold] = useState([]);
  const [attribute2ValuesHold, setAttribute2ValuesHold] = useState([]);
  const [attribute3ValuesHold, setAttribute3ValuesHold] = useState([]);
  const [attribute4ValuesHold, setAttribute4ValuesHold] = useState([]);
  const [attribute5ValuesHold, setAttribute5ValuesHold] = useState([]);
  const [selectedOptionIW, setSelectedOptionIW] = useState(null);
  const [includeworkglowoptions,setIncludeworkglowoptions] = useState([
    {
      label: "Yes",
      value: true,
    },
    {
      label: "No",
      value: false,
    },
  ])

  const [selectedOptionsRebateType, setSelectedOptionsRebateType] = useState('');
  const [selectedOptionsRebateMeasure, setSelectedOptionsRebateMeasure] = useState('');
  const [rebateTypeValue, setRebateTypeValue] = useState('')
  const [measureValueLabel, setmeasureValueLabel] = useState("");
  const [selectedOptionsPayout, setSelectedOptionsPayout] = useState();
  const [rebateMeasureData, setRebateMeasureData] = useState([]);
  const [rebatePayoutCondData, setRebatePayoutCondData] = useState([]);
  const [rebateTypeData, setRebateTypeData] = useState([]);
  const [targetedPayCond, setTargetPayCond] = useState(false);
  const [rebatePayoutCond, setRebatePayoutCond] = useState('')
  const [rebateIcon, setRebateIcon] = useState('');
  const [grouplist, setGroupList] = useState([]);
  const [selectedOptionsGroupList, setselectedOptionsGroupList] = useState();
  const [settlFreqValues, setSettlFreqValues] = useState([]);
  const [rebateTypValues, setRebateTypeValues] = useState([]);
  const [SCDropDownValues, setSCDropDownValues] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedValues, setSelectedValues] = useState("");
  const [showProductPopup, setShowProductPopup] = useState("");
  const [showCategoryPopup, setshowCategoryPopup] = useState("");
  const [showCustomerPopup, setshowCustomerPopup] = useState("");
  const [showCustomerGroupPopup, setshowCustomerGroupPopup] = useState("");
  const [showSetlFreqPopup, setshowSetlFreqPopup] = useState("");
  const [showRebateTypePopup, setshowRebateTypePopup] = useState("");
  const [sidebarwidth, setsidebarwidth] = useState('');
  const [clickProduct, setclickProduct] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [dualListBoxOptionsByHeirarchy, setdualListBoxOptionsByHeirarchy] = useState([])
  const [dualListBoxOptionsByAttribute, setdualListBoxOptionsByAttribute] = useState([])
  const [PrevSelectedTypeState, setPrevSelectedTypeState] = useState('Heirarchy');
  const [selectedType, setSelectedType] = useState('Attribute');
  const [rebatelinestatus, setrebatelinestatus] = useState('New');
  const [FilteredProducts, SetFilteredProducts] = useState([]);
  const [attbCheckAll, setattbCheckAll] = useState(false);
  const [keyValue, SetKeyValue] = useState("");
  const [itemNo, setItemNo] = useState(0);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState("Error");
  const [datagroupCust, setDataGroupCust] = useState([]);
  const [commentsForSubmit, setCommentsForSubmit] = useState("")
  const [showSubmitModal, setShowSubmitModal] = useState(false)
  const [loading, setLoading] = useState(false);
  const [selectedOptionsSetlFreq, setSelectedOptionsSetlFreq] = useState("");
  const [disableField,setDisableField] = useState(false)
  //let disableField = false;
  let catValues = [];
  const [exceptionValues, setExceptionValues] = useState([])
  const [missedTargetWarning, setMissedTargetWarning] = useState(false);
  const [missedTargetWarningMessage, setMissedTargetWarningMessage] = useState('');
  const [showExceptionWorkflow, setshowExceptionWorkflow] = useState('');
  const [showWorkflow, setshowWorkflow] = useState('');
  const [workflowchart, setworkflowchart] = useState([]);
  const [showspinnerworkflow, setshowspinnerworkflow] = useState('');
  const [workflowData, setWorkflowData] = useState([]);
  const [woData, setWoData] = useState('');
  const [totalAccrualMoney, setTotalAccrualMoney] = useState(0);
  const [showspinneraccural, setshowspinneraccural] = useState('');
  const [activepolicyProgramLine, setActivePolicyProgramLine] = useState([])
  const [expandAccurals, setExpandAccruals] = useState('')
  const [icon, setIcon] = useState('fa fa-plus');
  const [loadedFrom, setLoadedFrom] = useState('');
  const [checkedValue, setCheckedValue] = useState([])
  const [state, setState] = useState({})
  const [workflowApprovers, setWorkflowApprovers] = useState([])
  const [workflowLevelCount, setWorkflowLevelCount] = useState('');
  let wData = [0]
  const [rebateprogrmlinedetail, setrebateProgramLinedetail] = useState({ id: 0 })
  var currentDate = new Date();
  var year = currentDate.getFullYear();
  var month = currentDate.getMonth();
  var day = currentDate.getDate();
  const [defaultDate, setDefaultDate] = useState({ from_date: new Date(), to_date: (new Date(year + 1, month, day)) })
  const [saveProgramLine, setSaveProgramLine] = useState(false);
  const [showSubCategoryPopup, setShowSubCategoryPopup] = useState(false);
  const [SubCategoryList, setSubCategoryList] = useState([]);
  const [SubCategoryIndex, setSubCategoryIndex] = useState(false);
  const [CategoryPopup, setCategoryPopup] = useState(false);
  const [CategoryList, setCategoryList] = useState([]);
  const [CategoryIndex, setCategoryIndex] = useState(false);
  const [AllCategoryValues, setAllCategoryValues] = useState([])
  const [AllSubCategoryValues, setAllSubcategoryValues] = useState([])
  const [regioncheckAll, setRegioncheckAll] = useState(false);
  const [zoneOptions, setZoneOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [stationOptions, setStationOptions] = useState([]);
  const [stockistOptions, setStockistOptions] = useState([]);
  const [showZonePopup, setShowZonePopup] = useState(false);
  const [showStatePopup, setShowStatePopup] = useState(false);
  const [comments, setComments] = useState('');
  const [showStationPopup, setShowStationPopup] = useState(false);
  const [showStockistPopup, setShowStockistPopup] = useState(false);
  const [showSelectedZonepopup, setShowSelectedZonepopup] = useState(false);
  const [showSelectedStatepopup, setShowSelectedStatepopup] = useState(false);
  const [showSelectedStationpopup, setShowSelectedStationpopup] = useState(false);
  const [showSelectedStockistpopup, setShowSelectedStockistpopup] = useState(false);
  const [SalesLevelData, setSalesLevelData] = useState([])
  const [regionDataSelected, setRegionDataSelected] = useState([]);
  const [group, setGroup] = useState('')

  const [customerDataFilter, setCustomerDataFilter] = useState([]);
  const [customerSearch, setCustomerSearch] = useState('');
  const [data, setData] = useState([]);
  const [filtertextCust, setfiltertextCust] = useState('');
  const [custFilterCol, setCustomerFilterCol] = useState('');
  const [showCustomerfilterpopup, setshowCustomerfilterpopup] = useState(false);
  const [divleft, setdivleft] = useState('');
  const [divtop, setdivtop] = useState('');
  const [filterCusDropDownData, setFilterCusDropDownData] = useState([]);
  const [filterValueCust, setFilterValueCust] = useState([]);
  const [filterValue, setFilterValue] = useState([]);
  const [customercheckAll, setCustomercheckAll] = useState(false);
  const [totalselectedshow, settotalselectedshow] = useState('');
  const [customerChanged, setCustomerChanged] = useState(false);
  const disabledClass = `  ${Service.editPerms(userPerms) == false ? "disabledClass" : ""}`;
  const [filterSearchCust, setFilterSearchCust] = useState("");
  const [filtercustData, setfilterCustData] = useState([]);
  const [customerGroupSearch, setCustomerGroupSearch] = useState('');
  const [showCustomerGroupfilterpopup, setshowCustomerGroupfilterpopup] = useState(false);
  const [custGroupFilterCol, setCustomerGroupFilterCol] = useState('');
  const [filterSearchCustGroup, setFilterSearchCustGroup] = useState("");
  const [filtertextCustGroup, setfiltertextCustGroup] = useState('');
  const [currentCustGroupFilterKey, setcurrentCustGroupFilterKey] = useState('');
  const [filtercustGroupData, setfilterCustGroupData] = useState([]);
  const [filterValueCustGroup, setFilterValueCustGroup] = useState([]);
  const [columnAscending, setColumnAscending] = useState(true);
  const [sortColName, setSortColName] = useState('Id');
  const [searchValue, setSearchValue] = useState("")
  const [filterSearch, setFilterSearch] = useState("");
  const [settlFreqcheckAll, setsettlFreqcheckAll] = useState(false);
  const [RebateTypecheckAll, setRebateTypecheckAll] = useState(false);
  const [filterCustGroupDropDownData, setFilterCustGroupDropDownData] = useState([]);
  const [customerGroupDataFilter, setCustomerGroupDataFilter] = useState([]);
  const [showSelectedCustomerpopup, setShowSelectedCustomerpopup] = useState(false);
  const [showSelectedCustomerGrouppopup, setShowSelectedCustomerGrouppopup] = useState(false);
  const [showSelectedProductpopup, setShowSelectedProductpopup] = useState(false);
  const [showSelectedCategorypopup, setShowSelectedCategorypopup] = useState(false);
  const [showSelectedRebateType, setShowSelectedRebateType] = useState(false);
  const [showSelectedSettlFreq, setShowSelectedSettlFreq] = useState(false);
  const [countKeyValue, SetCountKeyValue] = useState("");
  const [SettlFreqDataSelected, setSettlFreqDataSelected] = useState([])
  const [RebateTypeDataSelected, setRebateTypeDataSelected] = useState([])
  const [customerDataSelected, setCustomerDataSelected] = useState([]);
  const [customerGroupDataSelected, setCustomerGroupDataSelected] = useState([]);
  const [showApprovedModal, setShowApprovedModal] = useState(false)
  const [commentsForApproved, setCommentsForApproved] = useState("")
  const [showDeniedModal, setShowDeniedModal] = useState(false)
  const [commentsForDenied, setCommentsForDenied] = useState("")
  const [showAddButton, setShowAddButton] = useState(false)
  const [inputBoolean, setInputBoolean] = useState(true)
  const [isLocked, setIsLocked] = useState(false);
  const [lockedBy, setLockedBy] = useState('');
  const [modifiedLst, setModifiedLst] = useState([])
  const [paging, setpaging] = useState({
    pageSize: 20,
    pageNumber: 1
  })
  let [exceptionPolicyData, setExceptionPolicyData] = useState({
    Id: 0,
    DistributorId: "",
    DistributorName: "",
    FiscalYear: "",
    LastFYSales: 0,
    LastFYTarget: 0,
    Achievement: 0,
    LastModifiedUserId: parseInt(loggedInUser),
    LastModifiedDate: moment(new Date()).format(SessionManage.getcurrentdateformat()),
    DistributorGroup: "",
    Comments: "",
    OldDistributorGroup: "",
    NewDistributorGroup: "",
    IncludeWorkflow: true,
  });
  const _ = require('lodash');
  const clickCloseAccuralWorkflow = () => {
    setshowExceptionWorkflow(false);
  }

  {
    useEffect(() => {
      setLoadedFrom(props.name);

      // Conditionally set the permission URL based on the value of props.name
      const permissionUrl = props.name === "fromApproval"
        ? "/DistributorGroupMappingPolicylist/approvals/pending"
        : "/DistributorGroupMapping-Policy/List";

      // Call the function with the conditional URL
      getUserPermission(permissionUrl);

     
             if (props.id !== '0') {
                   getDistributorGroupMappingPolicyData(props.name,props.id)
                // getExceptionPolicyById("DistributorGroupMappingPolicy/GetById", props.id)
             }
             else {
                 setLockedBy(loggedInUser);
                 setIsLocked(true);
                 //GetExceptionCategorySubCategoryValues("TargetSettingPolicy/GetDropdownValues");
             }
         }, [props.id])

  }

  const customStyles = {
    control: base => ({
      ...base,
      minHeight: 26,
    }),
    dropdownIndicator: base => ({
      ...base,
      padding: 2
    }),
    clearIndicator: base => ({
      ...base,
      padding: 2
    }),
    multiValue: base => ({
      ...base,

    }),
    valueContainer: base => ({
      ...base,
      padding: '0px 2px'
    }),
    input: base => ({
      ...base,
      margin: 0,
      padding: 0
    }),
  };

  const clickShowWorkflow = (val, id) => {
    setsidebarwidth("70vw");
    if (val === "Workflow")
      setshowExceptionWorkflow(true);
    setshowWorkflow(false);
  }

  const getDistributorGroupMappingPolicyData = (data, id) => {
    console.log(RecordStatus,isLockedRecord,"19-2",isEdit,isOpen)
    if (data === 'fromPolicy') {
      ////debugger;
      if(RecordStatus === 'Revising'){
        getExceptionPolicyById("DistributorGroupMappingPolicy/GetById", id, false, loggedInUser)
        setInputBoolean(true)
      }else if(isLockedRecord){
        getExceptionPolicyById("DistributorGroupMappingPolicy/GetById", id, false, loggedInUser)
        setInputBoolean(true)
      }else if(RecordStatus === 'Approved' && isEdit){
        getExceptionPolicyById("DistributorGroupMappingPolicy/GetById", id, true, loggedInUser)
        navigate(`/DistributorGroupMappingPolicyList/edit/${id}/fromPolicy`, { 
          state: { RecordStatus:'Revising', isLockedRecord: isLockedRecord } 
        });
        setInputBoolean(true)
      }else if(RecordStatus === 'Approved' && isOpen){
        setDisableField(true)
        getExceptionPolicyById("DistributorGroupMappingPolicy/GetById", id, false, loggedInUser)
        setInputBoolean(true)    
      }else if(RecordStatus === 'InReview'){
        setDisableField(true)
        getExceptionPolicyById("DistributorGroupMappingPolicy/GetById", id, false, loggedInUser)
        setInputBoolean(true)   
      }
      
    } else {
      getExceptionPolicyById("DistributorGroupMappingPolicy/GetById", id, false, loggedInUser)
      setInputBoolean(false)
    }
  }

  const getUserPermission = (pathname) => {
    Service.GetUserPermission(pathname).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null' && res.data !== '') {
          if (res.data.DataList !== undefined) {
            console.log(res.data.DataList, "peram")
            setuserPermission(res.data.DataList)
          }
        }
      }
    });
  }
  const [fiscalYear1,setFiscalyear] = useState('')
  const getExceptionPolicyById = (method, id, policyStatus, loggedInUser) => {
    console.log(method, "method");
    setInputBoolean(policyStatus)
    console.log(policyStatus, "19-2 setInputBoolean")
    let apiData = []
    Service.getDistributorGroupMappingPolicybyId(method, id, policyStatus, parseInt(loggedInUser)).then((res) => {
      ////debugger;
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (typeof res.data !== 'undefined' && res.data !== 'null') {
              let rData = res.data[0];
              console.log("14-2", res.data[0])
              setExceptionPolicyData({
                Id: rData.Id,
                DistributorId: rData.Distributorid,
                DistributorName: rData.DistributorLabel,
                PolicyId:rData.PolicyId,
                //CreatedUserId: rData.CreatedUserId,
                FiscalYear: rData.Fiscalyear,
                LastFYSales: rData.Lastfysales,
                LastFYTarget: rData.Lastfytarget,
                Achievement: rData.Achievementpercent,
                StatusLabel: rData.Status,
                LastModifiedUserId: rData.LastModifiedUserId,
                LastModifiedUser: rData.LockedByUserName,
                LastModifiedDate: moment(rData.LastModifiedDate).format(SessionManage.getcurrentdateformat()),
                DistributorGroup: rData.DistributorGroup,
                Comments: rData.Comments,
                OldDistributorGroup: rData.Olddistributorgroup,
                NewDistributorGroup: rData.NewDistributorGroup,
                IncludeWorkflow : rData.IncludeWorkflow,
              })

              const matchedOption = includeworkglowoptions.find(
                (option) => option.value === rData.IncludeWorkflow
              );
              setSelectedOptionIW(matchedOption || null);

              setRebatePayoutCond(rData.RebatePayoutCond)
              setFiscalyear(rData.Fiscalyear)

              //debugger;
              GetGroupList('DistributorGroupMappingPolicy/GetDistributorGroups', rData.Fiscalyear);
              if(!disableField){
                setDisableField((rData.Locked == true && rData.LockedById.toString() !== loggedInUser))
              }

              //setSelectedOptionsSetlFreq(rData.SettlementFrequencyId);
              if ((rData.Locked == true && rData.LockedById.toString() !== loggedInUser)) {
                $("#libread").html(rData.PolicyId + " " + `Locked By ${rData.LockedByUserName}`);
              }
              else {
                $("#libread").html(rData.PolicyId);
              }

               setselectedOptionsGroupList({ ...selectedOptionsGroupList, ['label']: rData.Newdistributorgroup, ['value']: rData.Newdistributorgroup });

              console.log(rData.DistributorGroup, "DG1");
              setGroup(rData.Olddistributorgroup)
              //setSelectedOptionsPayout(rData.RebatePayoutCondId);
              //setSelectedOptionsRebateType(rData.RebateTypeId);
              //setSelectedOptionsRebateMeasure(rData.RebateMeasureId);
              // setRebateIcon(rData.BimonthlypolicyTiervalueView[0].Rebate_Value)
            
                 if (((props.name == 'fromApproval' || props.name == 'fromapprovalcompleted') && rData.Status == 'InReview' && rData.Locked == false) || ((rData.Status == 'New' || rData.Status == 'Revising')&& rData.Locked == false)) {
                 Service.LockUnLockEntity('Configurationprop/LockEntity', "DistributorGroupMappingPolicy", id, loggedInUser).then((res1) => {
                   if (typeof res1 !== 'undefined') {
                     setLockedBy(loggedInUser);
                     setIsLocked(true);
                   }
                 });
               }
               else {
                 setLockedBy(rData.LockedById);
                 setIsLocked(rData.Locked);
               }
              //setDropDownValues(rData.dropDownData)


              setModifiedLst(rData.dropDownData)
              setTimeout(() => {
                getExceptionWorkflowDetails(id, paging.pageSize, paging.pageNumber)
                getExceptionWorkflowApprovers('DistributorGroupMappingPolicy/GetDistributorGroupMappingWorkflowApprovers', id);
              }, 500);
            }
          }
        }
      }
    });
  }


  const showApprovedDialog = (flag) => {
    if (flag === 'showApprovedDialog') {
      setCommentsForApproved("")
      setShowApprovedModal(!showApprovedModal)
    }
    else if (flag === 'proceed') {
      ApproveException()
      setShowApprovedModal(!showApprovedModal)

    }
  }

  const OnChangeIncludeWorkflow = (selected) => {
    console.log(selected,"19-2 n")
    setSelectedOptionIW(selected);
    setExceptionPolicyData((prevData) => ({
      ...prevData,
      IncludeWorkflow: selected.value,
    }));
  }

  const ApproveException = () => {

    let Id = exceptionPolicyData.Id
    let Comments = commentsForApproved
    let UserId = SessionManage.getuserid();//state.createdUserId
    let data = { Id, Comments, UserId }

    console.log("27/5", data)
    Service.createorupdate('DistributorGroupMappingPolicy/ApproveDistributorGroupMappingPolicy', data).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {


              Service.LockUnLockEntity('Configurationprop/UnLockEntity', "DistributorGroupMappingPolicy", Id, loggedInUser).then((res) => {
                if (typeof res !== 'undefined') {
                  if (typeof res.data !== 'undefined' && res.data !== 'null') {

                  }
                }

              });
              toast.success('Approved Successfully !', {
                position: toast.POSITION.TOP_RIGHT,
                onClose: () => navigate("/DistributorGroupMappingPolicyList/approvals/pending")
              });


          }
          else if (res.status !== 200) {
            toast.error('Error in approval !', {
              position: toast.POSITION.TOP_RIGHT,
            });
            setErrorMessage(res.data.ErrorMessage);
            setErrorStatus("Error")
            setShowErrorModal(true)
          }
        }
      }

    });
  }

  const showDeniedDialog = (flag) => {
    if (flag === 'showDeniedDialog') {
      setCommentsForDenied("")
      setShowDeniedModal(!showDeniedModal)
    }
    else if (flag === 'proceed') {
      DenyException()
      setShowDeniedModal(!showDeniedModal)

    }
  }

  const DenyException = () => {

    let Id = exceptionPolicyData.Id
    let Comments = commentsForDenied
    let UserId = SessionManage.getuserid();//state.createdUserId
    let data = { Id, Comments, UserId }

    Service.createorupdate('DistributorGroupMappingPolicy/DenyDistributorGroupMappingPolicy', data).then((res) => {

      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {

            if (res.data !== 'null' && res.data !== 'undefined') {

              Service.LockUnLockEntity('Configurationprop/UnLockEntity', "DistributorGroupMappingPolicy", Id, loggedInUser).then((res) => {
                if (typeof res !== 'undefined') {
                  if (typeof res.data !== 'undefined' && res.data !== 'null') {

                  }
                }

              });
              toast.success('Denied Successfully', {
                position: toast.POSITION.TOP_RIGHT,
                onClose: () => navigate("/DistributorGroupMappingPolicyList/approvals/pending")
              });

            }

          }
          else if (res.status !== 200) {
            toast.error('Error in deny !', {
              position: toast.POSITION.TOP_RIGHT,
            });
            setErrorMessage(res.data.ErrorMessage);
            setErrorStatus("Error")
            setShowErrorModal(true)
          }
        }
      }

    });
  }


  const GetGroupList = (method, fiscalYear) => {
    debugger;
    Service.getGroupData(method, fiscalYear) // passing fiscalYear in request object
      .then((res) => {
        if (typeof res !== 'undefined' && res.data && res.data.DataList) {
          console.log("11-10", res);

          // Map the response to the format { value, label } for react-select
          const formattedGroupList = res.data.DataList.map((item) => ({
            value: item.DistributorGrp,
            label: item.DistributorGrp
          }));

          setGroupList(formattedGroupList);
          console.log("Formatted Group List", formattedGroupList);
        }
      });
  };


  const getExceptionWorkflowDetails = (id, pagesize, pagenumber) => {
    Service.getdatabyId('DistributorGroupMappingPolicy/GetDistributorGroupMappingWorkflowById', id, pagesize, pagenumber).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data.DataList !== 'null' && res.data.DataList !== 'undefined') {

              wData = res.data.DataList;
              setWorkflowData(wData);
              setshowspinnerworkflow(false)
              setWoData('get data');
            }
          }
        }
      }
    });

  }

  const getExceptionWorkflowApprovers = (method, id) => {
    Service.getdatabyId(method, id).then((res) => {

      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          setWorkflowApprovers(res.data)
          setWorkflowLevelCount(res.data.length)
          const sortdata = _.orderBy(res.data, ['Workflowlevel'], ['asc'])
          setworkflowchart(sortdata);

        }

      }

    });
  }

  const workflowAlert = (e, flag) => {
    let isValid = validateField()
    if (isValid) {
      if (flag == 'open') {
        if (workflowApprovers.length > 0 && exceptionPolicyData.IncludeWorkflow === true) {
          setShowWorkflowAlert(true)
        }
        else {
          showSubmitDialog(e, flag)
        }
      }
      else if (flag == 'close') {
        setShowWorkflowAlert(false)
        showSubmitDialog(e, 'showSubmitDialog')
      }
    } else {
      setErrorStatus("Error")
      setShowErrorModal(true);
      return false;
    }
  }

  const OnChangeGroupList = (selectedOption) => {
    setselectedOptionsGroupList(selectedOption);
     setExceptionPolicyData({ ...exceptionPolicyData, ['NewDistributorGroup']: selectedOption.label });
    console.log(selectedOption, "DG2");
  };


  const showSubmitDialog = (e, flag) => {
    if (flag === 'showSubmitDialog') {
      setShowSubmitModal(!showSubmitModal)
    }
    else if (flag === 'open') {
      setShowSubmitModal(false)
      SubmitHandler()
    }
  }

  const validateField = () => {
    let isValid = true;
    if (!selectedOptionsGroupList || selectedOptionsGroupList === null || selectedOptionsGroupList.value === null) {
      setErrorMessage("Please Select Group");
      isValid = false;
    } 
    else if (selectedOptionsGroupList.value === group) {
      setErrorMessage("Group Selected is the same as previous");
      isValid = false;
    }
    return isValid;
  };

   console.log('23/10',exceptionPolicyData)
  console.log('23/10',selectedOptionsGroupList?.label,selectedOptionsGroupList)

  const SaveHandler = (e, action) => {
    //19-3
    console.log(exceptionPolicyData,"19-3")
    let isValid = validateField()
    if(isValid){
      let inputData = []
      inputData = {
        Id: exceptionPolicyData.Id, 
        Distributorid: exceptionPolicyData.Distributorid, 
        DistributorLabel: "exceptionPolicyData.DistributorName", 
        PolicyId: exceptionPolicyData.PolicyId, 
        PolicyName:"",
        Fiscalyear: fiscalYear1, 
        Lastfysales: exceptionPolicyData.Lastfysales, 
        Lastfytarget: exceptionPolicyData.Lastfytarget, 
        Achievementpercent: exceptionPolicyData.Achievementpercent, 
        //Status: exceptionPolicyData.Status, 
        LastModifiedUserId: parseInt(loggedInUser), 
        LastModifiedUser: exceptionPolicyData.LockedByUserName, 
        //LastModifiedDate: moment(exceptionPolicyData.LastModifiedDate).format(SessionManage.getcurrentdateformat()), 
        Distributorgroup: exceptionPolicyData.NewDistributorGroup === undefined ? "" : exceptionPolicyData.NewDistributorGroup, 
        Comments: exceptionPolicyData.Comments === null ? "":exceptionPolicyData.Comments, 
        Olddistributorgroup: exceptionPolicyData.NewDistributorGroup === undefined ? "" : exceptionPolicyData.NewDistributorGroup, 
        Newdistributorgroup: exceptionPolicyData.NewDistributorGroup === undefined ? "" : exceptionPolicyData.NewDistributorGroup,
        IncludeWorkflow:exceptionPolicyData.IncludeWorkflow,
    };
      console.log(inputData,"19-3 inputData")

      Service.createorupdate('DistributorGroupMappingPolicy/CreateOrUpdate', inputData).then((res) => {
        if (typeof res !== 'undefined') {
          if (res.data !== 'null' && typeof res.data !== 'undefined') {
            let rowData = res.data
            let id = parseInt(res.data)
            if (action != "Submit") {
              toast.success('Saved Successfully !', {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
            if (action == 'SaveClose') {
              Service.LockUnLockEntity('Configurationprop/UnLockEntity', "DistributorGroupMappingPolicy", id, loggedInUser).then((res) => {
              });
              navigate("/DistributorGroupMapping-Policy/List");
            }
            else {
              navigate("/DistributorGroupMappingPolicyList/edit/" + id + "/fromPolicy");
              getExceptionPolicyById("DistributorGroupMappingPolicy/GetById", id, false, loggedInUser)
            }
          }
        }
      });
    }else {
      setErrorStatus("Error")
      setShowErrorModal(true);
      return false;
    }

  }
  const SubmitHandler = (e) => {

    let isValid = validateField()

    if (isValid == true) {

      setLoading(true);
      debugger;
      Service.SubmitDistributorMappingPolicy("DistributorGroupMappingPolicy/SubmitDistributorGroupMapping", props.id, loggedInUser, commentsForSubmit,   exceptionPolicyData.Comments,exceptionPolicyData.NewDistributorGroup === undefined ? " " : exceptionPolicyData.NewDistributorGroup,exceptionPolicyData.IncludeWorkflow).then((res) => {
        if (typeof res !== 'undefined') {
          if (typeof res.data !== 'undefined' && res.data !== 'null') {
            let rData = res.data;
            if (res.data.Code == 200) {
              if (res.data !== 'null' && res.data !== 'undefined') {
                if (res.data.Status != 'Failure') {

                  let id = parseInt(res.data.Id)
                  setCommentsForSubmit('')
                  setShowSubmitModal(!showSubmitModal)
                  toast.success('Submitted Successfully !', {
                    position: toast.POSITION.TOP_RIGHT,
                    // onClose: () => navigate("/AnnualPolicylist")
                  });
                  setTimeout(() => {
                    CloseHandler('close');
                  }, 200)
                  //getExceptionPolicyById("DistributorGroupMappingPolicy/GetById", res.data.Id, inputBoolean, loggedInUser)
                }
                else {
                  toast.error('Error in submission !', {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                  setLoading(false)
                  setErrorMessage(res.data.ErrorMessage);
                  setErrorStatus("Error")
                  setShowErrorModal(true)
                  setShowSubmitModal(!showSubmitModal)
                  //getExceptionPolicyById("DistributorGroupMappingPolicy/GetById", res.data.Id, inputBoolean, loggedInUser)
                }
              }
            }
          }
        }
      });
    } else {
      setErrorStatus("Error")
      setShowErrorModal(true);
      return false;
    }
  }

  const inputsHandler = (e) => {
    setComments(e.target.value);
    setExceptionPolicyData({ ...exceptionPolicyData, ['Comments']: e.target.value });
  }

  const CloseHandler = (e) => {
    Service.LockUnLockEntity('Configurationprop/UnLockEntity', "DistributorGroupMappingPolicy", exceptionPolicyData.Id, loggedInUser).then((res) => {

    });
    if (loadedFrom === 'fromApproval') {
      navigate("/DistributorGroupMappingPolicyList/approvals/pending");
    }
    else if (loadedFrom === 'fromCompletedWorkflow') {
      navigate("/DistributorGroupMappingPolicyList/approvals/archive");
    }
    else if (loadedFrom == "fromPolicy") {
      navigate("/DistributorGroupMapping-Policy/List")
    }

  }

  const dateFormat = SessionManage.getcurrentdateformat();
  const timeFormat = "hh:mm A";


  return (
    <div className="container-fluid content-top-gap">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb my-breadcrumb">
            <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
            {/* <li className="breadcrumb-item active" aria-current="page"><Link to="/policies">{t('Menu.Policies')}</Link></li> */}
            <li className="breadcrumb-item active" aria-current="page"><Link to={props.name === "fromApproval" ? "/AnnualPolicylist/approvals/pending" : props.name === "fromCompletedWorkflow" ? "/AnnualPolicylist/approvals/archive" : "/DistributorGroupMapping-Policy/List"}>{props.name === "fromApproval" ? t('DistributorPolicy.PendingPolicyName') : props.name === "fromCompletedWorkflow" ? t('DistributorPolicy.CompletedPolicyName') : t('DistributorPolicy.PolicyName')}</Link></li>
            <li className="breadcrumb-item active" id="libread" aria-current="page">{props.name === "fromPolicy" ? t('DistributorPolicy.CreateNewDistributorGroupMappingPolicy') : exceptionPolicyData.AnnualPolicyId}</li>
          </ol>
        </nav>
        <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
          onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
        {loading ? <><div className="loader-container"><div className="spinner"></div></div> </> : ''}
        <ToastContainer autoClose={1000} />
        <div className="row" id="row">
            <div className="col-lg-12">
                <div className="card text-center card_border">
                    <div className="card-header chart-grid__header align-items-center mt-1" style={{ height: "35px" }}>
                        <div className="row">
                            <div className="col-md-6 text-left">
                               {t('DistributorPolicy.DistributorGroupMappingPolicyHeader')}
                            </div>
                            <div className="col-md-6">
                            {Service.editPerms(userPerms) == false ? "" : disableField === false ?
                            <>
                                 <button className="btn  btn-update btn-sm ml-1" style={{ width: "40px", float: "right" }} type="submit" onClick={(e) => SaveHandler(e, 'Save')} >{t('Save')}</button>
                                 <button className="btn  btn-update btn-sm ml-1" style={{ width: "90px", float: "right" }} type="submit" onClick={(e) => SaveHandler(e, 'SaveClose')}>{t('Save & Close')}</button>
                                <button className="btn  btn-update btn-sm ml-1" style={{ width: "50px", float: "right" }} type="submit" onClick={(event) => workflowAlert(event, 'open')}>{t('Submit')}</button>
                            </>
                            : ''}
                                <button className="btn  btn-update btn-sm ml-1" style={{ width: "50px", float: "right" }} type="submit" onClick={CloseHandler}>{t('Cancel')}</button>
                                <button className="btn  btn-update btn-sm ml-1" style={{ width: "65px", float: "right" }} type="submit" onClick={() => { clickShowWorkflow("Workflow", exceptionPolicyData.Id) }} >{t('Workflow')}</button>   
                                {console.log('21/10',Service.editPerms(userPerms))}
                                {console.log('21/10',loadedFrom)}
                                {console.log('21/10',exceptionPolicyData.StatusLabel)}
                                {console.log('21/10',isLocked)}
                                {console.log('21/10',lockedBy?.toString())}
                                {console.log('21/10',loggedInUser)}
                                {Service.editPerms(userPerms) == false ? "" :
                      (loadedFrom == 'fromApproval' && exceptionPolicyData.StatusLabel == 'InReview') ?
                        (isLocked == true && lockedBy.toString() == loggedInUser) ?
                          <>
                            <button type="button" style={{ float: "right", width: "60px" }} className="btn btn-sm btn-update ml-1" onClick={() => showApprovedDialog('showApprovedDialog')} >{t('Approve')}</button>
                            <button type="button" style={{ float: "right", width: "40px" }} className="btn btn-sm btn-update ml-1" onClick={() => showDeniedDialog('showDeniedDialog')} >{t('Deny')}</button>
                          </>
                          : ""
                        : <></>}                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="row col-lg-14 pr-lg-2 mt-1">
            <div className="col-sm-4">
                <div className="row">
                    <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border">
                            <div className="card-body" style={{ minHeight: "200px" }} >
                                <div className="container">
                                    <div className="row">
                                        <label className="col-md-6 my-3 text-left l_width">{t('DistributorPolicy.DistributorId')}</label>
                                        <input disabled={true} name="DistributorGroupMappingPolicyId" type="text" className="col-md-6 my-3 textbox-padding form-control m_width" value={exceptionPolicyData.DistributorId} />

                                        <label className="col-md-6 my-3 text-left l_width">{t('DistributorPolicy.DistributorName')}</label>
                                        <input disabled={true} name="DistributorGroupMappingPolicyName" type="text" className="col-md-6 my-3 textbox-padding form-control m_width" value={exceptionPolicyData.DistributorName} />

                                        <label className="col-md-6 text-left my-3 l_width">{t('Accruals.fiscalYear')}</label>
                                        <input disabled={true} name="DistributorGroupMappingPolicyFiscalyear" type="text" className="col-md-6 my-3 textbox-padding form-control m_width" value={exceptionPolicyData.FiscalYear} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-4">
             <div className="row">
              <div className="col-lg-12 pr-lg-2">
                <div className="card text-center card_border">
                  <div className="card-body" style={{ minHeight: "200px" }} >
                    <div className="container">
                      <div className="row">
                         <label className="col-md-6 my-3 text-left l_width">{t('DistributorPolicy.Lastfysales')}</label>
                         <input disabled={true} name="DistributorGroupMappingPolicyLastFYSales" type="text" className="col-md-6 my-3 textbox-padding form-control m_width" value={exceptionPolicyData.LastFYSales} />
                         <label className="col-md-6 my-3 text-left l_width">{t('DistributorPolicy.Lastfytarget')}</label>
                         <input disabled={true} name="DistributorGroupMappingPolicyLastFYTarget" type="text" className="col-md-6 my-3 textbox-padding form-control m_width" value={exceptionPolicyData.LastFYTarget} />
                         <label className="col-md-6 my-3 text-left l_width">{t('DistributorPolicy.Achievementpercent')}</label>
                         <input disabled={true} name="DistributorGroupMappingPolicyachievement" type="text" className="col-md-6 my-3 textbox-padding form-control m_width" value={exceptionPolicyData.Achievement} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
             </div>
            </div>
            <div className="col-sm-4">
             <div className="row">
              <div className="col-lg-12 pr-lg-2">
                <div className="card text-center card_border">
                  <div className="card-body" style={{ maxHeight: "200px" }} >
                    <div className="container">
                      <div className="row">
                         <label className="col-md-6 my-3 text-left l_width">{t('ExceptionPolicy.Status')}</label>
                         <input disabled={true} name="Status" type="text" className="col-md-6 my-3 textbox-padding form-control m_width" value={exceptionPolicyData.StatusLabel} />
                         <label className="col-md-6 my-3 text-left l_width">{t('LastModifiedBy')}</label>
                         <input disabled={true} name="LastModifiedBy" type="text" className="col-md-6 my-3 textbox-padding form-control m_width" value={exceptionPolicyData.LastModifiedUser} />
                         <label className="col-md-6 my-3 text-left l_width">{t('LastModifiedDate')}</label>
                         <input disabled={true} name="LastModifiedDate" type="text" className="col-md-6 my-3 textbox-padding form-control m_width" value={exceptionPolicyData.LastModifiedDate} />
                         <label className="col-md-6 text-left mt-2 mb-2 l_width">{t('Include Workflow')}<span className={classes.mandatory}>*</span></label>
                         <Select
                          styles={customStyles}
                          className={`col-md-6 mb-2 mt-2 textbox-padding-dropdown fontsizedropdown  textbox-padding m_width ${disabledClass}`}
                          options={includeworkglowoptions}
                          placeholder="Select"
                          isSearchable={true}
                          value={selectedOptionIW}
                          onChange={(selected) => {
                                OnChangeIncludeWorkflow(selected);
                          }}
                          isDisabled={disableField}
                          />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
             </div>
            </div>
        </div>
        <div className="col-lg-14 mt-3">
            <div className="card text-center card_border">
                <div className="card-header chart-grid__header">
                    <div className="row">
                      <div className="col-md-8 text-left">
                        {t('DistributorPolicy.DistributorGroupMappingPolicyDefinition')}
                      </div>
                    </div>
                </div>
                <div>
                <div className="container mt-2">
                   <div className="row mb-2">
                        <label className="col-md-2 text-left l_width">{t('DistributorPolicy.OldDistributorGroup')}</label>
                        <input disabled={true} name="OldDistributorGroup" type="text" className="col-md-3 textbox-padding form-control m_width" value={exceptionPolicyData.OldDistributorGroup}/>
                   </div>

              <div className="row mb-2">
                <label className="col-md-2 text-left l_width">{t('DistributorPolicy.NewDistributorGroup')}</label>
                <Select
                  styles={customStyles}
                  className={`col-md-3 mb-2 mt-2 textbox-padding-dropdown fontsizedropdown textbox-padding m_width ${disabledClass}`}
                  options={grouplist}
                  placeholder="Select"
                  isSearchable={true}
                  value={grouplist.find(option => option.label === selectedOptionsGroupList?.label)}
                  onChange={OnChangeGroupList}
                  isDisabled={disableField}
                />

              </div>

              <div className="row mb-3">
                <label className="col-md-2 text-left l_width">{t('DistributorPolicy.Comments')}</label>
                <textarea style={{ marginRight: "0.938rem" }} className="col-md-7 form-control mt-2"
                  onChange={inputsHandler} name="Comments" rows="4" value={exceptionPolicyData.Comments} disabled={disableField}>
                </textarea>
              </div>
            </div>

                </div>
            </div>
        </div>
        {
          showExceptionWorkflow === true ?
            <div id="mySidepanel" className="sidepanel sidepanelborder" style={{ width: sidebarwidth }}>
              {showWorkflow === false ?
                <div className="container" id="workflow">
                  <div className="row mt-3">
                    <div className="col-lg-12 pr-lg-2">
                      <div className="card text-center card_border">
                        <div className="card-header chart-grid__header">
                          <div className="row">
                            <div className="col-md-11 text-left">
                              {t('Workflow')}
                            </div>
                            <div className="ml-4">
                              <button className="btn btn-outline-dark  btn-sm" onClick={clickCloseAccuralWorkflow} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                            </div>
                          </div>
                        </div>

                        <div className="card-body" style={{ minHeight: "55vh" }}>


                          {(workflowchart.length > 0 && exceptionPolicyData.IncludeWorkflow) ?
                            <div className='col-md-12'>
                              <table className="table table-responsive-sm">
                                <thead>
                                  <tr>
                                    <th className='workflowchart-levelwidth workflowchart-font'>Level</th>
                                    <th className='font-center workflowchart-font'>Users</th>
                                    <th className='font-center workflowchart-font'>Approval</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {
                                    workflowchart.map((i) =>
                                      <tr key={i.Id}>
                                        <td className='workflowchart-font square-levels'>{i.Levels}</td>
                                        <td className='font-center'>
                                          <ul >
                                            {
                                              i.lstworkflowapprover.map((j, index) => {
                                                return <>

                                                  {
                                                    index < 2 ?
                                                      j.IsApproved === true ?
                                                        <li className='workflowchartperson'>
                                                          <span className='workflowtpersoncolor'><i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                                            <br></br> {j.FullName}</span>
                                                        </li>
                                                        :
                                                        <>
                                                          <li className='workflowchartperson'>
                                                            <i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                                            <br></br> {j.FullName}
                                                          </li>
                                                        </>
                                                      :
                                                      ''
                                                  }
                                                </>

                                              })
                                            }

                                            {
                                              i.lstworkflowapprover.length > 2 ?
                                                <li className='workflowchartperson tooltip_workflow'>
                                                  <i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                                  <br></br> {t('MoreUsers')}
                                                  <span class="tooltiptext">

                                                    {
                                                      i.lstworkflowapprover.map((k, index) => {
                                                        return <span>
                                                          {
                                                            index > 1 ? <><i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                                              &nbsp; {k.FullName}
                                                              <hr></hr></> : null
                                                          }


                                                        </span>
                                                      })
                                                    }

                                                  </span>
                                                </li>
                                                :
                                                ''
                                            }


                                          </ul>

                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                          {
                                            i.IsApproved === true ?
                                              <span className='workflowrightcolor'>
                                                <i class="fa fa-check" aria-hidden="true"></i>
                                              </span>

                                              : i.IsDenied == true ?
                                                <span className='workflowrightcolor'>
                                                  <i class="fa fa-times" aria-hidden="true" style={{ color: "red" }}></i>
                                                </span>
                                                : ''
                                          }
                                        </td>
                                      </tr>)

                                  }
                                </tbody>

                              </table>

                            </div>
                            :
                            <div className={classes.flow}>

                            </div>
                          }


                          <div className="row">
                            <div className="col-md-12">
                              <table className="table table-bordered text-left mt-2 table-responsive-sm">
                                <thead className='text-white' style={{ backgroundColor: "var(--primary)" }}>
                                  <tr>

                                    <th>{t('Rebates.Event')}</th>
                                    <th>{t('Rebates.CreatedDate')}</th>
                                    <th>{t('Rebates.User')}</th>
                                    <th>{t('Rebates.Comments')}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    showspinnerworkflow === true ?

                                      <>
                                        <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                                        <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                                        <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                                      </>
                                      :
                                      workflowData.length === 0 ?
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td>
                                            {t('NoDataAvailable')}
                                          </td> <td></td></tr>
                                        :
                                        woData === '' || woData === 'undefined' ?
                                          <tr></tr> :
                                          workflowData.map((i) =>
                                            <tr key={i.id}>
                                              <td>{i.EventName}</td>
                                              {i.CreatedDate === null ? <td></td> : <td>{`${moment(i.CreatedDate).format(dateFormat)} ${moment(i.CreatedDate).format(timeFormat)}`}</td>}
                                              <td>{i.CreateUserName}</td>
                                              <td>{i.Comments}</td>
                                            </tr>)

                                  }

                                </tbody>
                              </table>
                            </div>

                          </div>


                        </div>

                      </div>
                    </div>

                  </div>
                </div>
                :
                ''
              }

            </div>
            :
            ""
        }
        <Modal show={showApprovedModal} size="bg"
          centered>
          <Modal.Header>
            <Modal.Title>{t('Rebates.ApprovalComments')}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }} >
            <Form.Control className={classes['Textarea-width']} as="textarea" rows={2} value={commentsForApproved} name="commentsForApproved"
              onChange={(e) => { setCommentsForApproved(e.target.value) }} />
          </Modal.Body>
          <Modal.Footer>
            <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} onClick={() => showApprovedDialog('showApprovedDialog')}>
              {t('Cancel')}
            </Button>
            <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} onClick={() => showApprovedDialog('proceed')}>
              {t('Submit')}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showWorkflowAlert}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <Modal.Body style={{ "textAlign": 'center' }}>{t('Accruals.WorkflowAlert')}</Modal.Body>
          <Modal.Footer>
            <Button size='sm' style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "30px" }} onClick={(e) => workflowAlert(e, 'close')}>
              {t('Ok')}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showDeniedModal} size="bg"
          centered>
          <Modal.Header>
            <Modal.Title>{t('Rebates.DeniedComments')}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }} >
            <Form.Control className={classes['Textarea-width']} as="textarea" rows={2} value={commentsForDenied} name="commentsForDenied"
              onChange={(e) => { setCommentsForDenied(e.target.value) }} />
          </Modal.Body>
          <Modal.Footer>
            <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} onClick={() => showDeniedDialog('showDeniedDialog')}>
              {t('Cancel')}
            </Button>
            <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} onClick={() => showDeniedDialog('proceed')}>
              {t('Submit')}
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showSubmitModal} size="bg"
          centered>
          <Modal.Header>
            <Modal.Title>{t('Rebates.SubmitComments')}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ "textAlign": 'center' }} >
            <Form.Control className={classes['Textarea-width']} as="textarea" rows={2} value={commentsForSubmit} name="commentsForSubmit"
              onChange={(e) => { setCommentsForSubmit(e.target.value) }} />
          </Modal.Body>
          <Modal.Footer>
            <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} onClick={(event) => showSubmitDialog(event, 'showSubmitDialog')}>
              {t('Cancel')}
            </Button>
            <Button size='sm' style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} onClick={(event) => showSubmitDialog(event, 'open')}>
              {t('Submit')}
            </Button>
          </Modal.Footer>
        </Modal>
    </div>
  )
}

export default NewDistributorGroupMappingPolicy;