import React, { useEffect, useState, forwardRef, useRef } from 'react';
import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal } from 'react-bootstrap';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import DatePicker from "react-datepicker";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { Link, useLocation } from "react-router-dom"
import Service from '../../../src/Services/Service';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
//import CustomErrorPopup from '../../../UI/CustomErrorPopup';
//import Multiselect from 'multiselect-react-dropdown';
import SessionManage from '../../../src/SessionManagement/SessionManage';
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";


function AdminListView(props) {
    let rowData = [];
    let subrowData = [];
    const [listViewData, setListViewData] = useState(rowData)
    const [t, i18n] = useTranslation('translation');
    const [icon, setIcon] = useState('fa fa-minus');
    const [openMenuIds, setOpenManuIds] = useState({});
    const [subMenuOpen, setSubMenuOpen] = useState(-1);
    const toggleMenu = (x) => setSubMenuOpen(subMenuOpen === x ? -1 : x);
    var roleid = SessionManage.getroleid();
    const [searchValue, setSearchValue] = useState(null)

    {
        useEffect(() => {
            getPolicyListViewData("Permission/GetLinkList", null,roleid);
        }, [0])
    }

    const getPolicyListViewData = (method,search,roleid) => {
        console.log('26/12')
        console.log("3/6", search)
 
        Service.getdatabyIdsearch(method, 'Admin', search,roleid).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            //setTimeout(() => {
                                debugger;
                            rowData = res.data.DataList;
                            setListViewData(rowData)
                            console.log("3/4", rowData)
                            var ids_arry = {}
                            rowData.map((listViewData, index) =>
                                ids_arry[index] = 1
                            )
                            setOpenManuIds(ids_arry)
                            //}, 180);
                        }
                    }
                }
            }

        })
    }
    const changeIcon = (e, id) => {
        // window.alert('hi')
        toggleMenu(id)
        var ids = openMenuIds;
        for (let key in ids) {
            if (id == key) {
                ids[key] = ids[key] == 1 ? -1 : 1
            }
        }
        setOpenManuIds(ids)

        // console.log('3/6', subMenuOpen)
        // if (icon == 'fa fa-plus') {
        //     setIcon('fa fa-minus');
        // }
        // if (icon == 'fa fa-minus') {
        //     setIcon('fa fa-plus');
        // }
        // console.log('e/1',e)

    }

    const setIconbyId = (presentSymbol) => {
    }

    const searchHandler = (event) => {

        if (event.target.value != '') {
            // filterHandler('Reset');
            setSearchValue(String(event.target.value).toLowerCase())
            getPolicyListViewData("Permission/GetLinkList", event.target.value,roleid);
            console.log("3/6", 'Hi')
        }
        else {

            setSearchValue(null);
            getPolicyListViewData("Permission/GetLinkList", null,roleid);
            console.log("3/6", 'Buy')
        }
        console.log("3/6", searchValue)
    }

    return (
        <div className="container-fluid content-top-gap">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb my-breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page"><Link to="/admin">{t('Menu.Admin')}</Link></li>
                </ol>
            </nav>
            <div className="row mt-1">
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-lg-12 pr-lg-2">
                            <div className="card text-center card_border">
                                <div className="card-body">
                                    <div className="col-md-4">
                                        <input type="text" className="form-control form-control-user"
                                            onChange={(event) => searchHandler(event)} id="firstName" autoComplete='off' name="firstName" placeholder={`${t('SearchFor')}`}
                                        />
                                    </div>
                                    <br></br>

                                    {
                                        listViewData.map((listViewData, index) =>
                                            <tr>{listViewData.Name !== 'Partner Profile' ? 
                                                <td>

                                                    {listViewData.SubMenuList.length != 0 ?
                                                        <i className={openMenuIds[index] == 1 ? "fa fa-minus" : "fa fa-plus"} id={index} aria-controls="demo" aria-hidden="true" onClick={(event) => changeIcon(event, (index))} style={{}}></i>
                                                        : <></>
                                                    } <Link to={listViewData.Url}>
                                                        <font size="2">
                                                            <td>{(listViewData.Name)}</td>
                                                        </font>
                                                        <br></br>
                                                    </Link>
                                                    {openMenuIds[index] == 1 ?
                                                        listViewData.SubMenuList.map((SubMenuList, index) =>
                                                            <ul>
                                                                <Link to={SubMenuList.Url}>
                                                                    <font size="2">
                                                                        <td>{(SubMenuList.Name)}</td>
                                                                    </font>
                                                                    <br></br>
                                                                </Link>
                                                            </ul>
                                                        )
                                                        : <></>
                                                    }
                                                </td>
                                                :
                                                <></>
                                                }
                                            </tr>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AdminListView