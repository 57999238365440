import React, { useEffect, useState, useRef, forwardRef } from 'react';
import { CSVLink } from "react-csv";
import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal } from 'react-bootstrap';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import DatePicker from "react-datepicker";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { Link, useLocation } from "react-router-dom"
import Service from '../../../Services/Service';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import CustomErrorPopup from '../../../UI/CustomErrorPopup';
import Multiselect from 'multiselect-react-dropdown';
import SessionManage from '../../../SessionManagement/SessionManage';
import { useTranslation } from "react-i18next";
import * as XLSX from 'xlsx';


function ReportsList(props) {
    const csvInstance = useRef();
    const [csvList, setCsvList] = useState([]);
    const [t, i18n] = useTranslation('translation');
    const [sortColName, setSortColName] = useState("");
    const [sticky, setSticky] = useState("");

    const location = useLocation();
    const [userPerms, setuserPermission] = useState([]);
    const [reportdata, setreportdata] = useState([]);
    const navigate = useNavigate();
    let rowData = [];
    const [showInvoiceModal, setShowInvoiceModal] = useState(false);
    const [showspinner, setshowspinner] = useState('');
    const [listViewData, setListViewData] = useState(rowData)
    const [filteredArray, setFilteredArray] = useState(rowData)
    const [customerData, setCustomerData] = useState([]);
    const [groupCustomerData, setGroupCustomerData] = useState([]);
    const [fiscalYearData, setFiscalYearData] = useState([]);
    const [usersData, setUsersData] = useState([]);
    const [rolesData, setRolesData] = useState([]);
    const [filtertext, setfiltertext] = useState('');
    const [permissionsData, setPermissionsData] = useState([]);
    const [modulesData, setModulesData] = useState([]);
    const [filterSearch, setFilterSearch] = useState("");
    const [loadData, setLoadData] = useState("");
    const [invoicePeriodValue, setInvoicePeriodValue] = useState([
        { "id": 0, "Name": "Q1", "label": "Q1" },
        { "id": 1, "Name": "Q2", "label": "Q2" },
        { "id": 2, "Name": "Q3", "label": "Q3" },
        { "id": 3, "Name": "Q4", "label": "Q4" },
        { "id": 4, "Name": "H1", "label": "H1" },
        { "id": 5, "Name": "H2", "label": "H2" },
        { "id": 6, "Name": "FY", "label": "FY" },
    ]);
    const [customerInvoiceValue, setCustomerInvoiceValue] = useState([]);
    const [reportsData, setReportsData] = useState({
        "rebate_period": "",
        "fiscalYear": "",
        "customergroupname": [],
        "customer_Name": [],
        "report_type": "",
        "user_name": [],
        "roles": [],
        "permissions": [],
        "modules": [],
    });
    const [accrualPeriodValue, setAccrualPeriodValue] = useState([]);
    const [accrualCustomerValue, setAccrualCustomerValue] = useState([]);
    const [searchValue, setSearchValue] = useState(null)
    const [checkedValue, setCheckedValue] = useState([]);
    const [checkedValueid, setCheckedValueid] = useState([]);
    const [checkedValueStatus, setCheckedValueStatus] = useState([]);
    const [errorStatus, setErrorStatus] = useState("Error");
    const [errorMessage, setErrorMessage] = useState("");
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showbutton, setshowbutton] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [showfilterpopup, setshowfilterpopup] = useState(false);
    const [columnAscending, setColumnAscending] = useState(true);
    const [filterCol, setFilterCol] = useState('');
    const [dropdownData, setDropdownData] = useState([]);
    const [totalselectedshow, settotalselectedshow] = useState('');
    const [totalselectedcount, settotalselectedcount] = useState(0);
    const [totalCountlst, settotalCountlst] = useState('');
    const [ToggleStatusModal, setToggleStatusModal] = useState(false);
    const [toggleChange, setToggleChange] = useState("");
    const [toggleId, setToggleId] = useState();
    const [loading, setLoading] = useState(false);
    const [filePath, setFilePath] = useState('');
    const [filterValueDict, setFilterValueDict] = useState({
        name: '',
        label: '',
        last_modified_date: null
    });
    const [multiFilterValue, setMultiFilterValue] = useState({
        last_modified_user: ''
    });
    const [paging, setpaging] = useState({
        pageSize: 20,
        pageNumber: 1
    })
    let showSuccessMsg = false;
    let showErrorMsg = false;
    let showDetails = '';
    const [lastTapTime, setLastTapTime] = useState(0);
    const delay = 300;
    let loggedInUser = SessionManage.getuserid();

    const [userReportData, setuserReportData] = useState({
        "Users": [],
        "Roles": [],
        "Permissions": [],
        "Modules": []
    });


    {
        useEffect(() => {
            getUserPermission(location.pathname);
            setshowspinner(true);
            setColumnAscending(false);
            setSortColName("Id");
            getReportsListViewData("Reports", paging.pageSize, paging.pageNumber, "Descending", "Id", [], null);
            getCustomerData("Reports/GetActiveCustomers")
            getCustomerGroupData("Reports/GetActiveGroupCustomers")
            getFiscalYear("Reports")
            getUserData("Users/GetAll", paging.pageSize, paging.pageNumber, "Descending", "Id", [], null);
            getPermissionData("Permission/GetAllByPage", paging.pageSize, paging.pageNumber, "Descending", "Id", [], null);
            getRoleData("Roles/GetAll", paging.pageSize, paging.pageNumber, "Descending", "Id", [], null);

            Service.fetchModuleData()
                .then(data => {
                    if (data != null && data.length > 0) {
                        const NameOptions = data
                            .filter(item => item != null && item.Name != '')
                            .map(item => ({
                                value: item.Name, // Assuming Name is unique
                                label: item.Name
                            }));
                        setModulesData(NameOptions);
                        // Check the permissionsData state here
                        console.log("Modules Data:", data);
                    }
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });



            window.addEventListener("scroll", isSticky);
            return () => {
                window.removeEventListener("scroll", isSticky);
            };
        }, [props.id])
    }
    const isSticky = () => {
        const scrollTop = window.scrollY;
        const stickyClass = scrollTop >= 60 ? "is-sticky-thead" : "";
        setSticky(stickyClass);
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop(divtop1.offsetTop + scrollTopActual)
    };
    const class_thead = `text-white rt-thead  ${sticky}`;

    const filterLst = () => {
        let list = [];
        for (var key in multiFilterValue) {
            if (multiFilterValue[key].length > 0) {
                let values = ""
                multiFilterValue[key].map((i) => {
                    values += i.Name + ";";
                })
                if (values !== null && values !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: values
                    })
                }
            }
        }
        for (var key in filterValueDict) {
            if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                list.push({
                    filterKey: key,
                    FilterValue: filterValueDict[key]
                })
            }
        }
        return list;
    }

    const ExporttoExcel = () => {
        setShowInvoiceModal(false)
        setLoading(true);
        let data = reportsData

        if (loadData !== 'User Entitlement') {
            if (reportsData.report_type === "Report By Customer") {
                if (reportsData.customer_Name.length === 0) {

                    let data = []
                    for (let i = 0; i < customerData.length; i++) {

                        data.push(customerData[i].Name)
                    }

                    reportsData.customer_Name = data;

                }
            }
            else if (reportsData.report_type === "Report By Customer Group") {
                if (reportsData.customergroupname.length === 0) {
                    let data = []
                    for (let i = 0; i < groupCustomerData.length; i++) {

                        data.push(groupCustomerData[i].Name)
                    }

                    reportsData.customergroupname = data;

                }
            }
            else if (reportsData.report_type === "Customer Invoice Payment Details Report" || reportsData.report_type === "Report By Customer/CustomerGroup") {
                if (reportsData.customer_Name.length === 0) {
                    let data1 = []
                    let data2 = []
                    for (let i = 0; i < customerData.length; i++) {

                        data1.push(customerData[i].Name)
                    }
                    for (let i = 0; i < groupCustomerData.length; i++) {

                        data2.push(groupCustomerData[i].Name)
                    }

                    reportsData.customer_Name = data1;
                    reportsData.customergroupname = data2;
                }
            }
            Service.getAllReportData("Reports", data).then(res => {

                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {

                        debugger
                        var path = res.data.Path
                        setLoading(false);

                        window.location = Service.getBaseUrl() + path;
                        setFilePath(path);
                        getReportsListViewData("Reports", paging.pageSize, paging.pageNumber, "Descending", "Id", [], null);

                        setReportsData({ ...reportsData, ['rebate_period']: "", ['customergroupname']: [], ['customer_Name']: [], ['report_type']: "", ['fiscalYear']: "" })
                    }
                }
            });
        }
        else {
            Service.getAllUserReportData("Reports", userReportData).then(res => {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        console.log(res.data)
                        debugger
                        var path = res.data
                        setLoading(false);
                        window.location = Service.getBaseUrl() + path;
                        setFilePath(path);
                        getReportsListViewData("Reports", paging.pageSize, paging.pageNumber, "Descending", "Id", [], null);
                    }
                }
            });
        }

    }


    const ExporttoCSV = () => {
        alert("Export logic has to be implemented");

    }

    const regionStyles = {
        chips: {
            fontSize: "12px",
            lineHeight: "13px"
        },

        multiSelectContainer: {
            "&:hover": { background: "red" }
        }

    }
    const CustomerStyles = {

        searchBox: {
            height: "80px",
            overflowY: "scroll"
        },

        chips: {
            fontSize: "12px",
            lineHeight: "13px",
            display: "block",
            marginTop: "5px"
        },

        multiSelectContainer: {
            "&:hover": { background: "red" }
        },

    }

    const activeOption = [
        { Name: "Active", Id: "1" },
        { Name: "In-Active", Id: "0" },

    ]
    let headers = [
        { label: "Name", key: "Name" },
        { label: "Label", key: "label" },
        { label: "Last Modified Date", key: "LastModifiedDate" },
        { label: "Last Modified By", key: "LastModifiedUser" },
        { label: "Is Active", key: "ISActive" },

    ];

    const getCustomerData = (method) => {

        Service.getdata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            setCustomerData(res.data)

                        }
                    }
                }
            }

        });
    }
    const getCustomerGroupData = (method) => {

        Service.getdata(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {

                            setGroupCustomerData(res.data)

                        }
                    }
                }
            }

        });
    }
    const getFiscalYear = (method) => {

        Service.getFiscalYear(method).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {

                            setFiscalYearData(res.data)

                        }
                    }
                }
            }

        });
    }

    const OnPopupPeriodChange = (e) => {
        let selectedOption = (e);

        let data = String(selectedOption[0].Name)

        setReportsData({ ...reportsData, ['rebate_period']: data })
    }
    const OnPopupFiscalYearChange = (e) => {
        let selectedOption = (e);

        let data = String(selectedOption[0].value)

        setReportsData({ ...reportsData, ['fiscalYear']: data })
    }



    const OnPopupCustomerChange = (e) => {
        let selectedOption = (e);
        let data = []
        for (let i = 0; i < selectedOption.length; i++) {

            data.push(selectedOption[i].Name)
        }

        setReportsData({ ...reportsData, ['customer_Name']: data, ['report_type']: 'Report By Customer' })

    }
    const OnPopupGroupCustomerChange = (e) => {
        let selectedOption = (e);
        let data = []
        for (let i = 0; i < selectedOption.length; i++) {

            data.push(selectedOption[i].Name)
        }


        setReportsData({ ...reportsData, ['customergroupname']: data, ['report_type']: 'Report By CustomerGroup' })

    }




    const checkAll = (mycheckbox) => {
        var checkboxes = document.querySelectorAll("input[type = 'checkbox']");
        if (mycheckbox.target.checked == true) {
            settotalselectedshow(true);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = true;
            });
            settotalselectedcount(checkboxes.length - 1)
        }
        else {
            settotalselectedshow(false);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = false;
            });
            settotalselectedcount(0)
        }

    }
    const getUserPermission = (pathname) => {
        Service.GetUserPermission(pathname).then((res) => {

            if (res !== undefined) {

                if (res.data !== 'null' && res.data !== 'undefined' && res.data !== '') {
                    if (res.data.DataList !== undefined) {
                        setuserPermission(res.data.DataList)
                    }
                }
            }
        });
    }
    const searchHandler = (event) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;
        setpaging(paging);
        if (event.target.value != '') {

            setSearchValue(String(event.target.value).toLowerCase())
            getReportsListViewData("Reports", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), event.target.value);
        }
        else {
            setSearchValue(null);
            getReportsListViewData("Reports", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), null);
        }
    }

    const handleLoadMore = () => {

        let pagenumber = paging.pageNumber + 1;
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';

        Service.getAllReportsdataSearch("Reports", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), searchValue).then((res) => {
            if (typeof res !== 'undefined') {

                if (res.data !== 'null' && typeof res.data !== 'undefined') {

                    const currentlength = data.length;
                    setdata(data => [...data, ...res.data.DataList])
                    setFilteredArray(filteredArray => [...filteredArray, ...res.data.DataList])
                    LoadMore(currentlength + res.data.DataList.length, res.data.Totalcount)
                }
            }
        });

        paging.pageNumber = pagenumber
        setpaging(paging);
    };
    const [data, setdata] = useState([]);

    const [showLoadMore, setshowLoadMore] = useState('');
    const LoadMore = (lst, totalcount) => {

        if (lst < totalcount) {
            setshowLoadMore(true);
        }
        else if (lst >= totalcount) {
            setshowLoadMore(false);
        } else {
            setshowLoadMore(false);
        }

    }
    const handleChange = (e, id, name, status) => {
        let isChecked = e.target.checked;   // true if it is checked false if unchecked

        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        if (isChecked === true) {

            setCheckedValue(id)  // check box value - id
            setCheckedValueStatus(status) // check box value - Active status
            setCheckedValueid(name) //check box value - RA-0000
            settotalselectedshow(true);
            settotalselectedcount(checkedBoxes.length);
        }
        else {

            setCheckedValue([])  // check box value - id 
            setCheckedValueStatus([]) // check box value - Active status
            setCheckedValueid([]) //check box value - RA-0000
            if (checkedBoxes.length === 0) {
                settotalselectedshow(false);
            }
            settotalselectedcount(checkedBoxes.length);
        }


        if (checkedBoxes.length === 1) {
            setshowbutton(true);
        }
        else {
            setshowbutton(false);
        }

    }

    const OpenHandler = (id) => {

        if (id == null || id == 0) {
            alert('Please select a Policy to view')
        }
        else {

        }
    }

    const getReportsListViewData = (method, pageSize, pageNumber, sortType, SortProperty, filterValue, search) => {

        Service.getAllReportsdataSearch(method, pageSize, pageNumber, sortType, SortProperty, filterValue, search).then((res) => {
            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            rowData = res.data;
                            setListViewData(rowData)
                            settotalCountlst(res.data.Totalcount)
                            setFilteredArray(rowData)
                            console.log(rowData, "filtered-array");
                            setshowspinner(false)
                            setdata(rowData)
                            LoadMore(rowData.length, res.data.Totalcount)
                        }
                    }
                }
            }

        })
    }






    const DropdownHandler = (key) => {
        Service.getRebatePolicyfilterDropdown('Reports/GetReportsFilterValue', key).then((res) => {
            if (res && res.status === 200) {
                if (typeof res !== 'undefined') {
                    if (typeof res.data !== 'undefined' && res.data !== 'null') {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            setDropdownData(res.data)
                        }
                    }
                }

            }
        });
    }

    const [divleft, setdivleft] = useState('');
    const [divtop, setdivtop] = useState('');
    const FilterColumn = (name) => {

        const scrollTop = window.scrollY;
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop(divtop1.offsetTop + scrollTopActual + 30)

        setshowfilterpopup(true)
        setFilterCol(name)

        if (name === 'last_modified_user') {
            var testDiv = document.getElementById("last_modified_user");
            setdivleft(testDiv.offsetLeft + 20 + "px");
            DropdownHandler(name)
        }
        if (name === 'name') {
            setfiltertext('Name')
            var testDiv = document.getElementById("name");
            setdivleft(testDiv.offsetLeft + 335 + "px");
        }
        if (name === 'label') {
            setfiltertext('Primary Module')
            var testDiv = document.getElementById("label");
            setdivleft(testDiv.offsetLeft + 106 + "px");
        }
        if (name === 'last_modified_date') {
            var testDiv = document.getElementById("last_modified_date");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'status') {
            var testDiv = document.getElementById("status");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'last_run') {
            setfiltertext('Last Run')
            var testDiv = document.getElementById("last_run");
            setdivleft(testDiv.offsetLeft + 70 + "px");
        }
    }

    const OpenPolicy = (event, name, flag) => {

        setReportsData({ ...reportsData, ['report_type']: name });

        if (flag == "Open") {
            setLoadData(name)
            setShowInvoiceModal(true)
        }
        if (flag == "Close") {
            setShowInvoiceModal(false)
            setReportsData({
                ...reportsData, ['rebate_period']: "", ['customergroupname']: [], ['customer_Name']: [], ['fiscalYear']: "",
                ['user_name']: [], ['roles']: [], ['permissions']: [], ['modules']: []
            })
        }
    }
    const SortColumn = (colName) => {


        var sortType = '';
        if (columnAscending === true) {
            setColumnAscending(false);
            sortType = "Descending";
        } else {
            setColumnAscending(true);
            sortType = "Ascending";
        }
        setSortColName(colName);

        let pagenumber = 1;

        paging.pageNumber = pagenumber;
        setpaging(paging);
        getReportsListViewData("Reports", paging.pageSize, paging.pageNumber, sortType, colName, filterLst(), searchValue);
    }


    const filterInput = (e) => {

        let inputValue = e.target.value
        setFilterValueDict(() => ({
            ...filterValueDict, [filterCol]: e.target.value,
        }));
    }

    const OndropdownChange = (e) => {

        let selectedOption = (e);

        let data = []

        for (let i = 0; i < selectedOption.length; i++) {

            const getallData = {
                Name: selectedOption[i].Name,
            }
            data.push(getallData)
        }

        setMultiFilterValue(() => ({
            ...multiFilterValue, [filterCol]: data,
        }));
    }
    const filterHandler = (flag) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;

        if (flag === 'Apply') {
            setshowfilterpopup(false)
            let list = [];

            for (var key in multiFilterValue) {
                if (multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }

            if (list.length > 0) {
                setpaging(paging);
                getReportsListViewData("Reports", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);
            }

        }
        else {
            setshowfilterpopup(false)
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: '',
            }));
            setFilterValueDict(() => ({
                ...filterValueDict, [filterCol]: '',
            }));

            let list = [];
            for (var key in multiFilterValue) {
                if (key !== filterCol && multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '' && key !== filterCol) {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
            setpaging(paging);
            getReportsListViewData("Reports", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);

            setFilterCol('')
            setDropdownData([])
        }
    }



    const customMultiStyles = {
        multiselectContainer: {
            maxWidth: '188px',
            marginLeft: '3px'
        },
        chips: {
            borderRadius: '5px'
        }
    };
    const OnPopupInvoicePaymentChange = (e) => {

        let selectedOption = (e);
        let data = []
        for (let i = 0; i < selectedOption.length; i++) {

            data.push(selectedOption[i].Name)
        }

        setReportsData({ ...reportsData, ['customer_Name']: data, ['report_type']: loadData })

    }
    const handleTouchEnd = (event) => {
        const currentTime = new Date().getTime();
        const timeDiff = currentTime - lastTapTime;
        if (timeDiff < delay) {
            // Double tap detected
            // Your double click logic here
            // Prevent the default action, if needed
            event.preventDefault();
        }
        setLastTapTime(currentTime);
    };


    const getUserData = (method, pageSize, pageNumber, sortType, SortProperty, filterValue, search) => {
        Service.getDatawithMultiFilterSearch(method, pageSize, pageNumber, sortType, SortProperty, filterValue, search)
            .then((res) => {
                if (res.data != null) {
                    const fullNameOptions = res.data.DataList.map(item => ({
                        value: item.FullName, // Assuming FullName is unique
                        label: item.FullName
                    }));
                    setUsersData(fullNameOptions);
                    // settotalCountlst(res.data.Totalcount)
                    // LoadMore(res.data.DataList.length, res.data.Totalcount)
                    // setshowspinner(false)

                    // Check the usersData state here
                    console.log("Users Data:", res.data.DataList);
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                // Handle the error state here if necessary
            });
    }




    const OnPopupUserChange = (e, name) => {
        let selectedOption = (e);
        console.log("7/5", selectedOption);
        let updatedUserReportData = { ...userReportData };
        updatedUserReportData[name] = selectedOption.map(option => option.label);
        console.log("7/5", updatedUserReportData);
        setuserReportData(updatedUserReportData);
    }

    const OnPopupUserRemoveChange = (e, name) => {
        let selectedOption = (e);
        console.log("7/5", selectedOption);
        let updatedUserReportData = { ...userReportData };
        updatedUserReportData[name] = updatedUserReportData[name].filter(item => item !== selectedOption.label);
        console.log("7/5", updatedUserReportData);
        setuserReportData(updatedUserReportData);
    }

    const getRoleData = (method, pageSize, pageNumber, sortType, SortProperty, filterValue, search) => {
        Service.getDatawithMultiFilterSearch(method, pageSize, pageNumber, sortType, SortProperty, filterValue, search)
            .then((res) => {
                if (res.data != null) {
                    const NameOptions = res.data.DataList.map(item => ({
                        value: item.Name, // Assuming Name is unique
                        label: item.Name
                    }));
                    setRolesData(NameOptions);
                    // settotalCountlst(res.data.Totalcount)
                    // LoadMore(res.data.DataList.length, res.data.Totalcount)
                    // setshowspinner(false)

                    // Check the rolesData state here
                    console.log("Roles Data:", res.data.DataList);
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                // Handle the error state here if necessary
            });
    }





    const getPermissionData = (method, pageSize, pageNumber, sortType, SortProperty, filterValue, search) => {
        Service.getDatawithMultiFilterSearch(method, pageSize, pageNumber, sortType, SortProperty, filterValue, search)
            .then((res) => {
                if (res.data != null) {
                    const LabelOptions = res.data.DataList.map(item => ({
                        value: item.Name, // Assuming Label is unique
                        label: item.Name
                    }));
                    setPermissionsData(LabelOptions);
                    // settotalCountlst(res.data.Totalcount)
                    // LoadMore(res.data.DataList.length, res.data.Totalcount)
                    // setshowspinner(false)

                    // Check the permissionsData state here
                    console.log("Permissions Data:", res.data.DataList);
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                // Handle the error state here if necessary
            });
    }







    const OnPopupModuleChange = (e) => {

        let selectedOption = (e);
        let data = []
        for (let i = 0; i < selectedOption.length; i++) {

            data.push(selectedOption[i].Name)
        }

        setReportsData({ ...reportsData, ['modules']: data, ['report_type']: "User Entitlement" })

    }

    const OpenCreatePage = () => {
        navigate('/reports/byCustomer');
    }

    return (
        <div className="container-fluid content-top-gap " onTouchEnd={handleTouchEnd}>

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb my-breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page"><Link to="/reports">{t('Reports.ReportsList')}</Link></li>
                </ol>
            </nav>
            {loading ? <>
                <div className="loader-container">
                    <div className="spinner"></div>
                </div>
            </> : ""}
            <div style={{ display: "none" }}>
                <CSVLink data={csvList} headers={headers} ref={csvInstance}   > </CSVLink>
            </div>
            <div className="">
                <div className="row">
                    <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border">

                            <div className="card-body" style={{ minHeight: "70vh" }}>
                                <div className="row">


                                    <div className="col-md-5">
                                        {totalselectedcount <= 0 ? "" : Service.exportPerms(userPerms) == false ? "" : <button className="btn btn-update  btn-sm ml-2" type="submit" style={{ float: "right", width: "50px" }} onClick={(event) => ExporttoCSV()}>{t('Export')}</button>}

                                        {showbutton === true ?
                                            <>
                                                <a className="btn btn-update  btn-sm ml-2" type="submit" style={{ width: "40px", backgroundColor: "buttonface", float: "right" }} onClick={() => OpenHandler(checkedValue)} >{t('Open')}</a>



                                            </>
                                            :
                                            ''
                                        } </div>
                                </div>


                                <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableA" >
                                    <thead className={class_thead} style={{ backgroundColor: "var(--primary)" }}>
                                        <tr>

                                            <th id="name" style={{ backgroundColor: (filterValueDict.name ? '#6cae55' : '') }}>
                                                <div className='d-flex text-nowrap align-items-center'>
                                                {t('Reports.Name')} <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('name')}></span>
                                                <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('name')}></span>
                                                </div>
                                            </th>
                                            <th id="label" style={{ backgroundColor: (filterValueDict.label ? '#6cae55' : ''), minWidth: '120px' }}>
                                                <div className='d-flex text-nowrap align-items-center'>
                                                {t('Reports.PrimaryModule')} <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('label')}></span>
                                                <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('label')}></span>
                                                </div>
                                            </th>
                                            <th id="last_run" style={{ backgroundColor: (filterValueDict.last_run ? '#6cae55' : ''), minWidth: '100px' }}>
                                               <div className='d-flex text-nowrap align-items-center'>
                                                {t('Reports.LastRun')} <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('last_run')}></span>
                                                <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('last_run')}></span>
                                                </div>
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            showspinner === true ?
                                                <tr>
                                                    <>
                                                        <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                    </>
                                                </tr>

                                                :
                                                filteredArray.length === 0 ?
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>

                                                    </tr>
                                                    :
                                                    filteredArray.map((listVal, index) =>
                                                        <tr onClick={(event) => listVal.Name === "Report By Customer" ? OpenCreatePage() : OpenPolicy(event, listVal.Name, "Open")}>
                                                            {/* <tr onClick={(event) => OpenPolicy(event, listVal.Name, "Open")}> */}
                                                            <td >{listVal.Name}</td>
                                                            <td>{listVal.Primarymodule}</td>
                                                            <td>{moment(listVal.Lastrun).format(SessionManage.getcurrentdateformat())}  {moment(listVal.Lastrun).format('hh:mm:ss')}</td>

                                                        </tr>
                                                    )

                                        }

                                    </tbody>
                                </table>

                                {
                                    showLoadMore === true ?
                                        <div className='text-center container row'>
                                            <div className='col-md-5'></div>
                                            <div className='col-md-2'>
                                                <button className='btn btn-sm btn-update ml-1' onClick={handleLoadMore} style={{ width: "70px" }}>{t('LoadMore')}</button>
                                            </div>
                                            <div className='col-md-5'></div>

                                        </div> : ""}
                                {showfilterpopup === true ?
                                    <div className='pop-up' style={{ left: divleft, top: divtop }}>

                                        {filterCol === 'name' || filterCol === 'label' ?
                                            <>
                                                <div className="container">
                                                    <div className="row">
                                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                    </div>
                                                </div>

                                                {/* <div>
                                                <span className='fa fa-filter' /><input type='textbox' placeholder={t('Search')} className='col-md-10 ml-2' value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                            </div> */}
                                                <div className="container mt-2">
                                                    <div className="row">
                                                        <input type='textbox' placeholder={t('Search')} className='filtertextboxbackground col-md-11 textbox-padding ml-2' style={{ outline: 'none' }} value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>

                                        }

                                        {filterCol === 'last_modified_date' ?

                                            <div>
                                                <span className='fa fa-filter' /><input type='date' className='col-md-10 ml-2' value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                            </div>

                                            :
                                            <></>
                                        }

                                        {filterCol === 'last_modified_user' ?
                                            <>
                                                <div className="row">
                                                    <span className='col-md-1 fa fa-filter' />
                                                    <Multiselect
                                                        onSelect={(Event) => OndropdownChange(Event)}
                                                        onRemove={(Event) => OndropdownChange(Event)}
                                                        options={dropdownData}
                                                        displayValue="Name"

                                                        showCheckbox={true}
                                                        selectedValues={multiFilterValue[filterCol]}
                                                        className='col-md-9 textbox-padding fontsizedropdown'
                                                        style={customMultiStyles}
                                                    />
                                                </div>
                                            </>
                                            :
                                            <></>
                                        }
                                        {filterCol === 'last_run' ?
                                            <>
                                                <div className="container">
                                                    <div className="row">
                                                        <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                    </div>
                                                </div>
                                                <div className="container mt-2">
                                                    <div className="row">
                                                        <input type='date' className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                        }

                                        {filterCol === 'status' ?
                                            <>
                                                <div className="row">
                                                    <span className='col-md-1 fa fa-filter' />
                                                    <Multiselect
                                                        onSelect={(Event) => OndropdownChange(Event)}
                                                        onRemove={(Event) => OndropdownChange(Event)}
                                                        options={activeOption}
                                                        displayValue="Name"

                                                        showCheckbox={true}
                                                        selectedValues={multiFilterValue[filterCol]}
                                                        className='col-md-9 textbox-padding fontsizedropdown'
                                                        style={customMultiStyles}
                                                    />
                                                </div>
                                            </>
                                            :
                                            <></>
                                        }
                                        <div style={{ paddingTop: '10px', paddingLeft: '47px', paddingBottom: '7px' }}>
                                            <button className="btn btn-sm btn-update" onClick={() => filterHandler('Apply')} style={{ width: "45px" }}>{t('Apply')}</button>
                                            <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "45px" }} onClick={() => filterHandler('Reset')}>{t('Reset')}</button>
                                        </div>
                                        <div>
                                        </div>
                                    </div>
                                    :
                                    <></>
                                }
                            </div>

                        </div>
                    </div>

                </div>
            </div >
            <Modal show={showInvoiceModal}
                size="bg" centered>
                <Modal.Body style={{ "textAlign": 'center' }} >
                    <div class="container">
                        {loadData != 'User Entitlement' ?
                            <h6 style={{ "textAlign": 'center', marginBottom: "10px" }}>{t('Rebates.SelectAccrualPeriod&Customer')}</h6>
                            :
                            <h6 style={{ "textAlign": 'center', marginBottom: "10px" }}>{t('Rebates.SelectUsersRolesPermissionsModules')}</h6>
                        }
                        <hr></hr>
                        <div style={{ marginTop: "5px", maxHeight: "450px", overflowX: "hidden", overflowY: "scroll" }}>
                            {loadData != 'User Entitlement' ?
                                <>
                                    <div className="row">
                                        <label className="col-md-4 text-left">{t('Reports.DateRange')}</label>
                                        <Multiselect
                                            onSelect={(Event) => OnPopupFiscalYearChange(Event)}
                                            onRemove={(Event) => OnPopupFiscalYearChange(Event)}
                                            options={fiscalYearData}
                                            displayValue="label"
                                            className='col-md-9 textbox-padding fontsizedropdown'
                                            style={regionStyles}
                                            singleSelect="true"
                                        />
                                    </div>
                                    <br></br>
                                    <div className="row">
                                        <label className="col-md-4 text-left">{t('Rebates.AccrualPeriod')}</label>
                                        <Multiselect
                                            onSelect={(Event) => OnPopupPeriodChange(Event)}
                                            onRemove={(Event) => OnPopupPeriodChange(Event)}
                                            options={invoicePeriodValue}
                                            displayValue="Name"
                                            className='col-md-9 textbox-padding fontsizedropdown'
                                            style={regionStyles}
                                            singleSelect="true"
                                        />
                                    </div>
                                </>
                                :
                                ''}
                            <br></br>
                            {loadData == 'Report By Customer' ?
                                <div className="row">
                                    <label className="col-md-4 text-left">{t('Rebates.Customer')}</label>
                                    <Multiselect
                                        onSelect={(Event) => OnPopupCustomerChange(Event)}
                                        onRemove={(Event) => OnPopupCustomerChange(Event)}
                                        options={customerData}
                                        displayValue="label"

                                        className='col-md-9 textbox-padding fontsizedropdown'
                                        style={CustomerStyles}
                                    />
                                </div>
                                :
                                loadData == 'Report By Customer Group' ?
                                    <div className="row">

                                        <label className="col-md-4 text-left">{t('Rebates.GroupCustomer')}</label>
                                        <Multiselect
                                            onSelect={(Event) => OnPopupGroupCustomerChange(Event)}
                                            onRemove={(Event) => OnPopupGroupCustomerChange(Event)}
                                            options={groupCustomerData}
                                            displayValue="label"

                                            className='col-md-9 textbox-padding fontsizedropdown'
                                            style={CustomerStyles}
                                        />
                                    </div>
                                    : loadData == 'Customer Invoice Payment Details Report' || loadData == 'Report By Customer/CustomerGroup' ?
                                        <>
                                            <div className="row">
                                                <label className="col-md-4 text-left">{t('Rebates.Customer')}</label>
                                                <Multiselect
                                                    onSelect={(Event) => OnPopupInvoicePaymentChange(Event)}
                                                    onRemove={(Event) => OnPopupInvoicePaymentChange(Event)}
                                                    options={customerData}
                                                    displayValue="label"
                                                    className='col-md-9 textbox-padding fontsizedropdown'
                                                    style={CustomerStyles}
                                                />
                                            </div>
                                            <br></br>
                                            <div className="row">
                                                <label className="col-md-4 text-left">{t('Rebates.GroupCustomer')}</label>
                                                <Multiselect
                                                    onSelect={(Event) => OnPopupGroupCustomerChange(Event)}
                                                    onRemove={(Event) => OnPopupGroupCustomerChange(Event)}
                                                    options={groupCustomerData}
                                                    displayValue="label"

                                                    className='col-md-9 textbox-padding fontsizedropdown'
                                                    style={CustomerStyles}
                                                />
                                            </div>
                                        </>
                                        : loadData == 'User Entitlement' ?
                                            <>
                                                <div className="row">
                                                    <label className="col-md-4 text-left">{t('Users')}</label>
                                                    <Multiselect
                                                        onSelect={(Event) => OnPopupUserChange(Event, "Users")}
                                                        onRemove={(Event) => OnPopupUserRemoveChange(Event, "Users")}
                                                        options={usersData}
                                                        displayValue="label"
                                                        className='col-md-9 textbox-padding fontsizedropdown'
                                                        style={CustomerStyles}
                                                        name='Users'
                                                    />
                                                </div>
                                                <br></br>
                                                <div className="row">
                                                    <label className="col-md-4 text-left">{t('Roles')}</label>
                                                    <Multiselect
                                                        onSelect={(Event) => OnPopupUserChange(Event, "Roles")}
                                                        onRemove={(Event) => OnPopupUserRemoveChange(Event, "Roles")}
                                                        options={rolesData}
                                                        displayValue="label"
                                                        className='col-md-9 textbox-padding fontsizedropdown'
                                                        style={CustomerStyles}
                                                        name='Roles'
                                                    />
                                                </div>
                                                <br></br>
                                                <div className="row">
                                                    <label className="col-md-4 text-left">{t('Permissions')}</label>
                                                    <Multiselect
                                                        onSelect={(Event) => OnPopupUserChange(Event, 'Permissions')}
                                                        onRemove={(Event) => OnPopupUserRemoveChange(Event, 'Permissions')}
                                                        options={permissionsData}
                                                        displayValue="label"
                                                        className='col-md-9 textbox-padding fontsizedropdown'
                                                        style={CustomerStyles}
                                                        name='Permissions'
                                                    />
                                                </div>
                                                <br></br>
                                                <div className="row">
                                                    <label className="col-md-4 text-left">{t('Modules')}</label>
                                                    <Multiselect
                                                        onSelect={(Event) => OnPopupUserChange(Event, 'Modules')}
                                                        onRemove={(Event) => OnPopupUserRemoveChange(Event, 'Modules')}
                                                        options={modulesData}
                                                        displayValue="label"
                                                        className='col-md-9 textbox-padding fontsizedropdown'
                                                        style={CustomerStyles}
                                                        name='Modules'
                                                    />
                                                </div>
                                                <br></br>
                                                {/* <div className="row">
                                                    <label className="col-md-4 text-left">{t('Rebates.Customer')}</label>
                                                    <Multiselect
                                                        onSelect={(Event) => OnPopupInvoicePaymentChange(Event)}
                                                        onRemove={(Event) => OnPopupInvoicePaymentChange(Event)}
                                                        options={customerData}
                                                        displayValue="label"

                                                        className='col-md-9 textbox-padding fontsizedropdown'
                                                        style={CustomerStyles}
                                                    />
                                                </div>
                                                <br></br>
                                                <div className="row">
                                                    <label className="col-md-4 text-left">{t('Rebates.GroupCustomer')}</label>
                                                    <Multiselect
                                                        onSelect={(Event) => OnPopupGroupCustomerChange(Event)}
                                                        onRemove={(Event) => OnPopupGroupCustomerChange(Event)}
                                                        options={groupCustomerData}
                                                        displayValue="label"

                                                        className='col-md-9 textbox-padding fontsizedropdown'
                                                        style={CustomerStyles}
                                                    />
                                                </div> */}
                                            </>
                                            :
                                            ''
                            }
                        </div>
                        <div style={{ float: "right", marginTop: "40px" }}>
                            <Button size='sm' onClick={(event) => OpenPolicy("", "", "Close")} style={{ width: "45px" }}>
                                {t('Close')}
                            </Button>
                            <Button size='sm' style={{ marginLeft: "5px", width: "30px" }} onClick={(event) => ExporttoExcel()}>
                                {t('Ok')}
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>


            <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                onHide={() => setShowErrorModal(false)}></CustomErrorPopup>

        </div >


    )

}
export default ReportsList