import { Link, useLocation, useNavigate } from "react-router-dom"
import React, { useState, useEffect } from 'react'
import classes from '../../RebateProgramComponent/CreateRebateComponent/Form.module.css'
import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal } from 'react-bootstrap';
import Service from '../../../Services/Service';
import 'react-multiple-select-dropdown-lite/dist/index.css'
import CustomErrorPopup from '../../../UI/CustomErrorPopup';
import Multiselect from 'multiselect-react-dropdown';
import $ from 'jquery';
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment'
import SessionManage from '../../../SessionManagement/SessionManage';
import Spinner from 'react-bootstrap/Spinner'
import DatePicker from "react-datepicker";
import ProductTreeDialog from '../../RebateProgramComponent/CreateRebateComponent/ProductTreeDialog';
import Validation from '../../../Services/Validation';
import { NumericFormat } from "react-number-format";
import axios from "axios";
const REBATE_API_BASE_URL = "https://localhost:7096/api/";



function NewReports(props) {
  const navigate = useNavigate();
  let loggedInUser = SessionManage.getuserid();
  const [t, i18n] = useTranslation('translation');
  const [userPerms, setuserPermission] = useState([]);
  const [demoData, setDemoData] = useState([{
    "SAPCode": "ABC123",
    "Customer_Name": "XYZ Corporation",
    "Target": 500000,
    "Quarter_target": 120000,
    "Quarter_target_percentage": 24,
    "Total_Revenue": 110000,
    "Exception_Revenue": 5000,
    "Revenue_after_Exception": 105000,
    "Outstanding_Amount": 20000,
    "Percentage_Threshold": 10,
    "Revenue_for_Accrual": 95000,
    "Percentage_Achieved": 91.67,
    "Accrued_Amount": 20000,
    "LatePayment": 5000,
  }]);

  const [dateRange, setDateRange] = useState([]);
  const [selectedOptionsDateRange, setselectedOptionsDateRange] = useState();

  let rowData = [];
  const [fiscalYearData, setFiscalYearData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [loadData, setLoadData] = useState("");
  const [listViewData, setListViewData] = useState(rowData)
  const [filePath, setFilePath] = useState('');
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showspinner, setshowspinner] = useState('');
  const [filteredArray, setFilteredArray] = useState(rowData)
  const [showLoadMore, setshowLoadMore] = useState('');
  const [totalCountlst, settotalCountlst] = useState('');
  const [userReportData, setuserReportData] = useState({
    "Users": [],
    "Roles": [],
    "Permissions": [],
    "Modules": []
  });
   const [invoicePeriodValue, setInvoicePeriodValue] = useState([
    { "id": 0, "Name": "Q1", "label": "Q1" },
    { "id": 1, "Name": "Q2", "label": "Q2" },
    { "id": 2, "Name": "Q3", "label": "Q3" },
    { "id": 3, "Name": "Q4", "label": "Q4" },
    { "id": 4, "Name": "H1", "label": "H1" },
    { "id": 5, "Name": "H2", "label": "H2" },
    { "id": 6, "Name": "FY", "label": "FY" },
  ]);
const [reportsData, setReportsData] = useState({
    "rebate_period": "",
    "fiscalYear": "",
    "customergroupname": [],
    "customer_Name": [],
    "report_type": "",
    "user_name": [],
    "roles": [],
    "permissions": [],
    "modules": [],
    "filtervaluerequestjson": {
            objFilterValue: [], // Ensure it's an empty array if no filters are provided
            lists: [], // Optional: Include empty array for `AttributeProductListing`
            Cuslists: [], // Optional: Include empty array for `CustomerView`
          },
  });
  const [paging, setpaging] = useState({
    pageSize: 20,
    pageNumber: 1
  })

  {
    useEffect(() => {
      getUserPermission("/Annual-Policy/List")
      getFiscalYear("Reports")
      getCustomerData("Reports/GetActiveCustomers");
      // getReportsData("GetAllRecord", paging.pageSize, paging.pageNumber, "Descending", "Id", [], null);
     // handleApiCall();
    }, [props.id])
  }



  const handleApiCall = async () => {
    // Required query parameters
    const queryParams = {
      pageSize: 20,
      pageNumber: 1,
      sortType: 'Ascending',
      SortProperty: 'Id',
      groupids: 'group1,group2',
    };

    // Construct `getReport` parameters (body)

     if (reportsData.customer_Name.length === 0) {

              let data = []
              for (let i = 0; i < customerData.length; i++) {

                data.push(customerData[i].Name)
              }

              reportsData.customer_Name = data;

            }
    try {
         // Make the POST request with query parameters and request body
        Service.getAllReportData("Reports",paging.pageSize, paging.pageNumber,"Ascending", "Id" , reportsData).then(res => {
         if (typeof res !== 'undefined') {
             if (typeof res.data !== 'undefined' && res.data !== 'null') {
console.log('20/11',res.data)
               setLoading(false);
setDemoData(res.data.DataList)
                     }
           }
         });
         // Handle the API response (e.g., update state or UI)
       } catch (err) {
         // Log any errors
         console.error(err, 'An error occurred while fetching data.');
         // Optionally, show an alert or UI message to the user
       }
  };





  const getUserPermission = (pathname) => {
    Service.GetUserPermission(pathname).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null' && res.data !== '') {
          if (res.data.DataList !== undefined) {
            console.log(res.data.DataList, "peram")
            setuserPermission(res.data.DataList)
          }
        }
      }
    });
  }

  const regionStyles = {
    chips: {
      fontSize: "12px",
      lineHeight: "13px"
    },

    multiSelectContainer: {
      "&:hover": { background: "red" }
    }

  }

  const CustomerStyles = {

    searchBox: {
      height: "80px",
      overflowY: "scroll"
    },

    chips: {
      fontSize: "12px",
      lineHeight: "13px",
      display: "block",
      marginTop: "5px"
    },

    multiSelectContainer: {
      "&:hover": { background: "red" }
    },

  }

  const customStyles = {
    control: base => ({
      ...base,
      minHeight: 26,
    }),
    dropdownIndicator: base => ({
      ...base,
      padding: 2
    }),
    clearIndicator: base => ({
      ...base,
      padding: 2
    }),
    multiValue: base => ({
      ...base,

    }),
    valueContainer: base => ({
      ...base,
      padding: '0px 2px'
    }),
    input: base => ({
      ...base,
      margin: 0,
      padding: 0
    }),
  };


  const [divleft, setdivleft] = useState('');
  const [divtop, setdivtop] = useState('');
  const [sticky, setSticky] = useState("");

  const isSticky = () => {
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 60 ? "is-sticky-thead" : "";
    setSticky(stickyClass);
    const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
    var divtop1 = document.getElementById("tableA");
    setdivtop(divtop1.offsetTop + scrollTopActual)
  };

  const class_thead = `text-white rt-thead  ${sticky}`;


  const getReportsListViewData = (method, pageSize, pageNumber, sortType, SortProperty, filterValue, search) => {

    Service.getAllReportsdataSearch(method, pageSize, pageNumber, sortType, SortProperty, filterValue, search).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
              rowData = res.data;
              setListViewData(rowData)
              console.log(rowData, "rowdata");
              settotalCountlst(res.data.Totalcount)
              setFilteredArray(rowData)
              setshowspinner(false)
              setdata(rowData)
              LoadMore(rowData.length, res.data.Totalcount)
            }
          }
        }
      }

    })
  }

  const LoadMore = (lst, totalcount) => {

    if (lst < totalcount) {
      setshowLoadMore(true);
    }
    else if (lst >= totalcount) {
      setshowLoadMore(false);
    } else {
      setshowLoadMore(false);
    }

  }

  const getFiscalYear = (method) => {

    Service.getFiscalYear(method).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {

              setFiscalYearData(res.data)

            }
          }
        }
      }

    });
  }

  const OnPopupPeriodChange = (e) => {
    let selectedOption = (e);

    let data = String(selectedOption[0].Name)

    setReportsData({ ...reportsData, ['rebate_period']: data })
  }


  const OnPopupFiscalYearChange = (e) => {
    let selectedOption = (e);

    let data = String(selectedOption[0].value)

    setReportsData({ ...reportsData, ['fiscalYear']: data })
  }



  const OnPopupCustomerChange = (e) => {
    let selectedOption = (e);
    let data = []
    for (let i = 0; i < selectedOption.length; i++) {

      data.push(selectedOption[i].Name)
    }

    setReportsData({ ...reportsData, ['customer_Name']: data, ['report_type']: 'Report By Customer' })

  }

  const getCustomerData = (method) => {

    Service.getdata(method).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
              setCustomerData(res.data)

            }
          }
        }
      }

    });
  }

  const ConvertToPDF = (e) => {
    // setShowInvoiceModal(false)
    setLoading(true);
    let data = reportsData

    if (loadData !== 'User Entitlement') {
      if (reportsData.report_type === "Report By Customer") {
        if (reportsData.customer_Name.length === 0) {

          let data = []
          for (let i = 0; i < customerData.length; i++) {

            data.push(customerData[i].Name)
          }

          reportsData.customer_Name = data;

        }
      }
      // else if (reportsData.report_type === "Report By Customer Group") {
      //   if (reportsData.customergroupname.length === 0) {
      //     let data = []
      //     for (let i = 0; i < groupCustomerData.length; i++) {

      //       data.push(groupCustomerData[i].Name)
      //     }

      //     reportsData.customergroupname = data;

      //   }
      // }
      // else if (reportsData.report_type === "Customer Invoice Payment Details Report" || reportsData.report_type === "Report By Customer/CustomerGroup") {
      //   if (reportsData.customer_Name.length === 0) {
      //     let data1 = []
      //     let data2 = []
      //     for (let i = 0; i < customerData.length; i++) {

      //       data1.push(customerData[i].Name)
      //     }
      //     for (let i = 0; i < groupCustomerData.length; i++) {

      //       data2.push(groupCustomerData[i].Name)
      //     }

      //     reportsData.customer_Name = data1;
      //     reportsData.customergroupname = data2;
      //   }
      // }
      Service.getAllReportData("Reports", data).then(res => {

        if (typeof res !== 'undefined') {
          if (typeof res.data !== 'undefined' && res.data !== 'null') {

            debugger
            var path = res.data.Path
            setLoading(false);

            window.location = Service.getBaseUrl() + path;
            setFilePath(path);
            getReportsListViewData("Reports", paging.pageSize, paging.pageNumber, "Descending", "Id", [], null);

            setReportsData({ ...reportsData, ['rebate_period']: "", ['customergroupname']: [], ['customer_Name']: [], ['report_type']: "", ['fiscalYear']: "" })
          }
        }
      });
    }
    else {
      Service.getAllUserReportData("Reports", userReportData).then(res => {
        if (typeof res !== 'undefined') {
          if (typeof res.data !== 'undefined' && res.data !== 'null') {
            console.log(res.data)
            debugger
            var path = res.data
            setLoading(false);
            window.location = Service.getBaseUrl() + path;
            setFilePath(path);
            getReportsListViewData("Reports", paging.pageSize, paging.pageNumber, "Descending", "Id", [], null);
          }
        }
      });
    }
  }

  const SortColumn = () => { }
  const FilterColumn = () => { }
  const hideColumn = []
  const handleChange = () => { }
  const OpenPolicy = () => { }

  //------------------------------------------------------------ 

  return (
    <>
      <div className="container-fluid content-top-gap">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb my-breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">{t('Home.title')}</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <Link to="/reports">{t('Reports.ReportsList')}</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <Link to="/reports/byCustomer">{t('Create Reports By Customer')}</Link>
            </li>
          </ol>
        </nav>
        {loading && (
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        )}
        <ToastContainer autoClose={1000} />
        <div className="row" id="row">
          <div className="col-lg-12">
            <div className="card text-center card_border btn_res">
              <div className="card-header chart-grid__header align-items-center mt-1" style={{ height: "35px" }}>
                <div className="row">
                  <div className="col-lg-7 col-md-6 col-sm-12 text-left">
                    {t('Reports Header')}
                  </div>
                  <div className="col-lg-5 col-md-6 col-sm-12 text-right">
                    <button className="btn  btn-update btn-sm ml-1" style={{ width: "100px", float: "right" }} onClick={handleApiCall}>
                      Generate Report
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row col-lg-12 pr-lg-2 mt-1">
          <div className="col-sm-12">
            <div className="row">
              <div className="col-lg-12 pr-lg-2">
                <div className="card text-center card_border">
                  <div className="card-body" style={{ minHeight: "180px" }}>
                    <div className="container">
                      <div className="row">
                        <div className="col-md-4 d-flex align-items-center">
                          <label className="text-left mb-0" style={{ width: '100%' }}>
                            {t('Reports.DateRange')}
                          </label>
                        </div>
                        <div className="col-md-8">
                          <Multiselect
                            onSelect={(Event) => OnPopupFiscalYearChange(Event)}
                            onRemove={(Event) => OnPopupFiscalYearChange(Event)}
                            options={fiscalYearData}
                            displayValue="label"
                            className='textbox-padding fontsizedropdown'
                            style={regionStyles}
                            singleSelect={true}
                          />
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-md-4 d-flex align-items-center">
                          <label className="text-left mb-0" style={{ width: '100%' }}>
                            {t('Rebates.AccrualPeriod')}
                          </label>
                        </div>
                        <div className="col-md-8">
                          <Multiselect
                            onSelect={(Event) => OnPopupPeriodChange(Event)}
                            onRemove={(Event) => OnPopupPeriodChange(Event)}
                            options={invoicePeriodValue}
                            displayValue="Name"
                            className='textbox-padding fontsizedropdown'
                            style={regionStyles}
                            singleSelect={true}
                          />
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-md-4 d-flex align-items-center">
                          <label className="text-left mb-0" style={{ width: '100%' }}>
                            {t('Rebates.Customer')}
                          </label>
                        </div>
                        <div className="col-md-8">
                          <Multiselect
                            onSelect={(Event) => OnPopupCustomerChange(Event)}
                            onRemove={(Event) => OnPopupCustomerChange(Event)}
                            options={customerData}
                            displayValue="label"
                            className='textbox-padding fontsizedropdown'
                            style={CustomerStyles}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12 mt-3">
          <div className="card text-center card_border">
            <div className="card-header chart-grid__header">
              <div className="row">
                <div className="col-md-8 text-left">
                  {t('Reports Definition')}
                </div>
                <div className="col-md-4 text-right">
                  <button className="btn  btn-update btn-sm ml-1" style={{ width: "100px", float: "right" }} onClick={(e) => ConvertToPDF()}>Export to PDF</button>
                  <button className="btn  btn-update btn-sm ml-1" style={{ width: "100px", float: "right" }}>Export to CSV</button>
                </div>
              </div>
            </div>
            <div>
              <div className='scrollbar-class mb-1'>
                <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableA">
                  <thead style={{ backgroundColor: "var(--primary)" }}>
                    <tr style={{ position: "sticky", top: "0", backgroundColor: "var(--primary)" }}>
                      <th className="tableheader" style={{ width: '30px' }}>
                        <input
                          type="checkbox"
                          id="option-all"
                        />
                      </th>
                      <th style={{ width: '250px' }}>
                        <div className='d-flex text-nowrap'>
                          <span>SAP Code</span>
                        </div>
                      </th>
                      <th style={{ width: '250px' }}>
                        <div className='d-flex text-nowrap'>
                          <span>Customer Name</span>
                        </div>
                      </th>
                      <th style={{ width: "180px" }}>
                        <div className='d-flex text-nowrap'>
                          <span>Target</span>
                        </div>
                      </th>
                      <th style={{ width: "200px" }}>
                        <div className='d-flex text-nowrap'>
                          <span>Quarter Target</span>
                        </div>
                      </th>
                      <th style={{ width: "180px" }}>
                        <div className='d-flex text-nowrap'>
                          <span>Quarter Target %</span>
                        </div>
                      </th>
                      <th style={{ width: "180px" }}>
                        <div className='d-flex text-nowrap'>
                          <span>Total Revenue</span>
                        </div>
                      </th>
                      <th style={{ width: "180px" }}>
                        <div className='d-flex text-nowrap'>
                          <span>Exception Revenue</span>
                        </div>
                      </th>
                      <th style={{ width: '200px' }}>
                        <div className='d-flex text-nowrap'>
                          <span>Revenue After Exception</span>
                        </div>
                      </th>
                      <th style={{ width: '200px' }}>
                        <div className='d-flex text-nowrap'>
                          <span>Outstanding Ammount</span>
                        </div>
                      </th>
                      <th style={{ width: '180px' }}>
                        <div className='d-flex text-nowrap'>
                          <span>Late Payment</span>
                        </div>
                      </th>
                      <th style={{ width: '180px' }}>
                        <div className='d-flex text-nowrap'>
                          <span>% Threshold</span>
                        </div>
                      </th>
                      <th style={{ width: '180px' }}>
                        <div className='d-flex text-nowrap'>
                          <span>Revenue For Accrual</span>
                        </div>
                      </th>      <th style={{ width: '180px' }}>
                        <div className='d-flex text-nowrap'>
                          <span>Accured Ammount</span>
                          <span>% Achieved</span>
                        </div>
                      </th>      <th style={{ width: '180px' }}>
                        <div className='d-flex text-nowrap'>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>

                    {
                      showspinner === true ?
                        <tr>
                          <>
                            <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                            <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                            <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                          </>
                        </tr>

                        :
                        demoData.length === 0 ?
                          <tr><td></td>
                            <td colSpan="9" style={{ textAlign: 'center' }}>
                              {t('NoDataAvailable')}
                            </td>
                          </tr>
                          :
                          demoData.map((listVal, index) =>
                            <tr key={index} onDoubleClick={(event) => OpenPolicy(event, listVal.Id)}>
                              <td><input id={listVal.SAPCode} type="checkbox" value={`${listVal.SAPCode},${listVal.Customer_Name}`} onChange={(event) => handleChange(event, listVal.SAPCode, listVal.Customer_Name)} name="chkBox" /></td>
                              <td style={{ whiteSpace: "nowrap" }} hidden={hideColumn.includes('SAPCode') ? true : false}><div style={{ maxWidth: "100px", minWidth: "100px", maxHeight: "15px" }}>{listVal.SAPCode}</div></td>
                              <td style={{ whiteSpace: "nowrap" }} hidden={hideColumn.includes('Customer_Name') ? true : false}><div style={{ textOverflow: "ellipsis", overflow: "hidden", maxHeight: "15px", minWidth: "190px", maxWidth: "240px" }} data-toggle="tooltip" data-placement="right" title={listVal.Customer_Name}>{listVal.Customer_Name}</div></td>
                              <td hidden={hideColumn.includes('Target') ? true : false}><div style={{ overflow: "hidden", maxWidth: "90px", minWidth: "70px", maxHeight: "15px" }}>{listVal.Target}</div></td>
                              <td hidden={hideColumn.includes('Quarter_target') ? true : false}><div style={{ overflow: "hidden", maxWidth: "90px", minWidth: "70px", maxHeight: "15px" }}>{listVal.Quarter_target}</div></td>
                              <td hidden={hideColumn.includes('Quarter_target_percentage') ? true : false}><div style={{ overflow: "hidden", maxWidth: "90px", minWidth: "70px", maxHeight: "15px" }}>{listVal.Quarter_target_percentage}%</div></td>
                              <td hidden={hideColumn.includes('Total_Revenue') ? true : false}><div style={{ overflow: "hidden", maxWidth: "90px", minWidth: "70px", maxHeight: "15px" }}>{listVal.Total_Revenue}</div></td>
                              <td hidden={hideColumn.includes('Exception_Revenue') ? true : false}><div style={{ overflow: "hidden", maxWidth: "90px", minWidth: "70px", maxHeight: "15px" }}>{listVal.Exception_Revenue}</div></td>
                              <td hidden={hideColumn.includes('Revenue_after_Exception') ? true : false}><div style={{ overflow: "hidden", maxWidth: "90px", minWidth: "70px", maxHeight: "15px" }}>{listVal.Revenue_after_Exception}</div></td>
                              <td hidden={hideColumn.includes('Outstanding_Amount') ? true : false}><div style={{ overflow: "hidden", maxWidth: "90px", minWidth: "70px", maxHeight: "15px" }}>{listVal.Outstanding_Amount}</div></td>
                              <td hidden={hideColumn.includes('LatePayment') ? true : false}><div style={{ overflow: "hidden", maxWidth: "90px", minWidth: "70px", maxHeight: "15px" }}>{listVal.LatePayment}</div></td>
                              <td hidden={hideColumn.includes('Percentage_Threshold') ? true : false}><div style={{ overflow: "hidden", maxWidth: "90px", minWidth: "70px", maxHeight: "15px" }}>{listVal.Percentage_Threshold}%</div></td>
                              <td hidden={hideColumn.includes('Revenue_for_Accrual') ? true : false}><div style={{ overflow: "hidden", maxWidth: "90px", minWidth: "70px", maxHeight: "15px" }}>{listVal.Revenue_for_Accrual}</div></td>
                              <td hidden={hideColumn.includes('Percentage_Achieved') ? true : false}><div style={{ overflow: "hidden", maxWidth: "90px", minWidth: "70px", maxHeight: "15px" }}>{listVal.Percentage_Achieved}%</div></td>
                              <td hidden={hideColumn.includes('Accrued_Amount') ? true : false}><div style={{ overflow: "hidden", maxWidth: "90px", minWidth: "70px", maxHeight: "15px" }}>{listVal.Accrued_Amount}</div></td>
                            </tr>
                          )
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
}
export default NewReports