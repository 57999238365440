import React, { useEffect, useState, forwardRef, useRef } from 'react';
import { Form, FormGroup, Col, Row, Button, InputGroup, Collapse, Modal } from 'react-bootstrap';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import DatePicker from "react-datepicker";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { Link, useLocation } from "react-router-dom"
import Service from '../../../Services/Service';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import Multiselect from 'multiselect-react-dropdown';
import SessionManage from '../../../SessionManagement/SessionManage';
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import CustomErrorPopup from '../../../UI/CustomErrorPopup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function WorkflowRules(props) {
    const csvInstance = useRef();
    const [csvList, setCsvList] = useState([]);
    const [sortColName, setSortColName] = useState("");
    const [sticky, setSticky] = useState("");
    const location = useLocation();
    const navigate = useNavigate();
    const [t, i18n] = useTranslation('translation');
    let rowData = [];
    const [userPerms, setuserPermission] = useState([]);
    const [showspinner, setshowspinner] = useState('');
    const [listViewData, setListViewData] = useState(rowData)
    const [filteredArray, setFilteredArray] = useState(rowData)
    const [searchValue, setSearchValue] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showbutton, setshowbutton] = useState('');
    const [filterValue, setFilterValue] = useState('');
    const [filterCol, setFilterCol] = useState('');
    const [dropdownData, setDropdownData] = useState([]);
    const [dropdownChange, setDropdownChange] = useState('');
    const [showfilterpopup, setshowfilterpopup] = useState(false);
    const [columnAscending, setColumnAscending] = useState(true);
    const [totalselectedshow, settotalselectedshow] = useState('');
    const [totalselectedcount, settotalselectedcount] = useState(0);
    const [totalCountlst, settotalCountlst] = useState('');
    const [ToggleStatusModal, setToggleStatusModal] = useState(false);
    const [toggleChange, setToggleChange] = useState("");
    const [toggleId, setToggleId] = useState();
    const [filtertext, setfiltertext] = useState('');
    const [filterSearch, setFilterSearch] = useState("");
    const [filterdropdownData, setFilterDropdownData] = useState([]);
    const [multiValueids, setMultiValueIds] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteshowbutton, setDeleteshowbutton] = useState('');
    const [errorStatus, setErrorStatus] = useState("Error");

    // New state to manage the Check All checkbox
    const [checkAllChecked, setCheckAllChecked] = useState(false);
    const [checkedValue, setCheckedValue] = useState([]);
    const [checkedMultiValueid, setCheckedMultiValueid] = useState([]);
    const [checkedValueid, setCheckedValueid] = useState([]);
    const [checkedValueStatus, setCheckedValueStatus] = useState([]);
    const [allCheckedHistory, setAllCheckedHistory] = useState(false);
    const [uncheckedBoxesHistory, setuncheckedBoxesHistory] = useState([]);


    const [paging, setpaging] = useState({
        pageSize: 20,
        pageNumber: 1
    })
    const [filterValueDict, setFilterValueDict] = useState({
        WorkflowRuleId: '',
        WorkflowItemTypeName: '',
        RebateTypeName: '',
        SalesLevel: '',
        BusinessUnitName: '',
        CreatedDate: '',
        LastModifiedDate: null,
    });
    const [multiFilterValue, setMultiFilterValue] = useState({
        CreatedUserName: '',
        LastModifiedUserName: '',
        IsActiveValue: ''
    });
    let showSuccessMsg = false;
    let showErrorMsg = false;
    let showDetails = '';
    let loggedInUser = SessionManage.getuserid();
    {
        useEffect(() => {
            setshowspinner(true);
            setColumnAscending(false);
            setSortColName("Id");
            getWorkflowRuleListViewData("WorkflowRule/GetAll", paging.pageSize, paging.pageNumber, "Descending", "Id", [], null)

            getUserPermission(location.pathname);
            window.addEventListener("scroll", isSticky);
            return () => {
                window.removeEventListener("scroll", isSticky);
            };

        }, [props.id])
    }
    const activeOption = [
        // { Name: "Select All", Id: "0" },
        { Name: "Active", Id: "1" },
        { Name: "In-Active", Id: "2" }

    ]
    const isSticky = () => {

        const scrollTop = window.scrollY;
        const stickyClass = scrollTop >= 60 ? "is-sticky-thead" : "";
        setSticky(stickyClass);
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop(divtop1.offsetTop + scrollTopActual)
    };
    const class_thead = `text-white rt-thead  ${sticky}`;

    const getUserPermission = (pathname) => {
        Service.GetUserPermission(pathname).then((res) => {
            if (res !== 'undefined') {

                if (res.data !== 'null' && res.data !== 'undefined' && res.data !== '') {
                    if (res.data.DataList !== 'undefined') {
                        setuserPermission(res.data.DataList)
                    }
                }
            }
        });
    }

    const ExporttoCSV = () => {
        const dateFormat = SessionManage.getcurrentdateformat();
        const timeFormat = "hh:mm A";

        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        let values = "";
        if (checkedBoxes.length >= 1) {
            for (let checkbox of checkedBoxes) {
                values = values === "" ? checkbox.id : values + ";" + checkbox.id;
            }
        }

        let objFilter = [
            {
                filterKey: "id",
                FilterValue: values,
            },
        ];

        // Make the API call without any filters
        Service.getDatawithMultiFilterSearch("WorkflowRule/GetAll", 20000, 1, "Ascending", "Id", checkAllChecked ? filterLst() : objFilter, null).then((res) => {
            if (res.data !== 'null' && res.data !== 'undefined') {
                let csvLst = res.data.DataList;

                let selectedIds = values.split(';');

                let operatedList = csvLst
                //If checkall box is not checked
                if (!checkAllChecked) {
                    //If it has not been checked in history/past
                    if (!allCheckedHistory)
                        operatedList = csvLst.filter(item => selectedIds.includes(`${item.Id}`))
                    //If it has been checked in history/past
                    else
                        operatedList = csvLst.filter(item => !uncheckedBoxesHistory.includes(item.Id))
                }


                // Format the LastModifiedDate before exporting
                let filteredCsvList = operatedList.map(item => {
                    let newItem = { ...item };
                    if (newItem.LastModifiedDate) {
                        newItem.LastModifiedDate = `${moment(item.LastModifiedDate).format(dateFormat)} ${moment(item.LastModifiedDate).format(timeFormat)}`;
                    }
                    // Transform IsActiveValue
                    if (newItem.IsActiveValue) {
                        newItem.IsActiveValue = newItem.IsActiveValue === 'active' ? 'Active' : (newItem.IsActiveValue === 'in-active' ? 'In-Active' : newItem.IsActiveValue);
                    }
                    return newItem;
                });

                setCsvList(filteredCsvList);

                console.log(filteredCsvList, "filtered");

                setTimeout(() => {
                    toast.success('Exported Successfully !', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    csvInstance.current.link.click();
                });
            }
        });
    };

    const checkAll = (mycheckbox) => {
        const checkboxes = document.querySelectorAll("input[type='checkbox']");
        if (mycheckbox.target.checked) {
            settotalselectedshow(true);
            setAllCheckedHistory(true);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = true;
            });
            settotalselectedcount(totalCountlst);
            setCheckAllChecked(true); // Set state to manage Check All checkbox
        } else {
            settotalselectedshow(false);
            setAllCheckedHistory(false);
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = false;
            });
            settotalselectedcount(0);
            setCheckAllChecked(false); // Set state to manage Check All checkbox
        }
    };

    const getWorkflowRuleListViewData = (method, pagesize, pagenumber, sortType, SortProperty, filterValue, search) => {
        Service.getDatawithMultiFilterSearch(method, pagesize, pagenumber, sortType, SortProperty, filterValue, search).then((res) => {
            if (typeof res !== 'undefined') {
                if (res.data !== 'null' && res.data !== 'undefined') {
                    rowData = res.data.DataList
                    setListViewData(rowData)
                    setFilteredArray(rowData)
                    console.log(rowData, "filtered-data");
                    settotalCountlst(res.data.Totalcount)
                    setshowspinner(false);
                }
            }
        });
    }
    const OnActivateOrDeactivate = (item, flag) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        setToggleId(item)
        if (flag == "Open-A") {
            setToggleStatusModal(true)
            setToggleChange("DeActivate")
        }
        if (flag == "Open-D") {
            setToggleStatusModal(true)
            setToggleChange("Activate")
        }
        if (flag == "Ok") {
            Service.PostWithIdAndUser("WorkflowRule/ActivateOrDeActivate", item, loggedInUser).then((res) => {
                if (typeof res !== 'undefined') {

                    if (res.data !== 'null' && typeof res.data !== 'undefined') {
                        if (res.data.Status == "success") {

                            getWorkflowRuleListViewData("WorkflowRule/GetAll", paging.pageSize, paging.pageNumber, sortType, sortColName, filterLst(), searchValue)

                        }
                        else {
                            setErrorMessage(res.data.Status);
                            showErrorMsg = true;
                            setShowErrorModal(true);
                            setErrorStatus("Error");
                        }

                    }
                }
                setToggleStatusModal(false)
            });
        }
        if (flag == "Cancel") {
            setToggleStatusModal(false)
        }
    }
    const filterLst = () => {
        let list = [];
        for (var key in multiFilterValue) {
            if (multiFilterValue[key].length > 0) {
                let values = ""
                multiFilterValue[key].map((i) => {
                    values += i.Name + ";";
                })
                if (values !== null && values !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: values
                    })
                }
            }
        }

        for (var key in filterValueDict) {
            if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                list.push({
                    filterKey: key,
                    FilterValue: filterValueDict[key]
                })
            }
        }
        return list;
    }

    const searchHandler = (event) => {
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;
        setpaging(paging);
        if (event.target.value != '') {

            setSearchValue(String(event.target.value).toLowerCase())
            getWorkflowRuleListViewData("WorkflowRule/GetAll", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), event.target.value);
        }
        else {
            setSearchValue(null);
            getWorkflowRuleListViewData("WorkflowRule/GetAll", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), null);
        }
    }


    const LoadMore = (lst, totalcount) => {

        if (lst < totalcount) {
            setshowLoadMore(true);
        }
        else if (lst >= totalcount) {
            setshowLoadMore(false);
        } else {
            setshowLoadMore(false);
        }

    }
    const [showLoadMore, setshowLoadMore] = useState('');

    const handleLoadMore = () => {

        let pagenumber = paging.pageNumber + 1;
        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        paging.pageNumber = pagenumber
        setpaging(paging);

        Service.getDatawithMultiFilterSearch("WorkflowRule/GetAll", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), searchValue).then((res) => {

            if (typeof res !== 'undefined') {
                if (typeof res.data !== 'undefined' && res.data !== 'null') {
                    if (res.status === 200) {
                        if (res.data !== 'null' && res.data !== 'undefined') {
                            let rowData = res.data.DataList;

                            if (res.data.DataList !== 'null' && res.data.DataList !== 'undefined') {
                                const currentlength = listViewData.length;
                                setListViewData(data => [...data, ...res.data.DataList])
                                setFilteredArray(data => [...data, ...res.data.DataList])
                                settotalCountlst(res.data.Totalcount)

                                LoadMore(currentlength + res.data.DataList.length, res.data.Totalcount)

                                const allCheckBoxes = document.querySelectorAll("input[type='checkbox']");
                                console.log(allCheckBoxes, "21-6");

                                if (!checkAllChecked && totalselectedcount != 0) {
                                    allCheckBoxes.forEach((each) => {
                                        const checkboxId = parseInt(each.id, 10);
                                        if (!isNaN(checkboxId)) {
                                            if (uncheckedBoxesHistory.includes(checkboxId)) {
                                                each.checked = false;
                                            } else {
                                                // each.checked = true;
                                                each.checked = checkedMultiValueid.includes(checkboxId);
                                            }
                                        }
                                    });
                                } else {
                                    allCheckBoxes.forEach((each) => {
                                        each.checked = checkAllChecked;
                                    });
                                }
                            }
                        }
                    }
                }
            }
        });
    };


    const handleChange = (e, id, name, status) => {
        const isChecked = e.target.checked;
        const checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        let count = totalselectedcount;

        if (isChecked) {
            setCheckedValue(id);  // check box value - id
            setCheckedValueStatus(status); // check box value - Active status
            setCheckedValueid(name); // check box value - RA-0000
            setCheckedMultiValueid([...checkedMultiValueid, id]); // Add the checked id to the array
            count += 1;

            let tempUncheckedBoxesHistory = [...uncheckedBoxesHistory];
            const index = tempUncheckedBoxesHistory.findIndex((item) => item === id);
            if (index !== -1) {
                tempUncheckedBoxesHistory.splice(index, 1);
            }
            setuncheckedBoxesHistory(tempUncheckedBoxesHistory);

        } else {
            setuncheckedBoxesHistory([...uncheckedBoxesHistory, id]);
            setCheckedValue([]);  // check box value - id
            setCheckedValueStatus([]); // check box value - Active status
            setCheckedValueid([]); // check box value - RA-0000
            setCheckedMultiValueid(checkedMultiValueid.filter(item => item !== id)); // Remove the unchecked id from the array
            count -= 1;

        }

        settotalselectedcount(count);
        settotalselectedshow(count > 0);

        // Uncheck the "Check All" checkbox if any individual checkbox is unchecked
        if (!isChecked) {
            setCheckAllChecked(false);
        } else if (count === totalCountlst) {
            setCheckAllChecked(true);
        }

        if (checkedBoxes.length == 1) {
            const [Recordname, Recordstatus] = checkedBoxes[0].value.split(',');
            setCheckedValue(checkedBoxes[0].id)  // check box value - id
            setCheckedValueStatus(Recordstatus) // check box value - Active status
            setCheckedValueid(Recordname) //check box value - RA-0000
        }
    };

    const OpenHandler = (id) => {

        if (id == null || id == 0) {
            alert('Please select the Workflow Rule to view')
        }
        else {
            navigate("/workflow/edit/" + id);
        }
    }

    const DeleteHandler = (id) => {
        if (id == null || id == 0) {
            alert('Please select the Workflow Rule to view')
        }
        else {
            Service.delete('WorkflowRule/DeleteWorkflowRule', id).then((res) => {

                if (typeof res !== 'undefined') {
                    if (res.data !== 'null' && typeof res.data !== 'undefined') {
                        if (res.data.Status == "Success") {
                            setShowDeleteModal(false)
                            toast.success('Deleted Successfully !', {
                                position: toast.POSITION.TOP_RIGHT,
                                onClose: () => navigate("/Workflow/0")
                            });
                            getWorkflowRuleListViewData("WorkflowRule/GetAll", paging.pageSize, paging.pageNumber, "Descending", "Id", [], null)
                        }
                        else {
                            setShowDeleteModal(false)
                            setErrorMessage(res.data.Status);
                            showErrorMsg = true;
                            setShowErrorModal(true);
                            setErrorStatus("Error")
                        }
                    }
                }
            });

        }
    }
    const OpenWorkflow = (event, id) => {
        if (event.detail === 2) {

            navigate("/workflow/edit/" + id);
        }
    }

    const filterInput = (e) => {
        let inputValue = e.target.value
        setFilterValue(inputValue)

        setFilterValueDict(() => ({
            ...filterValueDict, [filterCol]: e.target.value,
        }));
    }

    const [divleft, setdivleft] = useState('');
    const [divtop, setdivtop] = useState('');

    const FilterColumn = (name) => {

        const scrollTop = window.scrollY;
        const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
        var divtop1 = document.getElementById("tableA");
        setdivtop(divtop1.offsetTop + scrollTopActual + 30)
        setshowfilterpopup(true)
        setFilterCol(name)

        if (name === 'WorkflowRuleId') {
            setfiltertext(t('WorkflowRule ID'))
            var testDiv = document.getElementById("WorkflowRuleId");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'LastModifiedUserName') {
            setfiltertext(t('LastModifiedBy'))
            var testDiv = document.getElementById("LastModifiedUserName");
            setdivleft(testDiv.offsetLeft + 20 + "px");
            DropdownHandler(name)
        }
        if (name === 'createdby') {
            setfiltertext('Created By')
            var testDiv = document.getElementById("createdby");
            setdivleft(testDiv.offsetLeft + 20 + "px");
            DropdownHandler(name)
        }
        if (name === 'WorkflowItemTypeName') {
            setfiltertext(t('WorkflowRules.WorkflowItem'))
            var testDiv = document.getElementById("WorkflowItemTypeName");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'rebatetype') {
            setfiltertext('Rebate Type')
            var testDiv = document.getElementById("rebatetype");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'saleslevel') {
            setfiltertext('Sales Level')
            var testDiv = document.getElementById("saleslevel");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'BusinessUnitName') {
            setfiltertext(t('WorkflowRules.BusinessUnit'))
            var testDiv = document.getElementById("BusinessUnitName");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }
        if (name === 'LastModifiedDate') {
            setfiltertext(t('LastModifiedDate'))
            var testDiv = document.getElementById("LastModifiedDate");
            setdivleft(testDiv.offsetLeft + 20 + "px");
        }

        if (name === 'IsActiveValue') {
            setfiltertext(t('IsActive'))
            var testDiv = document.getElementById("IsActiveValue");
            setdivleft(testDiv.offsetLeft - 130 + "px");
            addnewfield(activeOption, name)
        }
    }

    const addSeletAll = (optionsArray, alreadySelected) => {

        let result = optionsArray;
        let selectAllObj = {
            "Name": "Select All",
            "Value": "Select All",
            "ischecked": alreadySelected
        }

        result.splice(0, 0, selectAllObj);
        console.log(result, "9999")
        return result;
    }

    const addnewfield = (data, key) => {
        let selectedKeys = multiFilterValue[key] ? multiFilterValue[key] : [];
        let currentdata = data.map((i) => {
            i["ischecked"] = false;
            return i;
        });


        console.log(currentdata, "test-data");
        let alreadySelected = selectedKeys.find((obj) => obj.Name === "Select All") ? true : false;
        if (key != 'status') {
            if (selectedKeys) {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }

                    })
                })
            }


            let result = addSeletAll(currentdata, alreadySelected);
            setDropdownData(result);
            setFilterDropdownData(result)
        }
        else {
            let alreadySelected = selectedKeys.find((obj) => obj.Name === "Select All") ? true : false;
            if (selectedKeys) {
                currentdata.forEach((each) => {
                    selectedKeys.forEach((eachSelected) => {
                        if (each.Name == eachSelected.Name) {
                            each.ischecked = true
                        }
                    })
                })
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
            else {
                setDropdownData(currentdata)
                setFilterDropdownData(currentdata)
            }
        }
    }


    const checkAllFilter = (mycheckbox) => {
        var lastfilter = [...dropdownData]
        let data1 = []
        var checkboxes = document.querySelectorAll("input[name = 'filter-option-all']");
        if (mycheckbox.target.checked == true) {

            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = true;
                return i;
            });
            setDropdownData(currentdata)

            dropdownData.map((each) => {

                const getallData = {
                    Name: each.Name,
                }
                data1.push(getallData)
            })
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {

            let currentdata = lastfilter.map((i) => {
                i["ischecked"] = false;
                return i;
            });
            setFilterValue([])
            setMultiFilterValue([])
        }

    }



    const OndropdownChange = (e, id, name, filterCol) => {
        let isChecked = e.target.checked;
        let allChecked = false;


        let allCheckboxes = document.getElementsByName("filter-option-all");
        let totalChecked = 0;
        allCheckboxes.forEach((checkbox) => {
            if (checkbox.checked)
                totalChecked += 1;
        });

        let tempData = [...dropdownData];
        //If all checkboxes are checked
        if (tempData.length - 1 === totalChecked) {
            tempData[0].ischecked = isChecked;
            allChecked = true;
        }

        setDropdownData(tempData);

        console.log(tempData, "12x");

        var lastfilter = [...dropdownData]
        if (isChecked === true) {
            let currentdata = lastfilter.map((i) => {
                if (i.Id == id) {
                    i["ischecked"] = true;
                }
                return i;
            });

            setDropdownData(currentdata);
            let data1 = []
            const getallData = {
                Name: name,
            }
            data1.push(getallData)
            setFilterValue(filterValue => [...filterValue, ...data1]);
            let updateddata = ([...filterValue, ...data1]);

            if (allChecked) {
                const selectAllObj = {
                    "Name": "Select All"
                };
                updateddata.splice(0, 0, selectAllObj);
            }

            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: updateddata,
            }));
        }
        else {
            let currentdata = lastfilter.map((i) => {
                if (i.Id === id) {
                    i["ischecked"] = false;
                }
                return i;
            });

            console.log(currentdata, "c-data");

            setDropdownData(currentdata);
            setFilterValue(filterValue => filterValue.filter(data => data.Name !== name))
            // let data1 = multiFilterValue[filterCol].filter(data => data.Name !== name);

            let data1 = multiFilterValue[filterCol] || [];
            data1 = data1.filter((data) => data.Name !== name);

            if (tempData.length - 1 >= totalChecked) {
                data1 = data1.filter(data => data.Name !== "Select All");
            }

            console.log(data1, "data");

            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: data1,
            }));
        }
    }

    const UserfilterHandler = (e) => {

        setFilterSearch(e.target.value)
        if (e.target.value !== "") {
            let searchResults = dropdownData.filter((each) => {
                return each.Name.toLowerCase().includes(String(e.target.value).toLowerCase())
            });
            setDropdownData(searchResults);
        }
        else {

            setFilterSearch("")
            setDropdownData(filterdropdownData)
        }
    }



    const DropdownHandler = (key) => {
        Service.getfilterDropdown('WorkflowRule/GetWorkflowFilterValue', key).then((res) => {
            if (typeof res !== 'undefined') {

                if (res.data !== 'null' && typeof res.data !== 'undefined') {

                    addnewfield(res.data, key)
                }
            }
        });
    }



    const filterHandler = (flag) => {

        var sortType = columnAscending === true ? 'Ascending' : 'Descending';
        let pagenumber = 1;
        paging.pageNumber = pagenumber;

        if (flag === 'Apply') {
            setshowfilterpopup(false)
            let list = [];

            for (var key in multiFilterValue) {
                if (multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
            if (list.length > 0) {
                setpaging(paging);
                getWorkflowRuleListViewData("WorkflowRule/GetAll", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);
            }


            let tempDropdownData = dropdownData;
            if (tempDropdownData.length > 0){
                tempDropdownData[0]["ischecked"] = true;}
            setDropdownData(tempDropdownData);
        }
        else {
            setshowfilterpopup(false)
            setMultiFilterValue(() => ({
                ...multiFilterValue, [filterCol]: '',
            }));
            setFilterValueDict(() => ({
                ...filterValueDict, [filterCol]: '',
            }));

            let list = [];
            for (var key in multiFilterValue) {
                if (key !== filterCol && multiFilterValue[key].length > 0) {
                    let values = ""
                    multiFilterValue[key].map((i) => {
                        values += i.Name + ";";
                    })
                    if (values !== null && values !== '') {
                        list.push({
                            filterKey: key,
                            FilterValue: values
                        })
                    }
                }
            }

            for (var key in filterValueDict) {
                if (filterValueDict[key] !== null && filterValueDict[key] !== '' && key !== filterCol) {
                    list.push({
                        filterKey: key,
                        FilterValue: filterValueDict[key]
                    })
                }
            }
            setpaging(paging);
            getWorkflowRuleListViewData("WorkflowRule/GetAll", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);



            setDropdownChange("")
            setFilterCol('')
            setFilterValue('')
            setDropdownData([])
            setFilterSearch("")
            setFilterDropdownData([])
        }
    }

    const SortColumn = (colName) => {

        var sortType = '';
        if (columnAscending === true) {
            setColumnAscending(false);
            sortType = "Descending";
        } else {
            setColumnAscending(true);
            sortType = "Ascending";
        }
        setSortColName(colName);

        let pagenumber = 1;

        paging.pageNumber = pagenumber;
        setpaging(paging);


        getWorkflowRuleListViewData("WorkflowRule/GetAll", paging.pageSize, pagenumber, sortType, colName, filterLst(), searchValue);

    }
    const customMultiStyles = {
        multiselectContainer: {
            maxWidth: '188px',
            marginLeft: '3px'
        },
        chips: {
            borderRadius: '5px'
        }
    };
    let headers = [
        { label: "Workflow Rile ID", key: "WorkflowRuleId" },
        { label: "Workflow Item", key: "WorkflowItemTypeName" },
        { label: "Business Unit", key: "BusinessUnitName" },
        { label: "Last Modified By", key: "LastModifiedUserName" },
        { label: "Last Modified Date", key: "LastModifiedDate" },
        { label: "Is Active", key: "IsActiveValue" },

    ];

    const checkDelete = () => {
        let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
        if (checkedBoxes.length == 1) {

            setMultiValueIds(checkedValue)
            showDeleteDialog('open')

        }
        if (checkedBoxes.length > 1) {
            setCheckedMultiValueid([])
            for (let checkbox of checkedBoxes) {
                checkedMultiValueid.push(checkbox.id)
            }
            // checkedMultiValueid.map(i => {

            //     var z = i.split(",");
            //     multiValueids.push(z[0])
            //     showDeleteDialog('open')

            // })
            const matchingObjects = filteredArray.filter(obj => checkedMultiValueid.includes(obj.Id.toString()));
            let hasApprovedInReviewOrDenied = false;

            matchingObjects.forEach(i => {
                //   if (i.IsActive === false) {
                //     hasApprovedInReviewOrDenied = true;
                //   } else {
                var idAsString = i.Id.toString();
                multiValueids.push(idAsString);
                showDeleteDialog('open');
                //   }
            });


        }
    }

    // delete modal handler
    const showDeleteDialog = (flag) => {
        if (flag === 'open') {
            setShowDeleteModal(true);
        }
        if (flag === 'close') {
            setShowDeleteModal(false);
        }
        if (flag === 'proceed') {
            // Handle deletion logic here
            DeleteHandler(multiValueids);

            // Update state after deletion is confirmed
            let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
            for (let checkbox of checkedBoxes) {
                checkbox.checked = false;
            }

            // Clear the multi-value ids and reset the total selected count and visibility
            setMultiValueIds([]);
            setCheckedMultiValueid([]);
            settotalselectedcount(0);
            settotalselectedshow(false);
            setDeleteshowbutton(false);
            setShowDeleteModal(false);
        }
    };

    const CreateNew = () => {
        navigate("/workflow/add/0")
    }


    const dateFormat = SessionManage.getcurrentdateformat();
    const timeFormat = "hh:mm:ss A";


    function addSpacesToCamelCase(input) {
        return input
            // Add spaces before each capital letter except when followed by another capital letter.
            .replace(/([a-z])([A-Z])/g, '$1 $2')
            // Add spaces before capital letters in a sequence of lowercase letters.
            .replace(/([A-Z]+)([A-Z][a-z])/g, '$1 $2')
            // Ensure consistent capitalization and spacing for words like "Targetsetting".
            .replace(/([A-Z][a-z]+)([A-Z][a-z]+)/g, '$1 $2')
            // Correct specific terms to ensure proper capitalization and spacing.
            .replace(/Targetachievementpolicy/g, 'Target Achievement Policy')
            .replace(/Minthreshold Policy/g, 'Min Threshold Policy')
            .replace(/SlabBasedPolicy/g, 'Slab Based Policy')
            .replace(/Targetsetting Policy/g, 'Target Setting Policy')
            // Capitalize the first letter of each word.
            .replace(/\b\w/g, function (char) { return char.toUpperCase(); });
    }

    //---------------------------------------------------------
    return (
        <div className="container-fluid content-top-gap">

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb my-breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
                    <li className="breadcrumb-item"><Link to="/admin">{t('Menu.Admin')}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{t('WorkflowRules.WorkflowRulesList')}</li>
                </ol>
            </nav>
            <ToastContainer autoClose={1000} />
            <div style={{ display: "none" }}>
                <CSVLink data={csvList} headers={headers} ref={csvInstance} filename='Workflow_Rule_List.csv'  > </CSVLink>
            </div>

            <div className="">
                <div className="row">
                    <div className="col-lg-12 pr-lg-2">
                        <div className="card text-center card_border overflow-auto">

                            <div className="card-body" style={{ minHeight: "70vh" }}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className='d-flex align-items-center mb-1'>
                                            <input type="text" className="form-control form-control-user col-md-10" id="firstName" name="firstName" autoComplete='off' placeholder={t('Search for Workflow ID, Workflow Item, Buisness Unit Or Last Modified By')} onChange={(event) => searchHandler(event)} />

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className='row'>
                                            <div className="col-md-12 d-flex justify-content-end align-items-center">
                                                <div style={{ display: 'inline-block', marginRight: '10px' }}>
                                                    {totalselectedcount === 0 && (
                                                        <a className="btn btn-update btn-sm" type="submit" style={{ width: "75px", backgroundColor: "buttonface" }} onClick={() => CreateNew()}>{t('CreateNew')}</a>
                                                    )}
                                                    {totalselectedcount > 0 && Service.exportPerms(userPerms) && (
                                                        <button className="btn btn-update btn-sm ml-2" type="submit" style={{ width: "50px" }} onClick={(event) => ExporttoCSV()}>{t('Export')}</button>
                                                    )}
                                                    {totalselectedcount === 1 && (
                                                        <>
                                                            <a className="btn btn-update btn-sm ml-2" type="submit" style={{ width: "45px", backgroundColor: "buttonface" }} onClick={() => OpenHandler(checkedValue)}>{t('Open')}</a>
                                                            {/* {Service.editPerms(userPerms) !== false && (
                                                                <button className="btn btn-update btn-sm ml-2" type="submit" style={{ width: "40px" }} onClick={(event) => CopyHandler(checkedValue)}>{t('Copy')}</button>
                                                            )} */}
                                                        </>
                                                    )}
                                                    {totalselectedcount > 0 && (
                                                        <button className="btn btn-update btn-sm ml-2" type="submit" style={{ width: "50px", backgroundColor: "buttonface" }} onClick={() => checkDelete()}>{t('Delete')}</button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='scrollbar-class'>
                                    <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableA">
                                        <thead className="text-white is-sticky-thead-product" style={{ backgroundColor: "var(--primary)" }}>
                                            <tr style={{ position: "sticky", top: "0", backgroundColor: "var(--primary)" }}>
                                                <th className="tableheader" style={{ width: '30px' }}>
                                                    <input
                                                        type="checkbox"
                                                        onChange={checkAll}
                                                        checked={checkAllChecked}
                                                        id="option-all"
                                                    />
                                                </th>
                                                <th id='WorkflowRuleId' style={{ backgroundColor: (filterValueDict.WorkflowRuleId ? '#6cae55' : ''), width: '330px' }}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('WorkflowRules.WorkflowRuleId')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('WorkflowRuleId')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('WorkflowRuleId')}></span>
                                                    </div>
                                                </th>
                                                <th id='WorkflowItemTypeName' style={{ backgroundColor: (filterValueDict.WorkflowItemTypeName ? '#6cae55' : ''), width: '330px' }}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('WorkflowRules.WorkflowItem')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('WorkflowItemTypeName')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('WorkflowItemTypeName')}></span>
                                                    </div>
                                                </th>

                                                <th id='BusinessUnitName' style={{ backgroundColor: (filterValueDict.BusinessUnitName ? '#6cae55' : ''), width: '330px' }}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('WorkflowRules.BusinessUnit')} </span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('BusinessUnitName')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('BusinessUnitName')}></span>
                                                    </div>
                                                </th>

                                                <th id='LastModifiedUserName' style={{ backgroundColor: (multiFilterValue.LastModifiedUserName ? '#6cae55' : ''), width: '330px' }}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('LastModifiedBy')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('LastModifiedUserName')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('LastModifiedUserName')}></span>
                                                    </div>
                                                </th>
                                                <th id='LastModifiedDate' style={{ backgroundColor: (filterValueDict.LastModifiedDate ? '#6cae55' : ''), width: '300px' }}>
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('LastModifiedDate')}</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('LastModifiedDate')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('LastModifiedDate')}></span>
                                                    </div>
                                                </th>

                                                <th id="IsActiveValue" style={{ backgroundColor: (multiFilterValue.IsActiveValue ? '#6cae55' : ''), maxWidth: '200px' }}  >
                                                    <div className='d-flex text-nowrap'>
                                                        <span>{t('IsActive')} &nbsp;</span>
                                                        <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('IsActiveValue')}></span>
                                                        <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('IsActiveValue')}></span>
                                                    </div>
                                                </th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                showspinner === true ?

                                                    <>
                                                        <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                        <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                    </>
                                                    :
                                                    filteredArray.length === 0 ?
                                                        <tr><td></td>
                                                            <td colSpan="9" style={{ textAlign: 'center' }}>
                                                                {t('NoDataAvailable')}
                                                            </td>
                                                        </tr>
                                                        :
                                                        filteredArray.map((listVal, index) =>
                                                            <tr onDoubleClick={(event) => OpenWorkflow(event, listVal.Id)}>
                                                                <td ><input id={listVal.Id} type="checkbox" value={`${listVal.WorkflowRuleId} , ${listVal.status}`} onChange={(event) => handleChange(event, listVal.Id)} name="chkBox" /></td>

                                                                <td>{listVal.WorkflowRuleId}</td>
                                                                <td>{addSpacesToCamelCase(listVal.WorkflowItemTypeName)}</td>

                                                                <td>{listVal.BusinessUnitName}</td>

                                                                <td>{listVal.LastModifiedUserName}</td>
                                                                <td>{`${moment(listVal.LastModifiedDate).format(dateFormat)} ${moment(listVal.LastModifiedDate).format(timeFormat)}`}</td>
                                                                <td ><div className="btn-group">
                                                                    <div className="btn-group" style={{ marginRight: '12px', cursor: "pointer" }}>
                                                                        {Service.editPerms(userPerms) == false ? (listVal.IsActive === true ? "Active" : "In-Active") : listVal.IsActive === true ?
                                                                            <a href="#" style={{ cursor: "pointer" }} title="Active/In-Active" onClick={(event) => OnActivateOrDeactivate(listVal.Id, "Open-A")}>Active</a>
                                                                            :
                                                                            <a href="#" style={{ cursor: "pointer" }} title="Active/In-Active" onClick={(event) => OnActivateOrDeactivate(listVal.Id, "Open-D")}>In-Active</a>
                                                                        }
                                                                    </div>

                                                                </div></td>
                                                            </tr>
                                                        )
                                            }


                                        </tbody>
                                    </table >
                                </div>

                                

                                <div className='row'>

                                    {
                                        totalselectedshow === true ?

                                            <div className='col-lg-2 forgettext col-sm-12 border form-control' style={{ textAlign: "center", marginLeft: "0.938rem" }}>
                                                {t('TotalRecordsSelected')} - <span id="totalselected">{totalselectedcount}</span>
                                            </div>

                                            :
                                            <div className='col-lg-2 col-sm-12' style={{ textAlign: "center", marginLeft: "0.938rem" }}>

                                            </div>
                                    }



                                    <div style={{flex: `0 0 ${totalCountlst > 14 ? "62.5%" : "63%"}`,maxWidth: totalCountlst > 14 ? "62.5%" : "63%"}}></div>


                                    <div className='col-lg-2 forgettext  col-sm-12 border form-control' style={{ textAlign: "center",marginLeft: "0.1rem"  }}>
                                        {t('TotalRecords')} - {totalCountlst}
                                    </div>
                                </div>
                                {
                                    showLoadMore === true ?
                                        <div className='text-center container row mb-1'>
                                            <div className='col-md-5'></div>
                                            <div className='col-md-2'>
                                                <button className='btn btn-sm btn-update ml-1' onClick={handleLoadMore} style={{ width: "70px" }}>{t('LoadMore')}</button>
                                            </div>
                                            <div className='col-md-5'></div>

                                        </div>
                                        :
                                        ""
                                }
                                {
                                    showfilterpopup === true ?
                                        <div className='pop-up' style={{ left: divleft, top: divtop }}>

                                            {filterCol === 'WorkflowItemTypeName' || filterCol === 'rebatetype' || filterCol === 'saleslevel' || filterCol === 'BusinessUnitName' || filterCol === 'WorkflowRuleId' ?

                                                <>
                                                    <div className="container">
                                                        <div className="row">
                                                            <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                        </div>
                                                    </div>
                                                    <div className="container mt-2">
                                                        <div className="row">
                                                            <input type='textbox' placeholder={t('Search')} className='filtertextboxbackground col-md-11 textbox-padding ml-2' style={{ outline: 'none' }} value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                                        </div>
                                                    </div>
                                                </>

                                                :
                                                <>
                                                </>

                                            }

                                            {filterCol === 'created_date' || filterCol === 'LastModifiedDate' ?

                                                <>
                                                    <div className="container">
                                                        <div className="row">
                                                            <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                                                        </div>
                                                    </div>
                                                    <div className="container mt-2">
                                                        <div className="row">
                                                            <input type='date' className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                            }

                                            {filterCol === 'createdby' || filterCol === 'LastModifiedUserName' || filterCol === 'IsActiveValue' ?


                                                <>
                                                    <div className="container">
                                                        <div className="row">
                                                            <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>

                                                        </div>

                                                    </div>

                                                    <div className="container mt-2">
                                                        <div className="row">
                                                            <input type="text" placeholder={t('Search')} value={filterSearch} onChange={(event) => UserfilterHandler(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                                                        </div>

                                                        <div className="row contentoverflow mt-2">
                                                            <table className="filtertable">
                                                                <tbody>
                                                                    {/* <tr>
                                                                        <td><input type="checkbox" name="filter-option-all" id="option-all" onChange={checkAllFilter} /></td>
                                                                        <td>{t('SelectAll')}</td>
                                                                    </tr> */}
                                                                    {
                                                                        dropdownData.map((item, index) => (

                                                                            <tr key={index}>

                                                                                <td><input id={item.Id} data-index="1" name="filter-option-all" checked={item.ischecked} type="checkbox" onChange={item.Name === "Select All" ? checkAllFilter : (event) => OndropdownChange(event, item.Id, item.Name, filterCol)} /></td>

                                                                                <td>{item.Name}</td>

                                                                            </tr>


                                                                        ))
                                                                    }

                                                                </tbody>
                                                            </table>

                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <></>

                                            }

                                            <div style={{ paddingTop: '10px', paddingLeft: '47px', paddingBottom: '7px' }}>
                                                <button className="btn btn-sm btn-update" onClick={() => filterHandler('Apply')} style={{ width: "45px" }}>{t('Apply')}</button>
                                                <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "45px" }} onClick={() => filterHandler('Reset')}>{t('Reset')}</button>
                                            </div>
                                            <div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </div>

                        </div>
                    </div >

                </div >
            </div >
            <Modal show={ToggleStatusModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>{`${t('WorkflowRules.AreYouSureYouWantTo')} ${toggleChange}` + "?"}</Modal.Body>
                <Modal.Footer>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "35px" }} size='sm' onClick={() => OnActivateOrDeactivate(toggleId, "Ok")}>
                        {t('Yes')}
                    </Button>
                    <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => OnActivateOrDeactivate(toggleId, "Cancel")}>
                        {t('Cancel')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showDeleteModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body style={{ "textAlign": 'center' }}>Are you sure you want to delete ?</Modal.Body>
                <Modal.Footer>
                    <Button style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => showDeleteDialog('close')}>
                        Cancel
                    </Button>
                    <Button style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => showDeleteDialog('proceed')}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
        </div >

    )

}
export default WorkflowRules