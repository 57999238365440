import React, { Component } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import CreateNewCustomerGroupMappingPolicyComponent from '../../../Components/EndurancePolicies/CustomerGroupMappingPolicyComponent/CreateNewCustomerGroupMappingPolicyComponent';


function CreateCustomerGroupMappingPolicy(props) {
    let navigate = useNavigate();
    const params = useParams();

    return (
        <div>
            <CreateNewCustomerGroupMappingPolicyComponent {...props} navigate={navigate} id={params.id} name={params.name} />
        </div>
    );

}

export default CreateCustomerGroupMappingPolicy