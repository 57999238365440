import $, { data, event } from 'jquery';
import '../RebateListCompoent/RebateListing.css'
import React, { useRef, useState, forwardRef, useEffect } from 'react';
import { Dropdown, Button, InputGroup, FormControl, Modal } from 'react-bootstrap';
import Service from '../../../Services/Service';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import Multiselect from 'multiselect-react-dropdown';
import { CSVLink } from "react-csv";
import SessionManage from '../../../SessionManagement/SessionManage';
import { useTranslation } from "react-i18next";
import { faL } from '@fortawesome/free-solid-svg-icons';
import DatePicker from "react-datepicker";
import CustomErrorPopup from '../../../UI/CustomErrorPopup';
import classes from '../CreateRebateComponent/Form.module.css'
import ProductTreeDialog from '../CreateRebateComponent/ProductTreeDialog';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from "react-select";

function RebateListComponent(props) {
  const location = useLocation();
  const [userPerms, setuserPermission] = useState([]);
  const [userPermsForClaim, setuserPermissionForClaim] = useState([]);
  const [csvList, setCsvList] = useState([]);
  const [headers, setHeaders] = useState([
    { label: "Agreement ID", key: "Rebate_id" },
    { label: "Agreement Name", key: "RebateName" },
    { label: "Program Line Status", key: "Status" },
    { label: "Validity Start Date ", key: "Validity_From" },
    { label: "Validity End Date ", key: "Validity_To" },
    { label: "Last Modified By", key: "LastModifiedBy" },
    { label: "Last Modified Date", key: "LastModifiedDate" },
    { label: "Created By", key: "CreatedBy" },
  ]);
  const [searchValue, setSearchValue] = useState(null)
  const csvInstance = useRef();
  const [sortColName, setSortColName] = useState("");
  const [sticky, setSticky] = useState("");
  const [t, i18n] = useTranslation('translation');
  var currentDate = new Date();
  var year = currentDate.getFullYear();
  var month = currentDate.getMonth();
  var day = currentDate.getDate();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState("Error");
  const [PrevSelectedTypeState, setPrevSelectedTypeState] = useState('Heirarchy');
  const [selectedType, setSelectedType] = useState('Attribute');
  const [rebatelinestatus, setrebatelinestatus] = useState('New');
  const [Products, SetProducts] = React.useState([]);
  const [clickProduct, setclickProduct] = useState('');


  const [showDeleteModal, toggleModal] = useState(false)
  const [showbutton, setshowbutton] = useState(false);
  const [totalselectedshow, settotalselectedshow] = useState('');
  const [totalselectedcount, settotalselectedcount] = useState(0);
  const [deleteshowbutton, setDeleteshowbutton] = useState('');
  const [showLoadMore, setshowLoadMore] = useState('');
  const [showfilterpopup, setshowfilterpopup] = useState(false);
  const [showspinner, setshowspinner] = useState('');
  const [totalCountlst, settotalCountlst] = useState('');
  const [filtertext, setfiltertext] = useState('');
  const [actionsDone, setActionsDone] = useState(props.id)
  const [paging, setpaging] = useState({
    pageSize: 20,
    pageNumber: 1
  })
  const [showRenewPopup, setShowRenewPopup] = useState(false)
  const [showRenewProductPopup, setShowRenewProductPopup] = useState(false)
  const [showScreen, setShowScreen] = useState('')
  const [showRplData, setshowRplData] = useState([])
  const [rplExcludedData, setExcludedRplData] = useState([])
  const [BgColor, setBgColor] = useState("")
  const [autoSubmit1, setautoSubmit1] = useState(false)
  const [autoSubmit2, setautoSubmit2] = useState(true)
  const [FilteredProducts, SetFilteredProducts] = useState([]);
  const [closeHeaderModal, setCloseHeaderModal] = useState(false);
  const [renewPeriod, setrenewPeriod] = useState
    ({
      startDate: "",
      endDate: ""
    })
  const [rebateValidity, setrebateValidity] = useState
    ({
      validFrom: "",
      ValidTo: ""
    })

  const [inputField, setInputField] = useState({
    addproductdata: true,
    excludeproductdata: false
  })
  const [showpopupcontnent, setshowpopupcontnent] = useState({
    value: 1
  })
  const [ExcludedProductList, SetExcludedProductList] = useState([]);
  const [ProductList, SetProductList] = useState([]);
  const [dualListBoxOptionsByHeirarchy, setdualListBoxOptionsByHeirarchy] = useState([])
  const [dualListBoxOptionsByAttribute, setdualListBoxOptionsByAttribute] = useState([])
  const [checkExcludeRpl, setcheckExcludeRpl] = useState(false);
  const [checkRplProducts, setcheckRplProducts] = useState(false);
  const [checkRplValidity, setcheckRplValidity] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rplId, setRplId] = useState("");
  const[showLockedModal,setLockedModal]=useState(false);
  const [lockedDocumentsId,setLockedDocumentsId] = useState([]);
  const [matchingObject,SetMatchingObjectsLength] = useState(false)

  // New state to manage the Check All checkbox
  const [checkAllChecked, setCheckAllChecked] = useState(false);
  const [checkedValue, setCheckedValue] = useState([]);
  const [checkedMultiValueid, setCheckedMultiValueid] = useState([]);
  const [checkedValueid, setCheckedValueid] = useState([]);
  const [checkedValueStatus, setCheckedValueStatus] = useState([]);
  const [allCheckedHistory, setAllCheckedHistory] = useState(false);
  const [uncheckedBoxesHistory, setuncheckedBoxesHistory] = useState([]);

  const [renewData, setRenewData] = useState({
    Rebateid: 0,
    validityfrom: "",
    validityto: "",
    autosubmit: "",
    submit_comments: "",
    lineitems: []
  });





  const disabledClass = `  ${Service.editPerms(userPerms) == false ? "disabledClass" : ""}`;

  const checkAll = (mycheckbox) => {
    const checkboxes = document.querySelectorAll("input[type='checkbox']");
    if (mycheckbox.target.checked) {
      settotalselectedshow(true);
      setAllCheckedHistory(true);
      checkboxes.forEach(function (checkbox) {
        checkbox.checked = true;
      });
      settotalselectedcount(totalCountlst);
      setCheckAllChecked(true); // Set state to manage Check All checkbox
    } else {
      settotalselectedshow(false);
      setAllCheckedHistory(false);
      checkboxes.forEach(function (checkbox) {
        checkbox.checked = false;
      });
      settotalselectedcount(0);
      setCheckAllChecked(false); // Set state to manage Check All checkbox
    }
  };


  let rowData = [];
  let statusTableData = [];
  const [statusData, setStatusData] = useState(statusTableData)
  const [listViewData, setListViewData] = useState(rowData)
  const [filteredArray, setFilteredArray] = useState(rowData)
  const [state, setState] = useState({ status: '2' });
  const [selectedStatusValue, setSelectedStatusValue] = useState('0');
  const [multiValueids, setMultiValueIds] = useState([]);
  const [errorDeleteModal, setErrorDeleteModal] = useState(false);
  const [columnAscending, setColumnAscending] = useState(true);
  const [filterValue, setFilterValue] = useState([]);
  const [filterCol, setFilterCol] = useState('');
  const [dropdownData, setDropdownData] = useState([]);
  const [filterdropdownData, setFilterDropdownData] = useState([]);
  const [filterSearch, setFilterSearch] = useState("");
  const [dropdownChange, setDropdownChange] = useState([{ filterKey: "", FilterValue: "" }]);
  const [rebatefilter, setRebateFilter] = useState('red');
  const [customerfilter, setCustomerFilter] = useState();
  const [modifiedfilter, setModifiedFilter] = useState();
  const [modifieddatefilter, setModifiedDateFilter] = useState();
  const [createdfilter, setCreatedFilter] = useState();
  const [createddatefilter, setCreatedDateFilter] = useState();
  const [showCustomerPopup, setShowCustomerpopup] = useState([])
  const [customerData, setCustomerData] = useState([])
  const [sidebarwidth, setsidebarwidth] = useState('');
  const [sidebarHeight, setsidebarHeight] = useState('');
  const [filterValueDict, setFilterValueDict] = useState({
    LastModifiedDate: null,
    created_date: null,
    Rebate_id: '',
    Status: '',
    Validit_yFrom: null,
    Validity_To: null,
  });
  const [multiFilterValue, setMultiFilterValue] = useState({
    Customer: '',
    LastModifiedBy: '',
    CreatedBy: '',
    DistributorName: '',
    AgreementType: ''

  });
  let loggedInUser = SessionManage.getuserid();
  const navigate = useNavigate();

  const customStyles = {
    control: base => ({
      ...base,
      minHeight: 28
    }),
    dropdownIndicator: base => ({
      ...base,
      padding: 2
    }),
    clearIndicator: base => ({
      ...base,
      padding: 2
    }),
    multiValue: base => ({
      ...base,

    }),
    valueContainer: base => ({
      ...base,
      padding: '0px 2px'
    }),
    input: base => ({
      ...base,
      margin: 0,
      padding: 0
    })
  };

  {
    useEffect(() => {

      getUserPermission(location.pathname);
      getUserPermissionForClaim("new-claimagreement");
      settotalselectedshow(false);
      getStatusData("status")
      setshowspinner(true)
      setColumnAscending(false);
      setSortColName("Id");
      AddProductHandler()
      getdataProductSelectorByAttribute(0);
      getRebateListViewData("rebate/GetByRebateStatuslst/status", paging.pageSize, paging.pageNumber, "Descending", "Id", [], searchValue);
      getColumnVisibilityList();
      getColVisibilityListData();
      window.addEventListener("scroll", isSticky);
      return () => {
        window.removeEventListener("scroll", isSticky);
      };
    }, [selectedStatusValue])
  }
  const isSticky = () => {
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 60 ? "is-sticky-thead" : "";
    setSticky(stickyClass);
    const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
    var divtop1 = document.getElementById("tableA");
    setdivtop(divtop1.offsetTop + scrollTopActual)
  };
  const class_thead = `text-white rt-thead  ${sticky}`;

  const getUserPermission = (pathname) => {
    Service.GetUserPermission(pathname).then((res) => {
      if (typeof res !== 'undefined') {

        if (res.data !== 'null' && typeof res.data !== 'undefined' && res.data !== '') {
          if (res.data.DataList !== undefined) {
            setuserPermission(res.data.DataList)
          }
        }
      }
    });
  }

  const getUserPermissionForClaim = (claimpath) => {
    Service.GetUserPermission(claimpath).then((res) => {
      if (typeof res !== 'undefined') {

        if (res.data !== 'null' && typeof res.data !== 'undefined' && res.data !== '') {
          if (res.data.DataList !== undefined) {
            setuserPermissionForClaim(res.data.DataList)
          }
        }
      }
    });
  }


  const getStatusData = (method) => {
    Service.getAlldata(method).then((res) => {

      if (typeof res !== 'undefined') {
        if (res.data !== 'null' && res.data !== 'undefined') {
          statusTableData = res.data
          setStatusData(statusTableData)

        }
      }
    });
  }

  const LoadMore = (lst, totalcount) => {

    if (lst < totalcount) {
      setshowLoadMore(true);
    }
    else if (lst >= totalcount) {
      setshowLoadMore(false);
    } else {
      setshowLoadMore(false);
    }

  }

  const getRebateListViewData = (method, pageSize, pageNumber, sortType, SortProperty, filterVal, search) => {
    
    setLockedDocumentsId([]);
    Service.getdataWithPagingByGroupSearch(method, pageSize, pageNumber, sortType, SortProperty, filterVal, search).then((res) => {

      if (typeof res !== 'undefined') {
        if (res.data !== 'null' && res.data !== 'undefined') {
          rowData = res.data.DataList
          setListViewData(rowData)
          console.log(rowData, 'rowData');
          setFilteredArray(rowData)
          setshowspinner(false)
          settotalCountlst(res.data.Totalcount)
          setActionsDone(0)
          setLockedDocumentsId(rowData.filter(each => each.Locked === true).map(each => each.Id));
          LoadMore(rowData.length, res.data.Totalcount)
        }
      }
    });
  }


  const clickShowCustomerPopup = (e, data, flag) => {


    if (flag === 'Open') {

      getRebateCustomerData("rebate/GetCustomerBasedOnRebateId", data);
      setShowCustomerpopup(true)
      setsidebarwidth("60vw");

    }
    if (flag === 'Close') {
      setShowCustomerpopup(false)
    }
  }


  const searchHandler = (event) => {
    var sortType = columnAscending === true ? 'Ascending' : 'Descending';
    console.log(addRemoveCol,"Add Remove Col")
    let pagenumber = 1;

    paging.pageNumber = pagenumber;
    setpaging(paging);
    if (event.target.value != '') {

      setSearchValue(String(event.target.value).toLowerCase())
      getRebateListViewData("rebate/GetByRebateStatuslst/status", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), event.target.value);
    }
    else {

      setSearchValue(null);
      getRebateListViewData("rebate/GetByRebateStatuslst/status", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), null);
    }
  }




  const CopyHandler = (id) => {
    setLoading(true)
    let rowData = {}
    let pagesize = 20 * paging.pageNumber
    let pagenumber = 1
    Service.PostWithIdAndUser('Rebate/CopyRebate', id, loggedInUser).then((res) => {
      if (typeof res !== 'undefined') {
        if (res.data !== 'null' && res.data !== 'undefined') {
          // copyID = res.data
          paging.pageSize = 20
          paging.pageNumber = pagenumber
          setpaging(paging)
          getRebateListViewData("rebate/GetByRebateStatuslst/status", pagesize, pagenumber, "Ascending", "Id", [], searchValue)
          navigate('/rebate-program/0')
          setLoading(false)
          toast.success('Copy Successfully !', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
      else
        setLoading(false)

    });
  }

  const OpenHandler = (id,AgreementType) => {

    if (id == null || id == 0) {
      alert('Please select a Policy to view')
    }
    else {
      debugger
      if (AgreementType === "Engine oil TOD" || AgreementType === "Engine Oil TOD") {
        navigate("/new-engineoil/" + id + "/fromRebate/0")
      }
      else {
        navigate("/new-rebate/" + id + "/fromRebate/0");
      }
      
    }
  }


  const checkDelete = () => {
    let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
    if (checkedBoxes.length == 1) {
      if (checkedValueStatus.includes('Approved') || checkedValueStatus.includes('InReview') || checkedValueStatus.includes('Denied')) {
        setErrorDeleteModal(true)
      }
      else {

        // setMultiValueIds(checkedValue)
        // showDeleteDialog('open')
        if(lockedDocumentsId.includes(Number(checkedValue))){
          showDeleteDialog('locked');
        }else{
          setMultiValueIds(checkedValue);
          showDeleteDialog('open');
      }
      }
    }
    if (checkedBoxes.length > 1) {
      setCheckedMultiValueid([])
      for (let checkbox of checkedBoxes) {

        checkedMultiValueid.push(checkbox.id)

      }
      //const matchingObjects = filteredArray.filter(obj => checkedMultiValueid.includes(obj.Id.toString()));
      const lockedString = lockedDocumentsId.map(id => id.toString());
      const checkedMultiValueidString = checkedMultiValueid.map(id => id.toString());
      const commonIds = checkedMultiValueidString.filter(id => lockedString.includes(id));
      const result1 = checkedMultiValueidString.filter(id => !commonIds.includes(id))
      const matchingObjects = filteredArray.filter(obj => result1.includes(obj.Id.toString()));
      if(matchingObjects.length === 0){
          SetMatchingObjectsLength(true)
          showDeleteDialog('locked');    
      }
      let hasApprovedInReviewOrDenied = false;

      matchingObjects.forEach(i => {
        if (i.Status?.includes('Approved') || i.Status?.includes('InReview') || i.Status?.includes('Denied')) {
          hasApprovedInReviewOrDenied = true;
        } else {
          var idAsString = i.Id.toString();
          multiValueids.push(idAsString);
          showDeleteDialog('open');
        }
      });

      if (hasApprovedInReviewOrDenied) {
        setErrorDeleteModal(true);
      }
    }
  }




  // delete modal handler
  const showDeleteDialog = (flag) => {
    if (flag === 'open') {
      toggleModal(true);
    }
    if (flag === 'close') {
      toggleModal(false);
    }
    if (flag === 'proceed') {
      // Handle deletion logic here
      handleDelete(multiValueids);

      // Update state after deletion is confirmed
      let checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
      for (let checkbox of checkedBoxes) {
        checkbox.checked = false;
      }

      // Clear the multi-value ids and reset the total selected count and visibility
      setMultiValueIds([]);
      setCheckedMultiValueid([]);
      settotalselectedcount(0);
      settotalselectedshow(false);
      setDeleteshowbutton(false);
      toggleModal(false);
    }

    if(flag === 'locked'){
      setLockedModal(true)
  }

  if(flag === 'closelocked'){
      setLockedModal(false)
      SetMatchingObjectsLength(false)
  }
  };





  const handleDelete = (id) => {

    setLoading(true)
    let pagesize = paging.pageSize * paging.pageNumber;
    let pagenumber = 1;
    Service.deleteDataByRebateID('Rebate/Delete/RebateID', id, loggedInUser).then((res) => {
      if (typeof res !== 'undefined') {
        getRebateListViewData("rebate/GetByRebateStatuslst/status", pagesize, pagenumber, "Ascending", "Id", [], searchValue)
        if (res.data !== 'null' && res.data !== 'undefined') {

          showDeleteDialog('close')
          getRebateListViewData("rebate/GetByRebateStatuslst/status", paging.pageSize, paging.pageNumber, "Ascending", "Id", [], searchValue)
          setMultiValueIds([])
          setLoading(false)
          toast.error('Deleted Successfully !', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    });
  }


  const handleChange = (e, id, status, name, validfrom, validto) => {
    const isChecked = e.target.checked;
    const checkedBoxes = document.querySelectorAll('input[name=chkBox]:checked');
    let count = totalselectedcount;

    if (isChecked) {
      setCheckedValue(id);  // check box value - id
      setCheckedValueStatus(status); // check box value - Active status
      setCheckedValueid(name); // check box value - RA-0000
      setCheckedMultiValueid([...checkedMultiValueid, id]); // Add the checked id to the array
      setrebateValidity({ ...rebateValidity, ['validFrom']: validfrom, ["ValidTo"]: validto });
      count += 1;

      let tempUncheckedBoxesHistory = [...uncheckedBoxesHistory];
      const index = tempUncheckedBoxesHistory.findIndex((item) => item === id);
      if (index !== -1) {
        tempUncheckedBoxesHistory.splice(index, 1);
      }
      setuncheckedBoxesHistory(tempUncheckedBoxesHistory);

    } else {
      setuncheckedBoxesHistory([...uncheckedBoxesHistory, id]);
      setCheckedValue([]);  // check box value - id
      setCheckedValueStatus([]); // check box value - Active status
      setCheckedValueid([]); // check box value - RA-0000
      setCheckedMultiValueid(checkedMultiValueid.filter(item => item !== id)); // Remove the unchecked id from the array
      setrebateValidity({ ...rebateValidity, ['validFrom']: "", ["ValidTo"]: "" });
      count -= 1;

    }

    settotalselectedcount(count);
    settotalselectedshow(count > 0);

    // Uncheck the "Check All" checkbox if any individual checkbox is unchecked
    if (!isChecked) {
      setCheckAllChecked(false);
    } else if (count === totalCountlst) {
      setCheckAllChecked(true);
    }

    if (checkedBoxes.length == 1) {
      // const [Recordname, Recordstatus] = checkedBoxes[0].value.split(',');
      const value = checkedBoxes[0].value;
      const firstCommaIndex = value.indexOf(',');
      const Recordname = value.substring(0, firstCommaIndex);
      const Recordstatus = value.substring(firstCommaIndex + 1);
      setCheckedValue(checkedBoxes[0].id); // check box value - id
      setCheckedValueStatus(Recordstatus); // check box value - Active status
      setCheckedValueid(Recordname); // check box value - RA-0000

    }
  };

  


  const filterLst = () => {
    let list = [];
    for (var key in multiFilterValue) {
      if (multiFilterValue[key].length > 0) {
        let values = ""
        multiFilterValue[key].map((i) => {
          values += i.Name + ";";
        })
        if (values !== null && values !== '') {
          list.push({
            filterKey: key,
            FilterValue: values
          })
        }
      }
    }

    for (var key in filterValueDict) {
      if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
        list.push({
          filterKey: key,
          FilterValue: filterValueDict[key]
        })
      }
    }
    return list;
  }

  const handleLoadMore = () => {

    let pagenumber = paging.pageNumber + 1;
    var sortType = columnAscending === true ? 'Ascending' : 'Descending';
    paging.pageNumber = pagenumber
    setpaging(paging);

    Service.getdataWithPagingByGroupSearch("rebate/GetByRebateStatuslst/status", paging.pageSize, pagenumber, sortType, sortColName, filterLst(), searchValue).then((res) => {

      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
              let rowData = res.data.DataList;

              if (res.data.DataList !== 'null' && res.data.DataList !== 'undefined') {
                const currentlength = listViewData.length;
                setListViewData(listViewData => [...listViewData, ...res.data.DataList])
                setFilteredArray(filteredArray => [...filteredArray, ...res.data.DataList])

                setActionsDone(0)

                LoadMore(currentlength + rowData.length, res.data.Totalcount)

                const allCheckBoxes = document.querySelectorAll("input[type='checkbox']");
                console.log(allCheckBoxes, "21-6");

                if (!checkAllChecked && totalselectedcount != 0) {
                  allCheckBoxes.forEach((each) => {
                    const checkboxId = parseInt(each.id, 10);
                    if (!isNaN(checkboxId)) {
                      if (uncheckedBoxesHistory.includes(checkboxId)) {
                        each.checked = false;
                      } else {
                        // each.checked = true;
                        each.checked = checkedMultiValueid.includes(checkboxId);
                      }
                    }
                  });
                } else {
                  allCheckBoxes.forEach((each) => {
                    each.checked = checkAllChecked;
                  });
                }
              }
            }
          }
        }
      }
    });
  };

  const OpenRebate = (event, id, AgreementType) => {
    if (event.detail === 2) {
      if (AgreementType === "Engine oil TOD" || AgreementType === "Engine Oil TOD") {
        navigate("/new-engineoil/" + id + "/fromRebate/0")
      }
      else {
        navigate("/new-rebate/" + id + "/fromRebate/0");
      }
    }
  }


  const SortColumn = (colName) => {
    var sortType = '';
    if (columnAscending === true) {
      setColumnAscending(false);
      sortType = "Descending";
    } else {
      setColumnAscending(true);
      sortType = "Ascending";
    }
    setSortColName(colName);

    let pagenumber = 1;

    paging.pageNumber = pagenumber;
    setpaging(paging);
    getRebateListViewData("rebate/GetByRebateStatuslst/status", paging.pageSize, paging.pageNumber, sortType, colName, filterLst(), searchValue);
  }


  const filterInput = (e) => {

    let inputValue = e.target.value
    setFilterValue(inputValue)
    setFilterValueDict(() => ({
      ...filterValueDict, [filterCol]: e.target.value,
    }));
  }
  const OndropdownAgreementTypeChange = (e, id, name) => {

    let isChecked = e.target.checked;
    var lastfilter = [...dropdownData]
    if (isChecked === true) {
      let currentdata = lastfilter.map((i) => {
        if (i.Name == name) {
          i["ischecked"] = true;
        }
        return i;
      });

      setDropdownData(currentdata);
      let data1 = []
      const getallData = {
        Name: name,
      }
      data1.push(getallData)
      setFilterValue(filterValue => [...filterValue, ...data1]);

      let updateddata = ([...filterValue, ...data1]);
      setMultiFilterValue(() => ({
        ...multiFilterValue, [filterCol]: updateddata,
      }));
    }
    else {
      let currentdata = lastfilter.map((i) => {
        if (i.Name == name) {
          i["ischecked"] = false;
        }
        return i;
      });

      setDropdownData(currentdata);
      setFilterValue(filterValue => filterValue.filter(data => data.Name !== name))
      let data1 = filterValue.filter(data => data.Name !== name);
      setMultiFilterValue(() => ({
        ...multiFilterValue, [filterCol]: data1,
      }));
    }

  }

  const OndropdownChange = (e, id, name) => {

    let isChecked = e.target.checked;
    let allChecked = false;


    let allCheckboxes = document.getElementsByName("filter-option-all");
    let totalChecked = 0;
    allCheckboxes.forEach((checkbox) => {
      if (checkbox.checked)
        totalChecked += 1;
    });

    let tempData = [...dropdownData];
    //If all checkboxes are checked
    if (tempData.length - 1 === totalChecked) {
      tempData[0].ischecked = isChecked;
      allChecked = true;
    }

    setDropdownData(tempData);

    console.log(tempData, "12x");

    var lastfilter = [...dropdownData]
    if (isChecked === true) {
      let currentdata = lastfilter.map((i) => {
        if (i.Id == id) {
          i["ischecked"] = true;
        }
        return i;
      });

      setDropdownData(currentdata);
      let data1 = []
      const getallData = {
        Name: name,
      }
      data1.push(getallData)
      setFilterValue(filterValue => [...filterValue, ...data1]);

      let updateddata = ([...filterValue, ...data1]);

      if (allChecked) {
        const selectAllObj = {
          "Name": "Select All"
        };
        updateddata.splice(0, 0, selectAllObj);
      }

      setMultiFilterValue(() => ({
        ...multiFilterValue, [filterCol]: updateddata,
      }));
    }
    else {
      let currentdata = lastfilter.map((i) => {
        if (i.Id === id) {
          i["ischecked"] = false;
        }
        return i;
      });

      console.log(currentdata, "c-data");

      setDropdownData(currentdata);
      setFilterValue(filterValue => filterValue.filter(data => data.Name !== name))
      // let data1 = filterValue.filter(data => data.Name !== name);

      let data1 = multiFilterValue[filterCol] || [];
      data1 = data1.filter((data) => data.Name !== name);

      if (tempData.length - 1 >= totalChecked) {
        data1 = data1.filter(data => data.Name !== "Select All");
      }

      console.log(data1, "data");

      setMultiFilterValue(() => ({
        ...multiFilterValue, [filterCol]: data1,
      }));
    }

  }


  const checkAllFilter = (mycheckbox) => {
    var lastfilter = [...dropdownData]
    let data1 = []
    var checkboxes = document.querySelectorAll("input[name = 'filter-option-all']");
    if (mycheckbox.target.checked == true) {

      let currentdata = lastfilter.map((i) => {
        i["ischecked"] = true;
        return i;
      });
      setDropdownData(currentdata)

      dropdownData.map((each) => {

        const getallData = {
          Name: each.Name,
        }
        data1.push(getallData)
      })
      setFilterValue(filterValue => [...filterValue, ...data1]);
      let updateddata = ([...filterValue, ...data1]);
      setMultiFilterValue(() => ({
        ...multiFilterValue, [filterCol]: updateddata,
      }));
    }
    else {

      let currentdata = lastfilter.map((i) => {
        i["ischecked"] = false;
        return i;
      });
      setFilterValue([])
      setMultiFilterValue([])
    }
  }




  const [divleft, setdivleft] = useState('');
  const [divtop, setdivtop] = useState('');

  const FilterColumn = (name) => {

    const scrollTop = window.scrollY;
    const scrollTopActual = scrollTop >= 60 ? scrollTop - 85 : 0;
    var divtop1 = document.getElementById("tableA");
    setdivtop(divtop1.offsetTop + scrollTopActual + 30)

    setshowfilterpopup(true)
    setFilterCol(name)

    if (name === 'CustomerGroup') {
      setfiltertext("Customer Type")
      var testDiv = document.getElementById("CustomerGroup");
      setdivleft(testDiv.offsetLeft + 20 + "px");

    }
    if (name === 'LastModifiedBy') {
      setfiltertext(t('LastModifiedBy'))
      var testDiv = document.getElementById("LastModifiedBy");
      setdivleft(testDiv.offsetLeft + 20 + "px");
      DropdownHandler(name)
    }
    if (name === 'CreatedBy') {
      setfiltertext("Created By");
      var testDiv = document.getElementById("CreatedBy");
      // Adjust the calculation for divleft to shift the popup more towards the left
      setdivleft(((testDiv.offsetLeft + 30) - 150) + "px");
      DropdownHandler(name);
    }


    if (name === 'LastModifiedDate') {
      setfiltertext(t('LastModifiedDate'))
      var testDiv = document.getElementById("LastModifiedDate");
      setdivleft(testDiv.offsetLeft + 20 + "px");
    }
    if (name === 'Validity_From') {
      setfiltertext(t('Rebates.ValidityStartDate'))
      var testDiv = document.getElementById("Validity_From");
      setdivleft(testDiv.offsetLeft + 20 + "px");
    }
    if (name === 'Validity_To') {
      setfiltertext(t('Rebates.ValidityEndDate'))
      var testDiv = document.getElementById("Validity_To");
      setdivleft(testDiv.offsetLeft + 20 + "px");
    }
    if (name === 'Rebate_id') {
      setfiltertext(t('Rebates.RebateAgreementId'))
      var testDiv = document.getElementById("Rebate_id");
      setdivleft(testDiv.offsetLeft + 20 + "px");
    }
    if (name === 'Status') {
      setfiltertext(t('Program Line Status'))
      var testDiv = document.getElementById("Status");
      setdivleft(testDiv.offsetLeft + 20 + "px");
    }
    if (name === 'CustomerType') {
      setfiltertext(t('Rebates.CustomerType'))
      var testDiv = document.getElementById("CustomerType");
      setdivleft(testDiv.offsetLeft + 20 + "px");
    }
    if (name === 'RebateName') {
      setfiltertext(t('Rebates.AgreementName'))
      var testDiv = document.getElementById("RebateName");
      setdivleft(testDiv.offsetLeft + 20 + "px");
    }
    if (name === 'AgreementType') {
      setfiltertext(t('Rebates.AgreementType'))
      var testDiv = document.getElementById("AgreementType");
      setdivleft(testDiv.offsetLeft + 20 + "px");
       
       
      DropdownHandler(name)
    }
    if (name === 'DistributorName') {
      setfiltertext(t('Rebates.DistributorName'))
      var testDiv = document.getElementById("DistributorName");
      setdivleft(testDiv.offsetLeft + 20 + "px");
      DropdownHandler(name)

    }
    setshowfilterpopup(true)
  }


  const addSeletAll = (optionsArray, alreadySelected) => {

    let result = optionsArray;
    let selectAllObj = {
      "Name": "Select All",
      "Value": "Select All",
      "ischecked": alreadySelected
    }

    result.splice(0, 0, selectAllObj);
    console.log(result, "9999")
    return result;
  }

  const addnewfield = (data, key) => {

    let currentdata = data.map((i) => {
      i["ischecked"] = false;
      return i;
    });
    let selectedKeys = multiFilterValue[key] ? multiFilterValue[key] : [];

    console.log(currentdata, "test-data");
    let alreadySelected = selectedKeys.find((obj) => obj.Name === "Select All") ? true : false; if (key != 'StatusLabel') {
      if (selectedKeys) {
        currentdata.forEach((each) => {
          selectedKeys.forEach((eachSelected) => {
            if (each.Name == eachSelected.Name) {
              each.ischecked = true
            }

          })
        })
      }


      let result = addSeletAll(currentdata, alreadySelected);
      console.log(dropdownData,"Dropdown Data")
      setDropdownData(result);
      setFilterDropdownData(result)
    }
    else {
      let alreadySelected = selectedKeys.find((obj) => obj.Name === "Select All") ? true : false;
      if (selectedKeys) {
        currentdata.forEach((each) => {
          selectedKeys.forEach((eachSelected) => {
            if (each.Name == eachSelected.Name) {
              each.ischecked = true
            }
          })
        })
        setDropdownData(currentdata)
        setFilterDropdownData(currentdata)
      }
      else {
        setDropdownData(currentdata)
        setFilterDropdownData(currentdata)
      }
    }
  }



  const DropdownHandler = (key) => {
    Service.getfilterDropdownByGroup('Rebate/GetRebateAgreementFilterValuelst', key).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
              addnewfield(res.data, key);
              console.log(res.data, "MultiData");
            }
          }
        }
      }

    });
  }



  const UserfilterHandler = (e) => {

    setFilterSearch(e.target.value)
    if (e.target.value !== "") {
      let searchResults = dropdownData.filter((each) => {
        return each.Name.toLowerCase().includes(String(e.target.value).toLowerCase())
      });
      setDropdownData(searchResults);
    }
    else {

      setFilterSearch("")
      setDropdownData(filterdropdownData)
    }
  }

  const filterHandler = (flag) => {

    var sortType = columnAscending === true ? 'Ascending' : 'Descending';
    let pagenumber = 1;
    paging.pageNumber = pagenumber;

    if (flag === 'Apply') {

      setshowfilterpopup(false)
      let list = [];

      for (var key in multiFilterValue) {
        if (multiFilterValue[key].length > 0) {
          let values = ""
          multiFilterValue[key].map((i) => {
            values += i.Name + ";";
          })
          if (values !== null && values !== '') {
            list.push({
              filterKey: key,
              FilterValue: values
            })
          }
        }
      }

      for (var key in filterValueDict) {
        if (filterValueDict[key] !== null && filterValueDict[key] !== '') {
          list.push({
            filterKey: key,
            FilterValue: filterValueDict[key]
          })
        }
      }
      if (list.length > 0) {
        setpaging(paging);
        getRebateListViewData("rebate/GetByRebateStatuslst/status", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);
      }

      let tempDropdownData = dropdownData;
      if (tempDropdownData.length > 0){
        tempDropdownData[0]["ischecked"] = true;}
      setDropdownData(tempDropdownData);
    }
    else {
      setshowfilterpopup(false)

      setMultiFilterValue(() => ({
        ...multiFilterValue, [filterCol]: '',
      }));
      setFilterValueDict(() => ({
        ...filterValueDict, [filterCol]: '',
      }));

      let list = [];
      for (var key in multiFilterValue) {
        if (key !== filterCol && multiFilterValue[key].length > 0) {
          let values = ""
          multiFilterValue[key].map((i) => {
            values += i.Name + ";";
          })
          if (values !== null && values !== '') {
            list.push({
              filterKey: key,
              FilterValue: values
            })
          }
        }
      }

      for (var key in filterValueDict) {
        if (filterValueDict[key] !== null && filterValueDict[key] !== '' && key !== filterCol) {
          list.push({
            filterKey: key,
            FilterValue: filterValueDict[key]
          })
        }
      }
      setpaging(paging);
      getRebateListViewData("rebate/GetByRebateStatuslst/status", paging.pageSize, pagenumber, sortType, sortColName, list, searchValue);
      setFilterCol('')
      setFilterValue([])
      setDropdownChange("")
      setFilterSearch("")
      setDropdownData([])
      setFilterDropdownData([])
    }
  }



  const CreateNew = (type) => {
    if (type === "spares-target-tod") {
      navigate("/new-rebate/0/fromRebate/0")
    }
    else if (type === "engine-oil-tod") {
      navigate("/new-engineoil/0/fromRebate/0")

    }
    
  }

  // const CreateNewClaimAgreement = () => {
  //   navigate("/new-claimagreement/0/fromRebate/0")
  // }

  const OpenRPL = () => {
    navigate("/new-rebate/" + checkedValue + "/fromRebate/0")
  }


  const ExporttoCSV = () => {

    let checkedBoxes = document.querySelectorAll("input[name=chkBox]:checked");
    let values = "";
    if (checkedBoxes.length >= 1) {
      for (let checkbox of checkedBoxes) {
        values = values === "" ? checkbox.id : values + ";" + checkbox.id;
      }
    }

    let objFilter = [
      {
        filterKey: "id",
        FilterValue: values,
      },
    ];

    Service.getDatawithMultiFilterSearch("rebate/GetByRebateStatuslst/status", 20000, 1, "Ascending", "Id", checkAllChecked ? filterLst() : objFilter, null).then((res) => {
      console.log(res, 'api-resp');
      if (res !== undefined && res.data !== null && res.data !== undefined) {
        let csvLst = res.data.DataList;

        let selectedIds = values.split(';');

        let operatedList = csvLst
        //If checkall box is not checked
        if (!checkAllChecked) {
          //If it has not been checked in history/past
          if (!allCheckedHistory)
            operatedList = csvLst.filter(item => selectedIds.includes(`${item.Id}`))
          //If it has been checked in history/past
          else
            operatedList = csvLst.filter(item => !uncheckedBoxesHistory.includes(item.Id))
        }

        let filteredCsvList = operatedList.map(item => {
          let newItem = {};
          Object.keys(item).forEach(key => {
            if (!hideColumn.includes(key)) {
              if (key === 'LastModifiedDate') {
                newItem[key] = `${moment(item[key]).format(dateFormat)} ${moment(item[key]).format(timeFormat)}`;
              } else if (key === 'Validity_From' || key === 'Validity_To') {
                newItem[key] = moment(item[key]).format(dateFormat);
              } else {
                newItem[key] = item[key];
              }
            }
          });
          return newItem;
        });

        let filteredHeaders = headers.filter(header => !hideColumn.includes(header.key));

        console.log(filteredCsvList, "filtered");

        setCsvList(filteredCsvList);
        setHeaders(filteredHeaders);

        setTimeout(() => {
          toast.success('Exported Successfully !', {
            position: toast.POSITION.TOP_RIGHT,
          });
          csvInstance.current.link.click();
        });
      }
    });
  }



  const customMultiStyles = {
    multiselectContainer: {
      maxWidth: '188px',
      marginLeft: '3px'
    },
    chips: {
      borderRadius: '5px'
    }
  };


  const getRebateCustomerData = (method, Id) => {

    Service.getdatabyId(method, Id).then((res) => {
      if (typeof res !== 'undefined') {
        if (res.data !== 'null' && res.data !== 'undefined') {

          setCustomerData(res.data.DataList)

        }
      }
    });
  }

  const getRebatePrgramLines = (method, id, pagesize, pagenumber, sortType, SortProperty, filterlist, search, flag) => {
    Service.getPostdatabyRebateIdSearch(method, id, pagesize, pagenumber, sortType, SortProperty, filterlist, search, flag).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          let rData = res.data.DataList;
          if (res.status === 200) {
            if (res.data.DataList !== 'null' && res.data.DataList !== 'undefined') {

              res.data.DataList.forEach((each) => {
                each['selected'] = false;
              })
              setshowRplData(res.data.DataList)

            }
          }
        }
      }
    });
  }


  const getRebateProgramLineDetails = (method, id) => {
    Service.getdatabyRebateProgramId(method, id).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
              let rData = res.data[0];

              SetProducts(rData.Product_list);
              SetFilteredProducts(rData.Product_list);
              setclickProduct(false);
            }
          }
        }
      }
    });
  }







  const RenewPopup = (flag, screen) => {

    if (flag == "Close" & screen == 'Screen2') {
      if (checkExcludeRpl == true || checkRplProducts == true) {
        closeScreen2('Open')
      }
      else {
        setShowRenewPopup(false)
        setShowCustomerpopup(false)
        setShowScreen("")
        setRenewData({ ...renewData, ['lineitems']: [], ['Rebateid']: 0 })
      }
    }
    if (flag == "Close" & screen == 'Screen3') {
      if (checkRplValidity == true || checkExcludeRpl == true || checkRplProducts == true) {
        closeScreen3('Open')
      }
      else {
        setShowRenewPopup(false)
        setShowCustomerpopup(false)
        setShowScreen("")
        setRenewData({ ...renewData, ['lineitems']: [], ['Rebateid']: 0, ['validityfrom']: "", ['validityto']: "", ['autosubmit']: "", ['submit_comments']: "" })
      }
    }
    if (flag == "Skip") {
      skipexclude()
      setBgColor("#f2f2f2")
      setShowRenewPopup(true)
      setShowCustomerpopup(false)
      setShowScreen("Screen3")
      setsidebarwidth("60vw")
      setsidebarHeight("70vh")
    }
    if (flag == "Open") {
      getRebatePrgramLines("getrebateprogramlines/rebateid", checkedValue, paging.pageSize, paging.pageNumber, "Descending", "Id", [], searchValue, "Renew")
      setBgColor("white")
      setShowRenewPopup(true)
      setShowCustomerpopup(false)
      setShowScreen("Screen2")
      setsidebarwidth("60vw")
      setsidebarHeight("70vh")
    }
    else if (flag == "Proceed" && screen == 'Screen2') {
      setBgColor("#f2f2f2")
      setShowScreen("Screen3")
      setShowRenewPopup(true)
      setShowCustomerpopup(false)
      setsidebarwidth("60vw")
      setsidebarHeight("70vh")
      updateValues()
    }
    else if (flag == "Proceed" && screen == 'Screen3') {
      let renewcheck = false
      if (renewData.validityfrom == '') {
        alert("Please Select Validity From for Renew")
        renewcheck = true
      }
      if (renewData.validityto == '') {
        alert("Please Select Validity To for Renew")
        renewcheck = true
      }
      if (renewcheck == false) {
        setShowRenewPopup(false)
        setShowCustomerpopup(false)
        renewAgreement()
      }
    }
  }


  const renewAgreement = () => {
    Service.databyRenew("Rebate/RenewAgreement", loggedInUser, "Renew", renewData).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          let rData = res.data;
          if (res.status === 200) {
            if (res.data !== 'null' && res.data !== 'undefined') {
              if (res.data.Status != 'Failure') {

                let id = res.data.Id
                navigate("/new-rebate/" + id + "/fromRebate/0")
              }
              else {
                setErrorMessage(res.data.ErrorMessage);
                setErrorStatus("Error")
                setShowErrorModal(true)
              }
            }
          }
        }
      }
    });
  }

  const autoSubmitAgreement = (e, flag) => {
    if (flag == 'yes') {
      setautoSubmit1(true)
      setautoSubmit2(false)
      setRenewData({ ...renewData, ['autosubmit']: "Yes" })
      setcheckRplValidity(true)
    }
    if (flag == 'No') {
      setautoSubmit2(true)
      setautoSubmit1(false)
      setRenewData({ ...renewData, ['autosubmit']: "No" })
      setcheckRplValidity(true)
    }
    if (flag == 'Comments') {
      setRenewData({ ...renewData, ['submit_comments']: e.target.value })
      setcheckRplValidity(true)
    }
  }

  const getdataProductSelectorByAttribute = (programId) => {

    Service.getdataWithPagingandattributeSearchingByGroup('rebate/ProductSelectorByAttributeNew', '', 200000, 1, 0, [], programId).then((res) => {
      if (typeof res !== 'undefined' && typeof res.data !== 'undefined') {
        setdualListBoxOptionsByAttribute(res.data.DataList)
      }
    });
  };

  const RenewProductPopup = (id, flag) => {
    setRplId(id)
    if (flag == 'Open') {
      if (renewData.lineitems.length === 0) {
        getRebateProgramLineDetails("GetRebateProgramLinesDetails", id)
      }
      else {
        let exists = false
        const selectedProducts = renewData.lineitems
        selectedProducts.forEach((each) => {
          if (each.Id == id) {
            exists = true
            SetFilteredProducts(each.Products)
          }
        })
        if (!exists) {
          getRebateProgramLineDetails("GetRebateProgramLinesDetails", id)
        }
      }
      setTimeout(() => {
        setSelectedType('Attribute')
        setShowRenewPopup(false)
        setShowRenewProductPopup(true)
        setShowCustomerpopup(false)
        setsidebarwidth("60vw")
        setsidebarHeight("100vh")
      }, 100)
    }
    if (flag == 'Close') {
      if (checkRplProducts == true) {
        closeProductRenew('Open')
      }
      else {
        setShowRenewProductPopup(false)
        setShowCustomerpopup(false)
        setBgColor("white")
        setShowRenewPopup(true)
        setShowScreen("Screen2")
        setsidebarwidth("60vw")
        setsidebarHeight("70vh")
      }
    }
    if (flag == 'Proceed') {
      addProductData()
      setShowRenewProductPopup(false)
      setShowCustomerpopup(false)
      setBgColor("white")
      setShowRenewPopup(true)
      setShowScreen("Screen2")
      setsidebarwidth("60vw")
      setsidebarHeight("70vh")
    }
  }

  const addProductData = () => {
    setRenewData({ ...renewData, ['Rebateid']: checkedValue });
    setShowRenewProductPopup(false)
    setShowCustomerpopup(false)
    setBgColor("white")
    setShowRenewPopup(true)
    setShowScreen("Screen2")
    setsidebarwidth("60vw")
    setsidebarHeight("70vh")
  }

  const OnChangeHandlerStartDate = (e) => {
    let validityfrom = e
    let date = moment(moment(validityfrom).toDate()).format("YYYY-MM-DD");

    if (moment(e).isSameOrBefore(rebateValidity.ValidTo)) {
      setErrorMessage(`${t('Rebates.Validations.RenewValidFromCannotStartBeforeYourAgreementValidityTo')}`);
      setErrorStatus("Error")
      setShowErrorModal(true)
    }
    else {
      var validfrom = new Date(e);
      validfrom.setHours(validfrom.getHours() + 5);
      validfrom.setMinutes(validfrom.getMinutes() + 30);
      setRenewData({ ...renewData, ['validityfrom']: validfrom });

      setcheckRplValidity(true)
    }
  };
  const OnChangeHandlerEndDate = (e) => {
    let date = moment(moment(e).toDate()).format("YYYY-MM-DD");



    if (moment(e).isBefore(renewData.validityfrom)) {
      setErrorMessage(`${t('Rebates.Validations.RenewValidToCannotStartBeforeRenewValidFrom')}`);
      setErrorStatus("Error")
      setShowErrorModal(true)
    }
    else {
      var validto = new Date(e);
      validto.setHours(validto.getHours() + 5);
      validto.setMinutes(validto.getMinutes() + 30);
      setRenewData({ ...renewData, ['validityto']: validto });
      setcheckRplValidity(true)
    }
  };

  const UpdateProducts = (_SelectedProducts, rplid) => {

    setPrevSelectedTypeState(selectedType);
    SetFilteredProducts(_SelectedProducts);
    FilterProductList(_SelectedProducts);
    let temp = false;
    inputField.addproductdata = temp
    setInputField(inputField);
    showpopupcontnent.value = 2;
    setshowpopupcontnent(showpopupcontnent);

    setcheckRplProducts(true)
    let data = []
    if (renewData.lineitems.length > 0) {
      let exists = false
      renewData.lineitems.forEach((each) => {
        if (each.Id === rplid) {
          exists = true
          each.Products = _SelectedProducts
        }
      })
      if (!exists) {
        const getAllData = {
          Id: rplid,
          Products: _SelectedProducts
        }
        renewData.lineitems.push(getAllData)
      }
    }
    else {
      const getAllData = {
        Id: rplid,
        Products: _SelectedProducts
      }
      renewData.lineitems.push(getAllData)
    }
  }

  const FilterProductList = (selectedProducts) => {

    let res = []
    let res2 = []
    let temp_prodList = []
    let temp_excludeProdList = []


    if (ProductList == undefined) {
      ProductList = [];
    } else {
      ProductList.length = 0;
    }
    ExcludedProductList.length = 0;
    temp_prodList.length = 0
    temp_excludeProdList.length = 0

    if (selectedProducts) {
      Service.postDataWithValue("Rebate/ProductSelectorByhierarchyUpdated", selectedProducts).then((res) => {
        if (typeof res !== 'undefined') {
          if (typeof res.data !== 'undefined' && res.data !== 'null') {
            if (res.status === 200) {
              let rData = res.data

              let result = []
              if (rData !== 'null' && rData !== 'undefined') {

                rData.forEach((item) => {
                  if (item) {
                    temp_prodList.push(item)
                  }
                })
              }
              SetFilteredProducts(rData)
            }
          }
        }

      })
    }
  }



  const SelectedProductTypeHandler = (event) => {
    setSelectedType(event.target.value);

    if (event.target.value === 'Heirarchy') {
      setPrevSelectedTypeState("Attribute");
    }
    else {
      setPrevSelectedTypeState("Heirarchy");
    }
  }


  const AddProductHandler = () => {

    if (dualListBoxOptionsByHeirarchy.length == 0) {
      Service.postdatawithId("Rebate/GetProductSelectorUpdatedByGroup").then((res) => {
        if (typeof res !== 'undefined') {
          if (typeof res.data !== 'undefined' && res.data !== 'null') {
            if (res.status === 200) {
              if (res.data !== 'null' && res.data !== 'undefined') {


                setdualListBoxOptionsByHeirarchy(res.data)
              }
            }
          }
        }

      });
    }
  }

  const excludeRpl = (e, id, rplId, desc) => {
    let selected = false
    let temp = showRplData
    temp.forEach((each) => {
      if (each.Id == id) {
        each.selected = e.target.checked
      }
      if (each.selected) {
        selected = true
      }
    })
    setshowRplData(temp)
    setcheckExcludeRpl(selected)

  }



  const closeScreen2 = (flag) => {
    if (flag == 'Open') {
      setCloseHeaderModal(true)
    }
    if (flag == 'Cancel') {
      setCloseHeaderModal(false)
    }
    if (flag == 'Close') {
      setCloseHeaderModal(false)
      setShowRenewPopup(false)
      setShowCustomerpopup(false)
      setShowScreen("")
      setcheckExcludeRpl(false)
      setcheckRplProducts(false)
      setRenewData({ ...renewData, ['lineitems']: [], ['Rebateid']: 0 })
    }
  }
  const closeProductRenew = (flag) => {
    if (flag == 'Open') {
      setCloseHeaderModal(true)
    }
    if (flag == 'Cancel') {
      setCloseHeaderModal(false)
    }
    if (flag == 'Close') {
      let data = renewData.lineitems.filter(i => i.Id !== rplId)
      setRenewData({ ...renewData, ['lineitems']: data })
      setCloseHeaderModal(false)
      setShowRenewProductPopup(false)
      setShowCustomerpopup(false)
      setBgColor("white")
      setShowRenewPopup(true)
      setShowScreen("Screen2")
      setsidebarwidth("60vw")
      setsidebarHeight("70vh")
    }
  }

  const closeScreen3 = (flag) => {
    if (flag == 'Open') {
      setCloseHeaderModal(true)
    }
    if (flag == 'Cancel') {
      setCloseHeaderModal(false)
    }
    if (flag == 'Close') {
      setCloseHeaderModal(false)
      setShowRenewPopup(false)
      setShowCustomerpopup(false)
      setShowScreen("")
      setautoSubmit1(false)
      setautoSubmit2(false)
      setcheckExcludeRpl(false)
      setcheckRplProducts(false)
      setcheckRplValidity(false)
      setRenewData({ ...renewData, ['lineitems']: [], ['Rebateid']: 0, ['validityfrom']: "", ['validityto']: "", ['autosubmit']: "", ['submit_comments']: "" })
    }
  }


  const skipexclude = () => {
    let lineitems = []

    showRplData.forEach((each) => {
      let data = {
        Id: each.Id,
        Products: []
      }
      lineitems.push(data)
    })
    setRenewData({ ...renewData, ['lineitems']: lineitems, ['Rebateid']: checkedValue })

  }
  const updateValues = () => {
    let updatedItems = renewData.lineitems;
    let obj = []
    showRplData.forEach((each) => {
      if (each.selected == false) {
        obj.push({
          Id: each.Id,
          Products: []
        })
      }
    })

    updatedItems.forEach((item) => {
      obj.forEach((each) => {
        if (each.Id == item.Id) {
          each.Products = item.Products
        }
      })
    })

    setRenewData({ ...renewData, ['lineitems']: obj, ['Rebateid']: checkedValue })

  }




  // -------------------------Column Manager---------------

  const columnList = [
    { "label": "Rebates.RebateAgreementId", "key": "Rebate_id", "value": true },
    { "label": "Rebates.AgreementName", "key": "RebateName", "value": true },
    { "label": "Rebates.AgreementType", "key": "AgreementType", "value": true },
    { "label": "Rebates.Customer", "key": "Customer", "value": true },
    { "label": "Program Line Status", "key": "Status", "value": true },
    { "label": "Rebates.ValidityStartDate", "key": "Validity_From", "value": true },
    { "label": "Rebates.ValidityEndDate", "key": "Validity_To", "value": true },
    { "label": "LastModifiedBy", "key": "LastModifiedBy", "value": true },
    { "label": "LastModifiedDate", "key": "LastModifiedDate", "value": true },
    { "label": "CreatedBy", "key": "CreatedBy", "value": true },

  ]
  const [addRemoveCol, setAddRemoveCol] = useState(columnList);
  const [createColumnListOption, setCreateColumnListOption] = useState(columnList)
  const [showAddColumnPopup, setShowAddColumnPopup] = useState(false)
  const [showCreateColumnListPopup, setShowCreateColumnListPopup] = useState(false)
  const [hideColumn, setHideColumn] = useState([]);
  const [resetColumnKey, setResetColumnKey] = useState([]);
  const [colVisibilityOption, setColVisibilityOption] = useState([]);
  const [colvisiOptionVal, setColvisiOptionVal] = useState({ value: null, label: 'Default' })
  const [divleft1, setdivleft1] = useState('');
  const [divtop1, setdivtop1] = useState('');
  const [existingViewVal, setExistingViewVal] = useState(false);
  const [defaultView, setDefaultView] = useState(false);
  const [colViewName, setColViewName] = useState('');
  const [recorddId, setRecordId] = useState(0);


  const OnChangedefaultView = (event,) => {
    setDefaultView(event.target.checked)
  }
  const OnChangeexistingViewVal = (event,) => {
    setExistingViewVal(event.target.checked)
  }

  const OnChangeSetViewName = (event,) => {
    setColViewName(event.target.value)
  }

  const OnChangeColVisibilityList = (data) => {
    setColvisiOptionVal({ value: data.value, label: data.label });
    getColVisibilityListData(data.value)
  }
  const CreateColumnListPopup = () => {
    setShowCreateColumnListPopup(true)
    var divtop1 = document.getElementById("addRemoveCol");
    setdivtop1(divtop1.offsetTop + 30)
    setdivleft1((divtop1.offsetLeft) + 100 + "px");
  }

  const CloseCreateColumnListPopup = () => {
    setShowCreateColumnListPopup(false)
    setColViewName('')
    setDefaultView(false)
  }

  const AddRemoveColumn = () => {
    setShowAddColumnPopup(true)
    var divtop1 = document.getElementById("addRemoveCol");
    setdivtop(divtop1.offsetTop + 45)
    setdivleft((divtop1.offsetLeft) + "px");
    getColumnVisibilityList()
  }
  const OnchangeAddRemoveCol = (event, column) => {
    let isChecked = event.target.checked;
    let lst = [...addRemoveCol];
    for (let d in lst) {
      if (lst[d].key == column.key && isChecked) {
        lst[d]['value'] = true
      }
      else if (lst[d].key == column.key && !isChecked) {
        lst[d]['value'] = false
      }
    }
    setResetColumnKey([...resetColumnKey, column.key])
    setAddRemoveCol(lst)
  }
  const OnchangeCreateColumnListOption = (event, column) => {
    let isChecked = event.target.checked;
    let lst = [...createColumnListOption];
    for (let d in lst) {
      if (lst[d].key == column.key && isChecked) {
        lst[d]['value'] = true
      }
      else if (lst[d].key == column.key && !isChecked) {
        lst[d]['value'] = false
      }
    }
    setCreateColumnListOption(lst)
  }
  const updateColumnVisibilityList = (tag) => {
    let lst = [...addRemoveCol];
    if (tag == 'reset') {
      for (let d in lst) {
        lst[d]['value'] = true
      }
      setHideColumn([])
      setAddRemoveCol(lst)
    }
    else {
      let data = [];
      // console.log(lst, 'lst');
      for (let d in lst) {
        if (!lst[d].value) {
          data.push(lst[d].key)
        }
      }
      setHideColumn(data)
    }
    let viewName = colvisiOptionVal.value;
    Service.setColumnVisibilityListData('ColumnVisibility/CreateOrUpdateColumnList', recorddId, loggedInUser, 'rebateAgreementList', viewName, existingViewVal, lst).then((res) => { })
    setShowAddColumnPopup(false)
    setResetColumnKey([])
  }

  const CreateColumnVisibilityList = (tag) => {
    let veiwName = colViewName;
    let idDefault = defaultView;
    if (veiwName.trim() == 'undefined' || veiwName.trim() == '' || veiwName.trim() == null) {
      setErrorStatus("Error")
      setErrorMessage("Please set View Name")
      setShowErrorModal(true)
    }
    else {
      let lst = [...createColumnListOption];
      if (tag == 'reset') {
        for (let d in lst) {
          lst[d]['value'] = true
        }
        setHideColumn([])
        setAddRemoveCol(lst)
      }
      else {
        let data = [];
        for (let d in lst) {
          if (!lst[d].value) {
            data.push(lst[d].key)
          }
        }
        setHideColumn(data)
      }
      Service.setColumnVisibilityListData('ColumnVisibility/CreateOrUpdateColumnList', 0, loggedInUser, 'rebateAgreementList', colViewName, defaultView, lst).then((res) => {
        getColumnVisibilityList()
        setColvisiOptionVal({ value: colViewName, label: colViewName });
        getColVisibilityListData(colViewName)
        CloseCreateColumnListPopup()
        setShowAddColumnPopup(false)
      })
    }
  }

  const closeAddRemoveColumnPopup = () => {
    let lst = [...addRemoveCol];
    let resetKey = [...resetColumnKey];
    for (let rk in resetKey) {
      for (let d in lst) {
        if (lst[d].key == resetKey[rk]) {
          lst[d]['value'] = !lst[d].value
        }
      }
    }
    setAddRemoveCol(lst)
    setShowAddColumnPopup(false)
    setResetColumnKey([])

  }

  const getColumnVisibilityList = () => {
    Service.getColumnVisibilityListData('ColumnVisibility/GetColumnList', loggedInUser, 'rebateAgreementList', null).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {

            if (typeof res.data !== 'undefined' && res.data !== 'null') {
              let data = []
              for (let d in res.data) {
                data.push({ value: res.data[d].label, label: res.data[d].label })

              }

              setColVisibilityOption(data)
            }
          }
        }
      }
    })
  }


  const getColVisibilityListData = (viewName = null) => {
    debugger
    if (!viewName) {
      viewName = colvisiOptionVal.value
    }
    if (viewName == 'Deleted') {
      viewName = null
    }

    Service.getColumnVisibilityListData('ColumnVisibility/GetColumnListJsonData', loggedInUser, 'rebateAgreementList', viewName).then((res) => {
      if (typeof res !== 'undefined') {
        if (typeof res.data !== 'undefined' && res.data !== 'null') {
          if (res.status === 200) {
            if (typeof res.data.jsonData !== 'undefined' && res.data.jsonData !== 'null') {
                   console.log("res data",res.data)
              let jsonData = JSON.parse(res.data.jsonData)
              let data = [];
              for (let d in jsonData) {
                if (!jsonData[d].value) {
                  data.push(jsonData[d].key)
                }
              }
              setColvisiOptionVal({ value: res.data.label, label: res.data.label });
              setHideColumn(data)
              console.log(jsonData,"json Data");
              setAddRemoveCol(jsonData)
              setExistingViewVal(res.data.defaultView)
              setResetColumnKey([])
              setRecordId(res.data.id)
            }
          }
        }
      }
    })
  }

  const deleteColumnVisibilityView = () => {
    Service.deleteDataByPolicyID("ColumnVisibility/Delete", recorddId, loggedInUser).then(res => {
      if (res.status === 200) {
        getColumnVisibilityList()
        getColVisibilityListData('Deleted')
        setShowAddColumnPopup(false)

        toast.error('Deleted Successfully !', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    })
  }



  const dateFormat = SessionManage.getcurrentdateformat();
  const timeFormat = "hh:mm:ss A";

  // ---------------------------------------






  return (
    <>
      <div id='overlay'>
        <div className="container-fluid content-top-gap">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb my-breadcrumb">
              <li className="breadcrumb-item"><Link to="/">{t('Home.title')}</Link></li>
              <li className="breadcrumb-item active" aria-current="page">{t('TOD Agreement List')}</li>
            </ol>
          </nav>
          {loading ? <><div className="loader-container"><div className="spinner"></div> </div> </> : ''}
          <div style={{ display: "none" }}>
            <CSVLink data={csvList} headers={headers} ref={csvInstance} filename="TOD Agreement.csv" > </CSVLink> </div>
          <ToastContainer autoClose={1000} />

          <div className="">
            <div className="row">
              <div className="col-lg-12 pr-lg-2">
                <div className="card text-center card_border overflow-auto">

                  <div className="card-body" style={{ minHeight: "70vh", width: "100%" }}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className='d-flex align-items-center mb-1'>
                          <input type="text" className="form-control form-control-user col-md-10" id="firstName" name="firstName" autoComplete='off' placeholder={t('Search for Agreement ID, Agreement Name, Program Line Status, Last Modified By and Created By')} onChange={(event) => searchHandler(event)} />
                          <button id="addRemoveCol" icon className="btn btn-update ml-2 pa-0" type="submit"
                            style={{ float: "left", cursor: "pointer", padding: '0px', height: '28px', width: '26px' }}
                            onClick={AddRemoveColumn} title='Manage View'><i class="fa fa-columns" aria-hidden="true"></i></button>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className='row'>
                          <div className="col-md-12 d-flex justify-content-end align-items-center">
                            <div style={{ display: 'inline-block', marginRight: '15px' }}>
                            {totalselectedcount === 0 &&  !userPerms.some(permission => permission.ActionName === "View Only") && Service.exportPerms(userPerms) &&(
                                <>
                                  <a className="btn btn-update btn-sm mr-1" type="submit" style={{ width: "190px", backgroundColor: "buttonface" }} onClick={() => CreateNew("spares-target-tod")}>{t('Create New Spares Target TOD')}</a>
                                  <a className="btn btn-update btn-sm" type="submit" style={{ width: "190px", backgroundColor: "buttonface" }} onClick={() => CreateNew("engine-oil-tod")}>{t('Rebates.CreateNewEngineOilTOD')}</a>
                                </>
                              )}
                              {totalselectedcount > 0 && Service.exportPerms(userPerms) && (
                                <button className="btn btn-update btn-sm ml-2" type="submit" style={{ width: "50px" }} onClick={(event) => ExporttoCSV()}>{t('Export')}</button>
                              )}
                              {totalselectedcount === 1 && (
                                <>
                                  <a className="btn btn-update btn-sm ml-2" type="submit" style={{ width: "45px", backgroundColor: "buttonface" }} 
                                  onClick={() => {
                                      const agreementType = filteredArray.find(each => each.Id == checkedValue)?.AgreementType;
                                      OpenHandler(checkedValue, agreementType);
                                  }}
                                  >{t('Open')}</a>
                                  {Service.editPerms(userPerms) !== false && (
                                    <button className="btn btn-update btn-sm ml-2" type="submit" style={{ width: "40px" }} onClick={(event) => CopyHandler(checkedValue)}>{t('Copy')}</button>
                                  )}
                                </>
                              )}
                              {totalselectedcount > 0 &&  !userPerms.some(permission => permission.ActionName === "View Only") && Service.exportPerms(userPerms) &&(
                                <button className="btn btn-update btn-sm ml-2" type="submit" style={{ width: "50px", backgroundColor: "buttonface" }} onClick={() => checkDelete()}>{t('Delete')}</button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="scrollbar-class mb-1">
                      <table className="table table-bordered text-left mt-2 table-responsive-sm" id="tableA">
                        <thead className="text-white is-sticky-thead-product" style={{ backgroundColor: "var(--primary)" }}>
                          <tr style={{ position: "sticky", top: "0", backgroundColor: "var(--primary)" }}>

                            <th style={{ width: '30px' }}>
                              <input
                                type="checkbox"
                                onChange={checkAll}
                                checked={checkAllChecked}
                                id="option-all"
                              />
                            </th>

                            <th id="Rebate_id" style={{ backgroundColor: (filterValueDict.Rebate_id ? '#6cae55' : ''), minWidth: "130px" }}
                              hidden={hideColumn.includes('Rebate_id') ? true : false}>
                              <div className='d-flex text-nowrap'>
                                <span>{t('Rebates.RebateAgreementId')} </span>
                                <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Rebate_id')}></span>
                                <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Rebate_id')}></span>
                              </div>
                            </th>
                            <th id="RebateName" style={{ backgroundColor: (filterValueDict.RebateName ? '#6cae55' : ''), minWidth: "110px" }}
                              hidden={hideColumn.includes('RebateName') ? true : false}>
                              <div className='d-flex text-nowrap'>
                                <span>{t('Rebates.AgreementName')} </span>
                                <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('RebateName')}></span>
                                <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('RebateName')}></span>
                              </div>
                            </th>
                            <th id="AgreementType" style={{ backgroundColor: (multiFilterValue.AgreementType ? '#6cae55' : ''), minWidth: "120px" }}
                              hidden={hideColumn.includes('AgreementType') ? true : false}>
                              <div className='d-flex text-nowrap'>
                                <span>{t('Agreement Type')}</span>
                                <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('AgreementType')}></span>
                                <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('AgreementType')}></span>

                              </div>
                            </th>

                            <th id="Customer" style={{ backgroundColor: (multiFilterValue.Customer ? '#6cae55' : ''), minWidth: "120px" }}
                              hidden={hideColumn.includes('Customer') ? true : false}>
                              <div className='d-flex text-nowrap'>
                                <span>{t('Rebates.Customer')}</span>

                              </div>
                            </th>
                           

                            {/* <th id="DistributorName" style={{ backgroundColor: (multiFilterValue.DistributorName ? '#6cae55' : ''), minWidth: "110px" }}
                              hidden={hideColumn.includes('DistributorName') ? true : false}>
                              <div className='d-flex text-nowrap'>
                                <span>{t('Rebates.DistributorName')} </span>
                                <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('DistributorName')}></span>
                                <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('DistributorName')}></span>
                              </div>
                            </th> */}
                            <th id="Status" style={{ backgroundColor: (filterValueDict.Status ? '#6cae55' : ''), minWidth: "140px" }}
                              hidden={hideColumn.includes('Status') ? true : false}>
                              <div className='d-flex text-nowrap'>
                                <span>{t('Program Line Status')}</span>
                                <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Status')}></span>
                                <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Status')}></span>
                              </div>
                            </th>
                            <th id="Validity_From" style={{ backgroundColor: (filterValueDict.created_date ? '#6cae55' : ''), minWidth: "150px" }}
                              hidden={hideColumn.includes('Validity_From') ? true : false}>
                              <div className='d-flex text-nowrap'>
                                <span>{t('Rebates.ValidityStartDate')}</span>
                                <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Validity_From')}></span>
                                <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Validity_From')}></span>
                              </div>
                            </th>
                            <th id="Validity_To" style={{ backgroundColor: (filterValueDict.Validity_To ? '#6cae55' : ''), minWidth: "150px" }}
                              hidden={hideColumn.includes('Validity_To') ? true : false}>
                              <div className='d-flex text-nowrap'>
                                <span>{t('Rebates.ValidityEndDate')}</span>
                                <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('Validity_To')}></span>
                                <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('Validity_To')}></span>
                              </div>
                            </th>

                            <th id="LastModifiedBy" style={{ backgroundColor: (multiFilterValue.LastModifiedBy ? '#6cae55' : ''), minWidth: "110px" }}
                              hidden={hideColumn.includes('LastModifiedBy') ? true : false}>
                              <div className='d-flex text-nowrap'>
                                <span>{t('LastModifiedBy')}</span>
                                <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('LastModifiedBy')}></span>
                                <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('LastModifiedBy')}></span>
                              </div>
                            </th>
                            <th id="LastModifiedDate" style={{ backgroundColor: (filterValueDict.LastModifiedDate ? '#6cae55' : ''), minWidth: "120px" }}
                              hidden={hideColumn.includes('LastModifiedDate') ? true : false}>
                              <div className='d-flex text-nowrap'>
                                <span>{t('LastModifiedDate')} </span>
                                <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('LastModifiedDate')}></span>
                                <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('LastModifiedDate')}></span>
                              </div>
                            </th>
                            <th id="CreatedBy" style={{ backgroundColor: (multiFilterValue.CreatedBy ? '#6cae55' : ''), minWidth: "120px" }}
                              hidden={hideColumn.includes('CreatedBy') ? true : false}>
                              <div className='d-flex text-nowrap'>
                                <span>{t('CreatedBy')} </span>
                                <span className='fa fa-sort ml-1 mt-1' onClick={() => SortColumn('CreatedBy')}></span>
                                <span className='fa fa-filter ml-1 mt-1' style={{ float: "right", marginTop: "4px", cursor: "pointer" }} onClick={() => FilterColumn('CreatedBy')}></span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            showspinner === true ?
                              <tr>
                                <>
                                  <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                                  <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                                  <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                                </>
                              </tr>

                              :
                              filteredArray.length === 0 ?

                                <tr><td></td>
                                  <td colSpan="9" style={{ textAlign: 'center' }}>
                                    {t('NoDataAvailable')}
                                  </td>
                                </tr>
                                :
                                filteredArray.map((listVal, index) =>
                                  <tr key={listVal.Rebate_id} onDoubleClick={(event) => OpenRebate(event, listVal.Id, listVal.AgreementType)}>
                                    <td><input id={listVal.Id} type="checkbox" name="chkBox" value={`${listVal.Rebate_id}, ${listVal.Status}`} onChange={(event) => handleChange(event, listVal.Id, listVal.Status, listVal.Rebate_id, listVal.Validity_From, listVal.Validity_To, listVal.DistributorName, listVal.AgreementType)} /></td>
                                    {/* <td>{index + 1}</td> */}
                                    <td style={{ minWidth: "110px" }} hidden={hideColumn.includes('Rebate_id') ? true : false}>{listVal.Rebate_id}</td>
                                    <td style={{ minWidth: "120px" }} hidden={hideColumn.includes('RebateName') ? true : false}><div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={listVal.RebateName}>{listVal.RebateName}</div></td>
                                    <td style={{ minWidth: "120px" }} hidden={hideColumn.includes('AgreementType') ? true : false}><div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={listVal.AgreementType}>{listVal.AgreementType}</div></td>
                                    {listVal.AgreementType !== "ClaimAgreement" ?
                                      <td hidden={hideColumn.includes('Customer') ? true : false}><a href='javascript:void(0)' onClick={(event) => clickShowCustomerPopup(event, listVal.Id, "Open")}><label> Selected Customers</label></a></td>
                                      : <td hidden={hideColumn.includes('Customer') ? true : false}></td>}
                                     
                                    <td style={{ minWidth: "150px" }} hidden={hideColumn.includes('Status') ? true : false}><div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right" title={listVal.Status}>{listVal.Status}</div></td>
                                    <td hidden={hideColumn.includes('Validity_From') ? true : false}>{moment(listVal.Validity_From).format(dateFormat)}</td>
                                    <td hidden={hideColumn.includes('Validity_To') ? true : false}>{moment(listVal.Validity_To).format(dateFormat)}</td>
                                    <td style={{ minWidth: "110px" }} hidden={hideColumn.includes('LastModifiedBy') ? true : false}>{listVal.LastModifiedBy}</td>
                                    <td style={{ minWidth: "110px" }} hidden={hideColumn.includes('LastModifiedDate') ? true : false}>{`${moment(listVal.LastModifiedDate).format(dateFormat)} ${moment(listVal.LastModifiedDate).format(timeFormat)}`}</td>
                                    <td hidden={hideColumn.includes('CreatedBy') ? true : false}>{listVal.CreatedBy}</td>
                                  </tr>,
                                )
                          }
                        </tbody>
                      </table>
                    </div>
                    
                    <div className='row'>

                      {
                        totalselectedshow === true ?

                          <div className='col-lg-2 forgettext col-sm-12 border form-control' style={{ textAlign: "center", marginLeft: "0.938rem" }}>
                            {t('TotalRecordsSelected')} - <span id="totalselected">{totalselectedcount}</span>
                          </div>

                          :
                          <div className='col-lg-2 col-sm-12' style={{ textAlign: "center", marginLeft: "0.938rem" }}>

                          </div>
                      }



                       <div style={{flex: `0 0 ${totalCountlst > 14 ? "62.5%" : "63%"}`,maxWidth: totalCountlst > 14 ? "62.5%" : "63%"}}></div>
                      <div className='col-lg-2 forgettext  col-sm-12 border form-control' style={{ textAlign: "center",marginLeft: "0.1rem" }}>
                        {t('TotalRecords')} - {totalCountlst}
                      </div>
                    </div>
                    {
                      showLoadMore === true ?
                        <div className='text-center container row mb-1'>
                          <div className='col-md-5'></div>
                          <div className='col-md-2'>
                            <button className='btn btn-sm btn-update ml-1' onClick={handleLoadMore} style={{ width: "70px" }}>{t('LoadMore')}</button>
                          </div>
                          <div className='col-md-5'></div>
                        </div>
                        :
                        ""
                    }
                    <Modal show={showDeleteModal}
                      size="sm"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered>
                      <Modal.Body style={{ "textAlign": 'center' }}>Are you sure you want to delete ?</Modal.Body>
                      <Modal.Footer>
                        <Button style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => showDeleteDialog('close')}>
                          Cancel
                        </Button>
                        <Button style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => showDeleteDialog('proceed')}>
                          Delete
                        </Button>
                      </Modal.Footer>
                    </Modal>
                    <Modal show={errorDeleteModal}
                      size="sm"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered>
                      <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>
                        Only Agreements with Rebate Programs not Submitted can be Deleted
                      </Modal.Body>
                      <Modal.Footer>
                        <Button style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "45px" }} size='sm' onClick={() => setErrorDeleteModal(false)}>
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  {showfilterpopup === true ?
                    <div className='pop-up' style={{ left: divleft, top: divtop }}>
                      {filterCol === 'Rebate_id' || filterCol === 'Status' || filterCol == 'CustomerGroup' || filterCol == 'CustomerType' || filterCol == 'RebateName' ?
                        <>
                          <div className="container">
                            <div className="row">
                              <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                            </div>
                          </div>
                          <div className="container mt-2">
                            <div className="row">
                              <input type='textbox' placeholder={t('Search')} className='filtertextboxbackground col-md-11 textbox-padding ml-2' style={{ outline: 'none' }} value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                            </div>
                          </div>
                        </>

                        :
                        <>
                        </>
                      }

                      {filterCol === 'LastModifiedDate' || filterCol === 'Validity_From' || filterCol === 'Validity_To' ?
                        <>
                          <div className="container">
                            <div className="row">
                              <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>
                            </div>
                          </div>
                          <div className="container mt-2">
                            <div className="row">
                              <input type='date' className='filtertextboxbackground col-md-11 textbox-padding ml-2' value={filterValueDict[filterCol]} onChange={(event) => filterInput(event)}></input>
                            </div>
                          </div>
                        </>
                        :
                        <></>
                      }

                      {filterCol === 'Customer' || filterCol === 'LastModifiedBy' || filterCol === 'CreatedBy' || filterCol === "DistributorName" || filterCol == "AgreementType"?

                        <>
                          <div className="container">
                            <div className="row">
                              <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>

                            </div>

                          </div>

                          <div className="container mt-2">
                            <div className="row">
                              <input type="text" placeholder={t('Search')} value={filterSearch} onChange={(event) => UserfilterHandler(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                            </div>

                            <div className="row contentoverflow mt-2">
                              <table className="filtertable">
                                <tbody>
                                  {/* <tr>
                                    <td><input type="checkbox" name="filter-option-all" id="option-all" onChange={checkAllFilter} /></td>
                                    <td>{t('SelectAll')}</td>
                                  </tr> */}
                                  {
                                    dropdownData.map((item, index) => (

                                      <tr key={index}>

                                        <td><input id={item.Id} data-index="1" name="filter-option-all" checked={item.ischecked} type="checkbox" onChange={item.Name === "Select All" ? checkAllFilter : (event) => OndropdownChange(event, item.Id, item.Name, filterCol)} /></td>

                                        <td>{item.Name}</td>

                                      </tr>


                                    ))
                                  }

                                </tbody>
                              </table>

                            </div>
                          </div>
                        </>
                        :
                        <> {filterCol == "AgreementType" ?
                          <><div className="container">
                            <div className="row">
                              <b className="forgettext col-md-11 textbox-padding ml-2">{filtertext}</b>

                            </div>

                          </div>

                            <div className="container mt-2">
                              <div className="row">
                                <input type="text" placeholder={t('Search')} value={filterSearch} onChange={(event) => UserfilterHandler(event)} className='filtertextboxbackground col-md-11 textbox-padding ml-2' />
                              </div>

                              <div className="row contentoverflow mt-2">
                                <table className="filtertable">
                                  <tbody>
                                    <tr>
                                      <td><input type="checkbox" name="filter-option-all" id="option-all" onChange={checkAllFilter} /></td>
                                      <td>{t('SelectAll')}</td>
                                    </tr>
                                    {
                                      dropdownData.map((item, index) => (

                                        <tr key={index}>

                                          <td><input id={item.Id} data-index="1" name="filter-option-all" checked={item.ischecked} type="checkbox" onChange={(event) => OndropdownAgreementTypeChange(event, item.Id, item.Name)} /></td>

                                          <td>{item.Name}</td>

                                        </tr>


                                      ))
                                    }

                                  </tbody>
                                </table>

                              </div>
                            </div></>
                          : <></>}</>
                      }
                      <div style={{ paddingTop: '10px', paddingLeft: '47px', paddingBottom: '7px' }}>

                        <button className="btn btn-sm btn-update" onClick={() => filterHandler('Apply')} style={{ width: "45px" }}>{t('Apply')}</button>
                        <button className="btn btn-sm btn-update" style={{ marginLeft: '10px', width: "45px" }} onClick={() => filterHandler('Reset')}>{t('Reset')}</button>
                      </div>
                      <div>
                      </div>
                    </div>
                    :
                    <></>
                  }

                  {
                    showCustomerPopup === true ?
                      <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, marginRight: "10px", padding: "0" }}>
                        <div className="container" style={{ padding: "0" }}>
                          <div className="row">
                            <div className="col-lg-12 pr-lg-2">
                              <div className="card text-center card_border">
                                <div className="card-header chart-grid__header">
                                  <div className="row">
                                    <div className="col-md-9 text-left">
                                      Customer Listing
                                    </div>
                                    <div className="col-md-3 d-flex justify-content-end">
                                      <button className="btn btn-outline-dark  btn-sm ml-1" onClick={(event) => clickShowCustomerPopup(0, 0, 'Close')} type="submit" style={{ width: "45px" }}>{t('Close')}</button>
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                          <div className="row mt-1" id="Customer">
                            <div className="col-lg-12 pr-lg-2">
                              <div className="card text-center card_border">
                                <div className="card-header chart-grid__header">
                                </div>
                                <div className='scrollbar-class'>
                                  <div className="container">
                                    <table className="table table-bordered text-left mt-2 " id="Customer">
                                      <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)" }}>
                                        <tr>
                                          <th id="Name" style={{ width: "110px" }}>Name</th>
                                          <th id="Label" style={{ maxWidth: "110px" }}>Label</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {
                                          customerData.map((cslist, index) => (
                                            <tr>
                                              <td style={{ width: "90px" }}>
                                                <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                  title={cslist.Name}>{cslist.Name}</div>
                                              </td>
                                              <td style={{ width: "260px" }}>
                                                <div style={{ textOverflow: "ellipsis", overflow: "hidden", height: "20px" }} data-toggle="tooltip" data-placement="right"
                                                  title={cslist.label}>{cslist.label}</div>
                                              </td>
                                            </tr>
                                          ))
                                        }
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      :
                      ""
                  }


                  {
                    showRenewPopup === true ?
                      <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: sidebarHeight, overflow: "hidden" }}>
                        <div className="container" style={{ padding: "0" }}>
                          <div className="row">
                            <div className="col-lg-12 pr-lg-2">
                              <div className="card text-center card_border ">
                                <div className="card-header chart-grid__header">
                                  <div className="row">
                                    <div className="col-md-6 text-left">
                                      {t('Renew')}
                                    </div>

                                    {showScreen === 'Screen2' ?
                                      <>
                                        <div className="col-md-6 text-right">
                                          <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => RenewPopup("Skip")} type="submit" style={{ width: "40px" }}>{t('Skip')}</button>
                                          <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => RenewPopup("Close", "Screen2")} type="submit" style={{ width: "50px" }}>{t('Cancel')}</button>
                                          <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => RenewPopup("Proceed", 'Screen2')} type="submit" style={{ width: "55px" }}>{t('Proceed')}</button>
                                        </div>
                                      </>
                                      :
                                      <></>
                                    }
                                    {showScreen === 'Screen3' ?
                                      <>
                                        <div className="col-md-6 text-right">
                                          <div className="row" style={{ display: "inline" }}>
                                            <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => RenewPopup("Close", "Screen3")} type="submit" style={{ width: "50px" }}>{t('Cancel')}</button>
                                            <button className="btn btn-outline-dark  btn-sm ml-2" style={{ width: "70px" }} onClick={() => RenewPopup("Proceed", 'Screen3')} type="submit">{t('RenewNow')}</button>
                                          </div>
                                        </div>
                                      </>
                                      :
                                      <></>
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-1">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-lg-12 pr-lg-2">
                                  <div className="card text-center card_border">
                                    <div className="card-body">
                                      <div className="container" style={{ minHeight: "60vh", backgroundColor: BgColor }}>
                                        <div className='col-md-12'>
                                          {/* {showScreen === "Screen1" ?
                                            <>

                                            </>
                                            :
                                            <></>
                                          } */}
                                          {showScreen === "Screen2" ?
                                            <>
                                              <div className='scrollbar-class'>
                                                <table className="table table-bordered text-left mt-2" id="tableA">
                                                  <thead className='text-white is-sticky-thead-product' style={{ backgroundColor: "var(--primary)", color: "white" }}>
                                                    <tr>
                                                      <th style={{ width: '30px' }}>
                                                        <div className='d-flex text-nowrap'>
                                                          <span>{t('Exclude')}</span>
                                                        </div>
                                                      </th>
                                                      <th style={{ width: '150px' }}>
                                                        <div className='d-flex text-nowrap'>
                                                          <span>{t('Rebates.ProgramId')}</span>
                                                        </div>
                                                      </th>
                                                      <th style={{ width: '250px' }}>
                                                        <div className='d-flex text-nowrap'>
                                                          <span> {t('Rebates.Description')} </span>
                                                        </div>
                                                      </th>
                                                      <th style={{ width: '150px' }}>
                                                        <div className='d-flex text-nowrap'>
                                                          <span>{t('Rebates.AddRemoveProducts')}</span>
                                                        </div>
                                                      </th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {
                                                      showspinner === true ?
                                                        <tr>
                                                          <>
                                                            <Spinner animation="grow" size="sm" variant="success" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                            <Spinner animation="grow" size="sm" variant="danger" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                            <Spinner animation="grow" size="sm" variant="warning" style={{ width: "0.7rem", height: "0.7rem" }} />
                                                          </>
                                                        </tr>

                                                        :
                                                        showRplData.length === 0 ?
                                                          <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td>{t('NoDataAvailable')}</td>
                                                            <td></td>
                                                          </tr>
                                                          :
                                                          showRplData.map((listVal, index) =>
                                                            <tr >
                                                              <td><input id={listVal.Id} value={listVal.Id} type="checkbox" onChange={(event) => excludeRpl(event, listVal.Id, listVal.RebateProgramLineId, listVal.RebateProgram_Desc)} name="chkBox" /></td>
                                                              <td>{listVal.RebateProgramLineId}</td>
                                                              <td>{listVal.RebateProgram_Desc}</td>
                                                              <td><a href='javascript:void(0)'><label onClick={() => RenewProductPopup(listVal.Id, 'Open')}>{t('Rebates.Products')}</label></a></td>
                                                            </tr>
                                                          )

                                                    }

                                                  </tbody>
                                                </table>
                                                <label className="col-md-3 text-left" style={{ float: "left" }}>{t('Rebates.Page1')}</label>
                                              </div>
                                            </>
                                            :
                                            <></>
                                          }
                                          {showScreen === "Screen3" ?
                                            <>
                                              <div style={{ "textAlign": 'left' }}>
                                                <label style={{ marginBottom: "8px" }}>{t('Rebates.RenewPeriod')}</label>
                                                <hr style={{ borderColor: "black" }}></hr>
                                              </div>
                                              <div>
                                                <div className='row'>
                                                  <label className="col-md-3 text-left">{t('Rebates.ValidFrom')}</label>
                                                  <div className='col-md-2 mt-2 text-left textbox-padding'>
                                                    <DatePicker name="validityFrom" className={`drodownwidth ${disabledClass}`} autoComplete='off' dateFormat={SessionManage.getdateformat()} selected={renewData.validityfrom} onChange={(e) => OnChangeHandlerStartDate(e)} />
                                                  </div>
                                                </div>
                                                <div className='row'>
                                                  <label className="col-md-3 text-left">{t('Rebates.ValidTo')}</label>
                                                  <div className='col-md-2 mt-2 text-left textbox-padding'>
                                                    <DatePicker name="validityTo" className={`drodownwidth ${disabledClass}`} autoComplete='off' dateFormat={SessionManage.getdateformat()} selected={renewData.validityto} onChange={(e) => OnChangeHandlerEndDate(e)} />
                                                  </div>
                                                </div>
                                              </div>
                                              <div style={{ "textAlign": 'left', marginBottom: "8px" }}>
                                                <label style={{ marginBottom: "8px" }}>{t('Rebates.AutoSubmit')}</label>
                                                <hr style={{ borderColor: "black" }}></hr>
                                              </div>
                                              <div style={{ marginBottom: "10px" }}>
                                                <div className='row'>
                                                  <label className="col-md-4 text-left">{t('Rebates.AutoSubmitRenewedAgreement')}</label>
                                                  <label style={{ marginRight: "5px" }} >{t('Yes')}</label>
                                                  <input style={{ marginRight: "5px" }} type="radio" checked={autoSubmit1} onChange={(e) => autoSubmitAgreement(e, "yes")}></input>
                                                  <label style={{ marginRight: "8px" }} >{t('No')}</label>
                                                  <input style={{ marginRight: "5px" }} type="radio" checked={autoSubmit2} onChange={(e) => autoSubmitAgreement(e, "No")}></input>
                                                </div>
                                              </div>
                                              <label className="col-md-3 text-left" style={{ float: "left" }}>{t('Rebates.Page2')}</label>
                                              {autoSubmit1 == true ?
                                                <div>
                                                  <label className="col-md-4 text-left">{t('Rebates.SubmitComments')}</label>
                                                  <textarea style={{ resize: "none" }} onChange={(e) => autoSubmitAgreement(e, "Comments")}></textarea>
                                                </div>
                                                :
                                                <></>
                                              }
                                            </>
                                            :
                                            <></>
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      :
                      <></>
                  }


                  {
                    showRenewProductPopup === true ?
                      <div id="productsidepanel" className="productsidepanel sidepanelborder" style={{ width: sidebarwidth, padding: "0", height: sidebarHeight, overflow: "hidden" }}>
                        <div className="container" style={{ padding: "0" }}>
                          <div className="row">
                            <div className="col-lg-12 pr-lg-2">
                              <div className="card text-center card_border ">
                                <div className="card-header chart-grid__header">
                                  <div className="row">
                                    <div className="col-md-8 text-left">
                                      {t('Rebates.ProductListing')}
                                    </div>
                                    <>
                                      <div className="col-md-3">
                                        <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => RenewProductPopup(rplId, "Close")} type="submit" style={{ width: "50px" }}>{t('Cancel')}</button>
                                        <button className="btn btn-outline-dark  btn-sm ml-1" onClick={() => RenewProductPopup(rplId, "Proceed")} type="submit" style={{ width: "55px" }}>{t('Proceed')}</button>
                                      </div>
                                    </>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-1">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-lg-12 pr-lg-2">
                                  <div className="card text-center card_border ">
                                    <div className="card-body">
                                      <div className="container">
                                        <div className='col-md-12'>
                                          {showScreen === "Screen2" ?
                                            <ProductTreeDialog
                                              show={showRenewProductPopup}
                                              onOkClick={(e) => UpdateProducts(e, rplId)}
                                              onHide={() => RenewProductPopup}
                                              dataByHeirarchy={dualListBoxOptionsByHeirarchy}
                                              rebatelinestatus={rebatelinestatus}
                                              dialogType="Product"
                                              SelectedData={FilteredProducts}
                                              dataByAtrribute={dualListBoxOptionsByHeirarchy}
                                              selectedType={selectedType}
                                              programLineStatus={rebatelinestatus}
                                              dualListBoxOptionsByAttribute={dualListBoxOptionsByAttribute}
                                            >
                                              <div className={classes['Radiobutton-header']}>
                                                <div className={classes['Radiobutton-internal-div']} >
                                                  <input type="radio" checked={selectedType === 'Attribute'}
                                                    value="Attribute" onChange={SelectedProductTypeHandler}
                                                    name="product" className={classes['radio-button-Right']} /> {t('Rebates.Attribute')}
                                                  <input type="radio" checked={selectedType === 'Heirarchy'}
                                                    onChange={SelectedProductTypeHandler}
                                                    className={classes['radio-button-Left']} value="Heirarchy" name="product" /> {t('Rebates.Hierarchy')}

                                                </div>
                                              </div>
                                            </ProductTreeDialog>
                                            :
                                            <></>
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      :
                      <></>
                  }
                  {showAddColumnPopup === true ?
                    <div className='pop-up' style={{ left: divleft, top: divtop, width: "250px" }}>
                      <div className="container">
                        <div className="row">
                          <Select
                            className='col-md-10 textbox-padding fontsizedropdown'
                            options={colVisibilityOption}
                            placeholder="Select"
                            value={colvisiOptionVal}
                            onChange={OnChangeColVisibilityList}
                            styles={customStyles}
                          />
                          <button id="createColumnListPopup" icon className="btn btn-update ml-1 pa-0" type="submit"
                            style={{ float: "left", cursor: "pointer", padding: '0px', height: '28px', width: '26px' }}
                            onClick={CreateColumnListPopup} title='Add New View'><i class="fa fa-plus" aria-hidden="true"></i></button>
                        </div>
                        <div className="row mb-2" style={{ textAlign: 'right' }}>
                          <div className="col-md-10 mt-1 mr-1" style={{ padding: '0px', fontSize: '12px' }}>
                            <input id="existingViewVal" data-index="1" name="filter-option-all" checked={existingViewVal} type="checkbox"
                              onChange={(event) => OnChangeexistingViewVal(event)} /> <b>Is Default</b>
                          </div>
                        </div>
                        <div className="row">
                          <table className='ml-2' style={{ fontSize: '0.80rem' }}>
                            {
                              addRemoveCol.map((item, index) => (
                                <tr>
                                  <td key={index}><input id={item.label} data-index="1" name="filter-option-all" checked={item.value} type="checkbox"
                                    onChange={(event) => OnchangeAddRemoveCol(event, item)}
                                  /> {t(item.label)}</td>
                                </tr>
                              ))
                            }
                          </table>
                        </div>
                        <div className="row mr-2" style={{ float: "right" }}>
                          <div style={{ paddingTop: '10px', paddingLeft: '15px', paddingBottom: '7px' }}>
                            <button className="btn btn-sm btn-update" style={{ width: "45px" }} onClick={() => updateColumnVisibilityList('reset')}>{t('Reset')}</button>
                            <button className="btn btn-sm btn-update ml-1" style={{ width: "45px" }} onClick={closeAddRemoveColumnPopup}>{t('Close')}</button>
                            <button className="btn btn-sm btn-update ml-1" style={{ width: "55px", background: '#4755ab', color: 'white' }} onClick={() => updateColumnVisibilityList('apply')} title='Update View'>{t('Update')}</button>
                            <button className="btn btn-sm btn-update ml-1" style={{ width: "50px", background: 'rgb(233 43 0)', color: 'white' }} onClick={deleteColumnVisibilityView} title='Delete View'>{t('Delete')}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    : <></>
                  }


                  {showCreateColumnListPopup === true ?
                    <div className='pop-up' style={{ left: divleft1, top: divtop1, width: "190px" }}>
                      <div className="container">
                        <div className="row">
                          <div className="col-12 ml-2" style={{ padding: '0px' }}>
                            <input type='textbox' placeholder='View Name' style={{ height: '30px' }}
                              value={colViewName} name={colViewName} onChange={(event) => OnChangeSetViewName(event)}></input>
                          </div>
                        </div>
                        <div className="row mb-2" style={{ textAlign: 'right' }}>
                          <div className="col-10 mt-2" style={{ padding: '0px', fontSize: '12px' }}>
                            <input id="defaultView" data-index="1" name="filter-option-all" checked={defaultView} type="checkbox"
                              onChange={(event) => OnChangedefaultView(event)} /> <b>Set Default</b>
                          </div>
                        </div>
                        <div className="row">
                          <table className='ml-2' style={{ fontSize: '0.80rem' }}>
                            {
                              createColumnListOption.map((item, index) => (
                                <tr>
                                  <td key={index}><input id={item.label} data-index="1" name="filter-option-all" checked={item.value} type="checkbox"
                                    onChange={(event) => OnchangeCreateColumnListOption(event, item)}
                                  /> {t(item.label)}</td>
                                </tr>
                              ))
                            }
                          </table>
                        </div>
                        <div className="row mr-2" style={{ float: "right" }}>
                          <div style={{ paddingTop: '10px', paddingLeft: '35px', paddingBottom: '7px' }}>
                            <button className="btn btn-sm btn-update ml-1" style={{ width: "50px" }} onClick={CloseCreateColumnListPopup}>{t('Cancel')}</button>
                            <button className="btn btn-sm btn-update ml-1" style={{ width: "40px", background: '#4755ab', color: 'white' }} onClick={() => CreateColumnVisibilityList('apply')}>{t('Save')}</button>
                          </div>
                        </div>
                      </div>


                    </div>
                    : <></>
                  }
                  <Modal show={closeHeaderModal}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Body style={{ "textAlign": 'center', fontFamily: 'Segoe UI' }}>{t('Rebates.YourChangesWillBeLost!AreYouSureYouWantToClose?')}</Modal.Body>
                    <Modal.Footer>
                      {showScreen == 'Screen2' && showRenewProductPopup == false ?
                        <>
                          <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "45px" }} size='sm' onClick={(e) => closeScreen2('Close')}>
                            {t('Close')}
                          </Button>
                          <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={(e) => closeScreen2('Cancel')}>
                            {t('Cancel')}
                          </Button>
                        </>
                        :
                        <></>
                      }
                      {showScreen == 'Screen3' && showRenewProductPopup == false ?
                        <>
                          <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "45px" }} size='sm' onClick={(e) => closeScreen3('Close')}>
                            {t('Close')}
                          </Button>
                          <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={(e) => closeScreen3('Cancel')}>
                            {t('Cancel')}
                          </Button>
                        </>
                        :
                        <></>
                      }
                      {showRenewProductPopup == true ?
                        <>
                          <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "45px" }} size='sm' onClick={() => closeProductRenew('Close')}>
                            {t('Close')}
                          </Button>
                          <Button style={{ background: 'buttonface', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => closeProductRenew('Cancel')}>
                            {t('Cancel')}
                          </Button>
                        </>
                        :
                        <></>
                      }

                    </Modal.Footer>
                  </Modal>

                  <Modal show={showLockedModal}  size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                    {matchingObject === true ? <Modal.Body style={{ textAlign: 'center' }}>These records are locked!<br></br> You can't delete it.</Modal.Body> :
                <Modal.Body style={{ textAlign: 'center' }}>This record is locked!<br></br> You can't delete it.</Modal.Body>}
                <Modal.Footer>
                    <Button style={{ background: '#eff1f9', color: 'black', borderColor: 'black', width: "50px" }} size='sm' onClick={() => showDeleteDialog('closelocked')}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>


                  <CustomErrorPopup ErrorMessage={errorMessage} show={showErrorModal} ErrorStatus={errorStatus}
                    onHide={() => setShowErrorModal(false)}></CustomErrorPopup>
                </div>
              </div>
            </div>
          </div >
        </div >
      </div >
    </>
  )
}
export default RebateListComponent
